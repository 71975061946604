import { useCallback } from 'react';

import { EntityDiagramEffectFn, ReinitializationEffect } from '../types';

export function useReinitializeEffect(): EntityDiagramEffectFn<ReinitializationEffect> {
  return useCallback<EntityDiagramEffectFn<ReinitializationEffect>>(
    (_state, _effect, dispatch) => {
      dispatch({ type: 'REINITIALIZE' });
    },
    []
  );
}
