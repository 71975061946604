import { applyDerivedDataToState, generateDefaultState } from '../state';
import { EntityDiagramActionFn, ReinitializeAction } from '../types';

export const reinitializeAction: EntityDiagramActionFn<ReinitializeAction> = (
  state,
  _action,
  _queueEffect
) => {
  const newState = applyDerivedDataToState(
    generateDefaultState({
      primaryClients: state.primaryClients,
      entity: state.entity,
      isTwoClientHousehold: state.isTwoClientHousehold,
      presentationMode: state.presentationMode,
      selectedPrimaryClientId: state.selectedPrimaryClientId,
    })
  );

  return {
    ...newState,
    isInitializing: true,
  };
};
