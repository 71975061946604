import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  AIOnboardingDocumentPanelProps,
  AIOnboardingModalProviderProps,
  AIOnboardingModalStep,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { AIOnboardingModalFormShapeKeys } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { AiOnboardingModal_AiSuggestionFragment } from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { HouseholdBillableKind } from '@/types/schema';

export interface AIOnboardingModalContext
  extends Omit<
    AIOnboardingModalProviderProps,
    'suggestions' | 'documentIds' | 'documentIdsWithAcceptedSuggestions'
  > {
  activeStep: AIOnboardingModalStep | null;
  setActiveStep: (step: AIOnboardingModalStep) => void;
  submittedSteps: AIOnboardingModalStep[];

  suggestionsByFormPath: Record<
    AIOnboardingModalFormShapeKeys,
    AiOnboardingModal_AiSuggestionFragment[]
  >;

  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>;

  documentPanelProps: AIOnboardingDocumentPanelProps;
  setDocumentPanelProps: Dispatch<
    SetStateAction<AIOnboardingDocumentPanelProps>
  >;

  handleSearchEvidence: (suggestionID: string | undefined) => void;

  addRejectedSuggestion: (suggestionID: string | undefined) => void;

  endWorkflow: (shouldDeleteDocuments: boolean) => void;

  isSubmitting: boolean;
  onSubmit: () => void;
}

export const getDefaultValues = (): AIOnboardingModalContext => ({
  isOpen: false,
  onClose: noop,
  householdId: '',
  kgRootId: '',
  loading: false,
  household: {
    id: '',
    possiblePrimaryClients: [],
    possibleGrantors: [],
    billableKind: HouseholdBillableKind.Billable,
  },

  activeStep: null,
  setActiveStep: noop,
  submittedSteps: [],

  suggestionsByFormPath:
    {} as AIOnboardingModalContext['suggestionsByFormPath'],

  suggestionsByID: {},

  documentPanelProps: {
    activeDocumentViewerTab: 'document',
    activeDocumentId: '',
    searchQuery: '',
    annotations: undefined,
    searchLoading: false,
  },
  setDocumentPanelProps: noop,

  handleSearchEvidence: noop,

  addRejectedSuggestion: noop,

  endWorkflow: noop,

  isSubmitting: false,
  onSubmit: noop,
});

export const AIOnboardingModalContext =
  createContext<AIOnboardingModalContext>(getDefaultValues());

export const useAIOnboardingModalContext = () => {
  return useGuardedContext(
    AIOnboardingModalContext,
    'AIOnboardingModalProvider'
  );
};
