import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { EntityKind } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { PersonalAccountSummaryData } from './PersonalAccountDetails.types';

export function PersonalAccountDetailsMiniTable({
  taxStatus,
  entityKind,
  ownerNames,
  effectiveDate,
  locationDisplay,
  hideTaxStatus,
}: PersonalAccountSummaryData) {
  const shouldShowEffectiveDate =
    entityKind === EntityKind.IndividualPersonalAccount ||
    entityKind === EntityKind.JointPersonalAccount ||
    entityKind === EntityKind.CustodialPersonalAccount ||
    entityKind === EntityKind.QualifiedTuitionPersonalAccount;

  const rows = useMemo(() => {
    return compact([
      locationDisplay
        ? {
            label: 'Asset location',
            value: locationDisplay ?? EMPTY_CONTENT_HYPHEN,
          }
        : null,
      (ownerNames?.length ?? 0) > 0
        ? {
            label: 'Owner(s)',
            value: ownerNames ? (
              <TypographyClamped
                sx={{
                  textAlign: 'right',
                }}
                lines={2}
                variant="h6"
              >
                {ownerNames.join(', ')}
              </TypographyClamped>
            ) : (
              EMPTY_CONTENT_HYPHEN
            ),
          }
        : null,
      !hideTaxStatus && taxStatus?.value
        ? {
            label: 'Tax status',
            value: taxStatus?.value ?? EMPTY_CONTENT_HYPHEN,
          }
        : null,
      shouldShowEffectiveDate && effectiveDate
        ? {
            label: 'Effective Date',
            value: effectiveDate
              ? formatDateToMonDDYYYY(effectiveDate)
              : EMPTY_CONTENT_HYPHEN,
          }
        : null,
    ]);
  }, [
    effectiveDate,
    hideTaxStatus,
    locationDisplay,
    ownerNames,
    shouldShowEffectiveDate,
    taxStatus?.value,
  ]);

  return (
    <MiniTable
      variant="default"
      rowProps={{
        alignItems: 'start',
      }}
      rows={rows}
    />
  );
}
