import { map } from 'lodash';

import { STATE_CODE_TO_NAME } from '@/constants/states';

const STATE_CODE_OPTIONS = map(STATE_CODE_TO_NAME, (stateName, stateCode) => ({
  value: stateCode,
  display: `${stateCode} - ${stateName}`,
}));

const OFFSHORE_OPTION = {
  value: 'OFFSHORE',
  display: 'Offshore',
};

export const stateOptions = [...STATE_CODE_OPTIONS, OFFSHORE_OPTION];

export const stateAndOffshoreOptions = [...STATE_CODE_OPTIONS, OFFSHORE_OPTION];
