import { Box, Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';

import {
  FullScreenStructuredAssetsModalForm,
  FullScreenStructuredAssetsModalFormPath,
} from './FullScreenStructuredAssetsModal.types';

export interface BalanceSheetConfigurationForm {
  categoryId: string;
  showAsLineItem: boolean;
}

export interface BalanceSheetConfigurationAccordionProps {
  balanceSheetCategories: {
    id: string;
    displayName: string;
  }[];
}

export function BalanceSheetConfigurationAccordion({
  balanceSheetCategories,
}: BalanceSheetConfigurationAccordionProps) {
  const { control } = useFormContext<FullScreenStructuredAssetsModalForm>();
  const showBalanceSheetCategoryOverrideChooser = useWatch({
    control,
    name: `showBalanceSheetCategoryOverrideChooser`,
  });
  const categoryOptions = balanceSheetCategories.map((category) => ({
    display: category.displayName,
    value: category.id,
  }));

  return (
    <Accordion variant="inner-shadow" title="Balance sheet settings">
      <Stack spacing={2}>
        <Box>
          <FormAwareSwitch
            labelPosition="right"
            fieldName={
              `showBalanceSheetCategoryOverrideChooser` as const satisfies FullScreenStructuredAssetsModalFormPath
            }
            contextualHelp={
              <PopperContent body="Assets held by this entity will appear in a single balance sheet category, overriding any existing asset category to balance sheet category mapping" />
            }
            label="Bucket to single category"
            control={control}
          />
          {showBalanceSheetCategoryOverrideChooser && (
            <Box pl={6.5} mt={1}>
              <FormAwareSelectInput
                fieldName={
                  `balanceSheetCategoryOverrideId` as const satisfies FullScreenStructuredAssetsModalFormPath
                }
                label="Choose category"
                hideLabel
                control={control}
                options={categoryOptions}
              />
            </Box>
          )}
        </Box>
        <FormAwareSwitch
          labelPosition="right"
          fieldName={
            `showEntityNameOnBalanceSheet` as const satisfies FullScreenStructuredAssetsModalFormPath
          }
          contextualHelp={
            <PopperContent
              body="The display name of this entity will appear on the balance sheet
              instead of each individual asset held by this entity"
            />
          }
          label="Show only entity name"
          control={control}
        />
      </Stack>
    </Accordion>
  );
}
