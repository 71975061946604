// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../../types/schema';

import { gql } from '@apollo/client';
export type CustomSlide_PresentationBundleFragment = { __typename?: 'PresentationBundle', id: string, kind: Types.PresentationBundleKind, pages: { __typename?: 'PresentationPageConnection', edges?: Array<{ __typename?: 'PresentationPageEdge', node?: { __typename?: 'PresentationPage', id: string, kind: Types.PresentationPageKind } | null } | null> | null } };

export type CustomSlide_PresentationPageFragment = { __typename?: 'PresentationPage', id: string, kind: Types.PresentationPageKind };

export const CustomSlide_PresentationPageFragmentDoc = gql`
    fragment CustomSlide_PresentationPage on PresentationPage {
  id
  kind
}
    `;
export const CustomSlide_PresentationBundleFragmentDoc = gql`
    fragment CustomSlide_PresentationBundle on PresentationBundle {
  id
  kind
  pages {
    edges {
      node {
        ...CustomSlide_PresentationPage
      }
    }
  }
}
    ${CustomSlide_PresentationPageFragmentDoc}`;
export const Operations = {
  Fragment: {
    CustomSlide_PresentationBundle: 'CustomSlide_PresentationBundle' as const,
    CustomSlide_PresentationPage: 'CustomSlide_PresentationPage' as const
  }
}