import { useApolloClient } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { compact, first, isEmpty, some, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { LayoutAlt03Icon } from '@/components/icons/LayoutAlt03Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { DispositiveProvisionTemplateMenu } from '@/modules/dispositiveProvisions/components/DispositiveProvisionTemplatesMenu/DispositiveProvisionTemplateMenu';
import { DispositiveProvisionsModalContainer } from '@/modules/dispositiveProvisions/DispositiveProvisionsModalContainer/DispositiveProvisionsContainer';
import { DispositiveProvisionsTemplateSplitScreenModal } from '@/modules/dispositiveProvisions/DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal';
import {
  DispositiveProvisionsTemplateSplitScreenModalContextProvider,
  useDispositiveProvisionsTemplateSplitScreenModalContext,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { BUSINESS_ENTITY_KINDS } from '@/modules/entities/entities.constants';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath, AsyncJobKind, EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { CustomizeTaxSettingsButton } from './components/CustomizeTaxSettingsButton';
import { ManageDispositionsBreadcrumbs } from './components/ManageDispositionsBreadcrumbs';
import { ManageDispositionsSidebar } from './components/ManageDispositionsSidebar';
import { useManageDispositionsContext } from './context/ManageDispositions.context';
import { ManageDispositionsProvider } from './context/ManageDispositions.provider';
import {
  ManageDispositions_ClientProfileFragment,
  ManageDispositions_EstateWaterfallFragment,
  ManageDispositionsDocument,
  useManageDispositionsQuery,
} from './graphql/ManageDispositionsPage.generated';
import {
  ManageDispositionPageSections,
  ManageDispositions_AugmentedClientProfileRow,
} from './ManageDispositionPage.types';
import { ManageDispositionsContent } from './ManageDispositionsContent';
import { getEntitiesByVariant } from './ManageDispositionsEntitiesTable/ManageDispositionsEntitiesTable.utils';

const ORDERED_DEATH_EVENTS = [
  AfterDeath.None,
  AfterDeath.First,
  AfterDeath.Second,
];

function getAugmentedClientNodesFromWaterfall(
  primaryClients: ManageDispositions_ClientProfileFragment[],
  estateWaterfall: ManageDispositions_EstateWaterfallFragment
): ManageDispositions_AugmentedClientProfileRow[] {
  const relevantDeathEvents = ORDERED_DEATH_EVENTS.slice(
    0,
    primaryClients.length
  );

  const nodes = estateWaterfall.visualization.nodes.flatMap((n) => {
    if (
      !relevantDeathEvents.includes(n.afterDeath) ||
      n.node.__typename !== 'ClientProfile'
    ) {
      return [];
    }

    const associatedPrimaryClient = primaryClients.find(
      // TS wants an additional typeguard for the typename for some reason
      (c) => n.node.__typename === 'ClientProfile' && c.id === n.node.id
    );
    if (!associatedPrimaryClient) return [];
    return {
      ...associatedPrimaryClient,
      valueToDistribute: n.valueBeforeDispositions ?? new Decimal(0),
      firstDeathClientProfileId: estateWaterfall.firstGrantorDeath.id,
    };
  });

  // a given client may show up in a waterfall multiple times. for example, in a "client a dies first scenario",
  // if client b already has directly-held assets, they will show up both in the "None" death event and the
  // "First" death event. we only want to expose the first one, so we filter out duplicates.
  return uniqBy(nodes, 'id');
}

/**
 * @description Returns a representation of primary clients related to how they should show up in the dispositions lists.
 *
 * Important things to know:
 * - We only care about setting dispositions for clients that have directly-held assets.
 * - We use computed waterfalls to determine if they have directly-held assets, because trying to write a standalone
 *   query to determine this is very difficult.
 * - When we expose the "amount to distribute" for a client, we use the computed value of their directly-held assets
 *   from the waterfall.
 *
 * This function returns a list of clients that should be shown in the dispositions list *for each death order*.
 *
 * This is simple for a single-client household, where we just show the client at most one time (if they have any directly-held assets).
 *
 * For a two-client household, we can show each client zero OR up to two times: once for each death order, if they have directly-held assets.
 */
function getFilteredAugmentedPrimaryClients(
  primaryClients: ManageDispositions_ClientProfileFragment[],
  clientOneEstateWaterfalls: ManageDispositions_EstateWaterfallFragment[],
  clientTwoEstateWaterfalls: ManageDispositions_EstateWaterfallFragment[] | null // won't be present for a single-client household
): ManageDispositions_AugmentedClientProfileRow[] {
  // in the rare case that we have no estate waterfalls to compute this from, default to allowing
  // the user to configure DPs for all primary clients. we don't know the underlying value to distribute.
  if (isEmpty(clientOneEstateWaterfalls)) {
    return primaryClients.flatMap((deathClient) => {
      return primaryClients.map((profileClient) => {
        return {
          ...profileClient,
          valueToDistribute: new Decimal(0),
          firstDeathClientProfileId: deathClient.id,
        };
      });
    });
  }

  // estateWaterfalls will always be at most of length 1 because of the query
  const clientOneEstateWaterfall = clientOneEstateWaterfalls[0]!;
  const clientTwoEstateWaterfall = clientTwoEstateWaterfalls?.[0];

  const clientOneDeathProfileNodes = getAugmentedClientNodesFromWaterfall(
    primaryClients,
    clientOneEstateWaterfall
  );

  const clientTwoDeathProfileNodes = clientTwoEstateWaterfall
    ? getAugmentedClientNodesFromWaterfall(
        primaryClients,
        clientTwoEstateWaterfall
      )
    : [];

  return [...clientOneDeathProfileNodes, ...clientTwoDeathProfileNodes];
}

function ManageDispositionsPageInner() {
  const client = useApolloClient();
  const { lastCompletedJobs } = useAsyncJobNotificationsContext();

  const { householdId, isTwoClientHousehold, primaryClients } =
    useHouseholdDetailsContext();

  const {
    setInEstateEntities,
    setOutOfEstateEntities,
    setTestamentaryEntities,
    setPrimaryClientRows: setPrimaryClients,
    dispositiveProvisionsModalDetails: modalDetails,
    setDispositiveProvisionsModalDetails,
    setIsLoading,
    setAIDPSuggestionJobs,
  } = useManageDispositionsContext();

  const {
    dispositiveProvisionTemplateDetails,
    setDispositiveProvisionTemplateDetails,
  } = useDispositiveProvisionsTemplateSplitScreenModalContext();

  const { showFeedback } = useFeedback();

  const { data, loading } = useManageDispositionsQuery({
    // make sure to also refetch so e.g. deleted entities aren't incorrectly cached and displayed
    fetchPolicy: 'cache-and-network',
    variables: {
      householdId: householdId ?? '',
      isTwoClientHousehold,
      entitiesLike: {
        stageIn: [EntityStage.Active],
        // business entities don't have dispositive provisions
        kindNotIn: [...BUSINESS_ENTITY_KINDS],
      },
      clientOneEstateWaterfallsLike: {
        hasHouseholdWith: [{ id: householdId ?? '' }],
        hasFirstGrantorDeathWith: [{ id: primaryClients?.[0]?.id ?? '' }],
      },
      clientTwoEstateWaterfallsLike: {
        hasHouseholdWith: [{ id: householdId ?? '' }],
        hasFirstGrantorDeathWith: [{ id: primaryClients?.[1]?.id ?? '' }],
      },
    },
    onError: () => {
      showFeedback(
        "We weren't able to load entities. Please refresh the page and try again."
      );
    },
    skip: !householdId || !primaryClients,
  });

  useEffect(() => {
    if (some(lastCompletedJobs, (j) => j.kind === AsyncJobKind.AiDpBatched)) {
      // If we're on this page while a batch DP job has completed, refetch the
      // manage dispositions query to show sparkles for entities with DP suggestions,
      // without needing a refresh.
      void client.refetchQueries({
        include: [ManageDispositionsDocument],
      });
    }
  }, [client, lastCompletedJobs]);

  const {
    testamentaryEntities,
    inEstateEntities,
    outOfEstateEntities,
    primaryClients: augmentedPrimaryClients,
    aiDPSuggestionJobs,
  } = useMemo(() => {
    const household = getNodes(data?.households)[0];
    if (!household) {
      return {
        testamentaryEntities: [],
        inEstateEntities: [],
        outOfEstateEntities: [],
        primaryClients: [],
        aiDPSuggestionJobs: [],
      };
    }

    const testamentaryEntities = getNodes(household.testamentaryEntities);
    const entities = getNodes(household.entities);
    const clientOneEstateWaterfalls = getNodes(data?.clientOneEstateWaterfall);
    const clientTwoEstateWaterfalls = getNodes(data?.clientTwoEstateWaterfall);
    const allPrimaryClients = household.possiblePrimaryClients;
    const { inEstateEntities, outOfEstateEntities } =
      getEntitiesByVariant(entities);

    const relevantAugmentedPrimaryClients = getFilteredAugmentedPrimaryClients(
      allPrimaryClients,
      clientOneEstateWaterfalls,
      clientTwoEstateWaterfalls
    );

    return {
      testamentaryEntities,
      inEstateEntities,
      outOfEstateEntities,
      primaryClients: relevantAugmentedPrimaryClients,
      aiDPSuggestionJobs: compact(
        entities?.map((e) => first(getNodes(e?.prevAIDPJobs)))
      ),
    };
  }, [
    data?.clientOneEstateWaterfall,
    data?.clientTwoEstateWaterfall,
    data?.households,
  ]);

  useEffect(() => {
    setIsLoading(loading);
    setInEstateEntities(inEstateEntities);
    setOutOfEstateEntities(outOfEstateEntities);
    setTestamentaryEntities(testamentaryEntities);
    setPrimaryClients(augmentedPrimaryClients);
    setAIDPSuggestionJobs(aiDPSuggestionJobs);
  }, [
    aiDPSuggestionJobs,
    augmentedPrimaryClients,
    inEstateEntities,
    loading,
    outOfEstateEntities,
    setAIDPSuggestionJobs,
    setInEstateEntities,
    setIsLoading,
    setOutOfEstateEntities,
    setPrimaryClients,
    setTestamentaryEntities,
    testamentaryEntities,
  ]);

  return (
    <>
      {modalDetails && (
        <DispositiveProvisionsModalContainer
          details={modalDetails}
          onClose={() => setDispositiveProvisionsModalDetails(null)}
        />
      )}
      {dispositiveProvisionTemplateDetails && (
        <DispositiveProvisionsTemplateSplitScreenModal
          isOpen
          onClose={() => setDispositiveProvisionTemplateDetails(null)}
          templateDetails={dispositiveProvisionTemplateDetails}
          setDispositiveProvisionsModalDetails={
            setDispositiveProvisionsModalDetails
          }
        />
      )}
      <Grid container>
        <Grid item sm={4}>
          <ManageDispositionsSidebar />
        </Grid>
        <Grid item sm={8}>
          <ManageDispositionsContent />
        </Grid>
      </Grid>
    </>
  );
}

export function ManageDispositionsPage() {
  const { householdId } = useHouseholdDetailsContext();

  return (
    <>
      <ManageDispositionsProvider
        activeSection={ManageDispositionPageSections.IN_ESTATE_ENTITIES}
      >
        <DispositiveProvisionsTemplateSplitScreenModalContextProvider
          isTemplateMode={false}
        >
          <SubpageLayout
            heading={<ManageDispositionsBreadcrumbs />}
            actions={
              <Stack spacing={1} direction="row">
                {householdId && (
                  <DispositiveProvisionTemplateMenu
                    householdId={householdId}
                    label="Manage templates"
                    variant="secondary"
                    startIcon={LayoutAlt03Icon}
                  />
                )}
                <CustomizeTaxSettingsButton />
              </Stack>
            }
          >
            <ManageDispositionsPageInner />
          </SubpageLayout>
        </DispositiveProvisionsTemplateSplitScreenModalContextProvider>
      </ManageDispositionsProvider>
      <Outlet />
    </>
  );
}
