import { Box, Stack } from '@mui/material';
import { getYear } from 'date-fns';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { Loader } from '@/components/progress/Loader/Loader';
import { useForm, useFormContext } from '@/components/react-hook-form';
import {
  GiftBenefitsCard,
  GiftDesignerModelScenarios,
  GiftDesignerModelScenariosFormShape,
  GiftScenarioBarCharts,
  GiftScenarioTable,
  useGiftDesignerModelScenariosDefaultValues,
} from '@/modules/gifting/proposal/designer/form';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { GiftingProposalSelectedPreTaxReturnCategory } from '@/types/schema';

import { useGetGiftProjectedWealth } from '../hooks/useGetGiftProjectedWealth';
import { SlideBodyWithHeader } from './SlideBodyWithHeader';

interface GiftOverviewProps {
  proposal: ProposalFragment;
  yearOptions: {
    display: string;
    value: string;
  }[];
  preTaxCategory?: GiftingProposalSelectedPreTaxReturnCategory;
  yearIdx?: number;
}

function GiftProjectedWealthInner({
  proposal,
  yearOptions,
}: GiftOverviewProps) {
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const { projections, loading } = useGetGiftProjectedWealth({
    giftingProposalId: proposal.giftingProposal?.id ?? '',
  });

  return (
    <Stack spacing={8} sx={{ pb: 6 }}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="end"
            gap={3}
            rowGap={1}
            width="100%"
            flexWrap="wrap"
          >
            {loading && (
              <Loader
                boxProps={{
                  sx: {
                    display: 'flex',
                  },
                }}
                circularProgressProps={{
                  size: 20,
                }}
              />
            )}
            <Box maxWidth="200px">
              <GiftDesignerModelScenarios.AnnualPreTaxReturn />
            </Box>
            <Box maxWidth="200px">
              <div className="printVisible">
                <FormAwareButtonGroup<GiftDesignerModelScenariosFormShape>
                  control={control}
                  fieldName={'yearOfAnalysisClientProposal'}
                  variant="dark"
                  label="Year"
                  options={yearOptions}
                />
              </div>
            </Box>
            <Stack direction="row" spacing={3} pt={4}>
              <GiftDesignerModelScenarios.ShowAfterEstateTax />
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <GiftScenarioBarCharts
            proposalId={proposal.id}
            projections={projections}
          />
        </Stack>
        <GiftBenefitsCard
          proposalId={proposal.id}
          projections={projections}
          gridTemplateColumns={
            columnWidths.length
              ? columnWidths
                  // Account for the margin differences between the table and the card
                  .map((w) => `${w - 64 / columnWidths.length}px`)
                  .join(' ')
              : undefined
          }
        />
        <Stack pt={3}>
          <GiftScenarioTable
            proposalId={proposal.id}
            projections={projections}
            setColumnWidths={setColumnWidths}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function GiftProjectedWealth({
  proposal,
  preTaxCategory,
  yearIdx,
}: Omit<GiftOverviewProps, 'yearOptions'>) {
  const { getDefaultValues } = useGiftDesignerModelScenariosDefaultValues({
    proposalId: proposal.id,
  });

  const yearOptions = useMemo(() => {
    const lengthOfAnalysis = proposal.giftingProposal?.lengthOfAnalysis ?? 0;
    const start = getYear(new Date());
    const end = start + lengthOfAnalysis;
    const median = Math.floor((start + end) / 2);

    const years = compact([start, median, end]);

    return years.map((year) => ({
      display: year.toString(),
      value: year.toString(),
    }));
  }, [proposal]);

  const formMethods = useForm<GiftDesignerModelScenariosFormShape>({
    defaultValues: async () => {
      const values = await getDefaultValues();

      return {
        ...values,
        yearOfAnalysisClientProposal: yearOptions[yearIdx ?? 0]?.value ?? '',
        annualPreTaxReturn:
          preTaxCategory ?? GiftingProposalSelectedPreTaxReturnCategory.Low,
        showAfterEstateTax: true,
        exemptionSunsets: true,
      };
    },
  });

  return (
    <FormProvider {...formMethods}>
      <SlideBodyWithHeader header={proposal.displayName}>
        <GiftProjectedWealthInner
          proposal={proposal}
          yearOptions={yearOptions}
        />
      </SlideBodyWithHeader>
    </FormProvider>
  );
}
