import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export interface EntityDiagramContextContainerProps {
  height: number;
  width: number;
  children: ReactNode;
}

export const EntityDiagramContextContainer = ({
  height,
  width,
  children,
}: EntityDiagramContextContainerProps) => {
  return (
    <Stack
      display="grid"
      gridTemplateColumns="auto"
      gap={4}
      height={height}
      width={width}
    >
      {children}
    </Stack>
  );
};
