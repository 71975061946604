import Decimal from 'decimal.js';
import { cloneDeep, compact, keyBy, uniqueId } from 'lodash';
import { ReactNode } from 'react';

import { TileBeforeAndAfter } from '@/components/diagrams/components/Tile/TileBeforeAndAfter';
import { TileThemedTypography } from '@/components/diagrams/components/Tile/TileThemedTypography';
import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { Node, TileNode } from '@/components/diagrams/FlowChart';
import { isTileGroupNode } from '@/components/diagrams/FlowChart/utils/nodes';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
} from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatEnumCase } from '@/utils/formatting/strings';

import { getIsCharitableNode } from '../EstateWaterfall.utils';
import {
  EstateWaterfall_NodeFragment,
  EstateWaterfall_VizConfigFragment,
  EstateWaterfallFragment,
} from '../graphql/EstateWaterfall.generated';
import {
  EstateWaterfallGraph,
  EstateWaterfallGraphNodeAttributes,
  GraphNodeCategorizationType,
} from '../types';
import {
  categorizationTypeToVariant,
  EntityNode,
  ESTATE_TAX_NODE_IDENTIFIER,
  EXTERNAL_TRANSFER_NODE_IDENTIFIER,
  GIFT_TAX_NODE_IDENTIFIER,
  kindToCategorizationType,
  UI_ONLY_GROUP_NODE_IDENTIFIER,
  ValidTypeName,
  ValidTypeNamesSet,
} from './constants';
import { SHOW_CREATE_GROUP_MODAL_SENTINEL } from './CreateNewGroupModal.utils';
import { createEdge } from './waterfallGraph';

export function getNodeId({
  id,
  afterDeath,
}: Pick<EstateWaterfall_NodeFragment, 'id' | 'afterDeath'>) {
  return [afterDeath, id].join(':');
}

export function isTaxNode(nodeId: string): boolean {
  return new RegExp(ESTATE_TAX_NODE_IDENTIFIER).test(nodeId);
}

export function isGiftTaxNode(nodeId: string): boolean {
  return new RegExp(GIFT_TAX_NODE_IDENTIFIER).test(nodeId);
}

export function isUIOnlyGroupNode(nodeId: string): boolean {
  return new RegExp(UI_ONLY_GROUP_NODE_IDENTIFIER).test(nodeId);
}

export function isEntityNode(
  n: EstateWaterfall_NodeFragment['node']
): n is EntityNode {
  return ValidTypeNamesSet.has(n.__typename as ValidTypeName);
}

export function getTaxNodeId({
  afterDeath,
}: Pick<EstateWaterfall_NodeFragment, 'afterDeath'>) {
  return getNodeId({ id: ESTATE_TAX_NODE_IDENTIFIER, afterDeath });
}

export function getGiftTaxNodeId({
  afterDeath,
}: Pick<EstateWaterfall_NodeFragment, 'afterDeath'>) {
  return getNodeId({ id: GIFT_TAX_NODE_IDENTIFIER, afterDeath });
}

export function isNodeIdForSection(nodeId: string, sectionId: AfterDeath) {
  return nodeId.startsWith(`${sectionId}:`);
}

export function getExternalTransferNodeId(
  transferKind: 'incoming' | 'outgoing'
) {
  return getNodeId({
    id: `${transferKind}-${EXTERNAL_TRANSFER_NODE_IDENTIFIER}`,
    afterDeath:
      transferKind === 'incoming' ? AfterDeath.None : AfterDeath.First,
  });
}

export function getEstateTaxAfterDeath(afterDeath: AfterDeath): AfterDeath {
  if (afterDeath === AfterDeath.None) return AfterDeath.First;
  if (afterDeath === AfterDeath.First) return AfterDeath.Second;
  // Context: https://withluminary.slack.com/archives/C05BPQS8CG4/p1692220364855759
  throw new Error(
    `Cannot traverse estate tax after death target past: ${afterDeath}`
  );
}

export function getNodeGroupId(
  node: EstateWaterfallGraphNodeAttributes
): string | null {
  return 'group' in node.data ? node.data.group?.id ?? null : null;
}

export function getGroupNodeIdForNodeId(
  g: EstateWaterfallGraph,
  nodeId: string
): string | null {
  const node = assertNonNil(
    g.getNodeAttributes(nodeId),
    `could not get node attrs for ${nodeId}`
  );

  const groupId = getNodeGroupId(node);
  if (!groupId) return null;

  return getNodeId({ id: groupId, afterDeath: node.data.afterDeath });
}

export function getCategorizationType({
  node,
}: EstateWaterfall_NodeFragment): GraphNodeCategorizationType | null {
  let kind;
  if (node.__typename === 'Entity') {
    kind = node.entityKind;
  } else if (node.__typename === 'TestamentaryEntity') {
    kind = node.testamentaryEntityKind;
  } else if (
    node.__typename === 'ClientProfile' ||
    node.__typename === 'ClientOrganization'
  ) {
    return GraphNodeCategorizationType.Individual;
  } else {
    throw new Error(`Unhandled tile in estate waterfall`);
  }

  const type = kindToCategorizationType[kind];
  if (!type) {
    diagnostics.error(`Unhandled type from kind in estate waterfall ${type}`);
    return null;
  }
  return type;
}

export interface BuildTileFromNodeInput {
  nodeFragment: EstateWaterfall_NodeFragment;
  visualizationConfig?: EstateWaterfall_VizConfigFragment | null;
  firstDeathGrantor?: ContextPrimaryClient;
  secondDeathGrantor?: ContextPrimaryClient;
  isNewTile?: boolean;
  hasHypotheticalTransfer?: boolean;
  hasIncomingPouroverDisposition?: boolean;
  hasOutgoingPouroverDisposition?: boolean;
}

export interface GetBeforeAndAfterDataOptions {
  hasHypotheticalTransfer: boolean | undefined;
  hasIncomingPouroverDisposition: boolean | undefined;
  hasOutgoingPouroverDisposition: boolean | undefined;
}

interface GetBeforeAndAfterDataOutputBase {
  shouldShowBeforeAndAfterTransfers: boolean;
  shouldShowBeforeAndAfterPouroverWills: boolean;
  beforeActionsValue: Decimal | null;
  afterActionsValue: Decimal | null;
  shouldShowBeforeAndAfter: boolean;
}

interface GetBeforeAndAfterShowData extends GetBeforeAndAfterDataOutputBase {
  beforeActionsValue: Decimal;
  afterActionsValue: Decimal;
  shouldShowBeforeAndAfter: true;
}

interface GetBeforeAndAfterHideData extends GetBeforeAndAfterDataOutputBase {
  shouldShowBeforeAndAfter: false;
}

export type GetBeforeAndAfterDataOutput =
  | GetBeforeAndAfterShowData
  | GetBeforeAndAfterHideData;

export function getBeforeAndAfterData(
  nodeFragment: BuildTileFromNodeInput['nodeFragment'],
  opts: GetBeforeAndAfterDataOptions
): GetBeforeAndAfterDataOutput {
  const {
    hasHypotheticalTransfer,
    hasIncomingPouroverDisposition,
    hasOutgoingPouroverDisposition,
  } = opts;
  const { value, valueBeforeTransfers, valueBeforePouroverWills } =
    nodeFragment;
  const shouldShowBeforeAndAfterTransfers: boolean =
    (valueBeforeTransfers &&
      !valueBeforeTransfers.eq(value) &&
      hasHypotheticalTransfer) ??
    false;
  const shouldShowBeforeAndAfterPouroverWills: boolean =
    (valueBeforePouroverWills &&
      !valueBeforePouroverWills.eq(value) &&
      (hasIncomingPouroverDisposition || hasOutgoingPouroverDisposition)) ??
    false;

  let beforeActionsValue: Decimal | null = value;
  if (shouldShowBeforeAndAfterPouroverWills) {
    beforeActionsValue = valueBeforePouroverWills ?? null;
  } else if (shouldShowBeforeAndAfterTransfers) {
    beforeActionsValue = valueBeforeTransfers ?? null;
  }

  let afterActionsValue: Decimal | null = null;
  if (beforeActionsValue) {
    afterActionsValue = value;
  }

  if (
    (shouldShowBeforeAndAfterPouroverWills ||
      shouldShowBeforeAndAfterTransfers) &&
    beforeActionsValue &&
    afterActionsValue
  ) {
    return {
      shouldShowBeforeAndAfterPouroverWills,
      shouldShowBeforeAndAfterTransfers,
      beforeActionsValue: beforeActionsValue,
      afterActionsValue: afterActionsValue,
      shouldShowBeforeAndAfter: true,
    };
  }

  return {
    shouldShowBeforeAndAfterPouroverWills,
    shouldShowBeforeAndAfterTransfers,
    beforeActionsValue,
    afterActionsValue,
    shouldShowBeforeAndAfter: false,
  };
}

export function buildTileFromNode({
  nodeFragment,
  visualizationConfig,
  firstDeathGrantor,
  secondDeathGrantor,
  isNewTile,
  hasHypotheticalTransfer,
  hasIncomingPouroverDisposition,
  hasOutgoingPouroverDisposition,
}: BuildTileFromNodeInput): TileNode | null {
  const {
    id,
    nodeConfiguration,
    node,
    afterDeath,
    value,
    inEstateStatus,
    isHypothetical,
  } = nodeFragment;
  if (!isEntityNode(node)) {
    diagnostics.error(`Unhandled tile in estate waterfall`);
    return null;
  }

  const nameOfDeadGrantor = (() => {
    if (afterDeath === AfterDeath.First) {
      return firstDeathGrantor?.displayName;
    } else if (afterDeath === AfterDeath.Second) {
      return secondDeathGrantor?.displayName;
    }
    return undefined;
  })();

  const categorizationType = getCategorizationType(nodeFragment);
  if (!categorizationType) {
    // just return here; this is logged in getCategorizationType
    return null;
  }

  let lineOne = '';
  let lineTwo = '';
  let variant = categorizationTypeToVariant[categorizationType];

  if (inEstateStatus === EntityInEstateStatus.InEstate) {
    variant = TileVariant.Primary;
  }

  if (node.__typename === 'Entity') {
    lineOne = node.subtype.displayName;
    lineTwo = node.extendedDisplayKind;
  } else if (node.__typename === 'TestamentaryEntity') {
    lineOne = node.displayName;
    lineTwo = testamentaryEntityKindToDisplayName(
      node.testamentaryEntityKind,
      nameOfDeadGrantor
    );
  } else if (node.__typename === 'ClientProfile') {
    lineOne = node.displayName;
  } else if (node.__typename === 'ClientOrganization') {
    lineOne = node.name;
    lineTwo = formatEnumCase(node.clientOrganizationKind);
    if (
      node.clientOrganizationKind ===
        ClientOrganizationKind.CharitableOrganization &&
      inEstateStatus !== EntityInEstateStatus.InEstate
    ) {
      variant = TileVariant.Tertiary;
    }
  }

  const nodeId = getNodeId({ id, afterDeath });
  let frameText: string | undefined = undefined;
  if (isHypothetical) {
    frameText = 'Draft entity';
  } else if (hasIncomingPouroverDisposition && hasHypotheticalTransfer) {
    frameText = 'Includes transfers and pour-over provisions';
  } else if (hasHypotheticalTransfer) {
    frameText = 'Includes transfers';
  } else if (hasIncomingPouroverDisposition) {
    frameText = 'Includes pour-over provisions';
  }

  let deathBenefitAmount: Decimal | null = null;

  if (
    node.__typename === 'Entity' &&
    (node.subtype.__typename === 'InsurancePersonalAccount' ||
      node.subtype.__typename === 'ILITTrust') &&
    node.subtype.policies
  ) {
    deathBenefitAmount = sumDecimalJS(
      node.subtype.policies.map(({ deathBenefitAmount }) => deathBenefitAmount)
    );
  }

  let children: ReactNode | null = null;

  /** Value before hypothetical transfers and/or pour-over dispositions are applied */
  const { beforeActionsValue, afterActionsValue, shouldShowBeforeAndAfter } =
    getBeforeAndAfterData(nodeFragment, {
      hasHypotheticalTransfer,
      hasIncomingPouroverDisposition,
      hasOutgoingPouroverDisposition,
    });

  if (shouldShowBeforeAndAfter || deathBenefitAmount) {
    children = (
      <>
        {shouldShowBeforeAndAfter ? (
          <TileBeforeAndAfter
            variant={variant}
            before={formatCurrency(beforeActionsValue, {
              notation: 'compact',
            })}
            after={formatCurrency(afterActionsValue, {
              notation: 'compact',
            })}
          />
        ) : null}
        {deathBenefitAmount ? (
          <TileThemedTypography variant="subtitle2" tileVariant={variant}>
            Death benefit{' '}
            {formatCurrency(deathBenefitAmount, { notation: 'compact' })}
          </TileThemedTypography>
        ) : null}
      </>
    );
  }

  return {
    id: nodeId,
    type: 'tile',
    position: {
      x: nodeConfiguration?.xPosition ?? 0,
      y: nodeConfiguration?.yPosition ?? 0,
    },
    data: {
      lineOne,
      lineTwo,
      lineThree:
        children || visualizationConfig?.hideEntityValues
          ? undefined
          : formatCurrency(value, {
              notation: 'compact',
              currencySign: 'accounting',
            }),
      children,
      variant,
      frameText,
      sectionLabelId: afterDeath,
      isNewTile,
    },
  };
}

function nodeIdToDataId(graph: EstateWaterfallGraph, id: string): string {
  return graph.getNodeAttributes(id).data.id;
}

export function mutateWaterfallWithFlowNodesPositions(
  waterfall: EstateWaterfallFragment,
  reactFlowNodes: Node[],
  waterfallNodeConfigurations: EstateWaterfallFragment['nodeConfigurations']
) {
  // Sync current node positions on the flow chart
  const reactFlowNodesById = keyBy(reactFlowNodes, (n) => n.id);
  const updatedNodeConfigurations = waterfallNodeConfigurations.map(
    (nodeConfig) => {
      const existingNode = reactFlowNodesById[nodeConfig.nodeID];
      if (!existingNode) return nodeConfig;
      return {
        ...nodeConfig,
        xPosition: existingNode.position.x,
        yPosition: existingNode.position.y,
      };
    }
  );
  waterfall.nodeConfigurations = updatedNodeConfigurations;
}

function cloneWaterfallAppendGroupNodes(
  graph: EstateWaterfallGraph,
  groupId: string,
  appendNodes: Node[],
  reactFlowNodes: Node[],
  waterfallNodeConfigurations: EstateWaterfallFragment['nodeConfigurations']
): EstateWaterfallFragment {
  const waterfall = cloneDeep(graph.getAttribute('waterfall'));

  const nodesToBeGrouped = appendNodes.map((n) => n.id);
  const nodesToBeGroupedSet = new Set(nodesToBeGrouped);

  // Mutate the existing nodes to have a parent of the new group
  waterfall.visualizationWithProjections.nodes =
    waterfall.visualizationWithProjections.nodes.map((vizNode) => {
      const nodeId = getNodeId({
        id: vizNode.id,
        afterDeath: vizNode.afterDeath,
      });

      if (!nodesToBeGroupedSet.has(nodeId)) return vizNode;
      return { ...vizNode, group: { id: groupId } };
    });

  // Mutate the existing edges to map from their previous individual
  // relationships to the new group relationship
  waterfall.visualizationWithProjections.edges =
    waterfall.visualizationWithProjections.edges.map((vizEdge) => {
      return {
        ...vizEdge,
        from: nodesToBeGroupedSet.has(getNodeId(vizEdge.from))
          ? { ...vizEdge.from, group: { id: groupId } }
          : vizEdge.from,
        to: nodesToBeGroupedSet.has(getNodeId(vizEdge.to))
          ? { ...vizEdge.to, group: { id: groupId } }
          : vizEdge.to,
      };
    });

  // Find the matching group and update it to include the appended nodes
  waterfall.visualizationGroups.edges =
    waterfall.visualizationGroups.edges?.map((e) => {
      if (e?.node?.id !== groupId) return e;

      const nodesToAppend = nodesToBeGrouped.map((id) => ({
        id: nodeIdToDataId(graph, id),
      }));

      return {
        ...e,
        node: { ...e.node, nodes: [...e.node.nodes, ...nodesToAppend] },
      };
    });

  // Sync current node positions on the flow chart
  mutateWaterfallWithFlowNodesPositions(
    waterfall,
    reactFlowNodes,
    waterfallNodeConfigurations
  );

  return waterfall;
}

export interface CloneWaterfallWithUpsertGroupProps {
  graph: EstateWaterfallGraph;
  groupNode: Node;
  appendNodes: Node[];
  reactFlowNodes: Node[];
  waterfallNodeConfigurations: EstateWaterfallFragment['nodeConfigurations'];
}

/**
 * @description Creates group nodes when they don't yet exist as a UI only group,
 * or adds new nodes to an existing group
 */
export function cloneWaterfallWithUpsertGroup({
  graph,
  groupNode,
  appendNodes,
  reactFlowNodes,
  waterfallNodeConfigurations,
}: CloneWaterfallWithUpsertGroupProps): EstateWaterfallFragment {
  // Simply append if it's already a grouped node
  if (isTileGroupNode(groupNode)) {
    const groupId = graph.getNodeAttributes(groupNode.id).data.id;
    return cloneWaterfallAppendGroupNodes(
      graph,
      groupId,
      appendNodes,
      reactFlowNodes,
      waterfallNodeConfigurations
    );
  }

  // No group exists yet, so include the drop target itself in list of nodes we want to group together
  const nodes = [groupNode, ...appendNodes];
  const afterDeath = graph.getNodeAttributes(groupNode.id).data.afterDeath;

  // Unique id is required for multiple UI only groups being created
  const groupId = uniqueId(UI_ONLY_GROUP_NODE_IDENTIFIER);
  const nodeId = getNodeId({ id: groupId, afterDeath });

  // Create a waterfall and add existing nodes to the new group
  const waterfall = cloneWaterfallAppendGroupNodes(
    graph,
    groupId,
    nodes,
    reactFlowNodes,
    waterfallNodeConfigurations
  );

  // Create a UI only group node with all nodes associated
  waterfall.visualizationGroups.edges = [
    ...(waterfall.visualizationGroups.edges || []),
    {
      node: {
        id: groupId,
        displayName: SHOW_CREATE_GROUP_MODAL_SENTINEL,
        description: '',
        namespace: afterDeath,
        nodes: nodes.map((node) => ({ id: nodeIdToDataId(graph, node.id) })),
      },
    },
  ];

  // Set the position of the group to the position of the first node that was grouped
  waterfall.nodeConfigurations = [
    ...waterfall.nodeConfigurations,
    {
      nodeID: nodeId,
      xPosition: groupNode.position.x,
      yPosition: groupNode.position.y,
    },
  ];

  return waterfall;
}

interface AddTaxEdgeInput {
  graph: EstateWaterfallGraph;
  source: string;
  target: string;
  value: Decimal;
}

export function addTaxEdge({ graph, source, target, value }: AddTaxEdgeInput) {
  const giftTaxEdge = createEdge({
    source,
    target,
    data: {
      variant: 'destructive',
      edgeLabel: {
        variant: 'destructive',
        label: '',
        value: formatCurrency(value, {
          notation: 'compact',
        }),
      },
    },
  });

  graph.addEdgeSafe(source, target, { type: 'tax', edge: giftTaxEdge });
}

export function extractReactFlowNodesAndEdges(graph: EstateWaterfallGraph) {
  const nodes = compact(
    graph.mapNodes((_nodeId, node) => {
      // We don't want to surface nodes that belong to a group as individual nodes in the waterfall,
      // but we do want them to be available on the waterfallGraph for processing relationships
      return getNodeGroupId(node) ? null : node.node;
    })
  );

  const edges = graph.getEdges();

  return { nodes, edges };
}

export function getGroupNodeTileVariant(nodes: EstateWaterfall_NodeFragment[]) {
  if (
    // All nodes are out of estate, the variant is warning (navy)
    nodes.every((node) => node.inEstateStatus === EntityInEstateStatus.InEstate)
  ) {
    return TileVariant.Primary;
  }

  if (
    // All nodes are charitable and out of estate, variant is tertiary (blue)
    nodes.every(
      (vizNode) =>
        vizNode.inEstateStatus === EntityInEstateStatus.OutOfEstate &&
        getIsCharitableNode(vizNode.node)
    )
  ) {
    return TileVariant.Tertiary;
  }

  if (
    // All nodes are family giving (not charitable) and out of estate, variant is secondary (teal)
    nodes.every(
      (node) =>
        node.inEstateStatus === EntityInEstateStatus.OutOfEstate &&
        !getIsCharitableNode(node.node)
    )
  ) {
    return TileVariant.Secondary;
  }

  // Default to group variant (gray)
  return TileVariant.Group;
}
