import { Aidp_ParentAsyncJobWithKgRootFragment } from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/graphql/TriggerAIDPSuggestions.generated';
import { AiOnboarding_KgRootFragment } from '@/modules/aiOnboarding/graphql/aiOnboarding.generated';

export enum AsyncJobNotificationInfoType {
  AI_ONBOARDING = 'AI_ONBOARDING',
  AI_DP = 'AI_DP',
}

export enum AsyncJobNotificationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  // Notifications that are in this stage will be hidden from the UI.
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export interface AsyncJobNotificationInfo {
  // id is a pulid, depending on the type
  id: string;
  type: AsyncJobNotificationInfoType;
  status: AsyncJobNotificationStatus;
  estimatedDurationMs: number;
  household: AiOnboarding_KgRootFragment['household'] | null | undefined;
  asyncJob: Aidp_ParentAsyncJobWithKgRootFragment;
  // Message to show in the notification popup when the job is added
  newNotificationMessage: string;
  // Message to show in the notification popup when the job is completed
  completedNotificationMessage: string;
  // Message to show in the banner when the job is completed
  formatBannerMessage: (bannerNoun: string) => string;
  // The display name of the target of the notification, like the entity or household
  notificationTargetDisplayName: string;
}
