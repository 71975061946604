import { useApolloClient } from '@apollo/client';
import { compact, groupBy, isEmpty, keyBy, startCase, toLower } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  AI_ONBOARDING_MODAL_STEP_CONFIGS,
  AI_ONBOARDING_MODAL_SUGGESTION_FORM_KEY_MAP,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.constants';
import {
  AIOnboardingModalProviderProps,
  AIOnboardingModalStep,
  AIOnboardingModalSuggestionKinds,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { getNodeNameFromSuggestion } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.utils';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { formDataToInput } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.utils';
import { AIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModalBulkCreateMutationVariables,
  useAiOnboardingModalBulkCreateMutation,
  useEndAiOnboardingWorkflowMutation,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { useTriggerAIOnboardingWorkflow } from '@/modules/aiOnboarding/hooks/useTriggerAIOnboardingWorkflow';
import { getSuggestionAsSearchQuery } from '@/modules/aiSuggestions/aiSuggestions.utils';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { useMultiDocumentSearch } from '@/modules/documents/hooks/useMultiDocumentSearch';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { AiSuggestionAcceptanceStatus, KgRootStatus } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

function useContextValue(
  props: AIOnboardingModalProviderProps
): AIOnboardingModalContext {
  const { loading, onClose, suggestions, household, kgRootId, documentIds } =
    props;

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { removeNotifications } = useAsyncJobNotificationsContext();
  const { triggerWorkflow } = useTriggerAIOnboardingWorkflow();
  const { uploadedFiles, setUploaderErrorMessage } =
    useMultiDocumentUploaderContext();
  const { doMultiDocSearch } = useMultiDocumentSearch();

  // We are tracking our own submission state instead of using the one from the
  // formContext, because for the NO_SUGGESTIONS step, we upload documents and
  // trigger a new onboarding workflow, instead of a real form submission.
  const [isSubmitting, setIsSubmitting] = useState(false);

  // This is used to track the steps that have been submitted so far since the
  // modal has been opened. Mainly, this is used to let users know that if they
  // cancel the workflow from steps 2-n, the things they created from previous
  // steps will remain.
  const [submittedSteps, setSubmittedSteps] = useState<AIOnboardingModalStep[]>(
    []
  );

  const [bulkCreateMutation] = useAiOnboardingModalBulkCreateMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError('error in bulkCreateMutation from AISuggestionsModal', error);
      setIsSubmitting(false);
    },
  });

  const [endWorkflowMutation] = useEndAiOnboardingWorkflowMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(
        'error ending ai onboarding workflow from AISuggestionsModal',
        error
      );
      setIsSubmitting(false);
    },
  });

  const { handleSubmit } = useFormContext<AIOnboardingModalFormShape>();

  const [activeStep, setActiveStep] =
    useState<AIOnboardingModalContext['activeStep']>(null);

  const [suggestionsByFormPath, setSuggestionsByFormPath] = useState<
    AIOnboardingModalContext['suggestionsByFormPath']
  >({} as AIOnboardingModalContext['suggestionsByFormPath']);

  const [suggestionsByID, setSuggestionsByID] = useState<
    AIOnboardingModalContext['suggestionsByID']
  >({});

  const [documentPanelProps, setDocumentPanelProps] = useState<
    AIOnboardingModalContext['documentPanelProps']
  >({
    activeDocumentViewerTab: 'document',
    activeDocumentId: '',
    searchQuery: '',
    annotations: undefined,
    searchLoading: false,
  });

  const [rejectedSuggestionIDs, setRejectedSuggestionIDs] = useState<string[]>(
    []
  );

  useEffect(() => {
    setSuggestionsByFormPath((prev) => ({
      ...prev,
      ...groupSuggestionsByFormPath(suggestions),
    }));

    setSuggestionsByID(keyBy(suggestions, 'id'));
  }, [documentIds, suggestions]);

  useEffect(() => {
    if (loading) return;

    if (!loading && isEmpty(suggestionsByFormPath)) {
      setActiveStep(AIOnboardingModalStep.NO_SUGGESTIONS);
      return;
    }

    if (
      suggestionsByFormPath.individuals.length > 0 ||
      suggestionsByFormPath.organizations.length > 0
    ) {
      setActiveStep(AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS);
      return;
    }
    if (
      suggestionsByFormPath.entities.length > 0 ||
      suggestionsByFormPath.testamentaryEntities.length > 0
    ) {
      setActiveStep(AIOnboardingModalStep.ENTITIES);
      return;
    }

    setActiveStep(AIOnboardingModalStep.NO_SUGGESTIONS);
  }, [loading, suggestionsByFormPath]);

  const addRejectedSuggestion = useCallback(
    (suggestionID: string | undefined) => {
      if (!suggestionID) {
        diagnostics.warn(
          'Trying to add rejected suggestion but suggestionID is undefined'
        );
        return;
      }
      setRejectedSuggestionIDs((prev) => [...prev, suggestionID]);
    },
    []
  );

  const handleSearchEvidence = useCallback(
    async (suggestionID: string | undefined) => {
      const suggestion = suggestionsByID[suggestionID || ''];
      if (!suggestionID || !suggestion) {
        // This shouldn't happen. Just here for type-safety.
        diagnostics.warn(
          'Trying to search for suggestion evidence but suggestion is undefined'
        );
        return;
      }

      const documentIds = compact(
        suggestion.kgNode?.mergedFrom?.map((n) => n.file?.document?.id)
      );

      setDocumentPanelProps({
        activeDocumentViewerTab: 'document',
        // Don't change the active document in the viewer until the search results come back
        activeDocumentId: undefined,
        searchQuery: getSuggestionAsSearchQuery(suggestion),
        annotations: undefined,
        searchLoading: true,
      });

      // Use the node name to search for the suggestion's evidence in the documents
      const nodeName = getNodeNameFromSuggestion(suggestion);

      try {
        const boundingRegions = await doMultiDocSearch(
          startCase(toLower(nodeName)),
          documentIds
        );

        setDocumentPanelProps((prev) => ({
          ...prev,
          activeDocumentId: boundingRegions[0]?.documentID,
          annotations: boundingRegions,
          searchLoading: false,
        }));
      } catch (e) {
        showFeedback(
          'Unable to locate evidence for this suggestion. Please try again later.'
        );
        reportError('error in multi-doc evidence search', e as Error);
      } finally {
        setDocumentPanelProps((prev) => ({
          ...prev,
          searchLoading: false,
        }));
      }
    },
    [doMultiDocSearch, reportError, showFeedback, suggestionsByID]
  );

  const submitBulkCreateMutation = useCallback(
    async (
      input: AiOnboardingModalBulkCreateMutationVariables,
      isLastStep: boolean
    ) => {
      if (!activeStep) return;

      const { nextStep, refetchQueries, navigateIfLast } =
        AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep];

      return await bulkCreateMutation({
        variables: {
          ...input,
        },
        onCompleted: async () => {
          showFeedback('Successfully created', { variant: 'success' });
          setIsSubmitting(false);

          if (activeStep === AIOnboardingModalStep.ENTITIES) {
            await client.refetchQueries({
              updateCache(cache) {
                cache.evict({ fieldName: 'entities' });
                cache.gc();
              },
            });
          }

          if (isLastStep || !nextStep) {
            onClose();
            removeNotifications([kgRootId]);
            if (navigateIfLast) {
              navigate(
                getCompletePathFromRouteKey(navigateIfLast, {
                  householdId: household.id,
                })
              );
            }
            return;
          }

          setSubmittedSteps((prev) => [...prev, activeStep]);
          setActiveStep(nextStep);
          setRejectedSuggestionIDs([]);
        },
        // Refetch any queries that need to be updated after submitting the step.
        refetchQueries,
      });
    },
    [
      activeStep,
      bulkCreateMutation,
      client,
      household.id,
      kgRootId,
      navigate,
      onClose,
      removeNotifications,
      showFeedback,
    ]
  );

  const onValidSubmission = useCallback(
    async (formData: AIOnboardingModalFormShape) => {
      if (!activeStep) return;

      const formPaths = AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep].formPaths;

      // The data in input and acceptedSuggestionIDs only contains the fields
      // that are in the given formPaths.
      const { input, acceptedSuggestionIDs } = formDataToInput(
        formData,
        formPaths,
        household,
        suggestionsByID
      );

      const isLastStep = getIsLastStep(activeStep, suggestionsByFormPath);

      const fullInput: AiOnboardingModalBulkCreateMutationVariables = {
        ...input,
        acknowledgeSuggestionsInput: [
          ...acceptedSuggestionIDs.map((id) => ({
            suggestionID: id,
            status: AiSuggestionAcceptanceStatus.Accepted,
          })),
          ...rejectedSuggestionIDs.map((id) => ({
            suggestionID: id,
            status: AiSuggestionAcceptanceStatus.Rejected,
          })),
        ],
        updateKGRootInput: {
          id: kgRootId,
          update: {
            status: isLastStep
              ? KgRootStatus.SuggestionReviewComplete
              : KgRootStatus.Complete,
          },
        },
      };

      return submitBulkCreateMutation(fullInput, isLastStep);
    },
    [
      activeStep,
      household,
      kgRootId,
      rejectedSuggestionIDs,
      submitBulkCreateMutation,
      suggestionsByFormPath,
      suggestionsByID,
    ]
  );

  const onSubmit = useCallback(async () => {
    if (activeStep === AIOnboardingModalStep.NO_SUGGESTIONS) {
      if (isEmpty(uploadedFiles)) {
        setUploaderErrorMessage('Please upload a document to continue');
        return;
      }

      // If we're submitting the modal from the NO_SUGGESTIONS step, we will
      // trigger a new onboarding workflow and remove the current notification.
      setIsSubmitting(true);
      await triggerWorkflow(household.id, () => {
        removeNotifications([kgRootId]);
        onClose();
      });
      return;
    }

    // Otherwise, proceed to the form validation and submission.
    setIsSubmitting(true);
    void handleSubmit(
      (values) => {
        return onValidSubmission(values);
      },
      () => {
        setIsSubmitting(false);
      }
    )();
  }, [
    activeStep,
    handleSubmit,
    household.id,
    kgRootId,
    onClose,
    onValidSubmission,
    removeNotifications,
    setUploaderErrorMessage,
    triggerWorkflow,
    uploadedFiles,
  ]);

  const endWorkflow = useCallback(
    async (shouldDeleteDocuments: boolean) => {
      await endWorkflowMutation({
        variables: {
          updateKGRootInput: {
            id: kgRootId,
            update: {
              status: KgRootStatus.SuggestionReviewComplete,
            },
          },
          deleteDocumentIds: shouldDeleteDocuments ? documentIds : [],
        },
        onCompleted: async () => {
          if (shouldDeleteDocuments) {
            await client.refetchQueries({
              updateCache(cache) {
                cache.evict({ fieldName: 'documents' });
                cache.gc();
              },
            });
          }
          removeNotifications([kgRootId]);
          showFeedback('Successfully ended workflow', { variant: 'success' });
          onClose();
        },
      });
    },
    [
      client,
      documentIds,
      endWorkflowMutation,
      kgRootId,
      onClose,
      removeNotifications,
      showFeedback,
    ]
  );

  return {
    ...props,
    activeStep,
    setActiveStep,
    submittedSteps,
    suggestionsByFormPath,
    suggestionsByID,
    documentPanelProps,
    setDocumentPanelProps,
    handleSearchEvidence,
    addRejectedSuggestion,
    isSubmitting,
    onSubmit,
    endWorkflow,
  };
}

export function AIOnboardingModalProvider({
  children,
  ...props
}: PropsWithChildren<AIOnboardingModalProviderProps>) {
  const value = useContextValue(props);

  return (
    <AIOnboardingModalContext.Provider value={value}>
      {children}
    </AIOnboardingModalContext.Provider>
  );
}

function groupSuggestionsByFormPath(
  suggestions: AiOnboardingModal_AiSuggestionFragment[]
): AIOnboardingModalContext['suggestionsByFormPath'] {
  if (isEmpty(suggestions)) {
    return {} as AIOnboardingModalContext['suggestionsByFormPath'];
  }

  const filtered = suggestions.filter(
    (s) => s.kind in AI_ONBOARDING_MODAL_SUGGESTION_FORM_KEY_MAP
  );

  const grouped = groupBy(filtered, (s) => {
    return AI_ONBOARDING_MODAL_SUGGESTION_FORM_KEY_MAP[
      s.kind as AIOnboardingModalSuggestionKinds
    ];
  });

  return {
    individuals: grouped.individuals || [],
    organizations: grouped.organizations || [],
    entities: grouped.entities || [],
    testamentaryEntities: grouped.testamentaryEntities || [],
  };
}

function getIsLastStep(
  activeStep: AIOnboardingModalStep,
  suggestionsByFormPath: AIOnboardingModalContext['suggestionsByFormPath']
): boolean {
  const nextStep = AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep].nextStep;
  if (!nextStep) {
    return true;
  }

  const nextStepFormsPaths =
    AI_ONBOARDING_MODAL_STEP_CONFIGS[nextStep].formPaths;

  return nextStepFormsPaths.every(
    (formPath) => suggestionsByFormPath[formPath].length === 0
  );
}
