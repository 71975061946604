import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { EntityInEstateStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { CustomEntityGrowthCustomizationType } from '../GrowthProfileModal/GrowthProfileModal.types';
import { ENTITY_CUSTOM_GROWTH_OPTIONS } from './CustomEntityGrowthModal.constants';
import {
  CustomEntityGrowthFormPaths,
  CustomEntityGrowthFormShape,
} from './CustomEntityGrowthModal.types';
import { CustomEntityGrowthModal_EntityFragment } from './graphql/CustomEntityGrowthModal.generated';

export interface CustomEntityGrowthModalBodyProps {
  entities: CustomEntityGrowthModal_EntityFragment[];
  currentOverrideIds: string[];
  entityId?: string;
}

export function CustomEntityGrowthModalBody(
  props: CustomEntityGrowthModalBodyProps
) {
  return (
    <Stack direction="column" spacing={2}>
      <EntityDropdown {...props} />
      <CustomizationType />
      <CustomizationDetails />
    </Stack>
  );
}

function mapEntityOption(
  entity: CustomEntityGrowthModal_EntityFragment
): TypeaheadSelectInputOption<string> {
  let groupName: string | undefined;

  if (entity.subtype?.__typename?.includes('BusinessEntity')) {
    groupName = 'Business';
  } else if (
    // all non-biz entities should have an inEstateStatus field
    'inEstateStatus' in entity.subtype &&
    entity.subtype.inEstateStatus === EntityInEstateStatus.InEstate
  ) {
    groupName = 'In estate';
  } else {
    groupName = 'Out of estate';
  }

  return {
    display: entity.subtype?.displayName,
    value: entity.id,
    groupName,
    endLabel: entity.subtype.currentValue
      ? formatCurrencyNoDecimals(entity.subtype.currentValue)
      : EMPTY_CONTENT_HYPHEN,
  };
}

function reduceEntities(
  entities: CustomEntityGrowthModal_EntityFragment[],
  currentEntityIds: string[],
  entityId: string | undefined
): TypeaheadSelectInputOption<string>[] {
  const { inEstateEntities, outOfEstateEntities, bizEntities } = entities
    .filter(
      (entity) =>
        !currentEntityIds.includes(entity.id) || entityId === entity.id
    )
    .reduce<{
      inEstateEntities: TypeaheadSelectInputOption<string>[];
      outOfEstateEntities: TypeaheadSelectInputOption<string>[];
      bizEntities: TypeaheadSelectInputOption<string>[];
    }>(
      (acc, entity) => {
        if (entity.subtype?.__typename?.includes('BusinessEntity')) {
          acc.bizEntities.push(mapEntityOption(entity));
        } else if (
          'inEstateStatus' in entity.subtype &&
          entity.subtype.inEstateStatus === EntityInEstateStatus.InEstate
        ) {
          acc.inEstateEntities.push(mapEntityOption(entity));
        } else {
          acc.outOfEstateEntities.push(mapEntityOption(entity));
        }
        return acc;
      },
      {
        inEstateEntities: [],
        outOfEstateEntities: [],
        bizEntities: [],
      }
    );

  return [
    ...inEstateEntities.sort((a, b) => a.display.localeCompare(b.display)),
    ...outOfEstateEntities.sort((a, b) => a.display.localeCompare(b.display)),
    ...bizEntities.sort((a, b) => a.display.localeCompare(b.display)),
  ];
}

function EntityDropdown({
  entities,
  currentOverrideIds: currentEntityIDs,
  entityId,
}: CustomEntityGrowthModalBodyProps) {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();
  const entityOptions = useMemo<TypeaheadSelectInputOption<string>[]>(
    () => reduceEntities(entities, currentEntityIDs, entityId),
    [currentEntityIDs, entities, entityId]
  );

  return (
    <FormAwareTypeaheadSelectInput<CustomEntityGrowthFormShape>
      control={control}
      fieldName={'entityId' as const satisfies CustomEntityGrowthFormPaths}
      label="Entity"
      options={entityOptions}
      required
      groupBy={(option) => option.groupName ?? ''}
    />
  );
}

function CustomizationType() {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();

  const isEWV2EntityFutureValuationEnabled = useFeatureFlag(
    'ew_v2_entity_future_valuation'
  );

  if (!isEWV2EntityFutureValuationEnabled) {
    return null;
  }

  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <Stack spacing={1.5}>
        <Typography variant="h4">Specify customization</Typography>
        <FormAwareRadioGroup<CustomEntityGrowthFormShape>
          control={control}
          fieldName="customizationType"
          options={ENTITY_CUSTOM_GROWTH_OPTIONS}
          label="Specify customization"
          required
          hideLabel
          row={false}
        />
      </Stack>
    </Card>
  );
}

function CustomizationDetails() {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();
  const [customizationType] = useWatch({
    control,
    name: ['customizationType'],
  });

  switch (customizationType) {
    case CustomEntityGrowthCustomizationType.None:
      return null;
    case CustomEntityGrowthCustomizationType.GrowthRate:
      return <CustomGrowthRate />;
    case CustomEntityGrowthCustomizationType.FutureValuation:
      return <FutureValuationAtYear />;
    case CustomEntityGrowthCustomizationType.GrowthRateAndFutureValuation:
      return (
        <>
          <FutureValuationAtYear />
          <FutureValuationSplitGrowthRates />
        </>
      );
    default: {
      throw new UnreachableError({
        case: customizationType,
        message: `Invalid customization type ${customizationType}`,
      });
    }
  }
}

function CustomGrowthRate() {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();

  return (
    <FormLayoutRow>
      <FormLayoutItem width={4}>
        <FormAwarePercentInput<CustomEntityGrowthFormShape>
          control={control}
          fieldName={
            'customGrowthRate' as const satisfies CustomEntityGrowthFormPaths
          }
          label="Custom growth rate"
          required
          isDecimalJSInput
          fixedDecimalScale
          decimalScale={2}
        />
      </FormLayoutItem>
    </FormLayoutRow>
  );
}

function FutureValuationAtYear() {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();

  return (
    <FormLayoutRow>
      <FormLayoutItem width={12}>
        <Stack direction="row" spacing={1}>
          <FormAwareCurrencyInput<CustomEntityGrowthFormShape>
            control={control}
            fieldName={
              'entityFutureValuationValue' as const satisfies CustomEntityGrowthFormPaths
            }
            label="Future valuation"
            required
            isDecimalJSInput
          />
          <Typography variant="label" sx={{ pt: 4 }}>
            at year
          </Typography>
          <Box sx={{ pt: 3 }} width={68}>
            {/* width: 68 taken from the figma */}
            <FormAwareFormattedNumberInput<CustomEntityGrowthFormShape>
              control={control}
              fieldName={
                'entityFutureValuationYear' as const satisfies CustomEntityGrowthFormPaths
              }
              label="at year"
              hideLabel
              required
            />
          </Box>
        </Stack>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}

function FutureValuationSplitGrowthRates() {
  const { control } = useFormContext<CustomEntityGrowthFormShape>();

  return (
    <FormLayoutRow>
      <FormLayoutItem width={12}>
        <Stack direction="row" spacing={3}>
          <FormAwarePercentInput<CustomEntityGrowthFormShape>
            control={control}
            fieldName={
              'entityGrowthBeforeFutureValuation' as const satisfies CustomEntityGrowthFormPaths
            }
            label="Growth before valuation"
            required
            isDecimalJSInput
          />
          <FormAwarePercentInput<CustomEntityGrowthFormShape>
            control={control}
            fieldName={
              'entityGrowthAfterFutureValuation' as const satisfies CustomEntityGrowthFormPaths
            }
            label="Growth after valuation"
            required
            isDecimalJSInput
          />
        </Stack>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}
