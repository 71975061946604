import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ClientPresentationDesigner } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/ClientPresentationDesigner';

export function ClientDetailsPresentationPage() {
  const householdId = useRequiredParam('householdId');
  const presentationId = useRequiredParam('presentationId');

  const props = {
    householdId,
    presentationId,
  };

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesigner {...props} />
    </HouseholdDetailsAwareRoute>
  );
}
