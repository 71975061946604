import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { getOptionsWithGroupLabels } from '@/components/form/baseInputs/SelectInput/SelectInput.utils';
import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { useAiSuggestionsMatcherContext } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import {
  useRecipientOptions,
  useRecipientOptionsParams,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/hooks/useRecipientOptions';
import { useFetchBusinessEntityDetailsOptions } from '@/modules/entities/BusinessEntityDetailsSubform/useGetBusinessEntityOwnerOptions';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AiSuggestionKind } from '@/types/schema';

interface UseAISuggestionsMatcherOptionsProps {
  matcherVariant: AISuggestionsMatcherVariant;
  suggestionKind: AiSuggestionKind;
}

/**
 * Returns the grouped options to match AI suggestions to existing clients/organizations/entities.
 *
 * @param matcherVariant
 * @param suggestionKind
 */
export function useAISuggestionsMatcherGroupedOptions({
  matcherVariant,
  suggestionKind,
}: UseAISuggestionsMatcherOptionsProps) {
  const {
    householdId,
    possibleGrantors,
    possibleTrustees,
    possibleBeneficiaries,
  } = useHouseholdDetailsContext();

  const { entityId } = useEntityDetailsContext();

  const { matchedSuggestions } = useAiSuggestionsMatcherContext();

  const { matchedOwnerIds, matchedKeyPersonIds } = useMemo(() => {
    return {
      matchedOwnerIds: Object.values(matchedSuggestions)
        .filter((m) => m.suggestion.kind === AiSuggestionKind.BeneficialOwner)
        .map(
          (m) => m.clientProfileId || m.clientOrganizationId || m.entityId || ''
        )
        .filter(Boolean),
      matchedKeyPersonIds: Object.values(matchedSuggestions)
        .filter((m) => m.suggestion.kind === AiSuggestionKind.KeyPerson)
        .map(
          (m) => m.clientProfileId || m.clientOrganizationId || m.entityId || ''
        )
        .filter(Boolean),
    };
  }, [matchedSuggestions]);

  const { ownerOptions, keyPeopleOptions } =
    useFetchBusinessEntityDetailsOptions(
      householdId ?? '',
      entityId ?? null,
      matchedOwnerIds,
      matchedKeyPersonIds
    );

  // Client profiles/organizations that are already on luminary to match suggestions to.
  const existingOptions = useMemo(() => {
    switch (matcherVariant) {
      case AISuggestionsMatcherVariant.GRANTORS:
      case AISuggestionsMatcherVariant.SINGLE_GRANTOR:
        return {
          clients: (possibleGrantors ?? []).map((g) => ({
            id: g.id,
            name: g.legalName,
          })),
          // Grantors cannot be organizations.
          organizations: [],
        };
      case AISuggestionsMatcherVariant.TRUSTEES:
        return possibleTrustees ?? { clients: [], organizations: [] };
      case AISuggestionsMatcherVariant.BENEFICIARIES_V2:
        return possibleBeneficiaries ?? { clients: [], organizations: [] };
      default:
        // For variants such as BusinessEntity-related people, and DP Recipients,
        // we have other ways to get the options. So just return empty arrays here.
        return { clients: [], organizations: [] };
    }
  }, [
    matcherVariant,
    possibleBeneficiaries,
    possibleGrantors,
    possibleTrustees,
  ]);

  // These options are returned for grantor/trustee/beneficiary/key person/owner
  // suggestions matcher dropdown options.
  const groupedOptions = useMemo(() => {
    if (suggestionKind == AiSuggestionKind.BeneficialOwner) {
      return ownerOptions;
    }

    if (suggestionKind == AiSuggestionKind.KeyPerson) {
      return keyPeopleOptions;
    }

    const individualOptions = sortBy(existingOptions.clients, 'name').map(
      (o) => ({
        value: o.id,
        display: o.name,
        disabled: Object.values(matchedSuggestions).some(
          (m) => m.clientProfileId === o.id
        ),
      })
    );

    const organizationOptions = sortBy(
      existingOptions.organizations,
      'name'
    ).map((o) => ({
      value: o.id,
      display: o.name,
      disabled: Object.values(matchedSuggestions).some(
        (m) => m.clientOrganizationId === o.id
      ),
    }));

    return getOptionsWithGroupLabels([
      {
        label: 'Individuals',
        options: individualOptions,
      },
      {
        label: 'Organizations',
        options: organizationOptions,
      },
    ]);
  }, [
    suggestionKind,
    existingOptions.clients,
    existingOptions.organizations,
    ownerOptions,
    keyPeopleOptions,
    matchedSuggestions,
  ]);

  // For the DP Recipients variant, get the options from the DP Recipient hook.
  const dpRecipientOptionParams = useRecipientOptionsParams();
  const { options: dpRecipientOptions } = useRecipientOptions(
    dpRecipientOptionParams
  );
  if (
    matcherVariant ===
      AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_1_DIES_FIRST ||
    matcherVariant ===
      AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_2_DIES_FIRST
  ) {
    return dpRecipientOptions;
  }

  return groupedOptions;
}
