import { compact } from 'lodash';

import {
  CommonAiSuggestionActorFragment,
  CommonAiSuggestionFragment,
  DpRecipientSuggestionFragment,
} from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { ClientProfileNameFields } from '@/modules/clientProfiles/ClientProfile.types';
import { AiSuggestionKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getPulidKind, PulidKind } from '@/utils/pulid';

export function getSuggestedActorDisplayName(
  suggestion: CommonAiSuggestionFragment
): string {
  switch (suggestion.kind) {
    case AiSuggestionKind.Beneficiary:
      return formatSuggestedPersonDisplayName(suggestion.beneficiary);
    case AiSuggestionKind.BeneficiaryV2:
      return formatSuggestedPersonDisplayName(suggestion.beneficiaryV2);
    case AiSuggestionKind.Principal:
      return formatSuggestedPersonDisplayName(suggestion.principal);
    case AiSuggestionKind.TrustAdvisor:
      return formatSuggestedPersonDisplayName(suggestion.trustAdvisor);
    case AiSuggestionKind.Trustee:
      return formatSuggestedPersonDisplayName(suggestion.trustee);
    case AiSuggestionKind.BeneficialOwner:
      return formatSuggestedPersonDisplayName(suggestion.beneficialOwner);
    case AiSuggestionKind.KeyPerson:
      return formatSuggestedPersonDisplayName(suggestion.keyPerson);
    case AiSuggestionKind.DpRecipientGrantor_1DiesFirst:
    case AiSuggestionKind.DpRecipientGrantor_2DiesFirst:
      return formatSuggestedDPRecipientDisplayName(suggestion.dpRecipient);
    default:
      return `Unknown suggestion of type ${suggestion.kind}`;
  }
}

export function formatSuggestedPersonDisplayName(
  actor:
    | {
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        suffix?: string | null;
        organizationName?: string | null;
      }
    | undefined
    | null
): string {
  if (!actor) {
    const msg = 'Actor is undefined or null';
    diagnostics.error(msg, new Error(msg));
    return 'Unknown suggestion';
  }

  if (actor?.organizationName) {
    return actor.organizationName;
  }

  return compact([
    actor.firstName,
    actor.middleName,
    actor.lastName,
    actor.suffix,
  ]).join(' ');
}

function formatSuggestedDPRecipientDisplayName(
  dpRecipient: DpRecipientSuggestionFragment | undefined | null
) {
  if (dpRecipient?.recipientEntityName) {
    return dpRecipient.recipientEntityName;
  }

  if (dpRecipient?.recipientOrganizationName) {
    return dpRecipient.recipientOrganizationName;
  }

  if (!dpRecipient?.recipientNameParts) {
    diagnostics.error(
      "DP Recipient Suggestion doesn't have entity, org, or person name"
    );
    return 'Unknown DP Recipient Name';
  }

  return compact([
    dpRecipient?.recipientNameParts?.firstName,
    dpRecipient?.recipientNameParts?.middleName,
    dpRecipient?.recipientNameParts?.lastName,
    dpRecipient?.recipientNameParts?.suffix,
  ]).join(' ');
}

const DEFAULT_EMPTY_NAME: {
  initialClientProfileName: ClientProfileNameFields;
  initialClientOrganizationName: string;
  initialEntityName: string;
} = {
  initialClientProfileName: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
  },
  initialClientOrganizationName: '',
  initialEntityName: '',
};

export function addNewAnythingFromSuggestionName(
  suggestion: CommonAiSuggestionFragment
): {
  initialClientProfileName: ClientProfileNameFields;
  initialClientOrganizationName: string;
  initialEntityName: string;
} {
  // Return the initial name values for the addNewAnything modal from the
  // suggested DP Recipient's name parts.
  if (
    suggestion.kind === AiSuggestionKind.DpRecipientGrantor_1DiesFirst ||
    suggestion.kind === AiSuggestionKind.DpRecipientGrantor_2DiesFirst
  ) {
    const dpRecipient = suggestion.dpRecipient;
    if (!dpRecipient) {
      return DEFAULT_EMPTY_NAME;
    }

    return {
      initialClientProfileName: {
        firstName: dpRecipient.recipientNameParts?.firstName ?? '',
        middleName: dpRecipient.recipientNameParts?.middleName ?? '',
        lastName: dpRecipient.recipientNameParts?.lastName ?? '',
        suffix: dpRecipient.recipientNameParts?.suffix ?? '',
      },
      initialClientOrganizationName:
        dpRecipient.recipientOrganizationName ?? '',
      initialEntityName: dpRecipient.recipientEntityName ?? '',
    };
  }

  // Return the initial name values for the addNewAnything modal from the
  // suggested actor's name parts.

  let actor: CommonAiSuggestionActorFragment | null | undefined = null;

  switch (suggestion.kind) {
    case AiSuggestionKind.BeneficiaryV2:
      actor = suggestion.beneficiaryV2;
      break;
    case AiSuggestionKind.Principal:
      actor = suggestion.principal;
      break;
    case AiSuggestionKind.TrustAdvisor:
      actor = suggestion.trustAdvisor;
      break;
    case AiSuggestionKind.Trustee:
      actor = suggestion.trustee;
      break;
    case AiSuggestionKind.BeneficialOwner:
      actor = suggestion.beneficialOwner;
      break;
    case AiSuggestionKind.KeyPerson:
      actor = suggestion.keyPerson;
      break;
    default:
      return DEFAULT_EMPTY_NAME;
  }

  if (!actor) {
    return DEFAULT_EMPTY_NAME;
  }

  const { firstName, middleName, lastName, suffix } = actor;

  return {
    initialClientProfileName: {
      firstName: firstName ?? '',
      middleName: middleName ?? '',
      lastName: lastName ?? '',
      suffix: suffix ?? '',
    },
    initialClientOrganizationName: actor.organizationName ?? '',
    initialEntityName: actor.organizationName ?? '',
  };
}

export function getMatchFromNewId(
  newId: string
):
  | { clientProfileId: string }
  | { clientOrganizationId: string }
  | { entityId: string }
  | { testamentaryId: string }
  | null {
  const selectedPulidKind = getPulidKind(newId);

  if (selectedPulidKind === PulidKind.ClientProfile) {
    return {
      clientProfileId: newId,
    };
  }

  if (selectedPulidKind === PulidKind.ClientOrganization) {
    return {
      clientOrganizationId: newId,
    };
  }

  if (selectedPulidKind === PulidKind.Entity) {
    return {
      entityId: newId,
    };
  }

  if (selectedPulidKind === PulidKind.TestamentaryEntity) {
    return {
      testamentaryId: newId,
    };
  }

  return null;
}

export function getExistingIDSuggestionMatch(
  suggestion: CommonAiSuggestionFragment
): string | undefined | null {
  switch (suggestion.kind) {
    case AiSuggestionKind.BeneficiaryV2:
      return suggestion.beneficiaryV2?.existingID;
    case AiSuggestionKind.Principal:
      return suggestion.principal?.existingID;
    case AiSuggestionKind.TrustAdvisor:
      return suggestion.trustAdvisor?.existingID;
    case AiSuggestionKind.Trustee:
      return suggestion.trustee?.existingID;
    case AiSuggestionKind.BeneficialOwner:
      return suggestion.beneficialOwner?.existingID;
    case AiSuggestionKind.KeyPerson:
      return suggestion.keyPerson?.existingID;
    case AiSuggestionKind.DpRecipientGrantor_1DiesFirst:
    case AiSuggestionKind.DpRecipientGrantor_2DiesFirst:
      return suggestion.dpRecipient?.recipientID;
    default:
      return undefined;
  }
}
