import { Box, Stack } from '@mui/material';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { mapWaterfallToAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EditEstateWaterfallAssumptions.utils';
import { EstateWaterfallAssumptionsAccordion } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsAccordion';
import { EditEstateWaterfallAssumptionsUpdatingOverlay } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdatingOverlay';
import {
  useQueryEstateWaterfallAssumptionsQuery,
  useUpdateEstateWaterfallAssumptionsMutation,
} from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/graphql/EstateWaterfallAssumptions.generated';
import { EstateWaterfallAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/types';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { UpdateEstateWaterfallInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useEstateWaterfallCalloutCopy } from '../../hooks/useEstateWaterfallCalloutCopy';
import { DEFAULT_ASSUMPTIONS } from './constants';
import { getAssumptionsPreviewCopy } from './EstateWaterfallAssumptionsUpdater.utils';

interface EditEstateWaterfallAssumptionsFormProps {
  loading: boolean;
  waterfallId: string;
  isTwoGrantor?: boolean;
  variant: 'edit' | 'readOnly'; // Edit variant will trigger mutation and onSubmit callback on submit, readOnly variant will only trigger callback
  onSubmit?: (form: EstateWaterfallAssumptionFields) => Promise<unknown>; // The return type of the promise isn't meaningful to this component
  assumptionsHelpCopy: string;
}

function getUpdateFromFormData(
  formData: EstateWaterfallAssumptionFields
): UpdateEstateWaterfallInput {
  const update: UpdateEstateWaterfallInput = {
    exemptionGrowthRate: formData.exemptionGrowthRate,
    willExemptionSunset: formData.willExemptionSunset,
    firstGrantorDeathYear: formData.firstGrantorDeathYear,
    secondGrantorDeathYear: formData.secondGrantorDeathYear,
  };

  // is custom growth flag isn't yet enabled, we always want to set this property
  if (formData.useCustomGlobalGrowthRate) {
    update.assetGrowthReturn = formData.assetGrowthReturn;
  } else {
    update.clearAssetGrowthReturn = true;
  }

  return update;
}

const EditEstateWaterfallAssumptionsForm = ({
  waterfallId,
  loading,
  isTwoGrantor = false,
  variant,
  onSubmit: onSubmitExternal,
  assumptionsHelpCopy,
}: EditEstateWaterfallAssumptionsFormProps) => {
  const { showFeedback } = useFeedback();
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
    control,
  } = useFormContext<EstateWaterfallAssumptionFields>();

  const [updateMutation] = useUpdateEstateWaterfallAssumptionsMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(
        `Could not update estate waterfall assumptions`,
        error,
        {
          id: waterfallId,
        }
      );
    },
  });

  const onSubmit = handleSubmit((formData) => {
    const submitFn = async (formData: EstateWaterfallAssumptionFields) => {
      try {
        if (variant === 'edit') {
          const update = getUpdateFromFormData(formData);
          await updateMutation({
            variables: {
              input: { id: waterfallId, update: update },
            },
          });
        }
        await onSubmitExternal?.(formData);
      } catch (error) {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(
          `Could not update estate waterfall assumptions`,
          error as Error,
          {
            id: waterfallId,
          }
        );
      }
    };

    return submitFn(formData);
  });

  const submitButton = useMemo(
    () => (
      <Button
        type="submit"
        size="sm"
        variant="primary"
        // when updating, an overlay will be shown instead of a spinner on the button.
        // but still disable the button to prevent double submits
        // Figma: https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Eng-Specs?type=design&node-id=19983-25232&mode=design&t=CAwfLMM6oRqBI85a-0
        loading={isSubmitting}
      >
        {variant === 'readOnly' ? 'Submit' : 'Update waterfall'}
      </Button>
    ),
    [isSubmitting, variant]
  );

  const resetAssumptionsButton = useMemo(
    () => (
      <Button
        size="sm"
        variant="transparent"
        onClick={() => {
          reset(DEFAULT_ASSUMPTIONS);
          void onSubmit();
        }}
        // Disable while submitting, same as submit button
        disabled={isSubmitting}
      >
        Reset assumptions
      </Button>
    ),
    [isSubmitting, onSubmit, reset]
  );

  const [
    assetGrowthReturn = DEFAULT_ASSUMPTIONS.assetGrowthReturn,
    exemptionGrowthRate = DEFAULT_ASSUMPTIONS.exemptionGrowthRate,
    willExemptionSunset = DEFAULT_ASSUMPTIONS.willExemptionSunset,
    useCustomGlobalGrowthRate = DEFAULT_ASSUMPTIONS.useCustomGlobalGrowthRate,
  ] = useWatch({
    control,
    name: [
      'assetGrowthReturn',
      'exemptionGrowthRate',
      'willExemptionSunset',
      'useCustomGlobalGrowthRate',
    ],
  });

  const assumptionsCopy = useMemo(() => {
    // this shouldn't happen, satisfy ts
    if (!exemptionGrowthRate || !assetGrowthReturn) return '';
    return getAssumptionsPreviewCopy({
      exemptionGrowthRate,
      assetGrowthReturn,
      willExemptionSunset,
      useCustomGlobalGrowthRate,
    });
  }, [
    assetGrowthReturn,
    exemptionGrowthRate,
    useCustomGlobalGrowthRate,
    willExemptionSunset,
  ]);

  return (
    <form onSubmit={onSubmit}>
      <EstateWaterfallAssumptionsAccordion
        loading={loading}
        disabled={loading}
        submitButton={submitButton}
        resetAssumptionsButton={resetAssumptionsButton}
        isTwoGrantor={isTwoGrantor}
        assumptionsCopy={assumptionsCopy}
        assumptionsHelpCopy={assumptionsHelpCopy}
      />
    </form>
  );
};

type EstateWaterfallAssumptionsUpdaterProps = PropsWithChildren<{
  waterfallId: string;
  variant?: EditEstateWaterfallAssumptionsFormProps['variant'];
  onSubmit?: EditEstateWaterfallAssumptionsFormProps['onSubmit'];
}>;

/**
 * Contains a horizontal bar accordion form to update the assumption fields of an Estate Waterfall.
 * Any components that render as a result of assumption fields getting updated should be passed
 * as children to this component. While updating, a loading overlay will be shown on top of the
 * children.
 */
export const EstateWaterfallAssumptionsUpdater = ({
  waterfallId,
  variant = 'edit',
  onSubmit,
  children,
}: EstateWaterfallAssumptionsUpdaterProps) => {
  const formMethods = useForm<EstateWaterfallAssumptionFields>();

  const {
    reset,
    formState: { isSubmitting },
  } = formMethods;

  const { data, loading } = useQueryEstateWaterfallAssumptionsQuery({
    variables: {
      where: {
        id: waterfallId,
      },
    },
  });

  const waterfall = getNodes(data?.estateWaterfalls)?.[0];

  const stateDisclosuresData = useMemo(() => {
    const {
      beforeFirstDeathTaxSummary,
      firstDeathTaxSummary,
      secondDeathTaxSummary,
    } = waterfall?.visualizationWithProjections ?? {};

    const stateData = [
      beforeFirstDeathTaxSummary?.stateTax,
      firstDeathTaxSummary?.stateTax,
      secondDeathTaxSummary?.stateTax,
    ];

    return stateData.flatMap((state) => state ?? []);
  }, [waterfall?.visualizationWithProjections]);

  const { ILLUSTRATIVE_PURPOSES_ONLY } = useEstateWaterfallCalloutCopy({
    stateData: stateDisclosuresData,
  });

  useEffect(() => {
    if (!waterfall || loading) {
      return;
    }

    reset(mapWaterfallToAssumptionFields(waterfall));
  }, [waterfall, loading, reset]);

  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  if (isGrowthProfilesEnabled) return <>{children}</>;

  return (
    <FormProvider {...formMethods}>
      <Stack flex={1} gap={3}>
        <EditEstateWaterfallAssumptionsForm
          waterfallId={waterfallId}
          loading={loading}
          isTwoGrantor={waterfall?.isTwoGrantor}
          variant={variant}
          onSubmit={onSubmit}
          assumptionsHelpCopy={ILLUSTRATIVE_PURPOSES_ONLY}
        />
        <Box flex={1} sx={{ position: 'relative' }}>
          <EditEstateWaterfallAssumptionsUpdatingOverlay show={isSubmitting} />
          {children}
        </Box>
      </Stack>
    </FormProvider>
  );
};
