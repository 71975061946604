import { styled } from '@mui/material';
import { GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

import {
  BeneficiaryReport,
  BeneficiaryReportingRowVariant,
} from '../beneficiaryReporting.types';
import { BenefitsTableRowData } from '../beneficiaryReporting.types';
import {
  getBenefitsTableColumns,
  getBenefitsTableRows,
} from '../beneficiaryReporting.utils';

const StyledDataTable = styled(DataTable)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: `${COLORS.PRIMITIVES.WHITE} !important`,
    fontWeight: 'bold',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  '& .MuiDataGrid-columnHeader:not(:last-child)': {
    borderRight: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    lineHeight: '40px',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    '& > .MuiStack-root > .MuiTypography-root': {
      color: `${COLORS.PRIMITIVES.WHITE} !important`,
    },
    '& > .MuiStack-root': {
      padding: theme.spacing(1.5),
    },
  },
  '.MuiDataGrid-cell > *': {
    padding: theme.spacing(1.5),
  },
}));

const useRowStyles = makeStyles()(() => ({
  subtitleRowStyle: {
    backgroundColor: `${COLORS.GRAY[100]} !important`,
    fontWeight: 'bold !important',
    color: `${COLORS.PRIMITIVES.WHITE} !important`,
    minHeight: '41px !important', // 41px is the natural height of the subtitle row with border
  },
  rowStyle: {
    minHeight: '61px !important', // 61px is the natural height of the two line row with border
  },
}));

interface BenefitsTableProps {
  beneficiaryReport: BeneficiaryReport;
  afterDeath: AfterDeath;
  firstGrantor?: ContextPrimaryClient;
  secondGrantor?: ContextPrimaryClient;
  firstGrantorDeathYear?: number;
  secondGrantorDeathYear?: number;
}

export function BenefitsTable({
  beneficiaryReport,
  afterDeath,
  firstGrantor,
  secondGrantor,
  firstGrantorDeathYear,
  secondGrantorDeathYear,
}: BenefitsTableProps) {
  const { classes } = useRowStyles();

  const tableHeaders = useWaterfallGrantorDeathDisplay({
    firstGrantorDeath: firstGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
  });
  const tableHeader = tableHeaders[afterDeath];

  const noBenefitsText = useMemo(() => {
    if (afterDeath === AfterDeath.None) {
      return `No benefits before ${firstGrantor?.firstName}’s death`;
    }

    if (afterDeath === AfterDeath.First) {
      return `No benefits after ${firstGrantor?.firstName}’s death`;
    }

    return `No benefits after ${secondGrantor?.firstName}’s death`;
  }, [afterDeath, firstGrantor?.firstName, secondGrantor?.firstName]);

  const columns = useMemo(
    () => getBenefitsTableColumns(tableHeader),
    [tableHeader]
  );
  const rows = useMemo(() => {
    const scheduledDistributions = getBenefitsTableRows(
      beneficiaryReport.scheduledDistributions
    );
    const directGifts = getBenefitsTableRows(beneficiaryReport.directGifts);
    const fullAccess = getBenefitsTableRows(beneficiaryReport.fullAccess);
    const partialAccess = getBenefitsTableRows(beneficiaryReport.partialAccess);

    const scheduledDistributionSubtitleRow: BenefitsTableRowData = {
      id: 'scheduled-distribution-subtitle',
      variant: BeneficiaryReportingRowVariant.Subtitle,
      entity: {
        lineOne: 'Scheduled distributions',
      },
      parameters: {
        lineOne: 'Details',
      },
      distributionValue: {
        lineOne: 'Distribution value',
      },
      projectedEntityValue: {
        lineOne: 'Projected entity value',
      },
    };

    const directGiftSubtitleRow: BenefitsTableRowData = {
      id: 'direct-gift-subtitle',
      variant: BeneficiaryReportingRowVariant.Subtitle,
      entity: {
        lineOne: 'Direct gifts & distributions',
      },
      parameters: {
        lineOne: 'Details',
      },
      projectedEntityValue: {
        lineOne: 'Transfer value',
      },
    };

    const fullAccessSubtitleRow: BenefitsTableRowData = {
      id: 'full-access-subtitle',
      variant: BeneficiaryReportingRowVariant.Subtitle,
      entity: {
        lineOne: 'Full access',
      },
      projectedEntityValue: {
        lineOne: 'Projected entity value',
      },
    };

    const partialAccessSubtitleRow: BenefitsTableRowData = {
      id: 'partial-access-subtitle',
      variant: BeneficiaryReportingRowVariant.Subtitle,
      entity: {
        lineOne: 'Partial access',
      },
      parameters: {
        lineOne: 'Details',
      },
      projectedEntityValue: {
        lineOne: 'Projected entity value',
      },
    };

    return compact([
      scheduledDistributions.length > 0 && scheduledDistributionSubtitleRow,
      ...scheduledDistributions,
      directGifts.length > 0 && directGiftSubtitleRow,
      ...directGifts,
      fullAccess.length > 0 && fullAccessSubtitleRow,
      ...fullAccess,
      partialAccess.length > 0 && partialAccessSubtitleRow,
      ...partialAccess,
    ]);
  }, [
    beneficiaryReport.directGifts,
    beneficiaryReport.fullAccess,
    beneficiaryReport.partialAccess,
    beneficiaryReport.scheduledDistributions,
  ]);

  return (
    <StyledDataTable
      rowHoverVariant="transparent"
      noPointer
      data-testid={`beneficiary-reporting-table-${afterDeath}`}
      columns={columns}
      rows={rows}
      disableColumnReorder
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableVirtualization
      disableColumnResize
      disableAutosize
      pagination={false}
      slots={{
        noRowsOverlay: () => <TableEmptyState text={noBenefitsText} />,
        footer: () => null,
        columnResizeIcon: () => null,
      }}
      getRowClassName={({
        row,
      }: GridRowClassNameParams<BenefitsTableRowData>) => {
        switch (row.variant) {
          case BeneficiaryReportingRowVariant.Subtitle:
            return classes.subtitleRowStyle;
          default:
            return classes.rowStyle;
        }
      }}
    />
  );
}
