import { Box } from '@mui/material';

import { Callout } from '@/components/notifications/Callout/Callout';

export interface HypotheticalWaterfallScenarioCalloutProps {
  waterfallName: string;
}

export function HypotheticalWaterfallScenarioCallout({
  waterfallName,
}: HypotheticalWaterfallScenarioCalloutProps) {
  return (
    <Box mb={2}>
      <Callout severity="info-high">
        Dispositions edited here apply only to the hypothetical waterfall &quot;
        {waterfallName}&quot;
      </Callout>
    </Box>
  );
}
