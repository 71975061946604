import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';

import { HypotheticalSaleLoanFormTab } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';

export function useActiveSaleLoanTab() {
  return useActiveTab<HypotheticalSaleLoanFormTab>({
    defaultTab: 'basicInformation',
    filterSearchParam: 'saleLoanTab',
  });
}
