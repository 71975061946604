import { Box, Stack } from '@mui/material';
import { trim } from 'lodash';
import { useEffect } from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/form/baseInputs/Button';
import { Link } from '@/components/form/baseInputs/Link';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { IntegrationConfigurationAddeparClientType } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { ADDEPAR_IMPORT_TYPE_DISPLAY } from './graphql/AdminIntegrationsPage.constants';
import {
  AdminIntegrationsPage_IntegrationConfigurationFragment,
  useUpdateIntegrationConfigurationMutation,
} from './graphql/AdminIntegrationsPage.generated';

export function isAddeparConfigured(
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment | null
) {
  return (
    Boolean(configuration?.addeparFirmID) &&
    Boolean(configuration?.addeparSubdomain)
  );
}

const clientImportTypeOptions = [
  IntegrationConfigurationAddeparClientType.Client,
  IntegrationConfigurationAddeparClientType.Group,
  IntegrationConfigurationAddeparClientType.Household,
  IntegrationConfigurationAddeparClientType.All,
].map((v) => {
  return {
    value: v,
    label: ADDEPAR_IMPORT_TYPE_DISPLAY[v],
  };
});

interface AddeparIntegrationConfigurationFormShape {
  firmId: string;
  subdomain: string;
  clientImportType: IntegrationConfigurationAddeparClientType;
  customAssetClassName: string;
}

type AddeparIntegrationConfigurationFormPaths =
  PathsOf<AddeparIntegrationConfigurationFormShape>;

interface AddeparIntegrationFormProps {
  onEditComplete: () => void;
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment;
}

export function AddeparIntegrationForm({
  onEditComplete,
  configuration,
}: AddeparIntegrationFormProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateConfiguration] = useUpdateIntegrationConfigurationMutation({
    onCompleted: () => {
      showFeedback('Addepar configuration updated successfully', {
        variant: 'success',
      });
      onEditComplete();
    },
    onError: (err) => {
      showFeedback(
        'Failed to update Addepar configuration. Please refresh the page and try again.'
      );
      reportError('Failed to update Addepar configuration', err);
    },
  });
  const formMethods = useForm<AddeparIntegrationConfigurationFormShape>({
    defaultValues: {
      firmId: '',
      subdomain: '',
      clientImportType: IntegrationConfigurationAddeparClientType.All,
      customAssetClassName: '',
    },
  });
  const { control, handleSubmit, reset, watch } = formMethods;
  const subdomain = watch('subdomain');

  useEffect(() => {
    reset({
      firmId: configuration.addeparFirmID ?? '',
      subdomain: configuration.addeparSubdomain ?? '',
      clientImportType:
        configuration.addeparClientType ??
        IntegrationConfigurationAddeparClientType.All,
      customAssetClassName: configuration.addeparCustomAssetClassName ?? '',
    });
  }, [configuration, reset]);

  const onSubmit = handleSubmit((data) => {
    return updateConfiguration({
      variables: {
        input: {
          id: configuration?.id,
          update: {
            addeparFirmID: trim(data.firmId),
            // the backend requires that the subdomain is lowercase, and this is a more
            // user-friendly way to handle this rather than showing the user a validation error
            addeparSubdomain: trim(data.subdomain).toLowerCase(),
            addeparClientType: data.clientImportType,
            addeparCustomAssetClassName: data.customAssetClassName
              ? trim(data.customAssetClassName)
              : null,
            clearAddeparCustomAssetClassName: !data.customAssetClassName,
          },
        },
      },
    });
  });

  return (
    <Stack spacing={2}>
      <FormAwareTextInput
        control={control}
        fieldName={
          'firmId' as const satisfies AddeparIntegrationConfigurationFormPaths
        }
        label="Firm ID"
        helpText="This is the unique identifier for your Addepar firm. If you don't know it, please contact Addepar support."
        validation={{
          mustBeNumber: (value) =>
            /^\d+$/.test(value) || 'Firm ID must be a number',
        }}
        required
      />
      <FormAwareTextInput
        control={control}
        fieldName={
          'subdomain' as const satisfies AddeparIntegrationConfigurationFormPaths
        }
        helpText="This is the subdomain you use to access Addepar. For example, if you access Addepar at 'https://terrabella.addepar.com', your subdomain is 'terrabella'."
        label="Subdomain"
        required
      />
      <Accordion variant="default" title="Advanced settings">
        <Stack spacing={2}>
          <FormAwareRadioGroup
            control={control}
            row={false}
            required
            stackingDirection="row"
            helpText="How your clients are modeled in Addepar. For example, if you choose 'Groups', only Addepar 'Groups' will be eligible to be imported as Luminary clients."
            label="Client import type"
            fieldName={
              'clientImportType' as const satisfies AddeparIntegrationConfigurationFormPaths
            }
            options={clientImportTypeOptions}
          />
          <Stack spacing={0.5}>
            <FormAwareTextInput
              control={control}
              fieldName={
                'customAssetClassName' as const satisfies AddeparIntegrationConfigurationFormPaths
              }
              helpText="If your firm uses a custom asset class attribute, enter the API name of this data field as it appears in Addepar. It is labeled as 'API field name' and likely starts with '_custom."
              label="Asset class attribute (optional)"
            />
            {subdomain && (
              <Box>
                <Link
                  display="View Addepar attributes"
                  href={`https://${subdomain}.addepar.com/app/settings/attributes`}
                  external
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Accordion>

      <Button size="lg" variant="primary" onClick={onSubmit}>
        Save configuration
      </Button>
    </Stack>
  );
}
