import { Card } from '@/components/layout/Card/Card';
import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { EntityFormAIDPRecipientSuggestionsMatcher } from '@/modules/aiDP/entity/EntityFormAIDPRecipientSuggestionsMatcher';
import { useHasBeenReviewed } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/hooks/utilityHooks';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

export function EntityFormAIDPSuggestionsCard() {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const hasBeenReviewed = useHasBeenReviewed();
  const { hasUnacknowledgedRecipientSuggestions } =
    useEntityDPSuggestionsContext();

  if (
    !aiDpEnabled ||
    !hasUnacknowledgedRecipientSuggestions ||
    !hasBeenReviewed
  ) {
    return null;
  }

  return (
    <Card variant="filled-teal" sx={{ mb: 3, p: 3, pt: 0 }}>
      <EntityFormAIDPRecipientSuggestionsMatcher />
    </Card>
  );
}
