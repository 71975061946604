import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { Operations } from '../Illustration/graphql/SaleLoanIllustration.generated';
import { useSaleLoanFormContext } from './context/SaleLoanForm.context';

export function ReviewGrowthProfilesButton() {
  const apolloClient = useApolloClient();
  const { growthProfileId } = useSaleLoanFormContext();
  const { householdId } = useHouseholdDetailsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasRequiredData = householdId && growthProfileId;

  const handleClose = useCallback(async () => {
    setIsModalOpen(false);
    await apolloClient.refetchQueries({
      include: [Operations.Query.SaleLoanEstateWaterfall],
    });
  }, [apolloClient]);

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        fullWidth
        endIcon={LinkExternal01Icon}
        onClick={() => setIsModalOpen(true)}
        disabled={!hasRequiredData}
      >
        Review growth profile for assets
      </Button>
      {isModalOpen && hasRequiredData && (
        <GrowthProfileModal
          onClose={handleClose}
          householdId={householdId}
          growthProfileId={growthProfileId}
        />
      )}
    </>
  );
}
