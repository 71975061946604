import { compact, includes } from 'lodash';

import { useDefaultAssetClassId } from '@/modules/tenant/TenantDetailsContext/hooks/useDefaultAssetClassId';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { INSURANCE_POLICY_ENTITY_TYPES } from '../entities.constants';
import {
  annuitiesSubformConfig,
  assetsSubformConfig,
  basicAssetsInitialFundingSubformConfig,
  basicInformationSubformConfig,
  beneficiariesSubformConfig,
  businessEntitiesTaxStatusSubformConfig,
  businessEntityDetailsSubformConfig,
  insurancePolicyDetailsSubformConfig,
  SubformConfig,
  SubformConfigWithoutHtmlId,
  taxStatusSubformConfig,
  trustDetailsSubformConfig,
} from '../EntitySubforms/entitySubformConfigs';
import { EntityType } from '../types/EntityType';
import { EntityFormStage } from './types';

function addHtmlId(
  element: SubformConfigWithoutHtmlId,
  index: number
): SubformConfig {
  return {
    ...element,
    htmlId: `id-${element.type}-${index}`,
  };
}

export function useEntitySubformConfigs(
  type: EntityType | null,
  householdId: string | null,
  entityId: string | undefined,
  stage: EntityFormStage
): SubformConfig[] {
  const { assetClassesById } = useTenantDetailsContext();
  const defaultAssetClassId = useDefaultAssetClassId();
  // this is just here to allow calling this hook prior to all of these values being available
  if (!type || !householdId) {
    return [];
  }

  const sharedSubformOpts = {
    stage,
  };

  const assetsSubformOpts = {
    assetClassesById,
    defaultAssetClassId,
  };

  switch (type) {
    case 'grat': {
      const assets =
        stage === EntityFormStage.GRAT_IMPLEMENTATION_EDIT
          ? [
              assetsSubformConfig(type, householdId, entityId, {
                ...sharedSubformOpts,
                ...assetsSubformOpts,
                heading: 'Initial funding assets',
              }),
            ]
          : [];

      // we don't allow editing annuities after the GRAT has been implemented because
      // we want to make sure that the math works out between the taxable gift, the initial
      // funding value, and the annuity payment amounts
      const annuities =
        stage !== EntityFormStage.EDIT
          ? [
              annuitiesSubformConfig(type, householdId, entityId, {
                ...sharedSubformOpts,
                heading: 'Annuity payments',
              }),
            ]
          : [];

      return [
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig(type, householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
          heading: 'Remainder beneficiaries',
        }),
        ...assets,
        ...annuities,
      ].map(addHtmlId);
    }
    case 'ilit':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig(type, householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
      ]).map(addHtmlId);
    case 'qprt':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig(type, householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
          heading: 'Remainder beneficiaries',
        }),
        basicAssetsInitialFundingSubformConfig(type, householdId, {
          ...sharedSubformOpts,
          ...assetsSubformOpts,
        }),
      ]).map(addHtmlId);
    case 'slat':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig(type, householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(
          type,
          householdId,
          'lifetimeBeneficiariesSubform',
          { ...sharedSubformOpts }
        ),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'revocable-trust':
    // falls through to shared handling with irrevocable trust
    case 'irrevocable-trust':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig(type, householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'clt':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig('clt', householdId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(
          'clt',
          householdId,
          'leadBeneficiarySubform',
          {
            ...sharedSubformOpts,
          }
        ),
        beneficiariesSubformConfig(
          'clt',
          householdId,
          'remainderBeneficiariesSubform',
          { ...sharedSubformOpts }
        ),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'crt':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        trustDetailsSubformConfig('crt', householdId, {
          ...sharedSubformOpts,
        }),
        beneficiariesSubformConfig(
          'crt',
          householdId,
          'incomeBeneficiarySubform',
          {
            ...sharedSubformOpts,
          }
        ),
        beneficiariesSubformConfig(
          'clt',
          householdId,
          'remainderBeneficiariesSubform',
          { ...sharedSubformOpts }
        ),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'daf':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'private-foundation':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'individual-account':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
      ]).map(addHtmlId);
    case 'insurance-account':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    case 'joint-account':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
      ]).map(addHtmlId);
    case 'retirement-account':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
      ]).map(addHtmlId);
    case 'qualified-tuition-account':
    // falls through to shared handling with custodial account
    case 'custodial-account':
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        taxStatusSubformConfig(type, entityId),
        beneficiariesSubformConfig(type, householdId, 'beneficiariesSubform', {
          ...sharedSubformOpts,
        }),
      ]).map(addHtmlId);

    // Business entities
    case 'sole-proprietorship':
    case 'c-corp':
    case 's-corp':
    case 'llc':
    case 'lp':
    case 'gp': {
      const shouldIncludeInsurancePolicyForm = includes(
        INSURANCE_POLICY_ENTITY_TYPES,
        type
      );
      return compact([
        basicInformationSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        businessEntityDetailsSubformConfig(householdId, type, entityId, {
          ...sharedSubformOpts,
        }),
        businessEntitiesTaxStatusSubformConfig(type),
        shouldIncludeInsurancePolicyForm &&
          insurancePolicyDetailsSubformConfig(householdId, type, entityId),
      ]).map(addHtmlId);
    }

    default:
      throw new Error(
        `Could not get subforms for unrecognized entity, ${type}`
      );
  }
}
