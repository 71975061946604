// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDispositiveProvisionsMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityInput;
}>;


export type CreateDispositiveProvisionsMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string } };

export type CreateDispositiveProvisionsAndAckSuggestionsMutationVariables = Types.Exact<{
  entityInput: Types.AugmentedUpdateEntityInput;
  acknowledgeSuggestionsInput: Array<Types.AcknowledgeAiSuggestionInput> | Types.AcknowledgeAiSuggestionInput;
}>;


export type CreateDispositiveProvisionsAndAckSuggestionsMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string }, acknowledgeAISuggestions: Array<{ __typename?: 'AISuggestion', id: string, acceptanceStatus?: Types.AiSuggestionAcceptanceStatus | null }> };

export type CreateDispositiveProvisionsForTestamentaryEntitiesMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateTestamentaryEntityInput;
}>;


export type CreateDispositiveProvisionsForTestamentaryEntitiesMutation = { __typename?: 'Mutation', updateTestamentaryEntity: { __typename?: 'TestamentaryEntity', id: string } };

export type CreateDispositiveProvisionsForClientProfileMutationVariables = Types.Exact<{
  input: Array<Types.InputMaybe<Types.AugmentedUpdateHouseholdInput>> | Types.InputMaybe<Types.AugmentedUpdateHouseholdInput>;
}>;


export type CreateDispositiveProvisionsForClientProfileMutation = { __typename?: 'Mutation', updateHouseholds: Array<{ __typename?: 'Household', id: string } | null> };


export const CreateDispositiveProvisionsDocument = gql`
    mutation CreateDispositiveProvisions($input: AugmentedUpdateEntityInput!) {
  updateEntity(input: $input) {
    id
  }
}
    `;
export type CreateDispositiveProvisionsMutationFn = Apollo.MutationFunction<CreateDispositiveProvisionsMutation, CreateDispositiveProvisionsMutationVariables>;

/**
 * __useCreateDispositiveProvisionsMutation__
 *
 * To run a mutation, you first call `useCreateDispositiveProvisionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDispositiveProvisionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDispositiveProvisionsMutation, { data, loading, error }] = useCreateDispositiveProvisionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDispositiveProvisionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDispositiveProvisionsMutation, CreateDispositiveProvisionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDispositiveProvisionsMutation, CreateDispositiveProvisionsMutationVariables>(CreateDispositiveProvisionsDocument, options);
      }
export type CreateDispositiveProvisionsMutationHookResult = ReturnType<typeof useCreateDispositiveProvisionsMutation>;
export type CreateDispositiveProvisionsMutationResult = Apollo.MutationResult<CreateDispositiveProvisionsMutation>;
export type CreateDispositiveProvisionsMutationOptions = Apollo.BaseMutationOptions<CreateDispositiveProvisionsMutation, CreateDispositiveProvisionsMutationVariables>;
export const CreateDispositiveProvisionsAndAckSuggestionsDocument = gql`
    mutation CreateDispositiveProvisionsAndAckSuggestions($entityInput: AugmentedUpdateEntityInput!, $acknowledgeSuggestionsInput: [AcknowledgeAISuggestionInput!]!) {
  updateEntity(input: $entityInput) {
    id
  }
  acknowledgeAISuggestions(input: $acknowledgeSuggestionsInput) {
    id
    acceptanceStatus
  }
}
    `;
export type CreateDispositiveProvisionsAndAckSuggestionsMutationFn = Apollo.MutationFunction<CreateDispositiveProvisionsAndAckSuggestionsMutation, CreateDispositiveProvisionsAndAckSuggestionsMutationVariables>;

/**
 * __useCreateDispositiveProvisionsAndAckSuggestionsMutation__
 *
 * To run a mutation, you first call `useCreateDispositiveProvisionsAndAckSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDispositiveProvisionsAndAckSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDispositiveProvisionsAndAckSuggestionsMutation, { data, loading, error }] = useCreateDispositiveProvisionsAndAckSuggestionsMutation({
 *   variables: {
 *      entityInput: // value for 'entityInput'
 *      acknowledgeSuggestionsInput: // value for 'acknowledgeSuggestionsInput'
 *   },
 * });
 */
export function useCreateDispositiveProvisionsAndAckSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDispositiveProvisionsAndAckSuggestionsMutation, CreateDispositiveProvisionsAndAckSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDispositiveProvisionsAndAckSuggestionsMutation, CreateDispositiveProvisionsAndAckSuggestionsMutationVariables>(CreateDispositiveProvisionsAndAckSuggestionsDocument, options);
      }
export type CreateDispositiveProvisionsAndAckSuggestionsMutationHookResult = ReturnType<typeof useCreateDispositiveProvisionsAndAckSuggestionsMutation>;
export type CreateDispositiveProvisionsAndAckSuggestionsMutationResult = Apollo.MutationResult<CreateDispositiveProvisionsAndAckSuggestionsMutation>;
export type CreateDispositiveProvisionsAndAckSuggestionsMutationOptions = Apollo.BaseMutationOptions<CreateDispositiveProvisionsAndAckSuggestionsMutation, CreateDispositiveProvisionsAndAckSuggestionsMutationVariables>;
export const CreateDispositiveProvisionsForTestamentaryEntitiesDocument = gql`
    mutation CreateDispositiveProvisionsForTestamentaryEntities($input: AugmentedUpdateTestamentaryEntityInput!) {
  updateTestamentaryEntity(input: $input) {
    id
  }
}
    `;
export type CreateDispositiveProvisionsForTestamentaryEntitiesMutationFn = Apollo.MutationFunction<CreateDispositiveProvisionsForTestamentaryEntitiesMutation, CreateDispositiveProvisionsForTestamentaryEntitiesMutationVariables>;

/**
 * __useCreateDispositiveProvisionsForTestamentaryEntitiesMutation__
 *
 * To run a mutation, you first call `useCreateDispositiveProvisionsForTestamentaryEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDispositiveProvisionsForTestamentaryEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDispositiveProvisionsForTestamentaryEntitiesMutation, { data, loading, error }] = useCreateDispositiveProvisionsForTestamentaryEntitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDispositiveProvisionsForTestamentaryEntitiesMutation(baseOptions?: Apollo.MutationHookOptions<CreateDispositiveProvisionsForTestamentaryEntitiesMutation, CreateDispositiveProvisionsForTestamentaryEntitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDispositiveProvisionsForTestamentaryEntitiesMutation, CreateDispositiveProvisionsForTestamentaryEntitiesMutationVariables>(CreateDispositiveProvisionsForTestamentaryEntitiesDocument, options);
      }
export type CreateDispositiveProvisionsForTestamentaryEntitiesMutationHookResult = ReturnType<typeof useCreateDispositiveProvisionsForTestamentaryEntitiesMutation>;
export type CreateDispositiveProvisionsForTestamentaryEntitiesMutationResult = Apollo.MutationResult<CreateDispositiveProvisionsForTestamentaryEntitiesMutation>;
export type CreateDispositiveProvisionsForTestamentaryEntitiesMutationOptions = Apollo.BaseMutationOptions<CreateDispositiveProvisionsForTestamentaryEntitiesMutation, CreateDispositiveProvisionsForTestamentaryEntitiesMutationVariables>;
export const CreateDispositiveProvisionsForClientProfileDocument = gql`
    mutation CreateDispositiveProvisionsForClientProfile($input: [AugmentedUpdateHouseholdInput]!) {
  updateHouseholds(inputs: $input) {
    id
  }
}
    `;
export type CreateDispositiveProvisionsForClientProfileMutationFn = Apollo.MutationFunction<CreateDispositiveProvisionsForClientProfileMutation, CreateDispositiveProvisionsForClientProfileMutationVariables>;

/**
 * __useCreateDispositiveProvisionsForClientProfileMutation__
 *
 * To run a mutation, you first call `useCreateDispositiveProvisionsForClientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDispositiveProvisionsForClientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDispositiveProvisionsForClientProfileMutation, { data, loading, error }] = useCreateDispositiveProvisionsForClientProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDispositiveProvisionsForClientProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateDispositiveProvisionsForClientProfileMutation, CreateDispositiveProvisionsForClientProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDispositiveProvisionsForClientProfileMutation, CreateDispositiveProvisionsForClientProfileMutationVariables>(CreateDispositiveProvisionsForClientProfileDocument, options);
      }
export type CreateDispositiveProvisionsForClientProfileMutationHookResult = ReturnType<typeof useCreateDispositiveProvisionsForClientProfileMutation>;
export type CreateDispositiveProvisionsForClientProfileMutationResult = Apollo.MutationResult<CreateDispositiveProvisionsForClientProfileMutation>;
export type CreateDispositiveProvisionsForClientProfileMutationOptions = Apollo.BaseMutationOptions<CreateDispositiveProvisionsForClientProfileMutation, CreateDispositiveProvisionsForClientProfileMutationVariables>;
export const Operations = {
  Mutation: {
    CreateDispositiveProvisions: 'CreateDispositiveProvisions' as const,
    CreateDispositiveProvisionsAndAckSuggestions: 'CreateDispositiveProvisionsAndAckSuggestions' as const,
    CreateDispositiveProvisionsForTestamentaryEntities: 'CreateDispositiveProvisionsForTestamentaryEntities' as const,
    CreateDispositiveProvisionsForClientProfile: 'CreateDispositiveProvisionsForClientProfile' as const
  }
}