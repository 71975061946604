import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useEntityDiagramQuery } from '../graphql/EntityDiagram.generated';

interface UseQueryEntityDiagramProps {
  entityId: string;
}

export function useQueryEntityDiagram({
  entityId,
}: UseQueryEntityDiagramProps) {
  const { primaryClients } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();

  const { data, ...rest } = useEntityDiagramQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('entity diagram load failure', error, {
        entityId,
      });
    },
  });

  const { entity } = useMemo(() => {
    const entity = getNodes(data?.entities)[0] ?? null;

    return { entity };
  }, [data?.entities]);

  return {
    primaryClients,
    entity,
    ...rest,
  };
}
