import {
  EffectFunction,
  EffectReducer,
  EffectReducerExec,
  EffectsMap,
  InitialEffectStateGetter,
} from 'use-effect-reducer';

import { GraphViz_NodeConfigFragment } from '@/modules/graphViz/graphql/GraphVizNodeConfig.fragment.generated';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
  EntityKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { EntityDiagramAction } from './actions';
import { EntityDiagramEffect } from './effects';
import { EntityDiagramState } from './state';

export * from './actions';
export * from './effects';
export * from './entityDiagramGraph';
export * from './state';

export type EntityDiagramEffectFn<Effect extends EntityDiagramEffect> =
  EffectFunction<EntityDiagramState, EntityDiagramAction, Effect>;

export type EntityDiagramEffectReducerQueueEffect = EffectReducerExec<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramReducer = EffectReducer<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramEffectsMap = EffectsMap<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramActionFn<T> = (
  state: EntityDiagramState,
  action: T,
  queueEffect: EntityDiagramEffectReducerQueueEffect
) => EntityDiagramState;

export type EntityDiagramInitialStateGetter = InitialEffectStateGetter<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export interface EntityDiagram_EntityNode {
  __typename: 'Entity';
  id: string;
  entityKind: EntityKind;
  extendedDisplayKind: string;
  subtype: {
    id: string;
    displayName: string;
  };
}

interface EntityDiagram_TestamentaryEntityNode {
  __typename: 'TestamentaryEntity';
  id: string;
  testamentaryEntityKind: TestamentaryEntityKind;
  displayName: string;
}

interface EntityDiagram_ClientProfileNode {
  __typename: 'ClientProfile';
  id: string;
  displayName: string;
}

interface EntityDiagram_ClientOrganizationNode {
  __typename: 'ClientOrganization';
  id: string;
  kind: ClientOrganizationKind;
  name: string;
}

// replaces EstateWaterfall_NodeFragment
export type EntityDiagram_NodeFragment =
  | {
      id: string;
      afterDeath: AfterDeath;
      entityDiagramID: string | null;
      inEstateStatus: EntityInEstateStatus;
      nodeConfiguration: GraphViz_NodeConfigFragment | null;
      node:
        | EntityDiagram_EntityNode
        | EntityDiagram_TestamentaryEntityNode
        | EntityDiagram_ClientProfileNode
        | EntityDiagram_ClientOrganizationNode;
    }
  | {
      id: string;
      afterDeath: AfterDeath;
      node: null;
    };

// replaces EstateWaterfallEdgeKind
export enum EntityDiagramEdgeKind {
  Disposition = 'DISPOSITION',
}

// replaces EstateWaterfall_EdgeFragment
export interface EntityDiagram_EdgeFragment {
  from: EntityDiagram_NodeFragment;
  to: EntityDiagram_NodeFragment;
  kind: EntityDiagramEdgeKind;
}

export interface EntityDiagramProps {
  householdId: string;
  entityId: string;
  height: number;
  width: number;
  registerEntityDiagramReady?: (entityId: string) => void;
}
