import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { Callout } from '@/components/notifications/Callout/Callout';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  GiftDesignerModelScenariosFormShape,
  ScenarioGiftFormShape,
  ScenarioGiftFormShapeAnnuallyRecurring,
  ScenarioGiftFormShapeMerged,
  ScenarioGiftFormShapeOneTime,
} from '../../GiftDesignerModelScenariosForm.types';
import {
  AnnuallyRecurringValue,
  ScenarioIncomeAndExpensesFormFields,
} from './ScenarioGiftModal.fields';
import { useScenarioGiftFormDefaultValues } from './ScenarioGiftModal.utils';

interface ScenarioGiftModalProps {
  giftData: ScenarioGiftFormShape | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: (formData: ScenarioGiftFormShape) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
  proposalId: string;
}

function ScenarioGiftModalInner({
  giftData: incomeData,
  isOpen,
  onClose,
  onSubmitSuccess,
  onDelete = () => new Promise(() => null),
  proposalId,
}: ScenarioGiftModalProps) {
  const isEdit = !!incomeData;
  const [isDeleting, setIsDeleting] = useState(false);

  const { formRef, handleSave } = useFormSaveHandler();

  const { reset, handleSubmit, control, formState } =
    useFormContext<ScenarioGiftFormShape>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit gift';
    }

    return 'Add gift';
  }, [isEdit]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<ScenarioGiftFormShape>
        formState={formState}
        isDeleting={isDeleting}
      >
        {isEdit && (
          <FormModalActions.DeleteButton
            onConfirmDelete={async () => {
              setIsDeleting(true);
              await onDelete();
              setIsDeleting(false);
            }}
          />
        )}
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          {isEdit ? 'Save edits' : 'Add gift'}
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave, isDeleting, isEdit, onDelete]);

  const onSubmit = handleSubmit((formData: ScenarioGiftFormShapeMerged) => {
    const commonFormData = {
      _id: formData._id,
      recipientId: formData.recipientId,
      recipientKind: formData.recipientKind,
      amount: formData.amount,
      displayName: formData.displayName,
      order: formData.order,
      startYear: formData.startYear,
      discount: formData.discount,
      discountPercent: formData.discountPercent,
      isTaxable: formData.isTaxable,
      nonTaxableGiftType: formData.nonTaxableGiftType,
      senderIds: formData.senderIds,
      lengthOfAnalysis: formData.lengthOfAnalysis,
      isNewRecipient: formData.isNewRecipient,
      newRecipientName: formData.newRecipientName,
      newRecipientType: formData.newRecipientType,
    };

    // We need to make sure to format the form data correctly
    if (
      formData.annuallyRecurring === AnnuallyRecurringValue.false ||
      (formData.endYear && formData.startYear.equals(formData.endYear))
    ) {
      return onSubmitSuccess({
        ...commonFormData,
        annuallyRecurring: AnnuallyRecurringValue.false,
        endYear: null,
        growthPercentage: null,
      } as const satisfies ScenarioGiftFormShapeOneTime);
    } else {
      return onSubmitSuccess({
        ...commonFormData,
        annuallyRecurring: AnnuallyRecurringValue.true,
        endYear: formData.endYear!,
        growthPercentage: formData.growthPercentage!,
      } as const satisfies ScenarioGiftFormShapeAnnuallyRecurring);
    }
  });

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  const isDiscountSelected = useWatch({
    control,
    name: 'discount',
  });

  const amount = useWatch({
    control,
    name: 'amount',
  });

  const discountPercent = useWatch({
    control,
    name: 'discountPercent',
  });

  const annuallyRecurringValue = useWatch({
    control,
    name: 'annuallyRecurring',
  });

  const isNewRecipient = useWatch({
    control,
    name: 'isNewRecipient',
  });

  return (
    <FormConfigurationProvider
      value={{ optionalDisplayType: 'optional-label' }}
    >
      <FormModal
        isOpen={isOpen}
        heading={heading}
        onClose={handleClose}
        actions={actions}
        rightHeaderContent={null}
      >
        <Stack
          component="form"
          ref={formRef}
          onSubmit={onSubmit}
          noValidate
          pb={1}
        >
          <FormLayoutRow>
            <FormLayoutItem>
              <ScenarioIncomeAndExpensesFormFields.Name />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <ScenarioIncomeAndExpensesFormFields.GiftType
                proposalId={proposalId}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={8}>
              <ScenarioIncomeAndExpensesFormFields.Recipient
                proposalId={proposalId}
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <Stack height="100%">
                <Box height={32} /> {/* Spacer to center the toggle */}
                <ScenarioIncomeAndExpensesFormFields.IsNewRecipient />
              </Stack>
            </FormLayoutItem>
          </FormLayoutRow>
          {isNewRecipient && (
            <Card variant="filled" sx={{ p: 2, pb: 3, mb: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h4">New recipient</Typography>
                <ScenarioIncomeAndExpensesFormFields.NewRecipientType />
                <ScenarioIncomeAndExpensesFormFields.NewRecipientName />
              </Stack>
            </Card>
          )}
          <FormLayoutRow>
            <FormLayoutItem width={5}>
              <ScenarioIncomeAndExpensesFormFields.Amount />
            </FormLayoutItem>
            <FormLayoutItem width={7}>
              <Stack height="100%" justifyContent="flex-end">
                <ScenarioIncomeAndExpensesFormFields.AnnuallyRecurring />
                <Box height={8} />
              </Stack>
            </FormLayoutItem>
          </FormLayoutRow>
          {annuallyRecurringValue === AnnuallyRecurringValue.true ? (
            <FormLayoutRow>
              <FormLayoutItem width={4}>
                <ScenarioIncomeAndExpensesFormFields.StartYear
                  isAnnuallyRecurring={true}
                />
              </FormLayoutItem>
              <FormLayoutItem width={4}>
                <ScenarioIncomeAndExpensesFormFields.EndYear />
              </FormLayoutItem>
              <FormLayoutItem width={4}>
                <ScenarioIncomeAndExpensesFormFields.GrowthRate />
              </FormLayoutItem>
            </FormLayoutRow>
          ) : (
            <FormLayoutRow>
              <FormLayoutItem>
                <ScenarioIncomeAndExpensesFormFields.StartYear
                  isAnnuallyRecurring={false}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          )}
          <Card variant="filled" sx={{ p: 2, mb: 3 }}>
            <FormLayoutRow>
              <FormLayoutItem width={3}>
                <Stack height="100%" justifyContent="center">
                  <ScenarioIncomeAndExpensesFormFields.Discount />
                </Stack>
              </FormLayoutItem>
              {isDiscountSelected && (
                <FormLayoutItem width={3}>
                  <ScenarioIncomeAndExpensesFormFields.DiscountPercent />
                </FormLayoutItem>
              )}
            </FormLayoutRow>
            {isDiscountSelected && (
              <Callout severity="info-high">
                Based on inputs above, discounted value for tax purposes is{' '}
                {discountPercent && amount
                  ? formatCurrencyNoDecimals(
                      new Decimal(1)
                        .minus(discountPercent.div(100))
                        .times(amount)
                    )
                  : EMPTY_CONTENT_HYPHEN}
              </Callout>
            )}
          </Card>
        </Stack>
      </FormModal>
    </FormConfigurationProvider>
  );
}

export function ScenarioGiftModal(props: ScenarioGiftModalProps) {
  const { giftData } = props;

  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis',
  });

  const { defaultValues } = useScenarioGiftFormDefaultValues({
    giftData,
    lengthOfAnalysis,
  });

  const formMethods = useForm<ScenarioGiftFormShape>({
    defaultValues,
    values: defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <ScenarioGiftModalInner {...props} />
    </FormProvider>
  );
}
