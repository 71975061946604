import React from 'react';

export interface ArrowMarkerProps {
  id: string;
  color: string;
  direction?: 'forward' | 'reverse';
}

export function ArrowMarker({
  color,
  id,
  direction = 'forward',
}: ArrowMarkerProps) {
  return (
    <marker
      id={id}
      markerWidth="10"
      markerHeight="12"
      viewBox="-10 -10 20 20"
      orient="auto"
      refX="0"
      refY="0"
      key={direction}
    >
      <polyline
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        fill={color}
        points={
          direction === 'forward'
            ? '-12,-6 0,0 -12,6 -12,-6'
            : '12,-6 0,0 12,6 12,-6'
        }
      />
    </marker>
  );
}
