import { useCallback } from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  EditableHeader,
  EditableHeaderProps,
} from '../EditableHeader/EditableHeader';

export interface FormAwareEditableHeaderProps<FormShape extends FieldValues>
  extends Omit<EditableHeaderProps, 'value' | 'onChange'> {
  fieldName: FieldNameFromFormShape<FormShape>;
  control: Control<FormShape>;
}

export function FormAwareEditableHeader<
  FormShape extends FieldValues,
  TFieldName extends
    FieldNameFromFormShape<FormShape> = FieldNameFromFormShape<FormShape>,
>({ fieldName, control, ...rest }: FormAwareEditableHeaderProps<FormShape>) {
  const { setValue } = useFormContext<FormShape>();
  const value = useWatch({
    control,
    name: fieldName,
  });

  const onChange = useCallback(
    (newValue: string) => {
      type FieldType = FormShape[TFieldName];
      setValue(fieldName as TFieldName, newValue as FieldType);
    },
    [fieldName, setValue]
  );

  return <EditableHeader value={value} onChange={onChange} {...rest} />;
}
