import Decimal from 'decimal.js';

export enum SummaryPanelTransferKind {
  Transfer = 'transfer',
  SaleLoan = 'sale_loan',
}

export interface HypotheticalTransfersSummaryPanelTransfer {
  targetDisplayName: string;
  startYear: number;
  endYear: number;
  transferTaxKind: string;
  transferValue: Decimal;
  targetId: string;
  id: string;
  name: string;
  // These are either hypotethicalTransfers or hypotheticalSaleLoans
  transferKind: SummaryPanelTransferKind;
}

export interface HypotheticalTransfersSummaryPanelSource {
  id: string;
  displayName: string;
  color: string | null;
  transfers: HypotheticalTransfersSummaryPanelTransfer[];
}

export const HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL =
  '__external_transfer' as const;

/** Map the ID of the source (or the external transfer sentinel) to the relevant data */
export type HypotheticalTransfersSummaryMap = Record<
  string,
  HypotheticalTransfersSummaryPanelSource
>;
