import { Grid, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { PropsWithChildren } from 'react';
import {
  FormProvider,
  Path,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { Callout } from '@/components/notifications/Callout/Callout';
import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { LifetimeExemptionCard } from '@/modules/gifting/LifetimeExemptionCard/LifetimeExemptionCard';
import {
  AddNewIncomeOrExpenseButton,
  AddOutOfEstatePortfolioButton,
  BaselineIncomeAndExpensesTable,
  GiftDesignerBasicInformation,
  GiftDesignerBasicInformationFormShape,
  OutOfEstatePortfoliosTable,
  useGiftDesignerBasicInformationDefaultValues,
} from '@/modules/gifting/proposal/designer/form';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

import {
  SaveGiftDesignerInputKind,
  useSaveGiftDesigner,
} from './hooks/useSaveGiftDesigner';

const GRID_GAP = 3 as const;
const LEFT_COLUMN_WIDTH = 4 as const;
const RIGHT_COLUMN_WIDTH = 8 as const;
const TOTAL_COLUMNS = LEFT_COLUMN_WIDTH + RIGHT_COLUMN_WIDTH;

export const AddItemHeaderRow = ({ children }: PropsWithChildren) => (
  <Stack
    direction="row"
    spacing={6}
    justifyContent="space-between"
    alignItems="center"
  >
    {children}
  </Stack>
);

interface GiftDesignerBasicInformationPageInnerProps {
  householdId: string;
  proposalId: string;
}

function GiftDesignerBasicInformationPageInner({
  householdId,
  proposalId,
}: GiftDesignerBasicInformationPageInnerProps) {
  const navigate = useNavigate();

  const { control, handleSubmit, formState } =
    useFormContext<GiftDesignerBasicInformationFormShape>();

  const isLoadingForm = formState.isLoading;

  const {
    fields: outOfEstatePortfolios,
    append: appendOutOfEstatePortfolio,
    update: updateOutOfEstatePortfolio,
    remove: removeOutOfEstatePortfolio,
  } = useFieldArray({
    control,
    name: 'outOfEstatePortfolios' as const satisfies Path<GiftDesignerBasicInformationFormShape>,
    keyName: '_id',
  });

  const {
    fields: incomeAndExpenses,
    append: appendIncomeOrExpense,
    update: updateIncomeOrExpense,
    remove: removeIncomeOrExpense,
  } = useFieldArray({
    control,
    name: 'baselineIncomeAndExpenses' as const satisfies Path<GiftDesignerBasicInformationFormShape>,
    keyName: '_id',
  });

  const { saveGiftDesigner, savedProposalId, loading } = useSaveGiftDesigner({
    proposalId,
    householdId,
  });

  const onValidSubmission: SubmitHandler<
    GiftDesignerBasicInformationFormShape
  > = (formValues) => {
    return saveGiftDesigner({
      kind: SaveGiftDesignerInputKind.SaveBasicInformation,
      formValues,
    });
  };

  useSubmitSuccessHandler(() => {
    if (!savedProposalId) {
      return;
    }

    navigate(
      getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS,
        {
          householdId,
          proposalId: savedProposalId,
          designerStage: GiftDesignerStages.MODEL_SCENARIOS,
        }
      )
    );
  });

  const onSubmit = handleSubmit(onValidSubmission);

  return (
    <Stack
      component="form"
      noValidate
      onSubmit={onSubmit}
      overflow="hidden"
      height={`calc(100% - ${FOOTER_HEIGHT}px)`}
    >
      <Stack p={3} spacing={5} height="100%" overflow="auto">
        <Grid container columns={TOTAL_COLUMNS}>
          <Grid
            item
            sm={LEFT_COLUMN_WIDTH}
            sx={{
              pr: GRID_GAP,
            }}
          >
            <Stack spacing={3}>
              <Typography variant="h1" component="h2">
                Overview
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  p: 3,
                  pb: 4,
                  borderRadius: '6px',
                }}
              >
                <Stack spacing={2}>
                  <GiftDesignerBasicInformation.Name />
                  <GiftDesignerBasicInformation.LengthOfAnalysis />
                </Stack>
              </Card>
              <LifetimeExemptionCard
                householdId={householdId}
                showMenu
                showTooltip
              />
            </Stack>
          </Grid>
          <Grid item sm={RIGHT_COLUMN_WIDTH}>
            <Stack spacing={3}>
              <Typography variant="h1" component="h2">
                Baseline assets
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  p: 3,
                  pb: 4,
                  borderRadius: '6px',
                }}
              >
                <Stack spacing={3}>
                  <AddItemHeaderRow>
                    <Typography variant="h1" component="h2">
                      In estate portfolio & cash flows
                    </Typography>
                    <AddNewIncomeOrExpenseButton
                      isLoadingForm={isLoadingForm}
                      onSubmitSuccess={(formData) => {
                        appendIncomeOrExpense(formData);
                      }}
                    />
                  </AddItemHeaderRow>
                  <Stack
                    direction="row"
                    p={3}
                    spacing={3}
                    bgcolor={COLORS.GRAY[100]}
                  >
                    <GiftDesignerBasicInformation.InEstatePortfolioInput />
                    <Stack justifyContent="end">
                      <Callout severity="info-high">
                        Cash flows added here impact the value of the in estate
                        portfolio across ALL scenarios.
                        <br />
                        <strong>
                          Scenario-specific cash flows should be added in the
                          next step.
                        </strong>
                      </Callout>
                    </Stack>
                  </Stack>
                </Stack>
                <BaselineIncomeAndExpensesTable
                  incomeAndExpenses={incomeAndExpenses}
                  onSubmitSuccess={(idx, formData) => {
                    updateIncomeOrExpense(idx, formData);
                  }}
                  onDelete={(idx) => {
                    removeIncomeOrExpense(idx);
                  }}
                  isLoadingForm={isLoadingForm}
                />
              </Card>
              <Card
                variant="outlined"
                sx={{
                  p: 3,
                  pb: 4,
                  borderRadius: '6px',
                }}
              >
                <Stack spacing={3}>
                  <AddItemHeaderRow>
                    <Typography variant="h1" component="h2">
                      Out of estate portfolios
                    </Typography>
                    <AddOutOfEstatePortfolioButton
                      isLoadingForm={isLoadingForm}
                      onSubmitSuccess={(formData) => {
                        appendOutOfEstatePortfolio(formData);
                      }}
                    />
                  </AddItemHeaderRow>
                  <OutOfEstatePortfoliosTable
                    outOfEstatePortfolios={outOfEstatePortfolios}
                    onSubmitSuccess={(idx, formData) => {
                      updateOutOfEstatePortfolio(idx, formData);
                    }}
                    onDelete={(idx) => {
                      removeOutOfEstatePortfolio(idx);
                    }}
                  />
                </Stack>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Footer
        rightAction={
          <Stack direction="row" spacing={2}>
            <Button
              size="sm"
              variant="secondary"
              data-testid="gift-designer-cancel"
              onClick={() => {
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                    {
                      householdId,
                    }
                  )
                );
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              loading={loading}
              data-testid="gift-designer-save-proceed"
            >
              Save & proceed
            </Button>
          </Stack>
        }
      />
    </Stack>
  );
}

export function GiftDesignerBasicInformationPage() {
  const householdId = useRequiredParam('householdId');
  const proposalId = useRequiredParam('proposalId');

  const { getDefaultValues } = useGiftDesignerBasicInformationDefaultValues({
    proposalId,
  });

  const formMethods = useForm<GiftDesignerBasicInformationFormShape>({
    defaultValues: async () => {
      return await getDefaultValues();
    },
  });

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <FormProvider {...formMethods}>
        <GiftDesignerBasicInformationPageInner
          householdId={householdId}
          proposalId={proposalId}
        />
      </FormProvider>
    </ErrorBoundary>
  );
}
