import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import { chunk } from 'lodash';
import { ComponentType, useMemo } from 'react';

import {
  BeneficiaryReportingContext,
  useBeneficiaryReportingContext,
} from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import {
  BenefitsOverviewBarCharts,
  MAX_NUMBER_OF_BARS,
} from '@/modules/beneficiaryReporting/benefitsOverview/BenefitsOverviewBarCharts';
import { usePrimaryBeneficiaryBars } from '@/modules/beneficiaryReporting/benefitsOverview/hooks/usePrimaryBeneficiaryBars';
import {
  BeneficiaryReporting_EstateWaterfallFragment,
  BeneficiaryReportingQuery,
} from '@/modules/beneficiaryReporting/graphql/BeneficiaryReporting.generated';
import { getBeneficiariesDataFromWaterfall } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { PrimaryClientDropdown_PossibleGrantorFragment } from '@/modules/entities/inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';

import {
  useBundleWaterfall,
  useClientPresentationDesignerV2Context,
} from '../../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Bundle } from '../../../../types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';

function EstateWaterfallBeneficiariesSlideContent({
  page,
  waterfall,
  SlideWrapper = Box,
}: EstateWaterfallBeneficiariesSlideInnerProps) {
  const {
    firstGrantor,
    secondGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
  } = useBeneficiaryReportingContext();

  const bars = usePrimaryBeneficiaryBars();
  const chunks = chunk(bars, MAX_NUMBER_OF_BARS);

  return (
    <>
      {chunks.map((_chunk, idx) => (
        <SlideWrapper key={`${waterfall.id}-beneficiaries-${idx}`}>
          <PresentationSlide.Slide
            id={page.id}
            leftHeaderContent={
              <PresentationSlide.MainHeading
                heading={waterfall.displayName}
                subheading="Beneficiaries"
              />
            }
            rightHeaderContent={
              <PresentationSlideSecondaryHeading
                clientDisplayName={waterfall.household?.displayName ?? null}
              />
            }
            footer={<PresentationSlide.Footer />}
            readyToScale
            data-presentationwaterfallready="true"
          >
            <Stack
              spacing={3}
              justifyContent="space-between"
              p={PRESENTATION_SPACING_UNITS}
              height="100%"
            >
              <BenefitsOverviewBarCharts
                firstGrantor={firstGrantor}
                secondGrantor={secondGrantor}
                firstGrantorDeathYear={firstGrantorDeathYear}
                secondGrantorDeathYear={secondGrantorDeathYear}
                page={idx}
              />
            </Stack>
          </PresentationSlide.Slide>
        </SlideWrapper>
      ))}
    </>
  );
}

interface EstateWaterfallBeneficiariesSlideInnerProps
  extends EstateWaterfallBeneficiariesSlideProps {
  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[];
  waterfall: BeneficiaryReporting_EstateWaterfallFragment;
}

function EstateWaterfallBeneficiariesSlideInner({
  bundle,
  page,
  SlideWrapper,
  waterfall,
  possibleGrantors,
}: EstateWaterfallBeneficiariesSlideInnerProps) {
  const { primaryClients } = useHouseholdDetailsContext();

  const firstGrantorDeathId = waterfall?.firstGrantorDeath.id;
  const firstGrantorDeathYear =
    waterfall?.firstGrantorDeathYear ?? getYear(new Date());
  const secondGrantorDeathYear =
    waterfall?.secondGrantorDeathYear ?? getYear(new Date());
  const [firstGrantor, secondGrantor] = useMemo(() => {
    const firstGrantor = primaryClients?.find(
      (g) => g.id === firstGrantorDeathId
    );
    const secondGrantor = primaryClients?.find(
      (g) => g.id !== firstGrantorDeathId
    );

    return [firstGrantor, secondGrantor];
  }, [firstGrantorDeathId, primaryClients]);

  const { loggedTransfers } = useClientPresentationDesignerV2Context();

  const beneficiariesData = getBeneficiariesDataFromWaterfall({
    waterfall,
    loggedTransfers,
    possibleGrantors,
  });

  // rebuild beneficiary reporting query data so we don't need to make a network request
  const queryData: BeneficiaryReportingQuery = {
    estateWaterfalls: {
      edges: [
        {
          node: waterfall,
        },
      ],
    },
    loggedTransfers: {
      edges: loggedTransfers.map((node) => ({
        node,
      })),
    },
  };

  /*
  useRegisterSlide({
    displayName: 'Beneficiaries',
    bundleId: bundle.id,
    id: page.id,
    index: 0, // TODO
  });
  */

  return (
    <BeneficiaryReportingContext.Provider
      value={{
        clientOrOrgId: null,
        beneficiariesData,
        firstGrantor,
        secondGrantor,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        queryData,
      }}
    >
      <EstateWaterfallBeneficiariesSlideContent
        waterfall={waterfall}
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        possibleGrantors={possibleGrantors}
      />
    </BeneficiaryReportingContext.Provider>
  );
}

export interface EstateWaterfallBeneficiariesSlideProps {
  bundle: ClientPresentationV2Bundle;
  page: ClientPresentationV2Page;
  SlideWrapper?: ComponentType<BoxProps>;
}
export function EstateWaterfallBeneficiarySlide({
  bundle,
  page,
  SlideWrapper,
}: EstateWaterfallBeneficiariesSlideProps) {
  const waterfall = useBundleWaterfall(bundle.id);
  const { beneficiaryWaterfallVizMap } =
    useClientPresentationDesignerV2Context();
  const { possibleGrantors } = useHouseholdDetailsContext();

  const beneficiaryWaterfallViz = beneficiaryWaterfallVizMap[bundle.id];

  if (!waterfall) {
    diagnostics.error(`Could not find waterfall for bundle ${bundle.id}`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Waterfall not found
      </Typography>
    );
  }

  if (!possibleGrantors) {
    diagnostics.error(`Could not find possible grantors`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Possible grantors not found
      </Typography>
    );
  }

  if (!beneficiaryWaterfallViz) {
    diagnostics.error(
      `Could not find beneficiary waterfall viz for bundle ${bundle.id}`
    );
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Beneficiary waterfall viz not found
      </Typography>
    );
  }

  const modifiedWaterfall = {
    ...waterfall,
    visualizationWithProjections: beneficiaryWaterfallViz,
  } as BeneficiaryReporting_EstateWaterfallFragment;

  return (
    <EstateWaterfallBeneficiariesSlideInner
      bundle={bundle}
      page={page}
      SlideWrapper={SlideWrapper}
      waterfall={modifiedWaterfall}
      possibleGrantors={possibleGrantors}
    />
  );
}
