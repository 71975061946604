// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../../types/schema';

import { gql } from '@apollo/client';
export type WaterfallOverviewBundleSettingsModal_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, displayName: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null, growthProfile?: { __typename?: 'GrowthProfile', id: string } | null, firstGrantorDeath: { __typename?: 'ClientProfile', id: string } };

export const WaterfallOverviewBundleSettingsModal_EstateWaterfallFragmentDoc = gql`
    fragment WaterfallOverviewBundleSettingsModal_EstateWaterfall on EstateWaterfall {
  id
  displayName
  parent {
    id
  }
  growthProfile {
    id
  }
  firstGrantorDeath {
    id
  }
  firstGrantorDeathYear
  secondGrantorDeathYear
}
    `;
export const Operations = {
  Fragment: {
    WaterfallOverviewBundleSettingsModal_EstateWaterfall: 'WaterfallOverviewBundleSettingsModal_EstateWaterfall' as const
  }
}