import { Stack, Typography } from '@mui/material';
import { first } from 'lodash';
import { useMemo } from 'react';

import { Divider } from '@/components/Divider';
import { Card } from '@/components/layout/Card/Card';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useBeneficiaryReportingContext } from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import {
  BeneficiariesData,
  BeneficiaryReport,
  ClientOrOrgId,
} from '@/modules/beneficiaryReporting/beneficiaryReporting.types';
import { getDefaultBeneficiaryReport } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { BenefitsBarChart } from '@/modules/beneficiaryReporting/individualBenefits/BenefitsBarChart';
import { BenefitsMetrics } from '@/modules/beneficiaryReporting/individualBenefits/BenefitsMetrics';
import { BenefitsTable } from '@/modules/beneficiaryReporting/individualBenefits/BenefitsTable';
import { LogNewGiftOrDistributionMenu } from '@/modules/beneficiaryReporting/LogNewGiftOrDistributionMenu';
import { SectionLabelCentered } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/components/SectionLabelCentered';
import { TransferOptionType } from '@/modules/gifting/TransferOption.type';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

export function ClientDetailsEstateWaterfallBeneficiaryReportingBeneficiaryDetailsPage() {
  const householdId = useRequiredParam('householdId');
  const clientOrOrgId: ClientOrOrgId = useRequiredParam('clientOrOrgId');
  const {
    beneficiariesData,
    firstGrantor,
    secondGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
    queryData,
  } = useBeneficiaryReportingContext();

  const hasTwoGrantors = !!secondGrantor && !!firstGrantor;

  const beneficiaryDataByAfterDeath = useMemo(() => {
    let beneficiaryDataCopy: BeneficiariesData = {
      [AfterDeath.None]: beneficiariesData[AfterDeath.None],
      [AfterDeath.First]: beneficiariesData[AfterDeath.First],
      [AfterDeath.Second]: beneficiariesData[AfterDeath.Second],
    };

    if (!hasTwoGrantors) {
      beneficiaryDataCopy = {
        [AfterDeath.None]: beneficiaryDataCopy[AfterDeath.None],
        [AfterDeath.First]: beneficiaryDataCopy[AfterDeath.First],
      } as BeneficiariesData;
    }

    return Object.entries(beneficiaryDataCopy) as [
      AfterDeath,
      Record<ClientOrOrgId, BeneficiaryReport>,
    ][];
  }, [beneficiariesData, hasTwoGrantors]);

  const { selectedBeneficiaryName, selectedBeneficiaryKind } = useMemo(() => {
    const household = first(getNodes(queryData.estateWaterfalls))?.household;

    const foundClient = (household?.clientProfiles ?? []).find(
      (c) => c.id === clientOrOrgId
    );

    const foundClientOrganization = (household?.clientOrganizations ?? []).find(
      (o) => o.id === clientOrOrgId
    );

    const selectedBeneficiaryName =
      foundClient?.firstName ??
      foundClientOrganization?.name ??
      EMPTY_CONTENT_HYPHEN;

    return {
      selectedBeneficiaryName,
      selectedBeneficiaryKind: foundClient
        ? TransferOptionType.Grantor
        : TransferOptionType.Organization,
    };
  }, [clientOrOrgId, queryData.estateWaterfalls]);

  const beneficiaryReportsForBeneficiaryWithAfterDeath: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[] = useMemo(() => {
    return beneficiaryDataByAfterDeath
      .flatMap(([afterDeath, beneficiaryReport]) => {
        if (!beneficiaryReport[clientOrOrgId]) {
          return [];
        }

        return {
          beneficiaryReport: beneficiaryReport[clientOrOrgId]!,
          afterDeath,
        };
      })
      .filter(({ beneficiaryReport }) => beneficiaryReport !== undefined);
  }, [beneficiaryDataByAfterDeath, clientOrOrgId]);

  return (
    <Stack spacing={3}>
      <Card variant="outlined" sx={{ p: 3 }}>
        <BenefitsMetrics
          beneficiaryReports={beneficiaryReportsForBeneficiaryWithAfterDeath}
          firstGrantor={firstGrantor}
          secondGrantor={secondGrantor}
          beneficiaryReportingQueryData={queryData}
        />
      </Card>
      <Card variant="outlined" sx={{ p: 3, pt: 4 }}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={6}
          >
            <Stack>
              <Typography variant="h3">Total projected benefits</Typography>
              <Typography variant="body1">
                {`The total amount available to ${selectedBeneficiaryName} now and after ${hasTwoGrantors ? `both ${firstGrantor.firstName} and ${secondGrantor.firstName}’s deaths` : `${firstGrantor?.firstName}’s death`}.`}
              </Typography>
            </Stack>
            <LogNewGiftOrDistributionMenu
              householdId={householdId}
              beneficiaryId={clientOrOrgId}
              beneficiaryKind={selectedBeneficiaryKind}
            />
          </Stack>
          <Stack width="100%">
            <BenefitsBarChart
              beneficiaryReports={
                beneficiaryReportsForBeneficiaryWithAfterDeath
              }
            />
          </Stack>
          <Divider
            sx={{
              borderColor: COLORS.GRAY[200],
            }}
          />
          {beneficiaryDataByAfterDeath.map(
            ([afterDeath, beneficiaryReport], idx) => (
              <Stack spacing={3} key={afterDeath}>
                <BenefitsTable
                  beneficiaryReport={
                    beneficiaryReport[clientOrOrgId] ??
                    getDefaultBeneficiaryReport()
                  }
                  afterDeath={afterDeath}
                  firstGrantor={firstGrantor}
                  secondGrantor={secondGrantor}
                  firstGrantorDeathYear={firstGrantorDeathYear}
                  secondGrantorDeathYear={secondGrantorDeathYear}
                />
                {idx !== beneficiaryDataByAfterDeath.length - 1 && (
                  <SectionLabelCentered
                    label={
                      (afterDeath === AfterDeath.None
                        ? `${firstGrantor?.firstName}’s projected death`
                        : `${secondGrantor?.firstName}’s projected death`) ??
                      EMPTY_CONTENT_HYPHEN
                    }
                  />
                )}
              </Stack>
            )
          )}
        </Stack>
      </Card>
    </Stack>
  );
}
