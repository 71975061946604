import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

import { diagnostics } from '@/utils/diagnostics';

type UserEventProperties = Record<
  string,
  string | number | boolean | undefined | null
>;

export enum TrackUserEventModule {
  WATERFALL = 'waterfall',
  ENTITY_DIAGRAM = 'entity_diagram',
  USER = 'user',
  ENTITY = 'entity',
  TESTAMENTARY_ENTITY = 'testamentary_entity',
  BALANCE_SHEET = 'balance_sheet',
  DOCUMENT = 'document',
  PROPOSAL = 'proposal',
  PRESENTATION = 'presentation',
  AI_ONBOARDING = 'ai_onboarding', // actions related to the AI onboarding process, such as creating a new household, generating individuals/orgs/entities/TE's from documents, etc.
  AI_DP = 'ai_dp', // actions related to the AI DP suggestions
  AI_SUGGESTION = 'ai_suggestion', // actions related to entity-level AI suggestions
  GROWTH_PROFILE = 'growth_profile',
  ONBOARDING_SIDEBAR = 'onboarding_sidebar',
  GIFT_LIST = 'gift_list',
  CLIENT_PROFILE = 'client_profile',
  ORGANIZATION = 'client_organization',
  LIABILITY = 'liability',
  GIFT = 'gift',
  TRANSFER = 'transfer',
  CASH_FLOW = 'cash_flow',
}

export type TrackUserEventActionDescription =
  `${TrackUserEventModule} ${string}`;

/**
 * @description Track a user event with PostHog.
 * @param module - The module that the event belongs to.
 * @param eventName - The name of the event to track. Should a verb in the past tense, e.g. 'downloaded'.
 * @param properties - Optional properties to include with the event.
 */
export function useTrackUserEvent() {
  const posthogClient = usePostHog();
  return useCallback(
    (
      actionDescription: TrackUserEventActionDescription,
      properties?: UserEventProperties
    ) => {
      diagnostics.debug(`[User event]: ${actionDescription}`, properties);
      posthogClient.capture(actionDescription, properties);
    },
    [posthogClient]
  );
}
