/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { TileVariant } from './types';

export const padding = 16;
export const width = 240;
export const iconSize = 24;

const lineOne = {
  color: COLORS.NAVY[900],
};
const lineTwo = {
  color: COLORS.GRAY[600],
};
const lineThree = {
  color: COLORS.GRAY[900],
};

function getBoxShadowFromVariant(
  variant: TileVariant,
  theme: Theme,
  dragging: boolean
) {
  if (variant === TileVariant.Empty) {
    return 'none';
  }

  if (dragging) {
    return theme.palette.shadows.lg;
  }

  return theme.palette.shadows.md;
}

export const useStyles = makeStyles<{
  variant: TileVariant;
  dragging: boolean;
  hover: boolean;
  viewOnly: boolean;
}>()((theme, { variant, dragging, viewOnly }) => {
  const isMapVariant =
    variant !== TileVariant.Empty && variant !== TileVariant.Standalone;

  const hoverStyles = { outlineWidth: '3px' };
  const viewOnlyStyles = viewOnly
    ? {
        '@media screen': {
          boxShadow: 'none',
        },
      }
    : {};

  return {
    wrapper: {
      display: !isMapVariant ? 'flex' : 'inline-block',
      height: !isMapVariant ? '100%' : 'auto',
      position: 'relative',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      padding,
      width,
      boxShadow: getBoxShadowFromVariant(variant, theme, dragging),
      // Hide shadow on print
      '@media print': {
        boxShadow: 'none',
      },
      ...viewOnlyStyles,
      outlineStyle: variant === TileVariant.Empty ? 'dashed' : 'solid',
      outlineWidth: '1px',
      borderRadius: '6px',
      '&:hover': hoverStyles,
    },
    hoverRoot: hoverStyles,
    groupedRoot: {},
    selectedRoot: {
      outlineWidth: '3px',
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    badgeText: {
      color: COLORS.PRIMITIVES.WHITE,
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
    badgeRow: {},
  };
});

export const usePrimaryStyles = makeStyles()((theme) => {
  const backgroundColor = theme.palette.dataVisualizationPrimary.lighter;
  return {
    wrapper: {},
    root: {
      backgroundColor,
      outlineColor: theme.palette.dataVisualizationPrimary.dark,
    },
    hoverRoot: {},
    groupedRoot: {
      backgroundColor: theme.palette.dataVisualizationPrimary.main,
    },
    selectedRoot: {
      backgroundColor: theme.palette.dataVisualizationPrimary.main,
      outlineColor: theme.palette.dataVisualizationPrimary.dark,
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    badgeText: {},
    icon: {
      color: COLORS.GRAY[500],
    },
    badgeRow: {},
  };
});

export const useSecondaryStyles = makeStyles()((theme) => {
  const backgroundColor = theme.palette.dataVisualizationSecondary.lighter;
  return {
    wrapper: {},
    root: {
      backgroundColor,
      outlineColor: theme.palette.dataVisualizationSecondary.dark,
    },
    hoverRoot: {},
    groupedRoot: {
      backgroundColor: theme.palette.dataVisualizationSecondary.main,
    },
    selectedRoot: {
      backgroundColor: theme.palette.dataVisualizationSecondary.main,
      outlineColor: theme.palette.dataVisualizationSecondary.dark,
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    badgeText: {},
    icon: {},
    badgeRow: {},
  };
});

export const useTertiaryStyles = makeStyles()((theme) => {
  const backgroundColor = theme.palette.dataVisualizationTertiary.lighter;
  return {
    wrapper: {},
    root: {
      backgroundColor,
      outlineColor: theme.palette.dataVisualizationTertiary.dark,
    },
    hoverRoot: {},
    groupedRoot: {
      backgroundColor: theme.palette.dataVisualizationTertiary.main,
    },
    selectedRoot: {
      backgroundColor: theme.palette.dataVisualizationTertiary.main,
      outlineColor: theme.palette.dataVisualizationTertiary.dark,
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    badgeText: {},
    icon: {},
    badgeRow: {},
  };
});

export const lightHover = {
  outlineColor: COLORS.GRAY[700],
};

export const lightSelected = {
  outlineColor: COLORS.GRAY[700],
  backgroundColor: COLORS.GRAY[200],
};

export const useLightStyles = makeStyles()((_theme) => ({
  wrapper: {},
  root: {
    backgroundColor: COLORS.GRAY[100],
    outlineColor: COLORS.GRAY[400],
    '&:hover': { ...lightHover },
  },
  hoverRoot: lightHover,
  groupedRoot: {
    backgroundColor: COLORS.GRAY[200],
  },
  selectedRoot: { ...lightSelected },
  linesWrapper: {},
  lineOne,
  lineTwo,
  lineThree,
  badgeText: {},
  icon: {
    color: COLORS.FUNCTIONAL.SUCCESS[500],
  },
  badgeRow: {},
}));

export const useDestructiveStyles = makeStyles()((theme) => {
  const backgroundColor = theme.palette.dataVisualizationNegative.lighter;
  return {
    wrapper: {},
    root: {
      backgroundColor,
      outlineColor: theme.palette.dataVisualizationNegative.dark,
    },
    hoverRoot: {},
    groupedRoot: {
      backgroundColor: theme.palette.dataVisualizationNegative.main,
    },
    selectedRoot: {
      backgroundColor: theme.palette.dataVisualizationNegative.main,
      outlineColor: theme.palette.dataVisualizationNegative.dark,
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    badgeText: {},
    icon: {},
    badgeRow: {},
  };
});

export const groupHover = {
  outlineColor: COLORS.GRAY[700],
};

export const groupSelect = {
  backgroundColor: COLORS.GRAY[300],
  outlineColor: COLORS.GRAY[700],
};

export const useGroupStyles = makeStyles()((_theme) => ({
  wrapper: {},
  root: {
    backgroundColor: COLORS.GRAY[200],
    outlineColor: COLORS.GRAY[400],
    '&:hover': { ...groupHover },
  },
  hoverRoot: groupHover,
  groupedRoot: {
    backgroundColor: COLORS.GRAY[300],
  },
  selectedRoot: { ...groupHover },
  linesWrapper: {},
  lineOne,
  lineTwo,
  lineThree,
  badgeText: {},
  icon: {},
  badgeRow: {},
}));

export const useStandaloneStyles = makeStyles()((_theme) => ({
  wrapper: {},
  root: {
    backgroundColor: COLORS.GRAY[100],
    outlineColor: COLORS.GRAY[400],
  },
  groupedRoot: {
    backgroundColor: COLORS.TEAL[200],
  },
  hoverRoot: {},
  selectedRoot: {},
  linesWrapper: {},
  lineOne,
  lineTwo,
  lineThree,
  badgeText: {},
  icon: {},
  badgeRow: {},
}));

export const useEmptyStyles = makeStyles()((_theme) => ({
  wrapper: {},
  root: {
    backgroundColor: COLORS.GRAY[200],
    justifyContent: 'center',
    outlineColor: COLORS.GRAY[400],
  },
  hoverRoot: {},
  groupedRoot: {
    backgroundColor: COLORS.TEAL[200],
  },
  selectedRoot: {},
  linesWrapper: {},
  lineOne: {
    color: COLORS.GRAY[500],
  },
  lineTwo,
  lineThree,
  badgeText: {},
  icon: {},
  badgeRow: {},
}));

export function useTileClasses(props: {
  variant: TileVariant;
  viewOnly: boolean;
  dragging?: boolean;
  classes?: WithClasses<typeof useStyles>;
  hover?: boolean;
}) {
  const { classes } = useStyles(
    {
      variant: props.variant,
      dragging: props.dragging ?? false,
      hover: props.hover ?? false,
      viewOnly: props.viewOnly,
    },
    { props }
  );

  const { classes: primaryClasses } = usePrimaryStyles();
  const { classes: secondaryClasses } = useSecondaryStyles();
  const { classes: tertiaryClasses } = useTertiaryStyles();
  const { classes: lightClasses } = useLightStyles();
  const { classes: destructiveClasses } = useDestructiveStyles();
  const { classes: groupClasses } = useGroupStyles();
  const { classes: standaloneClasses } = useStandaloneStyles();
  const { classes: emptyClasses } = useEmptyStyles();

  const variantClasses: Record<
    Exclude<TileVariant, TileVariant.Pixel>,
    typeof classes
  > = {
    primary: primaryClasses,
    secondary: secondaryClasses,
    tertiary: tertiaryClasses,
    light: lightClasses,
    destructive: destructiveClasses,
    group: groupClasses,
    standalone: standaloneClasses,
    empty: emptyClasses,
  };

  return {
    classes,
    variantClasses,
    primaryClasses,
    secondaryClasses,
    tertiaryClasses,
    lightClasses,
    destructiveClasses,
    groupClasses,
    standaloneClasses,
    emptyClasses,
  };
}
