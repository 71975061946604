import { Stack, Typography } from '@mui/material';
import React, { ComponentProps } from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { COLORS } from '@/styles/tokens/colors';

export interface MiniTableRow {
  label: React.ReactNode;
  value: React.ReactNode | string;
  labelTooltip?: string;
  labelProps?: ComponentProps<typeof Typography>;
  valueProps?: ComponentProps<typeof Typography>;
}

interface Props {
  rows: MiniTableRow[];
  variant: 'default' | 'tally';
  rowProps?: ComponentProps<typeof Stack>;
}

export function MiniTable({ rows, variant, rowProps }: Props) {
  let tallyRow: MiniTableRow | undefined;
  const displayRows = rows.reduce((acc, row, idx) => {
    if (variant === 'tally' && idx === rows.length - 1) {
      tallyRow = row;
      return acc;
    } else {
      return [...acc, row];
    }
  }, [] as MiniTableRow[]);

  return (
    <Stack spacing={1}>
      {displayRows.map((row, i) => {
        const Value =
          typeof row.value === 'string' ? (
            <Typography
              variant="h6"
              component="span"
              {...(row.valueProps ?? {})}
            >
              {row.value}
            </Typography>
          ) : (
            row.value
          );

        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            key={`mini-table-row-${i}`}
            sx={{
              px: variant === 'tally' ? 1 : 0,
              ...(rowProps?.sx ?? {}),
            }}
            {...rowProps}
          >
            <Stack direction="row" alignItems="center">
              <Typography
                component="span"
                variant="subtitle2"
                {...(row.labelProps ?? {})}
              >
                {row.label}
              </Typography>
              {row.labelTooltip && (
                <ContextualHelpTooltip>
                  <PopperContent body={row.labelTooltip} />
                </ContextualHelpTooltip>
              )}
            </Stack>
            {Value}
          </Stack>
        );
      })}
      {tallyRow && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{ borderTop: `1px solid ${COLORS.NAVY[900]}`, px: 1, pt: 1 }}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle2">{tallyRow.label}</Typography>
            {tallyRow.labelTooltip && (
              <ContextualHelpTooltip>
                <PopperContent body={tallyRow.labelTooltip} />
              </ContextualHelpTooltip>
            )}
          </Stack>
          <Typography
            variant="h6"
            component="span"
            {...(tallyRow.valueProps ?? {})}
          >
            {tallyRow.value}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
