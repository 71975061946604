import { Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { useCallback, useMemo, useState } from 'react';

import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import {
  useWaterfallSummaryContext,
  WaterfallSummaryTableItem,
} from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getEstateTaxTabs } from '@/modules/summaryPanels/EstateTaxSummaryPanel/EstateTaxSummaryPanel.utils';
import { AfterDeath } from '@/types/schema';

import { useWaterfallSummaryTableRows } from './useWaterfallSummaryTableRows';
import { getWaterfallSummaryTableColumns } from './WaterfallSummaryTable.utils';

interface WaterfallSummaryTableProps {
  onOpenSectionSummaryPanel: (afterDeath: AfterDeath) => void;
}

export function WaterfallSummaryTable({
  onOpenSectionSummaryPanel,
}: WaterfallSummaryTableProps) {
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const [currentRow, setCurrentRow] = useState<
    GridRowParams<WaterfallSummaryTableItem>['row'] | null
  >(null);
  const { waterfall, summaryPanel } = useWaterfallSummaryContext();

  const { activeTab, setActiveTab } = useActiveTab<AfterDeath>({
    defaultTab: AfterDeath.None,
    filterSearchParam: 'selectedPassing',
  });

  const tabValues = useMemo(() => {
    return getEstateTaxTabs(isTwoClientHousehold);
  }, [isTwoClientHousehold]);

  const rowMap = useWaterfallSummaryTableRows(waterfall, primaryClients || []);

  const onClickTab = useCallback(
    (tabValue: AfterDeath) => {
      setActiveTab(tabValue);

      if (!summaryPanel.isModalOpen) {
        return;
      }

      // update the side panel with the equivalent row for the new afterDeath
      const matchingOtherDeathRow =
        currentRow?.id &&
        rowMap[tabValue].find((row) => row.id === currentRow.id);

      if (currentRow?.kind === 'tax') {
        onOpenSectionSummaryPanel(tabValue);
      } else if (matchingOtherDeathRow) {
        summaryPanel.openModal({ type: 'vizNode', row: matchingOtherDeathRow });
      } else {
        summaryPanel.closeModal();
      }
    },
    [
      currentRow?.id,
      currentRow?.kind,
      onOpenSectionSummaryPanel,
      rowMap,
      setActiveTab,
      summaryPanel,
    ]
  );

  const displayStrings = useWaterfallGrantorDeathDisplay(waterfall);
  const TabsForClient = useMemo(() => {
    return tabValues.map((tabValue) => {
      return (
        <ButtonTab
          data-testid={`WaterfallSummaryTable-tab-${tabValue}`}
          key={tabValue}
          display={displayStrings[tabValue]}
          isActive={activeTab === tabValue}
          onClick={() => onClickTab(tabValue)}
        />
      );
    });
  }, [activeTab, displayStrings, onClickTab, tabValues]);

  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const columns = useMemo(() => {
    let growthProfileName = '';
    let growthRate: Decimal | null =
      waterfall?.assetGrowthReturn || new Decimal(0);
    if (isGrowthProfilesEnabled) {
      growthProfileName = waterfall?.growthProfile?.displayName || '';
      growthRate = null;
    }
    return getWaterfallSummaryTableColumns(growthRate, growthProfileName);
  }, [
    isGrowthProfilesEnabled,
    waterfall?.assetGrowthReturn,
    waterfall?.growthProfile?.displayName,
  ]);

  const onRowClick = useCallback(
    ({ row }: GridRowParams<WaterfallSummaryTableItem>) => {
      setCurrentRow(row);
      if (row.kind === 'tax') {
        onOpenSectionSummaryPanel(row.afterDeath);
        return;
      }

      summaryPanel.openModal({ type: 'vizNode', row });
    },
    [onOpenSectionSummaryPanel, summaryPanel]
  );

  return (
    <TabsProvider currentTab={activeTab}>
      <Stack spacing={2.5}>
        <Tabs fullWidth={false}>{TabsForClient}</Tabs>
        <Stack>
          <DataTable
            rows={rowMap[activeTab]}
            columns={columns}
            onRowClick={onRowClick}
            pageSize={PageSizes.Twenty}
          />
        </Stack>
      </Stack>
    </TabsProvider>
  );
}
