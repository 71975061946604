import { useCallback } from 'react';

import { useSectionNodesResizeHandlers } from '@/components/diagrams/FlowChart/nodes/SectionLabelNode/hooks';

import { EntityDiagramEffectFn, RecomputeSectionWidthsEffect } from '../types';

export function useRecomputeSectionWidthsEffect(): EntityDiagramEffectFn<RecomputeSectionWidthsEffect> {
  const { forceSectionNodesRelayout } = useSectionNodesResizeHandlers();

  return useCallback<EntityDiagramEffectFn<RecomputeSectionWidthsEffect>>(
    (_state, _effect, _dispatch) => {
      forceSectionNodesRelayout();
    },
    [forceSectionNodesRelayout]
  );
}
