import { Position } from '@xyflow/react';

import { ArrowEdgeVariant } from '@/components/diagrams/FlowChart/edges/ArrowEdge';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import {
  EntityDiagram_EdgeFragment,
  EntityDiagram_NodeFragment,
  EntityDiagramGraphAttributes,
  EntityDiagramGraphEdgeAttributes,
  EntityDiagramGraphNodeAttributes,
  GraphNode,
  GraphNodeData,
} from '../types';
import { createEdge } from './entityDiagramGraph';
import { getNodeId } from './utils';

// Utility functions for edge operations
function getArrowEdgeVariantFromEdgeKind(): ArrowEdgeVariant {
  return 'primary';
}

function getTargetHandleFromEdgeKind(): Position {
  return Position.Top;
}

function getSourceHandleFromEdgeKind(): Position {
  return Position.Bottom;
}

const getEdgeSource = (edge: EntityDiagram_EdgeFragment) =>
  getNodeId({
    id: edge.from.id,
    afterDeath: edge.from.afterDeath,
  });

const getEdgeTarget = (edge: EntityDiagram_EdgeFragment) =>
  getNodeId({
    id: edge.to.id,
    afterDeath: edge.to.afterDeath,
  });

interface DrawEdgesInput {
  viz: {
    nodes: EntityDiagram_NodeFragment[];
    edges: EntityDiagram_EdgeFragment[];
  };
  graph: FlowChartGraph<
    EntityDiagramGraphNodeAttributes<GraphNode, GraphNodeData>,
    EntityDiagramGraphEdgeAttributes,
    EntityDiagramGraphAttributes
  >;
}

export function drawEdges({ viz, graph }: DrawEdgesInput): void {
  const createAndAddEdge = (source: string, target: string) => {
    const edge = createEdge({
      source,
      target,
      targetHandle: getTargetHandleFromEdgeKind(),
      sourceHandle: getSourceHandleFromEdgeKind(),
      data: {
        variant: getArrowEdgeVariantFromEdgeKind(),
      },
    });

    if (!graph.hasNode(source) || !graph.hasNode(target)) {
      return;
    }

    graph.addEdgeSafe(source, target, { type: 'default', edge });
  };

  // Add edges between ungrouped nodes
  viz.edges.forEach(({ to, from, kind, ...rest }) => {
    const source = getEdgeSource({ to, from, kind, ...rest });
    const target = getEdgeTarget({ to, from, kind, ...rest });

    createAndAddEdge(source, target);
  });
}
