import { Box } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { LiabilityPaymentKind } from '@/types/schema';

import { CalculatedLiabilityPaymentDetails } from './CalculatedLiabilityPaymentDetails';
import {
  LiabilityFormPaths,
  LiabilityFormShape,
} from './LiabilityModal.constants';
import {
  paymentFrequencyOptions,
  paymentKindOptions,
} from './LiabilityPaymentDetailsForm.constants';

interface LiabilityPaymentDetailsFormProps {
  onDeletePaymentDetails: () => void;
}

export function LiabilityPaymentDetailsForm({
  onDeletePaymentDetails,
}: LiabilityPaymentDetailsFormProps) {
  const { control } = useFormContext<LiabilityFormShape>();
  const paymentKind = useWatch({
    name: `paymentKind` as const satisfies LiabilityFormPaths,
    control,
  });

  return (
    <Box>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareRadioGroup
            control={control}
            fieldName={`paymentKind` as const satisfies LiabilityFormPaths}
            options={paymentKindOptions}
            stackingDirection="row"
            row
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareSelectInput
            label="Payment frequency"
            required
            options={paymentFrequencyOptions}
            control={control}
            fieldName={`paymentFrequency` as const satisfies LiabilityFormPaths}
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareCurrencyInput
            label="Payment amount"
            required
            isDecimalJSInput
            control={control}
            fieldName={`paymentAmount` as const satisfies LiabilityFormPaths}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={4}>
          <FormAwarePercentInput
            label="Interest rate"
            required
            isDecimalJSInput
            control={control}
            fieldName={`interestRate` as const satisfies LiabilityFormPaths}
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareDatePickerInput
            label="Term start"
            required
            control={control}
            fieldName={`termStartDate` as const satisfies LiabilityFormPaths}
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareDatePickerInput
            label="Term end"
            required
            control={control}
            fieldName={`termEndDate` as const satisfies LiabilityFormPaths}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {paymentKind === LiabilityPaymentKind.InterestOnly && (
        <FormLayoutRow>
          <FormLayoutItem>
            <Card variant="filled-dark" sx={{ p: 2 }}>
              <CalculatedLiabilityPaymentDetails />
            </Card>
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <Callout severity="warning">
        Payment details will update the outstanding balance of the liability
        automatically for the current value and in waterfall projections.
      </Callout>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="destructive-transparent"
          size="sm"
          onClick={onDeletePaymentDetails}
          startIcon={Trash01Icon}
        >
          Remove payment details
        </Button>
      </Box>
    </Box>
  );
}
