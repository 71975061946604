import { Box, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { Divider } from '@/components/Divider';
import {
  FormAwareButtonGroup,
  FormAwareButtonGroupProps,
} from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTypeaheadMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { CardWithHeader } from '@/components/layout/CardWithHeader';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { useAssetOptions } from './hooks/useAssetOptions';
import { ReviewGrowthProfilesButton } from './ReviewGrowthProfilesButton';
import {
  calculateTotalValue,
  DollarPercentValues,
} from './SaleLoanAssetsForm.utils';

function DollarPercentButtonGroup(
  props: SetOptional<
    FormAwareButtonGroupProps<HypotheticalSaleLoanFormShape>,
    'options'
  >
) {
  return (
    <Box width={80}>
      <FormAwareButtonGroup
        variant="onDark"
        options={[
          { display: '$', value: DollarPercentValues.DOLLAR },
          { display: '%', value: DollarPercentValues.PERCENT },
        ]}
        {...props}
      />
    </Box>
  );
}

export function SaleLoanAssetsForm() {
  const [assetTypeaheadInputValue, setAssetTypeaheadInputValue] = useState('');
  const {
    sellerId,
    assetOptions,
    assetOptionDetails,
    totalSellerValue,
    loading,
  } = useAssetOptions();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const proRataPattern = useWatch({
    control,
    name: 'proRataPattern',
  });
  const assetsPattern = useWatch({
    control,
    name: 'assetsPattern',
  });
  const fundingKind = useWatch({
    control,
    name: 'fundingKind',
  });
  const selectedAssetClassAndBusinessValues = useWatch({
    control,
    name: 'selectedAssetClassAndBusinessValues',
  });

  const selectedAssetIds = useWatch({
    control,
    name: 'selectedAssetClassesAndBusinessIds',
  });

  // get the asset details for the selectedAssetIds
  const selectedAssetDetails = useMemo(() => {
    if (!selectedAssetIds || !assetOptionDetails) return [];
    return compact(
      selectedAssetIds.map((id) => {
        const details = assetOptionDetails[id];
        if (!details) return null;
        return {
          id,
          ...details,
        };
      })
    ).sort((a, b) => {
      return a.totalValue.comparedTo(b.totalValue);
    });
  }, [selectedAssetIds, assetOptionDetails]);

  // This is the total value of the selected assets in the asset-specific pattern
  const totalSelectedValue = useMemo(() => {
    return calculateTotalValue({
      selectedAssetIds,
      selectedAssetClassAndBusinessValues,
      assetOptionDetails,
      pattern: assetsPattern,
    });
  }, [
    selectedAssetIds,
    selectedAssetClassAndBusinessValues,
    assetOptionDetails,
    assetsPattern,
  ]);

  return (
    <Box>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <CardWithHeader title="Fund sale using">
            <FormAwareRadioGroup<HypotheticalSaleLoanFormShape>
              fieldName={
                'fundingKind' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Fund sale using"
              hideLabel
              required
              control={control}
              row={false}
              options={[
                {
                  label: '$ or % of current entity value',
                  value: 'proRata',
                  selectedOptionAdornment: (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="flex-start"
                      py={1}
                    >
                      <DollarPercentButtonGroup
                        fieldName={
                          'proRataPattern' as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Dollar or percent of current entity value"
                        hideLabel
                        required
                        control={control}
                      />
                      {proRataPattern === DollarPercentValues.DOLLAR && (
                        <FormAwareCurrencyInput
                          fieldName={
                            'proRataAmount' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Dollar value"
                          hideLabel
                          helpText={`Current net value of ${formatCurrencyNoDecimals(totalSellerValue)}`}
                          control={control}
                          required
                          isDecimalJSInput
                        />
                      )}
                      {proRataPattern === DollarPercentValues.PERCENT && (
                        <FormAwarePercentInput
                          fieldName={
                            'proRataPercent' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Percent value"
                          hideLabel
                          helpText={`Current net value of ${formatCurrencyNoDecimals(totalSellerValue)}`}
                          control={control}
                          isDecimalJSInput
                          required
                        />
                      )}
                    </Stack>
                  ),
                },
                {
                  label: 'Specific owned businesses & asset categories',
                  value: 'assets',
                  selectedOptionAdornment: (
                    <Stack
                      direction="row"
                      py={1}
                      spacing={1}
                      alignItems="flex-start"
                    >
                      <DollarPercentButtonGroup
                        fieldName={
                          'assetsPattern' as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Dollar or percent input for assets"
                        hideLabel
                        control={control}
                      />
                      <Box flexGrow={1}>
                        <FormAwareTypeaheadMultiSelectInput
                          inputValue={assetTypeaheadInputValue}
                          onInputChange={setAssetTypeaheadInputValue}
                          groupBy={(option) => option.groupName ?? ''}
                          fieldName={
                            'selectedAssetClassesAndBusinessIds' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Asset classes and businesses"
                          placeholder="Select asset classes and businesses"
                          hideLabel
                          required
                          control={control}
                          disabled={!sellerId}
                          helpText={
                            sellerId
                              ? undefined
                              : 'Select a seller to see available assets'
                          }
                          options={assetOptions}
                          loading={loading}
                        />
                      </Box>
                    </Stack>
                  ),
                },
              ]}
            />
          </CardWithHeader>
        </FormLayoutItem>
      </FormLayoutRow>
      {fundingKind === 'assets' && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <Stack spacing={1} divider={<Divider />}>
              {selectedAssetDetails.length === 0 && (
                <Box textAlign="center" py={1}>
                  <Typography variant="subtitle1">
                    No assets selected
                  </Typography>
                </Box>
              )}
              {selectedAssetDetails.map((asset) => (
                <Stack
                  key={asset.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  px={2}
                >
                  <Stack>
                    <Typography variant="h5" component="div">
                      {asset.displayName}
                    </Typography>
                    {asset.ownershipPercentage && (
                      <Typography variant="subtitle2">
                        {formatPercent(asset.ownershipPercentage)}%
                      </Typography>
                    )}
                    <Typography>{formatCurrency(asset.totalValue)}</Typography>
                  </Stack>
                  <Box flexShrink={0} maxWidth={200}>
                    {assetsPattern === DollarPercentValues.DOLLAR && (
                      <FormAwareCurrencyInput
                        fieldName={
                          `selectedAssetClassAndBusinessValues.${asset.id}.amount` as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Asset class and business value"
                        hideLabel
                        control={control}
                        isDecimalJSInput
                      />
                    )}
                    {assetsPattern === DollarPercentValues.PERCENT && (
                      <FormAwarePercentInput
                        fieldName={
                          `selectedAssetClassAndBusinessValues.${asset.id}.percent` as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Asset class and business value"
                        hideLabel
                        control={control}
                        isDecimalJSInput
                      />
                    )}
                  </Box>
                </Stack>
              ))}
            </Stack>
            <Box
              sx={{
                borderTop: '2px solid',
                borderColor: COLORS.NAVY[500],
                p: 2,
                mt: 2,
                textAlign: 'right',
              }}
            >
              <Typography fontWeight="bold">
                {formatCurrency(totalSelectedValue)}
              </Typography>
            </Box>
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <ReviewGrowthProfilesButton />
    </Box>
  );
}
