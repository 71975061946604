import { Box, Stack } from '@mui/material';

import { Callout } from '@/components/notifications/Callout/Callout';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

export function PrintOnlyClientDashboardFooter() {
  const rate = useFederalEstateTaxFormatted();
  return (
    <Stack direction="column" spacing={2} pt={6}>
      <Box>
        <Callout severity="info-low" textVariant="label">
          Implied estate tax savings
        </Callout>
        <Callout severity="info-low">
          Implied estate tax savings assumes assets would otherwise be taxable
          at a {rate}% federal estate tax rate. Does not account for state
          transfer taxes.
        </Callout>
      </Box>
      <Box>
        <Callout severity="info-low" textVariant="label">
          Implied wealth transferred
        </Callout>
        <Callout severity="info-low">
          Wealth transferred is defined as the sum of direct gifts to
          individuals/charity, and the current value of DAFs & foundations,
          GRATs, and Irrevocable trusts/SLATs.
        </Callout>
      </Box>
      <Box>
        <Callout severity="info-low" textVariant="label">
          Growth out of the estate
        </Callout>
        <Callout severity="info-low">
          Growth out of the estate is defined as the current entity value,
          netting out any transactions (plus distributions and less
          contributions or gifts) made to the entity over time. This value may
          be overstated if no transactions have been logged on the entity.
        </Callout>
      </Box>
    </Stack>
  );
}
