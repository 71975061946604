import { PresentationPageKind } from '@/types/schema';

import { getNewPageId } from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2Page } from '../../types/ClientPresentationV2.PresentationPageType';

export function updatePageArrayForPageInclusion(
  pages: ClientPresentationV2Page[],
  pageKind: PresentationPageKind,
  pageIsIncluded: boolean,
  pageConfiguration?: ClientPresentationV2Page
) {
  const newPages = pages.slice();

  const pageIndex = newPages.findIndex((page) => page.type === pageKind);

  if (pageIsIncluded && pageIndex === -1) {
    newPages.push(
      pageConfiguration ?? {
        type: pageKind,
        id: getNewPageId(pageKind),
      }
    );
  } else if (!pageIsIncluded && pageIndex >= 0) {
    newPages.splice(pageIndex, 1);
  }

  return newPages;
}
