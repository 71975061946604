import { Stack } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { Divider } from '@/components/Divider';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { RowData as BeneficiaryRowData } from '@/modules/trusts/TrustBeneficiariesList/FlatBeneficiaryTable.types';
import { TermDetailsMiniTable } from '@/modules/trusts/TrustDetails/TermDetailsMiniTable';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { TrustSummaryMiniTable } from '@/modules/trusts/TrustDetails/TrustSummaryMiniTable';
import { COLORS } from '@/styles/tokens/colors';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { GeneratedDispositionScenarioSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { DispositionScenarioTable } from '../../components/DispositionScenarioSlide/DispositionScenarioTable';
import { RowData as DispositionRowData } from '../../components/DispositionScenarioSlide/DispositionScenarioTable.types';
import { NotesCard } from '../../components/DispositionScenarioSlide/NotesCard';
import { EntityValuation } from '../../components/EntityValuation';
import { SummarySlideLayout } from '../../components/SummarySlideLayout';
import { TrustAdvisors } from '../../components/TrustAdvisors';
import { Trustees } from '../../components/Trustees';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';
import { PersonalAccountBeneficiariesV2 } from '../../personalAccounts/components/PersonalAccount.BeneficiariesV2';

export interface GRATTrustSummarySlideProps {
  entity: EntityPresentation_EntityFragment;
  summary: TrustSummaryData;
  displayName: string;
  registrationProps: PresentationSlideRegistrationDetails;
  dispositionScenario?: GeneratedDispositionScenarioSlideProps;
  grantors: {
    displayName: string;
    id: string;
  }[];
  dispositionRowsByDeath: [DispositionRowData[], DispositionRowData[]] | null;
  beneficiariesGroupRows: BeneficiaryRowData[][] | null[];
  pageIndex: number;
  hideBeneficiaries?: boolean;
  paginatedDescription: string | null;
}

export function GRATTrustSummarySlide({
  displayName,
  entity,
  summary,
  registrationProps,
  dispositionScenario,
  grantors,
  dispositionRowsByDeath,
  beneficiariesGroupRows,
  pageIndex,
  hideBeneficiaries,
  paginatedDescription,
}: GRATTrustSummarySlideProps) {
  const slideId = `${registrationProps.slideId}_${pageIndex + 1}`;

  useRegisterSlide({
    displayName: 'Entity Summary',
    bundleId: registrationProps.bundleId,
    id: slideId,
    index: registrationProps.bundleIndex,
    skip: pageIndex > 0,
  });

  const LeftContent = useMemo(() => {
    return compact([
      <EntityValuation key="entity-valuation" entity={entity} />,
      <TrustSummaryMiniTable
        key="grat-trust-details-mini-table"
        {...summary}
      />,
      <TermDetailsMiniTable key="term-details-mini-table" {...summary} />,
      <Divider
        key="divider-0"
        sx={{ borderColor: COLORS.NEUTRAL_GRAY[200] }}
      />,
      <Trustees
        key="trustees"
        trustees={summary.trustees}
        successorTrustees={summary.successorTrustees}
        maxItems={3}
      />,
      <Divider
        key="divider-1"
        sx={{ borderColor: COLORS.NEUTRAL_GRAY[200] }}
      />,
      <TrustAdvisors
        key="trust-advisors"
        trustAdvisors={summary.trustAdvisors}
        maxItems={3}
      />,
    ]);
  }, [entity, summary]);

  const RightContent = useMemo(() => {
    const children = compact([
      ...(hideBeneficiaries
        ? []
        : beneficiariesGroupRows.map((rows, idx) => (
            <PersonalAccountBeneficiariesV2
              key="grat-trust-beneficiaries-v2"
              rows={rows}
              heading={summary.beneficiariesGroups[idx]?.heading}
            />
          ))),
      <DispositionScenarioTable
        key="grat-trust-disposition-scenario-table"
        rowDataByDeath={dispositionRowsByDeath}
        primaryClients={grantors}
        dispositionScenario={dispositionScenario?.dispositionScenario}
      />,
      <NotesCard key="grat-trust-notes" description={paginatedDescription} />,
    ]);

    return <Stack spacing={2.5}>{children}</Stack>;
  }, [
    beneficiariesGroupRows,
    dispositionRowsByDeath,
    dispositionScenario?.dispositionScenario,
    grantors,
    hideBeneficiaries,
    summary.beneficiariesGroups,
    paginatedDescription,
  ]);

  return (
    <PresentationSlide.Slide
      id={slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={displayName}
          subheading={entity.extendedDisplayKind}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <SummarySlideLayout
        LeftContent={LeftContent}
        RightContent={RightContent}
        pageIndex={pageIndex}
      />
    </PresentationSlide.Slide>
  );
}
