import { compact } from 'lodash';

import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { Settings01Icon } from '@/components/icons/Settings01Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

export interface ContextMenuItemsProps {
  bundleId?: string;
  onDuplicateBundle?: () => void;
  onManageBundle?: () => void;
  onDeleteBundle?: () => void;
}

function WaterfallBundleItems({
  bundleId,
  onDuplicateBundle,
  onManageBundle,
  onDeleteBundle,
}: ContextMenuItemsProps) {
  return (
    <>
      {compact([
        onDuplicateBundle ? (
          <MenuItem
            key={`${bundleId}-duplicate`}
            label="Duplicate waterfall"
            onClick={onDuplicateBundle}
            icon={<Copy07Icon size={16} />}
            typographyProps={{
              variant: 'subtitle2',
              color: COLORS.NAVY[900],
            }}
          />
        ) : null,
        onManageBundle ? (
          <MenuItem
            key={`${bundleId}-manage`}
            label="Manage waterfall"
            onClick={onManageBundle}
            icon={<Settings01Icon size={16} />}
            muiMenuItemProps={{
              divider: true,
            }}
            typographyProps={{
              variant: 'subtitle2',
              color: COLORS.NAVY[900],
            }}
          />
        ) : null,
        onDeleteBundle ? (
          <MenuItem
            key={`${bundleId}-delete`}
            label="Delete waterfall"
            onClick={onDeleteBundle}
            typographyProps={{
              sx: { color: COLORS.FUNCTIONAL.ERROR.DEFAULT },
              variant: 'subtitle2',
            }}
            icon={
              <Trash01Icon color={COLORS.FUNCTIONAL.ERROR.DEFAULT} size={16} />
            }
          />
        ) : null,
      ])}
    </>
  );
}

export const CONTEXT_MENU_MAP: Partial<
  Record<
    PresentationBundleKind | PresentationPageKind,
    ((props: ContextMenuItemsProps) => JSX.Element) | null
  >
> = {
  [PresentationBundleKind.CoverSlideBundle]: null,
  [PresentationBundleKind.TableOfContentsBundle]: null,
  [PresentationBundleKind.WaterfallOverviewBundle]: WaterfallBundleItems,
};
