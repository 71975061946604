import { MenuItem, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface AppNotificationListItemProps {
  icon: JSX.Element;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

export function AppNotificationListItem({
  icon,
  title,
  subtitle,
  children,
}: PropsWithChildren<AppNotificationListItemProps>) {
  return (
    <MenuItem sx={{ p: 2, pb: 3 }} data-testid="app-notification-item">
      <Stack direction="column">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          width={352}
          overflow="hidden"
        >
          {icon}
          <Stack flex={1} overflow="hidden" textOverflow="ellipsis">
            {typeof title === 'string' ? (
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
            ) : (
              title
            )}
            {typeof subtitle === 'string' ? (
              <Typography variant="subtitle2" noWrap>
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </MenuItem>
  );
}
