import { BoxProps } from '@mui/material';
import { ComponentType } from 'react';

import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';

import { EntityPresentation_EntityFragment } from './graphql/EntityPresentation.generated';

/**
 * These are the types of slides that can be rendered for a single entity type. This enum is likely to expand over time.
 * Not every entity type will have every slide type.
 */
export enum EntityPresentationSlideType {
  OVERVIEW = 'OVERVIEW',
  SUMMARY = 'SUMMARY',
  TRUST_FIDUCIARIES = 'TRUST_FIDUCIARIES', // trustees and trust advisors
  BENEFICIARIES = 'BENEFICIARIES',
  DISPOSITIVE_PROVISIONS = 'DISPOSITIVE_PROVISIONS',
  BUSINESS_OWNERSHIP = 'BUSINESS_OWNERSHIP',
  BUSINESS_KEY_PEOPLE = 'BUSINESS_KEY_PEOPLE',
  INSURANCE_POLICIES = 'INSURANCE_POLICEIS',
}

/**
 * @description The type definition for the props of the BundleWrapper, which is the top-level file and component
 * that renders the presentation for a single entity type.
 */
export interface EntityPresentationBundleProps<Subtype>
  extends EntityPresentationBundleSlideSharedProps<Subtype> {
  entityId: string;
  dispositionScenarios: DispositiveProvisions_DispositionScenarioFragment[];
  slideTypes: EntityPresentationSlideType[];
  SlideWrapper?: ComponentType<BoxProps>;
  isSingleEntityPrint?: boolean;
}

export interface PresentationSlideRegistrationDetails {
  // this is the index of the slide within the bundle
  bundleIndex: number;
  slideId: string;
  bundleId: string;
}

export interface EntityPresentationBundleSlideSharedProps<Subtype> {
  // because slides handle registering themselves with the presentation context,
  // we want to support rendering the slide before the data fetch is complete
  entity: EntityPresentation_EntityFragment;
  subtype: Subtype;
}

/**
 * @description The type definition for the props of a single entity presentation slide.
 */
export type EntityPresentationSlideProps<Subtype> =
  PresentationSlideRegistrationDetails &
    EntityPresentationBundleSlideSharedProps<Subtype>;

/**
 * @description This is an entity type-specifc map of slide types to the components that render them
 */
export type EntitySlideMap = Partial<
  Record<EntityPresentationSlideType, JSX.Element[] | JSX.Element>
>;
