import { Box } from '@mui/material';
import { useMemo } from 'react';

import { getJointPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/JointPersonalAccountDetails.utils';
import { EntityDetail_JointPersonalAccountFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';
import { StateCode } from '@/types/schema';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { getPaginatedBeneficiariesAndDispositionsForSummary } from '../../entityPresentationPagination.utils';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { PersonalAccountBeneficiariesSlide } from '../components/PersonalAccount.Beneficiaries';
import { PersonalAccountOverviewSlide } from '../components/PersonalAccount.OverviewSlide';
import { PersonalAccountSummarySlide } from '../components/PersonalAccount.SummarySlide';

type JointPersonalAccountPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_JointPersonalAccountFragment>;

export function JointPersonalAccountPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  entity,
  subtype,
  dispositionScenarios,
  entityId,
  isSingleEntityPrint = false,
}: JointPersonalAccountPresentationBundleProps) {
  const isEntitySummariesEnabled = isFeatureFlagEnabled('entity_summaries');
  const { availableStateEstateTaxes } = useIRSConstants();

  const displayName = subtype.displayName;
  const summary = getJointPersonalAccountSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.JOINT_ACCOUNT,
  });
  const grantors = useMemo(() => {
    return (
      entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false
          : false,
      })) ?? []
    );
  }, [availableStateEstateTaxes, entity.household.possiblePrimaryClients]);

  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName,
    identifier: entityId,
    skip: isEntitySummariesEnabled && !isSingleEntityPrint,
  });

  const SLIDE_MAP: EntitySlideMap = useMemo(() => {
    if (isEntitySummariesEnabled && isSingleEntityPrint) {
      return {
        [EntityPresentationSlideType.SUMMARY]:
          getPaginatedBeneficiariesAndDispositionsForSummary({
            dispositionScenarios,
            entityId,
            slideTypes,
            beneficiaries: summary.beneficiaries ?? [],
            entityKind: entity.kind,
            grantors,
            description: summary.description,
          }).map((slideProps, idx) => {
            return (
              <PersonalAccountSummarySlide
                key={`joint-personal-account-summary-slide-${idx}`}
                registrationProps={getPresentationEntitySlideProps(
                  entityId,
                  EntityPresentationSlideType.SUMMARY,
                  slideTypes
                )}
                entity={entity}
                summary={summary}
                displayName={displayName}
                grantors={grantors}
                {...slideProps}
              />
            );
          }),
      };
    }

    if (isEntitySummariesEnabled) {
      return {};
    }

    return {
      [EntityPresentationSlideType.OVERVIEW]: (
        <PersonalAccountOverviewSlide
          {...getPresentationEntitySlideProps(
            entityId,
            EntityPresentationSlideType.OVERVIEW,
            slideTypes
          )}
          entity={entity}
          summary={summary}
          displayName={displayName}
        />
      ),
      [EntityPresentationSlideType.BENEFICIARIES]: (
        <PersonalAccountBeneficiariesSlide
          {...getPresentationEntitySlideProps(
            entityId,
            EntityPresentationSlideType.BENEFICIARIES,
            slideTypes
          )}
          displayName={displayName}
          entity={entity}
          summary={summary}
        />
      ),
      [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
        getFilteredDispositionSlideProps({
          dispositionScenarios,
          entityId,
          slideTypes,
        }).map((slideProps, i) => (
          <DispositionScenarioSlide
            {...slideProps}
            key={i}
            displayName={displayName}
            entity={entity}
            grantors={grantors}
          />
        )),
    };
  }, [
    displayName,
    dispositionScenarios,
    entity,
    entityId,
    grantors,
    isEntitySummariesEnabled,
    isSingleEntityPrint,
    slideTypes,
    summary,
  ]);

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
