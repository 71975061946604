import { Theme } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';

import { DocumentSummaryPDF } from './DocumentSummaryPDF';

interface DocumentSummaryPDFDebuggerProps {
  isOpen: boolean;
  onClose: () => void;
  htmlDocumentSummary: string;
  householdDisplayName: string | null;
  customerTheme: Theme;
}

export function DocumentSummaryPDFDebugger({
  isOpen,
  onClose,
  htmlDocumentSummary,
  householdDisplayName,
  customerTheme,
}: DocumentSummaryPDFDebuggerProps) {
  return (
    <FullScreenModal isOpen={isOpen} onClose={onClose}>
      <PDFViewer width="100%" height="100%" showToolbar>
        <DocumentSummaryPDF
          htmlDocumentSummary={htmlDocumentSummary}
          householdDisplayName={householdDisplayName}
          customerTheme={customerTheme}
        />
      </PDFViewer>
    </FullScreenModal>
  );
}
