import { Box } from '@mui/material';
import { chunk } from 'lodash';
import { useMemo } from 'react';

import { getInsuranceAccountSummaryProperties } from '@/modules/entities/details/accounts/InsuranceAccountDetails.utils';
import { EntityDetail_InsurancePersonalAccountFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';
import { StateCode } from '@/types/schema';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { InsurancePoliciesSlide } from '../../components/InsurancePoliciesSlide';
import { getPaginatedBeneficiariesAndDispositionsForSummary } from '../../entityPresentationPagination.utils';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { PersonalAccountOverviewSlide } from '../components/PersonalAccount.OverviewSlide';
import { PersonalAccountSummarySlide } from '../components/PersonalAccount.SummarySlide';

export type InsurancePersonalAccountPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_InsurancePersonalAccountFragment>;

export function InsurancePersonalAccountPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  entity,
  subtype,
  dispositionScenarios,
  entityId,
}: InsurancePersonalAccountPresentationBundleProps) {
  const isEntitySummariesEnabled = isFeatureFlagEnabled('entity_summaries');
  const { availableStateEstateTaxes } = useIRSConstants();

  const displayName = subtype.displayName;
  const summary = getInsuranceAccountSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.INDIVIDUAL_ACCOUNT,
  });
  const grantors = useMemo(() => {
    return (
      entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false
          : false,
      })) ?? []
    );
  }, [availableStateEstateTaxes, entity.household.possiblePrimaryClients]);

  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName,
    identifier: entityId,
  });

  const policyPages = chunk(summary.insurancePolicies, 2);
  const SLIDE_MAP: EntitySlideMap = useMemo(() => {
    if (!isEntitySummariesEnabled) {
      return {
        [EntityPresentationSlideType.OVERVIEW]: (
          <PersonalAccountOverviewSlide
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.OVERVIEW,
              slideTypes
            )}
            entity={entity}
            summary={summary}
            displayName={displayName}
          />
        ),
        [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
          (pagePolicies, i) => (
            <InsurancePoliciesSlide
              key={i}
              entity={entity}
              pagePolicies={pagePolicies}
              slideIndex={i}
              numberOfPolicySlides={policyPages.length}
              displayName={displayName}
              {...getPresentationEntitySlideProps(
                entityId,
                EntityPresentationSlideType.INSURANCE_POLICIES,
                slideTypes,
                {
                  localSlideTypeIndex: i,
                  slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
                }
              )}
            />
          )
        ),
        [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
          getFilteredDispositionSlideProps({
            dispositionScenarios,
            entityId,
            slideTypes,
          }).map((slideProps, i) => (
            <DispositionScenarioSlide
              {...slideProps}
              key={i}
              displayName={displayName}
              entity={entity}
              grantors={grantors}
            />
          )),
      };
    }

    return {
      [EntityPresentationSlideType.SUMMARY]:
        getPaginatedBeneficiariesAndDispositionsForSummary({
          dispositionScenarios,
          entityId,
          slideTypes,
          beneficiaries: summary.beneficiaries ?? [],
          entityKind: entity.kind,
          grantors,
          description: summary.description,
        }).map((slideProps, idx) => {
          return (
            <PersonalAccountSummarySlide
              key={`insurance-personal-account-summary-slide-${idx}`}
              registrationProps={getPresentationEntitySlideProps(
                entityId,
                EntityPresentationSlideType.SUMMARY,
                slideTypes
              )}
              entity={entity}
              summary={summary}
              displayName={displayName}
              grantors={grantors}
              hideBeneficiaries={true}
              {...slideProps}
            />
          );
        }),
      [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
        (pagePolicies, i) => (
          <InsurancePoliciesSlide
            key={i}
            entity={entity}
            pagePolicies={pagePolicies}
            slideIndex={i}
            numberOfPolicySlides={policyPages.length}
            displayName={displayName}
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.INSURANCE_POLICIES,
              slideTypes,
              {
                localSlideTypeIndex: i,
                slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
              }
            )}
          />
        )
      ),
    };
  }, [
    displayName,
    dispositionScenarios,
    entity,
    entityId,
    grantors,
    isEntitySummariesEnabled,
    policyPages,
    slideTypes,
    summary,
  ]);

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
