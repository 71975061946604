import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import {
  GetHypotheticalTransfers_EstateWaterfallNodeValueHistoryFragment,
  GetHypotheticalTransfers_HypotheticalTransferFragment,
} from './graphql/GetHypotheticalTransfers.generated';
import { SaleLoanWithProjections } from './hooks/useGetHypotheticalTransfers';
import { getNetHypotheticalTransfers } from './HypotheticalTransfersCard.utils';

export interface HypotheticalTransfersSummaryTableProps {
  currentValue?: Decimal;
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
  saleLoansWithProjections: {
    inboundSaleLoans: SaleLoanWithProjections[];
    outboundSaleLoans: SaleLoanWithProjections[];
  };
  isIndividual: boolean;
  isEntity: boolean;
  growthRate: Decimal;
  referenceObjectId: string;
  referenceObjectValueHistory:
    | GetHypotheticalTransfers_EstateWaterfallNodeValueHistoryFragment[]
    | null;
}

export function HypotheticalTransfersSummaryTable({
  currentValue = new Decimal(0),
  transfers: { inboundTransfers, outboundTransfers },
  saleLoansWithProjections: { inboundSaleLoans, outboundSaleLoans },
  isIndividual,
  growthRate: wholePercentGrowthRate,
  referenceObjectValueHistory,
  referenceObjectId,
}: HypotheticalTransfersSummaryTableProps) {
  const growthRate = wholePercentGrowthRate.dividedBy(100).plus(1);

  const {
    totalNormalizedTransfers,
    totalValue: netResult,
    finalYear,
  } = getNetHypotheticalTransfers({
    currentValue,
    inboundTransfers,
    outboundTransfers,
    inboundSaleLoans,
    outboundSaleLoans,
    growthRate,
  });

  const entityYearValue = useMemo(() => {
    if (!referenceObjectValueHistory) return null;
    const projectedValueAtYear = referenceObjectValueHistory.find(
      (v) => v.year === finalYear
    )?.endOfYearValue;

    // this should exist, but be defensive since it's on a hot path
    if (!projectedValueAtYear) {
      diagnostics.error(
        'Entity year value not found',
        new Error('Entity year value not found'),
        {
          referenceObjectId,
        }
      );
      return null;
    }

    return projectedValueAtYear;
  }, [referenceObjectValueHistory, finalYear, referenceObjectId]);

  // we want to entirely hide the table if there are no transfers
  // or sale loans
  if (
    !inboundTransfers.length &&
    !outboundTransfers.length &&
    !inboundSaleLoans.length &&
    !outboundSaleLoans.length
  ) {
    return null;
  }

  const rows: MiniTableRow[] = [
    {
      label: isIndividual ? 'Current value' : 'Current entity value',
      value: formatCurrencyNoDecimals(currentValue),
    },
    {
      label: 'Total net transfers',
      value: formatCurrencyNoDecimalsAccounting(totalNormalizedTransfers),
      valueProps: totalNormalizedTransfers.isNegative()
        ? { sx: { color: COLORS.MATH.NEGATIVE } }
        : undefined,
    },
    {
      label: `Value after transfers (${finalYear})`,
      value: formatCurrencyNoDecimalsAccounting(
        entityYearValue ?? new Decimal(0)
      ),
      valueProps: netResult.isNegative()
        ? { sx: { color: COLORS.MATH.NEGATIVE } }
        : undefined,
    },
  ];
  return (
    <Card variant="filled" sx={{ p: 2 }}>
      <MiniTable rows={rows} variant="tally" />
    </Card>
  );
}
