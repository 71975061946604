import { Box, Stack, Typography } from '@mui/material';
import { compact, partition } from 'lodash';
import { useMemo } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { SHOW_ON_ROW_HOVER_CLASSNAME } from '@/components/tables/DataTable/components/ThemedDataGrid';
import { ButtonRenderer } from '@/components/tables/DataTable/renderers/cell/ButtonRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { doesEntityHaveUnacknowledgedAIDPSuggestions } from '@/modules/aiDP/aidp.utils';
import {
  CLIENT_OWNERSHIP_DESCRIPTION,
  NO_REVIEW_REQUIRED_ENTITY_KINDS,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { DispositiveProvisionsModalDetailsType } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import {
  CHARITABLE_ENTITY_TYPES,
  PERSONAL_FAMILY_ACCOUNT_TYPES,
  PERSONAL_FAMILY_TRUST_TYPES,
} from '@/modules/entities/entities.constants';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';
import { EntityInEstateStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { sortAlpha } from '@/utils/sortUtils';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import { ManageDispositions_EntityFragment } from '../graphql/ManageDispositionsPage.generated';
import { ManageDispositions_AugmentedClientProfileRow } from '../ManageDispositionPage.types';
import {
  EntityTableEntityRow,
  EntityTableIndividualRow,
  EntityTableRow,
} from './ManageDispositionEntitiesTable.types';

export const useEntitiesTableColumns = () => {
  const { navigate } = useNavigateToRoute();
  const { householdId } = useHouseholdDetailsContext();
  const { activeClientDeathId, setDispositiveProvisionsModalDetails } =
    useManageDispositionsContext();

  const columns: Column<EntityTableRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Entity',
        flex: 1,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => {
            switch (row.type) {
              case 'individual':
                return row.name;
              case 'entity': {
                if (
                  !row.hasUnacknowledgedDPSuggestions ||
                  !isFeatureFlagEnabled('ai_dispositive_provisions')
                ) {
                  return row.name;
                }
                return (
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <Typography variant="h5">{row.name}</Typography>
                    <Stars01Icon color={COLORS.TEAL['600']} size={16} />
                  </Stack>
                );
              }
              default: {
                throw new UnreachableError({
                  case: row,
                  message: `Unhandled row ${row}`,
                });
              }
            }
          },
          lineOneProps: () => ({
            variant: 'h5',
          }),
          lineTwo: ({ row }) => {
            switch (row.type) {
              case 'individual':
                return CLIENT_OWNERSHIP_DESCRIPTION;
              case 'entity':
                return entityKindToDisplayName(row.kind);
              default: {
                throw new UnreachableError({
                  case: row,
                  message: `Unhandled row ${row}`,
                });
              }
            }
          },
          textAccompaniment: ({ row }) => {
            // householdId check for type safety
            if (row.type !== 'entity' || !householdId) return null;
            return (
              <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
                <IconButton
                  ariaLabel="Go to entity"
                  icon={LinkExternal01Icon}
                  onClick={() =>
                    navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
                      householdId,
                      entityId: row.id,
                    })
                  }
                  variant="transparent"
                  size="xs"
                />
              </Box>
            );
          },
          rightContent: ({ row }) =>
            row.template ? (
              <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>
                {row.template} applied
              </Typography>
            ) : null,
        }),
      },
      {
        field: 'action',
        headerName: '',
        width: 176,
        renderCell: ButtonRenderer({
          buttonProps: (row) => ({
            fullWidth: true,
            'data-testid': `SetDispositionsButton-${row.id}`,
            handleClick: (row) => {
              if (row.type === 'individual') {
                return setDispositiveProvisionsModalDetails({
                  type: DispositiveProvisionsModalDetailsType.CLIENT_PROFILE,
                  clientProfileId: row.id,
                  displayName: row.name,
                  valueToDistribute: row.valueToDistribute,
                  firstDeathClientId: activeClientDeathId,
                });
              }

              setDispositiveProvisionsModalDetails({
                type: DispositiveProvisionsModalDetailsType.ENTITY,
                entityId: row.id,
                entityDisplayName: row.name,
                entitySubtypeId: row.entitySubtypeId,
                entityType: row.entityType,
                firstDeathClientId: activeClientDeathId,
              });
            },
            label: (row) => {
              return row.hasDispositionsSet ? 'Edit' : 'Set dispositions';
            },
            variant: (row) =>
              row.hasDispositionsSet ? 'secondary' : 'primary',
          }),
        }),
      },
    ],
    [
      activeClientDeathId,
      householdId,
      navigate,
      setDispositiveProvisionsModalDetails,
    ]
  );

  return columns;
};

export function mapEntitiesToRows(
  entities: ManageDispositions_EntityFragment[],
  activeClientDeathId: string
): EntityTableEntityRow[] {
  const rows = entities.map((entity) => {
    const entityDoesNotRequireReview = NO_REVIEW_REQUIRED_ENTITY_KINDS.includes(
      entity.kind
    );

    const relevantDispositionScenario =
      entity.subtype.dispositionScenarios?.find(
        (scenario) => scenario.firstGrantorDeath.id === activeClientDeathId
      );
    // we know that someone has reviewed the dispositions for this entity if either dispositions are set (obviously)
    // or if they've reviewed the dispositions, even if they've chosen to not enter any.
    const entityHasDefinedDispositionScenarios =
      entity.subtype.dispositionScenarios?.some((scenario) => {
        return (
          scenario.firstGrantorDeath.id === activeClientDeathId &&
          scenario.reviewedAt
        );
      }) ?? false;

    const hasDispositionsSet =
      entityDoesNotRequireReview || entityHasDefinedDispositionScenarios;

    const template =
      relevantDispositionScenario?.dispositiveProvisionsTemplate?.displayName ||
      relevantDispositionScenario?.secondDeathDispositiveProvisionsTemplate
        ?.displayName;

    return {
      id: entity.id,
      type: 'entity' as const,
      entitySubtypeId: entity.subtype.id,
      entityType: getEntityTypeFromEntityKind(entity.kind),
      name: entity.subtype.displayName,
      kind: entity.kind,
      hasDispositionsSet,
      action: null,
      template,
      hasUnacknowledgedDPSuggestions:
        doesEntityHaveUnacknowledgedAIDPSuggestions(entity),
    };
  });

  // we want the order of these entities to be stable and consistent across death scenarios
  // so we sort them first by category. then by name. the order of categories is:
  // 1. Personal & family trusts
  // 2. Charitable entities
  // 3. Personal & family accounts
  return rows.sort((a, b) => {
    const order: EntityType[] = [
      ...PERSONAL_FAMILY_TRUST_TYPES,
      ...CHARITABLE_ENTITY_TYPES,
      ...PERSONAL_FAMILY_ACCOUNT_TYPES,
    ];

    const aIndex = order.indexOf(getEntityTypeFromEntityKind(a.kind));
    const bIndex = order.indexOf(getEntityTypeFromEntityKind(b.kind));

    // If both entities are in the same category, sort by name
    if (aIndex === bIndex) {
      return sortAlpha(a.name, b.name);
    }

    // If one entity's kind is not in the order array, it should come after the one that is
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    // Otherwise, sort by the order in the array
    return aIndex - bIndex;
  });
}

export function mapIndividualsToRows(
  clients: ManageDispositions_AugmentedClientProfileRow[],
  activeClientDeathId: string
): EntityTableIndividualRow[] {
  const rows = compact(
    clients.map((client) => {
      if (client.firstDeathClientProfileId !== activeClientDeathId) return null;

      const hasDispositionsSet =
        client.dispositionScenarios?.some((scenario) => {
          return (
            scenario.firstGrantorDeath.id === activeClientDeathId &&
            scenario.reviewedAt
          );
        }) ?? false;

      const relevantDispositionScenario = client.dispositionScenarios?.find(
        (scenario) => scenario.firstGrantorDeath.id === activeClientDeathId
      );

      const template =
        relevantDispositionScenario?.dispositiveProvisionsTemplate
          ?.displayName ||
        relevantDispositionScenario?.secondDeathDispositiveProvisionsTemplate
          ?.displayName;

      return {
        id: client.id,
        type: 'individual' as const,
        name: client.displayName,
        hasDispositionsSet,
        valueToDistribute: client.valueToDistribute,
        action: null,
        template,
      };
    })
  );

  return rows.sort((a, b) => {
    return sortAlpha(a.name, b.name);
  });
}

export function getEntitiesByVariant(
  entities: ManageDispositions_EntityFragment[]
) {
  const [inEstateEntities, outOfEstateEntities] = partition(
    entities,
    (entity) => {
      if ('inEstateStatus' in entity.subtype) {
        return entity.subtype.inEstateStatus === EntityInEstateStatus.InEstate;
      }

      if ('nonTrustEntityInEstateStatus' in entity.subtype) {
        return (
          entity.subtype.nonTrustEntityInEstateStatus ===
          EntityInEstateStatus.InEstate
        );
      }

      return true;
    }
  );

  return { inEstateEntities, outOfEstateEntities };
}
