import { Box, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { Button } from '@/components/form/baseInputs/Button';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { getTransferDisplayName } from '@/modules/entities/details/HypotheticalTransfersCard/HypotheticalTransfersCard.utils';
import { COLORS } from '@/styles/tokens/colors';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import {
  HypotheticalTransfersSummaryPanelSource,
  HypotheticalTransfersSummaryPanelTransfer,
  SummaryPanelTransferKind,
} from './HypotheticalTransfersSummaryPanel.types';

interface HypotheticalTransferSummaryTransferProps {
  transfer: HypotheticalTransfersSummaryPanelTransfer;
  onManageTransfer: (transferId: string) => void;
}

function getYearDisplay(
  transfer: HypotheticalTransfersSummaryPanelTransfer
): string[] {
  const { startYear, endYear, transferKind } = transfer;

  if (startYear && endYear && startYear !== endYear) {
    switch (transferKind) {
      case SummaryPanelTransferKind.Transfer:
        return [`${startYear}-${endYear}`, 'Recurring'];
      case SummaryPanelTransferKind.SaleLoan:
        return [`${startYear}-${endYear}`, 'Cumulative'];
      default:
        throw new UnreachableError({
          case: transferKind,
          message: `Unknown transfer kind: ${transferKind}`,
        });
    }
  } else if (startYear) {
    return [`${startYear}`, 'One time'];
  } else if (endYear) {
    return [`${endYear}`, 'One time'];
  }

  return [];
}

function HypotheticalTransferSummaryTransfer({
  transfer,
  onManageTransfer,
}: HypotheticalTransferSummaryTransferProps) {
  const yearDisplay = getYearDisplay(transfer);

  return (
    <StyledTableRow inactive>
      <StyledTableCell>
        <Stack>
          <Typography variant="h5" component="span">
            {getTransferDisplayName(transfer)}
          </Typography>
          <Typography variant="subtitle2">
            To &quot;{transfer.targetDisplayName}&quot;
          </Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          spacing={1}
        >
          <Stack justifyContent="flex-end" alignItems="end">
            {/* Display all amounts as negative, as these are all outbound transfers */}
            <Typography
              variant="body1"
              color={COLORS.FUNCTIONAL.ERROR.DEFAULT}
              textAlign="right"
            >
              {formatCurrencyNoDecimalsAccounting(
                transfer.transferValue.absoluteValue().negated()
              )}
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="right"
              sx={{ wordBreak: 'break-word' }}
            >
              {yearDisplay.map((display, i) => (
                <Box key={i}>{display}</Box>
              ))}
            </Typography>
          </Stack>
          <Button
            variant="transparent"
            size="xs"
            onClick={() => onManageTransfer(transfer.id)}
          >
            <Edit02Icon size={20} sx={{ color: COLORS.NAVY[900] }} />
          </Button>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );
}

interface HypotheticalTransferSummaryManageSourceProps {
  onManageSource: (sourceId: string) => void;
  source: HypotheticalTransfersSummaryPanelSource;
  visibleNodeIds: string[];
}

function HypotheticalTransferSummaryManageSource({
  onManageSource,
  source,
  visibleNodeIds,
}: HypotheticalTransferSummaryManageSourceProps) {
  const onClickManage = useCallback(() => {
    onManageSource(source.id);
  }, [onManageSource, source.id]);

  if (!visibleNodeIds.includes(source.id)) {
    return null;
  }

  return (
    <Button size="xs" variant="secondary" onClick={onClickManage}>
      Manage
    </Button>
  );
}

export type HypotheticalTransfersSummarySourceProps =
  HypotheticalTransferSummaryManageSourceProps &
    Omit<HypotheticalTransferSummaryTransferProps, 'transfer'>;

const COLUMNS: DisplayTableColumn[] = [
  {
    headerName: 'Transfers',
  },
  {
    headerName: '',
  },
];

export function HypotheticalTransferSummarySource({
  source,
  onManageSource,
  onManageTransfer,
  visibleNodeIds,
}: HypotheticalTransfersSummarySourceProps) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          {source.color && <ColorBox color={source.color} />}
          <Typography variant="h5" component="span">
            From &quot;{source.displayName}&quot;
          </Typography>
        </Stack>
        <HypotheticalTransferSummaryManageSource
          onManageSource={onManageSource}
          source={source}
          visibleNodeIds={visibleNodeIds}
        />
      </Stack>
      <Box sx={{ borderBottom: `1px solid ${COLORS.GRAY[200]}` }}>
        <DisplayTable columns={COLUMNS}>
          {source.transfers.map((transfer, idx) => (
            <HypotheticalTransferSummaryTransfer
              transfer={transfer}
              onManageTransfer={onManageTransfer}
              key={`${transfer.targetId}-${idx}`}
            />
          ))}
        </DisplayTable>
      </Box>
    </Stack>
  );
}
