import { cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    padding: `4px 8px`,
    borderStyle: 'solid',
    borderWidth: 3,
    borderRadius: 6,
  },
  highlight: {},
  label: {
    color: COLORS.GRAY[700],
    fontWeight: 700,
    fontSize: 12,
  },
  value: {
    color: COLORS.NAVY[900],
    fontWeight: 700,
    fontSize: 14,
  },
}));

const usePrimaryStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.GRAY[50],
    borderColor: COLORS.GRAY[300],
  },
  highlight: {
    borderColor: COLORS.GRAY[700],
  },
  label: {},
  value: {},
}));

const useSecondaryStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.FUNCTIONAL.WARNING[50],
    borderColor: COLORS.FUNCTIONAL.WARNING[300],
  },
  highlight: {
    borderColor: COLORS.FUNCTIONAL.WARNING[700],
  },
  label: {},
  value: {},
}));

const useTertiaryStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.FUNCTIONAL.SUCCESS[50],
    borderColor: COLORS.FUNCTIONAL.SUCCESS[300],
  },
  highlight: {
    borderColor: COLORS.FUNCTIONAL.SUCCESS[700],
  },
  label: {},
  value: {},
}));

const useDestructiveStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.ORANGE[50],
    borderColor: COLORS.ORANGE[300],
  },
  highlight: {
    borderColor: COLORS.ORANGE[700],
  },
  label: {},
  value: {},
}));

const useAccentStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.FUNCTIONAL.SUCCESS[50],
    borderColor: COLORS.FUNCTIONAL.SUCCESS[300],
  },
  highlight: {
    borderColor: COLORS.FUNCTIONAL.SUCCESS[800],
  },
  label: {},
  value: {},
}));

export type EdgeLabelVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'destructive'
  | 'accent';

export interface EdgeLabelProps {
  variant?: EdgeLabelVariant;
  label?: string;
  value?: string;
  endIcon?: ReactNode;
  classes?: WithClasses<typeof useStyles>;
  highlight?: boolean;
}

export function EdgeLabel({
  variant = 'primary',
  label,
  value,
  endIcon,
  classes: externalClasses,
  highlight,
}: EdgeLabelProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  const { classes: primaryClasses } = usePrimaryStyles();
  const { classes: secondaryClasses } = useSecondaryStyles();
  const { classes: tertiaryClasses } = useTertiaryStyles();
  const { classes: destructiveClasses } = useDestructiveStyles();
  const { classes: accentClasses } = useAccentStyles();
  const variantClasses: Record<EdgeLabelVariant, typeof classes> = {
    primary: primaryClasses,
    secondary: secondaryClasses,
    tertiary: tertiaryClasses,
    destructive: destructiveClasses,
    accent: accentClasses,
  };
  const variantStyles = variantClasses[variant];

  return (
    <div
      className={cx(
        variantStyles.root,
        classes.root,
        highlight && variantStyles.highlight,
        highlight && classes.highlight
      )}
    >
      {label && (
        <Typography className={cx(variantStyles.label, classes.label)}>
          {label}
        </Typography>
      )}
      {value && (
        <Typography className={cx(variantStyles.value, classes.value)}>
          {value}
        </Typography>
      )}
      {endIcon}
    </div>
  );
}
