import { useApolloClient } from '@apollo/client';
import { Grid } from '@mui/material';
import Decimal from 'decimal.js';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useWatch, Validate } from 'react-hook-form';

import { stateAndOffshoreOptions } from '@/components/form/baseInputs/SelectInput/standardValues/stateOptions';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import {
  FormAwareDatePickerInput,
  FormAwareDatePickerInputProps,
} from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import {
  FormAwareTextInput,
  FormAwareTextInputProps,
} from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { SuggestableFormAwareInput } from '@/modules/aiSuggestions/components/forms/SuggestableFormAwareInput';
import { GRATQPRTTaxableGift } from '@/modules/content/tooltipContent/GRATQPRTTaxableGift';
import { RichDocumentUploaderWithList } from '@/modules/documents/RichDocumentUploaderWithList/RichDocumentUploaderWithList';
import { TrustProperties } from '@/modules/entities/BasicInformationSubform/components/TrustProperties';
import { GrantorsField } from '@/modules/entities/principals/GrantorsField/GrantorsField';
import { NonGrantorPrincipalFields } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields';
import { AiSuggestionKind } from '@/types/schema';

import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE,
  Fields,
  JOINT_ACCOUNT_TYPE_OPTIONS,
  Props,
  RETIREMENT_ACCOUNT_TYPE_OPTIONS,
  SubformField,
} from './BasicInformationSubform.types';

export function UI(props: Props) {
  const {
    variant,
    householdId,
    entityId,
    grantorsFieldVariant,
    grantorsFieldSubformValues,
    isCharitableEntity,
    hideDocumentsList,
    nonGrantorPrincipalsFieldVariant,
    wealthOwnerType,
  } = props;

  const { control, setValue } = useFormContext<Fields>();

  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const client = useApolloClient();

  const initialFundingValue = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.initialFundingValue` as const satisfies SubformField,
    control,
  });

  const requiresCtaReporting = useWatch({
    control,
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.requiresCtaReporting` as const satisfies SubformField,
  });

  useEffect(() => {
    if (!requiresCtaReporting) {
      setValue(
        `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.finCenID` as const satisfies SubformField,
        ''
      );
    }
  }, [requiresCtaReporting, setValue]);

  const finCenID = useWatch({
    control,
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.finCenID` as const satisfies SubformField,
  });

  useEffect(() => {
    if (finCenID) {
      setValue(
        `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.requiresCtaReporting` as const satisfies SubformField,
        true
      );
    }
  }, [finCenID, setValue]);

  const isGRATImplementation =
    variant === 'GRATImplementationConfirm' ||
    variant === 'GRATImplementationEdit';
  const isActiveGRATEdit = variant === 'GRATActive';
  const isJointAccount = variant === 'JointAccount';
  const isRetirementAccount = variant === 'RetirementAccount';
  const isInsuranceAccount = variant === 'InsuranceAccount';
  const isAccount = variant === 'Account' || isJointAccount;
  const isNonTrustCharitableEntity = variant === 'NonTrustCharitableEntity';
  const isBusinessEntity = variant === 'BusinessEntity';
  const isQPRT = variant === 'QPRT';
  const isCLT = variant === 'CLT';
  const isCRT = variant === 'CRT';
  const isCharitableTrust = isCLT || isCRT;
  const showTrustJurisdiction =
    !isAccount && !isRetirementAccount && !isNonTrustCharitableEntity;
  const showLegalName =
    !isInsuranceAccount && !isRetirementAccount && !isAccount;
  const showEffectiveDateOnTop =
    variant === 'default' ||
    isNonTrustCharitableEntity ||
    isBusinessEntity ||
    isAccount;

  return (
    <Grid container spacing={3} columns={12}>
      <Grid item sm={12}>
        <FormLayoutRow>
          <FormLayoutItem width={showEffectiveDateOnTop ? 8 : 12}>
            <FormAwareTextInput<Fields>
              control={control}
              label="Display name"
              required={true}
              fieldName={
                `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.displayName` as const satisfies SubformField
              }
            />
          </FormLayoutItem>
          {showEffectiveDateOnTop && (
            <FormLayoutItem width={4}>
              <SuggestableFormAwareInput<
                Fields,
                FormAwareDatePickerInputProps<Fields>
              >
                FormAwareElement={FormAwareDatePickerInput}
                control={control}
                label="Effective date"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.effectiveDate` as const satisfies SubformField
                }
                suggestionKind={AiSuggestionKind.EffectiveDate}
              />
            </FormLayoutItem>
          )}
        </FormLayoutRow>

        {isBusinessEntity && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <SuggestableFormAwareInput<
                Fields,
                FormAwareTextInputProps<Fields>
              >
                FormAwareElement={FormAwareTextInput}
                control={control}
                label="Doing business as (DBA) name"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.doingBusinessAsName` as const satisfies SubformField
                }
                suggestionKind={AiSuggestionKind.DoingBusinessAsName}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}

        {showLegalName && (
          <FormLayoutRow>
            <FormLayoutItem width={showTrustJurisdiction ? 8 : 12}>
              <SuggestableFormAwareInput<
                Fields,
                FormAwareTextInputProps<Fields>
              >
                FormAwareElement={FormAwareTextInput}
                control={control}
                label="Legal name"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.legalName` as const satisfies SubformField
                }
                suggestionKind={AiSuggestionKind.LegalName}
              />
            </FormLayoutItem>
            {showTrustJurisdiction && (
              <FormLayoutItem width={4}>
                <SuggestableFormAwareInput<
                  Fields,
                  FormAwareSelectInputProps<Fields>
                >
                  FormAwareElement={FormAwareSelectInput}
                  control={control}
                  fieldName={
                    `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.jurisdiction` as const satisfies SubformField
                  }
                  label={
                    isBusinessEntity
                      ? 'Formation jurisdiction'
                      : 'Trust jurisdiction'
                  }
                  options={stateAndOffshoreOptions}
                  suggestionKind={AiSuggestionKind.JurisdictionStateCode}
                />
              </FormLayoutItem>
            )}
          </FormLayoutRow>
        )}

        {isJointAccount && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSelectInput<Fields>
                options={JOINT_ACCOUNT_TYPE_OPTIONS}
                control={control}
                label="Account type"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.jointAccountType` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}

        {isRetirementAccount && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSelectInput<Fields>
                options={RETIREMENT_ACCOUNT_TYPE_OPTIONS}
                control={control}
                label="Account type"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.retirementAccountType` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}

        {grantorsFieldVariant &&
          !nonGrantorPrincipalsFieldVariant &&
          grantorsFieldSubformValues &&
          variant !== 'BusinessEntity' && (
            <GrantorsField<Fields>
              control={control}
              variant={grantorsFieldVariant}
              subformNamespace={BASIC_INFORMATION_SUBFORM_NAMESPACE}
              subformValues={grantorsFieldSubformValues}
              isCharitableEntity={!!isCharitableEntity}
            />
          )}

        {nonGrantorPrincipalsFieldVariant && wealthOwnerType && (
          <NonGrantorPrincipalFields
            householdId={householdId}
            variant={nonGrantorPrincipalsFieldVariant}
            wealthOwnerType={wealthOwnerType}
            isInsuranceAccount={isInsuranceAccount}
          />
        )}

        {!isGRATImplementation && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareTextInput<Fields>
                control={control}
                label="Description"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.description` as const satisfies SubformField
                }
                multiline
                rows={4}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}

        {isBusinessEntity && (
          <Card variant="filled" sx={{ p: 3, mb: 3 }}>
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareSwitch
                  control={control}
                  fieldName={
                    `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.requiresCtaReporting` as const satisfies SubformField
                  }
                  label="Requires Corporate Transparency Act reporting"
                  labelPosition="right"
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem width={6} sx={{ ml: 7 }}>
                <SuggestableFormAwareInput<
                  Fields,
                  FormAwareTextInputProps<Fields>
                >
                  FormAwareElement={FormAwareTextInput}
                  control={control}
                  fieldName={`${BASIC_INFORMATION_SUBFORM_NAMESPACE}.finCenID`}
                  label="FinCEN ID (optional)"
                  suggestionKind={AiSuggestionKind.FincenId}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Card>
        )}

        {(isQPRT || isGRATImplementation || isCharitableTrust) && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <TrustProperties
                householdId={householdId}
                variant={variant}
                entityId={entityId}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}

        {!isAccount && (
          <FormLayoutRow sx={{ alignItems: 'center' }}>
            {(isGRATImplementation || isActiveGRATEdit) && (
              <>
                <FormLayoutItem width={4}>
                  <FormAwareCurrencyInput<Fields>
                    control={control}
                    fieldName={
                      `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.taxableGift` as const satisfies SubformField
                    }
                    label="Taxable gift"
                    isDecimalJSInput
                    validation={
                      {
                        max: (value?: Decimal | null) => {
                          if (
                            !value ||
                            value.lessThanOrEqualTo(initialFundingValue ?? 0)
                          ) {
                            return undefined;
                          }
                          return 'Must be less than the funding value';
                        },
                      } as unknown as Validate<unknown, unknown>
                    }
                    validateOnChange
                    contextualHelp={
                      <GRATQPRTTaxableGift householdId={householdId} />
                    }
                  />
                </FormLayoutItem>
                <FormLayoutItem width={2}>
                  <FormAwarePercentInput<Fields>
                    control={control}
                    fieldName={
                      `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.rate7520` as const satisfies SubformField
                    }
                    label="7520 rate"
                    isDecimalJSInput
                  />
                </FormLayoutItem>
              </>
            )}
          </FormLayoutRow>
        )}

        {!isGRATImplementation && !hideDocumentsList && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <RichDocumentUploaderWithList
                householdId={householdId}
                entityId={entityId}
                hideEntityPicker={true}
                listDocumentsLike={
                  entityId
                    ? {
                        hasEntityWith: [
                          {
                            id: entityId,
                          },
                        ],
                      }
                    : undefined
                }
                documentIds={entityId ? undefined : documentIds}
                onDocumentUploadSuccess={async (documentId) => {
                  const uniqueDocumentIds = uniq([...documentIds, documentId]);
                  setValue(
                    `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.documentIds` as const satisfies SubformField,
                    uniqueDocumentIds
                  );
                  setDocumentIds(uniqueDocumentIds);
                  if (entityId) {
                    // refetch the documents if we are editing an existing entity
                    await client.refetchQueries({
                      updateCache(cache) {
                        cache.evict({ fieldName: 'documents' });
                        cache.gc();
                      },
                    });
                  }
                }}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Grid>
    </Grid>
  );
}
