import { compact, orderBy } from 'lodash';
import { PropsWithChildren, useMemo, useState } from 'react';

import { getSuggestionAsSearchQuery } from '@/modules/aiSuggestions/aiSuggestions.utils';
import { evidenceAdapter } from '@/modules/aiSuggestions/evidence';
import { DocumentViewerTab } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { diagnostics } from '@/utils/diagnostics';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';
import { SelectedSuggestionEvidenceContext } from './SelectedSuggestionEvidence.context';

function useContextValue(): SelectedSuggestionEvidenceContext {
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<CommonAiSuggestionFragment | null>(null);

  const { suggestionEvidence, documentViewerTabOverride } = useMemo(() => {
    if (!selectedSuggestion) {
      return {
        suggestionEvidence: {},
        documentViewerTabOverride: undefined,
      };
    }

    // When the user clicks to view the evidence for a suggestion, we show the
    // annotations, prefill the search bar with the suggestion text, and switch
    // the active document to the suggestion source.

    if (!selectedSuggestion.file?.document?.id && !selectedSuggestion.kgNode) {
      diagnostics.error(
        `Trying to get evidence for a suggestion without a source document or KGNode. suggestionID=${selectedSuggestion.id}`
      );
      return {
        suggestionEvidence: {},
        documentViewerTabOverride: undefined,
      };
    }

    if (selectedSuggestion.file?.document?.id) {
      // All the evidence for this suggestion comes from a single document.
      return {
        suggestionEvidence: {
          documentId: selectedSuggestion.file?.document?.id,
          annotations: compact(selectedSuggestion.evidence || []).map((e) =>
            evidenceAdapter(e, selectedSuggestion.file?.document?.id || '')
          ),
          searchQuery: getSuggestionAsSearchQuery(selectedSuggestion),
        },
        documentViewerTabOverride: 'document' as DocumentViewerTab,
      };
    }

    // Otherwise, group the evidence list by document
    const annotations = orderBy(
      (selectedSuggestion.evidence || [])
        .filter((e) => e?.document?.id) // Filter out evidence without a document
        .map((e) => evidenceAdapter(e, e?.document?.id || '')), // Convert to DocumentSearchResult type
      ['documentId']
    );

    return {
      suggestionEvidence: {
        documentId: annotations[0]?.documentID,
        annotations,
        searchQuery: getSuggestionAsSearchQuery(selectedSuggestion),
      },
      documentViewerTabOverride: 'document' as DocumentViewerTab,
    };
  }, [selectedSuggestion]);

  return {
    locateSuggestion: setSelectedSuggestion,
    unsetSelectedSuggestion: () => setSelectedSuggestion(null),
    suggestionEvidence,
    documentViewerTabOverride,
  };
}

export function SelectedSuggestionEvidenceProvider({
  children,
}: PropsWithChildren) {
  const value = useContextValue();

  return (
    <SelectedSuggestionEvidenceContext.Provider value={value}>
      {children}
    </SelectedSuggestionEvidenceContext.Provider>
  );
}
