import { Box, BoxProps } from '@mui/material';
import { ComponentType } from 'react';

import { PresentationSlide } from '@/modules/presentation/PresentationSlide';

import { ClientPresentationV2Bundle } from '../../../types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from '../../../types/ClientPresentationV2.PresentationPageType';

export interface CustomSlideBundleProps {
  bundle: ClientPresentationV2Bundle;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function CustomSlideBundle({
  bundle,
  SlideWrapper = Box,
}: CustomSlideBundleProps) {
  return (
    <>
      {bundle.pages.map((page) => (
        <CustomSlide page={page} SlideWrapper={SlideWrapper} key={page.id} />
      ))}
    </>
  );
}

export interface CustomSlideProps {
  page: ClientPresentationV2Page;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function CustomSlide({ page, SlideWrapper = Box }: CustomSlideProps) {
  return (
    <SlideWrapper>
      <PresentationSlide.Slide id="slide-display">
        <Box>
          <pre>{JSON.stringify(page, null, 2)}</pre>
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
