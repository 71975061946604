import { flatMap, isEmpty } from 'lodash';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DocumentType } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import {
  EntityDocumentViewer_DocumentFragment,
  EntityDocumentViewer_EntityFragment,
  EntityDocumentViewer_TestamentaryEntityFragment,
  EntityDocumentViewerQueryVariables,
  useEntityDocumentViewerQuery,
} from '../graphql/EntityDocumentViewer.generated';
import {
  DocumentViewerTab,
  HouseholdDocumentsViewerContext,
  UpdateActiveDocumentOpts,
} from './HouseholdDocumentsViewer.context';

function useHouseholdDocumentsViewerContextValue({
  targetId = '',
  documentIds,
  isDispositiveProvisionsTemplateFlow = false,
  householdId,
  overrideActiveDocumentId,
  onUpdateActiveDocumentId,
  overrideDocumentViewerActiveTab,
  onUpdateDocumentViewerActiveTab,
  hideAddDocument = false,
  documentOrderFn,
}: HouseholdDocumentsViewerProviderProps): HouseholdDocumentsViewerContext {
  const targetKind = targetId ? getPulidKind(targetId) : null;
  if (!targetKind && !documentIds && !isDispositiveProvisionsTemplateFlow) {
    diagnostics.warn(
      'A valid targetId or list of documentIds must be provided to HouseholdDocumentsViewer'
    );
  }

  const [activeDocumentId, setActiveDocumentId] = useState<string | null>(
    // If we get passed a list of document ids, set the first one as active
    documentIds?.[0] ?? null
  );
  const [defaultDocumentId, setDefaultDocumentId] = useState<string | null>(
    null
  );
  const [documentViewerActiveTab, setDocumentViewerActiveTab] =
    useState<DocumentViewerTab>('document');
  const [newlyUploadedDocumentId, setNewlyUploadedDocumentId] = useState<
    string | null
  >(null);

  const { data, loading, error, refetch } = useEntityDocumentViewerQuery({
    fetchPolicy: 'cache-and-network',
    variables: getDocumentsQueryVariables(
      targetId,
      householdId,
      targetKind,
      documentIds
    ),
    skip: !targetId && isEmpty(documentIds),
  });

  const {
    documents,
    defaultDocumentId: defaultDocumentIdFromQuery,
    entityKind,
  } = useMemo(() => {
    if (!data) {
      return { documents: null, defaultDocumentId: null };
    }

    // client profile scenario, or a list of documentIds were given
    if (data.householdDocuments) {
      return {
        documents: documentOrderFn
          ? documentOrderFn(getNodes(data.householdDocuments))
          : getNodes(data.householdDocuments),
        defaultDocumentId: null,
      };
    }

    if (data.testamentaryEntity) {
      const testamentaryEntity =
        data.testamentaryEntity as EntityDocumentViewer_TestamentaryEntityFragment;
      const documents = [
        ...flatMap(testamentaryEntity.benefitsFrom, (dp) => {
          return getNodes(dp?.entity?.documents);
        }),
        ...getNodes(testamentaryEntity.additionalDocuments),
      ];
      return {
        documents: documentOrderFn ? documentOrderFn(documents) : documents,
        defaultDocumentId: null,
      };
    }

    if (data.entity) {
      const entity = data.entity as EntityDocumentViewer_EntityFragment;
      const documents = [
        ...getNodes(entity.documents),
        ...getNodes(entity.additionalDocuments),
      ];
      return {
        entityKind: entity.kind,
        documents: documentOrderFn ? documentOrderFn(documents) : documents,
        defaultDocumentId: entity.defaultDocument?.id ?? null,
      };
    }

    throw new Error('Invalid data returned from EntityDocumentViewerQuery');
  }, [data, documentOrderFn]);

  // initialize the active and default documents from the available documents
  useEffect(() => {
    if (defaultDocumentIdFromQuery) {
      setDefaultDocumentId(defaultDocumentIdFromQuery);
    }

    // this is the document we're viewing -- the order of priority is:
    // 1. the active document the user has selected, or if not present
    // 2. the default document as defined by the entity, or if not present
    // 3. the first document in the list, or if not present
    // 4. show nothing
    const nextActiveDocumentId =
      activeDocumentId ??
      defaultDocumentIdFromQuery ??
      documents?.[0]?.id ??
      null;

    if (nextActiveDocumentId) {
      setActiveDocumentId(nextActiveDocumentId);
    }
  }, [activeDocumentId, defaultDocumentIdFromQuery, documents]);

  useEffect(() => {
    if (overrideActiveDocumentId) {
      setActiveDocumentId(overrideActiveDocumentId);
    }
  }, [overrideActiveDocumentId]);

  useEffect(() => {
    if (overrideDocumentViewerActiveTab) {
      setDocumentViewerActiveTab(overrideDocumentViewerActiveTab);
    }
  }, [overrideDocumentViewerActiveTab]);

  const activeDocument = useMemo(() => {
    if (!documents) {
      return null;
    }

    return documents.find((doc) => doc.id === activeDocumentId) ?? null;
  }, [activeDocumentId, documents]);

  const updateDocumentViewerActiveTab = useCallback(
    (tab: DocumentViewerTab) => {
      setDocumentViewerActiveTab(tab);
      onUpdateDocumentViewerActiveTab?.();
    },
    [onUpdateDocumentViewerActiveTab]
  );

  const updateActiveDocumentId = useCallback(
    ({ documentId, shouldClearSearchResults }: UpdateActiveDocumentOpts) => {
      setActiveDocumentId(documentId);
      onUpdateActiveDocumentId?.({ documentId, shouldClearSearchResults });
    },
    [onUpdateActiveDocumentId]
  );

  return {
    householdId,
    targetId,
    targetKind,
    entityKind,

    documents,
    activeDocument,
    activeDocumentId,
    updateActiveDocumentId,

    defaultDocumentId,
    setDefaultDocumentId,

    documentViewerActiveTab,
    updateDocumentViewerActiveTab,

    newlyUploadedDocumentId,
    setNewlyUploadedDocumentId,

    loading,
    error,
    refetch,

    hideAddDocument,
  };
}

export interface HouseholdDocumentsViewerProviderProps {
  /** `targetId` can be an entity ID, testamentary entity ID, or client profile ID */
  targetId?: string;
  /** documentIds should be provided if targetId is not */
  documentIds?: string[];
  /** Use this if there is no underlying ID to target */
  isDispositiveProvisionsTemplateFlow?: boolean;

  householdId: string;
  /**
   * This is used to override and set the active document id in the Viewer context
   * by a parent component.
   * Example: when a user clicks to view the evidence of a suggestion.
   */
  overrideActiveDocumentId?: string;
  /**
   * This callback will be called when the active document id is updated via the
   * Viewer context.
   * Example: when a user uses the document selector to view another document.
   */
  onUpdateActiveDocumentId?: ({
    documentId,
    shouldClearSearchResults,
  }: UpdateActiveDocumentOpts) => void;
  /**
   * This is used to change the active tab of the documents viewer side of the split screen.
   * Example: a user is on the Summary tab, but clicks to view the evidence of a suggestion.
   */
  overrideDocumentViewerActiveTab?: DocumentViewerTab;
  /**
   * This callback will be called when the user changes the active tab of
   * the documents viewer side of the split screen.
   */
  onUpdateDocumentViewerActiveTab?: () => void;
  overrideSearchLoading?: boolean;

  /** If true, the "Add new" menu item in the document selector will be hidden */
  hideAddDocument?: boolean;

  /**
   * An optional function to order the documents in the viewer.
   * @param docs
   */
  documentOrderFn?: (
    docs: EntityDocumentViewer_DocumentFragment[]
  ) => EntityDocumentViewer_DocumentFragment[];
}

export const HouseholdDocumentsViewerProvider = ({
  children,
  ...props
}: PropsWithChildren<HouseholdDocumentsViewerProviderProps>) => {
  const value = useHouseholdDocumentsViewerContextValue(props);
  return (
    <HouseholdDocumentsViewerContext.Provider value={value}>
      {children}
    </HouseholdDocumentsViewerContext.Provider>
  );
};

function getDocumentsQueryVariables(
  targetId: string,
  householdId: string,
  targetKind: PulidKind | null,
  documentIds: string[] | undefined
): EntityDocumentViewerQueryVariables {
  const isClientProfile = targetKind === PulidKind.ClientProfile;

  return {
    targetId,
    isEntity: targetKind === PulidKind.Entity,
    isTestamentaryEntity: targetKind === PulidKind.TestamentaryEntity,
    isHousehold: isClientProfile || !!documentIds,
    householdDocumentsWhere: {
      hasHouseholdWith: [
        {
          id: householdId,
        },
      ],
      idIn: !!documentIds ? documentIds : undefined,
      typeIn: isClientProfile
        ? [
            DocumentType.Will,
            DocumentType.PowerOfAttorney,
            DocumentType.OperatingAgreement,
          ]
        : undefined,
    },
  };
}
