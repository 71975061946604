import { Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';

import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { useTabsContext } from '@/components/navigation/NavigationTabs/tabs.context';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import * as Types from '@/types/schema';
import { AfterDeath, EstateTaxScenarioPayoutKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatEnumCase } from '@/utils/formatting/strings';

import { getEstateTaxTabs } from './EstateTaxSummaryPanel.utils';
import { useSummaryPanelQuery } from './hooks/useSummaryPanelQuery';
import { getNodeDisplayValues } from './utils';

export interface PaymentsProps {
  firstDeathData: ReturnType<typeof useSummaryPanelQuery>['firstDeathData'];
  secondDeathData: ReturnType<typeof useSummaryPanelQuery>['secondDeathData'];
  totalsElements: {
    [Types.AfterDeath.None]: JSX.Element;
    [AfterDeath.First]: JSX.Element;
    [AfterDeath.Second]?: JSX.Element;
  };
}

export const Payments = ({
  firstDeathData,
  secondDeathData,
  totalsElements,
}: PaymentsProps) => {
  const { currentTab, setCurrentTab } = useTabsContext<AfterDeath>();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const { paymentNodesForTab, payoutKindForTab } = useMemo(() => {
    switch (currentTab) {
      case AfterDeath.None:
        return {
          paymentNodesForTab: [],
          payoutKindForTab: firstDeathData.payoutKind,
        };
      case AfterDeath.First:
        return {
          paymentNodesForTab: firstDeathData.nodesForDeathTarget,
          payoutKindForTab: firstDeathData.payoutKind,
        };
      case AfterDeath.Second:
        return {
          paymentNodesForTab: secondDeathData.nodesForDeathTarget,
          payoutKindForTab: secondDeathData.payoutKind,
        };
    }
  }, [
    currentTab,
    firstDeathData.nodesForDeathTarget,
    firstDeathData.payoutKind,
    secondDeathData.nodesForDeathTarget,
    secondDeathData.payoutKind,
  ]);

  const rowGroup = useMemo(() => {
    if (currentTab === AfterDeath.None) {
      return null;
    }

    // In this table, filter out nodes that did not transfer anything to the estate tax, then order by who paid the most
    const paymentNodes = orderBy(
      paymentNodesForTab.filter((n) => n.transferredToEstateTax?.toNumber()),
      (n) => n.transferredToEstateTax?.toNumber(),
      ['desc']
    );

    const payout = payoutKindForTab
      ? formatEnumCase(payoutKindForTab)
      : formatEnumCase(EstateTaxScenarioPayoutKind.ProRata);

    return {
      heading: `Payout order (${payout})`,
      items: paymentNodes.map((n) => {
        const { displayName, type } = getNodeDisplayValues(n);
        return {
          id: n.id,
          displayName,
          type,
          transferredToEstateTax: n.transferredToEstateTax,
        };
      }),
    };
  }, [currentTab, paymentNodesForTab, payoutKindForTab]);

  const tabValues = useMemo(() => {
    return getEstateTaxTabs(isTwoClientHousehold);
  }, [isTwoClientHousehold]);

  const tabStrings = useWaterfallGrantorDeathDisplay(firstDeathData.waterfall);

  const TabsForClient = useMemo(() => {
    return tabValues.map((tabValue) => {
      return (
        <ButtonTab
          key={tabValue}
          display={tabStrings[tabValue]}
          isActive={currentTab === tabValue}
          onClick={() => setCurrentTab(tabValue)}
        />
      );
    });
  }, [currentTab, setCurrentTab, tabStrings, tabValues]);

  return (
    <Stack gap={6}>
      <Stack spacing={3}>
        <Tabs fullWidth>{TabsForClient}</Tabs>
        {tabValues.map((tabValue) => (
          <TabContent tabKey={tabValue} key={tabValue}>
            <>{totalsElements[tabValue]}</>
          </TabContent>
        ))}
      </Stack>
      {rowGroup && (
        <GroupedHeaderList
          rowGroups={[rowGroup]}
          withDividers
          ItemComponent={({
            item: { id, displayName, type, transferredToEstateTax },
          }) => (
            <Stack
              key={id}
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
            >
              <RichListItem heading={displayName} description={type} />
              <Typography variant="body1">
                {transferredToEstateTax
                  ? formatCurrencyNoDecimals(transferredToEstateTax)
                  : EMPTY_CONTENT_HYPHEN}
              </Typography>
            </Stack>
          )}
        />
      )}
    </Stack>
  );
};
