import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreatePrivateFoundationInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdatePrivateFoundationInput,
  CreatePrivateFoundationInput,
  UpdatePrivateFoundationInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import { controllingPartySchema } from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  inEstateStatusSchema,
  nonTrustEntityTaxStatus,
  principalWithOwnershipSchema,
  stageSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const privateFoundationCreateUpdateProperties = {
  // share trust properties
  legalName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  description: optional(nullable(string())),
};

const createPrivateFoundationInput: Describe<CreatePrivateFoundationInput> =
  object({
    displayName: string(),
    taxStatus: nonTrustEntityTaxStatus,
    inEstateStatus: inEstateStatusSchema,
    ...privateFoundationCreateUpdateProperties,
  });

const createPrivateFoundationSchema: Describe<AugmentedCreatePrivateFoundationInput> =
  object({
    create: createPrivateFoundationInput,
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withDonors: optional(nullable(array(principalWithOwnershipSchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updatePrivateFoundationInput: Describe<UpdatePrivateFoundationInput> =
  object({
    displayName: optional(nullable(string())),
    ...privateFoundationCreateUpdateProperties,
    taxStatus: optional(nullable(nonTrustEntityTaxStatus)),
    inEstateStatus: inEstateStatusSchema,
    clearBeneficiaries: optional(nullable(booleanType())),
    clearDonors: optional(nullable(booleanType())),
    clearControllingParties: optional(nullable(booleanType())),
    clearDescription: optional(nullable(booleanType())),
    clearGstStatus: optional(nullable(booleanType())),
    clearTaxStatus: optional(nullable(booleanType())),
    removePolicyIDs: optional(nullable(array(string()))),
  });

const updatePrivateFoundationSchema: Describe<AugmentedUpdatePrivateFoundationInput> =
  object({
    id: string(),
    update: updatePrivateFoundationInput,
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withDonors: optional(nullable(array(principalWithOwnershipSchema))),
    withControllingParties: optional(nullable(array(trusteeSchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
    withPolicies: optional(nullable(array(createInsurancePolicyInput))),
    updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
  });

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withPrivateFoundation: optional(nullable(createPrivateFoundationSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object({
    ...commonUpdateEntityProperties,
    stage: optional(nullable(stageSchema)),
  }),
  updatePrivateFoundation: optional(nullable(updatePrivateFoundationSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validatePrivateFoundationCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Private foundation create');
}

export function validatePrivateFoundationUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Private foundation update');
}
