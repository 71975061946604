import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { EstateWaterfall } from '@/modules/estateWaterfall';
import { EstateWaterfallProvider } from '@/modules/estateWaterfall/contexts/EstateWaterfall.provider';
import { applyWaterfallFilter } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { getNodeId } from '@/modules/estateWaterfall/waterfallGraph/utils';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { DisclaimerCallout } from '@/modules/presentation/estateWaterfall/DisclaimerCallout';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';

import { useClientPresentationDesignerV2Context } from '../../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EstateWaterfallFragment } from '../../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Bundle } from '../../../../types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';

const MIN_HEIGHT = 500;

export interface EstateWaterfallDiagramSlideProps {
  bundle: ClientPresentationV2Bundle;
  SlideWrapper?: ComponentType<BoxProps>;
  page: ClientPresentationV2Page;
}
export function EstateWaterfallDiagramSlide({
  bundle,
  SlideWrapper,
  page,
}: EstateWaterfallDiagramSlideProps) {
  const { estateWaterfallBundleConfigurationMap } =
    useClientPresentationDesignerV2Context();
  const waterfall = estateWaterfallBundleConfigurationMap[bundle.id];
  const { primaryClients } = useHouseholdDetailsContext();

  if (!waterfall) {
    diagnostics.error(`Could not find waterfall for bundle ${bundle.id}`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Waterfall not found
      </Typography>
    );
  }

  if (!primaryClients) {
    diagnostics.error(`Could not find primary clients for household`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Primary clients not found
      </Typography>
    );
  }

  return (
    <EstateWaterfallDiagramSlideInner
      bundle={bundle}
      page={page}
      waterfall={waterfall}
      primaryClients={primaryClients}
      SlideWrapper={SlideWrapper}
    />
  );
}

interface EstateWaterfallDiagramSlideInnerProps
  extends EstateWaterfallDiagramSlideProps {
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment;
  primaryClients: ContextPrimaryClient[];
}
function EstateWaterfallDiagramSlideInner({
  SlideWrapper = Box,
  bundle,
  page,
  waterfall,
  primaryClients,
}: EstateWaterfallDiagramSlideInnerProps) {
  const waterfallId = waterfall.id;
  const householdId = waterfall.household?.id;

  /*
  useRegisterSlide({
    displayName: 'Diagram',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });
  */

  /*
   * From here to the next multi-line comment is ported over from useQueryWaterfall,
   * since we don't want to make another network call here.
   */
  // The whole waterfall gets remounted when the waterfallId changes, so this resets to an empty set automatically
  const [initWaterfallNodeIds, setInitWaterfallNodeIds] = useState<Set<string>>(
    new Set<string>()
  );
  const waterfallWithFilter = useMemo(() => {
    return applyWaterfallFilter(waterfall);
  }, [waterfall]);
  const isFilteredWaterfall =
    waterfallWithFilter?.visualizationWithProjections.nodes.length !==
    waterfall?.visualizationWithProjections.nodes.length;
  const isEmptyWaterfall = isEmpty(
    waterfall?.visualizationWithProjections.nodes
  );
  const visibleNodeIds = useMemo(
    () =>
      waterfallWithFilter?.visualizationWithProjections.nodes.map((node) =>
        getNodeId({
          id: node.id,
          afterDeath: node.afterDeath,
        })
      ) ?? [],
    [waterfallWithFilter?.visualizationWithProjections.nodes]
  );

  const hiddenNodeIds = useMemo(() => {
    return (
      waterfall?.visualizationWithProjections.nodes
        .filter(
          (node) =>
            !visibleNodeIds?.includes(
              getNodeId({
                id: node.id,
                afterDeath: node.afterDeath,
              })
            )
        )
        .map((node) =>
          getNodeId({
            id: node.id,
            afterDeath: node.afterDeath,
          })
        ) ?? []
    );
  }, [waterfall?.visualizationWithProjections.nodes, visibleNodeIds]);
  useEffect(() => {
    if (initWaterfallNodeIds.size > 0) return;
    if (!waterfall) return;

    const waterfallIds = new Set<string>();
    waterfall.visualizationWithProjections.nodes.forEach((node) => {
      const nodeId = getNodeId({
        id: node.group?.id ?? node.id,
        afterDeath: node.afterDeath,
      });

      waterfallIds.add(nodeId);
    });

    setInitWaterfallNodeIds(waterfallIds);
  }, [initWaterfallNodeIds.size, waterfall]);
  /*
   * End of ported code
   */
  if (!householdId) return null;

  return (
    <SlideWrapper key={`${bundle.id}-${waterfallId}`}>
      <PresentationSlide.Slide
        id={page.id}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={bundle.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<PresentationSlide.Footer />}
        readyToScale
        data-presentationwaterfallready="true"
      >
        <Stack
          spacing={3}
          justifyContent="space-between"
          py={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          <Stack flexGrow={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              px={PRESENTATION_SPACING_UNITS}
            >
              <Typography variant="h2">Waterfall diagram</Typography>
            </Stack>
            <FlowChartProvider>
              <EstateWaterfallProvider
                waterfall={waterfall as EstateWaterfallFragment}
                primaryClients={primaryClients}
                isFilteredWaterfall={isFilteredWaterfall}
                isEmptyWaterfall={isEmptyWaterfall}
                visibleNodeIds={visibleNodeIds}
                hiddenNodeIds={hiddenNodeIds}
                initWaterfallNodeIds={initWaterfallNodeIds}
                presentationMode
              >
                <Stack minHeight={MIN_HEIGHT} height="100%" flexGrow={1}>
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <EstateWaterfall
                        householdId={householdId}
                        waterfallId={waterfallId}
                        height={Math.max(MIN_HEIGHT, height)}
                        width={width}
                        // Key so the state is reset on slide change
                        key={`${bundle.id}-${waterfallId}`}
                      />
                    )}
                  </AutoSizer>
                </Stack>
              </EstateWaterfallProvider>
            </FlowChartProvider>
          </Stack>
          <Box px={PRESENTATION_SPACING_UNITS}>
            <DisclaimerCallout waterfall={waterfall} />
          </Box>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
