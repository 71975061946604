import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Frame } from '@/components/notifications/Frame/Frame';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { CreateNewEntityOrStrategy } from '@/modules/common/CreateNewEntityOrStrategy/CreateNewEntityOrStrategy';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';
import { EstateWaterfallSubPanelButtons } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons';
import { useGetHandleEstateWaterfallSubpanelButtonsClick } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons.hooks';
import { EstateWaterfallAssumptionsUpdater } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater';
import { WaterfallEmptyState } from '@/modules/estateWaterfall/components/WaterfallEmptyState';
import { useWaterfallSummaryContext } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { setMostRecentlyViewedWaterfallId } from '@/modules/estateWaterfall/EstateWaterfall.utils';

import { WaterfallSummaryProvider } from '../ClientDetailsEstateWaterfallSummaryListPage/contexts/WaterfallSummary.provider';
import { SummaryPanel } from '../ClientDetailsEstateWaterfallSummaryListPage/SummaryPanel';
import { WaterfallLoadedContent } from './WaterfallLoadedContent';

interface ClientDetailsEstateWaterfallSummaryPageInnerProps {
  householdId: string;
  waterfallId: string;
}

function ClientDetailsEstateWaterfallSummaryPageInner({
  householdId,
  waterfallId,
}: ClientDetailsEstateWaterfallSummaryPageInnerProps) {
  useEffect(() => {
    setMostRecentlyViewedWaterfallId(waterfallId, householdId);
  }, [householdId, waterfallId]);

  const {
    waterfall,
    loading,
    hasNoWaterfallNodes,
    summaryPanel: { isModalOpen },
  } = useWaterfallSummaryContext();
  const handleSubpanelButtonsClick =
    useGetHandleEstateWaterfallSubpanelButtonsClick();
  const hasWaterfallData = waterfall && !hasNoWaterfallNodes;

  const summaryCard = (
    <Stack
      display="grid"
      gridTemplateColumns={isModalOpen ? 'auto 430px' : 'auto'}
      gap={4}
    >
      <Frame variant={'outlined'}>
        {loading ? (
          <Loader
            boxProps={{
              sx: { textAlign: 'center' },
            }}
            circularProgressProps={{ size: 40 }}
          />
        ) : (
          <Stack spacing={0}>
            <Stack>
              {hasNoWaterfallNodes && (
                <Stack sx={{ p: 2.5 }}>
                  <WaterfallEmptyState
                    minHeight={600}
                    heading="No entities exist"
                    description="There are no entities associated with this client. As soon as you add entiites your estate waterfall will appear."
                    action={
                      <CreateNewEntityOrStrategy
                        householdId={householdId}
                        label="Create entity"
                      />
                    }
                  />
                </Stack>
              )}
              {hasWaterfallData && (
                <WaterfallLoadedContent waterfall={waterfall} />
              )}
            </Stack>
          </Stack>
        )}
      </Frame>
      <SummaryPanel />
    </Stack>
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        pb={2}
        alignItems="center"
      >
        <Typography variant="h1" component="h2">
          Summary
        </Typography>
        <EstateWaterfallSubPanelButtons onClick={handleSubpanelButtonsClick} />
      </Stack>
      <EstateWaterfallAssumptionsUpdater waterfallId={waterfallId}>
        {summaryCard}
      </EstateWaterfallAssumptionsUpdater>
      <Outlet />
    </>
  );
}

export const ClientDetailsEstateWaterfallSummaryPage = () => {
  const householdId = useRequiredParam('householdId');
  const waterfallId = useRequiredParam('waterfallId');

  return (
    <Stack>
      <WaterfallRouteProvider waterfallId={waterfallId}>
        <WaterfallSummaryProvider waterfallId={waterfallId}>
          <ClientDetailsEstateWaterfallSummaryPageInner
            householdId={householdId}
            waterfallId={waterfallId}
          />
        </WaterfallSummaryProvider>
      </WaterfallRouteProvider>
    </Stack>
  );
};
