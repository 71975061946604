import { Stack } from '@mui/material';
import { compact } from 'lodash';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { HeaderCard } from '@/components/layout/HeaderCard';

export function ClearLocalStorageValues() {
  // the eslint plugin for hooks is incorrectly flagging this as being called in a loop
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const keyCount = localStorage.length;

  const keyAccumulator: (string | null)[] = [];
  for (let i = 0; i < keyCount; i++) {
    keyAccumulator.push(localStorage.key(i));
  }

  const keys = compact(keyAccumulator).map((keyName) => ({
    display: keyName,
    value: keyName,
  }));

  const removeKey = useCallback(() => {
    if (selectedKey) window.localStorage.removeItem(selectedKey);
    setSelectedKey(null);
  }, [selectedKey]);

  return (
    <HeaderCard heading="Delete local storage key">
      <Stack spacing={2}>
        <SelectInput
          label="Key name"
          options={keys}
          onChange={(event) => setSelectedKey(event.target.value! || null)}
          value={selectedKey}
        />
        {selectedKey && (
          <Button variant="primary" size="md" onClick={removeKey}>
            Delete key
          </Button>
        )}
        <Button
          variant="destructive-prominent"
          size="md"
          onClick={() => window.localStorage.clear()}
        >
          Clear local storage
        </Button>
      </Stack>
    </HeaderCard>
  );
}
