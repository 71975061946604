import {
  Edge,
  SectionLabelNode,
  TileNode,
  TileNodeGroup,
} from '@/components/diagrams/FlowChart';
import { AfterDeath } from '@/types/schema';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import { EntityDiagram_EntityFragment } from '../graphql/EntityDiagram.generated';
import { EntityDiagram_NodeFragment } from '.';

export type GraphEdgeType = 'default';
export enum GraphNodeCategorizationType {
  Individual = 'INDIVIDUAL',
  PersonalAccount = 'PERSONAL_ACCOUNT',
  InsuranceAccount = 'INSURANCE_ACCOUNT',
  FamilyGiving = 'FAMILY_GIVING',
  CharitableEntity = 'CHARITABLE_ENTITY',
  SectionLabel = 'SECTION_LABEL',
  GroupNode = 'GROUP_NODE',
}

export interface MinimumNodeData {
  id: string;
  afterDeath: AfterDeath;
}

export type GraphNodeData = EntityDiagram_NodeFragment;

export type GraphNode = TileNode | TileNodeGroup | SectionLabelNode;

export interface EntityDiagramGraphNodeAttributes<
  Node = GraphNode,
  Data = GraphNodeData,
> {
  node: Node;
  data: Data;
  categorizationType: GraphNodeCategorizationType;
}

export interface EntityDiagramGraphEdgeAttributes {
  type: GraphEdgeType;
  edge: Edge;
}

export interface EntityDiagramGraphAttributes {
  firstPrimaryClientDeathId: string;
  entity: EntityDiagram_EntityFragment;
}

export type EntityDiagramGraph = FlowChartGraph<
  EntityDiagramGraphNodeAttributes,
  EntityDiagramGraphEdgeAttributes,
  EntityDiagramGraphAttributes
>;
