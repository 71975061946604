import { isEmpty } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFormContext } from '@/components/react-hook-form';
import { EntityKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { HypotheticalSaleLoanFormShape } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import { EstateWaterfallHypotheticalSaleLoanModal_EstateWaterfallFragment } from '../../graphql/EstateWaterfallHypotheticalSaleLoanModal.generated';
import {
  SaleLoanForm_HouseholdFragment,
  useSaleLoanFormQuery,
} from '../graphql/SaleLoanForm.generated';
import { SaleLoanFormContext } from './SaleLoanForm.context';

interface RecipientOptionParams {
  sellerId: string;
}

function getRecipientOptions(
  household: SaleLoanForm_HouseholdFragment | null,
  { sellerId }: RecipientOptionParams
): TypeaheadSelectInputOption<string>[] {
  // just to be defensive
  if (!household) return [];
  const { possibleBeneficiariesV2 } = household;
  const options: TypeaheadSelectInputOption<string>[] = [];

  possibleBeneficiariesV2.clients.forEach((client) => {
    options.push({
      value: client.id,
      display: client.displayName,
      groupName: 'Individuals',
      disabled: client.id === sellerId,
    });
  });

  possibleBeneficiariesV2.entities
    .filter((entity) =>
      [EntityKind.IrrevocableTrust, EntityKind.SlatTrust].includes(entity.kind)
    )
    .forEach((entity) => {
      options.push({
        value: entity.id,
        display: entity.subtype.displayName,
        groupName: 'Entities',
        disabled: entity.id === sellerId,
      });
    });

  return options;
}

interface SellerLenderOptionParams {
  recipientId: string;
}

function getSellerLenderOptions(
  household: SaleLoanForm_HouseholdFragment | null,
  { recipientId }: SellerLenderOptionParams
): TypeaheadSelectInputOption<string>[] {
  if (!household) return [];

  const sellerIndividuals = household.sellerIndividuals
    .filter((client) => !isEmpty(client.ownedOwnershipStakes))
    .map((client) => ({
      value: client.id,
      display: client.displayName,
      disabled: client.id === recipientId,
      groupName: 'Individuals',
    }));

  const sellerEntities = getNodes(household.sellerEntities).map((entity) => ({
    value: entity.id,
    display: entity.subtype.displayName,
    groupName: 'Entities',
    disabled: entity.id === recipientId,
  }));

  return [...sellerIndividuals, ...sellerEntities];
}

interface SaleLoanFormProviderProps extends PropsWithChildren {
  waterfallId: string;
  waterfall: EstateWaterfallHypotheticalSaleLoanModal_EstateWaterfallFragment | null;
  householdId: string;
  loading: boolean;
  // saleLoanId will be null if this is a new sale loan
  saleLoanId: string | null;
  // saleLoanFundingId will be null if this is a new sale loan
  saleLoanFundingId: string | null;
  growthProfileId: string | null;
}

export function SaleLoanFormProvider({
  children,
  loading: loadingExternal,
  saleLoanId,
  saleLoanFundingId,
  householdId,
  waterfallId,
  growthProfileId,
  waterfall,
}: SaleLoanFormProviderProps) {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const sellerId = useWatch({
    control,
    name: 'sellerId',
  });
  const recipientId = useWatch({
    control,
    name: 'recipientId',
  });
  const { data, loading, error } = useSaleLoanFormQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      householdId,
    },
  });

  const { possibleRecipients, possibleSellerLenders } = useMemo(() => {
    const household = getNodes(data?.households)[0];
    const possibleRecipients = getRecipientOptions(household ?? null, {
      sellerId,
    });
    const possibleSellerLenders = getSellerLenderOptions(household ?? null, {
      recipientId,
    });

    return {
      possibleRecipients,
      possibleSellerLenders,
    };
  }, [data?.households, recipientId, sellerId]);

  const contextValue = useMemo(
    () => ({
      possibleRecipients,
      possibleSellerLenders,

      loading: loading || loadingExternal,
      saleLoanId,
      waterfallId,
      saleLoanFundingId,
      error,
      growthProfileId,
      waterfall,
    }),
    [
      possibleRecipients,
      possibleSellerLenders,
      loading,
      loadingExternal,
      saleLoanId,
      waterfallId,
      saleLoanFundingId,
      error,
      growthProfileId,
      waterfall,
    ]
  );

  return (
    <SaleLoanFormContext.Provider value={contextValue}>
      {children}
    </SaleLoanFormContext.Provider>
  );
}
