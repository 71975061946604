import { COLORS } from '@/styles/tokens/colors';

export type NotificationSeverity =
  | 'success'
  | 'info-high'
  | 'info-low'
  | 'warning'
  | 'error';

export const NOTIFICATION_THEME_BY_SEVERITY: Record<
  NotificationSeverity,
  Record<'border' | 'text' | 'background', string>
> = {
  success: {
    border: COLORS.FUNCTIONAL.SUCCESS[300],
    text: COLORS.FUNCTIONAL.SUCCESS[900],
    background: COLORS.FUNCTIONAL.SUCCESS[100],
  },
  'info-high': {
    border: COLORS.NAVY[200],
    text: COLORS.NAVY[500],
    background: COLORS.NAVY[50],
  },
  'info-low': {
    border: COLORS.GRAY[200],
    text: COLORS.GRAY[500],
    background: COLORS.GRAY[100],
  },
  error: {
    border: COLORS.FUNCTIONAL.ERROR[300],
    text: COLORS.FUNCTIONAL.ERROR[900],
    background: COLORS.FUNCTIONAL.ERROR[100],
  },
  warning: {
    border: COLORS.FUNCTIONAL.WARNING[300],
    text: COLORS.FUNCTIONAL.WARNING[900],
    background: COLORS.FUNCTIONAL.WARNING[100],
  },
};
