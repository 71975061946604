import { compact, noop } from 'lodash';
import { createContext, PropsWithChildren, useMemo, useState } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { TenantPresentationConfigurationProvider } from '../../context/TenantPresentationConfiguration.provider';
import { getEstateWaterfallWithProjections } from './ClientPresentationDesignerV2.utils';
import { EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment } from './components/slides/estateWaterfall/Beneficiaries/graphql/EstateWaterfallBeneficiarySlide.generated';
import {
  ClientPresentationDesignerV2_EstateWaterfallFragment,
  ClientPresentationDesignerV2_EstateWaterfallOptionsFragment,
  ClientPresentationDesignerV2_GrowthProfileFragment,
  ClientPresentationV2_ClientPresentationV2Fragment,
  ClientPresentationV2_LoggedTransfersFragment,
} from './graphql/ClientPresentationDesignerV2.generated';

export interface ClientPresentationDesignerV2ContextShape {
  householdId: string;
  presentationId: string;
  growthProfileMap: Record<
    string,
    ClientPresentationDesignerV2_GrowthProfileFragment
  >;
  loggedTransfers: ClientPresentationV2_LoggedTransfersFragment[];
  estateWaterfallOptionsMap: Record<
    string,
    ClientPresentationDesignerV2_EstateWaterfallOptionsFragment
  >;
  beneficiaryWaterfallVizMap: Record<
    string,
    EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment
  >;
  estateWaterfallBundleConfigurationMap: Record<
    string,
    ClientPresentationDesignerV2_EstateWaterfallFragment
  >;
  setEstateWaterfallBundleConfigurationMap: (
    map: Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
  ) => void;
  setBeneficiaryWaterfallVizMap: (
    map: Record<
      string,
      EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment
    >
  ) => void;
}

export const ClientPresentationDesignerV2Context =
  createContext<ClientPresentationDesignerV2ContextShape>({
    householdId: '',
    presentationId: '',
    growthProfileMap: {},
    loggedTransfers: [],
    estateWaterfallOptionsMap: {},
    beneficiaryWaterfallVizMap: {},
    estateWaterfallBundleConfigurationMap: {},
    setEstateWaterfallBundleConfigurationMap: noop,
    setBeneficiaryWaterfallVizMap: noop,
  });

export function useClientPresentationDesignerV2Context() {
  return useGuardedContext(
    ClientPresentationDesignerV2Context,
    'ClientPresentationDesignerV2Context'
  );
}

export function useBundleWaterfall(
  bundleId: string | null | undefined
): ClientPresentationDesignerV2_EstateWaterfallFragment | null {
  const { estateWaterfallBundleConfigurationMap } =
    useClientPresentationDesignerV2Context();

  if (!bundleId) return null;

  const waterfall = estateWaterfallBundleConfigurationMap[bundleId];
  if (!waterfall) {
    diagnostics.info(
      `No waterfall found for bundle ${bundleId} in estateWaterfallBundleConfigurationMap`,
      { keys: Object.keys(estateWaterfallBundleConfigurationMap) }
    );
    return null;
  }

  return waterfall;
}

export interface ClientPresentationDesignerV2ProviderProps {
  householdId: string;
  presentationId: string;
  growthProfiles: ClientPresentationDesignerV2_GrowthProfileFragment[];
  loggedTransfers: ClientPresentationV2_LoggedTransfersFragment[];
  estateWaterfalls: ClientPresentationDesignerV2_EstateWaterfallOptionsFragment[];
  presentation:
    | ClientPresentationV2_ClientPresentationV2Fragment
    | null
    | undefined;
}

export function ClientPresentationDesignerV2Provider({
  children,
  householdId,
  presentationId,
  presentation,
  growthProfiles,
  loggedTransfers = [],
  estateWaterfalls,
}: PropsWithChildren<ClientPresentationDesignerV2ProviderProps>) {
  const growthProfileMap = useMemo(
    () =>
      growthProfiles.reduce<
        Record<string, ClientPresentationDesignerV2_GrowthProfileFragment>
      >((acc, growthProfile) => {
        acc[growthProfile.id] = growthProfile;
        return acc;
      }, {}),
    [growthProfiles]
  );

  const estateWaterfallOptionsMap = useMemo(
    () =>
      compact(estateWaterfalls).reduce<
        Record<
          string,
          ClientPresentationDesignerV2_EstateWaterfallOptionsFragment
        >
      >((acc, estateWaterfall) => {
        acc[estateWaterfall.id] = estateWaterfall;
        return acc;
      }, {}),
    [estateWaterfalls]
  );

  const [
    estateWaterfallBundleConfigurationMap,
    setEstateWaterfallBundleConfigurationMap,
  ] = useState<
    Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
  >(() => {
    if (!presentation) {
      return {};
    }
    const bundlesWithWaterfalls = getNodes(presentation.bundles).filter(
      (bundle) => !!bundle.waterfallBundleConfiguration?.estateWaterfall
    );

    return bundlesWithWaterfalls.reduce<
      Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
    >((acc, bundle) => {
      const estateWaterfall = getEstateWaterfallWithProjections(bundle);

      if (estateWaterfall) {
        acc[bundle.id] = estateWaterfall;
      }
      return acc;
    }, {});
  });

  const [beneficiaryWaterfallVizMap, setBeneficiaryWaterfallVizMap] = useState<
    Record<string, EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment>
  >(() => {
    if (!presentation) {
      return {};
    }
    const bundlesWithBeneficiaryWaterfallViz = getNodes(
      presentation.bundles
    ).filter(
      (bundle) =>
        !!bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz
    );

    return bundlesWithBeneficiaryWaterfallViz.reduce<
      Record<string, EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment>
    >((acc, bundle) => {
      if (bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz) {
        acc[bundle.id] =
          bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz;
      }

      return acc;
    }, {});
  });

  return (
    <CustomerThemeProvider>
      <TenantPresentationConfigurationProvider>
        <ClientPresentationDesignerV2Context.Provider
          value={{
            householdId,
            presentationId,
            growthProfileMap,
            loggedTransfers,
            estateWaterfallOptionsMap,
            estateWaterfallBundleConfigurationMap,
            setEstateWaterfallBundleConfigurationMap,
            beneficiaryWaterfallVizMap,
            setBeneficiaryWaterfallVizMap,
          }}
        >
          {children}
        </ClientPresentationDesignerV2Context.Provider>
      </TenantPresentationConfigurationProvider>
    </CustomerThemeProvider>
  );
}
