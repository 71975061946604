export enum TileVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Light = 'light',
  Destructive = 'destructive',
  Group = 'group',
  Standalone = 'standalone',
  Empty = 'empty',
  Pixel = 'pixel',
}
