import { diagnostics } from './diagnostics';

interface ApproximateTextHeightInput {
  text: string;
  maxWidthPx: number;
  lineHeightPx?: number;
  fontSizePx?: number;
}

/**
 * Approximates the height in pixels of a given text string in a fixed-width container.
 */
export function approximateTextHeight({
  text,
  maxWidthPx,
  lineHeightPx = 20,
  fontSizePx = 16,
}: ApproximateTextHeightInput) {
  // 1. Create an offscreen canvas for measuring text
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    diagnostics.error(
      'Failed to create canvas context',
      new Error('Failed to create canvas context')
    );
    return 0;
  }

  // 2. Set the font for measurement
  context.font = `${fontSizePx}px sans-serif`;

  // 3. Split text by explicit newlines:
  const manualLines = text.split('\n');

  let totalLines = 0;

  // 4. Process each 'manual' line for wrapping
  manualLines.forEach((line) => {
    // Split into words
    const words = line.trim().split(/\s+/);
    if (words.length === 0) {
      // Blank line still counts as 1 line
      totalLines += 1;
      return;
    }

    let currentLineWidth = 0;
    let lineCountForThisSection = 1; // At least one line

    words.forEach((word, index) => {
      const wordToMeasure = index === 0 ? word : ' ' + word;
      const { width } = context.measureText(wordToMeasure);

      // If adding this word exceeds the max width, move to the next line
      if (currentLineWidth + width > maxWidthPx) {
        lineCountForThisSection++;
        currentLineWidth = width; // Reset width to current word
      } else {
        // Otherwise, add to the current line width
        currentLineWidth += width;
      }
    });

    totalLines += lineCountForThisSection;
  });

  // 5. Multiply total lines by line-height
  return totalLines * lineHeightPx;
}
