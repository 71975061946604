import { cx } from '@emotion/css';
import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { ComponentProps, ReactNode, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { WithClasses } from '@/styles/types';

import { useUnguardedAccordionContext } from './Accordion.context';

export type AccordionVariant = 'default' | 'filled' | 'inner-shadow';

const useAccordionSummaryStyles = makeStyles({ name: { MUIAccordionSummary } })(
  (_theme) => ({
    root: {
      padding: '0px',
      minHeight: '0px',
      '&.Mui-expanded': {
        padding: '0px',
        minHeight: '0px',
      },
      '& .MuiAccordionSummary-content': {
        margin: '0px',
        padding: '0px',
        paddingRight: '5px',
        '&.Mui-expanded': {
          margin: '0px',
          padding: '0px',
          paddingRight: '5px',
        },
      },
    },
  })
);

const useAccordionDetailsStyles = makeStyles<{ expanded: boolean }>({
  name: { MUIAccordionDetails },
})((theme, { expanded }) => ({
  root: {
    marginTop: theme.spacing(2.5),
    borderTop: expanded
      ? `1px solid ${COLORS.GRAY[300]}`
      : `1px solid transparent`,
    padding: '0px',
    paddingTop: theme.spacing(2.5),
  },
}));

const useStyles = makeStyles<{
  expanded: boolean;
}>()((theme, { expanded }) => ({
  root: {
    padding: `${theme.spacing(2.5)} !important`,
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    '&.MuiAccordion-rounded': {
      borderRadius: theme.spacing(0.5),
    },
    border: `1px solid ${COLORS.GRAY[300]}`,
    borderRadius: theme.spacing(0.5),
    borderColor: expanded ? COLORS.GRAY[300] : 'transparent',
    transition: 'border-color 0.5s ease',
    boxShadow: 'none',
  },
}));

const useDefaultStyles = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${COLORS.GRAY[200]}`,
    '&.MuiAccordion-rounded': {
      borderRadius: theme.spacing(0.5),
    },
  },
}));

const useFilledStyles = makeStyles<{ expanded: boolean }>()((theme) => ({
  root: {
    backgroundColor: COLORS.GRAY[100],
    border: `1px solid ${COLORS.GRAY[200]}`,
    '&.MuiAccordion-rounded': {
      borderRadius: theme.spacing(0.5),
    },
  },
}));

const useInnerShadowStyles = makeStyles<{ expanded: boolean }>()((theme) => ({
  root: {
    backgroundColor: COLORS.GRAY[100],
    boxShadow: theme.palette.shadows.mdInset,
    border: `1px solid ${COLORS.GRAY[200]}`,
    '&.MuiAccordion-rounded': {
      borderRadius: theme.spacing(0.5),
    },
  },
}));

type MUIAccordionProps = ComponentProps<typeof MUIAccordion>;

export interface AccordionProps
  extends Omit<MUIAccordionProps, 'variant' | 'title' | 'onChange'> {
  classes?: WithClasses<typeof useDefaultStyles>;
  variant: AccordionVariant;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  labelContainerProps?: ComponentProps<typeof Stack>;
  title: ReactNode;
  subtitle?: string | ReactNode;
  description?: ReactNode;
  isDefaultExpanded?: boolean;
  onChange?: (expanded: boolean) => void;
  hideSubtitleOnOpen?: boolean;
  id?: string;
}

export function Accordion({
  classes: externalClasses,
  variant,
  leftAdornment,
  rightAdornment,
  title,
  subtitle,
  description,
  labelContainerProps = {},
  isDefaultExpanded = false,
  onChange,
  hideSubtitleOnOpen,
  id,
  ...props
}: Omit<AccordionProps, 'disableGutters'>) {
  const [expanded, setExpanded] = useState(isDefaultExpanded);

  const possibleAccordionContext = useUnguardedAccordionContext();
  const closedAccordionIds = useMemo(() => {
    return possibleAccordionContext?.closedAccordionIds ?? [];
  }, [possibleAccordionContext?.closedAccordionIds]);

  useEffect(() => {
    // we don't want to collapse something that's already open just becuase the new "isDefaultExpanded" is false
    if (isDefaultExpanded !== expanded && isDefaultExpanded === true) {
      setExpanded(isDefaultExpanded);
    }
    // no exhaustive deps because we only want to rerun this if the default expanded prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultExpanded]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
    onChange?.(!expanded);
  };

  const { classes } = useStyles(
    { expanded },
    {
      props: { classes: externalClasses, ownerState: { expanded } },
    }
  );

  const { classes: defaultClasses } = useDefaultStyles();
  const { classes: filledClasses } = useFilledStyles({ expanded });
  const { classes: innerShadowClasses } = useInnerShadowStyles({ expanded });
  const { classes: summaryClasses } = useAccordionSummaryStyles();
  const { classes: detailsClasses } = useAccordionDetailsStyles({ expanded });

  const variantClasses: Record<AccordionVariant, typeof classes> = {
    default: defaultClasses,
    filled: filledClasses,
    'inner-shadow': innerShadowClasses,
  };

  const variantStyles = variantClasses[variant];

  const showSubtitle = useMemo(() => {
    if (!hideSubtitleOnOpen) {
      return true;
    }

    return !expanded;
  }, [expanded, hideSubtitleOnOpen]);

  useEffect(() => {
    if (!id || !closedAccordionIds.length) {
      return;
    }
    setExpanded(!closedAccordionIds.includes(id));
  }, [closedAccordionIds, id]);

  return (
    <MUIAccordion
      {...props}
      disableGutters
      expanded={expanded}
      onChange={handleAccordionChange}
      className={cx(classes.root, variantStyles.root)}
    >
      <MUIAccordionSummary
        className={cx(summaryClasses.root)}
        expandIcon={<ChevronDownIcon size={20} />}
      >
        <Stack
          direction="row"
          spacing={1}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="start"
          >
            {leftAdornment}
            <Stack spacing={0.5} {...labelContainerProps}>
              <Typography
                variant="label2"
                sx={{
                  fontWeight: FONT_WEIGHTS.bold,
                }}
              >
                {title}
              </Typography>
              {subtitle &&
                showSubtitle &&
                (typeof subtitle === 'string' ? (
                  <Typography variant="subtitle2">{subtitle}</Typography>
                ) : (
                  subtitle
                ))}
              {description && (
                <Typography variant="body1">{description}</Typography>
              )}
            </Stack>
          </Stack>
          {rightAdornment && <Box>{rightAdornment}</Box>}
        </Stack>
      </MUIAccordionSummary>
      <MUIAccordionDetails
        className={cx(detailsClasses.root)}
        aria-hidden={!expanded}
      >
        <>{props.children}</>
      </MUIAccordionDetails>
    </MUIAccordion>
  );
}
