import { groupBy, keyBy } from 'lodash';

import { isTileNode } from '@/components/diagrams/FlowChart/utils/nodes';

import { EntityDiagramDerivedData, EntityDiagramState } from '../types';

export function applyDerivedDataToState(
  state: EntityDiagramState
): EntityDiagramState {
  const nodesById = keyBy(state.nodes, (n) => n.id);
  const edgesById = keyBy(state.edges, (n) => n.id);

  const nodesByType = groupBy(
    state.nodes,
    (n) => n.type
  ) as EntityDiagramDerivedData['nodesByType'];

  const nodesBySectionId = state.nodes.reduce(
    (acc, n) => {
      if (isTileNode(n) && n.data.sectionLabelId) {
        acc[n.data.sectionLabelId] = acc[n.data.sectionLabelId] ?? [];
        acc[n.data.sectionLabelId]?.push(n);
      }
      return acc;
    },
    {} as EntityDiagramDerivedData['nodesBySectionId']
  );

  return {
    ...state,
    derivedData: {
      nodesById,
      edgesById,
      nodesByType,
      nodesBySectionId,
    },
  };
}
