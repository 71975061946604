import { orderBy } from 'lodash';

import {
  PersonalAccountDetailsCard_ControllingPartyFragment,
  PersonalAccountDetailsCard_OwnerPrincipalFragment,
} from '@/modules/personalAccounts/PersonalAccountDetails/graphql/PersonalAccountDetailsCard.generated';
import { PersonalAccountSummaryProps } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountSummary';
import { EntityInEstateStatus, NonTrustEntityTaxStatus } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityDetailPropsKind } from '../../components/EntityDetails';
import {
  JOINT_ACCOUNT_TYPE_DISPLAY_NAMES,
  RETIREMENT_ACCOUNT_TYPE_DISPLAY_NAMES,
} from '../../personalAccounts/personalAccounts.constants';
import { getAssetLocationDisplay } from '../../utils/getAssetLocationDisplay';
import { getFormattedNonTrustEntityTaxStatus } from '../entityDetailPageUtils';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';

export function getOwnerNameFromFragment(
  owner: PersonalAccountDetailsCard_OwnerPrincipalFragment
): string {
  if (owner.individual) {
    return owner.individual.legalName;
  }

  return 'Unknown owner';
}

export function getControllingPartyNameFromFragment(
  controllingParty: PersonalAccountDetailsCard_ControllingPartyFragment
): string {
  if (controllingParty.individual) {
    return controllingParty.individual.legalName;
  }

  if (controllingParty.organization) {
    return controllingParty.organization.name;
  }

  return 'Unknown controlling party or signatory';
}

export function getSharedSummaryProperties(
  data: EntityDetail_EntityFragment
): PersonalAccountSummaryProps {
  if (
    data.subtype.__typename !== 'CustodialPersonalAccount' &&
    data.subtype.__typename !== 'QualifiedTuitionPersonalAccount' &&
    data.subtype.__typename !== 'RetirementPersonalAccount' &&
    data.subtype.__typename !== 'JointPersonalAccount' &&
    data.subtype.__typename !== 'IndividualPersonalAccount' &&
    data.subtype.__typename !== 'InsurancePersonalAccount'
  ) {
    throw new Error(`Invalid account subtype ${data.subtype.__typename}`);
  }

  // variables to coerce the API types for these values into
  // consistent types for the summary properties because
  // the API types vary in requiredness depending on the subtype
  let taxStatus: NonTrustEntityTaxStatus | null = null;
  let inEstateStatus: EntityInEstateStatus | null = null;

  if ('nonTrustEntityTaxStatusRequired' in data.subtype) {
    taxStatus = data.subtype.nonTrustEntityTaxStatusRequired ?? null;
    inEstateStatus = data.subtype.inEstateStatusRequired ?? null;
  }

  if ('nonTrustEntityTaxStatus' in data.subtype) {
    taxStatus = taxStatus ?? data.subtype.nonTrustEntityTaxStatus ?? null;
    inEstateStatus = inEstateStatus ?? data.subtype.inEstateStatus ?? null;
  }

  let accountType: string | undefined = undefined;

  if (
    'retirementAccountType' in data.subtype &&
    data.subtype.retirementAccountType
  ) {
    const displayType =
      RETIREMENT_ACCOUNT_TYPE_DISPLAY_NAMES[data.subtype.retirementAccountType];
    accountType = `(${displayType})`;
  }

  if ('jointAccountType' in data.subtype && data.subtype.jointAccountType) {
    const displayType =
      JOINT_ACCOUNT_TYPE_DISPLAY_NAMES[data.subtype.jointAccountType];
    accountType = `(${displayType})`;
  }

  return {
    _kind: EntityDetailPropsKind.PersonalAccountSummaryData,
    entityId: data.id,
    description: data.subtype.description || undefined,
    entityKind: data.kind,
    extendedDisplayKind: data.extendedDisplayKind,
    ownerOfStakes: data.ownedOwnershipStakes || undefined,
    accountType,
    taxStatus: getFormattedNonTrustEntityTaxStatus(taxStatus),
    locationDisplay:
      getAssetLocationDisplay({
        inEstateStatus,
        stateTaxes: orderBy(
          (data.stateTaxes ?? []).map(({ stateCode, inEstateStatus }) => ({
            stateCode,
            inEstateStatus,
          })),
          'stateCode'
        ),
        gstStatus: data.subtype.gstStatus,
      }).locationDisplay ?? undefined,
    cashflows: getNodes(data.cashflows) || [],
  };
}
