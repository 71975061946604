import { Stack } from '@mui/material';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { Building07Icon } from '@/components/icons/Building07Icon';
import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import {
  ClientOrganizationModal,
  ClientOrganizationModalProps,
} from '@/modules/clientOrganizations/ClientOrganizationModal';
import {
  ClientProfileModal,
  ClientProfileModalProps,
} from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { CreateDraftEntityModal } from '@/modules/entities/draftEntities/CreateDraftEntityModal/CreateDraftEntityModal';
import { CreateEntityShortFormModalProps } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';
import {
  TestamentaryEntityModal,
  TestamentaryEntityModalProps,
} from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { AddNewAnythingActionCard } from './AddNewAnythingActionCard';

// importing this dynamically to avoid a circular dependency where:
//  CreateEntityShortForm requires BusinessEntityDetailsSubform
//  BusinessEntityDetailsSubform required AddNewAnythingModal
//  AddNewAnythingModal requires CreateEntityShortForm
const CreateEntityShortFormModal = dynamic(
  () =>
    import(
      `../../entities/EntityShortFormModal/CreateEntityShortFormModal`
    ).then((module) => module.CreateEntityShortFormModal),
  { ssr: false }
);

export interface AddNewAnythingModalProps {
  handleClose: () => void;
  isOpen: boolean;
  householdId: string;
  addableTypes: AddableType[];
  // Callback after creating a new client profile, client organization,
  // or entity that passes back the new id
  onAfterCreate?: (newId: string) => void;
  // Additional optional props to pass to the ClientProfile submodal
  addClientProfileModalProps?: Pick<
    ClientProfileModalProps,
    | 'forceBeneficiaryIfNoClientProfileId'
    | 'forceTrusteeIfNoClientProfileId'
    | 'forceGrantorIfNoClientProfileId'
    | 'initialClientProfileName'
    | 'ignoredQueryDocuments'
  >;
  // Additional optional props to pass to the ClientOrganization submodal
  addClientOrganizationModalProps?: Pick<
    ClientOrganizationModalProps,
    'requirement' | 'initialOrganizationName' | 'ignoredQueryDocuments'
  >;
  // Additional optional props to pass to the CreateEntityShortForm submodal
  addEntityModalProps?: Pick<
    CreateEntityShortFormModalProps,
    'initialEntityName' | 'ignoredQueryDocuments'
  >;
  // Additional optional props to pass to the CreateTestamentaryEntityShortForm submodal
  addTestamentaryEntityModalProps?: Pick<
    TestamentaryEntityModalProps,
    | 'dyingPrimaryClientId'
    | 'deadClientIds'
    | 'defaultName'
    | 'defaultKind'
    | 'defaultEstateTaxStatus'
    | 'ignoredQueryDocuments'
  >;
}

export type AddableType =
  | 'individual'
  | 'organization'
  | 'entity'
  | 'testamentaryEntity'
  | 'draftEntity';

/**
 * @description This modal is intended as a reusable component that exposes the
 * ability to add a new individual, entity, or organization.
 */
export function AddNewAnythingModal({
  handleClose,
  isOpen,
  householdId,
  addableTypes,
  onAfterCreate,
  addClientProfileModalProps,
  addClientOrganizationModalProps,
  addEntityModalProps,
  addTestamentaryEntityModalProps,
}: AddNewAnythingModalProps) {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const [addNewType, setAddNewType] = useState<AddableType | null>(null);
  // prior to opening the entity creation form, we open the entity picker modal to collect the entity type
  // that they want to add.

  function handleClick(value: AddableType | null) {
    setAddNewType(value);
    handleClose();
  }

  function handleSubmodalClose() {
    setAddNewType(null);
    handleClose();
  }

  return (
    <>
      {addNewType === 'individual' && (
        <ClientProfileModal
          isOpen
          setIsOpen={handleSubmodalClose}
          clientProfileId={null}
          householdId={householdId}
          clientProfileTypeDisplay="individual"
          onAfterCreate={onAfterCreate}
          {...addClientProfileModalProps}
        />
      )}

      {addNewType === 'entity' && (
        <CreateEntityShortFormModal
          isOpen
          onClose={() => handleSubmodalClose()}
          onAfterCreate={onAfterCreate}
          householdId={householdId}
          {...addEntityModalProps}
        />
      )}

      {addNewType === 'organization' && (
        <ClientOrganizationModal
          householdId={householdId}
          setIsOpen={handleSubmodalClose}
          isOpen
          organizationId={null}
          onAfterCreate={onAfterCreate}
          {...addClientOrganizationModalProps}
        />
      )}

      {addNewType === 'draftEntity' && (
        <CreateDraftEntityModal
          householdId={householdId}
          isOpen
          onClose={() => handleSubmodalClose()}
        />
      )}

      {addNewType === 'testamentaryEntity' &&
        addTestamentaryEntityModalProps && (
          <TestamentaryEntityModal
            isOpen
            isTwoClientHousehold={isTwoClientHousehold}
            onClose={() => handleSubmodalClose()}
            {...addTestamentaryEntityModalProps}
          />
        )}

      <DialogModal
        isOpen={isOpen}
        onClose={handleClose}
        heading="What would you like to add?"
      >
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            {addableTypes.includes('individual') && (
              <AddNewAnythingActionCard
                description="Individual"
                Icon={User01Icon}
                onClick={() => handleClick('individual')}
              />
            )}
            {addableTypes.includes('organization') && (
              <AddNewAnythingActionCard
                description="Organization"
                Icon={Building07Icon}
                onClick={() => handleClick('organization')}
              />
            )}
            {addableTypes.includes('entity') && (
              <AddNewAnythingActionCard
                description="Entity"
                Icon={FileShield02Icon}
                onClick={() => handleClick('entity')}
              />
            )}
            {addableTypes.includes('draftEntity') && (
              <AddNewAnythingActionCard
                description="Draft entity"
                Icon={FileShield02Icon}
                onClick={() => handleClick('draftEntity')}
              />
            )}
            {addableTypes.includes('testamentaryEntity') && (
              <AddNewAnythingActionCard
                description="Testamentary entity"
                Icon={FileShield02Icon}
                onClick={() => handleClick('testamentaryEntity')}
              />
            )}
          </Stack>
        </Stack>
      </DialogModal>
    </>
  );
}
