import { Stack, Typography } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { RowData } from '@/modules/trusts/TrustBeneficiariesList/FlatBeneficiaryTable.types';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';

interface PersonalAccountBeneficiariesV2Props {
  /**
   * List of beneficiary rows, can be null if we do not want to show the table
   */
  rows: RowData[] | null;
  heading?: string;
}

export function PersonalAccountBeneficiariesV2({
  rows,
  heading,
}: PersonalAccountBeneficiariesV2Props) {
  if (rows === null) {
    return null;
  }

  const noRows = rows.length === 0;

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      <Stack
        spacing={noRows ? 0.5 : 2}
        width="100%"
        sx={{
          alignItems: noRows ? 'center' : undefined,
        }}
      >
        <Typography variant="h6" component="span">
          {heading ?? 'Beneficiaries'}
        </Typography>
        {noRows && <Typography variant="subtitle2">None specified</Typography>}
        {!noRows && (
          <TrustBeneficiariesList
            variant="flat"
            beneficiariesGroups={[
              {
                heading: undefined,
                items: [],
              },
            ]}
            rows={rows}
          />
        )}
      </Stack>
    </Card>
  );
}
