import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { EntityKind } from '@/types/schema';

import { RowData } from './FlatBeneficiaryTable.types';
import { getColumns, getRows } from './FlatBeneficiaryTable.utils';
import { TrustBeneficiariesList_BeneficiaryFragment } from './graphql/TrustBeneficiariesList.fragments.generated';

interface FlatBeneficiaryTableProps {
  heading?: string;
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[];
  entityKind?: EntityKind;
  rows?: RowData[];
}

export function FlatBeneficiaryTable({
  heading,
  beneficiaries,
  entityKind,
  rows: rowsExternal,
}: FlatBeneficiaryTableProps) {
  const columns = useMemo(() => {
    return getColumns({ heading });
  }, [heading]);

  const rows = useMemo(() => {
    if (rowsExternal) {
      return rowsExternal;
    }

    return getRows(beneficiaries, entityKind);
  }, [beneficiaries, entityKind, rowsExternal]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      hideFooterPagination
      pagination={false}
      columnHeaderHeight={40}
      style={{
        border: 'none',
      }}
    />
  );
}
