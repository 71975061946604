import { compact } from 'lodash';

import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileNode } from '@/components/diagrams/FlowChart';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { formatEnumCase } from '@/utils/formatting/strings';

import {
  EntityDiagram_NodeFragment,
  EntityDiagramGraph,
  GraphNodeCategorizationType,
} from '../types';
import {
  categorizationTypeToVariant,
  EntityNode,
  kindToCategorizationType,
  ValidTypeNamesSet,
} from './constants';

export function getNodeId({
  id,
  afterDeath,
}: Pick<EntityDiagram_NodeFragment, 'id' | 'afterDeath'>) {
  return [afterDeath, id].join(':');
}

export function isEntityNode(
  n: EntityDiagram_NodeFragment['node']
): n is EntityNode {
  if (!n) {
    return false;
  }

  return ValidTypeNamesSet.has(n.__typename);
}

export function isNodeIdForSection(nodeId: string, sectionId: AfterDeath) {
  return nodeId.startsWith(`${sectionId}:`);
}

export function getCategorizationType({
  node,
}: EntityDiagram_NodeFragment): GraphNodeCategorizationType | null {
  let kind;
  if (node?.__typename === 'Entity') {
    kind = node.entityKind;
  } else if (node?.__typename === 'TestamentaryEntity') {
    kind = node.testamentaryEntityKind;
  } else if (
    node?.__typename === 'ClientProfile' ||
    node?.__typename === 'ClientOrganization'
  ) {
    return GraphNodeCategorizationType.Individual;
  } else {
    throw new Error(`Unhandled tile in entity diagram`);
  }

  const type = kindToCategorizationType[kind];
  if (!type) {
    diagnostics.error(`Unhandled type from kind in entity diagram ${type}`);
    return null;
  }
  return type;
}

export interface BuildTileFromNodeInput {
  nodeFragment: EntityDiagram_NodeFragment;
  firstDeathGrantor?: ContextPrimaryClient;
  secondDeathGrantor?: ContextPrimaryClient;
  isNewTile?: boolean;
}

export function buildTileFromNode({
  nodeFragment,
  firstDeathGrantor,
  secondDeathGrantor,
  isNewTile,
}: BuildTileFromNodeInput): TileNode | null {
  const { id, node, afterDeath } = nodeFragment;
  if (!isEntityNode(node)) {
    diagnostics.error(`Unhandled tile in entity diagram`);
    return null;
  }

  const inEstateStatus = (() => {
    if ('inEstateStatus' in nodeFragment) {
      return nodeFragment.inEstateStatus;
    }
    return EntityInEstateStatus.InEstate;
  })();

  const nameOfDeadGrantor = (() => {
    if (afterDeath === AfterDeath.First) {
      return firstDeathGrantor?.displayName;
    } else if (afterDeath === AfterDeath.Second) {
      return secondDeathGrantor?.displayName;
    }
    return undefined;
  })();

  const categorizationType = getCategorizationType(nodeFragment);
  if (!categorizationType) {
    // just return here; this is logged in getCategorizationType
    return null;
  }

  let lineOne = '';
  let lineTwo = '';
  let variant = categorizationTypeToVariant[categorizationType];

  if (inEstateStatus === EntityInEstateStatus.InEstate) {
    variant = TileVariant.Primary;
  }

  if (node.__typename === 'Entity') {
    lineOne = node.subtype.displayName ?? '';
    lineTwo = node.extendedDisplayKind;
  } else if (node.__typename === 'TestamentaryEntity') {
    lineOne = node.displayName;
    lineTwo = testamentaryEntityKindToDisplayName(
      node.testamentaryEntityKind,
      nameOfDeadGrantor
    );
  } else if (node.__typename === 'ClientProfile') {
    lineOne = node.displayName;
  } else if (node.__typename === 'ClientOrganization') {
    lineOne = node.name;
    lineTwo = formatEnumCase(node.kind);
    if (
      node.kind === ClientOrganizationKind.CharitableOrganization &&
      inEstateStatus !== EntityInEstateStatus.InEstate
    ) {
      variant = TileVariant.Tertiary;
    }
  }

  const nodeId = getNodeId({ id, afterDeath });
  const frameText: string | undefined = undefined;

  return {
    id: nodeId,
    type: 'tile',
    position: {
      x: 0,
      y: 0,
    },
    data: {
      lineOne,
      lineTwo,
      variant,
      frameText,
      sectionLabelId: afterDeath,
      isNewTile,
    },
  };
}

export function extractReactFlowNodesAndEdges(graph: EntityDiagramGraph) {
  const nodes = compact(
    graph.mapNodes((_nodeId, node) => {
      return node.node;
    })
  );

  const edges = graph.getEdges();

  return { nodes, edges };
}
