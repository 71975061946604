import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  EditEntitySection,
  isEditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';

// when this is set, it indicates that we should open the entity edit
// modal immediately upon landing on this page.
export const IMMEDIATE_EDIT_SEARCH_PARAM = 'immediateEdit' as const;

interface UseImmediateOpenEditModalReturn {
  doForceEditing: boolean;
  initialSection?: EditEntitySection;
  clearImmediateEditSearchParam: () => void;
}
export function useImmediateOpenEditModal(): UseImmediateOpenEditModalReturn {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Once the edit entity modals have immediately opened, they will call this
   * to clear the search param, so it doesn't keep re-triggering.
   */
  const clearImmediateEditSearchParam = useCallback(() => {
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.delete(IMMEDIATE_EDIT_SEARCH_PARAM);
    setSearchParams(nextSearchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const paramValue = useMemo(
    () => searchParams.get(IMMEDIATE_EDIT_SEARCH_PARAM),
    [searchParams]
  );

  return {
    doForceEditing: paramValue === 'true' || isEditEntitySection(paramValue),
    initialSection: isEditEntitySection(paramValue) ? paramValue : undefined,
    clearImmediateEditSearchParam,
  };
}
