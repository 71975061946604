import { styled } from '@mui/material';
import MuiPopper from '@mui/material/Popper';
import type { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import { debounce } from 'lodash';
import { ComponentProps, useMemo, useState } from 'react';

import { zIndexes } from '@/styles/zIndexes';

const ARROW_OFFSET_Y = 10;

interface ToolTipProps {
  open?: boolean;
  anchorRef: React.RefObject<HTMLElement | null>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  center?: boolean;
  popperModifiers?: ComponentProps<typeof MuiPopper>['modifiers'];
  disableArrow?: boolean;
}

const offsetFn: OffsetsFunction = ({ popper }) => {
  return [popper.width * 0.4, ARROW_OFFSET_Y];
};

const debouncedOffsetFn = debounce(offsetFn, 200, {
  leading: true,
  trailing: true,
});

const SCREEN_MARGIN = 24 as const; // theme.spacing(3)

export function Popper({
  children,
  open,
  anchorRef,
  onMouseEnter,
  onMouseLeave,
  center,
  popperModifiers: popperModifiersExternal = [],
  disableArrow,
}: React.PropsWithChildren<ToolTipProps>) {
  // the normal useRef here was behaving really strangely
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const popperModifiers = useMemo(() => {
    const modifiers = [];

    if (center) {
      modifiers.push({
        name: 'offset',
        options: {
          // https://popper.js.org/docs/v2/modifiers/offset/
          offset: [0, ARROW_OFFSET_Y],
        },
      });
    }

    return [...modifiers, ...popperModifiersExternal];
  }, [center, popperModifiersExternal]);

  return (
    <>
      <PopperInner
        anchorEl={anchorRef.current}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        open={!!open}
        placement="top"
        modifiers={[
          {
            name: 'arrow',
            enabled: !disableArrow,
            options: {
              element: arrowRef,
            },
          },
          {
            name: 'offset',
            options: {
              offset: debouncedOffsetFn,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: SCREEN_MARGIN,
              altAxis: true,
            },
          },
          ...popperModifiers,
        ]}
      >
        {!disableArrow && (
          <Arrow
            ref={(ref: HTMLDivElement) => setArrowRef(ref)}
            className="MuiPopper-arrow"
          />
        )}
        <>{children}</>
      </PopperInner>
    </>
  );
}

export const PopperInner = styled(MuiPopper)(({ theme }) => ({
  boxShadow: theme.palette.shadows.lg,
  zIndex: zIndexes.TOOLTIP,
  borderRadius: '8px',
  '& > div': {
    position: 'relative',
  },

  '&[data-popper-placement*="top"]': {
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `white transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="bottom"]': {
    boxShadow: theme.palette.shadows.lgYInvert,
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent white transparent`,
      },
    },
  },
}));

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 9,

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});
