import Decimal from 'decimal.js';
import { isNumber } from 'lodash';

import { getGrantorRelationshipsFriendlyString } from '@/modules/clientProfiles/clientProfileUtils';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { getDisplayNameFromEntityType } from '@/modules/entities/utils/getDisplayNameFromEntityType';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { EstateWaterfallSummaryTileSummaryPanelNode } from '@/modules/summaryPanels/EstateWaterfallSummmaryTileSummaryPanel/types';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatEnumCase } from '@/utils/formatting/strings';

import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from '../../graphql/GetWaterfallSummary.generated';

export function formatCompactValue(value: number | Decimal) {
  return formatCurrency(isNumber(value) ? new Decimal(value) : value, {
    notation: 'compact',
  });
}

export function getNameFromNode(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment['node']
) {
  if (node.__typename === 'ClientProfile') return node.displayName;
  if (node.__typename === 'TestamentaryEntity') return node.displayName;
  if (node.__typename === 'ClientOrganization') return node.name;
  if (node.__typename === 'Entity') return node.subtype.displayName;
  throw new Error(`Invalid node type: ${node.__typename}`);
}

export function getSubtitleFromNode(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment['node']
) {
  if (node.__typename === 'ClientProfile') {
    const subtitle = getGrantorRelationshipsFriendlyString(node.relationships);
    // Skip grantor subtitle for self
    return subtitle.includes('Self') ? 'Client' : subtitle;
  }
  if (node.__typename === 'TestamentaryEntity') {
    return testamentaryEntityKindToDisplayName(node.testamentaryEntityKind);
  }
  if (node.__typename === 'ClientOrganization') {
    return formatEnumCase(node.clientOrganizationKind);
  }
  if (node.__typename === 'Entity') {
    return getDisplayNameFromEntityType(
      getEntityTypeFromEntityKind(node.entityKind)
    );
  }
  return '';
}

export function getSummaryTileNode(
  vizNode: GetWaterfallSummary_EstateWaterfallVizNodeFragment
): EstateWaterfallSummaryTileSummaryPanelNode {
  return {
    id: vizNode.id,
    name: getNameFromNode(vizNode.node),
    subtitle: getSubtitleFromNode(vizNode.node) ?? '',
    value: vizNode.value,
    vizNode,
  };
}
