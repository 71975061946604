// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { KgRootWithAsyncJobFragmentDoc } from '../../asyncJobs/AsyncJobNotifications/graphql/asyncJobNotifications.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateKgRootMutationVariables = Types.Exact<{
  updateKGRootInput: Types.AugmentedUpdateKgRootInput;
}>;


export type UpdateKgRootMutation = { __typename?: 'Mutation', updateKgRoot: { __typename?: 'KGRoot', id: string, status: Types.KgRootStatus } };

export type GetHouseholdPendingAidpkgRootQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type GetHouseholdPendingAidpkgRootQuery = { __typename?: 'Query', kgRoots: { __typename?: 'KGRootConnection', edges?: Array<{ __typename?: 'KGRootEdge', node?: { __typename?: 'KGRoot', id: string, kind: Types.KgRootKind, status: Types.KgRootStatus, asyncJob?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null } | null> | null } };


export const UpdateKgRootDocument = gql`
    mutation UpdateKGRoot($updateKGRootInput: AugmentedUpdateKGRootInput!) {
  updateKgRoot(input: $updateKGRootInput) {
    id
    status
  }
}
    `;
export type UpdateKgRootMutationFn = Apollo.MutationFunction<UpdateKgRootMutation, UpdateKgRootMutationVariables>;

/**
 * __useUpdateKgRootMutation__
 *
 * To run a mutation, you first call `useUpdateKgRootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKgRootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKgRootMutation, { data, loading, error }] = useUpdateKgRootMutation({
 *   variables: {
 *      updateKGRootInput: // value for 'updateKGRootInput'
 *   },
 * });
 */
export function useUpdateKgRootMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKgRootMutation, UpdateKgRootMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKgRootMutation, UpdateKgRootMutationVariables>(UpdateKgRootDocument, options);
      }
export type UpdateKgRootMutationHookResult = ReturnType<typeof useUpdateKgRootMutation>;
export type UpdateKgRootMutationResult = Apollo.MutationResult<UpdateKgRootMutation>;
export type UpdateKgRootMutationOptions = Apollo.BaseMutationOptions<UpdateKgRootMutation, UpdateKgRootMutationVariables>;
export const GetHouseholdPendingAidpkgRootDocument = gql`
    query GetHouseholdPendingAIDPKGRoot($householdId: ID!) {
  kgRoots(
    where: {hasHouseholdWith: {id: $householdId}, hasAsyncJobWith: [{hasParentWith: {kind: AI_DP_BATCHED, statusIn: [IN_PROGRESS]}}]}
  ) {
    edges {
      node {
        ...KGRootWithAsyncJob
      }
    }
  }
}
    ${KgRootWithAsyncJobFragmentDoc}`;

/**
 * __useGetHouseholdPendingAidpkgRootQuery__
 *
 * To run a query within a React component, call `useGetHouseholdPendingAidpkgRootQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdPendingAidpkgRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdPendingAidpkgRootQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useGetHouseholdPendingAidpkgRootQuery(baseOptions: Apollo.QueryHookOptions<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>(GetHouseholdPendingAidpkgRootDocument, options);
      }
export function useGetHouseholdPendingAidpkgRootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>(GetHouseholdPendingAidpkgRootDocument, options);
        }
export function useGetHouseholdPendingAidpkgRootSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>(GetHouseholdPendingAidpkgRootDocument, options);
        }
export type GetHouseholdPendingAidpkgRootQueryHookResult = ReturnType<typeof useGetHouseholdPendingAidpkgRootQuery>;
export type GetHouseholdPendingAidpkgRootLazyQueryHookResult = ReturnType<typeof useGetHouseholdPendingAidpkgRootLazyQuery>;
export type GetHouseholdPendingAidpkgRootSuspenseQueryHookResult = ReturnType<typeof useGetHouseholdPendingAidpkgRootSuspenseQuery>;
export type GetHouseholdPendingAidpkgRootQueryResult = Apollo.QueryResult<GetHouseholdPendingAidpkgRootQuery, GetHouseholdPendingAidpkgRootQueryVariables>;
export const Operations = {
  Query: {
    GetHouseholdPendingAIDPKGRoot: 'GetHouseholdPendingAIDPKGRoot' as const
  },
  Mutation: {
    UpdateKGRoot: 'UpdateKGRoot' as const
  }
}