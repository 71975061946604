import { Box, Stack } from '@mui/material';
import { compact, isEmpty } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';
import { useLocalStorage } from 'react-use';

import { SectionHeaderWithAccent } from '@/components/display/SectionHeaderWithAccent/SectionHeaderWithAccent';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Coachmark } from '@/components/poppers/Coachmark/Coachmark';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useModalState } from '@/hooks/useModalState';
import { EntityFormAIDPStatusBanner } from '@/modules/aiDP/entity/EntityFormAIDPStatusBanner';
import { EntityFormAIDPSuggestionsCard } from '@/modules/aiDP/entity/EntityFormAIDPSuggestionsCard';
import { DispositiveProvisionTemplateKind } from '@/types/schema';

import { HypotheticalWaterfallScenarioCallout } from '../components/HypotheticalWaterfallScenarioCallout';
import { useDispositiveProvisionsContext } from '../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../dispositiveProvisions.types';
import { DispositiveProvisionsTemplateSplitScreenModal } from '../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal';
import { NEW_TEMPLATE_SENTINEL } from '../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.types';
import { CopyDispositiveProvisionsModal } from './CopyDispositiveProvisionsModal/CopyDispositiveProvisionsModal';
import { CopyDispositionsResult } from './CopyDispositiveProvisionsModal/CopyDispositiveProvisionsModal.types';
import {
  CLIENT_OWNERSHIP_DESCRIPTION,
  DISPOSITIVE_PROVISIONS_FORM_NAMESPACE,
} from './DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from './DispositiveProvisionsForm.types';
import { getDeathOrderCopy } from './DispositiveProvisionsForm.utils';
import { DispositiveProvisionsSelectTemplate } from './DispositiveProvisionsFormRecipients/DispositiveProvisionsSelectTemplate';
import { useCopyForDispositionScheme } from './hooks/useCopyForDispositionScheme';
import { useHandleCopyDispositionsResult } from './hooks/useHandleCopyDispositionsResult';
import {
  useDispositionScheme,
  useIsImmutableDeathOrder,
} from './hooks/utilityHooks';

interface DispositiveProvisionsFormChromeProps {
  footer?: React.ReactNode;
  topRightContent?: React.ReactNode;
}

export function DispositiveProvisionsFormChrome({
  children,
  footer,
  topRightContent,
}: PropsWithChildren<DispositiveProvisionsFormChromeProps>) {
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [
    { isModalOpen: isTemplateModalOpen },
    { openModal: openTemplateModal, closeModal: closeTemplateModal },
  ] = useModalState();
  const { showFeedback } = useFeedback();
  const {
    entityDisplayName,
    isTwoClientHousehold,
    isClientProfile,
    isOnHypotheticalWaterfall,
    waterfall,
    formVariant,
  } = useDispositiveProvisionsContext();
  const isImmutableDeathOrder = useIsImmutableDeathOrder();
  const { setValue, control } =
    useFormContext<DispositiveProvisionsFormShape>();
  const currentDispositionScheme = useDispositionScheme();
  const dispositionSchemeDescription = useCopyForDispositionScheme();
  const handleCopyDispositionsResult = useHandleCopyDispositionsResult();
  const [coachmarkSeen, setCoachmarkSeen] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_COPY_DISPOSITIVE_PROVISIONS
  );

  const [currentRecipients, templateId] = useWatch({
    name: [
      'dispositiveProvisions.recipients',
      'dispositiveProvisions.templateId',
    ],
    control,
  });

  const onCloseEditTemplateModal = useCallback(
    (templateId?: string, recipients?: Recipient[]) => {
      if (!templateId || !recipients?.length) {
        closeTemplateModal();
        return;
      }

      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
        templateId
      );
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
        recipients
      );

      closeTemplateModal();
    },
    [closeTemplateModal, setValue]
  );
  const handleToggleDispositions = useCallback(() => {
    const newDispositionScheme =
      currentDispositionScheme === DispositionScheme.UPON_FIRST_DEATH
        ? DispositionScheme.UPON_SECOND_DEATH
        : DispositionScheme.UPON_FIRST_DEATH;

    setValue('dispositiveProvisions.dispositionScheme', newDispositionScheme);

    showFeedback(
      `Dispositions switched to occur upon ${getDeathOrderCopy(
        newDispositionScheme
      )}`,
      {
        variant: 'success',
        autoHideDuration: 1500,
      }
    );
  }, [currentDispositionScheme, setValue, showFeedback]);

  const handleModalClose = useCallback(
    (result: CopyDispositionsResult | null) => {
      setIsCopyModalOpen(false);
      handleCopyDispositionsResult(result);
    },
    [handleCopyDispositionsResult]
  );

  const contextMenuOptions = useMemo(() => {
    // don't show any options if the disposition scheme is none;
    // we have a different affordance for copying in that scenario
    if (currentDispositionScheme === DispositionScheme.NONE) {
      return [];
    }

    const toggleDispositionSchemeCopy =
      currentDispositionScheme === DispositionScheme.UPON_FIRST_DEATH
        ? 'Switch dispositions to occur upon 2nd death'
        : 'Switch dispositions to occur upon 1st death';

    const shouldShowSwitchDispositionsOption =
      isTwoClientHousehold && !isImmutableDeathOrder;
    const shouldShowConvertToTemplateOption = !templateId;
    return compact([
      <MenuItem
        key="copy-dispositions"
        label="Copy dispositions from elsewhere"
        onClick={() => setIsCopyModalOpen(true)}
        muiMenuItemProps={{
          divider:
            shouldShowConvertToTemplateOption &&
            !shouldShowSwitchDispositionsOption,
        }}
      />,
      shouldShowSwitchDispositionsOption && (
        <MenuItem
          key="switch-dispositions"
          label={toggleDispositionSchemeCopy}
          onClick={() => handleToggleDispositions()}
          muiMenuItemProps={{
            divider: shouldShowConvertToTemplateOption,
          }}
        />
      ),
      shouldShowConvertToTemplateOption && (
        <MenuItem
          key="convert-to-template"
          label="Convert dispositions into a template"
          onClick={openTemplateModal}
        />
      ),
    ]);
  }, [
    currentDispositionScheme,
    handleToggleDispositions,
    isImmutableDeathOrder,
    isTwoClientHousehold,
    openTemplateModal,
    templateId,
  ]);

  const targetDescriptor = isClientProfile
    ? CLIENT_OWNERSHIP_DESCRIPTION
    : entityDisplayName;

  // when we're accessing this through the "edit single entity" flow, we want the
  // entity name to be the primary heading to match the other sections of the edit form
  const headerConfig =
    formVariant === 'editEntity'
      ? {
          heading: targetDescriptor,
          subheading: dispositionSchemeDescription,
        }
      : {
          heading: dispositionSchemeDescription,
          subheading: targetDescriptor,
        };

  return (
    <>
      {/* the query inside this modal is ~expensive, so don't fire it until someone opens the modal */}
      {isCopyModalOpen && (
        <CopyDispositiveProvisionsModal
          isOpen={isCopyModalOpen}
          onClose={handleModalClose}
          hasRecipients={Boolean(currentRecipients?.length)}
        />
      )}
      {isTemplateModalOpen && (
        <DispositiveProvisionsTemplateSplitScreenModal
          templateDetails={{
            templateId: NEW_TEMPLATE_SENTINEL,
            templateType: DispositiveProvisionTemplateKind.Default,
            recipients: currentRecipients,
          }}
          isOpen={isTemplateModalOpen}
          onClose={onCloseEditTemplateModal}
        />
      )}
      <Stack sx={{ height: '100%', position: 'relative' }}>
        <Stack spacing={2} flexGrow={1} sx={{ overflowY: 'auto' }} p={3}>
          <SectionHeaderWithAccent
            header={headerConfig}
            rightHeaderContent={
              <Stack direction="row" alignItems="center" spacing={1}>
                {topRightContent}
                {!isEmpty(contextMenuOptions) && (
                  <Coachmark
                    id={
                      LOCAL_STORAGE_KEYS.COACHMARK_COPY_DISPOSITIVE_PROVISIONS
                    }
                    onClose={() => setCoachmarkSeen(true)}
                    title="Copy recipients from another entity"
                    body="Select the “Copy dispositions from elsewhere” option in this menu if you wish to re-use an existing list of recipients and the distributions specified for them."
                    buttonLabel="Got it"
                    isOpen={!coachmarkSeen}
                  >
                    <ContextMenuButton data-testid="DispositiveProvisions-menu">
                      {contextMenuOptions}
                    </ContextMenuButton>
                  </Coachmark>
                )}
              </Stack>
            }
          />
          <Box mb={2}>
            <DispositiveProvisionsSelectTemplate
              onSelect={() => setCoachmarkSeen(true)}
            />
            {isOnHypotheticalWaterfall && waterfall && (
              <HypotheticalWaterfallScenarioCallout
                waterfallName={waterfall.displayName}
              />
            )}
            <EntityFormAIDPStatusBanner />
            <EntityFormAIDPSuggestionsCard />
            {children}
          </Box>
        </Stack>
        {footer && <Box>{footer}</Box>}
      </Stack>
    </>
  );
}
