import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { find } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';

import { HypotheticalSaleLoanFormShape } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import { SaleLoanIllustration_EstateWaterfallFragment } from '../graphql/SaleLoanIllustration.generated';
import { PROJECTION_PSEUDO_ID } from '../SaleLoanIllustrationContent.utils';

interface GetProjectedGrowthParams {
  termLength: number | null;
  recipientId: string;
}

export interface GrowthRow {
  year: number;
  valueAtYearStart: Decimal;
  growth: Decimal;
  noteInterest: Decimal;
  notePrincipal: Decimal;
  valueAtYearEnd: Decimal;
}

function getProjectedGrowth(
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null,
  { termLength, recipientId }: GetProjectedGrowthParams
): GrowthRow[] {
  if (!waterfall || !termLength) {
    return [];
  }

  const recipientVizNode = find(
    waterfall.visualizationWithSaleLoanProjections.nodes,
    (n) => n.id === recipientId
  );
  if (!recipientVizNode) {
    diagnostics.error(
      'Recipient not found in waterfall',
      new Error('Recipient not found in waterfall')
    );
    return [];
  }

  const loan = find(
    waterfall.visualizationWithSaleLoanProjections.loans,
    (l) => l.pseudoID === PROJECTION_PSEUDO_ID
  );
  if (!loan) {
    diagnostics.error(
      'Projected loan not found in waterfall',
      new Error('Projected loan not found in waterfall')
    );
    return [];
  }

  return recipientVizNode.valueHistory.map((vh) => {
    const loanValueForYear = loan.valueHistory.find(
      (lvh) => lvh.year === vh.year
    );

    return {
      year: vh.year,
      valueAtYearStart: vh.beginningOfYearValue ?? new Decimal(0),
      growth: vh.assetGrowth ?? new Decimal(0),
      valueAtYearEnd: vh.endOfYearValue ?? new Decimal(0),

      // the entity projections will extend out until the second death, whereas
      // the loan projections will only extend out until the term length, so we
      // expect loanValueForYear to be undefined for all years after the term
      // length
      noteInterest:
        loanValueForYear?.interestPaid?.times(new Decimal(-1)) ??
        new Decimal(0),
      notePrincipal:
        loanValueForYear?.principalPaid?.times(new Decimal(-1)) ??
        new Decimal(0),
    };
  });
}

export interface SaleLoanIllustrationGrowthScheduleProps {
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null;
}

export function SaleLoanIllustrationGrowthSchedule({
  waterfall,
}: SaleLoanIllustrationGrowthScheduleProps) {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const termLength = useWatch({
    control,
    name: 'termLength',
  });
  const recipientId = useWatch({
    control,
    name: 'recipientId',
  });

  const growthProjections = useMemo(
    () =>
      getProjectedGrowth(waterfall, {
        termLength,
        recipientId,
      }),
    [termLength, waterfall, recipientId]
  );

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Projected growth modeled below takes into account all details from the{' '}
        <strong>{waterfall?.displayName ?? 'modeled'}</strong> waterfall
        including death events, assumptions, cash flows and transfers.
      </Typography>
      <DisplayTable
        columns={[
          { headerName: 'Year', width: '10%' },
          {
            headerName: 'Assets at year start',
            width: '18%',
            align: 'right',
          },
          { headerName: 'Returns', width: '18%', align: 'right' },
          { headerName: 'Note interest', width: '18%', align: 'right' },
          { headerName: 'Note principal', width: '18%', align: 'right' },
          {
            headerName: 'Assets at year end',
            width: '18%',
            align: 'right',
          },
        ]}
      >
        {growthProjections.map((row) => (
          <StyledTableRow key={row.year}>
            <StyledTableCell>
              <Typography>{row.year}</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography>{formatCurrency(row.valueAtYearStart)}</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography color={row.growth.lt(0) ? 'error.main' : undefined}>
                {formatCurrency(row.growth, {
                  currencySign: 'accounting',
                })}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography
                color={row.noteInterest.lt(0) ? 'error.main' : undefined}
              >
                {formatCurrency(row.noteInterest, {
                  currencySign: 'accounting',
                })}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography
                color={row.notePrincipal.lt(0) ? 'error.main' : undefined}
              >
                {formatCurrency(row.notePrincipal, {
                  currencySign: 'accounting',
                })}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography>{formatCurrency(row.valueAtYearEnd)}</Typography>
            </StyledTableCell>
          </StyledTableRow>
        ))}
        {growthProjections.length === 0 && (
          <StyledTableRow>
            <StyledTableCell colSpan={5}>
              <Typography>
                Growth projections will generate when all required fields are
                specified
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        )}
      </DisplayTable>
    </Stack>
  );
}
