import { noop } from 'lodash';
import { createContext, Dispatch } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';

import { EntityDiagram_EntityFragment } from '../graphql/EntityDiagram.generated';
import { EntityDiagramGraph } from '../types';
import { EntityDiagramAction } from '../types/actions';
import { EntityDiagramDerivedData, EntityDiagramState } from '../types/state';

export interface EntityDiagramContext {
  dispatch: Dispatch<EntityDiagramAction>;
  state: EntityDiagramState;
  getState: () => EntityDiagramState;
}

const defaultDerivedData: EntityDiagramDerivedData = {
  nodesById: {},
  nodesByType: { default: [], sectionLabel: [], tile: [] },
  nodesBySectionId: {},
  edgesById: {},
};

const defaultState: EntityDiagramState = {
  isInitializing: true,
  selectedPrimaryClientId: '',
  primaryClients: null as unknown as ContextPrimaryClient[],
  entity: null as unknown as EntityDiagram_EntityFragment,
  entityDiagramGraph: null as unknown as EntityDiagramGraph,
  isTwoClientHousehold: false,
  nodes: [],
  edges: [],
  derivedData: { ...defaultDerivedData },
  highlightedIds: new Set(),
  isExporting: false,
  presentationMode: false,
};

export const DEFAULT_ENTITY_DIAGRAM_STATE: Readonly<EntityDiagramState> =
  Object.freeze(defaultState);

export const EntityDiagramContext = createContext<EntityDiagramContext>({
  dispatch: noop,
  state: { ...DEFAULT_ENTITY_DIAGRAM_STATE },
  getState: () => ({ ...DEFAULT_ENTITY_DIAGRAM_STATE }),
});

export const useEntityDiagramContext = () => {
  return useGuardedContext(EntityDiagramContext, 'EntityDiagramProvider');
};
