import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { AIOnboardingReadyForReviewBanner } from '@/modules/aiOnboarding/components/AIOnboardingReadyForReviewBanner';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { getNodes } from '@/utils/graphqlUtils';

import { mapDataToTableRows } from './ClientPeopleListView.utils';
import { ClientDetailsPrimaryClients } from './ClientPeopleListViewGrantors';
import { ClientDetailsPeopleOrganizationsTable } from './ClientPeopleListViewTable';
import { useClientPeopleListViewTableQuery } from './graphql/ClientPeopleListViewTable.generated';

export interface ClientPeopleListViewProps {
  householdId: string;
}
function ClientPeopleListViewInner({ householdId }: ClientPeopleListViewProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [isPrimaryClient, setPrimaryClient] = useState<boolean>(false);
  const { data, loading } = useClientPeopleListViewTableQuery({
    variables: { householdId },
    onError: (error: ApolloError) => {
      showFeedback(
        'Could not fetch client details. Please refresh the page to try again.'
      );
      reportError('Caught error when loading household info', error);
    },
  });
  const primaryClients = getNodes(data?.primaryClients);
  const integrationClientIds = useMemo(() => {
    return (
      getNodes(data?.households)[0]?.integrationClients?.map((c) => c.id) ?? []
    );
  }, [data?.households]);
  const listContent = mapDataToTableRows(data);
  const [
    clientProfileModalState,
    {
      openModal: openClientProfileModal,
      setModalIsOpen: setClientProfileModalIsOpen,
    },
  ] = useModalState<string | null>();
  const [
    clientOrganizationModalState,
    {
      openModal: openClientOrganizationModal,
      setModalIsOpen: setClientOrganizationModalOpen,
    },
  ] = useModalState<string | null>();
  return (
    <>
      <Stack direction="column" width="100%" spacing={3}>
        <AIOnboardingReadyForReviewBanner
          householdID={householdId}
          variant="people_and_orgs"
        />
        <ClientDetailsPrimaryClients
          primaryClients={primaryClients}
          loading={loading}
          householdId={householdId}
          integrationClientIds={integrationClientIds}
          onClick={(clientProfileId: string | null) => {
            setPrimaryClient(true);
            openClientProfileModal(clientProfileId);
          }}
        />
        <ClientDetailsPeopleOrganizationsTable
          listContent={listContent}
          openClientProfileModal={(clientProfileId: string | null) => {
            const isEditingPrimary = primaryClients.some(
              (c) => c.id === clientProfileId
            );
            setPrimaryClient(isEditingPrimary);
            openClientProfileModal(clientProfileId);
          }}
          openClientOrganizationModal={openClientOrganizationModal}
        />
      </Stack>
      <ClientProfileModal
        isOpen={clientProfileModalState.isModalOpen}
        setIsOpen={setClientProfileModalIsOpen}
        householdId={householdId}
        clientProfileId={clientProfileModalState.data}
        clientProfileTypeDisplay={isPrimaryClient ? 'client' : 'individual'}
        isPrimary={isPrimaryClient}
        forceGrantorIfNoClientProfileId={isPrimaryClient} // default grantor to true when adding a new primary client
        allowDelete={isPrimaryClient ? primaryClients.length > 1 : true}
      />
      <ClientOrganizationModal
        isOpen={clientOrganizationModalState.isModalOpen}
        setIsOpen={setClientOrganizationModalOpen}
        organizationId={clientOrganizationModalState.data}
        householdId={householdId}
      />
    </>
  );
}

export function ClientPeopleListView() {
  const householdId = useRequiredParam('householdId');
  return <ClientPeopleListViewInner householdId={householdId} />;
}
