import { UnreachableError } from '@/utils/errors';

import { EntityType } from '../types/EntityType';

export type EntitySubtype =
  | 'GRATTrust'
  | 'SLATTrust'
  | 'ILITTrust'
  | 'IrrevocableTrust'
  | 'RevocableTrust'
  | 'QPRTTrust'
  | 'IndividualPersonalAccount'
  | 'JointPersonalAccount'
  | 'RetirementPersonalAccount'
  | 'QualifiedTuitionPersonalAccount'
  | 'CustodialPersonalAccount'
  | 'CLTTrust'
  | 'CRTTrust'
  | 'DonorAdvisedFund'
  | 'PrivateFoundation'
  | 'SoleProprietorshipBusinessEntity'
  | 'CCorpBusinessEntity'
  | 'SCorpBusinessEntity'
  | 'LLCBusinessEntity'
  | 'LPBusinessEntity'
  | 'GPBusinessEntity'
  | 'InsurancePersonalAccount';

export function getEntityTypeFromSubtype(
  entitySubtype: EntitySubtype
): EntityType {
  switch (entitySubtype) {
    case 'GRATTrust':
      return 'grat';
    case 'SLATTrust':
      return 'slat';
    case 'QPRTTrust':
      return 'qprt';
    case 'ILITTrust':
      return 'ilit';
    case 'IrrevocableTrust':
      return 'irrevocable-trust';
    case 'RevocableTrust':
      return 'revocable-trust';
    case 'IndividualPersonalAccount':
      return 'individual-account';
    case 'JointPersonalAccount':
      return 'joint-account';
    case 'RetirementPersonalAccount':
      return 'retirement-account';
    case 'QualifiedTuitionPersonalAccount':
      return 'qualified-tuition-account';
    case 'CustodialPersonalAccount':
      return 'custodial-account';
    case 'CLTTrust':
      return 'clt';
    case 'CRTTrust':
      return 'crt';
    case 'DonorAdvisedFund':
      return 'daf';
    case 'PrivateFoundation':
      return 'private-foundation';
    case 'SoleProprietorshipBusinessEntity':
      return 'sole-proprietorship';
    case 'CCorpBusinessEntity':
      return 'c-corp';
    case 'SCorpBusinessEntity':
      return 's-corp';
    case 'LLCBusinessEntity':
      return 'llc';
    case 'LPBusinessEntity':
      return 'lp';
    case 'GPBusinessEntity':
      return 'gp';
    case 'InsurancePersonalAccount':
      return 'insurance-account';
    default:
      throw new UnreachableError({
        case: entitySubtype,
        message: 'Unexpected entity type',
      });
  }
}
