import { useReactFlow } from '@xyflow/react';
import { orderBy } from 'lodash';
import { useCallback } from 'react';

import { FlowChartProps, Node } from '@/components/diagrams/FlowChart';
import { useHelperLines } from '@/components/diagrams/FlowChart/components/HelperLines';
import { useIsInteractive } from '@/components/diagrams/FlowChart/hooks/useIsInteractive';
import { useSectionNodesBounds } from '@/components/diagrams/FlowChart/nodes/SectionLabelNode/hooks';

import { useEntityDiagramContext } from '../contexts/entityDiagram.context';

export function useFlowChartProps() {
  const { getNodes } = useReactFlow();
  const { isInteractive } = useIsInteractive();
  const { state, dispatch, getState } = useEntityDiagramContext();
  const { helperLineVertical, helperLineHorizontal, applyHelperLinesToNodes } =
    useHelperLines();
  const { applySectionBoundsToNodes } = useSectionNodesBounds();

  const onNodeMouseEnter = useCallback<
    NonNullable<FlowChartProps['onNodeMouseEnter']>
  >(
    (event, node) => {
      // No relevant code paths when non tile node (section group), or in edit mode
      if (node.type !== 'tile' || isInteractive) return;
      dispatch({ type: 'NODE_MOUSE_ENTER', event, node });
    },
    [dispatch, isInteractive]
  );

  const onNodeMouseLeave = useCallback<
    NonNullable<FlowChartProps['onNodeMouseLeave']>
  >(
    (event, node) => {
      // No relevant code paths when non tile node (section group), or in edit mode
      if (node.type !== 'tile' || isInteractive) return;
      dispatch({ type: 'NODE_MOUSE_LEAVE', event, node });
    },
    [dispatch, isInteractive]
  );

  const onNodesChange = useCallback<
    NonNullable<FlowChartProps['onNodesChange']>
  >(
    (changes) => {
      const nodes = applyHelperLinesToNodes(
        changes,
        applySectionBoundsToNodes(changes, getNodes() as Node[])
      );

      dispatch({ type: 'NODES_CHANGED', nodes, changes });
    },
    [applyHelperLinesToNodes, applySectionBoundsToNodes, dispatch, getNodes]
  );

  const onEdgeMouseEnter = useCallback<
    NonNullable<FlowChartProps['onEdgeMouseEnter']>
  >(
    (event, edge) => {
      // No relevant code paths in edit mode
      if (isInteractive) return;

      /**
       * Note: for some reason, edge mouse enter events can happen in rapid succession.
       * This doesn't occur for nodes, maybe because the edge hover area is so small?
       * Either way, we guard against it here as a stop gap from many events firing.
       */
      if (getState().highlightedIds.has(edge.id)) return;

      dispatch({ type: 'EDGE_MOUSE_ENTER', event, edge });
    },
    [dispatch, getState, isInteractive]
  );

  const onEdgeMouseLeave = useCallback<
    NonNullable<FlowChartProps['onEdgeMouseLeave']>
  >(
    (event, edge) => {
      // No relevant code paths in edit mode
      if (isInteractive) return;
      dispatch({ type: 'EDGE_MOUSE_LEAVE', event, edge });
    },
    [dispatch, isInteractive]
  );

  // Draw the highlighted edges on top of the others
  const orderedEdges = orderBy(state.edges, (edge) => edge.data?.highlight);

  const flowChartProps: FlowChartProps = {
    style: state.isInitializing ? { opacity: 0 } : undefined,
    nodes: state.nodes,
    edges: orderedEdges,
    onNodesChange,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onEdgeMouseEnter,
    onEdgeMouseLeave,
  };

  return { flowChartProps, helperLineHorizontal, helperLineVertical };
}
