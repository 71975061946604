import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { ROUTE_KEYS } from '@/navigation/constants';

import { CreatePresentationButton } from '../components/CreatePresentationButton';
import { getColumns, RowData } from './ClientPresentationsTable.constants';
import { useClientPresentationsTableRows } from './ClientPresentationsTable.hooks';

export interface ClientPresentationsTableProps {
  householdId: string;
}

function ClientPresentationTableEmptyState({
  householdId,
}: ClientPresentationsTableProps) {
  return (
    <TableEmptyState text="No reports">
      <Box>
        <CreatePresentationButton householdId={householdId} />
      </Box>
    </TableEmptyState>
  );
}

export function ClientPresentationsTable({
  householdId,
}: ClientPresentationsTableProps) {
  const { navigate } = useNavigateToRoute();
  const rows = useClientPresentationsTableRows(householdId);

  return (
    <DataTable
      rows={rows}
      columns={getColumns()}
      slots={{
        noRowsOverlay: () => (
          <ClientPresentationTableEmptyState householdId={householdId} />
        ),
      }}
      onRowClick={({ row }: GridRowParams<RowData>) =>
        row.isV2Presentation
          ? navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION_V2, {
              householdId,
              presentationId: row.id,
            })
          : navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION, {
              householdId,
              presentationId: row.id,
            })
      }
    />
  );
}
