import { Stack } from '@mui/material';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

export function ClientPresentationsPage() {
  const householdId = useRequiredParam('householdId');
  const isClientPresentationV2Enabled = useFeatureFlag(
    'client_presentation_v2'
  );

  const tabs: LinkTabProps[] = useMemo(() => {
    if (isClientPresentationV2Enabled) {
      // display reports first if the updated presentation flag is on
      return compact([
        {
          display: 'Reports',
          dataTestId: 'tab-HOUSEHOLD_DETAILS_PRESENTATIONS_V2',
          to: getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS,
            {
              householdId,
            }
          ),
        },
        {
          display: 'Proposals',
          dataTestId: 'tab-HOUSEHOLD_DETAILS_PROPOSALS_V2',
          to: getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
            {
              householdId,
            }
          ),
        },
      ]);
    }
    return compact([
      {
        display: 'Proposals',
        dataTestId: 'tab-HOUSEHOLD_DETAILS_PROPOSALS_V2',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Reports',
        dataTestId: 'tab-HOUSEHOLD_DETAILS_PRESENTATIONS_V2',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS,
          {
            householdId,
          }
        ),
      },
    ]);
  }, [householdId, isClientPresentationV2Enabled]);

  return (
    <Stack>
      <PageHeader heading="Presentations" tabs={tabs} />
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
