import { DialogModal } from '@/components/modals/DialogModal/DialogModal';

interface UploadSingleDocumentModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function UploadSingleDocumentModal({
  isOpen,
  handleClose,
}: UploadSingleDocumentModalProps) {
  // TODO: Implement: https://linear.app/luminary/issue/T2-3985/[frontend]-single-document-upload-modal
  return (
    <DialogModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Upload document"
    ></DialogModal>
  );
}
