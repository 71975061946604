import {
  AISuggestionsMatcherAddNewAnythingModalProps,
  AISuggestionsMatcherArrayField,
  AISuggestionsMatcherSingleField,
  AISuggestionsMatcherVariant,
  AISuggestionsMatcherVariantConfig,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { suggestionsToGrantorsSubform } from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/basicInformation';
import { suggestionsToBeneficiariesV2Subform } from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/beneficiaries';
import {
  suggestionsToBeneficialOwnersSubform,
  suggestionsToKeyPeopleSubform,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/business';
import { suggestionsToDPRecipientsSubform } from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/dispositiveProvisions';
import {
  suggestionsToSuccessorTrusteesSubform,
  suggestionsToTrustAdvisorsSubform,
  suggestionsToTrusteesSubform,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/trustDetails';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { EntityDocumentViewerDocument } from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  BENEFICIARIES_SUBFORM,
  LIFETIME_BENEFICIARIES_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { TRUST_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import { AiSuggestionKind } from '@/types/schema';

export function getConfigsForAISuggestionsMatcherVariant(
  entityType: EntityType | null | undefined,
  variant: AISuggestionsMatcherVariant
): AISuggestionsMatcherVariantConfig {
  const commonGrantorConfig: AISuggestionsMatcherVariantConfig = {
    nounSingular: 'grantor',
    nounPlural: 'grantors',
    suggestionKinds: [AiSuggestionKind.Principal],
    addNewAnythingModalProps: {
      [AiSuggestionKind.Principal]: {
        addableTypes: ['individual'],
        addClientProfileModalProps: {
          forceBeneficiaryIfNoClientProfileId: true,
          forceTrusteeIfNoClientProfileId: true,
          forceGrantorIfNoClientProfileId: true,
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        },
        addClientOrganizationModalProps: {},
        addEntityModalProps: {},
      },
    },
    subformArrayFieldUpdateProps: [],
    subformArrayFieldOnConflict: 'overwrite',
  };

  switch (variant) {
    case AISuggestionsMatcherVariant.GRANTORS:
      return {
        ...commonGrantorConfig,
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantors` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToGrantorsSubform,
            idField: 'clientProfileId',
          },
        ],
      };
    case AISuggestionsMatcherVariant.SINGLE_GRANTOR:
      return {
        ...commonGrantorConfig,
        calloutText:
          entityType === 'slat'
            ? 'Only one grantor can be specified for a SLAT.'
            : undefined,
        subformSingleFieldUpdateProps: {
          singleFieldFullPath:
            `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantor` as const satisfies AISuggestionsMatcherSingleField,
          mapperFunc: (s) => suggestionsToGrantorsSubform(s)?.[0] || '',
          idField: 'clientProfileId',
        },
      };
    case AISuggestionsMatcherVariant.TRUSTEES:
      return {
        nounSingular: 'trustee',
        nounPlural: 'trustees',
        suggestionKinds: [
          AiSuggestionKind.Trustee,
          AiSuggestionKind.TrustAdvisor,
        ],
        addNewAnythingModalProps: {
          [AiSuggestionKind.Trustee]: {
            addableTypes: ['individual', 'organization'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: true,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeTrustee',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {},
          },
          [AiSuggestionKind.TrustAdvisor]: {
            addableTypes: ['individual', 'organization'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: true,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeTrustee',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {},
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustees` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToTrusteesSubform,
            idField: 'trusteeId',
          },
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToSuccessorTrusteesSubform,
            idField: 'successorTrusteeId',
          },
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToTrustAdvisorsSubform,
            idField: 'trustAdvisorId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    case AISuggestionsMatcherVariant.BENEFICIARIES_V2:
      return {
        nounSingular: 'beneficiary',
        nounPlural: 'beneficiaries',
        suggestionKinds: [AiSuggestionKind.BeneficiaryV2],
        addNewAnythingModalProps: {
          [AiSuggestionKind.BeneficiaryV2]: {
            addableTypes: ['individual', 'organization', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: true,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeBeneficiary',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              getBeneficiarySubformArrayFieldFullPath(entityType),
            mapperFunc: suggestionsToBeneficiariesV2Subform,
            idField: 'beneficiaryId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    case AISuggestionsMatcherVariant.BUSINESS_PEOPLE:
      return {
        nounSingular: 'beneficial owner and key person',
        nounPlural: 'beneficial owners and key people',
        suggestionKinds: [
          AiSuggestionKind.BeneficialOwner,
          AiSuggestionKind.KeyPerson,
        ],
        addNewAnythingModalProps: {
          [AiSuggestionKind.BeneficialOwner]: {
            addableTypes: ['individual', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {},
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
          [AiSuggestionKind.KeyPerson]: {
            addableTypes: ['individual', 'organization', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToBeneficialOwnersSubform,
            idField: 'ownerId',
          },
          {
            arrayFieldFullPath:
              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToKeyPeopleSubform,
            idField: 'keyPersonId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    case AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_1_DIES_FIRST: {
      return getDPVariantConfigs(
        AiSuggestionKind.DpRecipientGrantor_1DiesFirst
      );
    }
    case AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_2_DIES_FIRST: {
      return getDPVariantConfigs(
        AiSuggestionKind.DpRecipientGrantor_2DiesFirst
      );
    }
    default:
      throw new Error(`Unknown matcher configs for variant: ${variant}`);
  }
}

function getBeneficiarySubformArrayFieldFullPath(
  entityType: EntityType | null | undefined
): AISuggestionsMatcherArrayField {
  if (!entityType) {
    throw new Error('Entity type is required');
  }

  switch (entityType) {
    case 'slat':
      return `${LIFETIME_BENEFICIARIES_SUBFORM}.lifetimeBeneficiaries` as const satisfies AISuggestionsMatcherArrayField;
    default:
      // Add more cases as we support more entity types that have custom beneficiary subform fields
      return `${BENEFICIARIES_SUBFORM}.beneficiaries` as const satisfies AISuggestionsMatcherArrayField;
  }
}

function getDPVariantConfigs(
  kind:
    | AiSuggestionKind.DpRecipientGrantor_1DiesFirst
    | AiSuggestionKind.DpRecipientGrantor_2DiesFirst
): AISuggestionsMatcherVariantConfig {
  const addNewAnythingModalProps: AISuggestionsMatcherAddNewAnythingModalProps =
    {
      addableTypes: [
        'individual',
        'organization',
        'entity',
        'testamentaryEntity',
      ],
      addClientProfileModalProps: {
        forceBeneficiaryIfNoClientProfileId: true,
        forceTrusteeIfNoClientProfileId: false,
        forceGrantorIfNoClientProfileId: false,
        ignoredQueryDocuments: [EntityDocumentViewerDocument],
      },
      addClientOrganizationModalProps: {
        requirement: 'mustBeBeneficiary',
        ignoredQueryDocuments: [EntityDocumentViewerDocument],
      },
      addEntityModalProps: {
        ignoredQueryDocuments: [EntityDocumentViewerDocument],
      },
      addTestamentaryEntityModalProps: {
        ignoredQueryDocuments: [EntityDocumentViewerDocument],
        deadClientIds: [],
        dyingPrimaryClientId: null,
      },
    };

  return {
    nounSingular: 'recipient',
    nounPlural: 'recipients',
    suggestionKinds: [kind],
    addNewAnythingModalProps: {
      [kind]: addNewAnythingModalProps,
    },
    subformArrayFieldUpdateProps: [
      {
        arrayFieldFullPath:
          `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` as const satisfies AISuggestionsMatcherArrayField,
        mapperFunc: suggestionsToDPRecipientsSubform,
        idField: 'recipientId',
      },
    ],
    subformArrayFieldOnConflict: 'append',
    showCommitButtonOnEmptySuggestions: true,
  };
}
