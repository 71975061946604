import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { TrustAdvisorsList_TrustAdvisorFragment } from '@/modules/trusts/TrustAdvisorsList/graphql/TrustAdvisorsList.fragments.generated';
import { getGroupedTrustAdvisorRows } from '@/modules/trusts/TrustAdvisorsList/TrustAdvisorsList.utils';

import { EntityDetailNameCard } from './EntityDetailNameCard';

interface TrustAdvisorsProps {
  trustAdvisors: TrustAdvisorsList_TrustAdvisorFragment[];
  maxItems?: number;
}

export function TrustAdvisors({ trustAdvisors, maxItems }: TrustAdvisorsProps) {
  const trustAdvisorRows = useMemo(
    () =>
      getGroupedTrustAdvisorRows(trustAdvisors, {
        format: 'short',
      }),
    [trustAdvisors]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Trust advisors
      </Typography>
      {trustAdvisorRows.length === 0 && (
        <Typography variant="subtitle2" component="span">
          None specified
        </Typography>
      )}
      {trustAdvisorRows.slice(0, maxItems).map((row) => (
        <EntityDetailNameCard
          key={row.heading}
          leftText={row.heading}
          rightText={
            row.items.length > 0
              ? row.items.map((item) => item.heading).join(', ')
              : 'None'
          }
        />
      ))}
    </Stack>
  );
}
