import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

import {
  getNewBundleId,
  getNewPageId,
} from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2Bundle } from '../../types/ClientPresentationV2.PresentationBundleType';

export function getEmptyBundleForType(
  type: PresentationBundleKind
): ClientPresentationV2Bundle | null {
  const id = getNewBundleId(type);
  switch (type) {
    case PresentationBundleKind.CustomPageBundle:
      return {
        id,
        displayName: '',
        type: PresentationBundleKind.CustomPageBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.CustomPage),
            type: PresentationPageKind.CustomPage,
          },
        ],
      };
    case PresentationBundleKind.TableOfContentsBundle:
      return {
        id,
        displayName: '',
        type: PresentationBundleKind.TableOfContentsBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.TableOfContentsPage),
            type: PresentationPageKind.TableOfContentsPage,
          },
        ],
      };
    default:
      return null;
  }
}
