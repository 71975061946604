import { compact, first } from 'lodash';
import { useCallback } from 'react';

import { useReportError } from '@/hooks/useReportError';
import { useGetMostRecentEntityAiSuggestionJobsQuery } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { AsyncJobKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

/**
 * For the current entity, fetches the most recent pending and active
 * AI Suggestions async jobs of types:
 * - Entity Details (aka "document inference") Suggestions
 * - Dispositive Provisions Suggestions
 *
 * Sets the jobs on the entity details context for status tracking/polling.
 */
export function useMostRecentEntityAISuggestionsAsyncJobs(kind: AsyncJobKind) {
  const { reportError } = useReportError();

  const {
    entityId,
    setActivePendingAISuggestionAsyncJobs,
    setPreviouslyCompletedAISuggestionAsyncJobs,
  } = useEntityDetailsContext();

  const { refetch: queryRefetch, ...rest } =
    useGetMostRecentEntityAiSuggestionJobsQuery({
      skip: !entityId,
      fetchPolicy: 'network-only',
      // notifyOnNetworkStatusChange is required to rerun onComplete for each poll
      notifyOnNetworkStatusChange: true,
      variables: {
        entityId: entityId!,
        asyncJobKind: kind,
      },
      onCompleted: (data) => {
        const entity = first(getNodes(data.entities));
        setActivePendingAISuggestionAsyncJobs(
          compact([first(getNodes(entity?.activeJobs))])
        );
        setPreviouslyCompletedAISuggestionAsyncJobs(
          compact([first(getNodes(entity?.completedJobs))])
        );
      },
      onError: (error) => {
        reportError(
          `Error fetching AI suggestions job for entityID=${entityId}`,
          error
        );
      },
    });

  /**
   * This wraps the query refetch to ensure that we only call it if the entityId
   * is set.
   */
  const refetch = useCallback(() => {
    if (!entityId) return;
    return queryRefetch();
  }, [entityId, queryRefetch]);

  return {
    refetch,
    ...rest,
  };
}
