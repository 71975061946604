import { useCallback } from 'react';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { SubPanelButtonType } from './EstateWaterfallSubpanelButtons';

export function useGetHandleEstateWaterfallSubpanelButtonsClick(): (
  type: SubPanelButtonType
) => void {
  const {
    summaryPanel: { openModal, closeModal, isModalOpen, data },
  } = useWaterfallSummaryContext();
  return useCallback(
    (type: SubPanelButtonType) => {
      if (data?.type === type && isModalOpen) {
        closeModal();
      } else {
        openModal({ type });
      }
    },
    [closeModal, data?.type, isModalOpen, openModal]
  );
}
