import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { first, orderBy, uniq } from 'lodash';
import { useMemo } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { getDistributionDetails } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsRow';
import { PrimaryClientDropdown_PossibleGrantorFragment } from '@/modules/entities/inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import { getNodeId } from '@/modules/estateWaterfall/waterfallGraph/utils';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import {
  AccessParameterFrequency,
  AccessParameterKind,
  AfterDeath,
  EntityGstStatus,
  EntityKind,
  EstateWaterfallEdgeKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
  LoggedTransferWhereInput,
  ScheduledDistributionFrequency,
  ScheduledDistributionKind,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  BeneficiariesData,
  BeneficiaryReport,
  BeneficiaryReportBenefitKind,
  BeneficiaryReportDirectGiftKind,
  BeneficiaryReportingRowVariant,
  BeneficiaryReportScheduledDistributionKind,
} from '../beneficiaryReporting.types';
import {
  BeneficiaryReporting_AccessParameterFragment,
  BeneficiaryReporting_BeneficiaryFragment,
  BeneficiaryReporting_EstateWaterfallFragment,
  BeneficiaryReporting_EstateWaterfallVizFragment,
  BeneficiaryReporting_LoggedTransferFragment,
  BeneficiaryReporting_ScheduledDistributionFragment,
  useBeneficiaryReportingQuery,
} from '../graphql/BeneficiaryReporting.generated';

export const getDefaultBeneficiaryReport = (): BeneficiaryReport => ({
  scheduledDistributions: [],
  directGifts: [],
  fullAccess: [],
  partialAccess: [],
});

interface WaterfallEdgeData {
  kind:
    | BeneficiaryReportScheduledDistributionKind
    | BeneficiaryReportDirectGiftKind;
  toVizNodeId: string;
  value: Decimal;
  toId: string;
  fromId: string;
  fromEntityKind:
    | 'Entity'
    | 'ClientProfile'
    | 'ClientOrganization'
    | 'TestamentaryEntity';
  fromVizNodeId: string;
  fromEntityName: string;
  fromEntityValue: Decimal;
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind | null;
  fromEntityGstStatus: EntityGstStatus | null;
  associatedHypotheticalTransfer?: {
    id: string;
    transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind;
  };
  dispositionParameters: string | null;
}

function getWaterfallEdgeData(
  e: BeneficiaryReporting_EstateWaterfallVizFragment['edges'][number],
  kind:
    | BeneficiaryReportScheduledDistributionKind
    | BeneficiaryReportDirectGiftKind
): WaterfallEdgeData | null {
  const associatedTransfer = first(e.associatedTransfers);

  const associatedTransferTransferTaxKind =
    associatedTransfer?.transfer.transferTaxKind ?? undefined;

  const associatedHypotheticalTransfer = (() => {
    if (
      associatedTransfer?.transfer.__typename ===
      'EstateWaterfallHypotheticalTransfer'
    ) {
      return associatedTransfer.transfer;
    }
    return undefined;
  })();

  const toId = (() => {
    if (e.to.node.__typename === 'ClientProfile') {
      return e.to.node.id;
    }
    if (e.to.node.__typename === 'ClientOrganization') {
      return e.to.node.id;
    }
    return '';
  })();

  const fromId = (() => {
    if (e.from.node.__typename === 'Entity') {
      return e.from.node.id;
    }
    if (e.from.node.__typename === 'ClientProfile') {
      return e.from.node.id;
    }
    if (e.from.node.__typename === 'ClientOrganization') {
      return e.from.node.id;
    }
    if (e.from.node.__typename === 'TestamentaryEntity') {
      return e.from.node.id;
    }
    return '';
  })();

  const fromEntityName = (() => {
    if (e.from.node.__typename === 'Entity') {
      return e.from.node.subtype.displayName;
    }
    if (e.from.node.__typename === 'ClientProfile') {
      return e.from.node.displayName;
    }
    if (e.from.node.__typename === 'ClientOrganization') {
      return e.from.node.name;
    }
    if (e.from.node.__typename === 'TestamentaryEntity') {
      return e.from.node.displayName;
    }
    return '';
  })();

  const fromEntityGstStatus = (() => {
    if (e.from.node.__typename === 'Entity') {
      if ('gstStatus' in e.from.node.subtype) {
        return e.from.node.subtype.gstStatus ?? null;
      }
    }

    return null;
  })();

  if (fromId === toId) {
    // Ignore disposition edges from the same underlying entity
    return null;
  }

  const fromEntityValue = (() => {
    if (
      kind === BeneficiaryReportScheduledDistributionKind.Disposition &&
      e.from.valueBeforeDispositions
    ) {
      return e.from.valueBeforeDispositions;
    }
    return e.from.value;
  })();

  const dispositionParameters = (() => {
    return (
      e.associatedDispositiveProvisions
        ?.flatMap((ap) =>
          ap
            ? getDistributionDetails(ap.dispositiveProvision, null, {
                compact: true,
              })
                .flatMap((d) => {
                  if (!d.heading) {
                    return [];
                  }

                  if (d.heading.includes('%') || d.heading.includes('$')) {
                    return `${d.heading} disposition`;
                  }
                  return d.heading;
                })
                .join(', ')
            : []
        )
        .join(', ') ?? null
    );
  })();

  return {
    kind,
    toVizNodeId: getNodeId({
      id: e.to.id,
      afterDeath: e.to.afterDeath,
    }),
    value: e.value,
    toId,
    fromId,
    fromEntityKind: e.from.node.__typename as
      | 'Entity'
      | 'ClientProfile'
      | 'ClientOrganization'
      | 'TestamentaryEntity',
    fromVizNodeId: getNodeId({
      id: e.from.id,
      afterDeath: e.from.afterDeath,
    }),
    fromEntityName,
    fromEntityValue,
    transferTaxKind: associatedTransferTransferTaxKind ?? null,
    fromEntityGstStatus,
    associatedHypotheticalTransfer,
    dispositionParameters,
  };
}

function mergeBeneficiaryReports(
  beneficiariesData: BeneficiariesData,
  afterDeath: AfterDeath,
  beneficiaryId: string,
  beneficiaryReport: BeneficiaryReport
): void {
  beneficiariesData[afterDeath][beneficiaryId] = {
    scheduledDistributions: [
      ...(beneficiariesData[afterDeath][beneficiaryId]
        ?.scheduledDistributions ?? []),
      ...beneficiaryReport.scheduledDistributions,
    ],
    directGifts: [
      ...(beneficiariesData[afterDeath][beneficiaryId]?.directGifts ?? []),
      ...beneficiaryReport.directGifts,
    ],
    fullAccess: [
      ...(beneficiariesData[afterDeath][beneficiaryId]?.fullAccess ?? []),
      ...beneficiaryReport.fullAccess,
    ],
    partialAccess: [
      ...(beneficiariesData[afterDeath][beneficiaryId]?.partialAccess ?? []),
      ...beneficiaryReport.partialAccess,
    ],
  };
}

interface ProcessLoggedTransferInput {
  t: BeneficiaryReporting_LoggedTransferFragment;
  waterfall: BeneficiaryReporting_EstateWaterfallFragment;
  firstGrantorDeathId: string;
  secondGrantorDeathId?: string;
}

function processLoggedTransfer({
  t,
  waterfall,
  firstGrantorDeathId,
  secondGrantorDeathId,
}: ProcessLoggedTransferInput): {
  afterDeath: AfterDeath;
  beneficiaryId: string;
  beneficiaryReport: BeneficiaryReport;
} | null {
  const beneficiaryReport: BeneficiaryReport = getDefaultBeneficiaryReport();
  const beneficiaryId = t.receivingGrantor?.id ?? t.receivingOrganization?.id;

  if (!beneficiaryId) {
    return null;
  }

  const firstDeathYear = waterfall.firstGrantorDeathYear ?? 0;
  const secondDeathYear = waterfall.secondGrantorDeathYear ?? 0;

  const isFirstGrantor = beneficiaryId === firstGrantorDeathId;
  const isSecondGrantor = beneficiaryId === secondGrantorDeathId;

  if (isFirstGrantor && getYear(t.transactionDate) > firstDeathYear) {
    // The first grantor has already died, so we don't need to process this transfer
    return null;
  }

  if (isSecondGrantor && getYear(t.transactionDate) > secondDeathYear) {
    // The second grantor has already died, so we don't need to process this transfer
    return null;
  }

  const entityGstStatus = (() => {
    if (t.sourceEntity?.subtype && 'gstStatus' in t.sourceEntity.subtype) {
      return t.sourceEntity.subtype.gstStatus ?? null;
    }

    return null;
  })();

  const sourceKind = (() => {
    if (t.sourceEntity) {
      return 'Entity';
    }
    if (t.sourceGrantor) {
      return 'ClientProfile';
    }
    if (t.sourceOrganization) {
      return 'ClientOrganization';
    }

    // It is possible to have dangling logged transfers
    // if the associated source has been deleted
    return null;
  })();

  if (!sourceKind) {
    return null;
  }

  const associatedLifetimeExclusionEvent = first(t.lifetimeExclusionEvents);

  beneficiaryReport.directGifts = [
    {
      id: t.id,
      associatedLifetimeExclusionEventId:
        associatedLifetimeExclusionEvent?.id ?? null,
      variant: BeneficiaryReportingRowVariant.Item,
      benefitKind: BeneficiaryReportBenefitKind.DirectGift,
      kind: BeneficiaryReportDirectGiftKind.LoggedTransfer,
      giftAmount: t.amount,
      giftDate: t.transactionDate,
      sourceId:
        t.sourceEntity?.id ??
        t.sourceGrantor?.id ??
        t.sourceOrganization?.id ??
        '',
      sourceName:
        t.sourceEntity?.subtype.displayName ??
        t.sourceGrantor?.displayName ??
        t.sourceOrganization?.name ??
        '',
      transferTaxKind: null,
      purpose: t.purpose ?? null,
      entityGstStatus,
      notes: null,
      sourceKind,
    },
  ];

  const afterDeath = (() => {
    const transactionYear = getYear(t.transactionDate);
    if (transactionYear <= firstDeathYear) return AfterDeath.None;
    if (transactionYear <= secondDeathYear) return AfterDeath.First;
    return AfterDeath.Second;
  })();

  return {
    afterDeath,
    beneficiaryId,
    beneficiaryReport,
  };
}

interface ProcessWaterfallEdgeInput {
  edge: WaterfallEdgeData;
}

function processWaterfallEdge({ edge }: ProcessWaterfallEdgeInput): {
  beneficiaryId: string;
  beneficiaryReport: BeneficiaryReport;
} | null {
  const beneficiaryReport: BeneficiaryReport = getDefaultBeneficiaryReport();
  const beneficiaryId = edge.toId;

  if (!beneficiaryId) {
    return null;
  }

  if (
    edge.kind !== BeneficiaryReportScheduledDistributionKind.Disposition &&
    edge.kind !== BeneficiaryReportDirectGiftKind.Hypothetical
  ) {
    return null;
  }

  if (edge.kind === BeneficiaryReportScheduledDistributionKind.Disposition) {
    beneficiaryReport.scheduledDistributions = [
      {
        id: `${edge.fromVizNodeId}-${edge.toVizNodeId}-disposition`,
        variant: BeneficiaryReportingRowVariant.Item,
        benefitKind: BeneficiaryReportBenefitKind.ScheduledDistribution,
        kind: edge.kind,
        scheduledDistribution: null,
        dispositionAmount: edge.value,
        sourceId: edge.fromId,
        sourceName: edge.fromEntityName,
        projectedEntityValue: edge.fromEntityValue,
        powerOfAppointment: null,
        entityGstStatus: edge.fromEntityGstStatus,
        notes: null,
        sourceKind: edge.fromEntityKind,
        dispositionParameters: edge.dispositionParameters,
      },
    ];
  }

  if (edge.kind === BeneficiaryReportDirectGiftKind.Hypothetical) {
    if (!edge.associatedHypotheticalTransfer) {
      const message =
        'No associated hypothetical transfer found for hypothetical gift';

      diagnostics.error(message, new Error(message), {
        edge: JSON.stringify(edge),
      });
    } else {
      beneficiaryReport.directGifts = [
        {
          id: edge.associatedHypotheticalTransfer.id,
          associatedLifetimeExclusionEventId: null,
          variant: BeneficiaryReportingRowVariant.Item,
          benefitKind: BeneficiaryReportBenefitKind.DirectGift,
          kind: edge.kind,
          giftAmount: edge.value,
          giftDate: null,
          sourceId: edge.fromId,
          sourceName: edge.fromEntityName,
          transferTaxKind: edge.transferTaxKind,
          purpose: null,
          entityGstStatus: edge.fromEntityGstStatus,
          notes: null,
          sourceKind: edge.fromEntityKind,
        },
      ];
    }
  }

  return {
    beneficiaryId,
    beneficiaryReport,
  };
}

interface GetNotesFromBeneficiaryInput {
  beneficiary: BeneficiaryReporting_BeneficiaryFragment;
  accessParameter?: BeneficiaryReporting_AccessParameterFragment;
  scheduledDistribution?: BeneficiaryReporting_ScheduledDistributionFragment;
}

function getNotesFromBeneficiary({
  beneficiary,
  accessParameter,
  scheduledDistribution,
}: GetNotesFromBeneficiaryInput): Record<string, string> | null {
  const notes: Record<string, string> = {};

  const generalNotes = beneficiary.notes;
  const powerOfAppointmentNotes =
    beneficiary.powerOfAppointment?.powerOtherNote;
  const accessParameterNotes = accessParameter?.accessParameterNotes;
  const scheduledDistributionNotes =
    scheduledDistribution?.scheduledDistributionNotes;
  const ageNotes = (() => {
    if (accessParameter?.accessAgeParameters) {
      return getNodes(accessParameter.accessAgeParameters).flatMap(
        (ap) => ap.notes ?? []
      );
    }

    return [];
  })();

  if (generalNotes) {
    notes['General notes'] = generalNotes;
  }
  if (powerOfAppointmentNotes) {
    notes['Power of appointment'] = powerOfAppointmentNotes;
  }
  if (accessParameterNotes) {
    notes['Access parameter'] = accessParameterNotes;
  }
  if (scheduledDistributionNotes) {
    notes['Scheduled distribution'] = scheduledDistributionNotes;
  }
  if (ageNotes.length > 0) {
    notes['Upon reaching a specific age'] = ageNotes.join('\n');
  }

  return Object.keys(notes).length > 0 ? notes : null;
}

interface ProcessBeneficiaryInput {
  beneficiary: BeneficiaryReporting_BeneficiaryFragment;
  afterDeath: AfterDeath;
  firstGrantorDeathId: string;
  secondGrantorDeathId?: string;
  currentValue: Decimal;
  entityGstStatus: EntityGstStatus | null;
  sourceId: string;
  sourceName: string;
  sourceKind:
    | 'Entity'
    | 'ClientProfile'
    | 'ClientOrganization'
    | 'TestamentaryEntity';
  sourceEntityKind?: EntityKind;
}

function processBeneficiary({
  beneficiary,
  afterDeath,
  firstGrantorDeathId,
  secondGrantorDeathId,
  currentValue,
  entityGstStatus,
  sourceId,
  sourceName,
  sourceKind,
  sourceEntityKind,
}: ProcessBeneficiaryInput): {
  beneficiaryId: string;
  beneficiaryReport: BeneficiaryReport;
} | null {
  const beneficiaryReport: BeneficiaryReport = getDefaultBeneficiaryReport();

  const beneficiaryId =
    beneficiary.individual?.id ?? beneficiary.organization?.id;

  if (!beneficiaryId) {
    return null;
  }

  if (
    (afterDeath === AfterDeath.First || afterDeath === AfterDeath.Second) &&
    beneficiaryId === firstGrantorDeathId
  ) {
    return null;
  }

  if (
    afterDeath === AfterDeath.Second &&
    beneficiaryId === secondGrantorDeathId
  ) {
    return null;
  }

  beneficiaryReport.scheduledDistributions = getNodes(
    beneficiary.scheduledDistributions
  ).map((sd) => ({
    id: sd.id,
    variant: BeneficiaryReportingRowVariant.Item,
    benefitKind: BeneficiaryReportBenefitKind.ScheduledDistribution,
    kind: BeneficiaryReportScheduledDistributionKind.Distribution,
    scheduledDistribution: sd,
    dispositionAmount: new Decimal(0),
    sourceId,
    sourceName,
    projectedEntityValue: currentValue,
    powerOfAppointment: beneficiary.powerOfAppointment ?? null,
    entityGstStatus,
    notes: getNotesFromBeneficiary({
      beneficiary,
      scheduledDistribution: sd,
    }),
    sourceKind,
    dispositionParameters: null,
  }));
  beneficiaryReport.fullAccess = getNodes(beneficiary.accessParameters).flatMap(
    (ap) => {
      if (!(ap.kind === AccessParameterKind.Full)) return [];

      return {
        id: ap.id,
        variant: BeneficiaryReportingRowVariant.Item,
        benefitKind: BeneficiaryReportBenefitKind.FullAccess,
        accessParameter: ap,
        sourceId,
        sourceName,
        projectedEntityValue: currentValue,
        powerOfAppointment: beneficiary.powerOfAppointment ?? null,
        entityGstStatus,
        notes: getNotesFromBeneficiary({
          beneficiary,
          accessParameter: ap,
        }),
        sourceKind,
      };
    }
  );

  const partialAccessParameters = getNodes(
    beneficiary.accessParameters
  ).flatMap((ap) => {
    if (ap.kind === AccessParameterKind.Full) return [];
    return ap;
  });

  // CLT and CRT trusts are displayed as scheduled distributions even though
  // they are modeled as partial access
  if (
    sourceEntityKind === EntityKind.CltTrust ||
    sourceEntityKind === EntityKind.CrtTrust
  ) {
    beneficiaryReport.scheduledDistributions = partialAccessParameters.map(
      (ap) => ({
        id: sourceId,
        variant: BeneficiaryReportingRowVariant.Item,
        benefitKind: BeneficiaryReportBenefitKind.ScheduledDistribution,
        kind: BeneficiaryReportScheduledDistributionKind.Distribution,
        scheduledDistribution: {
          id: ap.id,
          ageRequirementEnd: null, // Age requirement does not apply to CLT and CRT beneficiaries
          ageRequirementStart: null, // Age requirement does not apply to CLT and CRT beneficiaries
          amount: ap.amount,
          frequency: ap.frequency
            ? // This is an easy mapping
              accessParameterFrequencyToScheduledDistributionFrequency(
                ap.frequency
              )
            : null,
          kind: accessParameterKindToScheduledDistributionKind(ap.kind), // This is a werid mapping, but for CLT and CRT trusts we have amount, percentage, net income, and net income with makeup that need to be either an amount or percentage in the scheduled distributions table
          percentage: ap.percentage,
          scheduledDistributionNotes: null, // Notes are not in the forms for CLT and CRT beneficiaries
        },
        dispositionAmount: new Decimal(0),
        sourceId,
        sourceName,
        projectedEntityValue: currentValue,
        powerOfAppointment: beneficiary.powerOfAppointment ?? null,
        entityGstStatus,
        notes: null, // Notes are not in the forms for CLT and CRT beneficiaries
        sourceKind,
        dispositionParameters: null,
      })
    );
  } else {
    beneficiaryReport.partialAccess = partialAccessParameters.map((ap) => {
      return {
        id: sourceId, // Use sourceId to avoid double counting partial access
        variant: BeneficiaryReportingRowVariant.Item,
        benefitKind: BeneficiaryReportBenefitKind.PartialAccess,
        accessParameter: ap,
        sourceId,
        sourceName,
        projectedEntityValue: currentValue,
        powerOfAppointment: beneficiary.powerOfAppointment ?? null,
        entityGstStatus,
        notes: getNotesFromBeneficiary({
          beneficiary,
          accessParameter: ap,
        }),
        sourceKind,
      };
    });
  }

  return {
    beneficiaryId,
    beneficiaryReport,
  };
}

interface GetBeneficiariesDataFromWaterfallInput {
  waterfall: BeneficiaryReporting_EstateWaterfallFragment;
  loggedTransfers: BeneficiaryReporting_LoggedTransferFragment[];
  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[];
}

export function getBeneficiariesDataFromWaterfall({
  waterfall,
  loggedTransfers,
  possibleGrantors,
}: GetBeneficiariesDataFromWaterfallInput): BeneficiariesData {
  const firstGrantorDeathId = waterfall.firstGrantorDeath?.id;
  const secondGrantorDeathId = possibleGrantors.find(
    (g) => g.id !== firstGrantorDeathId
  )?.id;
  const beneficiariesData: BeneficiariesData = {
    [AfterDeath.None]: {},
    [AfterDeath.First]: {},
    [AfterDeath.Second]: {},
  };

  const inboundDispositions =
    waterfall.visualizationWithProjections.edges.flatMap((e) => {
      if (e.kind === EstateWaterfallEdgeKind.Disposition) {
        return (
          getWaterfallEdgeData(
            e,
            BeneficiaryReportScheduledDistributionKind.Disposition
          ) ?? []
        );
      }
      return [];
    });

  const inboundHypotheticalTransfers =
    waterfall.visualizationWithProjections.edges.flatMap((e) => {
      if (e.kind === EstateWaterfallEdgeKind.Transfer) {
        return (
          getWaterfallEdgeData(
            e,
            BeneficiaryReportDirectGiftKind.Hypothetical
          ) ?? []
        );
      }
      return [];
    });

  waterfall.visualizationWithProjections.nodes.forEach((node) => {
    if (node.isHidden) return;

    const afterDeath = node.afterDeath;
    const vizNodeId = getNodeId({
      id: node.id,
      afterDeath,
    });
    const currentValue = node.value;

    const inboundDispositionsForNode = inboundDispositions.filter(
      (d) => d.toVizNodeId === vizNodeId
    );

    const inboundTransfersForNode = inboundHypotheticalTransfers.filter(
      (t) => t.toVizNodeId === vizNodeId
    );

    if (node.node.__typename === 'Entity') {
      const trust = node.node.subtype;
      const entityName = trust.displayName;
      const entityKind = node.node.entityKind;
      const entityGstStatus = (() => {
        if ('gstStatus' in trust) {
          return trust.gstStatus ?? null;
        }
        return null;
      })();
      if ('beneficiaries' in trust) {
        const beneficiaries = trust.beneficiaries ?? [];
        beneficiaries.forEach((beneficiary) => {
          const processedBeneficiary = processBeneficiary({
            beneficiary,
            afterDeath,
            firstGrantorDeathId,
            secondGrantorDeathId,
            currentValue,
            entityGstStatus,
            sourceId: node.id,
            sourceName: entityName,
            sourceKind: 'Entity',
            sourceEntityKind: entityKind,
          });
          if (!processedBeneficiary) {
            return;
          }

          const { beneficiaryId, beneficiaryReport } = processedBeneficiary;

          mergeBeneficiaryReports(
            beneficiariesData,
            afterDeath,
            beneficiaryId,
            beneficiaryReport
          );
        });
      }
    }

    if (node.node.__typename === 'TestamentaryEntity') {
      const testamentaryEntity = node.node;
      const entityName = testamentaryEntity.displayName;
      const entityGstStatus = testamentaryEntity.gstStatus ?? null;
      const lifetimeBeneficiaries = testamentaryEntity.beneficiaries ?? [];
      const beneficiariesAfterBothDeaths =
        testamentaryEntity.beneficiariesAfterBothGrantorDeaths ?? [];

      const fullAccessBeneficiaryIds = new Set<string>();

      const processTestamentaryEntityBeneficiary = (
        beneficiary: BeneficiaryReporting_BeneficiaryFragment
      ) => {
        const processedBeneficiary = processBeneficiary({
          beneficiary,
          afterDeath,
          firstGrantorDeathId,
          secondGrantorDeathId,
          currentValue,
          entityGstStatus,
          sourceId: testamentaryEntity.id,
          sourceName: entityName,
          sourceKind: 'TestamentaryEntity',
        });
        if (!processedBeneficiary) {
          return;
        }

        const { beneficiaryId, beneficiaryReport } = processedBeneficiary;

        // If this beneficiary has already been reported as having full access,
        // we don't need to report it again
        if (fullAccessBeneficiaryIds.has(beneficiaryId)) {
          beneficiaryReport.fullAccess = [];
        }

        // Keep track of beneficiaries with full access so we can deduplicate them
        if (beneficiaryReport.fullAccess.length > 0) {
          fullAccessBeneficiaryIds.add(beneficiaryId);
        }

        mergeBeneficiaryReports(
          beneficiariesData,
          afterDeath,
          beneficiaryId,
          beneficiaryReport
        );
      };

      lifetimeBeneficiaries.forEach(processTestamentaryEntityBeneficiary);
      if (afterDeath === AfterDeath.Second) {
        beneficiariesAfterBothDeaths.forEach(
          processTestamentaryEntityBeneficiary
        );
      }
    }

    [...inboundDispositionsForNode, ...inboundTransfersForNode].forEach(
      (edge) => {
        const processedWaterfallEdge = processWaterfallEdge({
          edge,
        });
        if (!processedWaterfallEdge) {
          return;
        }

        const { beneficiaryId, beneficiaryReport } = processedWaterfallEdge;

        mergeBeneficiaryReports(
          beneficiariesData,
          afterDeath,
          beneficiaryId,
          beneficiaryReport
        );
      }
    );
  });

  loggedTransfers.forEach((t) => {
    const processedLoggedTransfer = processLoggedTransfer({
      t,
      waterfall,
      firstGrantorDeathId,
      secondGrantorDeathId,
    });
    if (!processedLoggedTransfer) {
      return;
    }

    const { afterDeath, beneficiaryId, beneficiaryReport } =
      processedLoggedTransfer;

    mergeBeneficiaryReports(
      beneficiariesData,
      afterDeath,
      beneficiaryId,
      beneficiaryReport
    );
  });

  return beneficiariesData;
}

export function useLoggedTransferWhereInput(): LoggedTransferWhereInput {
  const { possibleBeneficiaries } = useHouseholdDetailsContext();
  const beneficiaryIds = useMemo(() => {
    const clientIds = possibleBeneficiaries?.clients.map((c) => c.id) ?? [];
    const orgIds = possibleBeneficiaries?.organizations.map((o) => o.id) ?? [];
    return uniq([...clientIds, ...orgIds]);
  }, [possibleBeneficiaries]);

  const orPredicate: LoggedTransferWhereInput[] = [];

  if (beneficiaryIds.length > 0) {
    const beneficiaryFilter = { idIn: beneficiaryIds };

    orPredicate.push(
      { hasReceivingGrantorWith: [beneficiaryFilter] },
      { hasReceivingOrganizationWith: [beneficiaryFilter] }
    );
  }

  return { or: orPredicate };
}

export function useBeneficiariesData(waterfallId: string) {
  const { possibleGrantors } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();

  const loggedTransferWhereInput = useLoggedTransferWhereInput();

  const estateWaterfallBeneficiariesQuery = useBeneficiaryReportingQuery({
    variables: {
      estateWaterfallWhereInput: { id: waterfallId },
      loggedTransferWhereInput,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      diagnostics.error('Failed to fetch waterfall beneficiaries', error, {
        waterfallId,
      });
      showFeedback(FeedbackMessages.queryError);
    },
  });

  const beneficiariesData: BeneficiariesData | null = useMemo(() => {
    if (!estateWaterfallBeneficiariesQuery.data) {
      return null;
    }

    const waterfall = first(
      getNodes(estateWaterfallBeneficiariesQuery.data.estateWaterfalls)
    );
    if (!waterfall) {
      return null;
    }

    const loggedTransfers = orderBy(
      getNodes(estateWaterfallBeneficiariesQuery.data.loggedTransfers),
      ['transactionDate'],
      ['asc']
    );

    return getBeneficiariesDataFromWaterfall({
      waterfall,
      loggedTransfers,
      possibleGrantors: possibleGrantors ?? [],
    });
  }, [estateWaterfallBeneficiariesQuery.data, possibleGrantors]);

  return {
    beneficiariesData,
    ...estateWaterfallBeneficiariesQuery,
  };
}

function accessParameterFrequencyToScheduledDistributionFrequency(
  frequency: AccessParameterFrequency
): ScheduledDistributionFrequency {
  switch (frequency) {
    case AccessParameterFrequency.Annually:
      return ScheduledDistributionFrequency.Annually;
    case AccessParameterFrequency.Semiannually:
      return ScheduledDistributionFrequency.Semiannually;
    case AccessParameterFrequency.Quarterly:
      return ScheduledDistributionFrequency.Quarterly;
    case AccessParameterFrequency.Monthly:
      return ScheduledDistributionFrequency.Monthly;
    default:
      throw new UnreachableError({
        case: frequency,
        message: `Unexpected access parameter frequency: ${frequency}`,
      });
  }
}

// Only use this for CLT and CRT trusts where there are modeled as partial access
// but are displayed as scheduled distributions
function accessParameterKindToScheduledDistributionKind(
  kind: AccessParameterKind
): ScheduledDistributionKind {
  switch (kind) {
    case AccessParameterKind.Amount:
      return ScheduledDistributionKind.Amount;
    case AccessParameterKind.Percentage:
      return ScheduledDistributionKind.Percentage;
    case AccessParameterKind.Full:
    case AccessParameterKind.FullDiscretion:
    case AccessParameterKind.Hems:
    case AccessParameterKind.Hms:
    case AccessParameterKind.Other:
    case AccessParameterKind.AllTrustIncome:
    case AccessParameterKind.NetIncome:
    case AccessParameterKind.NetIncomeWithMakeup:
      return ScheduledDistributionKind.AllIncome;
    default:
      throw new UnreachableError({
        case: kind,
        message: `Unexpected access parameter kind: ${kind}`,
      });
  }
}
