import { Stack, Typography } from '@mui/material';
import { compact } from 'lodash';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { Callout } from '@/components/notifications/Callout/Callout';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { LuminaryThemeProvider } from '@/styles/themes/LuminaryThemeProvider';
import { InfoMessageDisplayType } from '@/types/schema';

import {
  AnnuityPaymentsTable,
  DesignSummarySection,
  EntityItemRow,
} from './accessoryComponents';
import { TrustDetailsSummary_DesignSummaryDetailsFragment } from './graphql/TrustDetailsSummary.generated';
import { TrusteeDetailsSummaryList } from './TrusteeDetailsSummaryList';

interface TrustDetailsSummaryProps {
  designSummary: TrustDetailsSummary_DesignSummaryDetailsFragment;
  onEditClick: () => void;
  variant: 'completeDesignSummary' | 'confirmTrustDetails';
}

export function TrustDetailsSummary({
  designSummary,
  onEditClick,
  variant,
}: TrustDetailsSummaryProps) {
  return (
    <LuminaryThemeProvider variant="darkText">
      <Stack spacing={4} mb={5}>
        <DesignSummarySection
          heading="Basic information"
          onEditClick={onEditClick}
        >
          <Stack spacing={2}>
            <SubtleLabelItem
              label="Display name"
              value={designSummary.displayName}
            />
            <SubtleLabelItem
              label="Legal name"
              value={designSummary.legalName ?? EMPTY_CONTENT_HYPHEN}
            />

            <Stack direction="row" spacing={4} justifyContent="flex-start">
              <SubtleLabelItem
                label="Grantor legal name"
                value={designSummary.grantor.displayName}
              />
              {designSummary.spouseDisplayName && (
                <SubtleLabelItem
                  label="Spouse legal name"
                  value={designSummary.spouseDisplayName}
                />
              )}
            </Stack>
            <SubtleLabelItem
              label="Trust jurisdiction"
              value={designSummary.trustSitus ?? EMPTY_CONTENT_HYPHEN}
            />
            <Stack direction="row" spacing={4} justifyContent="flex-start">
              <SubtleLabelItem
                label="Term length"
                value={designSummary.termDescription}
              />
              <Stack direction="row" spacing={3} justifyContent="flex-start">
                <SubtleLabelItem
                  label="7520 rate"
                  value={designSummary.officialInterestRate}
                />
                <Callout
                  severity={
                    designSummary.termDetailsInfo.displayType ===
                    InfoMessageDisplayType.Warning
                      ? 'error'
                      : 'info-low'
                  }
                >
                  {designSummary.termDetailsInfo.display}
                </Callout>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={4} justifyContent="flex-start">
              <SubtleLabelItem
                label="Initial funding value"
                value={designSummary.initialFundingValue}
              />
              <SubtleLabelItem
                label="Taxable gift amount"
                value={designSummary.taxableGiftAmount}
              />
              <SubtleLabelItem
                label="Grantor-retained interest"
                value={designSummary.grantorRetainedInterest}
              />
            </Stack>
          </Stack>
        </DesignSummarySection>
        <DesignSummarySection heading="Trustees" onEditClick={onEditClick}>
          <TrusteeDetailsSummaryList
            trustees={designSummary.trustees}
            successorTrustees={designSummary.successorTrustees}
            trustAdvisors={designSummary.trustAdvisors}
          />
        </DesignSummarySection>
        <DesignSummarySection
          heading="Remainder beneficiaries"
          onEditClick={onEditClick}
        >
          <Stack spacing={1.5}>
            {designSummary.beneficiaries.length === 0 && (
              <Callout severity="error">
                You have no beneficiaries set up for this GRAT. Please add
                beneficiaries.
              </Callout>
            )}
            {designSummary.beneficiaries.map((beneficiary, i) => (
              <EntityItemRow
                key={i}
                heading={beneficiary.displayName}
                description={beneficiary.displayType}
                value={beneficiary.ownershipPercentage}
                badgeText={beneficiary.badgeText || undefined}
                additionalItems={
                  beneficiary.additionalContext.filter(
                    (line) => !!line
                  ) as string[]
                }
              />
            ))}
          </Stack>
        </DesignSummarySection>
        <DesignSummarySection
          heading="Initial funding assets"
          onEditClick={
            variant !== 'confirmTrustDetails' ? onEditClick : undefined
          }
        >
          {variant === 'confirmTrustDetails' ? (
            <Stack spacing={1.5}>
              <Typography variant="body1">
                Assets will be confirmed in a later step{' '}
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={1.5}>
              {designSummary.assets.map((asset, i) => (
                <EntityItemRow
                  key={i}
                  heading={asset.displayName}
                  description={compact([
                    asset.type,
                    asset.qsbsDetails && `(${asset.qsbsDetails})`,
                  ]).join(' ')}
                  additionalItems={
                    asset.shareCount && asset.sharePrice
                      ? [
                          `${asset.shareCount.toLocaleString()} shares`,
                          `${asset.sharePrice}/share`,
                        ]
                      : []
                  }
                  value={asset.value}
                  valueContext={asset.shareCount ? 'Total value' : undefined}
                />
              ))}
            </Stack>
          )}
        </DesignSummarySection>
        <DesignSummarySection
          heading="Annuity payments"
          onEditClick={onEditClick}
        >
          <Stack maxWidth={650} mt={3} mx="auto" spacing={2}>
            <AnnuityPaymentsTable
              heading="Annuity payments as % of initial funding"
              rows={designSummary.annuityPayments.map((annuityDetails) => ({
                label: annuityDetails.label,
                value: annuityDetails.paymentPercentage,
              }))}
            />
            <Callout severity="info-high">
              Dollar value of annuity payments will be calculated once the
              initial funding amount is entered in the Trust setup workflow.
            </Callout>
          </Stack>
        </DesignSummarySection>
      </Stack>
    </LuminaryThemeProvider>
  );
}
