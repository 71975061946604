import { EntityDiagramContextContainer } from './components/EntityDiagramContextContainer';
import { EntityDiagramFlowChartWrapper } from './components/EntityDiagramFlowChartWrapper';
import { useEntityDiagramContext } from './contexts/entityDiagram.context';
import { EntityDiagramFlowChart } from './EntityDiagramFlowChart';
import { EntityDiagramProps } from './types';

export const EntityDiagram = ({
  height,
  width,
  householdId,
  entityId,
}: EntityDiagramProps) => {
  const { getState } = useEntityDiagramContext();
  const { presentationMode, entity, primaryClients } = getState();

  return (
    <EntityDiagramContextContainer height={height} width={width}>
      <EntityDiagramFlowChartWrapper
        height={height}
        presentationMode={presentationMode}
      >
        <EntityDiagramFlowChart
          entityId={entityId}
          householdId={householdId}
          entity={entity}
          primaryClients={primaryClients}
          presentationMode={presentationMode}
        />
      </EntityDiagramFlowChartWrapper>
    </EntityDiagramContextContainer>
  );
};
