import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { chunk } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { formatPercentNoDecimals } from '@/utils/formatting/percent';

import { EntityDetailNameCard } from './EntityDetailNameCard';

interface OwnersProps {
  owners: {
    id: string;
    displayName: string;
    percentage?: Decimal;
  }[];
  chunkSize?: number;
}

export function Owners({ owners, chunkSize = 4 }: OwnersProps) {
  const chunkedOwners = chunk(owners, chunkSize)?.[0] ?? [];
  const exceedsMax = owners.length > chunkSize;

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Owners
      </Typography>
      {chunkedOwners.map((owner) => (
        <EntityDetailNameCard
          key={owner.id}
          leftText={
            owner.percentage
              ? `${formatPercentNoDecimals(owner.percentage)}%`
              : EMPTY_CONTENT_HYPHEN
          }
          rightText={owner.displayName}
        />
      ))}
      {chunkedOwners.length === 0 && (
        <Typography variant="subtitle2">None specified</Typography>
      )}
      {exceedsMax && (
        <Typography variant="subtitle2">
          {`+${owners.length - chunkSize} more`}
        </Typography>
      )}
    </Stack>
  );
}
