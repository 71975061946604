import { Stack, StackProps, SxProps } from '@mui/material';
import { useMemo } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { Card } from '@/components/layout/Card/Card';
import { LuminaryAiBetaLogo } from '@/modules/documents/components/LuminaryAiLogo';
import { COLORS } from '@/styles/tokens/colors';

interface LuminaryAIBannerCardProps {
  bannerText: React.ReactNode;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  withBorder?: boolean;
  cardSx?: SxProps;
  buttonContainerProps?: StackProps;
  onDismiss?: () => void;
}

/**
 * This component is used to display a banner related to AI activities. Includes
 * a teal background, the Luminary AI logo, and props for the banner text and
 * buttons.
 *
 * @param bannerText
 * @param primaryButtonProps
 * @param secondaryButtonProps
 * @param withBorder
 * @param cardSx
 * @param buttonContainerProps
 * @param onDismiss
 * @constructor
 */
export function LuminaryAIBannerCard({
  bannerText,
  primaryButtonProps,
  secondaryButtonProps,
  withBorder = false,
  cardSx = {},
  buttonContainerProps = {},
  onDismiss,
}: LuminaryAIBannerCardProps) {
  const sx: SxProps = useMemo(() => {
    let styles: SxProps = { p: 3, flexShrink: 0 };

    if (withBorder) {
      styles = {
        ...styles,
        border: '1px solid',
        borderColor: COLORS.TEAL['600'],
      };
    }

    return { ...styles, ...cardSx };
  }, [cardSx, withBorder]);

  const hasButton = primaryButtonProps || secondaryButtonProps;

  return (
    <Card variant="filled-teal" sx={sx}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          width="100%"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <LuminaryAiBetaLogo />
            {bannerText}
          </Stack>
          {hasButton && (
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              {...buttonContainerProps}
            >
              {secondaryButtonProps && <Button {...secondaryButtonProps} />}
              {primaryButtonProps && <Button {...primaryButtonProps} />}
            </Stack>
          )}
        </Stack>
        {onDismiss && (
          <IconButton
            icon={XCloseIcon}
            ariaLabel="dismiss"
            size="sm"
            onClick={onDismiss}
            variant="transparent"
          />
        )}
      </Stack>
    </Card>
  );
}
