import { Divider } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { keyPersonRoleDisplayNames } from '@/modules/entities/details/businessEntities/businessEntities.constants';
import { BusinessEntitySummaryData } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.types';
import { BusinessEntityDetailsMiniTable } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetailsMiniTable';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { KeyPersonRoleKind } from '@/types/schema';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { NotesCard } from '../../components/DispositionScenarioSlide/NotesCard';
import { EntityValuation } from '../../components/EntityValuation';
import { KeyPeople } from '../../components/KeyPeople';
import { Owners } from '../../components/Owners';
import { SummarySlideLayout } from '../../components/SummarySlideLayout';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

/**
 * @description The BusinessEntitySummarySlide is a generic component that can be shared between business entity types that have
 * similar overview slides in their presentations.
 */

export interface BusinessEntitySummarySlideProps {
  entity: EntityPresentation_EntityFragment;
  summary: BusinessEntitySummaryData;
  displayName: string;
  registrationProps: PresentationSlideRegistrationDetails;
  pageIndex: number;
  maxPages: number;
  paginatedDescription: string | null;
}

export function BusinessEntitySummarySlide({
  entity,
  summary,
  displayName,
  registrationProps,
  pageIndex,
  paginatedDescription,
}: BusinessEntitySummarySlideProps) {
  const slideId = `${registrationProps.slideId}_${pageIndex + 1}`;

  useRegisterSlide({
    displayName: 'Entity Summary',
    bundleId: registrationProps.bundleId,
    id: slideId,
    index: registrationProps.bundleIndex,
    skip: pageIndex > 0,
  });

  const LeftContent = useMemo(() => {
    const ownerItems =
      summary.ownedByStakes?.map((stake) => ({
        id: stake.id,
        displayName:
          stake.owningIndividual?.displayName ??
          stake.owningEntity?.subtype.displayName ??
          '',
        percentage: stake.ownershipPercentage ?? undefined,
      })) ?? [];

    const keyPeopleItems = Object.entries(keyPersonRoleDisplayNames).flatMap(
      ([role, label]) => {
        const keyPeople = summary.keyPeople
          ?.filter((keyPerson) =>
            keyPerson.roles?.some((r) => r.kind === (role as KeyPersonRoleKind))
          )
          ?.map(
            (keyPerson) =>
              keyPerson.individual?.displayName ??
              keyPerson.entity?.subtype.displayName ??
              keyPerson.organization?.name ??
              ''
          );

        if (keyPeople?.length === 0) {
          return [];
        }

        return {
          label,
          names: [keyPeople?.join(', ') ?? ''],
        };
      }
    );

    // We can only show 8 items on a page
    const itemBudget = 8;
    let ownerItemsChunkSize = 4;
    let keyPeopleItemsChunkSize = 4;
    if (ownerItems.length + keyPeopleItems.length <= itemBudget) {
      // If we have less than 8 items, show all of them regardless of the individual chunk size
      ownerItemsChunkSize = ownerItems.length;
      keyPeopleItemsChunkSize = keyPeopleItems.length;
    } else {
      // Allocate the budget between the owner items and the key people items
      ownerItemsChunkSize = Math.min(ownerItems.length, itemBudget / 2); // Show up to 4 owners
      keyPeopleItemsChunkSize = itemBudget - ownerItemsChunkSize; // Fill the rest with key people
    }

    return compact([
      <EntityValuation key="entity-valuation" entity={entity} />,
      <BusinessEntityDetailsMiniTable
        key="business-entity-details-mini-table"
        {...summary}
      />,
      <Divider
        key="divider-0"
        sx={{ borderColor: COLORS.NEUTRAL_GRAY[200] }}
      />,
      <Owners
        key="owners"
        owners={ownerItems}
        chunkSize={ownerItemsChunkSize}
      />,
      <Divider
        key="divider-1"
        sx={{ borderColor: COLORS.NEUTRAL_GRAY[200] }}
      />,
      <KeyPeople
        key="key-people"
        keyPeople={keyPeopleItems}
        chunkSize={keyPeopleItemsChunkSize}
      />,
    ]);
  }, [entity, summary]);

  const RightContent = useMemo(() => {
    return (
      <NotesCard
        key="business-entity-notes"
        description={paginatedDescription}
      />
    );
  }, [paginatedDescription]);

  return (
    <PresentationSlide.Slide
      id={slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={displayName}
          subheading={entity.extendedDisplayKind}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <SummarySlideLayout
        LeftContent={LeftContent}
        RightContent={RightContent}
        pageIndex={pageIndex}
      />
    </PresentationSlide.Slide>
  );
}
