import { compact } from 'lodash';

import { AISuggestionMatch } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  AccessParameterRadioGroupKind,
  AgeRequirementKind,
  BeneficiaryFormAccessParameter,
  BeneficiaryFormAccessParameterAgeParam,
  BeneficiaryFormAccessParameters,
  BeneficiaryFormPowerOfAppointment,
  BeneficiaryFormScheduledDistribution,
  BeneficiaryFormScheduledDistributions,
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesSubformShape,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import {
  AccessParameterKind,
  AiSuggestionKind,
  BeneficiaryPowerOfAppointmentPower,
} from '@/types/schema';
import { parseDecimalJS } from '@/utils/decimalJSUtils';

import {
  BeneficiaryAccessParameterFragment,
  BeneficiaryAgeParametersFragment,
  BeneficiaryScheduledDistributionFragment,
} from '../../graphql/aiSuggestions.generated';

export function suggestionsToBeneficiariesV2Subform(
  matches: AISuggestionMatch[]
): NonNullable<EntityBeneficiariesSubformShape['beneficiaries']> {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.clientOrganizationId || m.entityId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.BeneficiaryV2)
      .filter((m) => m.suggestion.beneficiaryV2)
      .map((match) => suggestionToBeneficiaryV2(match))
  );
}

function suggestionToBeneficiaryV2(
  suggestionMatch: AISuggestionMatch
): EntityBeneficiariesFormBeneficiary | null {
  const clientProfileOrgEntityId =
    suggestionMatch.clientProfileId ||
    suggestionMatch.clientOrganizationId ||
    suggestionMatch.entityId;
  if (!clientProfileOrgEntityId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { beneficiaryV2 } = suggestionMatch.suggestion;
  if (!beneficiaryV2) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  return {
    id: null,
    beneficiaryId: clientProfileOrgEntityId,
    level: beneficiaryV2.order || '',
    notes: beneficiaryV2.notes || '',
    beneficiaryFormAccessParameters: beneficiaryV2.accessParameters
      ? accessParametersToBeneficiaryFormAccessParameters(
          beneficiaryV2.accessParameters as BeneficiaryAccessParameterFragment[]
        )
      : undefined,
    beneficiaryFormScheduledDistributions: beneficiaryV2.scheduledDistributions
      ? scheduledDistributionsToBeneficiaryFormScheduledDistributions(
          beneficiaryV2.scheduledDistributions as BeneficiaryScheduledDistributionFragment[]
        )
      : undefined,
    beneficiaryFormPowerOfAppointment:
      powerOfAppointmentToBeneficiaryFormPowerOfAppointment(
        beneficiaryV2.powerOfAppointment,
        beneficiaryV2.powerOfAppointmentNotes
      ),
    isSuggestion: true,
  };
}

function createCommonAccessParameterValue(
  ap: BeneficiaryAccessParameterFragment
): BeneficiaryFormAccessParameter {
  return {
    kind: ap.kind!,
    accessParameterNotes: ap.accessParameterNotes ?? '',
    _hasFrequency: false,
    _hasAgeParams: hasAgeParameters(ap.accessAgeParameters),
    ageParams: ap.accessAgeParameters
      ? ageParametersToBeneficiaryFormAccessParameterAgeParams(
          ap.accessAgeParameters
        )
      : undefined,
  };
}

function accessParametersToBeneficiaryFormAccessParameters(
  accessParameters: BeneficiaryAccessParameterFragment[]
): BeneficiaryFormAccessParameters | undefined {
  if (!accessParameters || accessParameters.length === 0) {
    return undefined;
  }

  // The first access parameter must be either of kind FULL or AMOUNT/PERCENTAGE or OTHER
  const firstAccessParameter = accessParameters[0]!;
  if (firstAccessParameter.kind === AccessParameterKind.Full) {
    return {
      _accessParameterKind: AccessParameterRadioGroupKind.Full,
    };
  }

  if (
    firstAccessParameter.kind === AccessParameterKind.Amount ||
    firstAccessParameter.kind === AccessParameterKind.Percentage
  ) {
    // If the first accessParameter is of kind PERCENTAGE or AMOUNT, there may be additional access parameters of either of those types
    const accessParametersPartial: BeneficiaryFormAccessParameter[] = [];
    accessParameters.forEach((ap) => {
      if (!ap) {
        return;
      }
      if (ap.kind == AccessParameterKind.Amount) {
        if (!ap.distributionAmount) {
          return;
        }
        accessParametersPartial.push({
          kind: ap.kind,
          amount: parseDecimalJS(ap.distributionAmount) as unknown as undefined, // HACK: we do this because expected type is OpaqueDecimal
          accessParameterNotes: ap.accessParameterNotes ?? '',
          _hasFrequency: !!ap.distributionFrequency,
          frequency: ap.distributionFrequency ?? undefined,
          _hasAgeParams: hasAgeParameters(ap.accessAgeParameters),
          ageParams: ap.accessAgeParameters
            ? ageParametersToBeneficiaryFormAccessParameterAgeParams(
                ap.accessAgeParameters
              )
            : undefined,
        });
      } else if (ap.kind === AccessParameterKind.Percentage) {
        if (!ap.distributionPercentage) {
          return;
        }
        // PERCENTAGE
        accessParametersPartial.push({
          kind: ap.kind,
          percentage: parseDecimalJS(
            ap.distributionPercentage
          ) as unknown as undefined, // HACK: we do this because expected type is OpaqueDecimal
          accessParameterNotes: ap.accessParameterNotes || '',
          _hasFrequency: !!ap.distributionFrequency,
          frequency: ap.distributionFrequency ?? undefined,
          _hasAgeParams: hasAgeParameters(ap.accessAgeParameters),
          ageParams: ap.accessAgeParameters
            ? ageParametersToBeneficiaryFormAccessParameterAgeParams(
                ap.accessAgeParameters
              )
            : undefined,
        });
      }
    });
    return {
      _accessParameterKind: AccessParameterRadioGroupKind.Partial,
      accessParametersPartial: accessParametersPartial,
    };
  }

  if (firstAccessParameter.kind === AccessParameterKind.Other) {
    const accessParameterOthers = {
      checkboxes: {} as Record<AccessParameterKind, boolean>,
      values: {} as Record<AccessParameterKind, BeneficiaryFormAccessParameter>,
    };

    // We skip the first access parameter since it is just indicating the kind of access parameter group
    accessParameters.slice(1).forEach((ap) => {
      if (!ap?.kind) return;

      const kinds = [
        AccessParameterKind.FullDiscretion,
        AccessParameterKind.Hms,
        AccessParameterKind.Hems,
        AccessParameterKind.AllTrustIncome,
        AccessParameterKind.Other,
      ];

      if (kinds.includes(ap.kind)) {
        accessParameterOthers.checkboxes[ap.kind] = true;
        accessParameterOthers.values[ap.kind] =
          createCommonAccessParameterValue(ap);
      }
    });

    return {
      _accessParameterKind: AccessParameterRadioGroupKind.Other,
      accessParametersOther: accessParameterOthers,
    };
  }
  return undefined;
}

function scheduledDistributionsToBeneficiaryFormScheduledDistributions(
  scheduledDistributions: BeneficiaryScheduledDistributionFragment[]
): BeneficiaryFormScheduledDistributions | undefined {
  if (!scheduledDistributions || scheduledDistributions.length === 0) {
    return undefined;
  }
  const scheduledDistributionsPartial: BeneficiaryFormScheduledDistribution[] =
    [];
  scheduledDistributions.forEach((sd) => {
    if (!sd.kind) {
      return;
    }
    const ageParams = sd.accessAgeParameters
      ? ageParametersToBeneficiaryFormAccessParameterAgeParams(
          sd.accessAgeParameters
        )
      : undefined;
    scheduledDistributionsPartial.push({
      _hasAgeParams: hasAgeParameters(sd.accessAgeParameters),
      _ageRequirementKind: ageParams?.[0]
        ? ageParams?.[0]!._ageRequirementKind
        : '',
      scheduledDistribution: {
        kind: sd.kind,
        amount: sd.distributionAmount
          ? parseDecimalJS(sd.distributionAmount)
          : undefined,
        percentage: sd.distributionPercentage
          ? parseDecimalJS(sd.distributionPercentage)
          : undefined,
        frequency: sd.distributionFrequency ?? '',
        ageRequirementStart: ageParams?.[0]?.ageRequirementStart ?? '',
        ageRequirementEnd: ageParams?.[0]?.ageRequirementEnd ?? '',
        scheduledDistributionNotes: sd.notes || '',
      },
    });
  });
  return {
    _hasScheduledDistributions: true,
    scheduledDistributions: scheduledDistributionsPartial,
  };
}

function hasAgeParameters(
  params: BeneficiaryAgeParametersFragment | null | undefined
): boolean {
  if (params) {
    if (params.ageRequirementStart !== 0 || params.ageRequirementEnd !== 0) {
      return true;
    }
  }
  return false;
}

function ageParametersToBeneficiaryFormAccessParameterAgeParams(
  params: BeneficiaryAgeParametersFragment
): BeneficiaryFormAccessParameterAgeParam[] {
  let kind = '';
  if (params.ageRequirementStart !== 0 && params.ageRequirementEnd !== 0) {
    kind = 'between';
  } else if (params.ageRequirementStart !== 0) {
    kind = 'upon';
  } else if (params.ageRequirementEnd !== 0) {
    kind = 'until';
  }
  return [
    {
      _ageRequirementKind: kind as AgeRequirementKind,
      ageRequirementStart: params.ageRequirementStart?.toString() ?? '',
      ageRequirementEnd: params.ageRequirementEnd?.toString() ?? '',
      notes: params.notes ?? '',
    },
  ];
}

function powerOfAppointmentToBeneficiaryFormPowerOfAppointment(
  powerOfAppointment: BeneficiaryPowerOfAppointmentPower | null | undefined,
  powerOfAppointmentNotes: string | null | undefined
): BeneficiaryFormPowerOfAppointment {
  if (!powerOfAppointment) {
    return {
      _hasPowerOfAppointment: false,
    };
  }
  return {
    _hasPowerOfAppointment: true,
    power: powerOfAppointment,
    powerOtherNote: powerOfAppointmentNotes || '',
  };
}
