import { useCallback } from 'react';

import {
  TrackUserEventModule,
  useTrackUserEvent,
} from '@/hooks/useTrackUserEvent';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';

/**
 * Hook to track user events related to async job notifications, setting the
 * appropriate tracking props based on the notification type.
 */
export function useTrackAsyncJobNotification(): {
  trackAsyncJobNotificationClick: (
    notification: AsyncJobNotificationInfo
  ) => void;
} {
  const trackUserEvent = useTrackUserEvent();

  const trackAsyncJobNotificationClick = useCallback(
    (notification: AsyncJobNotificationInfo) => {
      const userEventModule: TrackUserEventModule =
        getUserEventModule(notification);
      trackUserEvent(`${userEventModule} review button clicked`, {
        source: 'notification-item',
        kgRootId: notification.id,
        householdId: notification.household?.id,
      });
    },
    [trackUserEvent]
  );

  return { trackAsyncJobNotificationClick };
}

const getUserEventModule = (
  notification: AsyncJobNotificationInfo
): TrackUserEventModule => {
  switch (notification.type) {
    case AsyncJobNotificationInfoType.AI_ONBOARDING:
      return TrackUserEventModule.AI_ONBOARDING;
    case AsyncJobNotificationInfoType.AI_DP:
      return TrackUserEventModule.AI_DP;
  }
};
