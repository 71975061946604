import { NodeChange, NodePositionChange } from '@xyflow/react';

import { Node, TileNode } from '@/components/diagrams/FlowChart';

import { EntityDiagramActionFn, NodesChangedAction } from '../types';

/**
 * @description Sets the `isNewTile` flag to false for nodes that are in the graveyard and have been dragged
 */
function removeNodesFromGraveyard(changes: NodeChange[], nodes: Node[]) {
  const positionChangesByNodeId = changes.reduce(
    (acc, change) => {
      if (change.type !== 'position') return acc;
      acc[change.id] = change;
      return acc;
    },
    {} as Record<string, NodePositionChange>
  );

  return nodes.map((n) => {
    const change = positionChangesByNodeId[n.id];
    if (!change) return n;
    const draggedNode = n;
    return {
      ...draggedNode,
      data: { ...draggedNode.data, isNewTile: false },
    } as TileNode;
  });
}

export const nodesChangedAction: EntityDiagramActionFn<NodesChangedAction> = (
  state,
  { nodes, changes },
  _queueEffect
) => {
  const newNodes = removeNodesFromGraveyard(changes, nodes);

  return {
    ...state,
    nodes: newNodes,
  };
};
