import { compact } from 'lodash';
import { useMemo } from 'react';

import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { useFormContext } from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { useSaleLoanFormContext } from '../Form/context/SaleLoanForm.context';

export function SaleLoanIllustrationScenarioSelector() {
  const { waterfall } = useSaleLoanFormContext();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const firstGrantorToDie = primaryClients?.find(
    (client) => client.id === waterfall?.firstGrantorDeath.id
  );
  const secondGrantorToDie = primaryClients?.find(
    (client) => client.id !== firstGrantorToDie?.id
  );
  const firstGrantorToDieName = firstGrantorToDie?.firstName ?? 'first client';
  const secondGrantorToDieName =
    secondGrantorToDie?.firstName ?? 'second client';

  const scenarioOptions = useMemo<ButtonGroupInputOption<AfterDeath>[]>(() => {
    return compact([
      {
        value: AfterDeath.None,
        display: `Before ${firstGrantorToDieName}'s death (${waterfall?.firstGrantorDeathYear})`,
      },
      {
        value: AfterDeath.First,
        display: `After ${firstGrantorToDieName}'s death (${waterfall?.firstGrantorDeathYear})`,
      },
      isTwoClientHousehold && {
        value: AfterDeath.Second,
        display: `After ${secondGrantorToDieName}'s death (${waterfall?.secondGrantorDeathYear})`,
      },
    ]);
  }, [
    firstGrantorToDieName,
    isTwoClientHousehold,
    secondGrantorToDieName,
    waterfall?.firstGrantorDeathYear,
    waterfall?.secondGrantorDeathYear,
  ]);

  return (
    <FormAwareSelectInput
      control={control}
      options={scenarioOptions}
      fieldName={
        'illustrationScenario' as const satisfies HypotheticalSaleLoanFormPaths
      }
      label="Year of illustration"
      hideLabel
    />
  );
}
