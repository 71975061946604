import { Box, Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { EditableHeader } from '@/components/form/EditableHeader/EditableHeader';
import { FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFormContext } from '@/components/react-hook-form';
import { THEME_SHADOWS } from '@/styles/themes/common';
import { COLORS } from '@/styles/tokens/colors';

import { HypotheticalSaleLoanFormShape } from './EstateWaterfallHypotheticalSaleLoanModal.types';

interface LayoutWithSidePanelProps {
  MainContent: React.ReactElement;
  SidebarContent: React.ReactElement;
  Footer: React.ReactElement;
}

export function LayoutWithSidePanel({
  MainContent,
  SidebarContent,
  Footer,
}: LayoutWithSidePanelProps) {
  const { setValue, control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const headerValue = useWatch({ control, name: 'displayName' });

  return (
    <Box>
      <Stack height={`calc(100vh - ${FOOTER_HEIGHT}px)`} overflow="auto">
        <Stack direction="row" width="100%" height="100%">
          <Box
            width="35%"
            sx={{
              backgroundColor: 'white',
              boxShadow: THEME_SHADOWS.lg,
            }}
          >
            <Stack
              sx={{
                p: 3,
              }}
            >
              <EditableHeader
                value={headerValue}
                onChange={(value) => {
                  setValue('displayName', value);
                }}
              />
            </Stack>
            {SidebarContent}
          </Box>
          <Box
            p={3}
            width="65%"
            overflow="auto"
            sx={{
              backgroundColor: COLORS.GRAY[50],
              boxShadow: THEME_SHADOWS.innerShadowRightward,
            }}
          >
            <Box>{MainContent}</Box>
          </Box>
        </Stack>
      </Stack>
      <Box>{Footer}</Box>
    </Box>
  );
}
