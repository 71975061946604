import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { find } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';

import { HypotheticalSaleLoanFormShape } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import { SaleLoanIllustration_EstateWaterfallFragment } from '../graphql/SaleLoanIllustration.generated';
import { PROJECTION_PSEUDO_ID } from '../SaleLoanIllustrationContent.utils';

export interface SaleLoanIllustrationPaymentScheduleProps {
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null;
}

export interface PaymentScheduleRow {
  year: number;
  notePayableAtYearStart: Decimal;
  noteInterest: Decimal;
  notePrincipal: Decimal;
  notePayableAtYearEnd: Decimal;
}

interface GetPaymentScheduleParams {
  termLength: number | null;
}

function getPaymentSchedule(
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null,
  { termLength }: GetPaymentScheduleParams
): PaymentScheduleRow[] {
  if (!waterfall || !termLength) {
    return [];
  }

  const loan = find(
    waterfall.visualizationWithSaleLoanProjections.loans,
    (l) => l.pseudoID === PROJECTION_PSEUDO_ID
  );

  if (!loan) {
    diagnostics.error(
      'Projected loan not found in waterfall',
      new Error('Projected loan not found in waterfall')
    );
    return [];
  }

  return (
    loan.valueHistory
      .map((vh) => ({
        year: vh.year,
        notePayableAtYearStart: vh.beginningOfYearValue ?? new Decimal(0),
        noteInterest: vh.interestPaid ?? new Decimal(0),
        notePrincipal:
          vh.principalPaid?.times(new Decimal(-1)) ?? new Decimal(0),
        notePayableAtYearEnd: vh.endOfYearValue ?? new Decimal(0),
      }))
      // need to limit to term length because the waterfall
      // gives a value for every year up until second death
      .slice(0, termLength + 1)
  );
}

export function SaleLoanIllustrationPaymentSchedule({
  waterfall,
}: SaleLoanIllustrationPaymentScheduleProps) {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const termLength = useWatch({
    control,
    name: 'termLength',
  });

  const paymentSchedule = useMemo(
    () =>
      getPaymentSchedule(waterfall, {
        termLength,
      }),
    [termLength, waterfall]
  );

  return (
    <DisplayTable
      columns={[
        { headerName: 'Year', width: '10%' },
        {
          headerName: 'Note payable at year start',
          width: '22.5%',
          align: 'right',
        },
        { headerName: 'Note interest', width: '22.5%', align: 'right' },
        { headerName: 'Note principal', width: '22.5%', align: 'right' },
        {
          headerName: 'Note payable at year end',
          width: '22.5%',
          align: 'right',
        },
      ]}
    >
      {paymentSchedule.map((row) => (
        <StyledTableRow key={row.year}>
          <StyledTableCell>
            <Typography>{row.year}</Typography>
          </StyledTableCell>
          <StyledTableCell align="right">
            <Typography>
              {formatCurrency(row.notePayableAtYearStart)}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="right">
            <Typography>{formatCurrency(row.noteInterest)}</Typography>
          </StyledTableCell>
          <StyledTableCell align="right">
            <Typography
              color={row.notePrincipal.lt(0) ? 'error.main' : undefined}
            >
              {formatCurrency(row.notePrincipal, {
                currencySign: 'accounting',
              })}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="right">
            <Typography>{formatCurrency(row.notePayableAtYearEnd)}</Typography>
          </StyledTableCell>
        </StyledTableRow>
      ))}
      {paymentSchedule.length === 0 && (
        <StyledTableRow>
          <StyledTableCell colSpan={5}>
            <Typography>
              Schedule will generate when all required fields are specified
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </DisplayTable>
  );
}
