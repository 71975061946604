import { Box, BoxProps, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { ComponentType, useMemo } from 'react';

import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { TaxOverviewTable } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable';
import { TaxOverviewTableRowData } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable.types';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useRegisterSlide } from '../clientPresentation/hooks/useRegisterSlide';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PresentationSlideRegistrationDetails } from '../entities/entityPresentations.types';
import { PresentationSlide } from '../PresentationSlide';
import { getTaxSummaryTablePages } from './EstateWaterfall.TaxSummary.utils';

interface EstateWaterfallTaxTableSlideProps {
  afterDeath: AfterDeath;
  pageRows: TaxOverviewTableRowData[];
  index: number;
  registrationProps: PresentationSlideRegistrationDetails;
  SlideWrapper: ComponentType<BoxProps>;
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
  federalEstateTaxPercent: Decimal | null | undefined;
  deathYear: number | undefined | null;
  dyingGrantorName: string;
  tableId: string;
  isLastPage: boolean;
  isContinued: boolean;
}

function EstateWaterfallTaxTableSlide({
  afterDeath,
  pageRows,
  registrationProps,
  index,
  SlideWrapper,
  waterfall,
  deathYear,
  dyingGrantorName,
  tableId,
  isLastPage,
  isContinued,
}: EstateWaterfallTaxTableSlideProps) {
  const rightHeaderContent = `${afterDeath === AfterDeath.None ? 'Before' : 'After'} ${dyingGrantorName}'s death${index > 0 ? ' (cont.)' : ''}`;
  const rows = useMemo(
    () =>
      pageRows.map((pageRow, index) => {
        // if a subsection breaks across pages, add the `(cont.)` suffix to the first row of the next page
        if (isContinued && index === 0) {
          return {
            ...pageRow,
            name: {
              ...pageRow.name,
              lineOne: `${pageRow.name.lineOne} (cont.)`,
            },
          };
        }
        return pageRow;
      }),
    [isContinued, pageRows]
  );
  const tableHeaders = useWaterfallGrantorDeathDisplay(waterfall);
  return (
    <SlideWrapper key={tableId}>
      <PresentationSlide.Slide
        id={registrationProps.slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={waterfall.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<PresentationSlide.Footer />}
      >
        <Stack px={3} spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2" component="span">
              Tax Overview
            </Typography>
            <Typography
              variant="h2"
              component="span"
              sx={{ color: COLORS.GRAY[400] }}
            >
              {rightHeaderContent}
            </Typography>
          </Stack>
          <TaxOverviewTable
            afterDeath={afterDeath}
            rows={rows}
            deathYear={deathYear}
            isTwoGrantorHousehold={false}
            waterfall={waterfall}
            headerCopy={tableHeaders[afterDeath]}
          />
          {!isLastPage && (
            <Stack direction="row" width="100%" justifyContent="flex-end">
              <Typography variant="subtitle2">
                Continued on next page
              </Typography>
            </Stack>
          )}
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export interface EstateWaterfallTaxSummarySlideProps
  extends PresentationSlideRegistrationDetails {
  SlideWrapper?: ComponentType<BoxProps>;
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
  federalEstateTaxPercent: Decimal | null | undefined;
}
export function EstateWaterfallTaxSummarySlideInner({
  waterfall,
  federalEstateTaxPercent,
  SlideWrapper = Box,
  ...registrationProps
}: EstateWaterfallTaxSummarySlideProps) {
  useRegisterSlide({
    displayName: 'Tax Overview',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const {
    beforeFirstDeathPages,
    afterFirstDeathPages,
    afterSecondDeathPages,
    beforeFirstDeathContinuedPageIndices,
    afterFirstDeathContinuedPageIndices,
    afterSecondDeathContinuedPageIndices,
  } = useMemo(() => {
    return getTaxSummaryTablePages(waterfall, federalEstateTaxPercent);
  }, [federalEstateTaxPercent, waterfall]);

  const firstGrantorDeathName =
    waterfall.household?.possiblePrimaryClients.find(
      (client) => client.id === waterfall.firstGrantorDeath.id
    )?.displayName || 'First';

  const secondGrantorDeathName =
    waterfall.household?.possiblePrimaryClients.find(
      (client) => client.id !== waterfall.firstGrantorDeath.id
    )?.displayName || 'Second';

  return (
    <>
      {beforeFirstDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxTableSlide
          key={`before-first-death-${index}`}
          tableId={`before-first-death-${index}`}
          afterDeath={AfterDeath.None}
          pageRows={pageRows}
          index={index}
          registrationProps={registrationProps}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.firstGrantorDeathYear}
          dyingGrantorName={firstGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === beforeFirstDeathPages.length - 1}
          isContinued={beforeFirstDeathContinuedPageIndices.includes(index)}
        />
      ))}
      {afterFirstDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxTableSlide
          key={`after-first-death-${index}`}
          tableId={`after-first-death-${index}`}
          afterDeath={AfterDeath.First}
          pageRows={pageRows}
          index={index}
          registrationProps={registrationProps}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.firstGrantorDeathYear}
          dyingGrantorName={firstGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === afterFirstDeathPages.length - 1}
          isContinued={afterFirstDeathContinuedPageIndices.includes(index)}
        />
      ))}
      {afterSecondDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxTableSlide
          key={`after-second-death-${index}`}
          tableId={`after-second-death-${index}`}
          afterDeath={AfterDeath.Second}
          pageRows={pageRows}
          index={index}
          registrationProps={registrationProps}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.secondGrantorDeathYear}
          dyingGrantorName={secondGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === afterSecondDeathPages.length - 1}
          isContinued={afterSecondDeathContinuedPageIndices.includes(index)}
        />
      ))}
    </>
  );
}

export function EstateWaterfallTaxSummarySlide(
  props: EstateWaterfallTaxSummarySlideProps
) {
  return <EstateWaterfallTaxSummarySlideInner {...props} />;
}
