import { AiOnboarding_KgRootFragment } from '@/modules/aiOnboarding/graphql/aiOnboarding.generated';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { mapKGRootStatusToNotificationStatus } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.utils';
import { AsyncJobStatus } from '@/types/schema';
import { pluralize } from '@/utils/formatting/strings';

/**
 * Maps information about an AIOnboarding job to an AsyncJobNotificationInfo
 * @param input
 */
export function aiOnboardingNotificationMapper( // TODO: UNIT TEST THIS!
  input: AiOnboarding_KgRootFragment
): AsyncJobNotificationInfo | null {
  if (!input.asyncJob) {
    throw new Error('KGRoot must have an asyncJob');
  }

  if (
    input.asyncJob.status === AsyncJobStatus.Cancelled ||
    input.asyncJob.status === AsyncJobStatus.Failed
  ) {
    // We don't want to show notifications for failed or cancelled jobs
    return null;
  }

  const numDocs = input.files?.length ?? 0;
  const docPlural = pluralize(numDocs, 'document');

  return {
    id: input.id, // This will be the KGRoot ID
    type: AsyncJobNotificationInfoType.AI_ONBOARDING,
    status: mapKGRootStatusToNotificationStatus(input.status),
    estimatedDurationMs: 2 * 60 * 1000, // estimate
    household: input.household,
    asyncJob: input.asyncJob,
    newNotificationMessage: `Processing ${numDocs} ${docPlural}`,
    completedNotificationMessage: `Completed processing of ${numDocs} ${docPlural}`,
    formatBannerMessage: (bannerNoun: string) =>
      `Luminary AI finished analyzing ${bannerNoun} from ${numDocs} ${docPlural}`,
    notificationTargetDisplayName: input.household.displayName,
  };
}
