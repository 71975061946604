import { Box, Link, Stack, Typography } from '@mui/material';
import { compact, isUndefined, keys } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { Button } from '@/components/form/baseInputs/Button';
import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { Share04Icon } from '@/components/icons/Share04Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { getDisplayNamesForDeaths } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { diagnostics } from '@/utils/diagnostics';

import {
  ClientPresentationDesignerV2ContextShape,
  useClientPresentationDesignerV2Context,
} from '../../ClientPresentationDesignerV2.context';
import { getNewBundleId } from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2ModalUpdateType } from '../../ClientPresentationV2ModalContext';
import { ClientPresentationV2Bundle } from '../../types/ClientPresentationV2.PresentationBundleType';
import { updatePageArrayForPageInclusion } from './settingsModals.utils';

function getDefaultValues(
  bundle: ClientPresentationV2Bundle | null | undefined,
  estateWaterfallOptionsMap: ClientPresentationDesignerV2ContextShape['estateWaterfallOptionsMap'],
  isTwoClientHousehold: boolean
): WaterfallOverviewBundleSettingsFormShape {
  if (!bundle) {
    return {
      bundle: {
        type: PresentationBundleKind.WaterfallOverviewBundle,
        waterfallOverviewBundleConfiguration: {
          includeAssumptionsPage: false,
          includeBeneficiaryPage: true,
          includeDiagramPage: true,
          includeSummaryPage: true,
          includeTaxOverviewPage: true,
          firstDeathYear: new Date().getFullYear(),
          secondDeathYear: null,
          growthProfileId: null,
          estateWaterfallId: null,
        },
        id: getNewBundleId(PresentationBundleKind.WaterfallOverviewBundle),
        displayName: '',
        pages: [],
      },
      shouldCreateMore: false,
      customizeWaterfallName: false,
      useWaterfallAssumptions: false,
    };
  }

  const relevantWaterfall =
    estateWaterfallOptionsMap[
      bundle.waterfallOverviewBundleConfiguration?.estateWaterfallId || ''
    ];

  let useWaterfallAssumptions = false;

  // TODO this will need to be reworked once T1-2668 is implemented
  // https://linear.app/luminary/issue/T1-2668/add-a-usewaterfallassumptions-boolean-to-waterfallbundleconfiguration
  if (relevantWaterfall) {
    const hasSameGrowthProfileId =
      bundle?.waterfallOverviewBundleConfiguration?.growthProfileId ===
      relevantWaterfall?.growthProfile?.id;

    const hasSameFirstDeathYear =
      bundle?.waterfallOverviewBundleConfiguration?.firstDeathYear ===
      relevantWaterfall?.firstGrantorDeathYear;

    const hasSameSecondDeathYear =
      bundle?.waterfallOverviewBundleConfiguration?.secondDeathYear ===
      relevantWaterfall?.secondGrantorDeathYear;

    useWaterfallAssumptions =
      hasSameGrowthProfileId &&
      hasSameFirstDeathYear &&
      (isTwoClientHousehold ? hasSameSecondDeathYear : true);
  }

  return {
    bundle,
    shouldCreateMore: false,
    customizeWaterfallName: false,
    useWaterfallAssumptions,
  };
}

function useWaterfallOverviewBundleOptions(): {
  growthProfileOptions: TypeaheadSelectInputOption<string>[];
  estateWaterfallOptions: TypeaheadSelectInputOption<string>[];
} {
  const { growthProfileMap, estateWaterfallOptionsMap } =
    useClientPresentationDesignerV2Context();
  const output = useMemo(() => {
    const growthProfileOptions = compact(
      keys(growthProfileMap).map<TypeaheadSelectInputOption<string> | null>(
        (growthProfileId: string) =>
          growthProfileMap[growthProfileId]
            ? {
                display: growthProfileMap[growthProfileId]?.displayName ?? '',
                value: growthProfileId,
              }
            : null
      )
    ).sort(({ display: aDisplay }, { display: bDisplay }) =>
      aDisplay.localeCompare(bDisplay)
    );

    const estateWaterfallOptions = compact(
      keys(
        estateWaterfallOptionsMap
      ).map<TypeaheadSelectInputOption<string> | null>(
        (estateWaterfallId: string) =>
          estateWaterfallOptionsMap[estateWaterfallId]
            ? {
                display:
                  estateWaterfallOptionsMap[estateWaterfallId]?.displayName ??
                  '',
                value: estateWaterfallId,
              }
            : null
      )
    ).sort(({ display: aDisplay }, { display: bDisplay }) =>
      aDisplay.localeCompare(bDisplay)
    );

    return { growthProfileOptions, estateWaterfallOptions };
  }, [estateWaterfallOptionsMap, growthProfileMap]);
  return output;
}

function WaterfallOverviewBundleSettingsModalInner({
  modificationType,
}: {
  modificationType: ClientPresentationV2ModalUpdateType;
}) {
  const { householdId, estateWaterfallOptionsMap } =
    useClientPresentationDesignerV2Context();
  const { isTwoClientHousehold, primaryClients } = useHouseholdDetailsContext();
  const { control, setValue } =
    useFormContext<WaterfallOverviewBundleSettingsFormShape>();

  const [customizeWaterfallName, estateWaterfallId, useWaterfallAssumptions] =
    useWatch({
      control,
      name: [
        'customizeWaterfallName',
        'bundle.waterfallOverviewBundleConfiguration.estateWaterfallId',
        'useWaterfallAssumptions',
      ],
    });

  const previousEstateWaterfallId = usePrevious(estateWaterfallId);

  const { growthProfileOptions, estateWaterfallOptions } =
    useWaterfallOverviewBundleOptions();

  const { firstNameDisplay, secondNameDisplay, linkedWaterfallName } =
    useMemo(() => {
      const relevantWaterfall = estateWaterfallId
        ? estateWaterfallOptionsMap[estateWaterfallId]
        : null;

      if (!relevantWaterfall?.firstGrantorDeath?.id) {
        return {
          firstNameDisplay: 'First',
          secondNameDisplay: 'Second',
        };
      }

      const { firstNameDisplay, secondNameDisplay } = getDisplayNamesForDeaths({
        isTwoClientHousehold,
        primaryClients,
        firstDyingGrantorId: relevantWaterfall.firstGrantorDeath.id,
      });

      return {
        firstNameDisplay,
        secondNameDisplay,
        linkedWaterfallName: relevantWaterfall.displayName,
      };
    }, [
      estateWaterfallId,
      estateWaterfallOptionsMap,
      isTwoClientHousehold,
      primaryClients,
    ]);

  useEffect(() => {
    if (
      isUndefined(previousEstateWaterfallId) ||
      !estateWaterfallId ||
      estateWaterfallId === previousEstateWaterfallId
    ) {
      return;
    }

    const newWaterfall = estateWaterfallOptionsMap[estateWaterfallId];

    if (newWaterfall?.displayName) {
      setValue('bundle.displayName', newWaterfall.displayName, {
        shouldDirty: false,
      });
    }

    if (newWaterfall?.firstGrantorDeathYear) {
      setValue(
        'bundle.waterfallOverviewBundleConfiguration.firstDeathYear',
        newWaterfall.firstGrantorDeathYear,
        { shouldDirty: false }
      );
    }

    if (newWaterfall?.secondGrantorDeathYear && isTwoClientHousehold) {
      setValue(
        'bundle.waterfallOverviewBundleConfiguration.secondDeathYear',
        newWaterfall.secondGrantorDeathYear,
        { shouldDirty: false }
      );
    }

    if (newWaterfall?.growthProfile?.id) {
      setValue(
        'bundle.waterfallOverviewBundleConfiguration.growthProfileId',
        newWaterfall.growthProfile.id,
        { shouldDirty: false }
      );
    }
  }, [
    estateWaterfallId,
    estateWaterfallOptionsMap,
    isTwoClientHousehold,
    previousEstateWaterfallId,
    setValue,
  ]);

  let waterfallDetailsBlock = null;
  if (modificationType === 'create') {
    waterfallDetailsBlock = (
      <Stack width="100%" spacing={1}>
        <Box>
          <FormAwareTypeaheadSelectInput<WaterfallOverviewBundleSettingsFormShape>
            fieldName={
              'bundle.waterfallOverviewBundleConfiguration.estateWaterfallId' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
            }
            label="Waterfall"
            options={estateWaterfallOptions}
            control={control}
            required
            emptyOptionDisplay="Select a waterfall"
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flex: '1 1 50%' }}>
            <FormAwareSwitch<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'customizeWaterfallName' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Customize waterfall name"
              control={control}
              labelPosition="right"
            />
          </Box>
          <Box sx={{ flex: '1 1 50%' }}>
            <FormAwareTextInput<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'bundle.displayName' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Waterfall display name"
              control={control}
              hideLabel
              disabled={!customizeWaterfallName}
              required={customizeWaterfallName}
            />
          </Box>
        </Stack>
      </Stack>
    );
  } else {
    waterfallDetailsBlock = (
      <FormAwareTextInput<WaterfallOverviewBundleSettingsFormShape>
        fieldName={
          'bundle.displayName' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
        }
        label="Waterfall name"
        control={control}
        required
      />
    );
  }

  let waterfallLinkCallout = null;
  if (
    modificationType !== 'create' &&
    linkedWaterfallName &&
    estateWaterfallId
  ) {
    // using a href here because we want to open a new tab
    const route = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
      {
        householdId,
        waterfallId: estateWaterfallId,
      }
    );
    waterfallLinkCallout = (
      <Callout severity="info-high">
        <Stack spacing={0.5}>
          <Typography variant="subtitle2" color="text.secondary">
            Based on the source waterfall &quot;{linkedWaterfallName}&quot;.
          </Typography>
          <Link href={route} target="_blank" rel="noopener noreferrer">
            <Typography variant="subtitle2" color="text.secondary">
              Open source in new tab&nbsp;
              <Share04Icon size={12} />
            </Typography>
          </Link>
        </Stack>
      </Callout>
    );
  }

  return (
    <Stack spacing={3}>
      {waterfallLinkCallout}
      {waterfallDetailsBlock}
      <Card variant="filled" sx={{ p: 3, pb: 4 }}>
        <Stack spacing={2}>
          <Typography variant="h4">Assumptions</Typography>
          <FormAwareTypeaheadSelectInput<WaterfallOverviewBundleSettingsFormShape>
            fieldName={
              'bundle.waterfallOverviewBundleConfiguration.growthProfileId' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
            }
            label="Growth profile"
            disabled={useWaterfallAssumptions}
            options={growthProfileOptions}
            control={control}
            required
            emptyOptionDisplay="Select a growth profile"
          />
          <Stack direction="row" spacing={3} alignItems="center">
            <Box
              sx={{
                flex: isTwoClientHousehold ? '1 1 50%' : '1 1 100%',
              }}
            >
              <FormAwareFormattedNumberInput<WaterfallOverviewBundleSettingsFormShape>
                fieldName={
                  'bundle.waterfallOverviewBundleConfiguration.firstDeathYear' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
                }
                label={`${firstNameDisplay} death year`}
                control={control}
                required
                disabled={useWaterfallAssumptions}
              />
            </Box>
            {isTwoClientHousehold && (
              <Box sx={{ flex: '1 1 50%' }}>
                <FormAwareFormattedNumberInput<WaterfallOverviewBundleSettingsFormShape>
                  fieldName={
                    'bundle.waterfallOverviewBundleConfiguration.secondDeathYear' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
                  }
                  label={`${secondNameDisplay} death year`}
                  control={control}
                  required
                  disabled={useWaterfallAssumptions}
                />
              </Box>
            )}
          </Stack>
          <FormAwareSwitch<WaterfallOverviewBundleSettingsFormShape>
            fieldName={
              'useWaterfallAssumptions' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
            }
            label="Use assumptions from waterfall"
            control={control}
            labelPosition="right"
          />
        </Stack>
      </Card>

      <Card variant="filled" sx={{ p: 3, pb: 4 }}>
        <Stack spacing={2}>
          <Typography variant="h4">Pages to include</Typography>
          <Stack>
            <FormAwareCheckbox<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'bundle.waterfallOverviewBundleConfiguration.includeSummaryPage' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Summary"
              control={control}
            />
            <FormAwareCheckbox<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'bundle.waterfallOverviewBundleConfiguration.includeDiagramPage' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Diagram"
              control={control}
            />
            <FormAwareCheckbox<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'bundle.waterfallOverviewBundleConfiguration.includeBeneficiaryPage' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Beneficiaries"
              control={control}
            />
            <FormAwareCheckbox<WaterfallOverviewBundleSettingsFormShape>
              fieldName={
                'bundle.waterfallOverviewBundleConfiguration.includeTaxOverviewPage' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
              }
              label="Tax overview"
              control={control}
            />
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}

interface WaterfallOverviewBundleSettingsFormShape {
  bundle: ClientPresentationV2Bundle;
  shouldCreateMore: boolean;
  customizeWaterfallName: boolean;
  useWaterfallAssumptions: boolean;
}

export interface WaterfallOverviewBundleSettingsModalProps {
  onClose: () => void;
  onSave: (data: ClientPresentationV2Bundle, shouldCreateMore: boolean) => void;
  modificationType: ClientPresentationV2ModalUpdateType;
  bundle?: ClientPresentationV2Bundle;
}

export function WaterfallOverviewBundleSettingsModal({
  onClose,
  onSave,
  modificationType,
  bundle,
}: WaterfallOverviewBundleSettingsModalProps) {
  const { estateWaterfallOptionsMap } =
    useClientPresentationDesignerV2Context();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const isCreating = !bundle;

  const modalForm = useForm<WaterfallOverviewBundleSettingsFormShape>({
    defaultValues: getDefaultValues(
      bundle,
      estateWaterfallOptionsMap,
      isTwoClientHousehold
    ),
  });

  const { handleSubmit, control } = modalForm;

  const onSubmit = handleSubmit(
    ({ bundle: updatedBundle, shouldCreateMore, useWaterfallAssumptions }) => {
      if (!updatedBundle.waterfallOverviewBundleConfiguration) {
        // bad state -- this should always exist
        diagnostics.error(
          `No waterfall configuration found for ${updatedBundle.id}`
        );
        return;
      }

      if (useWaterfallAssumptions) {
        updatedBundle.waterfallOverviewBundleConfiguration.firstDeathYear = 0;
        updatedBundle.waterfallOverviewBundleConfiguration.secondDeathYear =
          null;
        updatedBundle.waterfallOverviewBundleConfiguration.growthProfileId =
          null;
      }

      // doing it a page at a time because
      // - preserves the order of the existing pages
      // - appends the new pages at the end
      let newPages = updatedBundle.pages.slice();
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.WaterfallSummaryPage,
        updatedBundle.waterfallOverviewBundleConfiguration.includeSummaryPage
      );
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.WaterfallDiagramPage,
        updatedBundle.waterfallOverviewBundleConfiguration.includeDiagramPage
      );
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.WaterfallTaxOverviewPage,
        updatedBundle.waterfallOverviewBundleConfiguration
          .includeTaxOverviewPage
      );
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.WaterfallAssumptionsPage,
        updatedBundle.waterfallOverviewBundleConfiguration
          .includeAssumptionsPage
      );
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.WaterfallBeneficiariesPage,
        updatedBundle.waterfallOverviewBundleConfiguration
          .includeBeneficiaryPage
      );

      if (modificationType === 'duplicate') {
        updatedBundle.id = getNewBundleId(
          PresentationBundleKind.WaterfallOverviewBundle
        );
      }

      onSave(
        {
          ...updatedBundle,
          pages: newPages,
        },
        shouldCreateMore
      );
      onClose();
    }
  );

  const [modalHeader, primaryButtonLabel] = useMemo(() => {
    if (modificationType === 'duplicate') {
      return ['Duplicate waterfall', 'Duplicate waterfall'];
    } else if (!isCreating) {
      return ['Manage waterfall', 'Save changes'];
    }
    return ['Add waterfall', 'Add waterfall'];
  }, [modificationType, isCreating]);

  return (
    <FormProvider {...modalForm}>
      <FormModal
        isOpen
        heading={modalHeader}
        onClose={onClose}
        actions={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {isCreating ? (
              <FormAwareSwitch<WaterfallOverviewBundleSettingsFormShape>
                fieldName={
                  'shouldCreateMore' as const satisfies PathsOf<WaterfallOverviewBundleSettingsFormShape>
                }
                label="Add additional waterfalls"
                control={control}
                labelPosition="right"
              />
            ) : (
              <>&nbsp;</> // blank for spacing
            )}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="secondary" size="sm" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" onClick={onSubmit}>
                {primaryButtonLabel}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <WaterfallOverviewBundleSettingsModalInner
          modificationType={modificationType}
        />
      </FormModal>
    </FormProvider>
  );
}
