import { Stack, Typography } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { GridPagination } from '@mui/x-data-grid-pro';
import { FunctionComponent } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';

const ActionsComponent: FunctionComponent<TablePaginationActionsProps> = ({
  page,
  onPageChange,
  rowsPerPage,
  count,
}) => {
  const havePreviousPage = page > 0;
  const totalPossiblePages = Math.ceil(count / rowsPerPage) - 1;
  const haveNextPage = page < totalPossiblePages;
  return (
    <Stack spacing={2} direction="row" flexWrap="nowrap">
      <IconButton
        variant="secondary"
        size="sm"
        icon={ArrowLeftIcon}
        disabled={!havePreviousPage}
        onClick={(event) => {
          onPageChange(event, page - 1);
        }}
        ariaLabel="Previous page"
      />
      <IconButton
        variant="secondary"
        size="sm"
        icon={ArrowRightIcon}
        disabled={!haveNextPage}
        onClick={(event) => onPageChange(event, page + 1)}
        ariaLabel="Next page"
      />
    </Stack>
  );
};

export function DefaultPaginatedTableFooter() {
  return (
    <GridPagination
      component="div" // defaults to <p>, which throws a validation error on Typography subtitle1, which uses <h6>
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        pt: 2,
      }}
      labelDisplayedRows={({ from, to, count }) => (
        <Typography variant="subtitle1" component="span">
          Displaying {from}-{to} of {count}
        </Typography>
      )}
      ActionsComponent={ActionsComponent}
    />
  );
}
