import { compose } from '@/utils/reducerUtils';

import { applyDerivedDataToState, applyHighlightIdsToState } from '../state';
import { EntityDiagramReducer } from '../types';

export const entityDiagramStateTransformReducer: EntityDiagramReducer = (
  state,
  _action,
  _queueEffect
) => {
  return compose(applyHighlightIdsToState, applyDerivedDataToState)(state);
};
