import { Stack, Typography } from '@mui/material';
import { chunk } from 'lodash';

import { keyPersonRoleDisplayNames } from '@/modules/entities/details/businessEntities/businessEntities.constants';
import { KeyPersonRoleKind } from '@/types/schema';

import { EntityDetailNameCard } from './EntityDetailNameCard';

const keyPeopleOrder: KeyPersonRoleKind[] = [
  KeyPersonRoleKind.Officer,
  KeyPersonRoleKind.President,
  KeyPersonRoleKind.VicePresident,
  KeyPersonRoleKind.BoardMember,
  KeyPersonRoleKind.Director,
  KeyPersonRoleKind.GeneralPartner,
  KeyPersonRoleKind.Manager,
  KeyPersonRoleKind.ControllingPerson,
  KeyPersonRoleKind.Secretary,
  KeyPersonRoleKind.Signatory,
  KeyPersonRoleKind.Other,
];

function getKeyPersonRoleKind(label: string): KeyPersonRoleKind | undefined {
  return Object.entries(keyPersonRoleDisplayNames).find(
    ([_, value]) => value === label
  )?.[0] as KeyPersonRoleKind | undefined;
}

interface KeyPeopleProps {
  keyPeople: {
    label: string;
    names: string[];
  }[];
  chunkSize?: number;
}

export function KeyPeople({ keyPeople, chunkSize = 4 }: KeyPeopleProps) {
  const orderedKeyPeople = keyPeople.sort((a, b) => {
    // Reverse lookup the KeyPersonRoleKind from the label
    const aRole = getKeyPersonRoleKind(a.label);
    const bRole = getKeyPersonRoleKind(b.label);

    const aIndex = aRole
      ? keyPeopleOrder.indexOf(aRole)
      : keyPeopleOrder.length;
    const bIndex = bRole
      ? keyPeopleOrder.indexOf(bRole)
      : keyPeopleOrder.length;
    return aIndex - bIndex;
  });

  const chunkedKeyPeople = chunk(orderedKeyPeople, chunkSize)?.[0] ?? [];
  const exceedsMax = keyPeople.length > chunkSize;

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Key people
      </Typography>
      {chunkedKeyPeople.map(({ label, names }) => (
        <EntityDetailNameCard
          key={label}
          leftText={label}
          rightText={names.join(', ')}
        />
      ))}
      {chunkedKeyPeople.length === 0 && (
        <Typography variant="subtitle2">None specified</Typography>
      )}
      {exceedsMax && (
        <Typography variant="subtitle2">
          {`+${keyPeople.length - chunkSize} more`}
        </Typography>
      )}
    </Stack>
  );
}
