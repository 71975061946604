import Decimal from 'decimal.js';
import { isUndefined } from 'lodash';

import { DEFAULT_ASSUMPTIONS } from '../EstateWaterfallAssumptions/constants';
import { EstateWaterfallAssumptionFields } from '../EstateWaterfallAssumptions/types';
import {
  COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH,
  COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH,
  COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET,
  COMPARE_VIEW_ASSUMPTION_FIRST_DEATH,
  COMPARE_VIEW_ASSUMPTION_SECOND_DEATH,
  COMPARE_VIEW_SEARCH_PARAM,
} from '../EstateWaterfallComparisonTrowserLegacy/WaterfallComparison.constants';

export function getAssumptionsFromSearchParams(
  searchParams: URLSearchParams
): EstateWaterfallAssumptionFields {
  const assetGrowthReturnParam = searchParams.get(
    COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH
  );

  const exemptionGrowthRateParam = searchParams.get(
    COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH
  );
  const willExemptionSunsetParam = searchParams.get(
    COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET
  );

  const exemptionGrowthRate = exemptionGrowthRateParam
    ? new Decimal(exemptionGrowthRateParam)
    : null;

  const assetGrowthReturn = assetGrowthReturnParam
    ? new Decimal(assetGrowthReturnParam)
    : null;

  return {
    firstGrantorDeathYear:
      parseInt(searchParams.get(COMPARE_VIEW_ASSUMPTION_FIRST_DEATH) || '') ||
      DEFAULT_ASSUMPTIONS.firstGrantorDeathYear,

    secondGrantorDeathYear:
      parseInt(searchParams.get(COMPARE_VIEW_ASSUMPTION_SECOND_DEATH) || '') ||
      DEFAULT_ASSUMPTIONS.secondGrantorDeathYear,

    assetGrowthReturn:
      assetGrowthReturn ?? DEFAULT_ASSUMPTIONS.assetGrowthReturn,

    exemptionGrowthRate:
      exemptionGrowthRate ?? DEFAULT_ASSUMPTIONS.exemptionGrowthRate,

    useCustomGlobalGrowthRate: Boolean(assetGrowthReturn),

    willExemptionSunset: willExemptionSunsetParam
      ? willExemptionSunsetParam === 'true'
      : DEFAULT_ASSUMPTIONS.willExemptionSunset,
  };
}

export function getWaterfallIdsFromSearchParams(
  searchParams: URLSearchParams
): string[] {
  return searchParams.get(COMPARE_VIEW_SEARCH_PARAM)?.split(',') || [];
}

export function getSearchParamsFromAssumptions(
  waterfallIds: string[],
  assumptions: EstateWaterfallAssumptionFields,
  initialSearchParams: URLSearchParams
): URLSearchParams {
  const searchParams = new URLSearchParams(initialSearchParams);
  const {
    firstGrantorDeathYear,
    secondGrantorDeathYear,
    assetGrowthReturn,
    exemptionGrowthRate,
    useCustomGlobalGrowthRate,
    willExemptionSunset,
  } = assumptions;

  searchParams.set(COMPARE_VIEW_SEARCH_PARAM, waterfallIds.join(','));

  if (firstGrantorDeathYear) {
    searchParams.set(
      COMPARE_VIEW_ASSUMPTION_FIRST_DEATH,
      firstGrantorDeathYear.toString()
    );
  }

  if (secondGrantorDeathYear) {
    searchParams.set(
      COMPARE_VIEW_ASSUMPTION_SECOND_DEATH,
      secondGrantorDeathYear.toString()
    );
  }

  if (assetGrowthReturn && useCustomGlobalGrowthRate) {
    searchParams.set(
      COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH,
      assetGrowthReturn.toString()
    );
  }

  if (exemptionGrowthRate) {
    searchParams.set(
      COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH,
      exemptionGrowthRate.toString()
    );
  }

  if (!isUndefined(willExemptionSunset)) {
    searchParams.set(
      COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET,
      willExemptionSunset ? 'true' : 'false'
    );
  }

  return searchParams;
}
