import { useMemo } from 'react';

import { useReportError } from '@/hooks/useReportError';
import {
  ClientPresentationOrderField,
  ClientPresentationV2OrderField,
  OrderDirection,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { RowData } from './ClientPresentationsTable.constants';
import {
  ClientPresentationsTable_ClientPresentationFragment,
  useClientPresentationsTableQuery,
} from './graphql/ClientPresentationsTable.generated';
import { ClientPresentationsV2Table_ClientPresentationFragment } from './graphql/ClientPresentationsTable.generated';

function mapPresentationsToRows(
  presentations:
    | ClientPresentationsTable_ClientPresentationFragment[]
    | ClientPresentationsV2Table_ClientPresentationFragment[],
  isV2Presentation: boolean
): RowData[] {
  return presentations.map((presentation) => ({
    id: presentation.id,
    // TODO: remove the "(V2)" suffix once client presentations v2 gets close to launch
    name: `${presentation.name} ${isV2Presentation ? '(V2)' : ''}`,
    lastModified: presentation.updatedAt,
    createdBy: presentation.createdBy,
    householdId: presentation.household?.id ?? '',
    isV2Presentation,
  }));
}

export function useClientPresentationsTableRows(
  householdId: string
): RowData[] {
  const { reportError } = useReportError();

  const where = {
    hasHouseholdWith: [
      {
        id: householdId,
      },
    ],
  };

  const { data } = useClientPresentationsTableQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where,
      whereV2: where,
      orderBy: [
        {
          field: ClientPresentationOrderField.UpdatedAt,
          direction: OrderDirection.Desc,
        },
      ],
      orderByV2: [
        {
          field: ClientPresentationV2OrderField.UpdatedAt,
          direction: OrderDirection.Desc,
        },
      ],
    },
    onError: (error) => {
      reportError('failed to load client presentations', error);
    },
  });

  const rows = useMemo(
    () =>
      [
        ...mapPresentationsToRows(
          getNodes(data?.clientPresentationV2s) ?? [],
          true
        ),
        ...mapPresentationsToRows(
          getNodes(data?.clientPresentations) ?? [],
          false
        ),
      ].sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime()),
    [data?.clientPresentationV2s, data?.clientPresentations]
  );

  return rows;
}
