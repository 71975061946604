import { isEmpty } from 'lodash';

import { useGetHouseholdPendingAidpkgRootQuery } from '@/modules/aiDP/graphql/aiDP.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getNodes } from '@/utils/graphqlUtils';

/**
 * This hook determines if the household that we're within the context of has
 * a pending "batch" AI DP workflow. If so, we don't want to show the trigger
 * banner on the Manage Dispositions page.
 */
export function useIsBatchAIDPWorkflowInProgress() {
  const { householdId } = useHouseholdDetailsContext();

  const queryProps = useGetHouseholdPendingAidpkgRootQuery({
    fetchPolicy: 'network-only',
    skip: !householdId,
    variables: {
      householdId: householdId!,
    },
  });

  return {
    isBatchAIDPWorkflowInProgress: !isEmpty(getNodes(queryProps.data?.kgRoots)),
    ...queryProps,
  };
}
