import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { orderBy } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { getDesignerAccountDetails } from '@/modules/assetValuation/entityValuationUtils';
import { getJurisdictionDisplay } from '@/modules/entities/utils/getJurisdictionDisplay';
import { TrustDetailsCard_GrantorPrincipalFragment } from '@/modules/trusts/TrustDetails/graphql/TrustDetailsCard.fragments.generated';
import { TrustTaxStatus } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityDetailPropsKind } from '../../components/EntityDetails';
import { getAssetLocationDisplay } from '../../utils/getAssetLocationDisplay';
import {
  EntityDetail_EntityFragment,
  EntityDetail_OwnerOfStakeFragment,
} from '../graphql/EntityDetailPage.generated';

const trustTaxStatusDisplayNames: Record<TrustTaxStatus, string> = {
  [TrustTaxStatus.GrantorTrust]: 'Grantor trust',
  [TrustTaxStatus.NonGrantorTrust]: 'Non-grantor trust',
  [TrustTaxStatus.NonTaxableTrust]: 'Non-taxable trust',
};

export function getGrantorNameFromFragment(
  grantorFragment: TrustDetailsCard_GrantorPrincipalFragment
): string {
  if (grantorFragment.individual) {
    return grantorFragment.individual.legalName;
  }

  return 'Unknown grantor';
}

/**
 * @description Grantor trust, Non-grantor trust, Non-taxable trust
 */
export function getFormattedTrustTaxStatus(taxStatus: Maybe<TrustTaxStatus>): {
  value: string | undefined;
} {
  return {
    value: taxStatus ? trustTaxStatusDisplayNames[taxStatus] : undefined,
  };
}

export function getTermLabelItem({
  effectiveDate,
  termEndDate,
  label,
  isLifetime,
}: {
  effectiveDate: Maybe<Date>;
  termEndDate: Maybe<Date>;
  isLifetime?: boolean;
  label?: string;
}) {
  const termEndDisplay = isLifetime
    ? 'Lifetime'
    : termEndDate && formatDateToMonDDYYYY(termEndDate);

  if (!effectiveDate || !termEndDisplay) {
    return { label: label ?? 'Term', value: EMPTY_CONTENT_HYPHEN };
  }

  return {
    label: label ?? 'Term',
    value: [formatDateToMonDDYYYY(effectiveDate), termEndDisplay].join(' - '),
  };
}

export function getMostRecentOwnershipStakeEffectiveDate(
  ownershipStakes: EntityDetail_OwnerOfStakeFragment[] | null | undefined
) {
  if (!ownershipStakes) {
    return null;
  }

  return ownershipStakes.reduce<Date | null>((mostRecent, stake) => {
    const ownedEntityValuationDate =
      stake.ownedEntity?.subtype?.mostRecentValuationDate;
    if (!ownedEntityValuationDate) {
      return mostRecent;
    }

    if (!mostRecent) {
      return ownedEntityValuationDate;
    }

    return ownedEntityValuationDate > mostRecent
      ? ownedEntityValuationDate
      : mostRecent;
  }, null);
}

function getMoreRecentDate(date1: Date | null, date2: Date | null) {
  if (!date1) {
    return date2;
  }

  if (!date2) {
    return date1;
  }

  return date1 > date2 ? date1 : date2;
}

export interface GenericAssetsValuationDetails {
  // totalMarketValue is a sum of the assets owned by this entity plus any business holdings it might have
  totalMarketValue: Decimal;
  // ownAssetsValue is the sum of only the assets owned by this entity, excluding the business holdings
  ownAssetsValue: Decimal;
  // ownAssetsEffectiveDate is the date of the most revaluation of the assets held directly by this entity
  ownAssetsEffectiveDate: Date | null;
  holdingsDescription: string;
  ownershipStakes: EntityDetail_OwnerOfStakeFragment[];
  ownershipStakesMostRecentEffectiveDate: Date | null;

  // this is whichever date between ownershipStakesMostRecentEffectiveDate and ownAssetsEffectiveDate is more recent
  mostRecentEffectiveDate: Date | null;
}

export interface GenericAssetsProperties {
  valuationDetails: GenericAssetsValuationDetails | null;
}

export function getSharedAssetsProperties(
  data: EntityDetail_EntityFragment
): GenericAssetsProperties {
  if (!('designerAccount' in data.subtype)) {
    throw new Error(
      'Trusts must have `designerAccount` to use the shared assets functionality'
    );
  }

  if (!data.subtype.designerAccount) {
    return {
      valuationDetails: null,
    };
  }

  const { ownAssetsValue, dateOfValuation, description } =
    getDesignerAccountDetails(data.subtype.designerAccount);
  const ownershipStakesMostRecentEffectiveDate =
    getMostRecentOwnershipStakeEffectiveDate(data.ownedOwnershipStakes);

  return {
    valuationDetails: {
      totalMarketValue: data.subtype.currentValue,
      ownAssetsValue,
      ownAssetsEffectiveDate: dateOfValuation,
      holdingsDescription: description,
      ownershipStakes: data.ownedOwnershipStakes ?? [],
      ownershipStakesMostRecentEffectiveDate,
      mostRecentEffectiveDate: getMoreRecentDate(
        dateOfValuation,
        ownershipStakesMostRecentEffectiveDate
      ),
    },
  };
}

export function getSharedSummaryProperties(data: EntityDetail_EntityFragment) {
  if (!('trustees' in data.subtype)) {
    throw new Error(`Invalid trust subtype ${data.subtype.__typename}`);
  }

  const trustJurisdictionDisplay = getJurisdictionDisplay(
    data.subtype.trustJurisdictionStateCode
  );

  return {
    _kind: EntityDetailPropsKind.TrustSummaryData as const,
    entityId: data.id,
    legalName: data.subtype.legalName || undefined,
    description: data.subtype.description || undefined,
    entityKind: data.kind,
    extendedDisplayKind: data.extendedDisplayKind,
    trustees: data.subtype.trustees || [],
    ownerOfStakes: data.ownedOwnershipStakes || [],
    cashflows: getNodes(data.cashflows) || [],
    successorTrustees: data.subtype.successorTrustees || [],
    trustAdvisors: data.subtype.trustAdvisors || [],
    taxStatus: getFormattedTrustTaxStatus(data.subtype.taxStatus),
    trustJurisdiction: trustJurisdictionDisplay,
    totalMarketValue:
      getSharedAssetsProperties(data).valuationDetails?.totalMarketValue ??
      undefined,
    entitySubtypeId: data.subtype.id,
    householdId: data.household.id,
    insurancePolicies:
      'policies' in data.subtype ? data.subtype.policies : undefined,
    assetLocation:
      getAssetLocationDisplay({
        inEstateStatus: data.subtype.inEstateStatus,
        stateTaxes: orderBy(
          (data.stateTaxes ?? []).map(({ stateCode, inEstateStatus }) => ({
            stateCode,
            inEstateStatus,
          })),
          'stateCode'
        ),
        gstStatus: data.subtype.gstStatus,
      }).locationDisplay ?? undefined,
  };
}
