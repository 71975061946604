import { Theme } from '@mui/material/styles';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import { COLORS } from '@/styles/tokens/colors';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getPublicImageUrl } from '@/utils/staticFiles';

const FONT_FAMILY = 'PublicSans';

// Register the Public Sans font - have to do them separately because of issues in react-pdf
// https://github.com/diegomura/react-pdf/issues/796
Font.register({
  family: FONT_FAMILY,
  fonts: [
    {
      fontWeight: 400,
      fontStyle: 'normal',
      src: '/fonts/PublicSans-Regular.ttf',
    },
    {
      fontWeight: 700,
      fontStyle: 'normal',
      src: '/fonts/PublicSans-Bold.ttf',
    },
    {
      fontWeight: 400,
      fontStyle: 'italic',
      src: '/fonts/PublicSans-Italic.ttf',
    },
    {
      fontWeight: 700,
      fontStyle: 'italic',
      src: '/fonts/PublicSans-BoldItalic.ttf',
    },
  ],
});

const PAGE_EDGE_PADDING = 24;

export function getThemedStyles(theme: Theme) {
  return StyleSheet.create({
    page: {
      flexDirection: 'column',
      display: 'flex',
      height: '100%',
      width: '100%',
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 72, // this is to account for the footer
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 12,
      paddingBottom: 12,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: COLORS.NEUTRAL_GRAY[900],
      borderBottomStyle: 'solid',
      flexGrow: 0,
    },
    headerTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
    },
    headerTitle: {
      fontSize: '18px',
      lineHeight: '26px',
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    headerRight: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    householdName: {
      fontSize: '10px',
      fontFamily: FONT_FAMILY,
      color: COLORS.NEUTRAL_GRAY[500],
      textAlign: 'right',
      fontWeight: 700,
      lineHeight: '14px',
    },
    headerDate: {
      fontSize: '12px',
      fontFamily: FONT_FAMILY,
      color: COLORS.NEUTRAL_GRAY[500],
      textAlign: 'right',
      fontWeight: 400,
      lineHeight: '14px',
    },
    summary: {
      flexGrow: 1,
      flexShrink: 1,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      paddingBottom: 8,
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      left: PAGE_EDGE_PADDING,
      right: PAGE_EDGE_PADDING,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 0,
      paddingRight: 0,
      borderTopWidth: 1,
      borderTopColor: COLORS.NEUTRAL_GRAY[200],
      borderTopStyle: 'solid',
    },
  });
}

export function getHtmlStylesheet(theme: Theme) {
  return {
    h1: {
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginTop: 0,
      marginBottom: 4,
    },
    h2: {
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginTop: 8,
      marginBottom: 8,
    },
    strong: {
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
    },
    ul: {
      marginTop: 0,
      marginBottom: 0,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: '14px',
      fontSize: '10px',
    },
    ol: {
      marginTop: 0,
      marginBottom: 0,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: '14px',
      fontSize: '10px',
    },
    li: {
      fontFamily: FONT_FAMILY,
      color: COLORS.NEUTRAL_GRAY[800],
      marginTop: 0,
      marginBottom: 0,
    },
    p: {
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      color: COLORS.NEUTRAL_GRAY[800],
      fontSize: '10px',
      lineHeight: '14px',
      marginTop: 0,
      marginBottom: 0,
    },
    em: {
      fontFamily: FONT_FAMILY,
      fontStyle: 'italic',
      fontSize: '10px',
    },
  };
}

export function DocumentSummaryPDFHeader({
  title,
  householdDisplayName,
  theme,
}: {
  title: string;
  householdDisplayName: string | null;
  theme: Theme;
}) {
  const styles = getThemedStyles(theme);
  return (
    <View fixed style={styles.header}>
      <View style={styles.headerTitleContainer}>
        <Text style={styles.headerTitle}>{title}</Text>
      </View>
      <View style={styles.headerRight}>
        <Text style={styles.householdName}>{householdDisplayName}</Text>
        <Text style={styles.headerDate}>
          As of {formatDateToMonDDYYYY(new Date())}
        </Text>
      </View>
    </View>
  );
}

export function DocumentSummaryPDFFooter({ theme }: { theme: Theme }) {
  const styles = getThemedStyles(theme);
  return (
    <View fixed style={styles.footer}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image
        src={
          theme.logos.primaryWide ??
          getPublicImageUrl('/LuminaryLogoAndText.png')
        }
        style={{ height: '25px' }}
      />
    </View>
  );
}

interface DocumentSummaryPDFProps {
  htmlDocumentSummary: string;
  householdDisplayName: string | null;
  customerTheme: Theme;
  debugMode?: boolean;
}

export function DocumentSummaryPDF({
  htmlDocumentSummary,
  householdDisplayName,
  customerTheme,
  debugMode = false,
}: DocumentSummaryPDFProps) {
  const styles = getThemedStyles(customerTheme);
  const htmlStylesheet = getHtmlStylesheet(customerTheme);

  return (
    <Document>
      <Page
        wrap
        size="LETTER"
        orientation="landscape"
        style={styles.page}
        debug={debugMode}
      >
        <DocumentSummaryPDFHeader
          title="Document Summary"
          householdDisplayName={householdDisplayName}
          theme={customerTheme}
        />
        {/*
        Padding in wrapped content only applies to the first page, so use a fixed height to account for the padding
        between the header and the summary content
        https://github.com/diegomura/react-pdf/issues/733
        */}
        <View fixed style={{ height: 8 }} />
        <View wrap style={styles.summary}>
          {/*
            Columns are not supported in this library
            https://github.com/diegomura/react-pdf/issues/304
            */}
          <Html stylesheet={htmlStylesheet}>{htmlDocumentSummary}</Html>
        </View>
        <DocumentSummaryPDFFooter theme={customerTheme} />
      </Page>
    </Document>
  );
}
