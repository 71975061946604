import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { maxBy } from 'lodash';

import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { DispositiveProvisionDirection } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsByDeath.types';
import { sortDispositiveProvisions } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView.utils';
import {
  createDispositiveProvisionRow,
  DispositiveProvisionRow,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsRow';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { StateCode } from '@/types/schema';

import { HEIGHT_CONFIG } from '../../entityPresentationPagination.utils';
import { GeneratedDispositionScenarioSlideProps } from './DispositionScenarioSlide.utils';
import { RowData } from './DispositionScenarioTable.types';

export function getColumns(): Column<RowData>[] {
  return [
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.recipient.lineOne,
        lineOneProps: { variant: 'h5' },
        cellContainerProps: {
          sx: {
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          },
        },
      }),
      sortComparator: (
        v1: RowData['recipient'],
        v2: RowData['recipient'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1, v2, p1, p2);
      },
    },
    {
      field: 'recipientType',
      headerName: 'Recipient Type',
      flex: 1,
      sortable: false,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.recipientType.lineOne,
        cellContainerProps: {
          sx: {
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          },
        },
      }),
    },
    {
      field: 'distributionAmount',
      headerName: 'Distribution Amount',
      flex: 1,
      align: 'right',
      // Note, this style is referenced indirectly in entityPresentationPagination.utils.ts
      // in the HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX constant
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.distributionAmount.lineOne ?? '',
        lineTwo: ({ row }) => row.distributionAmount.lineTwo ?? '',
        cellContainerProps: {
          sx: {
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          },
        },
      }),
      sortComparator: (
        v1: RowData['distributionAmount'],
        v2: RowData['distributionAmount'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
      },
    },
  ];
}

interface GetRowsForProvisionsInput {
  dispositionScenario: DispositiveProvisions_DispositionScenarioFragment;
  provisions: DispositiveProvisions_DispositiveProvisionFragment[];
  grantors: {
    displayName: string;
    id: string;
    stateCode?: StateCode;
    hasStateTax: boolean;
  }[];
}

export function getRowsForProvisions({
  dispositionScenario,
  provisions,
  grantors,
}: GetRowsForProvisionsInput) {
  const firstDeathGrantorId = dispositionScenario.firstGrantorDeath.id;
  const secondDeathGrantor = grantors.find(
    (grantor) => grantor.id !== firstDeathGrantorId
  );

  return provisions.flatMap((provision) => {
    const hasStateTax =
      grantors.find(
        (grantor) => grantor.id === dispositionScenario.firstGrantorDeath.id
      )?.hasStateTax ?? false;

    const row: DispositiveProvisionRow = createDispositiveProvisionRow({
      provision,
      nameOfDeadClient: dispositionScenario.firstGrantorDeath.displayName,
      nameOfSurvivingClient: secondDeathGrantor?.displayName ?? '',
      hasStateTax,
      calculatedProvisionAmount: null,
      direction: DispositiveProvisionDirection.Distributing,
    });

    return row.provisions.map((provision) => ({
      id: provision.id,
      recipient: {
        lineOne: row.heading,
        lineTwo: row.description,
      },
      recipientType: {
        lineOne: row.additionalItems?.map((item) => item).join(', '),
      },
      distributionAmount: {
        lineOne: provision.heading,
      },
    }));
  });
}

interface GetTableRowsInput {
  dispositionScenario?: DispositiveProvisions_DispositionScenarioFragment;
  firstDeathProvisions: DispositiveProvisions_DispositiveProvisionFragment[];
  secondDeathProvisions: DispositiveProvisions_DispositiveProvisionFragment[];
  grantors: {
    displayName: string;
    id: string;
    stateCode?: StateCode;
    hasStateTax: boolean;
  }[];
}

export function getTableRows({
  dispositionScenario,
  firstDeathProvisions,
  secondDeathProvisions,
  grantors,
}: GetTableRowsInput) {
  if (!dispositionScenario) {
    return { firstDeathRows: [], secondDeathRows: [] };
  }

  const sortedFirstDeathProvisions = firstDeathProvisions
    ? firstDeathProvisions.sort(sortDispositiveProvisions)
    : [];
  const sortedSecondDeathProvisions = secondDeathProvisions
    ? secondDeathProvisions.sort(sortDispositiveProvisions)
    : [];

  const firstDeathRows = getRowsForProvisions({
    dispositionScenario,
    provisions: sortedFirstDeathProvisions,
    grantors,
  });
  const secondDeathRows = getRowsForProvisions({
    dispositionScenario,
    provisions: sortedSecondDeathProvisions,
    grantors,
  });

  return { firstDeathRows, secondDeathRows };
}

export function getDispositionScenarioToShow(
  dispositionScenarios: GeneratedDispositionScenarioSlideProps[]
) {
  const numDispositionsByScenario = dispositionScenarios.map((scenario) => {
    return {
      id: scenario.dispositionScenario.id,
      numDispositions:
        (scenario.firstDeathProvisions?.length ?? 0) +
        (scenario.secondDeathProvisions?.length ?? 0),
    };
  });

  const scenarioWithMostDispositions = maxBy(
    numDispositionsByScenario,
    'numDispositions'
  );

  // Right now, we are showing one disposition scenario on the summary slide
  // so we pick the one with the most dispositions
  return dispositionScenarios.find(
    (scenario) =>
      scenario.dispositionScenario.id === scenarioWithMostDispositions?.id
  );
}
