import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import {
  TrustTrusteesList_SuccessorTrusteeFragment,
  TrustTrusteesList_TrusteeFragment,
} from '@/modules/trusts/TrustTrusteesList/graphql/TrustTrusteesList.fragments.generated';
import {
  getFormattedTrustee,
  getGroupedSuccessorTrusteeRows,
} from '@/modules/trusts/TrustTrusteesList/TrustTrusteesList.utils';

import { EntityDetailNameCard } from './EntityDetailNameCard';

interface TrusteesProps {
  trustees: TrustTrusteesList_TrusteeFragment[];
  successorTrustees: TrustTrusteesList_SuccessorTrusteeFragment[];
  maxItems?: number;
}

export function Trustees({
  trustees,
  successorTrustees,
  maxItems,
}: TrusteesProps) {
  const trusteeNames = useMemo(
    () => trustees.map((t) => getFormattedTrustee(t).heading),
    [trustees]
  );

  const successorTrusteeRows = useMemo(
    () =>
      getGroupedSuccessorTrusteeRows(successorTrustees, {
        format: 'short',
      }),
    [successorTrustees]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Trustees
      </Typography>
      {trusteeNames.length === 0 && (
        <Typography variant="subtitle2" component="span">
          None specified
        </Typography>
      )}
      {trusteeNames.length > 0 && (
        <EntityDetailNameCard
          leftText="Primary"
          rightText={trusteeNames.join(', ')}
        />
      )}
      {successorTrusteeRows
        .slice(0, Math.max(0, (maxItems ?? 0) - 1))
        .map((row) => (
          <EntityDetailNameCard
            key={row.heading}
            leftText={row.heading}
            rightText={
              row.items.length > 0
                ? row.items.map((item) => item.heading).join(', ')
                : 'None'
            }
          />
        ))}
    </Stack>
  );
}
