import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import { ComponentType } from 'react';

import { Logomark, LogomarkSize } from '@/components/brand/Logomark/Logomark';
import { NOTIFICATION_THEME_BY_SEVERITY } from '@/components/notifications/constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { DisclaimerFooter } from '@/modules/presentation/PresentationSlide/DisclaimerFooter';

import { ClientPresentationV2Configuration } from '../../../ClientPresentationDesignerV2.types';
import { ClientPresentationV2Bundle } from '../../../types/ClientPresentationV2.PresentationBundleType';

export interface CoverSlideProps {
  bundle: ClientPresentationV2Bundle;
  SlideWrapper?: ComponentType<BoxProps>;
  presentationConfiguration: ClientPresentationV2Configuration;
  presentationName: string;
}

export function CoverSlide({
  bundle,
  SlideWrapper = Box,
  presentationName,
}: CoverSlideProps) {
  const theme = useTheme();
  // TODO useRegisterSlide (for pagination)

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id="slide-display"
        footer={
          <Box px={2}>
            <DisclaimerFooter
              variant="cover"
              contentSx={{
                color: NOTIFICATION_THEME_BY_SEVERITY['info-low'].text,
              }}
            />
          </Box>
        }
      >
        <Stack spacing={3} justifyContent="center" p={10.5} height="100%">
          <Stack direction="column" spacing={theme.spacing(0.5)}>
            <Stack
              maxHeight="15vh"
              direction="row"
              alignItems="center"
              mb="3vh !important"
            >
              <Logomark
                style={{ maxHeight: '100%' }}
                size={LogomarkSize.Wide}
              />
            </Stack>
            {presentationName && (
              <Typography variant="h1" color={theme.palette.primary.main}>
                {presentationName}
              </Typography>
            )}
            {bundle.coverSlideBundleConfiguration?.customText && (
              <Typography variant="body1">
                {bundle.coverSlideBundleConfiguration?.customText}
              </Typography>
            )}
          </Stack>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
