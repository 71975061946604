import { first, isEmpty } from 'lodash';

import {
  Aidp_KgRootFragment,
  StartAiDispositiveProvisionWorkflowMutation,
} from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/graphql/TriggerAIDPSuggestions.generated';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { mapAsyncJobStatusToNotificationStatus } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.utils';
import { AsyncJobKind, AsyncJobStatus } from '@/types/schema';

/**
 * Maps information about an AI DP job to an AsyncJobNotificationInfo
 * @param input
 */
export function aiDPNotificationMapper( // TODO: UNIT TEST THIS!
  input: StartAiDispositiveProvisionWorkflowMutation['startAIDispositiveProvisionWorkflow']
): AsyncJobNotificationInfo | null {
  if (
    input.status === AsyncJobStatus.Cancelled ||
    input.status === AsyncJobStatus.Failed
  ) {
    // We don't want to show notifications for failed or cancelled jobs
    return null;
  }

  const isBatch =
    !isEmpty(input.children) && input.kind === AsyncJobKind.AiDpBatched;

  // Since AsyncJobs have not historically had households associated, we need to
  // get the household from the KGRoot.
  const household = isBatch
    ? first(first(input.children)?.kgRoot)?.household
    : first(input?.kgRoot)?.household;

  const targetName = getAIDPTargetDisplayName(isBatch, household, input);

  return {
    id: input.id, // This will be the AsyncJob ID
    type: AsyncJobNotificationInfoType.AI_DP,
    status: mapAsyncJobStatusToNotificationStatus(input.status),
    estimatedDurationMs: (isBatch ? 10 : 2) * 60 * 1000, // estimate that batch takes 10 min, and single takes 2 min? ¯\_(ツ)_/¯
    household,
    asyncJob: input,
    newNotificationMessage: `Processing dispositions`,
    completedNotificationMessage: `Completed processing dispositions`,
    formatBannerMessage: () => '', // we don't have a custom banner for this yet
    notificationTargetDisplayName: targetName,
  };
}

/**
 * The display name of the household or entity that the AI DP job is for.
 */
export function getAIDPTargetDisplayName(
  isBatch: boolean,
  household: Aidp_KgRootFragment['household'] | undefined,
  input: StartAiDispositiveProvisionWorkflowMutation['startAIDispositiveProvisionWorkflow']
): string {
  const householdName = household?.displayName || 'Household';

  if (isBatch) {
    // Just show the household name for batch jobs
    return householdName;
  }

  const entityName =
    first(input?.kgRoot)?.entity?.subtype?.displayName || 'Entity';

  // Get the entity display name
  return `${entityName} (${householdName})`;
}
