import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import React, { useEffect, useMemo } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import { useSelectedSuggestionEvidenceContext } from '@/modules/aiSuggestions/context/SelectedSuggestionEvidence.context';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { COLORS } from '@/styles/tokens/colors';
import { AiSuggestionAcceptanceStatus } from '@/types/schema';

import { useEntitySuggestionsContext } from '../context/EntitySuggestions.context';

// This enum tells us the use-case of the icon, which helps us determine
// conditional rendering logic.
export enum LocateSuggestionIconVariant {
  // The icon is being used in a matcher row. We will render the icon as long as
  // the suggestion has NOT been rejected.
  MATCHER = 'MATCHER',
  // The icon is being used in a field. We will render the icon ONLY if the
  // suggestion has been accepted.
  FIELD = 'FIELD',
}

interface LocateSuggestionIconProps {
  suggestion: CommonAiSuggestionFragment | undefined;
  variant: LocateSuggestionIconVariant;
  additionalItemRendererProps?: Omit<
    AdditionalItemRendererProps,
    'mainLabelOverride'
  >;
  sx?: SxProps<Theme>;
}

export function LocateSuggestionIcon({
  suggestion,
  variant,
  additionalItemRendererProps,
  sx,
}: LocateSuggestionIconProps) {
  const { acknowledgedSuggestions } = useEntitySuggestionsContext();
  const { locateSuggestion } = useSelectedSuggestionEvidenceContext();

  const willRender = useMemo(() => {
    if (variant === LocateSuggestionIconVariant.MATCHER) {
      // If the icon is used in a matcher row, we will render the icon as long
      // as the suggestion has NOT been rejected.
      return (
        suggestion &&
        acknowledgedSuggestions[suggestion.id]?.status !==
          AiSuggestionAcceptanceStatus.Rejected
      );
    }

    // If the icon is used in a field, we will render the icon ONLY if the
    // suggestion has been accepted.
    return (
      suggestion &&
      acknowledgedSuggestions[suggestion.id]?.status ===
        AiSuggestionAcceptanceStatus.Accepted &&
      !additionalItemRendererProps?.hideLocateSuggestionIconOverride
    );
  }, [
    acknowledgedSuggestions,
    additionalItemRendererProps?.hideLocateSuggestionIconOverride,
    suggestion,
    variant,
  ]);

  useEffect(() => {
    if (!additionalItemRendererProps) {
      return;
    }

    // If we render the locator icon, we need to pad the top of the "delete"
    // button when rendering the field in a FormAwareInputRepeater.
    additionalItemRendererProps.onRenderHasLocatorButton?.(
      variant === LocateSuggestionIconVariant.FIELD && !!willRender
    );
  }, [additionalItemRendererProps, variant, willRender]);

  if (!willRender || !suggestion) {
    return null;
  }

  return (
    <IconButton
      icon={SearchSmIcon}
      size="xs"
      variant="primary"
      ariaLabel="Locate suggestion"
      onClick={() => locateSuggestion(suggestion)}
      data-testid={`locateSuggestionIcon-${suggestion.kind}`}
      sx={{
        p: '3px',
        color: 'white',
        background: COLORS.TEAL[600],
        border: 'none',
        ml: 1,
        ':hover': {
          color: 'white',
          background: COLORS.TEAL[900],
          border: 'none',
        },
        ...sx,
      }}
    />
  );
}
