import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';

import { Node } from '@/components/diagrams/FlowChart';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { mostRecentlyViewedWaterfallVar } from '@/graphql/reactiveVars';
import {
  AugmentedUpdateEstateWaterfallInput,
  ClientOrganizationKind,
  EntityStage,
} from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatEnumCase } from '@/utils/formatting/strings';
import { getNodes } from '@/utils/graphqlUtils';

import { CHARITABLE_ENTITY_TYPES } from '../entities/entities.constants';
import {
  CHARITABLE_TESTAMENTARY_ENTITY_KINDS,
  testamentaryEntityKindToDisplayName,
} from '../entities/testamentaryEntities/testamentaryEntities.utils';
import { getEntityTypeFromSubtype } from '../entities/utils/getEntityTypeFromSubtype';
import { isNodeEligibleForGraveyard } from '../graphViz/graphVizNodeConfig/nodeGraveyard.utils';
import { UpdateEstateWaterfallMutationVariables } from './components/EstateWaterfallModal/graphql/EstateWaterfallModal.generated';
import {
  EstateWaterfall_GraphVizGroupFragment,
  EstateWaterfall_NodeFragment,
  EstateWaterfall_TaxSummaryFragment,
  EstateWaterfallFragment,
} from './graphql/EstateWaterfall.generated';
import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from './graphql/GetWaterfallSummary.generated';
import { isUIOnlyGroupNode } from './waterfallGraph/utils';

export function getUpdateWaterfallVariables(
  waterfall: Pick<
    EstateWaterfallFragment,
    'id' | 'visualizationGroups' | 'visualizationConfig'
  >,
  nodes: Node[]
): UpdateEstateWaterfallMutationVariables {
  const nodeConfigurations = nodes.flatMap((node) => {
    const {
      id: nodeID,
      position: { x: xPosition, y: yPosition },
      type,
    } = node;
    // Do not save the node configuration for a tile in the graveyard
    if (isNodeEligibleForGraveyard(node)) return [];
    // We don't save section group info since it's computed by a separate process and never saved
    if (type === 'sectionLabel') return [];
    return { nodeID, xPosition, yPosition, hidden: false };
  });

  const updateVisualizationGroups: AugmentedUpdateEstateWaterfallInput['updateVisualizationGroups'] =
    getNodes(waterfall.visualizationGroups).flatMap((group) => {
      if (isUIOnlyGroupNode(group.id)) return [];

      return {
        id: group.id,
        update: { nodeIds: group.nodes.map((n) => n.id) },
      };
    });

  const { __typename, id, ...nextVizConfig } = waterfall.visualizationConfig!;
  const updateVisualizationConfig: AugmentedUpdateEstateWaterfallInput['updateVisualizationConfig'] =
    { update: { ...nextVizConfig }, id };

  return assertExact<UpdateEstateWaterfallMutationVariables>()({
    input: {
      id: waterfall.id,
      update: { nodeConfigurations },
      updateVisualizationGroups,
      updateVisualizationConfig,
    },
    refetchWaterfall: true,
  });
}

interface IsHypotheticalWaterfallInput {
  parent?: {
    id?: string | null;
  } | null;
}

export function isHypotheticalWaterfall(
  waterfall?: IsHypotheticalWaterfallInput | null
) {
  return Boolean(waterfall?.parent?.id);
}

export function setMostRecentlyViewedWaterfallId(
  mostRecentlyViewedWaterfallId: string,
  householdId: string
) {
  const key = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_WATERFALL}_${householdId}`;
  localStorage.setItem(key, mostRecentlyViewedWaterfallId);
  // Broadcast to mostRecentlyViewedWaterfallVar that the value has changed.
  // This will trigger refetch of all User.localMostRecentlyViewedWaterfall queries.
  mostRecentlyViewedWaterfallVar({
    id: mostRecentlyViewedWaterfallId,
  });
}

export function getIsCharitableNode(
  node: EstateWaterfall_NodeFragment['node']
): boolean {
  // DAFs, private foundations, and charitable trusts
  const isCharitableEntity =
    node.__typename === 'Entity' &&
    node.subtype.__typename &&
    CHARITABLE_ENTITY_TYPES.includes(
      getEntityTypeFromSubtype(node.subtype.__typename)
    );
  // Charitable organizations
  const isCharitableClientOrg =
    node.__typename === 'ClientOrganization' &&
    node.clientOrganizationKind ===
      ClientOrganizationKind.CharitableOrganization;
  // Charitable testamentary trusts and charitable testamentary entities
  const isCharitableTestamentaryEntity =
    node.__typename === 'TestamentaryEntity' &&
    CHARITABLE_TESTAMENTARY_ENTITY_KINDS.includes(node.testamentaryEntityKind);

  return (
    isCharitableEntity ||
    isCharitableClientOrg ||
    isCharitableTestamentaryEntity
  );
}

export function getIsDraftNode(node: EstateWaterfall_NodeFragment['node']) {
  if ('stage' in node) {
    return node.stage === EntityStage.Draft;
  }

  return false;
}

export function getCombinedTaxStateAndFederal(
  waterfallSummary: Maybe<EstateWaterfall_TaxSummaryFragment>
) {
  const federal = waterfallSummary?.federalTax?.tax ?? new Decimal(0);
  const state = sumDecimalJS(
    waterfallSummary?.stateTax?.map((t) => t.tax) ?? []
  );
  return federal.plus(state);
}

type FilterableWaterfall = {
  visualizationWithProjections: {
    nodes?: {
      id: string;
      isHidden: boolean;
    }[];
    edges?: {
      to: { id: string };
      from: { id: string };
    }[];
  };
  visualizationGroups?: {
    edges?:
      | ({
          node?: {
            nodes: {
              id: string;
            }[];
          } | null;
        } | null)[]
      | null;
  };
} | null;

export function applyWaterfallFilter<T extends FilterableWaterfall>(
  waterfall: T
): T {
  if (!waterfall) {
    return waterfall;
  }

  // Get the nodes that are not hidden
  const nodes =
    waterfall.visualizationWithProjections.nodes?.filter((n) => !n.isHidden) ??
    [];

  // Use these IDs later to filter groups and edges
  const nodeIds = new Set(nodes.map((n) => n.id));

  // Only include edges that connect visible nodes
  const edges =
    waterfall.visualizationWithProjections.edges?.filter(
      (e) => nodeIds.has(e.to.id) && nodeIds.has(e.from.id)
    ) ?? [];

  // Exclude groups that don't have any visible nodes
  // Also filter out group nodes that are hidden
  const groups = (
    waterfall.visualizationGroups?.edges?.filter((e) => {
      const edgeNodeIds = e?.node?.nodes.map((n) => n.id) ?? [];
      const hasVisibleNodes = edgeNodeIds.some((id) => nodeIds.has(id));
      return hasVisibleNodes;
    }) ?? []
  ).reduce(
    (acc, group) => {
      const groupNodes =
        group?.node?.nodes.filter((n) => nodeIds.has(n.id)) ?? [];

      if (groupNodes.length) {
        acc?.push({
          ...group,
          node: {
            ...(group?.node ?? ({} as EstateWaterfall_GraphVizGroupFragment)),
            nodes: groupNodes,
          },
        });
      }

      return acc;
    },
    [] as {
      node?: {
        nodes: {
          id: string;
        }[];
      };
    }[]
  );

  // Return a new waterfall object with the filtered nodes, edges, and groups
  const wf: typeof waterfall = {
    ...waterfall,
    visualizationWithProjections: {
      ...waterfall.visualizationWithProjections,
      nodes,
      edges,
    },
    visualizationGroups: {
      edges: groups,
    },
  };

  return wf;
}

export function getWaterfallItemName(
  estateWaterfallVizNode:
    | GetWaterfallSummary_EstateWaterfallVizNodeFragment
    | EstateWaterfall_NodeFragment,
  nameOfDeadGrantor?: string
) {
  const lineOneAndTwoValues = {
    lineOne: EMPTY_CONTENT_HYPHEN,
    lineTwo: EMPTY_CONTENT_HYPHEN,
  };

  if (estateWaterfallVizNode.node.__typename === 'Entity') {
    lineOneAndTwoValues.lineOne =
      estateWaterfallVizNode.node.subtype.displayName;
    lineOneAndTwoValues.lineTwo =
      estateWaterfallVizNode.node.extendedDisplayKind;

    if (estateWaterfallVizNode.node.stage === EntityStage.Draft) {
      // This is a bit weird, but we want to remove any parentheticals from the
      // extended display kind that usually indicate the abbreviation, and then
      // add "(Draft)" to the end of it.
      lineOneAndTwoValues.lineTwo = `${estateWaterfallVizNode.node.extendedDisplayKind.replace(
        / *\([^)]*\) */g,
        ''
      )} (Draft)`;
    }
  }

  if (estateWaterfallVizNode.node.__typename === 'TestamentaryEntity') {
    lineOneAndTwoValues.lineOne = estateWaterfallVizNode.node.displayName;
    lineOneAndTwoValues.lineTwo = testamentaryEntityKindToDisplayName(
      estateWaterfallVizNode.node.testamentaryEntityKind,
      nameOfDeadGrantor
    );
  }

  if (estateWaterfallVizNode.node.__typename === 'ClientProfile') {
    lineOneAndTwoValues.lineOne = estateWaterfallVizNode.node.displayName;
  }

  if (estateWaterfallVizNode.node.__typename === 'ClientOrganization') {
    lineOneAndTwoValues.lineOne = estateWaterfallVizNode.node.name;
    lineOneAndTwoValues.lineTwo = formatEnumCase(
      estateWaterfallVizNode.node.clientOrganizationKind
    );
  }

  return lineOneAndTwoValues;
}
