import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { AsyncJobStatus_AsyncJobFragment } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { EntityKind } from '@/types/schema';

import { EntityType } from '../../types/EntityType';

export interface EntityDetailsContext {
  entityId: string | null;
  setEntityId: Dispatch<SetStateAction<EntityDetailsContext['entityId']>>;

  displayName: string | null;
  setDisplayName: Dispatch<SetStateAction<EntityDetailsContext['displayName']>>;

  entitySubtypeId: string | null;
  setEntitySubtypeId: Dispatch<
    SetStateAction<EntityDetailsContext['entitySubtypeId']>
  >;

  entityType: EntityType | null;
  setEntityType: Dispatch<SetStateAction<EntityDetailsContext['entityType']>>;

  // Derived from entityType
  entityKind: EntityKind | null;

  // These are the AsyncJobs that are currently pending and being tracked for the entity.
  activePendingAISuggestionAsyncJobs: AsyncJobStatus_AsyncJobFragment[] | null;
  setActivePendingAISuggestionAsyncJobs: Dispatch<
    SetStateAction<EntityDetailsContext['activePendingAISuggestionAsyncJobs']>
  >;

  // These are the AsyncJobs that were already completed for the entity.
  previouslyCompletedAISuggestionAsyncJobs:
    | AsyncJobStatus_AsyncJobFragment[]
    | null;
  setPreviouslyCompletedAISuggestionAsyncJobs: Dispatch<
    SetStateAction<
      EntityDetailsContext['previouslyCompletedAISuggestionAsyncJobs']
    >
  >;

  entityNotFound: boolean;
  setEntityNotFound: Dispatch<
    SetStateAction<EntityDetailsContext['entityNotFound']>
  >;
}

export const EntityDetailsContext = createContext<EntityDetailsContext>({
  entityId: null,
  setEntityId: () => null,

  displayName: null,
  setDisplayName: () => null,

  entitySubtypeId: null,
  setEntitySubtypeId: () => null,

  entityType: null,
  setEntityType: () => null,

  entityKind: null,

  activePendingAISuggestionAsyncJobs: null,
  setActivePendingAISuggestionAsyncJobs: () => null,

  previouslyCompletedAISuggestionAsyncJobs: null,
  setPreviouslyCompletedAISuggestionAsyncJobs: () => null,

  entityNotFound: false,
  setEntityNotFound: () => null,
});

export const useEntityDetailsContext = () => {
  return useGuardedContext(EntityDetailsContext, 'EntityDetailsProvider');
};
