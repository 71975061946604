// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../../types/schema';

import { gql } from '@apollo/client';
export type CoverSlide_PresentationBundleFragment = { __typename?: 'PresentationBundle', id: string, kind: Types.PresentationBundleKind, coverPageConfiguration?: { __typename?: 'CoverPageConfiguration', customText?: string | null } | null };

export const CoverSlide_PresentationBundleFragmentDoc = gql`
    fragment CoverSlide_PresentationBundle on PresentationBundle {
  id
  kind
  coverPageConfiguration {
    customText
  }
}
    `;
export const Operations = {
  Fragment: {
    CoverSlide_PresentationBundle: 'CoverSlide_PresentationBundle' as const
  }
}