import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ClientPresentationDesignerV2 } from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2';

export function ClientDetailsPresentationV2Page() {
  const householdId = useRequiredParam('householdId');
  const presentationId = useRequiredParam('presentationId');

  const props = {
    householdId,
    presentationId,
  };

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesignerV2 {...props} />
    </HouseholdDetailsAwareRoute>
  );
}
