import { useMemo } from 'react';

import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import {
  HouseholdDetailsContext,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

/**
 * This input type is designed to mimic the output of a waterfall query
 */
export interface useWaterfallGrantorDeathDisplayInput {
  firstGrantorDeathYear?: number | null | undefined;
  secondGrantorDeathYear?: number | null | undefined;
  firstGrantorDeath?:
    | {
        id: string;
      }
    | null
    | undefined;
}

export function useWaterfallGrantorDeathDisplay(
  input: useWaterfallGrantorDeathDisplayInput | null | undefined,
  fallbackGrantorNames: FallbackGrantorNames = {
    firstDeathFallback: '1st Client',
    secondDeathFallback: '2nd Client',
  }
): Record<AfterDeath, string> {
  const {
    firstGrantorDeathYear,
    secondGrantorDeathYear,
    firstGrantorDeath: firstGrantorDeathProfile,
  } = input || {};
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();

  const firstDyingGrantorId = firstGrantorDeathProfile?.id;

  return useMemo(() => {
    return {
      [AfterDeath.None]: getDisplayForWaterfallGrantorDeath({
        afterDeath: AfterDeath.None,
        primaryClients,
        isTwoClientHousehold,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        firstDyingGrantorId,
        alwaysIncludeYears: true,
        fallbackGrantorNames,
      }),
      [AfterDeath.First]: getDisplayForWaterfallGrantorDeath({
        afterDeath: AfterDeath.First,
        primaryClients,
        isTwoClientHousehold,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        firstDyingGrantorId,
        alwaysIncludeYears: true,
        fallbackGrantorNames,
      }),
      [AfterDeath.Second]: getDisplayForWaterfallGrantorDeath({
        afterDeath: AfterDeath.Second,
        primaryClients,
        isTwoClientHousehold,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        firstDyingGrantorId,
        alwaysIncludeYears: true,
        fallbackGrantorNames,
      }),
    };
  }, [
    fallbackGrantorNames,
    firstDyingGrantorId,
    firstGrantorDeathYear,
    isTwoClientHousehold,
    primaryClients,
    secondGrantorDeathYear,
  ]);
}

export interface FallbackGrantorNames {
  firstDeathFallback: string;
  secondDeathFallback: string;
}

export interface getDisplayForWaterfallGrantorDeathInput {
  afterDeath: AfterDeath;
  primaryClients:
    | (
        | {
            id: string;
            firstName: string;
          }
        | undefined
      )[]
    | null;
  isTwoClientHousehold: HouseholdDetailsContext['isTwoClientHousehold'];
  firstDyingGrantorId: string | undefined | null;
  firstGrantorDeathYear: number | undefined | null;
  secondGrantorDeathYear: number | undefined | null;
  fallbackGrantorNames?: FallbackGrantorNames;
  alwaysIncludeYears?: boolean;
  private_isFirstDeathMeansSecondDeathEnabled?: boolean | null | undefined;
}

export function getDisplayNamesForDeaths({
  isTwoClientHousehold,
  primaryClients,
  firstDyingGrantorId,
  fallbackGrantorNames = {
    firstDeathFallback: '1st',
    secondDeathFallback: '2nd',
  },
}: Pick<
  getDisplayForWaterfallGrantorDeathInput,
  | 'isTwoClientHousehold'
  | 'primaryClients'
  | 'firstDyingGrantorId'
  | 'fallbackGrantorNames'
>): {
  firstNameDisplay: string;
  secondNameDisplay: string | undefined;
} {
  const firstDyingGrantorName = primaryClients?.find(
    (client) => firstDyingGrantorId === client?.id
  )?.firstName;
  const lastDyingGrantorName = primaryClients?.find(
    (client) => firstDyingGrantorId !== client?.id
  )?.firstName;

  const { firstDeathFallback, secondDeathFallback } = fallbackGrantorNames;

  let firstNameDisplay = '';

  if (firstDyingGrantorName) {
    firstNameDisplay = `${firstDyingGrantorName}'s`;
  } else if (isTwoClientHousehold) {
    firstDeathFallback;
  }

  let secondNameDisplay = '';
  if (lastDyingGrantorName) {
    secondNameDisplay = `${lastDyingGrantorName}'s`;
  } else if (isTwoClientHousehold) {
    secondNameDisplay = secondDeathFallback;
  }
  return {
    firstNameDisplay,
    secondNameDisplay,
  };
}

export function getDisplayForWaterfallGrantorDeath({
  afterDeath,
  primaryClients,
  isTwoClientHousehold,
  firstGrantorDeathYear,
  secondGrantorDeathYear,
  firstDyingGrantorId,
  fallbackGrantorNames = {
    firstDeathFallback: '1st',
    secondDeathFallback: '2nd',
  },
  alwaysIncludeYears = false,
  private_isFirstDeathMeansSecondDeathEnabled,
}: getDisplayForWaterfallGrantorDeathInput): string {
  const isFirstDeathMeansSecondDeathEnabled =
    private_isFirstDeathMeansSecondDeathEnabled ??
    isFeatureFlagEnabled('ew_v2_move_first_death_to_mean_before_death_second');

  const { firstNameDisplay, secondNameDisplay } = getDisplayNamesForDeaths({
    isTwoClientHousehold,
    primaryClients,
    firstDyingGrantorId,
    fallbackGrantorNames,
  });

  const { firstDeathFallback, secondDeathFallback } = fallbackGrantorNames;

  const firstDeathYearDisplay = firstGrantorDeathYear
    ? ` (${firstGrantorDeathYear})`
    : '';

  const secondDeathYearDisplay = secondGrantorDeathYear
    ? ` (${secondGrantorDeathYear})`
    : '';

  if (afterDeath === AfterDeath.None) {
    if (isFirstDeathMeansSecondDeathEnabled) {
      return `Before ${firstNameDisplay} death${firstDeathYearDisplay}`;
    } else {
      return `Before ${firstDeathFallback} death${alwaysIncludeYears ? firstDeathYearDisplay : ''}`;
    }
  }

  if (afterDeath === AfterDeath.First) {
    if (isFirstDeathMeansSecondDeathEnabled) {
      if (isTwoClientHousehold) {
        return `Before ${secondNameDisplay} death${secondDeathYearDisplay}`;
      } else {
        return `After ${firstNameDisplay} death${firstDeathYearDisplay}`;
      }
    } else {
      return `After ${firstDeathFallback} death${alwaysIncludeYears ? firstDeathYearDisplay : ''}`;
    }
  }

  if (afterDeath === AfterDeath.Second) {
    if (isFirstDeathMeansSecondDeathEnabled) {
      return `After ${secondNameDisplay} death${secondGrantorDeathYear ? ` (${secondGrantorDeathYear})` : ''}`;
    }
    return `After ${secondDeathFallback} death${alwaysIncludeYears ? secondDeathYearDisplay : ''}`;
  }

  return '';
}
