import { FieldError, FieldErrors } from 'react-hook-form';

import {
  fieldTabMap,
  HypotheticalSaleLoanFormShape,
  HypotheticalSaleLoanFormTab,
} from '../../EstateWaterfallHypotheticalSaleLoanModal.types';

const ORDERED_TABS: HypotheticalSaleLoanFormTab[] = [
  'basicInformation',
  'assets',
  'payment',
];

interface FormErrorResult {
  fieldName: keyof HypotheticalSaleLoanFormShape;
  errorMessage?: string;
  tabName: HypotheticalSaleLoanFormTab;
}

const getTabForField = (
  fieldName: keyof HypotheticalSaleLoanFormShape
): HypotheticalSaleLoanFormTab => {
  const tab = fieldTabMap[fieldName];
  if (!tab) {
    throw new Error(`No tab mapping found for field: ${fieldName}`);
  }
  return tab;
};

// Something funny is going on with the FieldError type where it thinks it can be a decimal
// sometimes, so protecting against that here
function getFormErrorMessage(
  formError: FieldError | undefined
): string | undefined {
  if (!formError) return undefined;
  if (typeof formError.message === 'string') return formError.message;
  return undefined;
}

export function getFirstError(
  formErrors: FieldErrors<HypotheticalSaleLoanFormShape>,
  currentTab: HypotheticalSaleLoanFormTab
): FormErrorResult | null {
  // First, check for errors in the current tab
  for (const fieldName of Object.keys(
    formErrors
  ) as (keyof HypotheticalSaleLoanFormShape)[]) {
    if (getTabForField(fieldName) === currentTab) {
      const formError = formErrors[fieldName];
      return {
        fieldName,
        errorMessage: getFormErrorMessage(formError as FieldError),
        tabName: currentTab,
      };
    }
  }

  // If no errors in current tab, iterate through ORDERED_TABS
  for (const tab of ORDERED_TABS) {
    if (tab === currentTab) continue; // Already checked currentTab
    for (const fieldName of Object.keys(
      formErrors
    ) as (keyof HypotheticalSaleLoanFormShape)[]) {
      if (getTabForField(fieldName) === tab) {
        const formError = formErrors[fieldName];
        return {
          fieldName,
          errorMessage: getFormErrorMessage(formError as FieldError),
          tabName: tab,
        };
      }
    }
  }

  // No errors found
  return null;
}
