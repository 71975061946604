import Decimal from 'decimal.js';
import { compact, first, last } from 'lodash';

import {
  TaxOverviewTableRowData,
  TaxOverviewTableRowVariant,
} from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable.types';
import { getTaxOverviewTableRows } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable.utils';
import { AfterDeath } from '@/types/schema';

import { ClientPresentationDesignerV2_EstateWaterfallFragment } from '../../../../graphql/ClientPresentationDesignerV2.generated';

export type TaxOverviewTablePage = TaxOverviewTableRowData[];
export interface ReturnType {
  beforeFirstDeathPages: TaxOverviewTablePage[];
  afterFirstDeathPages: TaxOverviewTablePage[];
  afterSecondDeathPages: TaxOverviewTablePage[];

  beforeFirstDeathContinuedPageIndices: number[];
  afterFirstDeathContinuedPageIndices: number[];
  afterSecondDeathContinuedPageIndices: number[];
}

const ROWS_PER_PAGE = 11;

function isValidLastRow(row: TaxOverviewTableRowData | undefined): boolean {
  if (row?.variant === TaxOverviewTableRowVariant.Subtitle) return false;

  return true;
}

function getPriorRowIndex(
  rows: TaxOverviewTableRowData[],
  index: number
): number {
  for (let i = index - 1; i >= 0; i--) {
    if (rows[i]) {
      return i;
    }
  }
  throw new Error(`Could not get a prior row from index ${index}`);
}

export function private_paginateRows(
  rows: TaxOverviewTableRowData[]
): TaxOverviewTablePage[] {
  let currentSubHeaderRow: null | TaxOverviewTableRowData = null;

  let currentRowIndex = 0;
  let currentPage: TaxOverviewTablePage = [];

  const output: TaxOverviewTablePage[] = [];

  do {
    const currentRow: TaxOverviewTableRowData | undefined =
      rows[currentRowIndex];
    if (!currentRow) {
      throw new Error(`No row at index ${currentRowIndex}`);
    }

    // store this so it can be added to the next page if necessary
    if (
      currentRow.variant === TaxOverviewTableRowVariant.Subtitle &&
      currentRow !== currentSubHeaderRow
    ) {
      currentSubHeaderRow = currentRow;
    }

    currentPage.push(currentRow);

    currentRowIndex++;

    if (currentPage.length >= ROWS_PER_PAGE || currentRowIndex >= rows.length) {
      const nextRow = rows[currentRowIndex];

      let lastRow: TaxOverviewTableRowData | undefined = last(currentPage);
      let hasValidLastRow = isValidLastRow(lastRow);

      while (!hasValidLastRow) {
        currentPage.pop();
        currentRowIndex = getPriorRowIndex(rows, currentRowIndex);

        lastRow = last(currentPage);
        hasValidLastRow = isValidLastRow(lastRow);
      }

      if (currentPage.length) {
        output.push(currentPage);
      }

      currentPage = compact([
        nextRow?.variant === TaxOverviewTableRowVariant.Subtitle
          ? undefined
          : currentSubHeaderRow,
      ]);
    }
  } while (currentRowIndex < rows.length);
  return output;
}

function filterCollapsibleRows(row: TaxOverviewTableRowData) {
  return !!row.variant || row.path.length !== 2;
}

function getPageIndicesWithContinuedRows(
  pages: TaxOverviewTablePage[]
): number[] {
  return pages.reduce<number[]>((acc, page, index) => {
    if (index === 0) {
      return acc;
    }

    const previousPageLastRow = last(pages[index - 1]);
    if (!previousPageLastRow) {
      return acc;
    }

    const firstPageCurrentRow = first(page);
    if (!firstPageCurrentRow) {
      return acc;
    }

    if (previousPageLastRow.subsection === firstPageCurrentRow.subsection) {
      acc.push(index);
    }

    return acc;
  }, []);
}

export function getTaxSummaryTablePages(
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment,
  federalEstateTaxPercent: Decimal | null | undefined
): ReturnType {
  const isTwoGrantorHousehold = waterfall.household?.isTwoGrantor ?? false;
  const beforeFirstDeathPages = private_paginateRows(
    getTaxOverviewTableRows({
      afterDeath: AfterDeath.None,
      federalEstateTaxPercent,
      waterfall,
      isTwoGrantorHousehold,
      isMiniView: false,
    }).filter(filterCollapsibleRows)
  );

  const afterFirstDeathPages = private_paginateRows(
    getTaxOverviewTableRows({
      afterDeath: AfterDeath.First,
      federalEstateTaxPercent,
      waterfall,
      isTwoGrantorHousehold,
      isMiniView: false,
    }).filter(filterCollapsibleRows)
  );

  const afterSecondDeathPages = private_paginateRows(
    getTaxOverviewTableRows({
      afterDeath: AfterDeath.Second,
      federalEstateTaxPercent,
      waterfall,
      isTwoGrantorHousehold,
      isMiniView: false,
    }).filter(filterCollapsibleRows)
  );

  const beforeFirstDeathContinuedPageIndices = getPageIndicesWithContinuedRows(
    beforeFirstDeathPages
  );
  const afterFirstDeathContinuedPageIndices =
    getPageIndicesWithContinuedRows(afterFirstDeathPages);
  const afterSecondDeathContinuedPageIndices = getPageIndicesWithContinuedRows(
    afterSecondDeathPages
  );
  return {
    beforeFirstDeathPages,
    afterFirstDeathPages,
    afterSecondDeathPages,

    beforeFirstDeathContinuedPageIndices,
    afterFirstDeathContinuedPageIndices,
    afterSecondDeathContinuedPageIndices,
  };
}
