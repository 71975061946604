import { useEffect, useMemo } from 'react';
import {
  isRouteErrorResponse,
  Navigate,
  useRouteError,
} from 'react-router-dom';

// Layout & top-level pages
import { BaseLayout } from '@/components/architecture/Layout/BaseLayout';
import { ClientDetailsLayout } from '@/components/architecture/Layout/ClientDetailsLayout';
import { NoSidebarLayout } from '@/components/architecture/Layout/NoSidebarLayout';
import { PrimaryLayout } from '@/components/architecture/Layout/PrimaryLayout';
import { PrintOnlyLayout } from '@/components/architecture/Layout/PrintOnlyLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { EstateTaxScenariosTrowserPage } from '@/modules/estateTaxScenarios/EstateTaxScenariosTrowser';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
// Misc
import { AdminBrandingPage } from '@/pages/admin/AdminBrandingPage/AdminBrandingPage';
import { AdminCategoriesConfigurationPage } from '@/pages/admin/AdminCategoriesConfigurationPage/AdminCategoriesConfigurationPage';
import { AdminFeatureFlagsPage } from '@/pages/admin/AdminFeatureFlagsPage/AdminFeatureFlagsPage';
import { AdminIntegrationsPage } from '@/pages/admin/AdminIntegrationsPage/AdminIntegrationsPage';
import { AdminLegalDisclaimersPage } from '@/pages/admin/AdminLegalDisclaimersPage/AdminLegalDisclaimersPage';
// Admin pages
import { AdminPage } from '@/pages/admin/AdminPage';
import { AdminSubBrandsPage } from '@/pages/admin/AdminSubBrandsPage/AdminSubBrandsPage';
import { AdminTenantsPage } from '@/pages/admin/AdminTenantsPage/AdminTenantsPage';
import { AdminUsersPage } from '@/pages/admin/AdminUsersPage/AdminUsersPage';
// Proposal pages
import { PresentationPage as ClientProposalPresentationPage } from '@/pages/client-proposal/PresentationPage';
import { ClientDetailsDocumentsPage } from '@/pages/ClientDetailsPage/ClientDetailsDocumentsPage/ClientDetailsDocumentsPage';
import { ClientDetailsEntitiesListPage } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsEntitiesListPage/ClientDetailsEntitiesListPage';
import { ClientDetailsEntityMapBasePage } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsEntityMapPage/ClientDetailsEntityMapBasePage';
import { ClientDetailsEntityMapPage } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsEntityMapPage/ClientDetailsEntityMapPage';
import { ClientDetailsManageIncomeExpensesPage } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsManageIncomeExpensesPage/ClientDetailsManageIncomeExpensesPage';
import { ClientDetailsManageLiabilitiesPage } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsManageLiabilitiesPage/ClientDetailsManageLiabilitiesPage';
import { ClientDetailsEstateWaterfallBasePage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBasePage/ClientDetailsEstateWaterfallBasePage';
import { ClientDetailsEstateWaterfallBeneficiaryReportingBeneficiaryDetailsPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBeneficiaryReporting/ClientDetailsEstateWaterfallBeneficiaryReportingBeneficiaryDetailsPage';
import { ClientDetailsEstateWaterfallBeneficiaryReportingOverviewPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBeneficiaryReporting/ClientDetailsEstateWaterfallBeneficiaryReportingOverviewPage';
import { ClientDetailsEstateWaterfallBeneficiaryReportingPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBeneficiaryReporting/ClientDetailsEstateWaterfallBeneficiaryReportingPage';
import { ClientDetailsEstateWaterfallDiagramPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallDiagramPage';
import { ClientDetailsEstateWaterfallSummaryListPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallSummaryListPage';
import { ClientDetailsEstateWaterfallSummaryPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallSummaryPage/ClientDetailsEstateWaterfallSummaryPage';
import { ClientDetailsEstateWaterfallTaxOverviewPage } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallTaxOverviewPage/ClientDetailsEstateWaterfallTaxOverviewPage';
import { ClientDetailsGiftingPage } from '@/pages/ClientDetailsPage/ClientDetailsGiftingPage/ClientDetailsGiftingPage';
import { ClientDetailsGrowthProfilesPage } from '@/pages/ClientDetailsPage/ClientDetailsGrowthProfilesPage/ClientDetailsGrowthProfilesPage';
import { ManageDispositionsPage } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/ManageDispositionsPage';
import { ClientDetailsOverviewPage } from '@/pages/ClientDetailsPage/ClientDetailsOverviewPageV2/ClientDetailsOverviewPage';
import { ClientFamilyTreePage } from '@/pages/ClientDetailsPage/ClientDetailsPeopleOrganizationPage/ClientFamilyTreePage/ClientFamilyTreePage';
import { ClientProfessionalTeam } from '@/pages/ClientDetailsPage/ClientDetailsPeopleOrganizationPage/ClientProfessionalTeam/ClientProfessionalTeam';
import { ClientPeopleListView } from '@/pages/ClientDetailsPage/ClientDetailsPeopleOrganizationPage/ListView/ClientPeopleListView';
import { ClientValuePerformanceDashboard } from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/ClientValuePerformanceDashboard';
import { ClientDetailsPresentationPage } from '@/pages/ClientDetailsPage/ClientDetailsPresentationPage/ClientDetailsPresentationPage';
import { ClientDetailsPresentationV2Page } from '@/pages/ClientDetailsPage/ClientDetailsPresentationPage/ClientDetailsPresentationV2Page';
import { ClientDetailsPresentationsPage } from '@/pages/ClientDetailsPage/ClientDetailsPresentationsPage/ClientDetailsPresentationsPage';
import { ClientDetailsProposalsPage } from '@/pages/ClientDetailsPage/ClientDetailsProposalsPage/ClientDetailsProposalsPage';
import { ClientDetailsTasksPage } from '@/pages/ClientDetailsPage/ClientDetailsTasksPage';
// Client pages
import { ClientListPage } from '@/pages/ClientListPage/ClientListPage';
import { ClientBalanceSheetPage } from '@/pages/ClientPages/ClientBalanceSheetPage/ClientBalanceSheetPage';
import { ClientDocumentsPage } from '@/pages/ClientPages/ClientDocumentsPage';
import { ClientEntitiesPage } from '@/pages/ClientPages/ClientEntitiesPage/ClientEntitiesPage';
import { ClientOverviewPage } from '@/pages/ClientPages/ClientOverviewPage/ClientOverviewPage';
import { ClientPeoplePage } from '@/pages/ClientPages/ClientPeoplePage/ClientPeoplePage';
import { ClientPresentationsPage } from '@/pages/ClientPages/ClientPresentationsPage/ClientPresentationsPage';
import { ClientTasksPage } from '@/pages/ClientPages/ClientTasksPage';
import { ClientWaterfallContexts } from '@/pages/ClientPages/ClientWaterfallPage/ClientWaterfallContexts';
import { ClientWaterfallPage } from '@/pages/ClientPages/ClientWaterfallPage/ClientWaterfallPage';
import { ClientWealthPage } from '@/pages/ClientPages/ClientWealthPage/ClientWealthPage';
import { CollaboratorsPage } from '@/pages/collaborators/CollaboratorsPage';
import { CharitableTrustDesignerAnalysis } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/CharitableTrustDesignerAnalysis';
import { CharitableTrustDesignerBasicInformation } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerBasicInformation/CharitableTrustDesignerBasicInformation';
import { CharitableTrustDesignerPage } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerPage';
// GRAT design/implementation pages
import { DesignerPageV2 } from '@/pages/designer/DesignerPage';
import { GiftDesignerBasicInformationPage } from '@/pages/designer/GiftDesigner/GiftDesignerBasicInformationPage';
import { GiftDesignerModelScenariosPage } from '@/pages/designer/GiftDesigner/GiftDesignerModelScenariosPage';
import { GiftDesignerPage } from '@/pages/designer/GiftDesigner/GiftDesignerPage';
import { DocumentDetailsPage } from '@/pages/DocumentDetailsPage/DocumentDetailsPage';
import { DocumentDetailsPageVariant } from '@/pages/DocumentDetailsPage/DocumentDetailsPage.types';
import { EntityDetailPage } from '@/pages/entity/EntityDetailPage';
import { EntityDiagramPage } from '@/pages/entity/EntityDiagramPage';
import { EntityDocumentsPage } from '@/pages/entity/EntityDocumentsPage';
import { EntityPage } from '@/pages/entity/EntityPage';
import { EntityPaymentsPage } from '@/pages/entity/EntityPaymentsPage/EntityPaymentsPage';
import { EntityPerformancePage } from '@/pages/entity/EntityPerformancePage/EntityPerformancePage';
import { EntityTasksPage } from '@/pages/entity/EntityTasksPage/EntityTasksPage';
import { EntityValuationHistoryPage } from '@/pages/entity/EntityValuationHistoryPage/EntityValuationHistoryPage';
// Entity & trust pages
import { ErrorCodes } from '@/pages/error/constants';
import { ErrorOverlay } from '@/pages/error/ErrorOverlay';
import { PublicErrorPage } from '@/pages/error/PublicErrorPage';
import { ImplementationPage } from '@/pages/implementation/ImplementationPage';
import { BulkImportPage } from '@/pages/import/bulkImport/BulkImportPage';
import { ImportCSVValuationsPage } from '@/pages/import/csvImport/ImportCSVValuationsPage/ImportCSVValuationsPage';
import { ImportClientEntitiesPage } from '@/pages/importClientEntities/ImportClientEntitiesPage';
import { BulkImportEntitiesPage } from '@/pages/importEntities/BulkImportEntitiesPage';
import { DatesSandbox } from '@/pages/internal/DatesSandbox/DatesSandbox';
import { DevToolsPage } from '@/pages/internal/DevToolsPage/DevToolsPage';
import { DocumentInferenceSandbox } from '@/pages/internal/DocumentInferenceSandbox/DocumentInferenceSandbox';
import { ErrorsSandbox } from '@/pages/internal/ErrorsSandbox/ErrorsSandbox';
import { TextEditorSandbox } from '@/pages/internal/TextEditorSandbox/TextEditorSandbox';
import { ClientAccessPage } from '@/pages/manage/editClient/ClientAccessPage';
// Internal pages
// Client management pages
import { ClientActivityHistoryPage } from '@/pages/manage/editClient/ClientActivityHistoryPage/ClientActivityHistoryPage';
import { HouseholdAdminPage } from '@/pages/manage/editClient/ClientAdminPage/HouseholdAdminPage';
import { KeyDetailsPage } from '@/pages/manage/editClient/KeyDetailsPage/KeyDetailsPage';
import { PrintOnlyClientDashboard } from '@/pages/printOnly/PrintOnlyClientDashboard/PrintOnlyClientDashboard';
import { PrintOnlyClientPresentation } from '@/pages/printOnly/PrintOnlyClientPresentation/PrintOnlyClientPresentation';
import { PrintOnlyClientProposal } from '@/pages/printOnly/PrintOnlyClientProposal/PrintOnlyClientProposal';
import { PrintOnlySingleEntity } from '@/pages/printOnly/PrintOnlySingleEntity/PrintOnlySingleEntity';
import { PrintOnlyWaterfallComparison } from '@/pages/printOnly/PrintOnlyWaterfallComparison/PrintOnlyWaterfallComparison';
import { ProposalBuilderPage } from '@/pages/proposal/page/ProposalBuilderPage';
import { StandaloneTaskPage } from '@/pages/task/StandaloneTaskPage';
import { TasksPage } from '@/pages/tasks/TasksPage';
// User pages
import { UserProfilePage } from '@/pages/user/UserProfilePage';
import { UserProfileSettingsPage } from '@/pages/user/UserProfileSettingsPage/UserProfileSettingsPage';

import { computeRoutes } from './computeRoutes';
import {
  AugmentedRoute,
  CharitableTrustDesignerStages,
  GiftDesignerStages,
  ROUTE_KEYS,
} from './constants';
import { FunctionalRedirect } from './FunctionalRedirect';
import { GuardedPageRedirect } from './GuardedPageRedirect';
import { RootRedirect } from './RootRedirect';
import { CLIENT_PROPOSAL_PATH } from './routes.constants';

/**
 * @description This component is to only be used for the root error boundary. It will catch any errors that
 * are not caught in a child error boundary. If it triggers, you should probably add an error boundary to the
 * component that is throwing the error. See <Layout /> for an example of a component that has an error boundary.
 */
function RootErrorBoundary() {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const routeError = useRouteError();
  const isRouteError = isRouteErrorResponse(routeError);
  const errorCode = useMemo(() => {
    if (isRouteError && routeError.status === 404) {
      return ErrorCodes.NOT_FOUND;
    }

    return ErrorCodes.UNKNOWN;
  }, [isRouteError, routeError]);

  useEffect(() => {
    if (errorCode === ErrorCodes.UNKNOWN) {
      reportError('Client root error boundary triggered!', routeError as Error);
    }
  }, [errorCode, reportError, routeError, showFeedback]);

  return (
    <ErrorOverlay
      heading="There has been an unexpected error"
      showLogo={false} // don't show the logo on a "our bad" error page
      showBackButton // but do give the user a way to go back
      body={
        <p>
          The Luminary team has automatically notified and is looking into it.
          Refreshing the page may resolve the issue. If the issue persists or
          for any concerns, please reach out to{' '}
          <a href="mailto:support@withluminary.com" style={{ color: 'white' }}>
            support@withluminary.com
          </a>
          .
        </p>
      }
    />
  );
}

const PresentationsIndexPage = () => {
  const isClientPresentationV2Enabled = useFeatureFlag(
    'client_presentation_v2'
  );
  return (
    <Navigate
      to={isClientPresentationV2Enabled ? 'reports' : 'proposals'}
      replace
    />
  );
};

export const routes: AugmentedRoute[] = [
  {
    element: <BaseLayout />,
    key: ROUTE_KEYS.__CONTAINER,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: 'households',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        handle: {
          title: 'Clients',
        },
        children: [
          {
            index: true,
            path: '',
            element: <ClientListPage />,
            key: ROUTE_KEYS.HOUSEHOLDS_LIST,
          },
          {
            path: 'import',
            key: ROUTE_KEYS.__CONTAINER,
            children: [
              {
                path: 'bulk/entities/:flowType',
                key: ROUTE_KEYS.IMPORT_BULK_ENTITIES,
                element: <BulkImportEntitiesPage />,
                handle: {
                  title: 'Data import',
                },
              },
              {
                path: 'bulk/:flowType',
                key: ROUTE_KEYS.IMPORT_ADDEPAR_CONTENT,
                element: <BulkImportPage />,
                handle: {
                  title: 'Data import',
                },
              },
              {
                path: 'csv-valuations',
                key: ROUTE_KEYS.IMPORT_CSV_VALUATIONS,
                element: <ImportCSVValuationsPage />,
                handle: {
                  title: 'CSV valuations import',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'households',
        key: ROUTE_KEYS.__CONTAINER,
        handle: {
          title: 'Clients',
        },
        children: [
          {
            path: ':householdId',
            key: ROUTE_KEYS.__CONTAINER,
            element: <ClientDetailsLayout />,
            children: [
              {
                path: 'details',
                key: ROUTE_KEYS.__CONTAINER,
                handle: {
                  title: 'Client Details',
                },
                children: [
                  {
                    path: 'overview',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_OVERVIEW,
                    element: <ClientOverviewPage />,
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <Navigate to="overview" replace />,
                      },
                      {
                        path: 'overview',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
                        element: <ClientDetailsOverviewPage />,
                      },
                      {
                        path: 'performance',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PERFORMANCE,
                        element: <ClientValuePerformanceDashboard />,
                        handle: {
                          title: 'Value Creation',
                        },
                      },
                    ],
                  },
                  {
                    path: 'wealth',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_WEALTH,
                    element: <ClientWealthPage />,
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <Navigate to="balance-sheet" replace />,
                      },
                      {
                        path: 'balance-sheet',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_BALANCE_SHEET,
                        element: <ClientBalanceSheetPage />,
                      },
                      {
                        path: 'liabilities',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES,
                        element: <ClientDetailsManageLiabilitiesPage />,
                      },
                      {
                        path: 'growth-profiles',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_GROWTH_PROFILES,
                        element: <ClientDetailsGrowthProfilesPage />,
                      },
                      {
                        path: 'income-expenses',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES,
                        element: <ClientDetailsManageIncomeExpensesPage />,
                      },
                      {
                        path: 'gifting',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING,
                        element: <ClientDetailsGiftingPage />,
                        handle: {
                          title: 'Gifting',
                        },
                      },
                    ],
                  },
                  {
                    path: 'entities/:entityId',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_ENTITIES,
                    element: <EntityPage />,
                    handle: {
                      title: 'Entities',
                    },
                    children: [
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
                        path: 'details',
                        element: <EntityDetailPage />,
                        handle: {
                          title: 'Entity Details',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS_DIAGRAM,
                        path: 'diagram',
                        element: <EntityDiagramPage />,
                        handle: {
                          title: 'Diagram',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_TASKS,
                        path: 'tasks',
                        element: <EntityTasksPage />,
                        handle: {
                          title: 'Entity Tasks',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_PERFORMANCE,
                        path: 'performance',
                        element: <EntityPerformancePage />,
                        handle: {
                          title: 'Entity Performance',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_VALUATION_HISTORY,
                        path: 'valuation',
                        element: <EntityValuationHistoryPage />,
                        handle: {
                          title: 'Valuation History',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_PAYMENTS,
                        path: 'payments',
                        element: <EntityPaymentsPage />,
                        handle: {
                          title: 'Entity Payments',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_DOCUMENTS,
                        path: 'documents',
                        element: <EntityDocumentsPage />,
                        handle: {
                          title: 'Entity Documents',
                        },
                      },
                      {
                        key: ROUTE_KEYS.HOUSEHOLD_ENTITY_DOCUMENT_DETAILS,
                        path: `documents/:documentId`,
                        element: (
                          <DocumentDetailsPage
                            variant={DocumentDetailsPageVariant.Entity}
                          />
                        ),
                        handle: {
                          title: 'Entity Documents',
                        },
                      },
                      {
                        // this is the catchall route, so that users who land on /households/:id/trust/:id will be
                        // redirected to this default path
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.SPECIFIC_HOUSEHOLD_REDIRECT,
                        element: <Navigate to={`details`} replace />,
                      },
                    ],
                  },
                  {
                    path: 'entities',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_ENTITIES,
                    element: <ClientEntitiesPage />,
                    handle: {
                      title: 'Entities',
                    },
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <Navigate to="list" replace />,
                      },
                      {
                        path: 'list',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
                        element: <ClientDetailsEntitiesListPage />,
                      },
                      {
                        path: 'diagram',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP,
                        element: <ClientDetailsEntityMapBasePage />,
                        children: [
                          {
                            path: ':id',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM,
                            element: <ClientDetailsEntityMapPage />,
                            handle: {
                              title: 'Entity Map',
                            },
                          },
                        ],
                      },
                      {
                        path: 'manage-dispositions',
                        handle: {
                          title: 'Dispositions',
                        },
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS,
                        element: <ManageDispositionsPage />,
                        children: [
                          {
                            path: 'estate-tax-calculation',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_ESTATE_TAX_CALCULATION_TROWSER,
                            element: <EstateTaxScenariosTrowserPage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'people',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PEOPLE,
                    element: <ClientPeoplePage />,
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: (
                          <Navigate to="individuals-organizations" replace />
                        ),
                      },
                      {
                        path: 'individuals-organizations',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
                        element: <ClientPeopleListView />,
                        handle: {
                          title: 'People & Organizations',
                        },
                      },
                      {
                        path: 'client-team',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_CLIENT_TEAM,
                        element: <ClientProfessionalTeam />,
                        handle: {
                          title: 'Client Team',
                        },
                      },
                      {
                        path: 'family-tree',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_FAMILY_TREE,
                        element: <ClientFamilyTreePage />,
                        handle: {
                          title: 'Family Tree',
                        },
                      },
                    ],
                  },
                  {
                    path: 'estate-waterfall',
                    key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB,
                    element: <ClientWaterfallPage />,
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <ClientDetailsEstateWaterfallBasePage />,
                      },
                      {
                        path: ':waterfallId',
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <ClientWaterfallContexts />,
                        children: [
                          {
                            path: 'summary',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
                            element: (
                              <ClientDetailsEstateWaterfallSummaryPage />
                            ),
                            handle: {
                              title: 'Estate Waterfall',
                            },
                            children: [
                              {
                                path: 'estate-tax-calculation',
                                key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER,
                                element: <EstateTaxScenariosTrowserPage />,
                              },
                            ],
                          },
                          {
                            path: 'diagram',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM,
                            element: (
                              <ClientDetailsEstateWaterfallDiagramPage />
                            ),
                            handle: {
                              title: 'Estate Waterfall',
                            },
                            children: [
                              {
                                path: 'estate-tax-calculation',
                                key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER,
                                element: <EstateTaxScenariosTrowserPage />,
                              },
                            ],
                          },
                          {
                            path: 'list',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_LIST,
                            element: (
                              <ClientDetailsEstateWaterfallSummaryListPage />
                            ),
                            handle: {
                              title: 'Estate Waterfall',
                            },
                            children: [
                              {
                                path: 'estate-tax-calculation',
                                key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER,
                                element: <EstateTaxScenariosTrowserPage />,
                              },
                            ],
                          },
                          {
                            path: 'beneficiary-reporting',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING,
                            element: (
                              <ClientDetailsEstateWaterfallBeneficiaryReportingPage />
                            ),
                            handle: {
                              title: 'Beneficiary reporting',
                            },
                            children: [
                              {
                                path: '',
                                index: true,
                                key: ROUTE_KEYS.__CONTAINER,
                                element: <Navigate to="overview" replace />,
                              },
                              {
                                path: 'overview',
                                index: true,
                                key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_OVERVIEW,
                                element: (
                                  <ClientDetailsEstateWaterfallBeneficiaryReportingOverviewPage />
                                ),
                              },
                              {
                                path: ':clientOrOrgId',
                                key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS,
                                element: (
                                  <ClientDetailsEstateWaterfallBeneficiaryReportingBeneficiaryDetailsPage />
                                ),
                              },
                            ],
                          },
                          {
                            path: 'tax-overview',
                            key: ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW,
                            element: (
                              <ClientDetailsEstateWaterfallTaxOverviewPage />
                            ),
                            handle: {
                              title: 'Tax overview',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'presentations',
                    key: ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PRESENTATIONS,
                    element: <ClientPresentationsPage />,
                    handle: {
                      title: 'Presentations',
                    },
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <PresentationsIndexPage />,
                      },
                      {
                        path: 'proposals',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                        element: <ClientDetailsProposalsPage />,
                      },
                      {
                        path: 'reports',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS,
                        element: <ClientDetailsPresentationsPage />,
                      },
                    ],
                  },
                  {
                    path: 'tasks',
                    key: ROUTE_KEYS.HOUSEHOLD_DETAILS_TASKS,
                    element: <ClientTasksPage />,
                    handle: {
                      title: 'Tasks',
                    },
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <ClientDetailsTasksPage />,
                      },
                    ],
                  },
                  {
                    path: 'documents',
                    key: ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS,
                    element: <ClientDocumentsPage />,
                    handle: {
                      title: 'Documents',
                    },
                    children: [
                      {
                        path: '',
                        index: true,
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <ClientDetailsDocumentsPage />,
                      },
                      {
                        path: ':documentId',
                        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENT_DETAILS,
                        element: (
                          <DocumentDetailsPage
                            variant={DocumentDetailsPageVariant.Household}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'designer',
                key: ROUTE_KEYS.__CONTAINER,
                children: [
                  {
                    path: `:entityType/:entityId/:designerStage`,
                    key: ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
                    element: <DesignerPageV2 />,
                    handle: {
                      title: 'GRAT Designer',
                      features: {
                        hideGlobalSidebar: true,
                      },
                    },
                  },
                  {
                    path: `gifting/:proposalId`,
                    key: ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER,
                    element: <GiftDesignerPage />,
                    handle: {
                      title: 'Gift Designer',
                      features: {
                        hideGlobalSidebar: true,
                      },
                    },
                    children: [
                      {
                        path: GiftDesignerStages.BASIC_INFORMATION,
                        key: ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
                        element: <GiftDesignerBasicInformationPage />,
                      },
                      {
                        path: GiftDesignerStages.MODEL_SCENARIOS,
                        key: ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS,
                        element: <GiftDesignerModelScenariosPage />,
                      },
                    ],
                  },
                  {
                    path: 'charitable-trust/:variant/:proposalId',
                    key: ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER,
                    element: <CharitableTrustDesignerPage />,
                    handle: {
                      title: 'Charitable trust designer',
                      features: {
                        hideGlobalSidebar: true,
                      },
                    },
                    children: [
                      {
                        path: CharitableTrustDesignerStages.BASIC_INFORMATION,
                        key: ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
                        element: <CharitableTrustDesignerBasicInformation />,
                      },
                      {
                        path: CharitableTrustDesignerStages.MODEL_SCENARIOS,
                        key: ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS,
                        element: <CharitableTrustDesignerAnalysis />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'implementation',
                key: ROUTE_KEYS.__CONTAINER,
                handle: {
                  features: {
                    hideGlobalSidebar: true,
                  },
                },
                children: [
                  {
                    path: `:entityId`,
                    key: ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
                    element: <ImplementationPage />,
                    handle: {
                      title: 'Implementation',
                    },
                  },
                ],
              },
              {
                path: 'import/:flowType',
                key: ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPORT,
                element: <ImportClientEntitiesPage />,
                handle: {
                  title: 'Data import',
                },
              },
              {
                path: 'proposal',
                key: ROUTE_KEYS.__CONTAINER,
                handle: {
                  title: 'Proposals',
                },
                children: [
                  // TODO: These two routes can be compacted into one fairly trivially
                  {
                    path: `:proposalId`,
                    key: ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT,
                    element: <ProposalBuilderPage />,
                  },
                  {
                    path: `:proposalId`,
                    key: ROUTE_KEYS.HOUSEHOLD_PROPOSAL_CREATE,
                    element: <ProposalBuilderPage />,
                  },
                ],
              },
              {
                // this is the catchall route, so that users who land on /households/:id will be
                // redirected to this default path
                path: '',
                index: true,
                key: ROUTE_KEYS.SPECIFIC_HOUSEHOLD_REDIRECT,
                element: <Navigate to={`details/overview`} replace />,
              },
            ],
          },
          {
            path: `manage`,
            key: ROUTE_KEYS.__CONTAINER,
            children: [
              {
                path: `:householdId`,
                key: ROUTE_KEYS.__CONTAINER,
                element: <ClientDetailsLayout />,
                props: { to: ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW },
                children: [
                  {
                    path: `details`,
                    key: ROUTE_KEYS.__CONTAINER,
                    children: [
                      {
                        path: 'overview',
                        key: ROUTE_KEYS.__CONTAINER,
                        element: <ClientAccessPage />,
                        handle: {
                          title: 'Access',
                        },
                        children: [
                          {
                            path: '',
                            index: true,
                            key: ROUTE_KEYS.MANAGE_HOUSEHOLD_DETAILS,
                            element: <KeyDetailsPage />,
                            handle: {
                              title: 'Client access',
                            },
                          },
                          {
                            path: 'admin',
                            key: ROUTE_KEYS.MANAGE_HOUSEHOLD_ADMIN,
                            element: <HouseholdAdminPage />,
                            handle: {
                              title: 'Admin',
                            },
                          },
                          {
                            path: 'history',
                            key: ROUTE_KEYS.HOUSEHOLD_ACTIVITY_HISTORY,
                            element: <ClientActivityHistoryPage />,
                            handle: {
                              title: 'Activity History',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'task',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            path: ':taskId',
            key: ROUTE_KEYS.TASK_STANDALONE,
            element: <StandaloneTaskPage />,
            handle: {
              title: 'Task Details',
            },
          },
        ],
      },
      {
        path: 'tasks',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            index: true,
            path: '',
            handle: {
              title: 'Tasks',
            },
            element: (
              <GuardedPageRedirect route={ROUTE_KEYS.TASKS}>
                <TasksPage />
              </GuardedPageRedirect>
            ),
            key: ROUTE_KEYS.TASKS,
          },
        ],
      },
      {
        path: 'collaborators',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            index: true,
            path: '',
            handle: {
              title: 'Collaborators',
            },
            element: (
              <GuardedPageRedirect route={ROUTE_KEYS.COLLABORATORS}>
                <CollaboratorsPage />
              </GuardedPageRedirect>
            ),
            key: ROUTE_KEYS.COLLABORATORS,
          },
        ],
      },
      {
        path: 'admin',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            key: ROUTE_KEYS.ADMIN,
            element: <AdminPage />,
            handle: {
              title: 'Settings',
            },
            children: [
              {
                path: `sub-brands`,
                key: ROUTE_KEYS.ADMIN_SUB_BRANDS,
                element: <AdminSubBrandsPage />,
                handle: {
                  title: 'Sub-brands',
                },
              },
              {
                path: `categories`,
                key: ROUTE_KEYS.ADMIN_CATEGORIES,
                element: <AdminCategoriesConfigurationPage />,
                handle: {
                  title: 'Categories',
                },
              },
              {
                path: `branding`,
                key: ROUTE_KEYS.ADMIN_BRANDING,
                element: <AdminBrandingPage />,
                handle: {
                  title: 'Branding',
                },
              },
              {
                path: `users`,
                key: ROUTE_KEYS.ADMIN_USERS,
                element: <AdminUsersPage />,
                handle: {
                  title: 'Users',
                },
              },
              {
                path: `feature-flags`,
                key: ROUTE_KEYS.ADMIN_FEATURE_FLAGS,
                element: <AdminFeatureFlagsPage />,
                handle: {
                  title: 'Feature Flags',
                },
              },
              {
                path: `integrations`,
                key: ROUTE_KEYS.ADMIN_INTEGRATIONS,
                element: <AdminIntegrationsPage />,
                handle: {
                  title: 'Data integrations',
                },
              },
              {
                path: `tenants`,
                key: ROUTE_KEYS.ADMIN_TENANTS,
                element: <AdminTenantsPage />,
                handle: {
                  title: 'Tenants',
                },
              },
              {
                path: `legal`,
                key: ROUTE_KEYS.ADMIN_LEGAL_DISCLAIMERS,
                element: <AdminLegalDisclaimersPage />,
                handle: {
                  title: 'Legal disclaimers',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'internal',
        key: ROUTE_KEYS.__CONTAINER,
        element: <PrimaryLayout />,
        children: [
          {
            key: ROUTE_KEYS.__CONTAINER,
            // Code splitting this route so that we don't bundle the dev tools page with the rest of the app
            lazy: () => import('@/pages/internal/InternalToolsPage'),
            handle: {
              title: 'Internal Tools',
            },
            children: [
              {
                path: `dev-tools`,
                key: ROUTE_KEYS.INTERNAL_DEV_TOOLS,
                element: <DevToolsPage />,
                handle: {
                  title: 'Dev Tools',
                },
              },
              {
                path: `errors-sandbox`,
                key: ROUTE_KEYS.INTERNAL_ERRORS_SANDBOX,
                element: <ErrorsSandbox />,
                handle: {
                  title: 'Errors Sandbox',
                },
              },
              {
                path: `dates-sandbox`,
                key: ROUTE_KEYS.INTERNAL_DATES_SANDBOX,
                element: <DatesSandbox />,
                handle: {
                  title: 'Dates Sandbox',
                },
              },
              {
                path: `docs-sandbox`,
                key: ROUTE_KEYS.INTERNAL_DOCS_SANDBOX,
                element: <DocumentInferenceSandbox />,
                handle: {
                  title: 'Document Inference Sandbox',
                },
              },
              {
                path: `rte-sandbox`,
                key: ROUTE_KEYS.INTERNAL_RTE_SANDBOX,
                element: <TextEditorSandbox />,
                handle: {
                  title: 'Text Editor Sandbox',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'user',
        element: <PrimaryLayout />,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            key: ROUTE_KEYS.ADMIN,
            element: <UserProfilePage />,
            children: [
              {
                path: `settings`,
                key: ROUTE_KEYS.USER_SETTINGS,
                element: <UserProfileSettingsPage />,
                handle: {
                  title: 'Profile',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <NoSidebarLayout />,
    key: ROUTE_KEYS.__CONTAINER,
    children: [
      {
        path: CLIENT_PROPOSAL_PATH,
        key: ROUTE_KEYS.__CONTAINER,
        children: [
          {
            path: `:proposalId`,
            key: ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
            element: <ClientProposalPresentationPage />,
          },
        ],
      },
      {
        path: 'client-presentations/:householdId/presentations/:presentationId',
        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION,
        element: <ClientDetailsPresentationPage />,
      },
      {
        path: 'client-presentations/:householdId/presentations-v2/:presentationId',
        key: ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION_V2,
        element: <ClientDetailsPresentationV2Page />,
      },
    ],
  },
  {
    element: <PrintOnlyLayout />,
    key: ROUTE_KEYS.__CONTAINER,
    path: '__print',
    children: [
      {
        path: 'households/:householdId/entity/:entityId',
        key: ROUTE_KEYS.__PRINT_ONLY_SINGLE_ENTITY,
        element: <PrintOnlySingleEntity />,
      },
      {
        path: 'households/:householdId/presentation/:presentationId',
        key: ROUTE_KEYS.__PRINT_ONLY_CLIENT_PRESENTATION,
        element: <PrintOnlyClientPresentation />,
      },
      {
        path: 'households/:householdId/proposal/:proposalId',
        key: ROUTE_KEYS.__PRINT_ONLY_CLIENT_PROPOSAL,
        element: <PrintOnlyClientProposal />,
      },
      {
        path: 'households/:householdId/reporting/performance',
        key: ROUTE_KEYS.__PRINT_ONLY_CLIENT_REPORTING,
        element: <PrintOnlyClientDashboard />,
      },
      {
        path: 'households/:householdId/waterfall/:hypotheticalWaterfallId/comparison',
        key: ROUTE_KEYS.__PRINT_ONLY_WATERFALL_COMPARISON_LEGACY,
        element: <PrintOnlyWaterfallComparison />,
      },
      {
        path: 'households/:householdId/waterfall/comparison',
        key: ROUTE_KEYS.__PRINT_ONLY_WATERFALL_COMPARISON,
        element: <PrintOnlyWaterfallComparison />,
      },
    ],
  },
  {
    // this is the global default path. users that land on the root of the app
    // will hit this path.
    path: '',
    key: ROUTE_KEYS.ROOT_REDIRECT,
    element: <RootRedirect />,
    errorElement: <RootErrorBoundary />,
  },
  {
    // this is backwards-compatibility for the removal of the "home" page
    path: 'home',
    key: ROUTE_KEYS.ROOT_REDIRECT,
    element: <RootRedirect />,
  },
  {
    path: 'error/:errorCode',
    key: ROUTE_KEYS.PUBLIC_ERROR,
    element: <PublicErrorPage />,
  },
  {
    // this path is used as a centralized interface to handle redirects from the backend. this way,
    // we have a minimal, standardized API that our backend is aware of rather than it knowing about
    // all the routes and their structure.
    path: 'redirect/:destination',
    key: ROUTE_KEYS.FUNCTIONAL_REDIRECT,
    element: <FunctionalRedirect />,
  },
  {
    // this is a catchall 404 page for requests that don't map anywhere else.
    // THIS SHOULD ALWAYS COME LAST IN THE ROUTES LIST.
    path: '/:default',
    key: ROUTE_KEYS.NOT_FOUND,
    element: <Navigate to={`/error/${ErrorCodes.NOT_FOUND}`} />,
  },
];

export const computedRoutes = computeRoutes(routes);
