// Base color tokens
export const ORANGE = {
  50: '#FFEDEC',
  100: '#FFD3D0',
  200: '#FAA19B',
  300: '#F47C73',
  400: '#ff594e', // main brand color
  500: '#FF3E30',
  600: '#F63E31',
  700: '#E4362B',
  800: '#D72F24',
  900: '#65110C',
};

export const NAVY = {
  50: '#F0F8FF',
  100: '#E0E8F0',
  200: '#C8D3DF',
  300: '#9FAFC3',
  400: '#8598AF',
  500: '#6B819C',
  600: '#516B89',
  700: '#385677',
  800: '#1A4263',
  900: '#111827', // this is the primary navy
};

export const BLUE = {
  50: '#EEF7FC',
  100: '#C9E8F7',
  200: '#A3D8F3',
  300: '#7CC9F0',
  400: '#53BBEF',
  500: '#28ADF0',
  600: '#0C9BE2',
  700: '#0780BD',
  800: '#036697',
  900: '#00293D', // this is the primary blue
};

export const GRAY = {
  50: '#f9fafb',
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d1d5db',
  400: '#9ca3af',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#1f2937',
  900: '#111827',
};

export const NEUTRAL_GRAY = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#e5e5e5',
  300: '#d4d4d4',
  400: '#a3a3a3',
  500: '#737373',
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#171717',
};

export const TEAL = {
  50: '#F2FDFA',
  100: '#C9F6EA',
  200: '#A1EFDA',
  300: '#79E7CA',
  400: '#52DEB9',
  500: '#2BD4A7',
  600: '#24AC88',
  700: '#1D8368',
  800: '#155B48',
  900: '#08211A',
};

const FUNCTIONAL_SUCCESS = {
  50: TEAL[50],
  100: TEAL[100],
  200: TEAL[200],
  300: TEAL[300],
  400: TEAL[400],
  500: TEAL[500],
  600: TEAL[600],
  700: TEAL[700],
  800: TEAL[800],
  900: TEAL[900],
};
const DEFAULT_FUNCTIONAL_SUCCESS = FUNCTIONAL_SUCCESS[500];

const FUNCTIONAL_WARNING = {
  50: '#FDF5D3',
  100: '#FBE9AE',
  200: '#F8DA89',
  300: '#F5C965',
  400: '#F1B441',
  500: '#ED9D1E',
  600: '#D08013',
  700: '#AB6411',
  800: '#864A0E',
  900: '#2C2607',
};
const DEFAULT_FUNCTIONAL_WARNING = FUNCTIONAL_WARNING[400];

const FUNCTIONAL_ERROR = {
  50: ORANGE[50],
  100: ORANGE[100],
  200: ORANGE[200],
  300: ORANGE[300],
  400: ORANGE[400],
  500: ORANGE[500],
  600: ORANGE[600],
  700: ORANGE[700],
  800: ORANGE[800],
  900: ORANGE[900],
};
const DEFAULT_FUNCTIONAL_ERROR = FUNCTIONAL_ERROR[600];

export const FUNCTIONAL = {
  SUCCESS: {
    ...FUNCTIONAL_SUCCESS,
    DEFAULT: DEFAULT_FUNCTIONAL_SUCCESS,
  },
  WARNING: {
    ...FUNCTIONAL_WARNING,
    DEFAULT: DEFAULT_FUNCTIONAL_WARNING,
  },
  ERROR: {
    ...FUNCTIONAL_ERROR,
    DEFAULT: DEFAULT_FUNCTIONAL_ERROR,
  },
  HOVER: 'rgba(224, 235, 255, .5)',
};

export const PRIMITIVES = {
  WHITE: '#ffffff',
  BLACK: '#111827',
};

const CATEGORIES = {
  IN_ESTATE: NAVY,
  FAMILY_GIVING: TEAL,
  CHARITABLE_GIVING: BLUE,
  ESTATE_TAX_SAVINGS: ORANGE,
};

const MATH = {
  NEGATIVE: ORANGE[600],
};

export const COLORS = {
  GRAY,
  NEUTRAL_GRAY,

  TEAL,
  BLUE,
  NAVY,

  ORANGE,

  FUNCTIONAL,
  PRIMITIVES,
  CATEGORIES,
  MATH,
};
