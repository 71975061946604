import {
  AugmentedCreateLiabilityInput,
  CreateLiabilityInput,
} from '@/types/schema';

import { LiabilityFormShape } from './LiabilityModal.constants';

function getIssuerParams(
  formValues: LiabilityFormShape
): Partial<CreateLiabilityInput> {
  if (!formValues.issuerId) return {};
  // we only support entities for sources right now
  return { sourceEntityID: formValues.issuerId };
}

function getPaymentParams(
  formValues: LiabilityFormShape
): Partial<CreateLiabilityInput> {
  if (!formValues._showExpandedPaymentDetails) return {};
  return {
    paymentKind: formValues.paymentKind,
    recurringPaymentAmount: formValues.paymentAmount,
    termEnd: formValues.termEndDate,
    termStart: formValues.termStartDate,
    paymentFrequency: formValues.paymentFrequency,
    interestRate: formValues.interestRate,
  };
}

export function mapFormDataToInput(
  formValues: LiabilityFormShape,
  householdId: string
): AugmentedCreateLiabilityInput {
  return {
    create: {
      displayName: formValues.name,
      currentAmountAsOfDate: formValues.balanceAsOfDate,
      currentAmount: formValues.outstandingBalance,
      recipientEntityID: formValues.recipientId || null,
      householdID: householdId,
      payFromEntityID: formValues.hasDifferentPaymentSource
        ? formValues.alternateSourceId
        : null,
      ...getIssuerParams(formValues),
      ...getPaymentParams(formValues),
    },
  };
}
