import { ApolloError } from '@apollo/client';
import { createContext } from 'react';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useGuardedContext } from '@/hooks/useGuardedContext';

import { EstateWaterfallHypotheticalSaleLoanModal_EstateWaterfallFragment } from '../../graphql/EstateWaterfallHypotheticalSaleLoanModal.generated';

export interface SaleLoanFormContext {
  possibleRecipients: TypeaheadSelectInputOption<string>[];
  possibleSellerLenders: TypeaheadSelectInputOption<string>[];
  saleLoanId: string | null;
  saleLoanFundingId: string | null;
  growthProfileId: string | null;
  waterfallId: string;
  waterfall: EstateWaterfallHypotheticalSaleLoanModal_EstateWaterfallFragment | null;
  loading: boolean;
  error: ApolloError | undefined;
}

export const SaleLoanFormContext = createContext<SaleLoanFormContext>({
  possibleRecipients: [],
  possibleSellerLenders: [],
  saleLoanId: null,
  saleLoanFundingId: null,
  growthProfileId: null,
  waterfallId: '',
  waterfall: null,
  loading: false,
  error: undefined,
});

export const useSaleLoanFormContext = () =>
  useGuardedContext(SaleLoanFormContext, 'SaleLoanFormProvider');
