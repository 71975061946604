import { Stack } from '@mui/material';
import { values } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Share06Icon } from '@/components/icons/Share06Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { SidePanel } from '@/components/modals/SidePanel';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { HypotheticalTransferModal } from '@/modules/entities/details/HypotheticalTransferModal/HypotheticalTransferModal';
import { EstateWaterfallHypotheticalSaleLoanModal } from '@/modules/estateWaterfall/components/EstateWaterfallHypotheticalSaleLoanModal/EstateWaterfallHypotheticalSaleLoanModal';
import { useEstateWaterfallSummaryBarChartsSectionColors } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { CreateHypotheticalEventButton } from './CreateHypotheticalEventButton';
import { useHypotheticalTransfersSummaryQueryQuery } from './graphql/HypotheticalTransfersSummary.generated';
import { HypotheticalTransferSummarySource } from './HypotheticalTransfersSummaryPanel.components';
import { HypotheticalTransfersSummaryMap } from './HypotheticalTransfersSummaryPanel.types';
import {
  mapDataToEntityTransfersMap,
  mapDataToIncludedIds,
} from './HypotheticalTransfersSummaryPanel.utils';

interface HypotheticalTransferSummaryPanelInnerProps {
  waterfallId: string;
  householdId: string;
  /** sourceId is the id of the transfer source, which can be used by the consuming code to change the summary panel */
  onManageSource: (sourceId: string) => void;
  defaultOpenTransferId?: string;
}
export interface HypotheticalTransferSummaryPanelProps
  extends HypotheticalTransferSummaryPanelInnerProps {
  onClose: () => void;
}

function HypotheticalTransferSummaryPanelInner({
  waterfallId,
  householdId,
  onManageSource,
  defaultOpenTransferId,
}: HypotheticalTransferSummaryPanelInnerProps) {
  const isHypotheticalSaleLoanEnabled = isFeatureFlagEnabled(
    'hypothetical_sale_loan'
  );
  const { reportError } = useReportError();
  const [
    { isModalOpen: isTransferModalOpen, data: currentTransferId },
    { openModal: openTransferModal, closeModal: closeTransferModal },
  ] = useModalState<string>();
  const [
    { isModalOpen: isSaleLoanModalOpen, data: saleLoanId },
    { openModal: openSaleLoanModal, closeModal: closeSaleLoanModal },
  ] = useModalState<string>();

  const { data, error, loading, refetch } =
    useHypotheticalTransfersSummaryQueryQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        estateWaterfallsWhere: {
          id: waterfallId,
        },
      },
      onError: (error) => {
        reportError(
          `Failed to load transfers for waterfall ${waterfallId}`,
          error
        );
      },
    });

  const onManageTransfer = useCallback(
    (transferId: string) => {
      const pulidKind = getPulidKind(transferId);
      switch (pulidKind) {
        case PulidKind.HypotheticalSaleLoan:
          openSaleLoanModal(transferId);
          break;
        case PulidKind.EstateWaterfallHypotheticalTransfer:
          openTransferModal(transferId);
          break;
        default:
          diagnostics.error(
            `Unknown pulid kind: ${pulidKind} for transferId: ${transferId}`
          );
      }
    },
    [openTransferModal, openSaleLoanModal]
  );

  // we use this lifecycle hook to refetch potentially affected queries
  // when a submodal is closed.
  const onCloseModal = useCallback(() => {
    // we can always just close both modals because they're both on the same page
    // and only one can be open at a time
    closeTransferModal();
    closeSaleLoanModal();
    void refetch();
  }, [closeSaleLoanModal, closeTransferModal, refetch]);

  useEffect(() => {
    if (defaultOpenTransferId) {
      openTransferModal(defaultOpenTransferId);
    }
  }, [defaultOpenTransferId, openTransferModal]);

  const colorResult = useEstateWaterfallSummaryBarChartsSectionColors();

  const [sources, setSources] =
    useState<HypotheticalTransfersSummaryMap | null>(null);
  const [colors] = useState(colorResult);

  useEffect(() => {
    setSources(mapDataToEntityTransfersMap(data, colors));
  }, [colors, data]);

  const visibleNodeIds = useMemo(() => mapDataToIncludedIds(data), [data]);

  let body: ReactNode = null;
  if (error) {
    body = `Could not load transfers. Please try again later.`;
  } else if (loading) {
    body = <SummaryPanelLoader />;
  } else if (!sources) {
    body = (
      <ActionCard
        variant="noCard"
        icon={
          <Share06Icon
            sx={{ color: COLORS.GRAY[200], height: 36, width: 36 }}
          />
        }
        heading={`Specify hypothetical transfers`}
        description={`No hypothetical transfers have been created in this waterfall`}
        actions={
          isHypotheticalSaleLoanEnabled ? (
            <CreateHypotheticalEventButton
              onCloseSubmodal={onCloseModal}
              waterfallId={waterfallId}
              householdId={householdId}
              variant="primary"
              size="lg"
            />
          ) : (
            <Button
              variant="primary"
              size="lg"
              onClick={() => openTransferModal()}
            >
              Add a hypothetical transfer
            </Button>
          )
        }
      />
    );
  } else {
    body = (
      <Stack spacing={3}>
        {values(sources).map((source) => (
          <HypotheticalTransferSummarySource
            key={source.id}
            source={source}
            onManageSource={onManageSource}
            onManageTransfer={onManageTransfer}
            visibleNodeIds={visibleNodeIds}
          />
        ))}
        {isHypotheticalSaleLoanEnabled ? (
          <CreateHypotheticalEventButton
            onCloseSubmodal={onCloseModal}
            waterfallId={waterfallId}
            householdId={householdId}
            variant="secondary"
            size="sm"
            startIcon={PlusIcon}
            fullWidth
          />
        ) : (
          <Button
            variant="secondary"
            onClick={() => openTransferModal()}
            startIcon={PlusIcon}
            size="sm"
            fullWidth
          >
            Add hypothetical transfer
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <>
      <SidePanel.Section>{body}</SidePanel.Section>
      {isTransferModalOpen && (
        <HypotheticalTransferModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isTransferModalOpen}
          onClose={onCloseModal}
          transferId={currentTransferId ?? undefined}
        />
      )}
      {isSaleLoanModalOpen && (
        <EstateWaterfallHypotheticalSaleLoanModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isSaleLoanModalOpen}
          onClose={onCloseModal}
          saleLoanId={saleLoanId}
          // no need to pass an initialKind because we're editing an existing sale/loan
          initialKind={null}
        />
      )}
    </>
  );
}

export function HypotheticalTransfersSummaryPanel({
  onClose,
  ...props
}: HypotheticalTransferSummaryPanelProps) {
  return (
    <SidePanel.Panel>
      <SidePanel.Header title="Transfers" onClose={onClose} />
      <SidePanel.Content>
        <HypotheticalTransferSummaryPanelInner {...props} />
      </SidePanel.Content>
    </SidePanel.Panel>
  );
}
