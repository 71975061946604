import { addMilliseconds, isBefore } from 'date-fns';
import { compact, difference, isEmpty } from 'lodash';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { StructuredAssetsSubform_ValuationFragment } from '@/modules/entities/StructuredAssetsSubform/graphql/StructuredAssetsSubform.generated';
import {
  getFormAssetsFromAssets,
  getIntegratedAssetsFromIntegratedValuation,
  valuationHasAdditionalManualAssets,
} from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { makeEntityTypeUniversalUpdateInput } from '@/modules/entities/utils/makeEntityTypeUniversalUpdateInput';
import {
  AssetValuationV2ValuationSource,
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateEntityInput,
  IntegrationClientKind,
  UpdateEntityInput,
} from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';
import { getNodes } from '@/utils/graphqlUtils';

import { AssetValueV2OwnershipType } from '../AssetValueOwnershipTypes';
import { FullScreenStructuredAssetsModalForm } from './FullScreenStructuredAssetsModal.types';

export function getIntegratedAssetsFromValuation(
  valuation: StructuredAssetsSubform_ValuationFragment | null
) {
  return getNodes(valuation?.assets).filter((a) => Boolean(a.integrationType));
}

function getAssetIntegrationUpdateProperties(
  formValues: FullScreenStructuredAssetsModalForm
): Partial<UpdateEntityInput> {
  const nextIntegrationEntityIds = compact(formValues.integrationEntityIds);
  const previousIntegrationEntityIds = compact(
    formValues._previousIntegrationEntityIds
  );
  const linkedToNonGrantorEntity = getTypeOrUndefined<boolean>(
    formValues.linkToAllAddeparEntities
  );

  const addedIntegrationEntityIds = difference(
    nextIntegrationEntityIds,
    previousIntegrationEntityIds
  );
  const removedIntegrationEntityIds = difference(
    previousIntegrationEntityIds,
    nextIntegrationEntityIds
  );

  const removalProperties = {
    removeIntegrationEntityIDs: removedIntegrationEntityIds,
  };

  if (!isEmpty(nextIntegrationEntityIds)) {
    return {
      addIntegrationEntityIDs: addedIntegrationEntityIds,
      ...removalProperties,
      addeparLinkedToNongrantorEntity: linkedToNonGrantorEntity,
    };
  }

  return {
    // don't use `clearIntegrationEntities` here because it removes *all* integration entities
    // and we don't want to remove any potential CSV_IMPORT integration entities
    ...removalProperties,
    clearAddeparLinkedToNongrantorEntity: true,
  };
}

function getBalanceSheetConfigurationUpdateProperties(
  formValues: FullScreenStructuredAssetsModalForm
): Partial<UpdateEntityInput> {
  if (
    !formValues.showBalanceSheetCategoryOverrideChooser ||
    !formValues.balanceSheetCategoryOverrideId
  ) {
    return {
      clearBalanceSheetCategoryOverride: true,
      showEntityNameOnBalanceSheet: formValues.showEntityNameOnBalanceSheet,
    };
  }

  return {
    balanceSheetCategoryOverrideID: formValues.balanceSheetCategoryOverrideId,
    showEntityNameOnBalanceSheet: formValues.showEntityNameOnBalanceSheet,
  };
}

/**
 * This function ensures that the date picked from the form
 * retains the current time, preventing a scenario where the
 * new valuation ends up pre-dating an existing one if a sync
 * just occurred at a later time on the same date.
 *
 * If the resulting date is still before the current valuation,
 * we increment it slightly so the new valuation won't overwrite
 * the previous one.
 */
function getDateWithCurrentTime(originalDate: Date) {
  const preciseDate = new Date(originalDate);
  const now = new Date();

  preciseDate.setUTCHours(
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );
  return preciseDate;
}

/**
 * This function ensures that the date picked from the form
 * retains the current time, preventing a scenario where the
 * new valuation ends up pre-dating an existing one if a sync
 * just occurred at a later time on the same date.
 *
 * If the resulting date is still before the current valuation,
 * we increment it slightly so the new valuation won't overwrite
 * the previous one.
 */
function getDateForManualIntegratedValuationUpdate(currentValuationDate: Date) {
  const newValuationDate = getDateWithCurrentTime(currentValuationDate);

  if (isBefore(newValuationDate, currentValuationDate)) {
    // Ensure the new date is after the existing valuation
    return addMilliseconds(currentValuationDate, 1);
  }

  return newValuationDate;
}

function getValuationSourceFromClientIntegrationKind(
  integrationType: IntegrationClientKind | null
) {
  switch (integrationType) {
    case IntegrationClientKind.Addepar:
      return AssetValuationV2ValuationSource.Addepar;
    case IntegrationClientKind.BlackDiamond:
      return AssetValuationV2ValuationSource.BlackDiamond;
    case IntegrationClientKind.Orion:
      return AssetValuationV2ValuationSource.Orion;
    default:
      return AssetValuationV2ValuationSource.Manual;
  }
}

export interface CreateUpdateEntityAccountInputParams {
  activeClientIntegrationKind: IntegrationClientKind | null;
  previousValuation: StructuredAssetsSubform_ValuationFragment | null;
  entityId: string;
  entityType: EntityType;
  subtypeId: string;
}

export function createUpdateEntityAccountInput(
  formValues: FullScreenStructuredAssetsModalForm,
  params: CreateUpdateEntityAccountInputParams
): AugmentedUpdateEntityInput {
  if (!formValues.structuredAssetsSubform.accountId) {
    throw new Error('Missing required accountId');
  }

  const isLinkedToIntegration = !isEmpty(formValues.integrationEntityIds);

  const entityUpdateProperties = assertExact<UpdateEntityInput>()({
    ...getBalanceSheetConfigurationUpdateProperties(formValues),
    ...getAssetIntegrationUpdateProperties(formValues),
  });

  if (!formValues.valuationAsOfDate) {
    throw new Error('Missing required valuationAsOfDate');
  }

  if (isLinkedToIntegration) {
    const previousValuationHadManualAssets = valuationHasAdditionalManualAssets(
      params.previousValuation
    );

    const nextEffectiveDate = getDateForManualIntegratedValuationUpdate(
      formValues.valuationAsOfDate
    );
    const createNewValuationInput: AugmentedCreateAssetValuationV2Input | null =
      (() => {
        // this is the base case. we're not attemping to add additional manual assets, and the previous
        // valuation didn't have any so we don't need to worry about clearing them out.
        if (
          !formValues.allowAdditionalManualAssets &&
          !previousValuationHadManualAssets
        ) {
          return null;
        }

        const integratedAssets = getIntegratedAssetsFromIntegratedValuation(
          params.previousValuation
        );
        const assetsToAdd = [
          ...getFormAssetsFromAssets(integratedAssets, {
            activeClientIntegrationType: params.activeClientIntegrationKind,
          }),
          // don't add additional manual assets if we're not allowing them
          ...(formValues.allowAdditionalManualAssets
            ? formValues.structuredAssetsSubform.assets
            : []),
        ];

        return {
          create: {
            effectiveDate: nextEffectiveDate,
            valuationSource: getValuationSourceFromClientIntegrationKind(
              params.activeClientIntegrationKind
            ),
          },
          withAssets: assetsToAdd.map((a) => {
            return {
              create: {
                classID: a.categoryId,
                displayName: a.title,
                integrationType: a.integrationType,
              },
              withAssetValue: {
                create: {
                  ownedValue: a.value,
                  ownershipType: AssetValueV2OwnershipType.ValueBased,
                },
              },
            };
          }),
        };
      })();

    return makeEntityTypeUniversalUpdateInput(
      params.entityId,
      params.entityType,
      {
        id: params.subtypeId,
        updateDesignerAccount: {
          id: formValues.structuredAssetsSubform.accountId,
          update: {},
          withValuations: createNewValuationInput
            ? [createNewValuationInput]
            : undefined,
        },
      },
      entityUpdateProperties
    );
  }

  const valuationAsOfDate = getDateWithCurrentTime(
    formValues.valuationAsOfDate
  );
  const updateEntityAccountInput: AugmentedUpdateEntityInput =
    makeEntityTypeUniversalUpdateInput(
      params.entityId,
      params.entityType,
      {
        id: params.subtypeId,
        updateDesignerAccount: {
          id: formValues.structuredAssetsSubform.accountId,
          update: {},
          // if linked to an integration, don't allow adding new valuations manually
          // this isn't generally an issue, but can be if moving *from* a manual valuation
          // scenario to an integrated valuatino scenario
          withValuations: [
            {
              create: {
                effectiveDate: valuationAsOfDate,
                description: formValues.structuredAssetsSubform.description,
                valuationSource: AssetValuationV2ValuationSource.Manual,
                documentIDs: formValues.structuredAssetsSubform.documentIds,
              },
              withAssets: formValues.structuredAssetsSubform.assets.map((a) => {
                return {
                  create: {
                    classID: a.categoryId,
                    displayName: a.title,
                  },
                  withAssetValue: {
                    create: {
                      ownedValue: a.value,
                      ownershipType: AssetValueV2OwnershipType.ValueBased,
                    },
                  },
                };
              }),
            },
          ],
        },
      },
      entityUpdateProperties
    );

  return updateEntityAccountInput;
}
