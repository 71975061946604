import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { NonTrustCharitableEntitySummaryData } from './NonTrustCharitableEntityDetails.types';

export function NonTrustCharitableEntityDetailsMiniTable({
  taxStatus,
  assetLocation,
  donorNames,
  effectiveDate,
}: NonTrustCharitableEntitySummaryData) {
  const rows = useMemo(() => {
    return compact([
      assetLocation && {
        label: 'Asset location',
        value: assetLocation,
      },
      donorNames && {
        label: 'Donor(s)',
        value: (
          <TypographyClamped
            sx={{
              textAlign: 'right',
            }}
            lines={2}
            variant="h6"
          >
            {donorNames.join(', ')}
          </TypographyClamped>
        ),
      },
      taxStatus?.value && {
        label: 'Tax status',
        value: taxStatus.value,
      },
      effectiveDate && {
        label: 'Effective Date',
        value: formatDateToMonDDYYYY(effectiveDate),
      },
    ]);
  }, [assetLocation, donorNames, effectiveDate, taxStatus?.value]);

  return (
    <MiniTable
      variant="default"
      rowProps={{
        alignItems: 'start',
      }}
      rows={rows}
    />
  );
}
