import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { COLORS } from '@/styles/tokens/colors';

interface EntityDetailNameCardProps {
  leftText: string;
  rightText?: string;
}

// EntityDetailNameCard is the card component used to show entity trustees, controlling parties, owners, etc.
export function EntityDetailNameCard({
  leftText,
  rightText,
}: EntityDetailNameCardProps) {
  const LeftTextComponent = useMemo(() => {
    if (leftText && rightText) {
      return <Typography variant="subtitle2">{leftText}</Typography>;
    }
    return (
      <Typography variant="h6" component="span">
        {leftText}
      </Typography>
    );
  }, [leftText, rightText]);

  const RightTextComponent = useMemo(() => {
    if (rightText) {
      return (
        <TypographyClamped
          sx={{
            textAlign: 'right',
          }}
          lines={2}
          variant="h6"
        >
          {rightText}
        </TypographyClamped>
      );
    }
    return null;
  }, [rightText]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      p={1.5}
      borderRadius="4px"
      border={`1px solid ${COLORS.NEUTRAL_GRAY[200]}`}
      bgcolor={COLORS.NEUTRAL_GRAY[100]}
      spacing={1}
    >
      {LeftTextComponent}
      {RightTextComponent}
    </Stack>
  );
}
