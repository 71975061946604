import { compact } from 'lodash';
import { useMemo } from 'react';

import { CalloutListCardProps } from '@/components/layout/CalloutListCard/CalloutListCard';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { SUBSTITUTION_THRESHOLD_LABEL } from '@/modules/entities/details/grat/GRATTrustDetails';
import { COLORS } from '@/styles/tokens/colors';

const EXCLUDED_LABELS = [SUBSTITUTION_THRESHOLD_LABEL];

interface TermDetailsMiniTableProps {
  trustDetailsItems: CalloutListCardProps['items'];
}

export function TermDetailsMiniTable({
  trustDetailsItems,
}: TermDetailsMiniTableProps) {
  const filteredTrustDetailsItems = useMemo(() => {
    return trustDetailsItems.filter((item) => {
      if (EXCLUDED_LABELS.includes(item.label ?? '')) {
        return false;
      }
      return !!item.value && item.value !== EMPTY_CONTENT_HYPHEN;
    });
  }, [trustDetailsItems]);

  const rows: MiniTableRow[] = useMemo(() => {
    return compact([
      {
        label: 'Term details',
        labelProps: {
          variant: 'h6',
          component: 'span',
          color: COLORS.NEUTRAL_GRAY[900],
        },
        value: '',
      },
      ...filteredTrustDetailsItems.flatMap((item) => {
        if (typeof item.value === 'string' && typeof item.label === 'string') {
          return {
            label: item.label,
            value: item.value,
          };
        }
        return [];
      }),
    ]);
  }, [filteredTrustDetailsItems]);

  if (filteredTrustDetailsItems.length === 0) {
    return null;
  }

  return (
    <MiniTable
      variant="default"
      rowProps={{
        alignItems: 'start',
      }}
      rows={rows}
    />
  );
}
