import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { AccessParameterFrequency, BeneficiaryLevel } from '@/types/schema';

export const ACCESS_PARAMETER_FREQUENCY_DISPLAY_NAMES: Record<
  AccessParameterFrequency,
  string
> = {
  [AccessParameterFrequency.Monthly]: 'Monthly',
  [AccessParameterFrequency.Quarterly]: 'Quarterly',
  [AccessParameterFrequency.Semiannually]: 'Semiannually',
  [AccessParameterFrequency.Annually]: 'Annually',
};

export const ACCESS_PARAMETER_FREQUENCY_ITEMS: SelectInputOption<AccessParameterFrequency>[] =
  [
    AccessParameterFrequency.Annually,
    AccessParameterFrequency.Semiannually,
    AccessParameterFrequency.Quarterly,
    AccessParameterFrequency.Monthly,
  ].map((value) => ({
    display: ACCESS_PARAMETER_FREQUENCY_DISPLAY_NAMES[value],
    value,
  }));

export const BENEFICIARY_LEVEL_DISPLAY_NAMES: Record<BeneficiaryLevel, string> =
  {
    [BeneficiaryLevel.Primary]: 'First',
    [BeneficiaryLevel.Secondary]: 'Second',
    [BeneficiaryLevel.Tertiary]: 'Third',
    [BeneficiaryLevel.Other]: 'Other',
  };

export const BENEFICIARY_LEVEL_ITEMS: SelectInputOption<BeneficiaryLevel>[] = [
  BeneficiaryLevel.Primary,
  BeneficiaryLevel.Secondary,
  BeneficiaryLevel.Tertiary,
  BeneficiaryLevel.Other,
].map((value) => ({
  display: BENEFICIARY_LEVEL_DISPLAY_NAMES[value],
  value,
}));
