import { Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Settings02Icon } from '@/components/icons/Settings02Icon';
import { COLORS } from '@/styles/tokens/colors';

import { useClientPresentationV2ModalContext } from '../../ClientPresentationV2ModalContext';
import { AddBundleMenu } from '../AddBundleMenu/AddBundleMenu';
import { TREE_ITEM_HEIGHT } from '../ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.components';

export function PresentationManager() {
  const { openPresentationSettingsModal, createBundle } =
    useClientPresentationV2ModalContext();
  const [isAddPageMenuOpen, setIsAddPageMenuOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        pl: 2,
        pr: 0.5,
      }}
      width="100%"
      height={TREE_ITEM_HEIGHT}
    >
      <Typography
        variant="subtitle2"
        sx={{ flexGrow: 1, fontWeight: 'bold', color: COLORS.NAVY[900] }}
      >
        Pages
      </Typography>
      <Stack direction="row" alignItems="center">
        <IconButton
          icon={Settings02Icon}
          size="xs"
          ariaLabel="Presentation settings"
          onClick={openPresentationSettingsModal}
          variant="transparent"
        />
        <IconButton
          ref={anchorRef}
          icon={PlusIcon}
          ariaLabel="Add page"
          onClick={() => setIsAddPageMenuOpen(true)}
          size="xs"
          variant="transparent"
        />
        {isAddPageMenuOpen && (
          <AddBundleMenu
            onClose={() => setIsAddPageMenuOpen(false)}
            anchorRef={anchorRef}
            onSelectBundleForConfiguration={createBundle}
          />
        )}
      </Stack>
    </Stack>
  );
}
