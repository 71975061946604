import { useCallback } from 'react';

import { CompleteInitializationEffect, EntityDiagramEffectFn } from '../types';

export function useCompleteInitialiationEffect(): EntityDiagramEffectFn<CompleteInitializationEffect> {
  return useCallback<EntityDiagramEffectFn<CompleteInitializationEffect>>(
    (_state, _effect, dispatch) => {
      dispatch({ type: 'COMPLETE_INITIALIZATION' });
    },
    []
  );
}
