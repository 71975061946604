import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import {
  AISuggestionsMatcherAddNewAnythingModalProps,
  AISuggestionsMatcherVariant,
  SuggestionsGroup,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  addNewAnythingFromSuggestionName,
  getMatchFromNewId,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.utils';
import { AISuggestionsMatcherGroup } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcherGroup';
import { useAiSuggestionsMatcherContext } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AiSuggestionKind, TrusteeTrusteeCategory } from '@/types/schema';

interface AISuggestionsMatcherProps {
  variant: AISuggestionsMatcherVariant;
  suggestions: CommonAiSuggestionFragment[];
  /**
   * Callback to be called before committing the matched suggestions.
   */
  preCommitMatchedSuggestions?: (areAllSuggestionsMatched: boolean) => void;
}

export function AISuggestionsMatcher({
  variant,
  suggestions,
  preCommitMatchedSuggestions,
}: AISuggestionsMatcherProps) {
  const { householdId } = useHouseholdDetailsContext();

  const {
    setMatcherVariant,
    matchedSuggestions,
    setMatchedSuggestions,
    commitMatchedSuggestions,
    matcherRef,
    matcherConfigs: {
      nounPlural,
      calloutText,
      suggestionKinds,
      addNewAnythingModalProps,
      showCommitButtonOnEmptySuggestions,
    },
  } = useAiSuggestionsMatcherContext();

  const [addAnythingModalOpen, setAddAnythingModalOpen] = useState(false);
  const [addingForSuggestion, setAddingForSuggestion] =
    useState<CommonAiSuggestionFragment | null>(null);
  const [addAnythingModalPropsWithNames, setAddAnythingModalPropsWithNames] =
    useState<AISuggestionsMatcherAddNewAnythingModalProps>({
      addableTypes: [],
      addClientProfileModalProps: {},
      addClientOrganizationModalProps: {},
      addEntityModalProps: {},
    });

  useEffect(() => {
    setMatcherVariant(variant);
  }, [setMatcherVariant, variant]);

  // Sanity check to verify suggestions are supported by the current variant.
  const filteredSuggestions = useMemo(
    () => suggestions.filter((s) => suggestionKinds.includes(s.kind)),
    [suggestionKinds, suggestions]
  );

  const groupedSuggestions: SuggestionsGroup[] = useMemo(() => {
    switch (variant) {
      case AISuggestionsMatcherVariant.GRANTORS:
      case AISuggestionsMatcherVariant.SINGLE_GRANTOR:
      case AISuggestionsMatcherVariant.BENEFICIARIES_V2:
        return [
          {
            suggestions,
          },
        ];
      case AISuggestionsMatcherVariant.TRUSTEES: {
        const trusteesGroup: CommonAiSuggestionFragment[] = [];
        const successorTrusteesGroup: CommonAiSuggestionFragment[] = [];
        const trustAdvisorsGroup: CommonAiSuggestionFragment[] = [];

        suggestions.forEach((s) => {
          if (s.kind === AiSuggestionKind.Trustee) {
            if (s.trustee?.category === TrusteeTrusteeCategory.Primary) {
              trusteesGroup.push(s);
            } else if (
              s.trustee?.category === TrusteeTrusteeCategory.Successor
            ) {
              successorTrusteesGroup.push(s);
            }
          } else if (s.kind === AiSuggestionKind.TrustAdvisor) {
            trustAdvisorsGroup.push(s);
          }
        });

        return [
          {
            groupLabel: 'Trustees',
            suggestions: trusteesGroup,
          },
          {
            groupLabel: 'Successor trustees',
            suggestions: successorTrusteesGroup,
          },
          {
            groupLabel: 'Trust advisors',
            suggestions: trustAdvisorsGroup,
          },
        ];
      }
      case AISuggestionsMatcherVariant.BUSINESS_PEOPLE: {
        const ownersGroup: CommonAiSuggestionFragment[] = [];
        const keyPeopleGroup: CommonAiSuggestionFragment[] = [];

        suggestions.forEach((s) => {
          if (s.kind === AiSuggestionKind.BeneficialOwner) {
            ownersGroup.push(s);
          } else if (s.kind === AiSuggestionKind.KeyPerson) {
            keyPeopleGroup.push(s);
          }
        });

        return [
          {
            groupLabel: 'Beneficial owners',
            suggestions: ownersGroup,
          },
          {
            groupLabel: 'Key people',
            suggestions: keyPeopleGroup,
          },
        ];
      }
      case AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_1_DIES_FIRST:
      case AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_2_DIES_FIRST: {
        return [
          {
            suggestions,
          },
        ];
      }
    }
  }, [suggestions, variant]);

  const handleOpenAddAnythingModal = useCallback(
    (suggestion: CommonAiSuggestionFragment) => {
      const {
        initialClientProfileName,
        initialClientOrganizationName,
        initialEntityName,
      } = addNewAnythingFromSuggestionName(suggestion);

      const suggestionKind = suggestion.kind;
      const props = addNewAnythingModalProps[suggestionKind];
      if (!props) {
        throw new Error(
          `Unknown addNewAnythingModalProps to use for suggestionKind: ${suggestionKind}`
        );
      }

      const copy = Object.assign({}, props);

      copy.addClientProfileModalProps.initialClientProfileName = {
        ...initialClientProfileName,
      };
      copy.addClientOrganizationModalProps.initialOrganizationName =
        initialClientOrganizationName;
      copy.addEntityModalProps.initialEntityName = initialEntityName;

      setAddAnythingModalPropsWithNames(copy);
      setAddingForSuggestion(suggestion);
      setAddAnythingModalOpen(true);
    },
    [addNewAnythingModalProps]
  );

  const handleAfterAddAnythingCreate = useCallback(
    (newId: string) => {
      if (!addingForSuggestion) {
        return;
      }

      const match = getMatchFromNewId(newId);

      if (!match) {
        throw new Error(
          `Unsupported selected suggestion match option: ${newId}`
        );
      }

      setMatchedSuggestions((prevState) => ({
        ...prevState,
        [addingForSuggestion.id]: { ...match, suggestion: addingForSuggestion },
      }));

      setAddingForSuggestion(null);
      setAddAnythingModalOpen(false);
    },
    [addingForSuggestion, setMatchedSuggestions]
  );

  const handleCommitMatchedSuggestions = useCallback(() => {
    const areAllSuggestionsMatched = filteredSuggestions.every(
      (s) => matchedSuggestions[s.id]
    );
    preCommitMatchedSuggestions?.(areAllSuggestionsMatched);
    commitMatchedSuggestions();
  }, [
    matchedSuggestions,
    filteredSuggestions,
    commitMatchedSuggestions,
    preCommitMatchedSuggestions,
  ]);

  if (
    !householdId ||
    (!showCommitButtonOnEmptySuggestions && isEmpty(filteredSuggestions))
  ) {
    return null;
  }

  return (
    <>
      <Card
        variant="filled-teal"
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          overflow: 'visible',
        }}
        data-testid="ai-suggestions-matcher"
        inputRef={matcherRef}
      >
        {!isEmpty(filteredSuggestions) && (
          <Typography>
            The following {nounPlural} were identified. Specify whether they
            already exist in Luminary or need to be created.{' '}
            {calloutText && (
              <Typography fontWeight="bold" display="inline">
                {calloutText}
              </Typography>
            )}
          </Typography>
        )}
        {groupedSuggestions.map((sg) => (
          <AISuggestionsMatcherGroup
            key={sg.groupLabel}
            variant={variant}
            suggestionsGroup={sg}
            onSetSuggestionMatch={(suggestionId, matched) => {
              setMatchedSuggestions((prevState) => ({
                ...prevState,
                [suggestionId]: matched,
              }));
            }}
            onOpenAddAnythingModal={handleOpenAddAnythingModal}
          />
        ))}
        <Button
          variant="primary"
          size="lg"
          onClick={handleCommitMatchedSuggestions}
          data-testid="button-commitMatchedSuggestions"
        >
          Update form with all identified {nounPlural}
        </Button>
      </Card>
      <AddNewAnythingModal
        handleClose={() => setAddAnythingModalOpen(false)}
        onAfterCreate={handleAfterAddAnythingCreate}
        isOpen={addAnythingModalOpen}
        householdId={householdId}
        {...addAnythingModalPropsWithNames}
      />
    </>
  );
}
