import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { MenuPopper } from '@/components/poppers/MenuPopper/MenuPopper';
import { useFormContext } from '@/components/react-hook-form';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { ClientPresentationV2Shape } from '../../ClientPresentationDesignerV2.types';
import { isSinglePageBundle } from '../ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { getEmptyBundleForType } from './AddBundleMenu.utils';

export interface AddBundleMenuProps {
  onClose: () => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
  onSelectBundleForConfiguration: (bundleKind: PresentationBundleKind) => void;
}

export function AddBundleMenu({
  onClose,
  anchorRef,
  onSelectBundleForConfiguration,
}: AddBundleMenuProps) {
  const { control, setValue } = useFormContext<ClientPresentationV2Shape>();
  const [bundles] = useWatch({ control, name: ['bundles'] });

  const handleSelect = useCallback(
    (bundleKind: PresentationBundleKind) => {
      diagnostics.info('bundleKind', { bundleKind });

      if (isSinglePageBundle(bundleKind)) {
        const newBundle = getEmptyBundleForType(bundleKind);

        if (!newBundle) {
          return;
        }

        const newBundles = bundles.slice();
        newBundles.push(newBundle);

        setValue('bundles', newBundles);
      } else {
        onSelectBundleForConfiguration(bundleKind);
      }
      onClose();
    },
    [bundles, onClose, setValue, onSelectBundleForConfiguration]
  );

  const hasTableOfContentsBundle = bundles.some(
    (bundle) => bundle.type === PresentationBundleKind.TableOfContentsBundle
  );

  return (
    <MenuPopper anchorRef={anchorRef} open onClose={onClose}>
      {/*
      this section will be necessary for M2
      <SelectItemGroupLabel label="Estate overview" />
      */}
      <SelectItemGroupLabel label="Summaries" />
      <MenuItem
        label="Waterfall overview"
        onClick={() =>
          handleSelect(PresentationBundleKind.WaterfallOverviewBundle)
        }
      />
      {/*
      TODO: move the hasTableOfContentsBundle check to wrap just the menu item once we have 2+ options in this menu
      */}
      {!hasTableOfContentsBundle && (
        <>
          <SelectItemGroupLabel label="Other" />
          {/* 
      Custom pages won't be supported until M2
      <MenuItem
        label="Add custom page"
        onClick={() => handleSelect(PresentationBundleKind.CustomPageBundle)}
      />
      */}
          <MenuItem
            label="Table of contents"
            onClick={() =>
              handleSelect(PresentationBundleKind.TableOfContentsBundle)
            }
          />
        </>
      )}
    </MenuPopper>
  );
}
