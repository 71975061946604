import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { Legend, LegendSection } from '@/components/charts/Legend/Legend';
import { StackedHorizontalBarTooltip } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import {
  StackedHorizontalBarGroup,
  StackedHorizontalBarsWithLabels,
} from '@/components/charts/StackedHorizontalBarGroup/StackedHorizontalBarGroup';
import { CompoundHeader } from '@/components/display/CompoundHeader/CompoundHeader';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { AfterDeath, EntityInEstateStatus } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { HypotheticalSaleLoanFormShape } from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import {
  SaleLoanIllustration_EstateWaterfallFragment,
  SaleLoanIllustration_EstateWaterfallVizNodeFragment,
  SaleLoanIllustration_TaxSummaryFragment,
} from './graphql/SaleLoanIllustration.generated';
import { PROJECTION_PSEUDO_ID } from './SaleLoanIllustrationContent.utils';

interface SaleLoanIllustrationBarChartProps {
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null;
}

interface BarChartSectionValues {
  inEstateValue: Decimal;
  outOfEstateValue: Decimal;
  taxValue: Decimal;
  giftedAssetsValue: Decimal;
}

const emptyBarChartSectionValues: BarChartSectionValues = {
  inEstateValue: new Decimal(0),
  outOfEstateValue: new Decimal(0),
  taxValue: new Decimal(0),
  giftedAssetsValue: new Decimal(0),
};

function getTaxValueFromSummary(
  taxSummary: SaleLoanIllustration_TaxSummaryFragment | null
) {
  const federalTax = taxSummary?.federalTax?.tax ?? new Decimal(0);
  const stateTax = sumDecimalJS(taxSummary?.stateTax?.map((t) => t.tax) ?? []);
  return federalTax.add(stateTax);
}

function getValuesForSection(
  nodes: SaleLoanIllustration_EstateWaterfallVizNodeFragment[],
  deathEventTaxSummary: SaleLoanIllustration_TaxSummaryFragment | null,
  targetDeathEvent: AfterDeath
): Omit<BarChartSectionValues, 'giftedAssetsValue'> {
  const values = {
    inEstateValue: new Decimal(0),
    outOfEstateValue: new Decimal(0),
    taxValue: getTaxValueFromSummary(deathEventTaxSummary),
  };

  nodes.forEach((n) => {
    // Don't sum the value of this node unless it's the target death event
    if (n.afterDeath !== targetDeathEvent) return;

    switch (n.inEstateStatus) {
      case EntityInEstateStatus.InEstate:
        values.inEstateValue = values.inEstateValue.add(n.value);
        break;
      case EntityInEstateStatus.OutOfEstate:
        values.outOfEstateValue = values.outOfEstateValue.add(n.value);
        break;
    }
  });

  return values;
}

function getBarChartValues(
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null,
  afterDeath: AfterDeath
): { withPlan: BarChartSectionValues; noPlan: BarChartSectionValues } {
  if (!waterfall) {
    return {
      withPlan: emptyBarChartSectionValues,
      noPlan: emptyBarChartSectionValues,
    };
  }

  const projectedLoan =
    waterfall.visualizationWithSaleLoanProjections.loans.find(
      (l) => l.pseudoID === PROJECTION_PSEUDO_ID
    );
  const valueHistoryItem = projectedLoan?.valueHistory[0];
  const giftedAssetsValue =
    valueHistoryItem?.beginningOfYearValue ?? new Decimal(0);

  const withPlanTaxSummary = (() => {
    switch (afterDeath) {
      case AfterDeath.None:
        return waterfall.visualizationWithSaleLoanProjections
          .beforeFirstDeathTaxSummary;
      case AfterDeath.First:
        return waterfall.visualizationWithSaleLoanProjections
          .firstDeathTaxSummary;
      case AfterDeath.Second:
        return waterfall.visualizationWithSaleLoanProjections
          .secondDeathTaxSummary;
      default:
        throw new UnreachableError({
          case: afterDeath,
          message: `Invalid after death scenario: ${afterDeath}`,
        });
    }
  })();

  const withoutPlanTaxSummary = (() => {
    switch (afterDeath) {
      case AfterDeath.None:
        return waterfall.visualizationWithoutSaleLoanProjections
          .beforeFirstDeathTaxSummary;
      case AfterDeath.First:
        return waterfall.visualizationWithoutSaleLoanProjections
          .firstDeathTaxSummary;
      case AfterDeath.Second:
        return waterfall.visualizationWithoutSaleLoanProjections
          .secondDeathTaxSummary;
    }
  })();

  const values = {
    withPlan: {
      ...getValuesForSection(
        waterfall.visualizationWithSaleLoanProjections.nodes,
        withPlanTaxSummary ?? null,
        afterDeath
      ),
      giftedAssetsValue,
    },
    noPlan: {
      ...getValuesForSection(
        waterfall.visualizationWithoutSaleLoanProjections.nodes,
        withoutPlanTaxSummary ?? null,
        afterDeath
      ),
      giftedAssetsValue: new Decimal(0),
    },
  };

  return values;
}

export function SaleLoanIllustrationBarChart({
  waterfall,
}: SaleLoanIllustrationBarChartProps) {
  const chartColorDefinitions = useChartColorDefinitions();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const semanticColorMap = useMemo(() => {
    return {
      IN_ESTATE: chartColorDefinitions.PRIMARY.backgroundColor,
      OUT_OF_ESTATE: chartColorDefinitions.SECONDARY.backgroundColor,
      GIFT_AND_ESTATE_TAXES: chartColorDefinitions.NEGATIVE.backgroundColor,
    };
  }, [chartColorDefinitions]);

  const legendSections: LegendSection[] = [
    { label: 'In-estate', color: semanticColorMap.IN_ESTATE },
    { label: 'Out-of-estate', color: semanticColorMap.OUT_OF_ESTATE },
    {
      label: 'Gift & estate taxes',
      color: semanticColorMap.GIFT_AND_ESTATE_TAXES,
    },
  ];

  const [afterDeathScenario] = useWatch({
    control,
    name: ['illustrationScenario'],
  });

  const { withPlan, noPlan } = getBarChartValues(waterfall, afterDeathScenario);

  const bars: StackedHorizontalBarsWithLabels[] = useMemo(
    () => [
      {
        label: 'No plan',
        sections: [
          {
            groupName: 'No plan',
            value: noPlan.inEstateValue.toNumber(),
            color: semanticColorMap.IN_ESTATE,
            label: 'In-estate',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(noPlan.inEstateValue)}
                label="In-estate"
              />
            ),
          },
          {
            groupName: 'No plan',
            value: noPlan.outOfEstateValue.toNumber(),
            color: semanticColorMap.OUT_OF_ESTATE,
            label: 'Out of estate',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(noPlan.outOfEstateValue)}
                label="Out of estate"
              />
            ),
          },
          {
            groupName: 'No plan',
            value: noPlan.taxValue.toNumber(),
            color: semanticColorMap.GIFT_AND_ESTATE_TAXES,
            label: 'Gift & estate taxes',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(noPlan.taxValue)}
                label="Gift & estate taxes"
              />
            ),
          },
        ],
      },
      {
        label: 'With sale',
        sections: [
          {
            groupName: 'With sale',
            value: withPlan.inEstateValue.toNumber(),
            color: semanticColorMap.IN_ESTATE,
            label: 'In-estate',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(withPlan.inEstateValue)}
                label="In-estate"
              />
            ),
          },
          {
            groupName: 'With sale',
            value: withPlan.outOfEstateValue.toNumber(),
            color: semanticColorMap.OUT_OF_ESTATE,
            label: 'Out of estate',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(withPlan.outOfEstateValue)}
                label="Out of estate"
              />
            ),
          },
          {
            groupName: 'With sale',
            value: withPlan.taxValue.toNumber(),
            color: semanticColorMap.GIFT_AND_ESTATE_TAXES,
            label: 'Gift & estate taxes',
            tooltip: (
              <StackedHorizontalBarTooltip
                value={new Decimal(withPlan.taxValue)}
                label="Gift & estate taxes"
              />
            ),
          },
        ],
      },
    ],
    [
      noPlan.inEstateValue,
      noPlan.outOfEstateValue,
      noPlan.taxValue,
      semanticColorMap.IN_ESTATE,
      semanticColorMap.OUT_OF_ESTATE,
      semanticColorMap.GIFT_AND_ESTATE_TAXES,
      withPlan.inEstateValue,
      withPlan.outOfEstateValue,
      withPlan.taxValue,
    ]
  );

  const planBenefit = useMemo(() => {
    const noPlanTotal = noPlan.inEstateValue
      .add(noPlan.outOfEstateValue)
      .sub(noPlan.taxValue);
    const withPlanTotal = withPlan.inEstateValue
      .add(withPlan.outOfEstateValue)
      .sub(withPlan.taxValue);
    return withPlanTotal.sub(noPlanTotal);
  }, [
    noPlan.inEstateValue,
    noPlan.outOfEstateValue,
    noPlan.taxValue,
    withPlan.inEstateValue,
    withPlan.outOfEstateValue,
    withPlan.taxValue,
  ]);

  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={3} alignItems="center" width="100%">
          <Box flexGrow={1}>
            <StackedHorizontalBarGroup
              bars={bars}
              showLegend={false}
              headerSpacing={0}
            />
          </Box>
          <Box flexShrink={0}>
            <CompoundHeader
              subheading="Benefit of sale"
              heading={formatCurrencyNoDecimals(planBenefit)}
              invertLabel
            />
          </Box>
        </Stack>
        <Legend sections={legendSections} />
      </Stack>
    </Card>
  );
}
