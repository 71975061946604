import { startCase } from 'lodash';

import { STATE_CODE_TO_NAME } from '@/constants/states';

/**
 * Converts a jurisdiction state code into a display string.
 * Handles both US state codes and "OFFSHORE" cases.
 *
 * @param jurisdictionStateCode - The state code or "OFFSHORE"
 * @returns The formatted jurisdiction display name or undefined if no code provided
 */
export function getJurisdictionDisplay(
  jurisdictionStateCode: string | null | undefined
): string | undefined {
  if (!jurisdictionStateCode) {
    return undefined;
  }

  // this is either a state code or the word "OFFSHORE"
  return (
    STATE_CODE_TO_NAME[jurisdictionStateCode] ??
    startCase(jurisdictionStateCode.toLowerCase())
  );
}
