import { find, first, some } from 'lodash';

// eslint-disable-next-line luminary-rules/no-page-imports
import { ManageDispositions_EntityFragment } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/graphql/ManageDispositionsPage.generated';
import { AiSuggestionKind, KgRootStatus } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

/**
 * Determines if an entity has unacknowledged AI DP suggestions by traversing
 * the input entity -> async job -> kgroot -> ai suggestions
 * @param entity
 */
export function doesEntityHaveUnacknowledgedAIDPSuggestions(
  entity: ManageDispositions_EntityFragment
) {
  // Find a completed AI DP KGRoot on the entity
  const kgRoot = find(
    first(getNodes(entity?.prevAIDPJobs))?.kgRoot,
    (r) => r.status === KgRootStatus.Complete
  );

  // Are there any unack'd AI DP suggestions on the kgroot?
  return some(
    kgRoot?.aiSuggestions,
    (s) =>
      s.acceptanceStatus === null &&
      (s.kind === AiSuggestionKind.DpRecipientGrantor_1DiesFirst ||
        s.kind === AiSuggestionKind.DpRecipientGrantor_2DiesFirst)
  );
}
