import { Stack, Typography } from '@mui/material';
import { isEqual, some } from 'lodash';
import { useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import { Callout } from '@/components/notifications/Callout/Callout';
import { Loader } from '@/components/progress/Loader/Loader';
import { useFormContext } from '@/components/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import {
  HypotheticalSaleLoanFormKind,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { mapFormDataToCreateInput } from '../EstateWaterfallHypotheticalSaleLoanModal.utils';
import {
  SaleLoanIllustration_EstateWaterfallFragment,
  useSaleLoanEstateWaterfallQuery,
} from './graphql/SaleLoanIllustration.generated';
import { SaleLoanIllustrationBarChart } from './SaleLoanIllustrationBarChart';
import { PROJECTION_PSEUDO_ID } from './SaleLoanIllustrationContent.utils';
import { SaleLoanIllustrationDetails } from './SaleLoanIllustrationDetails/SaleLoanIllustrationDetails';
import { SaleLoanIllustrationScenarioSelector } from './SaleLoanIllustrationScenarioSelector';

interface SaleLoanIllustrationContentProps {
  waterfallId: string;
  householdId: string;
  saleLoanId: string | null; // saleLoanId is null if we're creating a new loan and it's not yet persisted
  modalKind: HypotheticalSaleLoanFormKind;
}

function hasAllRequiredFundingData(formData: HypotheticalSaleLoanFormShape) {
  switch (formData.fundingKind) {
    case 'proRata':
      return formData.proRataAmount || formData.proRataPercent;
    case 'assets': {
      const hasSelectedItems =
        formData.selectedAssetClassesAndBusinessIds.length > 0;
      const hasDefinedAmount = some(
        formData.selectedAssetClassAndBusinessValues,
        (value) => Boolean(value?.amount)
      );
      const hasDefinedPercent = some(
        formData.selectedAssetClassAndBusinessValues,
        (value) => Boolean(value?.percent)
      );
      return hasSelectedItems && (hasDefinedAmount || hasDefinedPercent);
    }
    default:
      return false;
  }
}

export function SaleLoanIllustrationContent({
  waterfallId,
  householdId,
  saleLoanId,
  modalKind,
}: SaleLoanIllustrationContentProps) {
  const [projectionWaterfall, setProjectionWaterfall] =
    useState<SaleLoanIllustration_EstateWaterfallFragment | null>(null);
  const { watch } = useFormContext<HypotheticalSaleLoanFormShape>();
  const formData = watch();
  const hypotheticalSaleLoanInput = useMemo(() => {
    // we don't throw inside mapFormDataToCreateInput for these, so we check them here to
    // make sure we don't start showing projection data too soon
    if (!hasAllRequiredFundingData(formData)) {
      return null;
    }

    if (!formData.paymentModel) {
      return null;
    }

    try {
      return [
        {
          // we skip the projection if we don't have a valid form data
          create: mapFormDataToCreateInput(
            formData,
            modalKind,
            waterfallId,
            householdId
          ),
          pseudoID: PROJECTION_PSEUDO_ID,
          replaces: saleLoanId ?? null,
        },
      ];
    } catch (error) {
      // we don't have a valid form data, so we can't create a projection. this is expected when the user
      // is still filling out the form and we have incomplete data, so we don't want to log or show an error
      diagnostics.debug(
        '[SALE/LOAN] Invalid or insufficient form data; not creating projection',
        { error }
      );
      return null;
    }
  }, [formData, modalKind, waterfallId, householdId, saleLoanId]);

  const previousInput = usePrevious(hypotheticalSaleLoanInput);
  const isSameInput = isEqual(previousInput, hypotheticalSaleLoanInput);
  const skip = !hypotheticalSaleLoanInput || isSameInput;
  const { error, loading } = useSaleLoanEstateWaterfallQuery({
    fetchPolicy: 'no-cache',
    variables: {
      waterfallId,
      simulationInputs: {
        hypotheticalSaleLoans: hypotheticalSaleLoanInput!,
      },
      noSaleLoanSimulationInputs: {
        hypotheticalSaleLoans: [
          {
            create: null,
            pseudoID: PROJECTION_PSEUDO_ID,
            replaces: saleLoanId ?? null,
          },
        ],
      },
    },
    onError: (error) => {
      diagnostics.error(
        'Error fetching projected waterfall for sale/loan designer',
        error
      );
    },
    onCompleted: (data) => {
      setProjectionWaterfall(data?.estateWaterfalls.edges?.[0]?.node ?? null);
    },
    skip,
  });

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h1">Illustration</Typography>
          {loading && <Loader circularProgressProps={{ size: 20 }} />}
        </Stack>
        <SaleLoanIllustrationScenarioSelector />
      </Stack>

      <SaleLoanIllustrationBarChart waterfall={projectionWaterfall} />

      {error && (
        <Callout severity="error">
          There was an error fetching the projected details. Please refresh the
          page and try again.
        </Callout>
      )}

      <SaleLoanIllustrationDetails waterfall={projectionWaterfall} />
    </Stack>
  );
}
