import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { getSaleLoanKindDisplay } from '@/modules/hypotheticalSaleLoans/displayUtils';
import { COLORS } from '@/styles/tokens/colors';
import { HypotheticalSaleLoanRecipientKind } from '@/types/schema';
import { HypotheticalSaleLoanSourceKind } from '@/types/schema';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { GetHypotheticalTransfers_HypotheticalSaleLoanFragment } from './graphql/GetHypotheticalTransfers.generated';
import { SaleLoanWithProjections } from './hooks/useGetHypotheticalTransfers';
import { getSaleLoanFundingValueFromEWLoan } from './HypotheticalTransfersCard.utils';
import { TransferTab } from './TransferItems';

function getSaleLoanHeading(
  direction: TransferTab,
  saleLoan: GetHypotheticalTransfers_HypotheticalSaleLoanFragment
): string {
  if (direction === TransferTab.Outbound) {
    // Outbound means "to" recipient
    switch (saleLoan.recipientKind) {
      case HypotheticalSaleLoanRecipientKind.Entity:
        return (
          saleLoan.recipientEntity?.subtype?.displayName ?? 'Unknown entity'
        );
      case HypotheticalSaleLoanRecipientKind.Individual:
        return (
          saleLoan.recipientIndividual?.displayName ?? 'Unknown individual'
        );
      default:
        return 'Unknown recipient';
    }
  } else {
    // Inbound means "from" source
    switch (saleLoan.sourceKind) {
      case HypotheticalSaleLoanSourceKind.Entity:
        return saleLoan.sourceEntity?.subtype?.displayName ?? 'Unknown entity';
      case HypotheticalSaleLoanSourceKind.Individual:
        return saleLoan.sourceIndividual?.displayName ?? 'Unknown individual';
      default:
        return 'Unknown source';
    }
  }
}

interface SaleLoanItemsTableProps {
  saleLoansWithProjections: SaleLoanWithProjections[];
  direction: TransferTab;
  onEditSaleLoan: (saleLoanId: string) => void;
}

export function SaleLoanItemsTable({
  saleLoansWithProjections,
  direction,
  onEditSaleLoan,
}: SaleLoanItemsTableProps) {
  const isOutbound = direction === TransferTab.Outbound;

  const items = useMemo(() => {
    return saleLoansWithProjections.map(({ saleLoan, loanProjections }) => {
      const value = loanProjections
        ? getSaleLoanFundingValueFromEWLoan(loanProjections)
        : new Decimal(0);

      let heading = formatCurrencyNoDecimals(value);
      if (isOutbound) {
        heading = formatCurrencyNoDecimalsAccounting(value.times(-1));
      }

      let headingSx = undefined;
      if (direction === TransferTab.Outbound) {
        headingSx = { color: COLORS.MATH.NEGATIVE };
      }

      const startYear = saleLoan.startDate.getFullYear();
      const endYear = startYear + saleLoan.termLengthYears;

      return {
        id: saleLoan.id,
        heading: getSaleLoanHeading(direction, saleLoan),
        description: getSaleLoanKindDisplay(saleLoan.kind),
        context: {
          heading,
          headingSx,
          subheading: `${startYear}-${endYear}`,
          action: (
            <EditButton
              onClick={() => {
                onEditSaleLoan(saleLoan.id);
              }}
            />
          ),
        },
      };
    });
  }, [direction, isOutbound, onEditSaleLoan, saleLoansWithProjections]);

  const netAmount = useMemo(() => {
    return saleLoansWithProjections.reduce((acc, { loanProjections }) => {
      const value = loanProjections
        ? getSaleLoanFundingValueFromEWLoan(loanProjections)
        : new Decimal(0);

      return acc.plus(value);
    }, new Decimal(0));
  }, [saleLoansWithProjections]);

  return (
    <Stack>
      <Stack p={1.5} direction="row" bgcolor={COLORS.GRAY[100]}>
        <Box>
          <Typography variant="h6" component="p" color={COLORS.GRAY[500]}>
            Sales and loans
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="column"
        sx={{
          borderBottom: `1px solid ${COLORS.GRAY[200]}`,
          '&:last-child': {
            borderBottom: 'none',
          },
        }}
      >
        {items.length === 0 && <EmptyListItem>No items</EmptyListItem>}
        <GroupedHeaderList
          rowGroups={[{ items }]}
          withDividers
          ItemComponent={({ item }) => (
            <TableCell
              RightContent={
                item.context && <TableCellContext {...item.context} />
              }
              {...item}
            />
          )}
        />
        <Stack
          sx={{
            borderTop: `2px solid ${COLORS.NAVY[600]}`,
            px: 1.5,
            py: 2.5,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="span">
            Transferred {isOutbound ? 'out' : 'in'}
          </Typography>
          <Typography
            variant="h5"
            component="span"
            sx={{
              color: isOutbound ? COLORS.MATH.NEGATIVE : undefined,
            }}
          >
            {formatCurrencyNoDecimalsAccounting(
              netAmount.times(isOutbound ? -1 : 1)
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
