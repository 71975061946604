import { Maybe } from 'graphql/jsutils/Maybe';
import { compact, upperFirst } from 'lodash';

import { RichListItemProps } from '@/components/lists/RichListItem/RichListItem';
import { TrusteeFragment } from '@/modules/trustees/graphql/trustees.fragments.generated';
import {
  SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES,
  SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES_SHORT,
  TRUSTEE_DUTY_DISPLAY_NAMES,
} from '@/modules/trustees/trustees.constants';
import { TrusteeDutyKind, TrusteeLevel } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { englishJoinStringList } from '@/utils/formatting/strings';

import { TrustAdvisorsList_TrustAdvisorPartyFragment } from '../TrustAdvisorsList/graphql/TrustAdvisorsList.fragments.generated';
import {
  TrustTrusteesList_SuccessorTrusteeFragment,
  TrustTrusteesList_TrusteeFragment,
} from './graphql/TrustTrusteesList.fragments.generated';

// this particular function is actually used by both the TrusteesList and TrustAdvisorList
export function getTrusteeAdvisorDisplayName(
  trustAdvisorWithParty: TrustAdvisorsList_TrustAdvisorPartyFragment
): string {
  if (trustAdvisorWithParty.individual) {
    return trustAdvisorWithParty.individual.displayName;
  } else if (trustAdvisorWithParty.organization) {
    return trustAdvisorWithParty.organization.name;
  }

  const message = 'unsupported trust advisor party type';
  diagnostics.error(message, new Error(message), {
    trustAdvisorId: trustAdvisorWithParty.id,
  });
  return 'Unknown trust advisor';
}

function getTrusteeDisplayName(trusteeWithParty: TrusteeFragment): string {
  if (trusteeWithParty.individual) {
    return trusteeWithParty.individual.displayName;
  }

  if (trusteeWithParty.organization) {
    return trusteeWithParty.organization.name;
  }

  const message = 'unsupported trustee party type';
  diagnostics.error(message, new Error(message), {
    trusteeId: trusteeWithParty.id,
  });
  return 'Unknown trustee';
}

interface TrusteeDuty {
  kind: TrusteeDutyKind;
}

function getDutiesSummary(trusteeDuties: Maybe<TrusteeDuty[]>): string {
  const displayDutyKinds =
    trusteeDuties?.map((duty) => TRUSTEE_DUTY_DISPLAY_NAMES[duty.kind]) ?? [];
  const joinedList = englishJoinStringList(displayDutyKinds, '&');
  if (joinedList.length === 0) return joinedList;
  return upperFirst(joinedList);
}

export function getFormattedTrustee(
  trustee: TrustTrusteesList_TrusteeFragment
): RichListItemProps {
  return {
    heading: getTrusteeDisplayName(trustee),
    description: getDutiesSummary(trustee.duties),
    additionalItems: compact([trustee.notes]),
  };
}

export function getFormattedSuccessorTrustee(
  trustee: TrustTrusteesList_SuccessorTrusteeFragment
): RichListItemProps {
  return {
    heading: getTrusteeDisplayName(trustee),
    description: getDutiesSummary(trustee.duties),
    additionalItems: compact([trustee.notes]),
  };
}

const ORDERED_TRUSTEE_LEVELS = [
  TrusteeLevel.Primary,
  TrusteeLevel.Secondary,
  TrusteeLevel.Tertiary,
  TrusteeLevel.Other,
];

export function getGroupedSuccessorTrusteeRows(
  successorTrustees: TrustTrusteesList_SuccessorTrusteeFragment[],
  opts?: {
    format?: 'long' | 'short';
  }
) {
  const groupedSuccessorTrustees: Record<
    TrusteeLevel,
    TrustTrusteesList_SuccessorTrusteeFragment[]
  > = {
    [TrusteeLevel.Primary]: [],
    [TrusteeLevel.Secondary]: [],
    [TrusteeLevel.Tertiary]: [],
    [TrusteeLevel.Other]: [],
  };

  successorTrustees.forEach((trustee) => {
    const levelToGroup = trustee.level ?? TrusteeLevel.Other;
    groupedSuccessorTrustees[levelToGroup].push(trustee);
  });

  return compact(
    ORDERED_TRUSTEE_LEVELS.map((level) => {
      const rowItems = groupedSuccessorTrustees[level];
      // don't show sections if there are no items within the section
      if (rowItems.length === 0) return null;
      return {
        heading:
          opts?.format === 'short'
            ? SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES_SHORT[level]
            : SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES[level],
        items: rowItems.map((t) => ({
          ...getFormattedSuccessorTrustee(t),
          id: t.id,
        })),
      };
    })
  );
}
