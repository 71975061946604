import { first, isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';

import { AppNotifications } from '@/components/AppNotifications/AppNotifications';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { AIOnboardingModal } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal';
import { AsyncJobNotificationItem } from '@/modules/asyncJobs/AsyncJobNotifications/AsyncJobNotificationItem';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { useTrackAsyncJobNotification } from '@/modules/asyncJobs/AsyncJobNotifications/hooks/useTrackAsyncJobNotification';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { IMMEDIATE_EDIT_SEARCH_PARAM } from '@/modules/entities/hooks/useImmediateOpenEditModal';
import { ROUTE_KEYS } from '@/navigation/constants';
import { AsyncJobKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

interface AsyncJobNotificationsProps {
  colorTheme: 'light' | 'dark';
}

export function AsyncJobNotifications({
  colorTheme,
}: AsyncJobNotificationsProps) {
  const { navigate } = useNavigateToRoute();

  const { trackAsyncJobNotificationClick } = useTrackAsyncJobNotification();

  const { notifications, newNotificationPopup } =
    useAsyncJobNotificationsContext();

  const [lastClickedNotification, setLastClickedNotification] =
    useState<AsyncJobNotificationInfo | null>(null);

  const handleCompletedButtonOnClick = useCallback(
    (notification: AsyncJobNotificationInfo) => {
      trackAsyncJobNotificationClick(notification);

      switch (notification.type) {
        case AsyncJobNotificationInfoType.AI_ONBOARDING:
          // Setting this to a notification will trigger the AIOnboardingModal to open.
          setLastClickedNotification(notification);
          break;
        case AsyncJobNotificationInfoType.AI_DP: {
          const householdId = notification.household?.id;

          if (!householdId) {
            diagnostics.error(
              `cannot execute review notification action without household id. notification: ${JSON.stringify(notification)}`
            );
            return;
          }

          if (notification.asyncJob?.kind === AsyncJobKind.AiDpBatched) {
            // Go to the manage dispositions page to review batch runs
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS, {
              householdId,
            });
            return;
          }

          // Go to the entity details page's disposition tab to review single entity runs
          const entityId = first(notification?.asyncJob?.kgRoot)?.entity?.id;
          if (!entityId) {
            diagnostics.error(
              `cannot execute review notification action without entity id. notification: ${JSON.stringify(notification)}`
            );
            return;
          }
          navigate(
            ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
            {
              householdId,
              entityId,
            },
            {
              entityTab: 'dispositions',
              [IMMEDIATE_EDIT_SEARCH_PARAM]:
                EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
            }
          );
          break;
        }
        default:
          throw new UnreachableError({
            message: `undefined action for completed notification click ${notification.type}`,
            case: notification.type,
          });
      }
    },
    [navigate, trackAsyncJobNotificationClick]
  );

  if (isEmpty(notifications)) {
    return null;
  }

  return (
    <>
      <AppNotifications
        colorTheme={colorTheme}
        count={notifications.length}
        newNotificationPopup={newNotificationPopup}
      >
        {notifications.map((notification) => (
          <AsyncJobNotificationItem
            key={notification.id}
            notification={notification}
            onCompletedActionClick={() =>
              handleCompletedButtonOnClick(notification)
            }
          />
        ))}
      </AppNotifications>
      {lastClickedNotification &&
        lastClickedNotification.type ===
          AsyncJobNotificationInfoType.AI_ONBOARDING &&
        lastClickedNotification.household?.id && (
          <AIOnboardingModal
            householdId={lastClickedNotification.household.id}
            kgRootId={lastClickedNotification.id}
            isOpen={!!lastClickedNotification}
            onClose={() => setLastClickedNotification(null)}
          />
        )}
    </>
  );
}
