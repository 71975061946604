import { SubtleLabelItemProps } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ACCESS_PARAMETER_FREQUENCY_DISPLAY_NAMES } from '@/modules/beneficiaries/beneficiaries.constants';
import {
  EntityGstStatus,
  EntityInEstateStatus,
  Maybe,
  NonTrustEntityTaxStatus,
} from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import {
  UNITRUST_TYPE_ITEMS_V2,
  validUnitrustTypesV2,
} from '../EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { EntityType } from '../types/EntityType';
import { getEntityTypeFromSubtype } from '../utils/getEntityTypeFromSubtype';
import { EntityDetail_EntityFragment } from './graphql/EntityDetailPage.generated';

export interface EntityCardProps {
  entity: EntityDetail_EntityFragment;
  entityType: EntityType;
  onUpdate: () => void;
  hasValuation?: boolean;
  isPanelView: boolean;
}

export const EntityGstStatusDisplayNames: Record<EntityGstStatus, string> = {
  [EntityGstStatus.GstExempt]: 'GST exempt',
  [EntityGstStatus.GstNonExempt]: 'GST non-exempt',
  [EntityGstStatus.MixedGst]: 'Mixed GST',
};

export const EntityInEstateStatusDisplayNames: Record<
  EntityInEstateStatus,
  string
> = {
  [EntityInEstateStatus.InEstate]: 'In estate',
  [EntityInEstateStatus.OutOfEstate]: 'Out of estate',
};

export function getSharedTopLevelProperties(data: EntityDetail_EntityFragment) {
  if (!('id' in data.subtype)) {
    throw new Error(
      `Invalid trust subtype ${
        (data.subtype as EntityDetail_EntityFragment['subtype']).__typename
      }`
    );
  }

  return {
    entityType: getEntityTypeFromSubtype(data.subtype.__typename!),
    subtypeId: data.subtype.id,
  };
}

export function getEffectiveDateLabelItem(
  effectiveDate?: Maybe<Date>
): SubtleLabelItemProps {
  return {
    label: 'Effective date',
    value: (
      <LoadingSkeletonOrValue
        loading={false}
        value={effectiveDate ? formatDateToMonDDYYYY(effectiveDate) : undefined}
      />
    ),
  };
}

export function getPossibleLifetimeDurationLabelItem({
  termDurationLifetime,
  termDurationYears,
}: {
  termDurationLifetime: boolean;
  termDurationYears: number | null;
}) {
  if (termDurationLifetime) {
    return {
      label: 'Term length',
      value: 'Lifetime',
    };
  }

  if (termDurationYears) {
    return {
      label: 'Term length',
      value: `${termDurationYears} years`,
    };
  }

  return {
    label: 'Term length',
    value: EMPTY_CONTENT_HYPHEN,
  };
}

const nonTrustEntityTaxStatusDisplayNames: Record<
  NonTrustEntityTaxStatus,
  string
> = {
  [NonTrustEntityTaxStatus.NonTaxable]: 'Non-taxable entity',
  [NonTrustEntityTaxStatus.Taxable]: 'Taxable entity',
};

export function getFormattedNonTrustEntityTaxStatus(
  taxStatus: Maybe<NonTrustEntityTaxStatus>
): {
  value: string | undefined;
} {
  return {
    value: taxStatus
      ? nonTrustEntityTaxStatusDisplayNames[taxStatus]
      : undefined,
  };
}

export function getPayoutInformation({
  entity,
}: {
  entity: EntityCardProps['entity'];
}) {
  if (
    entity.subtype.__typename !== 'CRTTrust' &&
    entity.subtype.__typename !== 'CLTTrust'
  ) {
    throw new Error('Invalid trust type');
  }

  const lifetimeBeneficiaryAccessParameter =
    getNodes(entity.subtype.lifetimeBeneficiaries?.[0]?.accessParameters)[0] ??
    null;

  // If we do not have access params, we return the default
  if (!lifetimeBeneficiaryAccessParameter) {
    return {
      payoutType: EMPTY_CONTENT_HYPHEN,
      payoutAmount: EMPTY_CONTENT_HYPHEN,
    };
  }

  const unitrustTypes = validUnitrustTypesV2;
  const distributionKind = lifetimeBeneficiaryAccessParameter.kind;

  const [frequency, displayFrequency] = (() => {
    const frequency = lifetimeBeneficiaryAccessParameter.frequency;

    if (!frequency) {
      return [frequency, EMPTY_CONTENT_HYPHEN];
    }

    return [
      frequency,
      ACCESS_PARAMETER_FREQUENCY_DISPLAY_NAMES[frequency].toLowerCase(),
    ];
  })();

  const { amount, percentage } = lifetimeBeneficiaryAccessParameter;

  // Either "Annuity" or "Unitrust (x)"
  const payoutType = (() => {
    const isUnitrust = unitrustTypes.includes(distributionKind);

    const unitrustLabel = UNITRUST_TYPE_ITEMS_V2.find(
      (item) => item.value === distributionKind
    )?.summaryLabel;

    if (isUnitrust && !unitrustLabel) {
      throw new Error('Invalid unitrust type');
    }

    if (isUnitrust && unitrustLabel) {
      return `Unitrust (${unitrustLabel})`;
    }

    return 'Annuity';
  })();

  // Either "x.x% annually" or "$x annually"
  const payoutAmount = (() => {
    if (!frequency) {
      return EMPTY_CONTENT_HYPHEN;
    }

    // There must be a distribution percentage or amount
    if (!percentage && !amount) {
      return EMPTY_CONTENT_HYPHEN;
    }

    const displayAmount =
      (amount && formatCurrencyNoDecimals(amount)) ??
      (percentage && `${formatPercent(percentage, 1)}%`) ??
      EMPTY_CONTENT_HYPHEN;

    return `${displayAmount} ${displayFrequency}`;
  })();

  return {
    payoutType,
    payoutAmount,
  };
}
