import { Box, Stack, Typography } from '@mui/material';
import {
  GridSortCellParams,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import _, { first } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';

import { GREY_CANDY_CANE } from '@/components/charts/constants';
import {
  Section,
  StackedHorizontalBarTooltip,
} from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { InfoCircleIcon } from '@/components/icons/InfoCircleIcon';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { SHOW_ON_ROW_HOVER_CLASSNAME } from '@/components/tables/DataTable/components/ThemedDataGrid';
import { LegendColorRenderer } from '@/components/tables/DataTable/renderers/cell/LegendColorRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { DEFAULT_FILTER_SEARCH_PARAM } from '@/components/tables/FilterableTable/FilterableTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import {
  AfterDeath,
  EntityGstStatus,
  LoggedTransferPurpose,
  ScheduledDistributionKind,
} from '@/types/schema';
import { maxDecimalJS, sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM } from '../entities/components/EntityDetailsCard';
import { TrustDetailsTabs } from '../entities/details/entityDetails.types';
import { FilterOptions } from '../entities/entities.constants';
import { POWER_OF_APPOINTMENT_ITEMS } from '../entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { TESTAMENTARY_ENTITY_SEARCH_PARAM } from '../entities/testamentaryEntities/testamentaryEntities.utils';
import { HypotheticalEstateWaterfallBadge } from '../estateWaterfall/components/HypotheticalEstateWaterfallBadge';
import { useWaterfallSummaryContext } from '../estateWaterfall/contexts/waterfallSummary.context';
import { LoggedTransferPurposeCopyMap } from '../gifting/gifting.copy';
import { LogNewGiftModal } from '../gifting/LogNewGiftModal/LogNewGiftModal';
import { LogNewTransferModal } from '../gifting/LogNewTransferModal/LogNewTransferModal';
import { TransferType } from '../gifting/LogNewTransferModal/LogNewTransferModal.types';
import { TransferOptionType } from '../gifting/TransferOption.type';
import { useHouseholdDetailsContext } from '../household/contexts/householdDetails.context';
import { getHypotheticalTransferTaxDisplay } from '../summaryPanels/HypotheticalTransfersSummaryPanel/HypotheticalTransfersSummaryPanel.utils';
import {
  getAccessParameterCopy,
  getAgeBoundsCopy,
  getScheduledDistributionCopy,
} from '../trusts/TrustBeneficiariesList/ExpandableBeneficiaryTable.utils';
import { useBeneficiaryReportingContext } from './beneficiaryReporting.context';
import {
  BeneficiaryReport,
  BeneficiaryReportBenefitKind,
  BeneficiaryReportDirectGift,
  BeneficiaryReportDirectGiftKind,
  BeneficiaryReportFullAccess,
  BeneficiaryReportingRowVariant,
  BeneficiaryReportPartialAccess,
  BeneficiaryReportScheduledDistribution,
  BeneficiaryReportScheduledDistributionKind,
  BenefitsTableRowData,
} from './beneficiaryReporting.types';
import {
  BeneficiaryReporting_PowerOfAppointmentFragment,
  BeneficiaryReporting_ScheduledDistributionFragment,
} from './graphql/BeneficiaryReporting.generated';

export function getPowerOfAppointmentText(
  powerOfAppointment: BeneficiaryReporting_PowerOfAppointmentFragment | null
) {
  const powerOfAppointmentItem = POWER_OF_APPOINTMENT_ITEMS.find(
    (item) => item.value === powerOfAppointment?.power
  )?.display;

  if (!powerOfAppointmentItem) {
    return '';
  }

  return `“${powerOfAppointmentItem}” power of appointment`;
}

function getTwoLineRowDataComparator(field: 'entity' | 'parameters') {
  return (
    v1: BenefitsTableRowData[typeof field],
    v2: BenefitsTableRowData[typeof field],
    p1: GridSortCellParams,
    p2: GridSortCellParams
  ) => {
    return gridStringOrNumberComparator(v1?.lineOne, v2?.lineOne, p1, p2);
  };
}

function getStringOrNumberComparitor() {
  return (
    v1: string | Decimal,
    v2: string | Decimal,
    p1: GridSortCellParams,
    p2: GridSortCellParams
  ) => {
    return gridStringOrNumberComparator(v1, v2, p1, p2);
  };
}

export function getBenefitsTableColumns(
  tableHeader: string
): Column<BenefitsTableRowData>[] {
  return [
    {
      field: 'sectionIndicator',
      headerName: '',
      renderCell: LegendColorRenderer({
        backgroundColor: ({ row }) =>
          row.sectionIndicator?.backgroundColor ?? '',
      }),
      align: 'center',
      sortable: false,
      width: 55,
      minWidth: 55,
      maxWidth: 55,
    },
    {
      field: 'entity',
      headerName: tableHeader,
      flex: 1.5,
      sortable: false,
      disableReorder: true,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (row.variant === BeneficiaryReportingRowVariant.Subtitle) {
            return (
              <Typography
                variant="h6"
                component="span"
                style={{
                  color: COLORS.GRAY[600],
                }}
              >
                {row.entity.lineOne}
              </Typography>
            );
          }

          return row.entity.lineOne;
        },
        lineOneProps: ({ row }) => row.entity.lineOneProps ?? {},
        lineTwo: ({ row }) => row.entity.lineTwo,
        rightContent: ({ row }) => row.entity.rightContent,
        textAccompaniment: ({ row }) => row.entity.textAccompaniment,
      }),
      sortComparator: getTwoLineRowDataComparator('entity'),
    },
    {
      field: 'parameters',
      headerName: '',
      flex: 1,
      sortable: false,
      disableReorder: true,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (row.variant === BeneficiaryReportingRowVariant.Subtitle) {
            return (
              <Typography
                variant="h6"
                component="span"
                style={{
                  color: COLORS.GRAY[600],
                }}
              >
                {row.parameters?.lineOne ?? ''}
              </Typography>
            );
          }

          return row.parameters?.lineOne ?? '';
        },
        lineOneProps: ({ row }) => row.parameters?.lineOneProps ?? {},
        lineTwo: ({ row }) => row.parameters?.lineTwo,
        rightContent: ({ row }) => row.parameters?.rightContent,
        textAccompaniment: ({ row }) => row.parameters?.textAccompaniment,
      }),
      sortComparator: getTwoLineRowDataComparator('parameters'),
    },
    {
      field: 'distributionValue',
      headerName: '',
      align: 'right',
      flex: 1,
      sortable: false,
      disableReorder: true,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (!row.distributionValue) {
            return '';
          }

          if (typeof row.distributionValue === 'string') {
            return row.distributionValue;
          }

          if (
            row.variant === BeneficiaryReportingRowVariant.Subtitle &&
            'lineOne' in row.distributionValue
          ) {
            return (
              <Typography
                variant="h6"
                component="span"
                style={{
                  color: COLORS.GRAY[600],
                }}
              >
                {row.distributionValue.lineOne}
              </Typography>
            );
          }

          if ('lineOne' in row.distributionValue) {
            return row.distributionValue.lineOne;
          }

          return formatCurrencyNoDecimals(row.distributionValue);
        },
        lineTwo: ({ row }) => {
          if (!row.distributionValue) {
            return '';
          }

          if (typeof row.distributionValue === 'string') {
            return '';
          }

          if ('lineTwo' in row.distributionValue) {
            return row.distributionValue.lineTwo;
          }

          return '';
        },
      }),
      sortComparator: getStringOrNumberComparitor(),
    },
    {
      field: 'projectedEntityValue',
      headerName: '',
      align: 'right',
      flex: 1,
      sortable: false,
      disableReorder: true,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (!row.projectedEntityValue) {
            return '';
          }

          if (typeof row.projectedEntityValue === 'string') {
            return row.projectedEntityValue;
          }

          if (
            row.variant === BeneficiaryReportingRowVariant.Subtitle &&
            'lineOne' in row.projectedEntityValue
          ) {
            return (
              <Typography
                variant="h6"
                component="span"
                style={{
                  color: COLORS.GRAY[600],
                }}
              >
                {row.projectedEntityValue.lineOne}
              </Typography>
            );
          }

          if ('lineOne' in row.projectedEntityValue) {
            return row.projectedEntityValue.lineOne;
          }

          return formatCurrencyNoDecimals(row.projectedEntityValue);
        },
      }),
      sortComparator: getStringOrNumberComparitor(),
    },
  ];
}

function getScheduledDistributionAmount(
  scheduledDistribution: BeneficiaryReporting_ScheduledDistributionFragment | null,
  projectedEntityValue: Decimal
) {
  if (!scheduledDistribution) {
    return new Decimal(0);
  }

  switch (scheduledDistribution.kind) {
    case ScheduledDistributionKind.AllIncome:
      return 'All trust income';
    case ScheduledDistributionKind.Amount:
      return scheduledDistribution.amount ?? EMPTY_CONTENT_HYPHEN;
    case ScheduledDistributionKind.Percentage:
      return (
        scheduledDistribution.percentage
          ?.div(100)
          .times(projectedEntityValue) ?? EMPTY_CONTENT_HYPHEN
      );
    default:
      throw new UnreachableError({
        case: scheduledDistribution.kind,
        message: `Unknown ScheduledDistributionKind ${scheduledDistribution.kind}`,
      });
  }
}

function GSTExemptBadge() {
  return (
    <Badge
      variant={BadgeVariants.Gray}
      display="GST exempt"
      wrapperSx={{
        whiteSpace: 'nowrap',
      }}
    />
  );
}

function Badges({
  entityGstStatus,
  isHypothetical,
}: {
  entityGstStatus: EntityGstStatus | null;
  isHypothetical: boolean;
}) {
  const badges = useMemo(() => {
    const result: ReactNode[] = [];

    if (isHypothetical) {
      result.push(<HypotheticalEstateWaterfallBadge key="hypothetical" />);
    }

    if (entityGstStatus === EntityGstStatus.GstExempt) {
      result.push(<GSTExemptBadge key="gst-exempt" />);
    }

    return result;
  }, [isHypothetical, entityGstStatus]);

  if (badges.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {badges}
    </Stack>
  );
}

function NotesIcon({ notes }: { notes: Record<string, string> }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DialogModal
        heading="Notes"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        dialogContentSx={{ minWidth: 600, pb: 4 }}
      >
        <Stack spacing={2}>
          {Object.entries(notes).map(([key, value]) => (
            <Stack key={key} spacing={0.5}>
              <Typography variant="h5">{key}</Typography>
              <Typography variant="body1">{value}</Typography>
            </Stack>
          ))}
        </Stack>
      </DialogModal>
      <Tooltip
        onClick={() => setIsOpen(true)}
        placement="top"
        title="Show notes"
      >
        <InfoCircleIcon sx={{ display: 'flex' }} size={16} />
      </Tooltip>
    </>
  );
}

function getParametersLineTwoCopy(
  scheduledDistributionOrDate:
    | BeneficiaryReporting_ScheduledDistributionFragment
    | Date
    | null
) {
  if (!scheduledDistributionOrDate) {
    return '';
  }

  if (scheduledDistributionOrDate instanceof Date) {
    return formatDateToMonDDYYYY(scheduledDistributionOrDate);
  }

  return getAgeBoundsCopy(scheduledDistributionOrDate);
}

interface EditGiftTextAccompanimentProps {
  lifetimeExclusionEventId: string;
}

function EditGiftTextAccompaniment({
  lifetimeExclusionEventId,
}: EditGiftTextAccompanimentProps) {
  const { householdId } = useHouseholdDetailsContext();
  const [isLogNewGiftModalOpen, setIsLogNewGiftModalOpen] = useState(false);

  if (!householdId) {
    return null;
  }

  return (
    <>
      <LogNewGiftModal
        isOpen={isLogNewGiftModalOpen}
        onClose={() => setIsLogNewGiftModalOpen(false)}
        householdId={householdId}
        lifetimeExclusionEventId={lifetimeExclusionEventId}
        isEdit
      />
      <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
        <IconButton
          icon={Edit02Icon}
          ariaLabel="Edit gift"
          size="xs"
          variant="transparent"
          onClick={(e) => {
            e.stopPropagation();
            setIsLogNewGiftModalOpen(true);
          }}
        />
      </Box>
    </>
  );
}

interface EditDistributionTextAccompanimentProps {
  transferId: string;
}

function EditDistributionTextAccompaniment({
  transferId,
}: EditDistributionTextAccompanimentProps) {
  const { householdId } = useHouseholdDetailsContext();
  const { clientOrOrgId } = useBeneficiaryReportingContext();

  const { queryData } = useBeneficiaryReportingContext();

  const selectedBeneficiaryKind = useMemo(() => {
    const household = first(getNodes(queryData.estateWaterfalls))?.household;

    const foundClient = (household?.clientProfiles ?? []).find(
      (c) => c.id === clientOrOrgId
    );

    return foundClient
      ? TransferOptionType.Grantor
      : TransferOptionType.Organization;
  }, [clientOrOrgId, queryData.estateWaterfalls]);

  const [isLogNewTransferModalOpen, setIsLogNewTransferModalOpen] =
    useState(false);

  if (!householdId || !clientOrOrgId) {
    return null;
  }

  return (
    <>
      {isLogNewTransferModalOpen && (
        <LogNewTransferModal
          isOpen={isLogNewTransferModalOpen}
          onClose={() => setIsLogNewTransferModalOpen(false)}
          initiatorId={clientOrOrgId}
          transferId={transferId}
          initiatorKind={selectedBeneficiaryKind}
          initiatorEntityKind={null}
          householdId={householdId}
          transferType={TransferType.Contribution}
          isInboundDistribution
        />
      )}
      <Box height={30}>
        <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
          <IconButton
            icon={Edit02Icon}
            ariaLabel="Edit distribution"
            size="xs"
            variant="transparent"
            onClick={(e) => {
              e.stopPropagation();
              setIsLogNewTransferModalOpen(true);
            }}
          />
        </Box>
      </Box>
    </>
  );
}

interface LinkToDispositionsProps {
  entityId: string;
  sourceKind:
    | 'Entity'
    | 'ClientProfile'
    | 'ClientOrganization'
    | 'TestamentaryEntity';
}

function LinkToDispositions({ entityId, sourceKind }: LinkToDispositionsProps) {
  const { householdId } = useHouseholdDetailsContext();

  const dispositionsPath = useMemo(() => {
    if (!householdId || !entityId) {
      return '';
    }

    if (sourceKind === 'TestamentaryEntity') {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
        { householdId },
        {
          [DEFAULT_FILTER_SEARCH_PARAM]: FilterOptions.TESTAMENTARY,
          [TESTAMENTARY_ENTITY_SEARCH_PARAM]: entityId,
        }
      );
    }

    if (sourceKind === 'Entity') {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
        {
          householdId,
          entityId,
        },
        {
          [ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM]:
            TrustDetailsTabs.DISPOSITIONS,
        }
      );
    }

    return '';
  }, [householdId, entityId, sourceKind]);

  if (!dispositionsPath) {
    return null;
  }

  return (
    <Box height={30}>
      <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
        <IconButton
          ariaLabel="Go to entity disposition"
          icon={LinkExternal01Icon}
          onClick={() => {
            window.open(dispositionsPath, '_blank');
          }}
          variant="transparent"
          size="xs"
        />
      </Box>
    </Box>
  );
}

interface LinkToBeneficiariesProps {
  entityId: string;
  sourceKind:
    | 'Entity'
    | 'ClientProfile'
    | 'ClientOrganization'
    | 'TestamentaryEntity';
}

function LinkToBeneficiaries({
  entityId,
  sourceKind,
}: LinkToBeneficiariesProps) {
  const { householdId } = useHouseholdDetailsContext();

  const beneficiariesPath = useMemo(() => {
    if (!householdId || !entityId) {
      return '';
    }

    if (sourceKind === 'TestamentaryEntity') {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
        { householdId },
        {
          [DEFAULT_FILTER_SEARCH_PARAM]: FilterOptions.TESTAMENTARY,
          [TESTAMENTARY_ENTITY_SEARCH_PARAM]: entityId,
        }
      );
    }

    if (sourceKind === 'Entity') {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
        {
          householdId,
          entityId,
        },
        {
          [ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM]:
            TrustDetailsTabs.BENEFICIARIES,
        }
      );
    }

    return '';
  }, [householdId, entityId, sourceKind]);

  if (!beneficiariesPath) {
    return null;
  }

  return (
    <Box height={30}>
      <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
        <IconButton
          ariaLabel="Go to entity beneficiaries"
          icon={LinkExternal01Icon}
          onClick={() => {
            window.open(beneficiariesPath, '_blank');
          }}
          variant="transparent"
          size="xs"
        />
      </Box>
    </Box>
  );
}

interface EditHypotheticalTransferButtonProps {
  transferId: string;
}

function EditHypotheticalTransferButton({
  transferId,
}: EditHypotheticalTransferButtonProps) {
  const {
    summaryPanel: { openModal },
  } = useWaterfallSummaryContext();

  return (
    <Box height={30}>
      <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
        <IconButton
          icon={Edit02Icon}
          ariaLabel="Edit hypothetical transfer"
          size="xs"
          variant="transparent"
          onClick={(e) => {
            e.stopPropagation();
            openModal({
              type: 'hypotheticalTransfers',
              defaultOpenTransferId: transferId,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export function getBenefitsTableRows(
  beneficiaryReportingTableRowData:
    | BeneficiaryReportScheduledDistribution[]
    | BeneficiaryReportDirectGift[]
    | BeneficiaryReportFullAccess[]
    | BeneficiaryReportPartialAccess[]
): BenefitsTableRowData[] {
  if (
    beneficiaryReportingTableRowData[0]?.benefitKind ===
    BeneficiaryReportBenefitKind.ScheduledDistribution
  ) {
    return (
      beneficiaryReportingTableRowData as BeneficiaryReportScheduledDistribution[]
    ).map(
      (
        {
          variant,
          sourceId,
          sourceName,
          sourceKind,
          entityGstStatus,
          powerOfAppointment,
          kind,
          scheduledDistribution,
          dispositionAmount,
          projectedEntityValue,
          notes,
          dispositionParameters,
        },
        idx
      ) => {
        const scheduledDistributionAmount = getScheduledDistributionAmount(
          scheduledDistribution,
          projectedEntityValue
        );

        const scheduledDistributionLineOne =
          typeof scheduledDistributionAmount !== 'string'
            ? formatCurrencyNoDecimals(scheduledDistributionAmount)
            : scheduledDistributionAmount;

        const scheduledDistributionLineTwo = !!scheduledDistributionAmount
          ? 'Scheduled'
          : '';

        const textAccompaniment = (() => {
          if (kind === BeneficiaryReportScheduledDistributionKind.Disposition) {
            return (
              <LinkToDispositions entityId={sourceId} sourceKind={sourceKind} />
            );
          }

          if (
            kind === BeneficiaryReportScheduledDistributionKind.Distribution
          ) {
            return (
              <LinkToBeneficiaries
                entityId={sourceId}
                sourceKind={sourceKind}
              />
            );
          }

          return undefined;
        })();

        const parametersLineOne = (() => {
          if (dispositionParameters) {
            return dispositionParameters;
          }

          if (scheduledDistribution) {
            return getScheduledDistributionCopy(scheduledDistribution);
          }

          return EMPTY_CONTENT_HYPHEN;
        })();

        return {
          id: `scheduled-distribution-${idx}`,
          variant,
          sectionIndicator: {
            backgroundColor: COLORS.NAVY[300],
          },
          entity: {
            lineOne: sourceName,
            lineOneProps: { variant: 'h5' },
            lineTwo: getPowerOfAppointmentText(powerOfAppointment),
            textAccompaniment,
            rightContent: (
              <Badges
                entityGstStatus={entityGstStatus}
                isHypothetical={false}
              />
            ),
          },
          parameters: {
            lineOne: parametersLineOne,
            lineTwo: getParametersLineTwoCopy(scheduledDistribution),
            rightContent: notes ? <NotesIcon notes={notes} /> : undefined,
          },
          distributionValue:
            kind === BeneficiaryReportScheduledDistributionKind.Disposition
              ? {
                  lineOne: formatCurrencyNoDecimals(dispositionAmount),
                  lineTwo: 'Disposition',
                }
              : {
                  lineOne: scheduledDistributionLineOne,
                  lineTwo: scheduledDistributionLineTwo,
                },
          projectedEntityValue,
        };
      }
    );
  }

  if (
    beneficiaryReportingTableRowData[0]?.benefitKind ===
    BeneficiaryReportBenefitKind.DirectGift
  ) {
    return (
      beneficiaryReportingTableRowData as BeneficiaryReportDirectGift[]
    ).map(
      (
        {
          id,
          associatedLifetimeExclusionEventId,
          variant,
          sourceName,
          entityGstStatus,
          kind,
          transferTaxKind,
          purpose,
          giftAmount,
          giftDate,
        },
        idx
      ) => {
        const parametersLineOne = (() => {
          if (transferTaxKind) {
            return getHypotheticalTransferTaxDisplay({ transferTaxKind });
          }

          if (purpose) {
            return LoggedTransferPurposeCopyMap[purpose];
          }

          return '';
        })();

        const textAccompaniment = (() => {
          if (associatedLifetimeExclusionEventId) {
            return (
              <EditGiftTextAccompaniment
                lifetimeExclusionEventId={associatedLifetimeExclusionEventId}
              />
            );
          }

          if (
            kind === BeneficiaryReportDirectGiftKind.LoggedTransfer &&
            purpose !== LoggedTransferPurpose.TaxableGift
          ) {
            return <EditDistributionTextAccompaniment transferId={id} />;
          }

          if (kind === BeneficiaryReportDirectGiftKind.Hypothetical) {
            return <EditHypotheticalTransferButton transferId={id} />;
          }

          return undefined;
        })();

        return {
          id: `direct-gift-${idx}`,
          variant,
          sectionIndicator: {
            backgroundColor: COLORS.TEAL[300],
          },
          entity: {
            lineOne: sourceName,
            lineOneProps: { variant: 'h5' },
            textAccompaniment,
            rightContent: (
              <Badges
                entityGstStatus={entityGstStatus}
                isHypothetical={
                  kind === BeneficiaryReportDirectGiftKind.Hypothetical
                }
              />
            ),
          },
          parameters: {
            lineOne: parametersLineOne,
            lineTwo: getParametersLineTwoCopy(giftDate),
          },
          distributionValue: {
            lineOne: '',
          },
          projectedEntityValue: formatCurrencyNoDecimals(giftAmount),
        };
      }
    );
  }

  if (
    beneficiaryReportingTableRowData[0]?.benefitKind ===
    BeneficiaryReportBenefitKind.FullAccess
  ) {
    return (
      beneficiaryReportingTableRowData as BeneficiaryReportFullAccess[]
    ).map(
      (
        {
          variant,
          sourceId,
          sourceKind,
          sourceName,
          entityGstStatus,
          powerOfAppointment,
          projectedEntityValue,
          notes,
        },
        idx
      ) => ({
        id: `full-access-parameter-${idx}`,
        variant,
        sectionIndicator: {
          backgroundColor: COLORS.BLUE[300],
        },
        entity: {
          lineOne: sourceName,
          lineOneProps: { variant: 'h5' },
          lineTwo: getPowerOfAppointmentText(powerOfAppointment),
          textAccompaniment: (
            <LinkToBeneficiaries entityId={sourceId} sourceKind={sourceKind} />
          ),
          rightContent: (
            <Badges entityGstStatus={entityGstStatus} isHypothetical={false} />
          ),
        },
        parameters: {
          lineOne: notes ? EMPTY_CONTENT_HYPHEN : '',
          rightContent: notes ? <NotesIcon notes={notes} /> : undefined,
        },
        projectedEntityValue,
      })
    );
  }

  if (
    beneficiaryReportingTableRowData[0]?.benefitKind ===
    BeneficiaryReportBenefitKind.PartialAccess
  ) {
    return (
      beneficiaryReportingTableRowData as BeneficiaryReportPartialAccess[]
    ).map(
      (
        {
          variant,
          sourceId,
          sourceKind,
          sourceName,
          entityGstStatus,
          powerOfAppointment,
          accessParameter,
          projectedEntityValue,
          notes,
        },
        idx
      ) => ({
        id: `partial-access-parameter-${idx}`,
        variant,
        sectionIndicator: {
          backgroundColor: GREY_CANDY_CANE as string,
        },
        entity: {
          lineOne: sourceName,
          lineOneProps: { variant: 'h5' },
          lineTwo: getPowerOfAppointmentText(powerOfAppointment),
          textAccompaniment: (
            <LinkToBeneficiaries entityId={sourceId} sourceKind={sourceKind} />
          ),
          rightContent: (
            <Badges entityGstStatus={entityGstStatus} isHypothetical={false} />
          ),
        },
        parameters: {
          lineOne: getAccessParameterCopy(accessParameter),
          lineTwo: getAgeBoundsCopy(accessParameter),
          rightContent: notes ? <NotesIcon notes={notes} /> : undefined,
        },
        projectedEntityValue,
      })
    );
  }

  return [];
}

// Returns an array of the max value for each duplicate key, or the first value if there are no duplicates
function getMaxOrFirstOfDuplicates(data: [string, Decimal][]): Decimal[] {
  const maxOrFirstOfDuplicates: Decimal[] = [];
  const byId = _(data)
    .groupBy(([key]) => key)
    .mapValues((group) => group.map(([_, value]) => value))
    .value();

  Object.entries(byId).forEach(([_, duplicates]) => {
    maxOrFirstOfDuplicates.push(
      (duplicates?.length ?? 0) > 1
        ? maxDecimalJS(duplicates, new Decimal(0))
        : duplicates[0]!
    );
  });

  return maxOrFirstOfDuplicates;
}

export function getScheduledDistributionSection(
  scheduledDistributions: BeneficiaryReportScheduledDistribution[]
): Section & { valueDecimal: Decimal } {
  const valuesWithPossibleDuplicates: [string, Decimal][] =
    scheduledDistributions.map((sd) => {
      switch (sd.kind) {
        case BeneficiaryReportScheduledDistributionKind.Disposition:
          return [sd.id, sd.dispositionAmount ?? new Decimal(0)];
        case BeneficiaryReportScheduledDistributionKind.Distribution: {
          const scheduledDistribution = sd.scheduledDistribution;

          if (!scheduledDistribution) {
            return [sd.id, new Decimal(0)];
          }

          switch (scheduledDistribution.kind) {
            case ScheduledDistributionKind.Amount: {
              return [sd.id, scheduledDistribution.amount ?? new Decimal(0)];
            }
            case ScheduledDistributionKind.Percentage:
              return [
                sd.id,
                scheduledDistribution.percentage
                  ?.div(100)
                  .times(sd.projectedEntityValue) ?? new Decimal(0),
              ];
            case ScheduledDistributionKind.AllIncome:
              // We do not want to count access to all income
              // https://linear.app/luminary/issue/T1-2482/double-counting-in-beneficiary-reporting
              return [sd.id, new Decimal(0)];
            default:
              throw new UnreachableError({
                case: scheduledDistribution.kind,
                message: 'Unhandled scheduled distribution kind',
              });
          }
        }
        default:
          throw new UnreachableError({
            case: sd.kind,
            message: 'Unhandled scheduled distribution kind',
          });
      }
    });

  // If there are duplicate scheduled distributions represented in the data, we take the max value
  const values = getMaxOrFirstOfDuplicates(valuesWithPossibleDuplicates);
  const valueDecimal = sumDecimalJS(values);

  return {
    value: valueDecimal.toNumber(),
    valueDecimal,
    color: COLORS.NAVY[300],
    label: 'Scheduled distributions',
    tooltip: (
      <StackedHorizontalBarTooltip
        value={valueDecimal}
        label="Scheduled distributions"
      />
    ),
    sectionTextColor: COLORS.PRIMITIVES.WHITE,
  };
}

export function getDirectGiftSection(
  directGifts: BeneficiaryReportDirectGift[]
): Section & { valueDecimal: Decimal } {
  const valueDecimal = sumDecimalJS(directGifts.map((dg) => dg.giftAmount));

  return {
    value: valueDecimal.toNumber(),
    valueDecimal,
    color: COLORS.TEAL[300],
    label: 'Direct gifts & distributions',
    tooltip: (
      <StackedHorizontalBarTooltip
        value={valueDecimal}
        label="Direct gifts & distributions"
      />
    ),
    sectionTextColor: COLORS.NAVY[900],
  };
}

export function getFullAccessSection(
  fullAccess: BeneficiaryReportFullAccess[]
): Section & { valueDecimal: Decimal } {
  const valuesWithPossibleDuplicates: [string, Decimal][] = fullAccess.map(
    (fa) => [fa.id, fa.projectedEntityValue]
  );

  // If there are duplicate full accesses represented in the data, we take the max value
  const values = getMaxOrFirstOfDuplicates(valuesWithPossibleDuplicates);
  const valueDecimal = sumDecimalJS(values);

  return {
    value: valueDecimal.toNumber(),
    valueDecimal,
    color: COLORS.BLUE[300],
    label: 'Full access',
    tooltip: (
      <StackedHorizontalBarTooltip value={valueDecimal} label="Full access" />
    ),
    sectionTextColor: COLORS.NAVY[900],
  };
}

export function getPartialAccessSection(
  partialAccess: BeneficiaryReportPartialAccess[]
): Section & { valueDecimal: Decimal } {
  const valuesWithPossibleDuplicates: [string, Decimal][] = partialAccess.map(
    (pa) => [pa.id, pa.projectedEntityValue]
  );

  // If there are duplicate partial accesses represented in the data, we take the max value
  const values = getMaxOrFirstOfDuplicates(valuesWithPossibleDuplicates);
  const valueDecimal = sumDecimalJS(values);

  return {
    value: valueDecimal.toNumber(),
    valueDecimal,
    color: COLORS.GRAY[200],
    label: 'Partial access',
    backgroundCss: GREY_CANDY_CANE,
    tooltip: (
      <StackedHorizontalBarTooltip
        value={valueDecimal}
        label="Partial access"
      />
    ),
    sectionTextColor: COLORS.NAVY[900],
  };
}

export function getAccessValueForDeathEvent(
  beneficiaryReports: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[],
  afterDeaths?: AfterDeath[]
) {
  const beneficiaryReportsForDeathEvent = beneficiaryReports.filter(
    ({ afterDeath: ad }) => {
      if (afterDeaths) {
        return afterDeaths.includes(ad);
      }

      return true;
    }
  );

  const scheduledDistributions = beneficiaryReportsForDeathEvent.flatMap(
    ({ beneficiaryReport }) => beneficiaryReport.scheduledDistributions
  );
  const directGifts = beneficiaryReportsForDeathEvent.flatMap(
    ({ beneficiaryReport }) => beneficiaryReport.directGifts
  );
  const fullAccess = beneficiaryReportsForDeathEvent.flatMap(
    ({ beneficiaryReport }) => beneficiaryReport.fullAccess
  );
  const partialAccess = beneficiaryReportsForDeathEvent.flatMap(
    ({ beneficiaryReport }) => beneficiaryReport.partialAccess
  );

  const scheduledDistributionValue = getScheduledDistributionSection(
    scheduledDistributions
  ).valueDecimal;
  const directGiftValue = getDirectGiftSection(directGifts).valueDecimal;
  const fullAccessValue = getFullAccessSection(fullAccess).valueDecimal;
  const partialAccessValue =
    getPartialAccessSection(partialAccess).valueDecimal;

  return sumDecimalJS([
    scheduledDistributionValue,
    directGiftValue,
    fullAccessValue,
    partialAccessValue,
  ]);
}
