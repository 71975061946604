import { Stack } from '@mui/material';
import { isUndefined, last } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCardRepeater } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { validateNumberRange } from '@/utils/validators/validateNumberRange';

import { useWaterfallComparisonSearchParams } from '../EstateWaterfallBreadcrumbNavigation/hooks/useWaterfallComparisonSearchParams';
import {
  EMPTY_ESTATE_WATERFALL_COMPARISON_WATERFALL,
  EstateWaterfallComparisonPaths,
  EstateWaterfallComparisonShape,
  EstateWaterfallComparisonWaterfall,
} from '../EstateWaterfallComparison.type';
import { useEstateWaterfallComparisonModalContext } from './EstateWatefallComparisonModal.context';
import { useWaterfallComparisonWaterfallsAndGrowthProfiles } from './EstateWaterfallComparisonModal.hooks';

function getFieldNameGenerator(index: number) {
  return {
    getFieldName: (fieldName: keyof EstateWaterfallComparisonWaterfall) =>
      `waterfalls.${index}.${fieldName}` as const satisfies EstateWaterfallComparisonPaths,
  };
}

export function EstateWaterfallComparisonModalBody() {
  const { control } = useFormContext<EstateWaterfallComparisonShape>();
  const { isPresentation } = useEstateWaterfallComparisonModalContext();
  const [waterfalls] = useWatch({
    control,
    name: ['waterfalls'],
  });
  const { growthProfileOptions, waterfallOptions } =
    useWaterfallComparisonWaterfallsAndGrowthProfiles();

  /**
   * This is the default value to show a user when adding a new waterfall to compare.
   *
   * If there is no above waterfall, use default options.  If there is, use the above waterfall's
   * growth profile and death years.
   */
  const emptyValue = useMemo(() => {
    const lastWaterfall = last(waterfalls);
    if (!lastWaterfall) {
      return EMPTY_ESTATE_WATERFALL_COMPARISON_WATERFALL;
    }

    return {
      ...EMPTY_ESTATE_WATERFALL_COMPARISON_WATERFALL,
      growthProfileId: lastWaterfall.growthProfileId,
      firstDeathYear: lastWaterfall.firstDeathYear,
      secondDeathYear: lastWaterfall.secondDeathYear,
    };
  }, [waterfalls]);

  return (
    <Stack direction="column" gap={3}>
      {isPresentation && (
        <FormAwareTextInput<EstateWaterfallComparisonShape>
          fieldName={
            'comparisonName' as const satisfies EstateWaterfallComparisonPaths
          }
          control={control}
          label="Comparison name"
          required
        />
      )}
      <FormAwareCardRepeater<EstateWaterfallComparisonShape>
        data-testid="comparison-waterfall-repeater"
        control={control}
        name="waterfalls"
        showRemove={false}
        render={(index, fieldArrayProps) => (
          <WaterfallAccordion
            index={index}
            onRemove={() => fieldArrayProps.remove(index)}
            growthProfileOptions={growthProfileOptions}
            waterfallOptions={waterfallOptions}
          />
        )}
        addAnotherItemText="Add waterfall"
        emptyValue={emptyValue}
        maxItems={4}
        allowEmpty={false}
      />
    </Stack>
  );
}

interface WaterfallAccordionProps {
  index: number;
  onRemove: () => void;
  growthProfileOptions: SelectInputOption[];
  waterfallOptions: SelectInputOption[];
}

function WaterfallAccordion({
  index,
  onRemove,
  growthProfileOptions,
  waterfallOptions,
}: WaterfallAccordionProps) {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const [comparisonState] = useWaterfallComparisonSearchParams();
  const { control, setValue } =
    useFormContext<EstateWaterfallComparisonShape>();

  const { getFieldName } = useMemo(() => getFieldNameGenerator(index), [index]);

  const { waterfalls, loadingHouseholds, loadingGrowthProfiles } =
    useWaterfallComparisonWaterfallsAndGrowthProfiles();

  const [
    waterfallId,
    growthProfileId,
    firstDeathYear,
    secondDeathYear,
    displayName,
  ] = useWatch({
    control,
    name: [
      getFieldName('waterfallId'),
      getFieldName('growthProfileId'),
      getFieldName('firstDeathYear'),
      getFieldName('secondDeathYear'),
      getFieldName('displayName'),
    ],
  });

  const previousWaterfallId = usePrevious(waterfallId);

  const matchingWaterfall = useMemo(
    () => waterfalls.find(({ id }) => id === waterfallId),
    [waterfalls, waterfallId]
  );

  const subtitle = useMemo(() => {
    if (!waterfallId) return undefined;

    let deathYearDisplay = `${firstDeathYear} death`;
    if (secondDeathYear && isTwoClientHousehold) {
      deathYearDisplay = `${firstDeathYear} & ${secondDeathYear} deaths`;
    }

    const growthProfileDisplay =
      growthProfileOptions.find(({ value }) => value === growthProfileId)
        ?.display || 'Unknown growth profile';

    return `${growthProfileDisplay}, ${deathYearDisplay}`;
  }, [
    firstDeathYear,
    growthProfileId,
    growthProfileOptions,
    isTwoClientHousehold,
    secondDeathYear,
    waterfallId,
  ]);

  // when the waterfallId changes, reset the display name, growth profile, and death years
  useEffect(() => {
    if (
      isUndefined(previousWaterfallId) ||
      waterfallId === previousWaterfallId ||
      !waterfallId ||
      !matchingWaterfall
    )
      return;

    setValue(getFieldName('displayName'), matchingWaterfall.displayName);

    if (index !== 0) return;

    // if the first waterfall changes, reset the growth profile and death years to the one on that
    // waterfall.  otherwise, use the default values calculated above (see the definition of
    // `emptyValue` in EstateWaterfallComparisonModalBody), which pulls in the above waterfall's
    // growth profile and death years.
    if (matchingWaterfall.growthProfile?.id) {
      setValue(
        getFieldName('growthProfileId'),
        matchingWaterfall.growthProfile.id
      );
    }
    if (matchingWaterfall.firstGrantorDeathYear) {
      setValue(
        getFieldName('firstDeathYear'),
        matchingWaterfall.firstGrantorDeathYear
      );
    }
    if (matchingWaterfall.secondGrantorDeathYear) {
      setValue(
        getFieldName('secondDeathYear'),
        matchingWaterfall.secondGrantorDeathYear
      );
    }
  }, [
    waterfallId,
    waterfalls,
    index,
    getFieldName,
    matchingWaterfall,
    previousWaterfallId,
    setValue,
    comparisonState,
  ]);

  return (
    <Accordion
      title={displayName ?? matchingWaterfall?.displayName ?? 'Waterfall'}
      subtitle={subtitle}
      variant="filled"
      isDefaultExpanded
      data-testid={`waterfall-accordion-${index}`}
    >
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareSelectInput<EstateWaterfallComparisonShape>
            fieldName={
              `waterfalls.${index}.waterfallId` as const satisfies EstateWaterfallComparisonPaths
            }
            control={control}
            label="Waterfall"
            options={waterfallOptions}
            loading={loadingHouseholds}
            data-testid={`waterfall-select-${index}`}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareTextInput<EstateWaterfallComparisonShape>
            fieldName={
              `waterfalls.${index}.displayName` as const satisfies EstateWaterfallComparisonPaths
            }
            control={control}
            label="Display name for waterfall"
            disabled={!Boolean(waterfallId)}
            data-testid={`display-name-input-${index}`}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareSelectInput<EstateWaterfallComparisonShape>
            control={control}
            options={growthProfileOptions}
            loading={loadingGrowthProfiles}
            fieldName={
              `waterfalls.${index}.growthProfileId` as const satisfies EstateWaterfallComparisonPaths
            }
            label="Growth profile"
            required
            showEmptyValue={false}
            data-testid={`growth-profile-select-${index}`}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={4}>
          <FormAwareFormattedNumberInput<EstateWaterfallComparisonShape>
            fieldName={
              `waterfalls.${index}.firstDeathYear` as const satisfies EstateWaterfallComparisonPaths
            }
            control={control}
            label={isTwoClientHousehold ? 'First death year' : 'Death year'}
            required
            formatHashString="####"
            validation={{
              min: validateNumberRange({
                min: new Date().getFullYear(),
                fieldName: isTwoClientHousehold
                  ? 'First death year'
                  : 'Death year',
              }),
            }}
          />
        </FormLayoutItem>
        {isTwoClientHousehold && (
          <FormLayoutItem width={4}>
            <FormAwareFormattedNumberInput<EstateWaterfallComparisonShape>
              fieldName={
                `waterfalls.${index}.secondDeathYear` as const satisfies EstateWaterfallComparisonPaths
              }
              control={control}
              label="Second death year"
              required
              formatHashString="####"
              validation={{
                min: validateNumberRange({
                  min: new Date().getFullYear(),
                  fieldName: 'Second death year',
                }),
              }}
            />
          </FormLayoutItem>
        )}
        <FormLayoutItem width={4}>
          {index !== 0 && (
            <Stack
              direction="row"
              width="100%"
              height="100%"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                fullWidth
                variant="destructive-transparent"
                startIcon={Trash01Icon}
                size="sm"
                onClick={onRemove}
                data-testid={`remove-waterfall-button-${index}`}
              >
                Remove
              </Button>
            </Stack>
          )}
        </FormLayoutItem>
      </FormLayoutRow>
    </Accordion>
  );
}
