import { Stack, Typography, TypographyProps } from '@mui/material';

export interface CompoundHeaderProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  headingProps?: Partial<TypographyProps>;
  subheadingProps?: Partial<TypographyProps>;
  invertLabel?: boolean;
}

export function CompoundHeader({
  heading,
  subheading,
  headingProps = {},
  subheadingProps = {},
  invertLabel = false,
}: CompoundHeaderProps) {
  return (
    <Stack direction={invertLabel ? 'column-reverse' : 'column'}>
      <Typography variant="h2" {...headingProps}>
        {heading}
      </Typography>
      {subheading && (
        <Typography variant="subtitle2" {...subheadingProps}>
          {subheading}
        </Typography>
      )}
    </Stack>
  );
}
