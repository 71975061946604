import { ApolloError } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { compact, first, includes } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { NotFoundError } from '@/components/display/NotFoundError/NotFoundError';
import { Divider } from '@/components/Divider';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { Repeat02Icon } from '@/components/icons/Repeat02Icon';
import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';
import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { getEntityAssetsIntegrationProvider } from '@/modules/assetProviderIntegrations/shared/utils';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { ConvertEntityMenuItem } from '@/modules/entities/components/ConvertEntity/ConvertEntityMenuItem';
import { ConvertEntityModal } from '@/modules/entities/components/ConvertEntity/ConvertEntityModal';
import { ConvertToActiveMenuItem } from '@/modules/entities/components/ConvertToActiveMenuItem/ConvertToActiveMenuItem';
import { DeleteEntityMenuItem } from '@/modules/entities/components/DeleteEntityMenuItem';
import { DuplicateEntityMenuItem } from '@/modules/entities/components/DuplicateEntityMenuItem';
import { UnlinkFromIntegrationMenuItem } from '@/modules/entities/components/UnlinkFromIntegrationMenuItem/UnlinkFromIntegrationMenuItem';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { EntityDetailsAwareRoute } from '@/modules/entities/contexts/entityDetails/EntityDetailsAwareRoute';
import { EditEntitySplitScreenOpenModalButton } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenOpenModalButton';
import { useImmediateOpenEditModal } from '@/modules/entities/hooks/useImmediateOpenEditModal';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityKind, EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  EntityPage_EntityFragment,
  useEntityPageBasicsQuery,
} from './graphql/EntityPage.generated';
import { PrintEntityExportMenuItem } from './PrintEntityExportButton/PrintEntityExportMenuItem';

function useGetEntityBasicsData(entityId: string): {
  error?: ApolloError;
  loading: boolean;
  entityData: EntityPage_EntityFragment | null;
} {
  const { data, error, loading } = useEntityPageBasicsQuery({
    variables: { entityId },
  });

  const entityData = useMemo(() => {
    if (!data || error) return null;
    return first(getNodes(data?.entities)) ?? null;
  }, [data, error]);

  useReportError('failed to fetch data for entity details page', error);

  return { entityData, error, loading };
}

function EntityPageInner() {
  const householdId = useRequiredParam('householdId');
  const entityId = useRequiredParam('entityId');
  const { entityNotFound } = useEntityDetailsContext();
  const { doForceEditing, initialSection } = useImmediateOpenEditModal();
  const navigate = useNavigate();
  const canAccessTasksPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_TASKS_PAGE
  );
  const canDeleteEntity = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );

  const [isConvertEntityModalOpen, setIsConvertEntityModalOpen] =
    useState(false);

  const { entityData } = useGetEntityBasicsData(entityId);
  const taskEnabledTypes = [EntityKind.GratTrust, EntityKind.IlitTrust];
  const isGrat = entityData?.kind === EntityKind.GratTrust;
  const canBeConverted =
    entityData?.kind === EntityKind.RevocableTrust ||
    entityData?.kind === EntityKind.IrrevocableTrust ||
    entityData?.kind === EntityKind.IndividualPersonalAccount ||
    entityData?.kind === EntityKind.JointPersonalAccount;
  const showTasks =
    canAccessTasksPage && includes(taskEnabledTypes, entityData?.kind);

  const showValuationHistory =
    entityData?.kind !== EntityKind.InsurancePersonalAccount;

  const canUnlinkFromIntegration =
    getEntityAssetsIntegrationProvider(entityData) !== null;

  // TODO SINGLE_ENTITY_DIAGRAM replace with ff
  // can navigate direclty to the diagram page for dev purposes
  const showDiagram = false;

  const trustTabs: LinkTabProps[] = React.useMemo(() => {
    return compact([
      {
        display: 'Overview',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
          householdId,
          entityId,
        }),
      },
      showDiagram && {
        display: 'Diagram',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS_DIAGRAM,
          {
            householdId,
            entityId,
          }
        ),
      },
      showTasks && {
        display: 'Tasks',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_TASKS, {
          householdId,
          entityId,
        }),
      },
      showValuationHistory && {
        display: 'Valuation history',
        to: getCompletePathFromRouteKey(
          isGrat
            ? ROUTE_KEYS.HOUSEHOLD_ENTITY_PERFORMANCE
            : ROUTE_KEYS.HOUSEHOLD_ENTITY_VALUATION_HISTORY,
          {
            householdId,
            entityId,
          }
        ),
      },
      isGrat && {
        display: 'Payment schedule',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_PAYMENTS, {
          householdId,
          entityId,
        }),
      },
      {
        display: 'Documents',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DOCUMENTS, {
          householdId,
          entityId,
        }),
      },
    ]);
  }, [
    householdId,
    entityId,
    showDiagram,
    isGrat,
    showTasks,
    showValuationHistory,
  ]);

  const kind = entityData?.kind;
  const type = kind && getEntityTypeFromEntityKind(kind);
  const isDraft = entityData?.stage === EntityStage.Draft;

  if (entityNotFound) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <NotFoundError
          sx={{ width: 350 }}
          title="Unable to load entity"
          message="The entity you are looking for could not be found, or you may not have the necessary access"
          buttonText="Return to client overview"
          returnPath={getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
            { householdId }
          )}
        />
      </Stack>
    );
  }

  return (
    <Stack height={'100%'}>
      <PageHeader
        heading={
          <Stack spacing={2} direction="row" alignItems="center">
            <BigBreadcrumbs
              crumbs={[
                {
                  to: getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
                    { householdId }
                  ),
                  label: 'Entities',
                },
                {
                  label: entityData?.subtype?.displayName ?? '',
                },
              ]}
            />
            {entityData?.stage === EntityStage.Draft && (
              <Box pb={1}>
                <Badge variant={BadgeVariants.Yellow} display="Draft" />
              </Box>
            )}
            {entityData?.stage === EntityStage.Completed && (
              <Box pb={1}>
                <Badge variant={BadgeVariants.Teal} display="Completed" />
              </Box>
            )}
          </Stack>
        }
        tabs={trustTabs}
        action={
          <Stack direction="row" spacing={1}>
            <EditEntitySplitScreenOpenModalButton
              buttonText="Edit entity"
              navigateAfterSave
              initialSection={initialSection}
              openOnMount={doForceEditing}
            />
            <ContextMenuButton>
              {isDraft && type && (
                <ConvertToActiveMenuItem
                  entityId={entityId}
                  subtypeId={entityData.subtype.id}
                  entityType={type}
                  entityDisplayName={entityData.subtype.displayName}
                />
              )}
              {!isGrat && (
                <DuplicateEntityMenuItem
                  label="Duplicate"
                  householdId={householdId}
                  entityId={entityId}
                  icon={<Copy07Icon size={20} />}
                />
              )}
              {canBeConverted && (
                <>
                  <ConvertEntityMenuItem
                    entityId={entityId}
                    entityKind={kind!}
                    setOpenConvertModal={setIsConvertEntityModalOpen}
                    icon={<Repeat02Icon size={20} />}
                  />
                </>
              )}
              <PrintEntityExportMenuItem
                label="Export PDF"
                icon={<Download01Icon size={20} />}
                householdId={householdId}
                entityId={entityId}
                entityDisplayName={
                  entityData?.subtype?.displayName || 'Luminary entity'
                }
              />
              {(canUnlinkFromIntegration || canDeleteEntity) && (
                <Divider noMargin />
              )}
              <UnlinkFromIntegrationMenuItem entity={entityData} />
              {canDeleteEntity && (
                <>
                  <DeleteEntityMenuItem
                    entityId={entityId}
                    modalProps={{
                      children:
                        'Deleting will remove this entity from all associated entities and remove all other associated data such as transactions.',
                    }}
                    onDelete={() =>
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
                          { householdId }
                        )
                      )
                    }
                  />
                </>
              )}
            </ContextMenuButton>
          </Stack>
        }
      />
      {isConvertEntityModalOpen && (
        <ConvertEntityModal
          entityId={entityId}
          entityKind={kind!}
          isOpen={isConvertEntityModalOpen}
          onClose={() => setIsConvertEntityModalOpen(false)}
        />
      )}
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}

export function EntityPage() {
  const entityId = useRequiredParam('entityId');
  return (
    <EntityDetailsAwareRoute entityId={entityId}>
      <EntityPageInner />
    </EntityDetailsAwareRoute>
  );
}
