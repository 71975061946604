import { Stack, styled, Theme } from '@mui/material';
import { compact, includes, isEmpty } from 'lodash';

import { EntityDetailTab } from '@/modules/entities/components/EntityDetails';
import { TrustDetailsTabs } from '@/modules/entities/details/entityDetails.types';
import { INSURANCE_POLICY_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import { EntityKind } from '@/types/schema';

import { TrustSummaryData } from './TrustDetails.types';

export const SummaryWrapper = styled(Stack)({
  containerType: 'inline-size',
});

export const SummaryContainer = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'stretch',
    // @container selections are not native to MUI yet, but will be soon.
    // See: https://github.com/mui/material-ui/issues/25189#issuecomment-1321236185
    [theme.breakpoints.down('sm').replace('@media', '@container')]: {
      flexDirection: 'column',
    },
  })
);

export function getTrustDetailsTabs(
  trustSummaryData: TrustSummaryData,
  _featureFlags: FeatureFlagMap = {}
): EntityDetailTab[] {
  const { entityKind } = trustSummaryData;

  const Details = {
    display: 'Details',
    value: TrustDetailsTabs.SUMMARY,
    props: trustSummaryData,
  };

  const Trustees = {
    display: 'Trustees',
    value: TrustDetailsTabs.TRUSTEES,
    props: trustSummaryData,
  };

  const Beneficiaries = {
    display: 'Beneficiaries',
    value: TrustDetailsTabs.BENEFICIARIES,
    props: trustSummaryData,
  };

  const InsurancePolicies = {
    display: 'Policies',
    value: TrustDetailsTabs.POLICIES,
    props: trustSummaryData,
  };

  const Dispositions = {
    display: 'Dispositions',
    value: TrustDetailsTabs.DISPOSITIONS,
    props: trustSummaryData,
  };

  // We *always* show policies for ILITs because it's a core feature of the trust type,
  // but only show them when present for other trusts because it's sort of an "extra"
  const alwaysShowPolicies = entityKind === EntityKind.IlitTrust;
  const showPoliciesWhenPresent = (() => {
    const entityType = getEntityTypeFromEntityKind(entityKind);
    const hasPolicies = !isEmpty(trustSummaryData.insurancePolicies);
    return includes(INSURANCE_POLICY_ENTITY_TYPES, entityType) && hasPolicies;
  })();

  return compact([
    Details,
    Trustees,
    Beneficiaries,
    (alwaysShowPolicies || showPoliciesWhenPresent) && InsurancePolicies,
    Dispositions,
  ]);
}
