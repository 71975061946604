// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { AsyncJobStatus_AsyncJobFragmentDoc } from '../../asyncJobs/graphql/AsyncJobs.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiOnboarding_KgRootFragment = { __typename?: 'KGRoot', id: string, kind: Types.KgRootKind, status: Types.KgRootStatus, household: { __typename?: 'Household', id: string, displayName: string }, files?: Array<{ __typename?: 'File', id: string, document?: { __typename?: 'Document', id: string } | null }> | null, asyncJob?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null, aiSuggestions?: Array<{ __typename?: 'AISuggestion', id: string, kind: Types.AiSuggestionKind }> | null };

export type GenerateKnowledgeGraphFromDocumentsMutationVariables = Types.Exact<{
  input: Types.GenerateKnowledgeGraphInput;
}>;


export type GenerateKnowledgeGraphFromDocumentsMutation = { __typename?: 'Mutation', generateKnowledgeGraphFromDocuments: { __typename?: 'KGRoot', id: string, kind: Types.KgRootKind, status: Types.KgRootStatus, household: { __typename?: 'Household', id: string, displayName: string }, files?: Array<{ __typename?: 'File', id: string, document?: { __typename?: 'Document', id: string } | null }> | null, asyncJob?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null, aiSuggestions?: Array<{ __typename?: 'AISuggestion', id: string, kind: Types.AiSuggestionKind }> | null } };

export const AiOnboarding_KgRootFragmentDoc = gql`
    fragment AIOnboarding_KGRoot on KGRoot {
  id
  kind
  status
  household {
    id
    displayName
  }
  files {
    id
    document {
      id
    }
  }
  asyncJob {
    ...AsyncJobStatus_AsyncJob
  }
  aiSuggestions {
    id
    kind
  }
}
    ${AsyncJobStatus_AsyncJobFragmentDoc}`;
export const GenerateKnowledgeGraphFromDocumentsDocument = gql`
    mutation GenerateKnowledgeGraphFromDocuments($input: GenerateKnowledgeGraphInput!) {
  generateKnowledgeGraphFromDocuments(input: $input) {
    ...AIOnboarding_KGRoot
  }
}
    ${AiOnboarding_KgRootFragmentDoc}`;
export type GenerateKnowledgeGraphFromDocumentsMutationFn = Apollo.MutationFunction<GenerateKnowledgeGraphFromDocumentsMutation, GenerateKnowledgeGraphFromDocumentsMutationVariables>;

/**
 * __useGenerateKnowledgeGraphFromDocumentsMutation__
 *
 * To run a mutation, you first call `useGenerateKnowledgeGraphFromDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateKnowledgeGraphFromDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateKnowledgeGraphFromDocumentsMutation, { data, loading, error }] = useGenerateKnowledgeGraphFromDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateKnowledgeGraphFromDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateKnowledgeGraphFromDocumentsMutation, GenerateKnowledgeGraphFromDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateKnowledgeGraphFromDocumentsMutation, GenerateKnowledgeGraphFromDocumentsMutationVariables>(GenerateKnowledgeGraphFromDocumentsDocument, options);
      }
export type GenerateKnowledgeGraphFromDocumentsMutationHookResult = ReturnType<typeof useGenerateKnowledgeGraphFromDocumentsMutation>;
export type GenerateKnowledgeGraphFromDocumentsMutationResult = Apollo.MutationResult<GenerateKnowledgeGraphFromDocumentsMutation>;
export type GenerateKnowledgeGraphFromDocumentsMutationOptions = Apollo.BaseMutationOptions<GenerateKnowledgeGraphFromDocumentsMutation, GenerateKnowledgeGraphFromDocumentsMutationVariables>;
export const Operations = {
  Mutation: {
    GenerateKnowledgeGraphFromDocuments: 'GenerateKnowledgeGraphFromDocuments' as const
  },
  Fragment: {
    AIOnboarding_KGRoot: 'AIOnboarding_KGRoot' as const
  }
}