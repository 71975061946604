import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { noop } from 'lodash';
import React, { createContext, useMemo, useState } from 'react';

import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { diagnostics } from '@/utils/diagnostics';

import { COMMON_THEME_PROPERTIES } from './common';
import { useLuminaryTheme } from './LuminaryThemeProvider';
import {
  BRAND,
  getThemeFromTenantBranding,
  makeThemeFromBrand,
} from './themes.utils';

// initialTheme is only a placeholder and provides some common baseline properties;
// we will always override it with the customer's theme or the luminary theme
const initialTheme = createTheme(
  COMMON_THEME_PROPERTIES,
  makeThemeFromBrand(BRAND)
);

interface CustomerThemeContextType {
  setTheme: (arg: Theme) => void;
  customerTheme: Theme;
}

export const CustomerThemeContext = createContext<CustomerThemeContextType>({
  setTheme: noop,
  customerTheme: initialTheme,
});

/**
 * Returns the customer's theme, or the theme of the active subbrand if there is one.
 * You should generally use `useTheme` directly, because that will correctly get you the
 * Luminary theme or the customer's theme. You should only use this if you need to explicitly
 * get the customer's theme in a scenario where making the current component the child of the
 * CustomerThemeProvider is not possible or overly onerous.
 */
export function useCustomerTheme(): Theme {
  const { branding, subBrandsById, activeSubBrandId } =
    useTenantDetailsContext();

  return useMemo(() => {
    // this can happen during a first pass when tenant data is still populating
    if (!branding) return initialTheme;

    // if there's no active subbrand, reset to the primary branding -- this will happen when the activeSubBrandId is
    // set to null or in the initial case
    if (!activeSubBrandId) {
      return getThemeFromTenantBranding(branding);
    }

    const subBrand = subBrandsById[activeSubBrandId];
    if (!subBrand) {
      diagnostics.error(
        `attempting to render subbrand with id ${activeSubBrandId} but it does not exist`,
        new Error(
          `attempting to render subbrand with id ${activeSubBrandId} but it does not exist`
        ),
        {
          activeSubBrandId,
          availableIds: JSON.stringify(Object.keys(subBrandsById)),
        }
      );
      return initialTheme;
    }

    return getThemeFromTenantBranding(subBrand);
  }, [activeSubBrandId, branding, subBrandsById]);
}

interface CustomerThemeProviderProps {
  /**
   * Whether to force the use of the Luminary theme, even if there is an active subbrand. This is useful
   * if you need to change the theme of a component without changing the component tree and forcing an unmount/remount.
   */
  forceLuminaryTheme?: boolean;
}

export function CustomerThemeProvider({
  children,
  forceLuminaryTheme,
}: React.PropsWithChildren<CustomerThemeProviderProps>) {
  const [themeState, setTheme] = useState<Theme | null>(null);
  const luminaryTheme = useLuminaryTheme();
  const customerTheme = useCustomerTheme();

  const theme = (() => {
    // This is the customer's defined theme, or the customer's sub-brand theme
    // if appropriate
    if (customerTheme) return customerTheme;

    // If we're in the case where we can be setting an explicit theme from an internal
    // tool, use this
    if (themeState) return themeState;

    diagnostics.warn('Using an undefined theme in CustomerThemeProvider');
    return initialTheme;
  })();

  return (
    <CustomerThemeContext.Provider
      value={{ setTheme, customerTheme: luminaryTheme }}
    >
      <ThemeProvider theme={forceLuminaryTheme ? luminaryTheme : theme}>
        {children}
      </ThemeProvider>
    </CustomerThemeContext.Provider>
  );
}
