import Decimal from 'decimal.js';

interface AssetOptionDetails {
  totalValue: Decimal;
}

interface SelectedValue {
  amount: Decimal | null;
  percent: Decimal | null;
}

export const DollarPercentValues = {
  DOLLAR: 'amount',
  PERCENT: 'percent',
} as const;

export type DollarPercentPattern =
  (typeof DollarPercentValues)[keyof typeof DollarPercentValues];

export function calculateTotalValue({
  selectedAssetIds,
  selectedAssetClassAndBusinessValues,
  assetOptionDetails,
  pattern,
}: {
  selectedAssetIds: string[] | null;
  selectedAssetClassAndBusinessValues: Record<string, SelectedValue> | null;
  assetOptionDetails: Record<string, AssetOptionDetails>;
  pattern: DollarPercentPattern;
}): Decimal {
  if (!selectedAssetIds || !selectedAssetClassAndBusinessValues) {
    return new Decimal(0);
  }

  return selectedAssetIds.reduce((sum, assetId) => {
    const details = assetOptionDetails[assetId];
    const selectedValue = selectedAssetClassAndBusinessValues[assetId];
    if (!details || !selectedValue) {
      return sum;
    }

    if (pattern === DollarPercentValues.DOLLAR) {
      return sum.plus(selectedValue.amount ?? new Decimal(0));
    } else {
      // For percentages, calculate the dollar value based on the percentage of the total asset value
      const percentage = new Decimal(selectedValue.percent ?? 0).dividedBy(100);
      return sum.plus(details.totalValue.times(percentage));
    }
  }, new Decimal(0));
}
