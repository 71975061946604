import { css } from '@emotion/css';
import { Stack } from '@mui/material';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { Fullscreen } from '@/components/Fullscreen/Fullscreen';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityDiagram } from '@/modules/entityDiagram';
import { EntityDiagramProvider } from '@/modules/entityDiagram/contexts/EntityDiagram.provider';
import { useQueryEntityDiagram } from '@/modules/entityDiagram/hooks';

const styles = {
  fullHeight: css({ height: '100%' }),
};

const MIN_HEIGHT = 800;

interface EntityDiagramPageInnerProps {
  entityId: string;
  householdId: string;
}

function EntityDiagramPageInner({
  entityId,
  householdId,
}: EntityDiagramPageInnerProps) {
  return (
    <Stack className={styles.fullHeight} minHeight={MIN_HEIGHT}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <EntityDiagram
            height={Math.max(MIN_HEIGHT, height)}
            width={width}
            householdId={householdId}
            entityId={entityId}
          />
        )}
      </AutoSizer>
    </Stack>
  );
}

export function EntityDiagramPage() {
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');

  const { primaryClients, entity } = useQueryEntityDiagram({
    entityId,
  });

  const hasData = !!primaryClients && !!entity;

  return (
    <Fullscreen name="waterfallDiagram">
      <FlowChartProvider>
        {hasData ? (
          <EntityDiagramProvider
            primaryClients={primaryClients}
            entity={entity}
            presentationMode={false}
          >
            <EntityDiagramPageInner
              entityId={entityId}
              householdId={householdId}
            />
          </EntityDiagramProvider>
        ) : (
          <LoadingOverlay
            open={true}
            updatingText="Loading diagram..."
            data-diagramloading={true.toString()}
          />
        )}
      </FlowChartProvider>
    </Fullscreen>
  );
}
