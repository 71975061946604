import { Maybe } from 'graphql/jsutils/Maybe';
import { compact, upperFirst } from 'lodash';

import { RichListItemProps } from '@/components/lists/RichListItem/RichListItem';
import {
  TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES,
  TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES_SHORT,
} from '@/modules/trustees/trustees.constants';
import { TrustAdvisorLevel, TrustAdvisorRoleKind } from '@/types/schema';
import { englishJoinStringList } from '@/utils/formatting/strings';

import { getTrusteeAdvisorDisplayName } from '../TrustTrusteesList/TrustTrusteesList.utils';
import { TrustAdvisorsList_TrustAdvisorFragment } from './graphql/TrustAdvisorsList.fragments.generated';

const advisorRoleKindDisplayNames: Record<TrustAdvisorRoleKind, string> = {
  DISTRIBUTION_ADVISOR: 'Distribution advisor',
  INVESTMENT_ADVISOR: 'Investment advisor',
  OTHER: 'Other',
  TRUST_PROTECTOR: 'Trust protector',
};

interface AdvisorRole {
  kind: TrustAdvisorRoleKind;
}

function getRolesSummary(advisorRoles: Maybe<AdvisorRole[]>): string {
  const displayDutyKinds =
    advisorRoles?.map((role) => advisorRoleKindDisplayNames[role.kind]) ?? [];
  const joinedList = englishJoinStringList(displayDutyKinds, '&');
  if (joinedList.length === 0) return joinedList;
  return upperFirst(joinedList);
}

export function getFormattedTrustAdvisor(
  advisor: TrustAdvisorsList_TrustAdvisorFragment
): RichListItemProps {
  return {
    heading: getTrusteeAdvisorDisplayName(advisor),
    description: getRolesSummary(advisor.roles ?? null),
    additionalItems: compact([advisor.note]),
  };
}

const ORDERED_TRUST_ADVISOR_LEVELS = [
  TrustAdvisorLevel.Primary,
  TrustAdvisorLevel.Secondary,
  TrustAdvisorLevel.Tertiary,
  TrustAdvisorLevel.Other,
];

export function getGroupedTrustAdvisorRows(
  trustAdvisors: TrustAdvisorsList_TrustAdvisorFragment[],
  opts?: {
    format?: 'long' | 'short';
  }
) {
  const groupedTrustAdvisors: Record<
    TrustAdvisorLevel,
    TrustAdvisorsList_TrustAdvisorFragment[]
  > = {
    [TrustAdvisorLevel.Primary]: [],
    [TrustAdvisorLevel.Secondary]: [],
    [TrustAdvisorLevel.Tertiary]: [],
    [TrustAdvisorLevel.Other]: [],
  };

  trustAdvisors.forEach((trustAdvisor) => {
    const levelToGroup = trustAdvisor.level ?? TrustAdvisorLevel.Other;
    groupedTrustAdvisors[levelToGroup].push(trustAdvisor);
  });

  return compact(
    ORDERED_TRUST_ADVISOR_LEVELS.map((level) => {
      const rowItems = groupedTrustAdvisors[level];
      // don't show sections if there are no items within the section
      if (rowItems.length === 0) return null;

      return {
        heading:
          opts?.format === 'short'
            ? TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES_SHORT[level]
            : TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES[level],
        items: rowItems.map((t) => ({
          ...getFormattedTrustAdvisor(t),
          id: t.id,
        })),
      };
    })
  );
}
