import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { Card } from '@/components/layout/Card/Card';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ManageDispositionsAISuggestionsBanner } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/components/ManageDispositionsAISuggestionsBanner';
import { diagnostics } from '@/utils/diagnostics';

import { useManageDispositionsContext } from './context/ManageDispositions.context';
import { ManageDispositionPageSections } from './ManageDispositionPage.types';
import { ManageDispositionsEntitiesTable } from './ManageDispositionsEntitiesTable/ManageDispositionsEntitiesTable';
import { ManageDispositionsTestamentaryEntitiesTable } from './ManageDispositionsTestamentaryEntitiesTable/ManageDispositionsTestamentaryEntitiesTable';

function useDeathOrderOptions(): ButtonGroupInputOption[] {
  const { primaryClients } = useHouseholdDetailsContext();

  return (
    primaryClients?.map((client) => ({
      display: `${client.firstName} dies first`,
      value: client.id,
    })) ?? []
  );
}

export function ManageDispositionsContent() {
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const { activeClientDeathId, setActiveClientDeathId } =
    useManageDispositionsContext();

  const firstDeathClient = primaryClients?.find(
    (c) => c.id === activeClientDeathId
  );

  useEffect(
    function setInitialActiveClient() {
      if (!primaryClients) return;
      const firstPrimaryClientId = primaryClients[0]?.id ?? '';
      if (!firstPrimaryClientId) {
        diagnostics.error(
          'No primary clients found',
          new Error('no primary clients found')
        );
      }
      setActiveClientDeathId(firstPrimaryClientId);
    },
    [primaryClients, setActiveClientDeathId]
  );

  function handleDeathOrderChange(_: React.SyntheticEvent, clientId: string) {
    setActiveClientDeathId(clientId);
  }

  const deathOrderOptions = useDeathOrderOptions();
  // this should never happen, just for typesafety
  const deathOrderName = firstDeathClient?.firstName ?? 'client';

  return (
    <Card variant="outlined" sx={{ p: 2, minHeight: 400 }}>
      <ManageDispositionsAISuggestionsBanner />
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <TypographyClamped
            lines={1}
            variant="h1"
            data-testid="ManageDispositionsContent-header"
          >
            {isTwoClientHousehold
              ? `If ${deathOrderName} dies first`
              : `Upon ${deathOrderName}'s death`}
          </TypographyClamped>
          {isTwoClientHousehold && (
            <Box
              width={450}
              flexShrink={0}
              data-testid="ManageDispositionsContent-deathOrderToggle"
            >
              <ButtonGroup
                label="Choose death order"
                value={activeClientDeathId}
                hideLabel
                options={deathOrderOptions}
                onChange={handleDeathOrderChange}
              />
            </Box>
          )}
        </Stack>
        <Box>
          <ManageDispositionsTable />
        </Box>
      </Stack>
    </Card>
  );
}

function ManageDispositionsTable() {
  const { activeSection } = useManageDispositionsContext();

  switch (activeSection) {
    case ManageDispositionPageSections.IN_ESTATE_ENTITIES:
      return <ManageDispositionsEntitiesTable variant="in-estate" />;
    case ManageDispositionPageSections.OUT_OF_ESTATE_ENTITIES:
      return <ManageDispositionsEntitiesTable variant="out-of-estate" />;
    case ManageDispositionPageSections.TESTAMENTARY_ENTITIES:
      return <ManageDispositionsTestamentaryEntitiesTable />;
  }
}
