import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { DocumentViewerTab } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';

/**
 * SuggestionEvidence holds the information about the selected suggestion to
 * show the evidence for. We use this object to communicate the annotations and
 * search bar text between the entity forms and the document viewer.
 */
export interface SuggestionEvidence {
  documentId?: string;
  annotations?: DocumentSearchResult[];
  searchQuery?: string;
}

export interface SelectedSuggestionEvidenceContext {
  /**
   * Locate the evidence for a suggestion in the doc viewer.
   * @param suggestion
   */
  locateSuggestion: (suggestion: CommonAiSuggestionFragment) => void;

  /**
   * Unselect the selected suggestion.
   */
  unsetSelectedSuggestion: () => void;

  /**
   * The evidence for the selected suggestion to show in the doc viewer.
   */
  suggestionEvidence: SuggestionEvidence | null;

  /**
   * The tab to show in the document viewer when the user selects a suggestion.
   * This should always swap to the document tab when a suggestion is selected.
   */
  documentViewerTabOverride: DocumentViewerTab | undefined;
}

export const getDefaultValues = (): SelectedSuggestionEvidenceContext => ({
  locateSuggestion: noop,
  unsetSelectedSuggestion: noop,
  suggestionEvidence: {},
  documentViewerTabOverride: undefined,
});

export const SelectedSuggestionEvidenceContext =
  createContext<SelectedSuggestionEvidenceContext>(getDefaultValues());

export const useSelectedSuggestionEvidenceContext = () => {
  return useGuardedContext(
    SelectedSuggestionEvidenceContext,
    'SelectedSuggestionEvidenceProvider'
  );
};
