import { first } from 'lodash';
import { useWatch } from 'react-hook-form';

import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { useFormContext } from '@/components/react-hook-form';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import { EstateWaterfallComparisonTrowserComponentsProps } from '../EstateWaterfallComparisonTrowser.components';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';

export interface EstateWaterfallComparisonTrowserTabsProps
  extends EstateWaterfallComparisonTrowserComponentsProps {
  loading: boolean;
}

export function EstateWaterfallComparisonTrowserTabs({
  loading,
  waterfalls,
}: EstateWaterfallComparisonTrowserTabsProps) {
  const { control, setValue } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const [afterDeath, legacyAssumptions] = useWatch({
    control,
    name: ['afterDeath', 'legacyAssumptions', 'comparisonState'],
  });

  const {
    firstGrantorDeathYear: legacyFirstGrantorDeathYear,
    secondGrantorDeathYear: legacySecondGrantorDeathYear,
  } = legacyAssumptions;

  // intentionally not showing death years if growth profiles are enabled,
  // because each waterfall compared can have its own unique death years
  const firstGrantorDeathYear = isGrowthProfilesEnabled
    ? undefined
    : legacyFirstGrantorDeathYear;
  const secondGrantorDeathYear = isGrowthProfilesEnabled
    ? undefined
    : legacySecondGrantorDeathYear;

  const firstWaterfall = first(waterfalls)?.waterfallNode;

  const tabDisplayStrings = useWaterfallGrantorDeathDisplay({
    firstGrantorDeathYear,
    secondGrantorDeathYear,
    firstGrantorDeath: firstWaterfall?.firstGrantorDeath,
  });

  return (
    <Tabs>
      <ButtonTab
        display={tabDisplayStrings[AfterDeath.None]}
        onClick={() => setValue('afterDeath', AfterDeath.None)}
        disabled={loading}
        isActive={afterDeath === AfterDeath.None}
        data-testid="before-1st-death-tab"
      />
      <ButtonTab
        display={tabDisplayStrings[AfterDeath.First]}
        onClick={() => setValue('afterDeath', AfterDeath.First)}
        disabled={loading}
        isActive={afterDeath === AfterDeath.First}
        data-testid="after-1st-death-tab"
      />
      {isTwoClientHousehold && (
        <ButtonTab
          display={tabDisplayStrings[AfterDeath.Second]}
          onClick={() => setValue('afterDeath', AfterDeath.Second)}
          disabled={loading}
          isActive={afterDeath === AfterDeath.Second}
          data-testid="after-2nd-death-tab"
        />
      )}
    </Tabs>
  );
}
