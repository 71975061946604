import { useCallback, useEffect, useState } from 'react';

import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { AISuggestionsMatcher } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { DispositionScheme } from '@/modules/dispositiveProvisions/dispositiveProvisions.types';
import {
  useDispositionScheme,
  useHasBeenReviewed,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/hooks/utilityHooks';
import { AiSuggestionAcceptanceStatus } from '@/types/schema';

export function EntityFormAIDPRecipientSuggestionsMatcher() {
  const {
    dpSuggestionKinds,
    recipientSuggestions,
    hasUnacknowledgedRecipientSuggestions,
  } = useEntityDPSuggestionsContext();

  const { acknowledgedSuggestions, acknowledgeSuggestion } =
    useEntitySuggestionsContext();

  const hasBeenReviewed = useHasBeenReviewed();
  const dispositionScheme = useDispositionScheme();

  const [allSuggestionsIgnored, setAllSuggestionsIgnored] = useState(false);

  useEffect(() => {
    if (dispositionScheme === DispositionScheme.NONE && hasBeenReviewed) {
      // This means the user has selected that there should not be any distributions.
      // Mark all the suggestions as ignored.
      recipientSuggestions?.forEach((s) => {
        acknowledgeSuggestion({
          suggestionID: s.id,
          status: AiSuggestionAcceptanceStatus.Rejected,
        });
      });
      setAllSuggestionsIgnored(true);
    }
  }, [
    acknowledgeSuggestion,
    dispositionScheme,
    hasBeenReviewed,
    recipientSuggestions,
  ]);

  const preCommitMatchedSuggestions = useCallback(
    (areAllSuggestionsMatched: boolean) => {
      if (areAllSuggestionsMatched || allSuggestionsIgnored) {
        // TODO: If all acknowledged, mark the corresponding KGRoot as review complete - https://linear.app/luminary/issue/T2-4019/[frontend]-remove-ai-dp-notifications-from-notifications-center
      }
    },
    [allSuggestionsIgnored]
  );

  if (
    !recipientSuggestions ||
    !hasUnacknowledgedRecipientSuggestions ||
    !dpSuggestionKinds?.matcherVariantKind
  ) {
    return null;
  }

  return (
    <AISuggestionsMatcher
      variant={dpSuggestionKinds.matcherVariantKind}
      suggestions={
        dispositionScheme === DispositionScheme.NONE
          ? []
          : recipientSuggestions.filter((s) => !acknowledgedSuggestions[s.id])
      }
      preCommitMatchedSuggestions={preCommitMatchedSuggestions}
    />
  );
}
