import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { COLORS } from '@/styles/tokens/colors';

import { RowData } from './DispositionScenarioTable.types';
import { getColumns } from './DispositionScenarioTable.utils';

interface DispositionScenarioTableProps {
  dispositionScenario?: DispositiveProvisions_DispositionScenarioFragment;
  primaryClients: {
    displayName: string;
    id: string;
  }[];
  /**
   * List of dispositions ordered by death event, can be null if we do not want to show the table
   */
  rowDataByDeath: [RowData[], RowData[]] | null;
}

export function DispositionScenarioTable({
  dispositionScenario,
  rowDataByDeath,
  primaryClients,
}: DispositionScenarioTableProps) {
  const theme = useTheme();
  const { firstGrantorDeathName, secondGrantorDeathName } = useMemo(() => {
    const firstGrantorDeath = dispositionScenario?.firstGrantorDeath;

    return {
      firstGrantorDeathName: firstGrantorDeath?.displayName,
      secondGrantorDeathName: primaryClients.find(
        (g) => g.id !== firstGrantorDeath?.id
      )?.displayName,
    };
  }, [dispositionScenario?.firstGrantorDeath, primaryClients]);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  if (!rowDataByDeath) {
    return null;
  }

  if (
    rowDataByDeath.every((rows) => rows?.length === 0) ||
    !dispositionScenario
  ) {
    return (
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          p: 2,
        }}
      >
        <Stack spacing={0.5} width="100%" alignItems="center">
          <Stack direction="row">
            <Typography variant="h6" component="span">
              Dispositions
            </Typography>
          </Stack>
          <Typography variant="subtitle2">None specified</Typography>
        </Stack>
      </Card>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      <Stack spacing={2} width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" component="span">
            Dispositions
          </Typography>
          {primaryClients.length > 1 && (
            <Typography
              variant="h5"
              component="span"
              color={COLORS.NEUTRAL_GRAY[400]}
            >
              {dispositionScenario.firstGrantorDeath.displayName} dies first
            </Typography>
          )}
        </Stack>
        {rowDataByDeath.map((rows, idx) => {
          if (rows.length === 0) {
            return null;
          }

          return (
            <Stack key={`${dispositionScenario.id}-${idx}`}>
              <Stack direction="row" p={1} bgcolor={theme.palette.primary.main}>
                <Typography
                  variant="h6"
                  component="span"
                  color={COLORS.PRIMITIVES.WHITE}
                >
                  Upon{' '}
                  {idx === 0 ? firstGrantorDeathName : secondGrantorDeathName}
                  {'’s '}
                  death
                </Typography>
              </Stack>
              <DataTable
                columns={columns}
                rows={rows}
                hideFooterPagination
                pagination={false}
                columnHeaderHeight={40}
                style={{
                  border: 'none',
                }}
              />
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
