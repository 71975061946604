import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedUpdateLiabilityInput,
  LiabilityPaymentFrequency,
  LiabilityPaymentKind,
  UpdateLiabilityInput,
} from '@/types/schema';

import {
  LiabilityModal_LiabilityFragment,
  useGetLiabilityLazyQuery,
} from './graphql/LiabilityModal.generated';
import { LiabilityFormShape } from './LiabilityModal.constants';

/**
 * @description getFormValuesFromNode is responsible for taking a Liability object
 * and mapping it to the shape of the form in the UI.
 */
export function getFormValuesFromNode(
  data: LiabilityModal_LiabilityFragment
): LiabilityFormShape {
  return {
    // paymentFrequency is always present for all payment details scenarios, and *not*
    // present if the payment details aren't persisted, so it's a good heuristic for
    // the presence of payment details
    _showExpandedPaymentDetails: Boolean(data.paymentFrequency),
    name: data.displayName,
    balanceAsOfDate: data.currentAmountAsOfDate ?? null,
    outstandingBalance: data.currentAmount ?? null,
    issuerId: data.sourceEntity?.id ?? '',
    recipientId: data.recipientEntity?.id ?? '',
    paymentAmount: data.recurringPaymentAmount ?? null,
    termEndDate: data.termEnd ?? null,
    termStartDate: data.termStart ?? null,
    interestRate: data.interestRate ?? null,
    paymentFrequency:
      data.paymentFrequency ?? LiabilityPaymentFrequency.Annually,
    paymentKind: data.paymentKind ?? LiabilityPaymentKind.Amortized,
    hasDifferentPaymentSource: Boolean(data.payFromEntity?.id),
    alternateSourceId: data.payFromEntity?.id ?? '',
  };
}

function getIssuerUpdateProps(
  initialFormValues: LiabilityFormShape | undefined,
  formValues: LiabilityFormShape
): Partial<UpdateLiabilityInput> {
  if (initialFormValues?.issuerId === formValues.issuerId) {
    return {};
  }

  if (formValues.issuerId) {
    return {
      sourceEntityID: formValues.issuerId,
    };
  }

  // this is the scenario where nothing was selected, so we
  // should clear the issuer
  return {
    clearSourceEntity: true,
  };
}

function getRecipientUpdateProps(
  initialFormValues: LiabilityFormShape | undefined,
  formValues: LiabilityFormShape
): Partial<UpdateLiabilityInput> {
  if (initialFormValues?.recipientId === formValues.recipientId) return {};

  if (formValues.recipientId) {
    return {
      recipientEntityID: formValues.recipientId,
    };
  }

  return {
    clearRecipientEntity: true,
  };
}

function getPaymentUpdateProps(
  formValues: LiabilityFormShape
): Partial<UpdateLiabilityInput> {
  // the user has deleted or never expanded the payment details;
  // clear them all and send nothing
  if (!formValues._showExpandedPaymentDetails) {
    return {
      clearPaymentFrequency: true,
      clearInterestRate: true,
      clearTermStart: true,
      clearTermEnd: true,
      clearPaymentKind: true,
      clearRecurringPaymentAmount: true,
    };
  }

  return {
    recurringPaymentAmount: formValues.paymentAmount,
    paymentFrequency: formValues.paymentFrequency,
    interestRate: formValues.interestRate,
    termStart: formValues.termStartDate,
    termEnd: formValues.termEndDate,
    paymentKind: formValues.paymentKind,
  };
}

export function mapFormDataToUpdateInput(
  initialFormValues: LiabilityFormShape | undefined,
  formValues: LiabilityFormShape,
  liabilityId: string
): AugmentedUpdateLiabilityInput {
  const payFromEntityId = formValues.hasDifferentPaymentSource
    ? formValues.alternateSourceId
    : undefined;

  return {
    id: liabilityId,
    update: {
      displayName: formValues.name,
      currentAmountAsOfDate: formValues.balanceAsOfDate,
      currentAmount: formValues.outstandingBalance,
      payFromEntityID: payFromEntityId,
      clearPayFromEntity: !payFromEntityId,
      ...getRecipientUpdateProps(initialFormValues, formValues),
      ...getIssuerUpdateProps(initialFormValues, formValues),
      ...getPaymentUpdateProps(formValues),
    },
  };
}

export function useSyncLiabilityDataToForm(
  reset: UseFormReset<LiabilityFormShape>,
  liabilityId: string
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [getLiability, queryProps] = useGetLiabilityLazyQuery({
    variables: { liabilityId },
    onError: (err) => {
      showFeedback(
        "We weren't able to get the liability information. Please refresh the page and try again."
      );
      reportError('LiabilityEditModal: Could not get liability', err, {
        liabilityId,
      });
    },
  });

  useEffect(() => {
    if (!liabilityId) {
      return;
    }

    async function syncLiabilityDataToForm() {
      const { data } = await getLiability();
      if (data?.node?.__typename === 'Liability') {
        reset(getFormValuesFromNode(data.node));
      }
    }

    void syncLiabilityDataToForm();
  }, [reset, getLiability, liabilityId]);

  return queryProps;
}
