import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { noop } from 'lodash';
import { ComponentType } from 'react';

import { Legend } from '@/components/charts/Legend/Legend';
import { EstateWaterfallSummaryBarCharts } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/EstateWaterfallSummaryBarCharts';
import { useEstateWaterfallSummaryLegendSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryLegend.hook';
import { useWaterfallSummaryBarChartSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { EstateWaterfallSummaryTiles } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/EstateWaterfallSummaryTiles';
import { ClientPresentationDesigner_EstateWaterfallFragment } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { DisclaimerCallout } from '@/modules/presentation/estateWaterfall/DisclaimerCallout';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';

import { useClientPresentationDesignerV2Context } from '../../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Bundle } from '../../../../types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';

interface EstateWaterfallSummarySlideInnerProps
  extends EstateWaterfallSummarySlideProps {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}

function EstateWaterfallSummarySlideInner({
  bundle,
  page,
  waterfall,
  SlideWrapper = Box,
}: EstateWaterfallSummarySlideInnerProps) {
  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
    onOpenSectionSummaryPanel: noop,
  });

  const legendSections =
    useEstateWaterfallSummaryLegendSections(waterfallSections);

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={page.id}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={bundle.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<PresentationSlide.Footer />}
      >
        <Stack
          spacing={3}
          justifyContent="space-between"
          p={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          <Stack spacing={3} height="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography variant="h2">Waterfall summary</Typography>
            </Stack>
            <EstateWaterfallSummaryBarCharts sections={waterfallSections} />
            <Box sx={{ maxHeight: '478px' }}>
              <Box sx={{ transform: 'scale(0.8)', transformOrigin: '50% 0' }}>
                <EstateWaterfallSummaryTiles waterfall={waterfall} />
              </Box>
            </Box>
            <div style={{ marginTop: 'auto' }}>
              <Legend sections={legendSections} />
            </div>
          </Stack>
          <DisclaimerCallout waterfall={waterfall} />
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export interface EstateWaterfallSummarySlideProps {
  bundle: ClientPresentationV2Bundle;
  SlideWrapper?: ComponentType<BoxProps>;
  page: ClientPresentationV2Page;
}

export function EstateWaterfallSummarySlide({
  bundle,
  SlideWrapper,
  page,
}: EstateWaterfallSummarySlideProps) {
  /*
  useRegisterSlide({
    displayName: 'Summary',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });
  */

  const { estateWaterfallBundleConfigurationMap } =
    useClientPresentationDesignerV2Context();

  const waterfall = estateWaterfallBundleConfigurationMap[bundle.id];
  if (!waterfall) {
    diagnostics.error(`No waterfall found for bundle ${bundle.id}`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Waterfall not found
      </Typography>
    );
  }

  return (
    <EstateWaterfallSummarySlideInner
      bundle={bundle}
      page={page}
      waterfall={waterfall}
      SlideWrapper={SlideWrapper}
    />
  );
}
