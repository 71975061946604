import Decimal from 'decimal.js';

import {
  EntityAssetOptionsQuery,
  IndividualAssetOptionsQuery,
} from '../graphql/SaleLoanForm.generated';
import { UseAssetOptionsReturn } from './useAssetOptions';

export function generateAssetOptionDetails(
  entityAssetData: EntityAssetOptionsQuery | undefined,
  individualAssetData: IndividualAssetOptionsQuery | undefined
): UseAssetOptionsReturn['assetOptionDetails'] {
  const details: UseAssetOptionsReturn['assetOptionDetails'] = {};

  // Handle entity assets
  if (entityAssetData?.node?.__typename === 'Entity') {
    const entity = entityAssetData.node;
    const latestValuation =
      entity.subtype?.designerAccount?.valuations.edges?.[0]?.node;

    // Add asset class details
    latestValuation?.assets.edges?.forEach((edge) => {
      const asset = edge?.node;
      if (asset) {
        const classId = asset.class.id;
        if (!details[classId]?.totalValue) {
          details[classId] = {
            displayName: asset.class.displayName,
            totalValue: new Decimal(0),
            ownershipPercentage: null,
          };
        }
        details[classId]!.totalValue = details[classId]!.totalValue.plus(
          asset.assetValue.ownedValue ?? new Decimal(0)
        );
      }
    });

    // Add business entity details
    entity.ownedOwnershipStakes?.forEach((stake) => {
      if (stake.ownedEntity) {
        details[stake.ownedEntity.id] = {
          displayName: stake.ownedEntity.subtype.displayName,
          totalValue: stake.ownedValue,
          ownershipPercentage: stake.ownershipPercentage ?? null,
        };
      }
    });
  }

  // Handle individual assets (business entities only)
  if (individualAssetData?.node?.__typename === 'ClientProfile') {
    individualAssetData.node.ownedOwnershipStakes?.forEach((stake) => {
      if (stake.ownedEntity) {
        details[stake.ownedEntity.id] = {
          displayName: stake.ownedEntity.subtype.displayName,
          totalValue: stake.ownedValue,
          ownershipPercentage: stake.ownershipPercentage ?? null,
        };
      }
    });
  }

  return details;
}
