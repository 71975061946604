import { Box, Stack } from '@mui/material';
import { compact, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { Card } from '@/components/layout/Card/Card';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { Loader } from '@/components/progress/Loader/Loader';
import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  TriggerAIDPSuggestionsBanner,
  TriggerDPAISuggestionsVariant,
} from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/TriggerAIDPSuggestionsBanner';
import { useHasEntityTriggeredAIDPWorkflow } from '@/modules/aiDP/hooks/useHasEntityTriggeredAIDPWorkflow';
import { AISuggestionsStatusBanner } from '@/modules/aiSuggestions/components/AISuggestionsStatusBanner';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { EDIT_ENTITY_SECTION_DISPLAY } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.constants';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import {
  getAsyncJobKindToTrackForActiveTab,
  getEditEntitySectionAiSuggestionKinds,
  getInitialSectionOptions,
  getSectionForEntityDetailsActiveTab,
  getSectionHasUnacknowledgedSuggestions,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { EditEntitySplitScreenModal } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenModal';
import { EditEntitySplitScreenOpenModalButton } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenOpenModalButton';

import { useEntityDetailsContext } from '../contexts/entityDetails/entityDetails.context';
import {
  NonTrustCharitableEntityDetailsTabs,
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '../details/entityDetails.types';
import {
  EntityDetails,
  EntityDetailsView,
  EntityDetailTab,
} from './EntityDetails';

export const ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM = 'entityTab';

export interface EntityDetailsCardProps {
  tabs: EntityDetailTab[];
  loading?: boolean;
  entityId: string;
  householdId: string;
}

export function EntityDetailsCard({
  tabs,
  loading = false,
}: EntityDetailsCardProps) {
  const trackUserEvent = useTrackUserEvent();

  // Whether to show the new Edit Entity Details button for the active tab.
  // Currently, this is only applicable for Rev/Irrev Trusts, on the Summary,
  // Trustees, and Beneficiary tabs. If there are no Trustees/Beneficiaries,
  // the button will not be shown (for that tab). We need to pass this as a function
  // that will be set by the child component that actual renders the active tab
  // details, because we don't resolve the entity details until then.
  const [
    shouldShowEditEntityButtonForTab,
    setShouldShowEditEntityButtonForTab,
  ] = useState<boolean>(false);

  const { activeTab, setActiveTab } = useActiveTab<EntityDetailsView | ''>({
    defaultTab: '',
    filterSearchParam: ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM,
  });

  const { entityId, entityType } = useEntityDetailsContext();
  const { suggestionsByKind } = useEntitySuggestionsContext();
  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityType);

  const buttonGroupOptions = useMemo(() => {
    return compact(
      tabs.map(({ display, value }) => {
        return {
          display,
          value,
        };
      })
    );
  }, [tabs]);

  // If the current active tab is not in the list of tabs, set the active tab to the first tab
  useEffect(() => {
    if (
      tabs.length > 0 &&
      tabs.find((tab) => tab.value === activeTab) === undefined
    ) {
      // If we are trying to link the the dispositions tab, and it's not in the list of tabs,
      // we can try to intuit the correct disposions tab
      if (activeTab === TrustDetailsTabs.DISPOSITIONS) {
        const otherPossibleDispositionTabs = [
          NonTrustCharitableEntityDetailsTabs.DISPOSITIONS,
          PersonalAccountDetailsTabs.DISPOSITIONS,
        ];

        const otherDispositionTab = otherPossibleDispositionTabs.find((tab) =>
          tabs.find((t) => t.value === tab)
        );

        if (otherDispositionTab) {
          setActiveTab(otherDispositionTab);
          return;
        }
      }

      setActiveTab(tabs[0]!.value);
    }
  }, [activeTab, loading, setActiveTab, tabs]);

  // If there are tabs and the active tab is not set, set the active tab to the first tab
  useEffect(() => {
    if (tabs.length > 0 && activeTab === '') {
      setActiveTab(tabs[0]!.value);
    }
  }, [activeTab, loading, setActiveTab, tabs]);

  const { shouldHideTriggerBanner, handleStartWorkflowSuccess } =
    useHasEntityTriggeredAIDPWorkflow();

  const activeTabSupportsAiSuggestions = useMemo(() => {
    if (!entityType || !activeTab) {
      return false;
    }

    const entitySectionsForTab = getSectionForEntityDetailsActiveTab(
      activeTab,
      entityType
    );

    const tabSupportsAiSuggestions = entitySectionsForTab.some(
      (section) => getEditEntitySectionAiSuggestionKinds(section).length > 0
    );

    return aiSuggestionsEnabled && tabSupportsAiSuggestions;
  }, [activeTab, aiSuggestionsEnabled, entityType]);

  const openEditEntityModalButtonProps = useMemo(() => {
    const initialSectionOptions = getInitialSectionOptions(
      activeTab,
      entityType
    );

    if (!initialSectionOptions) return undefined;

    if (initialSectionOptions.length === 1) {
      // There's only 1 section option, so we won't show the button as a dropdown.
      const firstOption: EditEntitySection = initialSectionOptions[0]!;
      return {
        buttonText: EDIT_ENTITY_SECTION_DISPLAY[firstOption],
        initialSection: firstOption,
        fullWidth: true,
        size: 'lg' as const,
      };
    }

    // Show the button as a dropdown to user can select which section to open to.
    return {
      initialSectionOptions,
    };
  }, [activeTab, entityType]);

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const handleReviewSuggestionsClick = useCallback(() => {
    openModal();
    trackUserEvent('ai_suggestion review suggestions', {
      entityType,
    });
  }, [entityType, openModal, trackUserEvent]);

  const sectionsWithSuggestions = useMemo(() => {
    if (!aiSuggestionsEnabled || !activeTab || !entityType) {
      return [];
    }

    const entitySectionsForTab = getSectionForEntityDetailsActiveTab(
      activeTab,
      entityType
    );

    return entitySectionsForTab.filter((section) => {
      return getSectionHasUnacknowledgedSuggestions(section, suggestionsByKind);
    });
  }, [activeTab, aiSuggestionsEnabled, entityType, suggestionsByKind]);

  const initialSectionToReview = useMemo(() => {
    if (!activeTab) return undefined;

    const sectionOptions = getInitialSectionOptions(activeTab, entityType);
    if (Array.isArray(sectionOptions)) {
      // when the user clicks "review suggestions" take them to the first section that has AI suggestions outstanding
      return sectionOptions.find((section) =>
        sectionsWithSuggestions.includes(section)
      );
    }
    return sectionOptions;
  }, [activeTab, entityType, sectionsWithSuggestions]);

  const asyncJobToTrack = useMemo(
    () => getAsyncJobKindToTrackForActiveTab(activeTab),
    [activeTab]
  );

  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: false }}>
      <Card
        variant="outlined"
        sx={{
          p: 3,
          pb: 4,
          height: '100%',
        }}
      >
        {loading && (
          <Loader
            boxProps={{
              sx: {
                textAlign: 'center',
                my: 3,
              },
            }}
          />
        )}
        <Box display={loading ? 'none' : 'block'}>
          <Box mb={3}>
            <ButtonGroup<EntityDetailsView | ''>
              label=""
              options={buttonGroupOptions}
              value={activeTab}
              onChange={(_e, value) => {
                setShouldShowEditEntityButtonForTab(false);
                setActiveTab(value);
              }}
            />
          </Box>
          {activeTab !== '' && (
            <Stack spacing={3}>
              {activeTabSupportsAiSuggestions && asyncJobToTrack && (
                <AISuggestionsStatusBanner
                  trackedAsyncJobKind={asyncJobToTrack}
                  onReviewSuggestionsClick={
                    isEmpty(sectionsWithSuggestions)
                      ? undefined
                      : handleReviewSuggestionsClick
                  }
                />
              )}
              {!shouldHideTriggerBanner &&
                entityId &&
                activeTab === TrustDetailsTabs.DISPOSITIONS && (
                  <TriggerAIDPSuggestionsBanner
                    variant={TriggerDPAISuggestionsVariant.SINGLE_ENTITY}
                    onStartWorkflowSuccess={handleStartWorkflowSuccess}
                    entityId={entityId}
                  />
                )}
              <EntityDetails
                tabs={tabs}
                activeEntityView={activeTab}
                onShowEditEntity={() =>
                  setShouldShowEditEntityButtonForTab(true)
                }
              />
              {openEditEntityModalButtonProps &&
                shouldShowEditEntityButtonForTab && (
                  <Box>
                    <EditEntitySplitScreenOpenModalButton
                      {...openEditEntityModalButtonProps}
                      navigateAfterSave
                    />
                  </Box>
                )}
              {isModalOpen && initialSectionToReview && entityType && (
                <EditEntitySplitScreenModal
                  initialSection={initialSectionToReview}
                  entityType={entityType}
                  navigateAfterSave
                  isOpen={isModalOpen}
                  onClose={closeModal}
                />
              )}
            </Stack>
          )}
        </Box>
      </Card>
    </InteractionParadigmContext.Provider>
  );
}
