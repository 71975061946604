import { EntityDiagramActionFn, SelectPrimaryClientAction } from '../types';

export const selectPrimaryClientAction: EntityDiagramActionFn<
  SelectPrimaryClientAction
> = (state, action, queueEffect) => {
  queueEffect({ type: 'reinitializeEffect' });

  return {
    ...state,
    selectedPrimaryClientId: action.primaryClientId,
  };
};
