import { Stack, Typography } from '@mui/material';
import { chunk } from 'lodash';

import { EntityDetailNameCard } from './EntityDetailNameCard';

interface ControllingPersonsProps {
  showControllingPersons: boolean;
  controllingPartyNames: string[];
}

export function ControllingPersons({
  showControllingPersons,
  controllingPartyNames,
}: ControllingPersonsProps) {
  if (!showControllingPersons) {
    return null;
  }

  const chunkedControllingPartyNames =
    chunk(controllingPartyNames, 4)?.[0] ?? [];

  const exceedsMax = controllingPartyNames.length > 4;

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Controlling persons/signaturies
      </Typography>
      {chunkedControllingPartyNames.map((name, idx) => (
        <EntityDetailNameCard key={`${name}-${idx}`} leftText={name} />
      ))}
      {chunkedControllingPartyNames.length === 0 && (
        <Typography variant="subtitle2">None specified</Typography>
      )}
      {exceedsMax && (
        <Typography variant="subtitle2">
          {`+${controllingPartyNames.length - 4} more`}
        </Typography>
      )}
    </Stack>
  );
}
