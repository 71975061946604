import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { groupBy } from 'lodash';

import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { HEIGHT_CONFIG } from '@/modules/presentation/entities/entityPresentationPagination.utils';
import { EntityKind } from '@/types/schema';

import { getRows as getExpandableBeneficiaryRows } from './ExpandableBeneficiaryTable.utils';
import { RowData } from './FlatBeneficiaryTable.types';
import { TrustBeneficiariesList_BeneficiaryFragment } from './graphql/TrustBeneficiariesList.fragments.generated';

interface GetColumnsInput {
  heading?: string;
}

export function getColumns({
  heading = 'Beneficiaries',
}: GetColumnsInput): Column<RowData>[] {
  return [
    {
      field: 'beneficiary',
      headerName: heading,
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.beneficiary,
        lineOneProps: { variant: 'h5' },
        cellContainerProps: {
          sx: {
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          },
        },
      }),
      sortComparator: (
        v1: RowData['beneficiary'],
        v2: RowData['beneficiary'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1, v2, p1, p2);
      },
    },
    {
      field: 'access',
      headerName: 'Access',
      flex: 1,
      sortable: false,
      renderCell: ({ row, colDef }) => (
        <CellContainer
          align={colDef.align}
          sx={{
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          }}
        >
          {row.access.component}
        </CellContainer>
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      // Note, this style is referenced indirectly in entityPresentationPagination.utils.ts
      // in the HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX constant
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.details.lineOne ?? '',
        lineTwo: ({ row }) => row.details.lineTwo ?? '',
        cellContainerProps: {
          sx: {
            p: 1.5,
            maxHeight: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
          },
        },
      }),
      sortComparator: (
        v1: RowData['details'],
        v2: RowData['details'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
      },
    },
  ];
}

export function getRows(
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[],
  entityKind?: EntityKind
): RowData[] {
  // Get the rows using the same logic as the ExpandableBeneficiaryTable
  const rows = getExpandableBeneficiaryRows(beneficiaries, entityKind);
  const groupedRows = groupBy(rows, (row) => row.path[0] ?? 'beneficiary');
  // Reduce the rows to a single row per benefit
  const reducedRows = Object.entries(groupedRows).flatMap(([_, benefits]) => {
    const beneficiaryName =
      benefits.find((row) => row.path[1] === undefined)?.beneficiary.lineOne ??
      '';
    return benefits.flatMap((benefit) => {
      const access: RowData['access'] = {};
      const details: RowData['details'] = {};
      if (benefit.path[1]) {
        // If the row has a path[1], it is a scheduled distribution or access parameter
        access.component = benefit.beneficiary.rightContent;
        details.lineOne = benefit.beneficiary.lineOne;
        details.lineTwo = benefit.beneficiary.lineTwo;

        return {
          id: benefit.path.join('.'),
          beneficiary: beneficiaryName,
          access,
          details,
        };
      }

      return [];
    });
  });

  return reducedRows;
}
