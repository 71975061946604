import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  DeleteMenuItemWithConfirmationModal,
  DeleteMenuItemWithConfirmationModalProps,
} from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { useReportError } from '@/hooks/useReportError';
import { pruneDeletedEntityAIDPNotifications } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.utils';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import {
  FetchInitialAsyncJobNotificationsDocument,
  FetchInitialAsyncJobNotificationsQuery,
} from '@/modules/asyncJobs/AsyncJobNotifications/graphql/asyncJobNotifications.generated';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';

import { useDeleteEntityMutation } from '../graphql/Entities.generated';

interface DeleteEntityMenuItemProps
  extends Partial<DeleteMenuItemWithConfirmationModalProps> {
  entityId: string;
  onDelete: () => void;
}

export function DeleteEntityMenuItem({
  entityId,
  onDelete,
  modalProps = {},
  menuItemProps = {},
  ...deleteProps
}: DeleteEntityMenuItemProps) {
  const user = useCurrentUser();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { removeEntityNotifications } = useAsyncJobNotificationsContext();

  const [deleteEntity, { loading: deleting }] = useDeleteEntityMutation({
    variables: { id: entityId },
    onError: (error) => {
      reportError(`failed to delete entity`, error);
      showFeedback(
        'Failed to delete this entity. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      showFeedback('Entity deleted successfully', { variant: 'success' });
      removeEntityNotifications(entityId);
      onDelete();
    },
    update(cache) {
      // Update the cache for any notifications related to the deleted entity
      const existingData =
        cache.readQuery<FetchInitialAsyncJobNotificationsQuery>({
          query: FetchInitialAsyncJobNotificationsDocument,
          variables: { userID: user?.id },
        });

      if (!existingData) return;

      const prunedDP = pruneDeletedEntityAIDPNotifications(
        entityId,
        existingData.aiDp
      );

      // Write the updated data back to the cache
      cache.writeQuery<FetchInitialAsyncJobNotificationsQuery>({
        query: FetchInitialAsyncJobNotificationsDocument,
        variables: { userID: user?.id },
        data: {
          ...existingData,
          aiDp: prunedDP,
        },
      });
    },
  });

  return (
    <DeleteMenuItemWithConfirmationModal
      label="Delete entity"
      onConfirmDelete={() => deleteEntity()}
      {...deleteProps}
      menuItemProps={{ loading: deleting, ...menuItemProps }}
      modalProps={{
        heading: 'Are you sure you want to delete this entity?',
        ...modalProps,
      }}
    />
  );
}
