import { HypotheticalSaleLoanKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

export function getSaleLoanKindDisplay(kind: HypotheticalSaleLoanKind): string {
  switch (kind) {
    case HypotheticalSaleLoanKind.Sale:
      return 'Installment sale';
    case HypotheticalSaleLoanKind.Loan:
      return 'Intra-family loan';
    default:
      throw new UnreachableError({
        case: kind,
        message: `Invalid hypothetical sale/loan kind specified: ${kind}`,
      });
  }
}
