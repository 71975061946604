import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { KgRootWithAsyncJobFragment } from '@/modules/asyncJobs/AsyncJobNotifications/graphql/asyncJobNotifications.generated';
import { AiSuggestionKind, KgRootKind } from '@/types/schema';

export interface EntityDPSuggestionsContext {
  loadingSuggestions: boolean;

  /**
   * Depending on which primary client death form we're on, these are the
   * suggestion kinds we should use. For example, if we're on the first grantor
   * death form, we will use DP_RECIPIENT_GRANTOR_1_DIES_FIRST instead of
   * DP_RECIPIENT_GRANTOR_2_DIES_FIRST, for the recipient suggestions.
   */
  dpSuggestionKinds:
    | {
        recipientSuggestionKind: AiSuggestionKind;
        matcherVariantKind: AISuggestionsMatcherVariant;
        kgRootKind: KgRootKind;
      }
    | undefined;

  /**
   * The dp recipient suggestions, for the current primary client death form.
   */
  recipientSuggestions: CommonAiSuggestionFragment[] | undefined;

  /**
   * The kgRoot that the suggestions are associated with, for the current primary client death form.
   */
  kgRoot: KgRootWithAsyncJobFragment | undefined | null;

  /**
   * Indicates if there are any suggestions for the current primary client death form.
   */
  hasSuggestions: boolean;

  /**
   * Indicates if there are any unacked recipient suggestions,
   * for the current primary client death form.
   */
  hasUnacknowledgedRecipientSuggestions: boolean;
}

export const EntityDPSuggestionsContext =
  createContext<EntityDPSuggestionsContext>({
    loadingSuggestions: true,
    dpSuggestionKinds: undefined,
    recipientSuggestions: undefined,
    hasSuggestions: false,
    hasUnacknowledgedRecipientSuggestions: false,
    kgRoot: undefined,
  });

export const useEntityDPSuggestionsContext = () => {
  return useGuardedContext(
    EntityDPSuggestionsContext,
    'EntityDPSuggestionsContextProvider'
  );
};
