import { some } from 'lodash';

import { AsyncJobNotificationStatus } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { FetchInitialAsyncJobNotificationsQuery } from '@/modules/asyncJobs/AsyncJobNotifications/graphql/asyncJobNotifications.generated';
import { AsyncJobKind, AsyncJobStatus, KgRootStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

/**
 * Maps a KGRoot status to a NotificationStatus
 * @param status
 */
export function mapKGRootStatusToNotificationStatus(
  status: KgRootStatus
): AsyncJobNotificationStatus {
  switch (status) {
    case KgRootStatus.InProgress:
      return AsyncJobNotificationStatus.IN_PROGRESS;
    case KgRootStatus.Complete:
      return AsyncJobNotificationStatus.COMPLETED;
    case KgRootStatus.SuggestionReviewComplete:
      return AsyncJobNotificationStatus.ACKNOWLEDGED;
    default:
      throw new UnreachableError({
        case: status,
        message: `Unknown KGRoot status for notifications: ${status}`,
      });
  }
}

/**
 * Maps an AsyncJob status to a NotificationStatus
 * @param status
 */
export function mapAsyncJobStatusToNotificationStatus(
  status: AsyncJobStatus
): AsyncJobNotificationStatus {
  switch (status) {
    case AsyncJobStatus.Cancelled: // We don't typically set this status.
    case AsyncJobStatus.Completed:
      return AsyncJobNotificationStatus.COMPLETED;
    case AsyncJobStatus.Failed:
      return AsyncJobNotificationStatus.FAILED;
    case AsyncJobStatus.InProgress:
    case AsyncJobStatus.Queued:
      return AsyncJobNotificationStatus.IN_PROGRESS;
  }
}

/**
 * Prunes AIOnboarding notifications related to the deleted household.
 * We don't want to show notifications for deleted households.
 *
 * @param deletedHouseholdId
 * @param cached
 */
export function pruneDeletedHouseholdAIOnboardingNotifications(
  deletedHouseholdId: string,
  cached: FetchInitialAsyncJobNotificationsQuery['aiOnboarding']
): FetchInitialAsyncJobNotificationsQuery['aiOnboarding'] {
  if (!cached?.edges) return cached; // Nothing to prune

  // Filter out AIOnboarding notification nodes that reference the deleted household
  const pruned = cached.edges.filter((edge) => {
    return edge?.node?.household?.id !== deletedHouseholdId;
  });

  return {
    ...cached,
    edges: pruned,
  };
}

/**
 * Prunes AIDP notifications related to the deleted household.
 * We don't want to show notifications for deleted households.
 *
 * @param deletedHouseholdId
 * @param cached
 */
export function pruneDeletedHouseholdAIDPNotifications(
  deletedHouseholdId: string,
  cached: FetchInitialAsyncJobNotificationsQuery['aiDp']
): FetchInitialAsyncJobNotificationsQuery['aiDp'] {
  if (!cached) return cached; // Nothing to prune

  // Exclude AIDP notification nodes that reference the deleted household
  return cached.filter((j) => {
    if (j.kind === AsyncJobKind.AiDpBatched) {
      // Exclude if any child references the deleted household
      return !some(j.children, (child) =>
        some(child?.kgRoot, (r) => r.household?.id === deletedHouseholdId)
      );
    }
    // Exclude if any kgRoot references the deleted household
    return !some(j?.kgRoot, (r) => r.household?.id === deletedHouseholdId);
  });
}

/**
 * Prunes AIOnboarding notifications related to the deleted entity.
 * We don't want to show notifications for deleted entities.
 *
 * @param deletedEntityId
 * @param cached
 */
export function pruneDeletedEntityAIDPNotifications(
  deletedEntityId: string,
  cached: FetchInitialAsyncJobNotificationsQuery['aiDp']
): FetchInitialAsyncJobNotificationsQuery['aiDp'] {
  if (!cached) return cached; // Nothing to prune

  // Exclude AIDP notification nodes that reference the deleted entity
  return cached.filter((j) => {
    // Batched jobs are not related to specific entities
    if (j.kind === AsyncJobKind.AiDpBatched) return true;

    // Exclude if any kgRoot references the deleted entity
    return !some(j?.kgRoot, (r) => r.entity?.id === deletedEntityId);
  });
}
