import { Theme } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { compact } from 'lodash';
import { marked, Renderer } from 'marked';

import { DocumentSummaryPDF } from './component/DocumentSummaryPDF';

/**
 * Override the 'del' method to use <s> instead of <del>, because the
 * react-pdf/renderer actually hides content in a del tag rather than
 * striking it through.
 *
 * This is the only way that I can find to do this; the `del` handling
 * inside react-pdf/renderer really, really wants the (deprecated) s tag
 * and insists on deleting `del` tags. I tried rewriting `del` tags, but
 * doing so force surrounding content onto a new line.
 *
 * @returns A marked instance that has been configured to use the compatible renderer.
 */
export function getReactPDFCompatibleMarked() {
  const renderer: Renderer = {
    del({ tokens }) {
      return `<s>${this.parser.parseInline(tokens)}</s>`;
    },
  } as Renderer;

  marked.use({ renderer });

  return marked;
}

/**
 * Converts the document summary in markdown format to a PDF blob via HTML.
 *
 * @param documentSummary The document summary markdown to convert to PDF.
 * @param displayName The display name of the household.
 * @param editedDate The date the document was last edited.
 * @returns A blob containing the PDF and the summary title.
 */
export async function markdownToPDFBlob(
  documentSummary: string,
  displayName: string | null,
  customerTheme: Theme
): Promise<{ blob: Blob; summaryTitle: string }> {
  // Remove the added whitespace around the bullets in the markdown
  const markdown = preprocessMarkdown(documentSummary);
  // Convert the markdown to HTML
  const htmlContent = await getReactPDFCompatibleMarked()(markdown, {
    pedantic: false,
    gfm: true,
  });

  const summaryComponent = (
    <DocumentSummaryPDF
      htmlDocumentSummary={htmlContent}
      householdDisplayName={displayName}
      customerTheme={customerTheme}
    />
  );
  // Generate the PDF
  const blob = await pdf(summaryComponent).toBlob();
  const summaryTitle = compact([
    extractSummaryTitle(htmlContent),
    'Summary',
  ]).join(' ');
  return { blob, summaryTitle };
}

/**
 * Extracts the title from the first h1 tag in the HTML content.
 * @param html The HTML content to extract the title from.
 * @returns The title from the first h1 tag or null if no h1 tag is found.
 */
export function extractSummaryTitle(html: string): string | undefined {
  const match = html.match(/<h1[^>]*>(.*?)<\/h1>/);
  return match ? match[1] : undefined;
}

/**
 * Preprocesses the Markdown content to adjust spaces for list items but preserve nested structures.
 * /^(\s+)(- .+)/gm: Matches lines with leading spaces followed by a list item.
 * (\s+): Captures leading spaces.
 * (- .+): Captures the list item.
 * @param markdown The Markdown content to preprocess.
 * @returns The preprocessed Markdown content.
 */
export function preprocessMarkdown(markdown: string): string {
  return markdown.replace(
    /^(\s+)(- .+)/gm,
    (_, spaces: string, item: string) => {
      const level = Math.floor(spaces.length / 4); // Our prompt uses 4 spaces for each level of nesting
      return '  '.repeat(level) + item.trim();
    }
  );
}
