import { Box, Fade, styled, Typography, useTheme } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type AvatarVariant = 'light' | 'dark';
export interface AvatarProps {
  size: AvatarSize;
  initials: string;
  fullName: string;
  email?: string;
  variant?: AvatarVariant;
  onlyShowInitials?: boolean;
  animationTimingMs?: number;
}

const InitialsContainer = styled('span')<{ size: AvatarSize }>(({
  size,
}: {
  size: AvatarSize;
}) => {
  const dimensionsBySize: Record<AvatarSize, number> = {
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48,
    xl: 56,
  };
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.NAVY[50],
    height: dimensionsBySize[size],
    width: dimensionsBySize[size],
    borderRadius: dimensionsBySize[size],
  };
});

export function Avatar({
  size,
  initials,
  fullName,
  email,
  onlyShowInitials,
  animationTimingMs,
  variant = 'dark',
}: AvatarProps) {
  const theme = useTheme();
  const typeStyleOverrides =
    variant === 'light' ? { color: theme.palette.common.white } : {};
  return (
    <Box display="flex" justifyContent="flex-start">
      <Box>
        <InitialsContainer size={size}>
          <Typography variant="h6">{initials}</Typography>
        </InitialsContainer>
      </Box>
      {/* This Fade component being inside the avatar component is overkill, but was the easiest way to implement it for our needs in the sidebar component */}
      <Fade
        in={!onlyShowInitials}
        unmountOnExit={true}
        appear={false}
        timeout={animationTimingMs}
      >
        <Box
          ml={1}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-start"
          flexShrink={2}
        >
          <Typography
            fontWeight="medium"
            {...typeStyleOverrides}
            variant="body1"
          >
            {fullName}
          </Typography>
          <Typography variant="subtitle1">{email}</Typography>
        </Box>
      </Fade>
    </Box>
  );
}
