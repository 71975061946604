import { DEFAULT_ENTITY_DIAGRAM_STATE } from '../contexts/entityDiagram.context';
import { createEntityDiagramGraph } from '../entityDiagramGraph/entityDiagramGraph';
import { extractReactFlowNodesAndEdges } from '../entityDiagramGraph/utils';
import { EntityDiagramState, EntityDiagramStateProps } from '../types';

export function generateEntityDiagramGraphState({
  primaryClients,
  entity,
  isTwoClientHousehold,
  isGeneratingDefaultState = false,
  selectedPrimaryClientId,
}: EntityDiagramStateProps & {
  isGeneratingDefaultState?: boolean;
}): Pick<EntityDiagramState, 'entityDiagramGraph' | 'nodes' | 'edges'> {
  const entityDiagramGraph = createEntityDiagramGraph({
    grantors: primaryClients,
    entity,
    isTwoClientHousehold,
    isGeneratingDefaultState,
    firstGrantorDeathId: selectedPrimaryClientId,
  });

  const { nodes, edges } = extractReactFlowNodesAndEdges(entityDiagramGraph);

  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  // const finalNodes = transformNodesWithViewConfig({
  //   nodes,
  //   nodeConfigurations: entityDiagram?.nodeConfigurations ?? [],
  // });

  return {
    entityDiagramGraph,
    nodes,
    edges,
  };
}

export function generateDefaultState(
  defaultStateProps: EntityDiagramStateProps
): EntityDiagramState {
  const entityDiagramGraphProps = generateEntityDiagramGraphState({
    ...defaultStateProps,
    isGeneratingDefaultState: true,
  });

  return {
    ...DEFAULT_ENTITY_DIAGRAM_STATE,
    ...defaultStateProps,
    ...entityDiagramGraphProps,
  };
}
