import { cx } from '@emotion/css';
import { Box, Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { getIdSafeString } from '@/utils/formatting/strings';

import { useTileClasses } from './styles';
import { TileProps } from './Tile';
import { TileThemedTypographyClamped } from './TileThemedTypography';
import { TileVariant } from './types';

type TileCardProps = TileProps &
  ReturnType<typeof useTileClasses> & {
    variant: Exclude<TileProps['variant'], TileVariant.Pixel>;
  };

export function TileCard({
  variantClasses,
  variant = TileVariant.Primary,
  classes,
  isGrouped,
  isSelected,
  hover,
  lineOne,
  lineTwo,
  lineThree,
  icon: Icon,
  badgeProps,
  children,
  slots,
}: TileCardProps) {
  const TileBadge = badgeProps?.display ? (
    <Badge
      variant={BadgeVariants.Primary}
      display={badgeProps.display}
      {...badgeProps}
    />
  ) : null;

  const hasBadgeRow = Boolean(Icon || TileBadge || slots?.EditButton);

  return (
    <Card
      variant="transparent"
      data-testid={`TileCard-${getIdSafeString(lineOne)}`}
      className={cx(
        variantClasses[variant].root,
        classes.root,
        hover && variantClasses[variant].hoverRoot,
        hover && classes.hoverRoot,
        isGrouped && variantClasses[variant].groupedRoot,
        isGrouped && classes.groupedRoot,
        isSelected && variantClasses[variant].selectedRoot,
        isSelected && classes.selectedRoot
      )}
    >
      {hasBadgeRow && (
        <Stack
          direction="row"
          justifyContent="space-between"
          className={cx(variantClasses[variant].badgeRow, classes.badgeRow)}
        >
          {Icon && (
            <>
              <Icon
                className={cx(variantClasses[variant].icon, classes.icon)}
              />
              <Box flexGrow={1} /> {/* Push rest to the right */}
            </>
          )}
          {TileBadge}
          {slots?.EditButton}
        </Stack>
      )}
      <div
        className={cx(
          variantClasses[variant].linesWrapper,
          classes.linesWrapper
        )}
      >
        <TileThemedTypographyClamped
          variant="h5"
          tileVariant={variant}
          lines={2}
          className={cx(variantClasses[variant].lineOne, classes.lineOne)}
        >
          {lineOne}
        </TileThemedTypographyClamped>
        {lineTwo && (
          <TileThemedTypographyClamped
            lines={2}
            variant="body2"
            tileVariant={variant}
            className={cx(variantClasses[variant].lineTwo, classes.lineTwo)}
          >
            {lineTwo}
          </TileThemedTypographyClamped>
        )}
        {lineThree && (
          <TileThemedTypographyClamped
            lines={2}
            variant="h2"
            tileVariant={variant}
            component="p"
            className={cx(variantClasses[variant].lineThree, classes.lineThree)}
          >
            {lineThree}
          </TileThemedTypographyClamped>
        )}
      </div>
      {children}
    </Card>
  );
}
