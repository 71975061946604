import { Stack } from '@mui/material';
import { compact, first, isUndefined } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import {
  RadioGroupOption,
  SelectInputOption,
} from '@/components/form/baseInputs/inputTypes';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { GiftGrowthRate } from '@/modules/content/tooltipContent/GiftGrowthRate';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { createRadioGroupOptionsFromEnum } from '@/utils/createOptionsFromEnum';
import { getNodes } from '@/utils/graphqlUtils';

import {
  CashflowFrequency,
  CashflowIncomeOrExpense,
  CashflowModalFormPaths,
  CashflowModalFormShape,
  END_AT_BOTH_DEATHS_SENTINEL,
} from './CashflowModal.types';
import { getTargetType, TargetType } from './CashflowModal.utils';
import { useEntityTargets } from './hooks/useEntityTargets';

export interface HouseholdCashflowComponentSharedProps {
  householdId: string | null;
  recipient?: string;
}
export function CashflowModalBody({
  householdId,
  recipient,
}: HouseholdCashflowComponentSharedProps) {
  const { control } = useFormContext<CashflowModalFormShape>();
  const [frequency] = useWatch({
    control,
    name: ['frequency'],
  });
  return (
    <Stack>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Name />
        </FormLayoutItem>
      </FormLayoutRow>
      {!recipient && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <Entity householdId={householdId} />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <IncomeExpenseSelector />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={7}>
          <Amount />
        </FormLayoutItem>
        <FormLayoutItem
          width={5}
          sx={{
            pt: 3.75 /* padding necessary to align with label next to it */,
          }}
        >
          <Frequency />
        </FormLayoutItem>
      </FormLayoutRow>
      {frequency === CashflowFrequency.Annual && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <UntilTerminationSwitch />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        {frequency === CashflowFrequency.Annual ? (
          <>
            <FormLayoutItem width={4}>
              <StartYear />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <EndYear householdId={householdId} />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <GrowthRate />
            </FormLayoutItem>
          </>
        ) : (
          <FormLayoutItem width={12}>
            <StartYear />
          </FormLayoutItem>
        )}
      </FormLayoutRow>
    </Stack>
  );
}

function Name() {
  const { control } = useFormContext<CashflowModalFormShape>();

  return (
    <FormAwareTextInput<CashflowModalFormShape>
      control={control}
      fieldName={'name' as const satisfies CashflowModalFormPaths}
      label="Name"
      required
    />
  );
}

function Entity({ householdId }: HouseholdCashflowComponentSharedProps) {
  const { control } = useFormContext<CashflowModalFormShape>();
  const [options] = useEntityTargets(householdId);
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState<boolean>();

  return (
    <>
      <FormAwareSelectInput<CashflowModalFormShape>
        control={control}
        label="Client/Entity"
        options={options}
        fieldName={'targetId' as const satisfies CashflowModalFormPaths}
        required
        showEmptyValue={false}
        addNewOption={{
          onClick: () => openModal(),
        }}
      />
      {householdId && (
        <AddNewAnythingModal
          isOpen={isModalOpen}
          handleClose={() => closeModal()}
          householdId={householdId}
          addableTypes={['entity']}
          onAfterCreate={() => closeModal()}
        />
      )}
    </>
  );
}

function IncomeExpenseSelector() {
  const { control } = useFormContext<CashflowModalFormShape>();
  const options = useMemo(() => {
    const baseOptions = createRadioGroupOptionsFromEnum(
      CashflowIncomeOrExpense
    );

    return baseOptions.map<RadioGroupOption<string>>((option) => {
      if (option.value === CashflowIncomeOrExpense.Expense.toString()) {
        return {
          ...option,
          label: (
            <Stack direction="row">
              Expense
              <ContextualHelpTooltip>
                <PopperContent body="Expenses modeled will continue until the earlier of end year or until entity is fully depleted." />
              </ContextualHelpTooltip>
            </Stack>
          ),
        };
      }

      return option;
    });
  }, []);

  return (
    <FormAwareRadioGroup<CashflowModalFormShape>
      control={control}
      fieldName={'incomeOrExpense' as const satisfies CashflowModalFormPaths}
      options={options}
      row
    />
  );
}

function Amount() {
  const { control } = useFormContext<CashflowModalFormShape>();

  return (
    <FormAwareCurrencyInput<CashflowModalFormShape>
      control={control}
      fieldName={'amount' as const satisfies CashflowModalFormPaths}
      label="After-tax amount"
      required
      isDecimalJSInput
    />
  );
}

function Frequency() {
  const { control } = useFormContext<CashflowModalFormShape>();
  const options = createRadioGroupOptionsFromEnum(CashflowFrequency);
  return (
    <FormAwareRadioGroup<CashflowModalFormShape>
      control={control}
      fieldName={'frequency' as const satisfies CashflowModalFormPaths}
      options={options}
      row
    />
  );
}

function UntilTerminationSwitch() {
  const { control } = useFormContext<CashflowModalFormShape>();
  const [targetId] = useWatch({ control, name: ['targetId'] });

  const targetType = getTargetType(targetId);

  const label =
    targetType === TargetType.Entity ? 'trust termination' : 'death';

  return (
    <FormAwareSwitch<CashflowModalFormShape>
      control={control}
      fieldName={'endAtTermination' as const satisfies CashflowModalFormPaths}
      label={`Cash flow continues until ${label}`}
      labelPosition="right"
      disabled={!targetId}
    />
  );
}

function StartYear() {
  const { control } = useFormContext<CashflowModalFormShape>();
  const [frequency] = useWatch({
    control,
    name: ['frequency'],
  });

  return (
    <FormAwareFormattedNumberInput<CashflowModalFormShape>
      control={control}
      fieldName={'startYear' as const satisfies CashflowModalFormPaths}
      label={frequency === CashflowFrequency.OneTime ? 'Year' : 'Start year'}
      required
    />
  );
}

function EndYear({ householdId }: HouseholdCashflowComponentSharedProps) {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const { control, setValue } = useFormContext<CashflowModalFormShape>();
  const [targetId, endAtTermination] = useWatch({
    control,
    name: ['targetId', 'endAtTermination'],
  });
  const previousEndAtTermination = usePrevious(endAtTermination);

  const isClientProfile = targetId.toLowerCase().startsWith('cp_');
  const isEntity = targetId.toLowerCase().startsWith('entity_');
  const [_, { data: queryData }] = useEntityTargets(householdId);
  const { possiblePrimaryClients = [] } =
    first(getNodes(queryData?.households)) || {};

  const { endAtDeathOptions, firstEndAtDeathId } = useMemo<{
    endAtDeathOptions: SelectInputOption<string>[];
    firstEndAtDeathId: string | undefined;
  }>(() => {
    const endAtDeathOptions = compact(
      possiblePrimaryClients.map(({ id, firstName }) => ({
        value: id,
        display: `Upon ${firstName}'s death`,
      }))
    );

    const firstEndAtDeathId = first(endAtDeathOptions)?.value;

    if (endAtDeathOptions.length >= 2) {
      return {
        endAtDeathOptions: [
          ...endAtDeathOptions,
          {
            value: END_AT_BOTH_DEATHS_SENTINEL,
            display: 'After both deaths',
          },
        ],
        firstEndAtDeathId,
      };
    }

    return { endAtDeathOptions, firstEndAtDeathId };
  }, [possiblePrimaryClients]);

  // When switching to end at termination for single-grantor household, set the end at death client profile ID to the only client
  useEffect(() => {
    // on first render, previousEndAtTermination is undefined; in this case, skip the update so we don't overwrite the default value
    if (isUndefined(previousEndAtTermination)) return;

    // only applies to single-grantor households
    if (isTwoClientHousehold) return;

    if (endAtTermination && !previousEndAtTermination && firstEndAtDeathId) {
      setValue('endAtDeathClientProfileId', firstEndAtDeathId);
    }
  }, [
    endAtDeathOptions,
    endAtTermination,
    firstEndAtDeathId,
    isTwoClientHousehold,
    previousEndAtTermination,
    setValue,
  ]);

  if (endAtTermination) {
    if (isClientProfile) {
      return (
        <FormAwareSelectInput<CashflowModalFormShape>
          control={control}
          label="End year"
          options={endAtDeathOptions}
          showEmptyValue={false}
          fieldName={
            'endAtDeathClientProfileId' as const satisfies CashflowModalFormPaths
          }
          required
        />
      );
    } else if (isEntity) {
      return <TextInput label="End year" value="Trust termination" disabled />;
    }
  }

  return (
    <FormAwareFormattedNumberInput<CashflowModalFormShape>
      control={control}
      fieldName={'endYear' as const satisfies CashflowModalFormPaths}
      label="End year"
      required
    />
  );
}

function GrowthRate() {
  const { control } = useFormContext<CashflowModalFormShape>();
  return (
    <FormAwarePercentInput<CashflowModalFormShape>
      isDecimalJSInput
      control={control}
      fieldName={'growthRate' as const satisfies CashflowModalFormPaths}
      label="Growth rate"
      required
      fixedDecimalScale
      decimalScale={2}
      contextualHelp={<GiftGrowthRate />}
    />
  );
}
