import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { formatCurrency } from '@/utils/formatting/currency';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { HypotheticalSaleLoanFormShape } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import {
  useEntityAssetOptionsQuery,
  useIndividualAssetOptionsQuery,
} from '../graphql/SaleLoanForm.generated';
import { generateAssetOptionDetails } from './useAssetOptions.utils';

function formatCompactCurrency(value: Decimal | null | undefined) {
  return formatCurrency(value ?? new Decimal(0), { notation: 'compact' });
}

export interface UseAssetOptionsReturn {
  sellerId: string;
  assetOptions: TypeaheadSelectInputOption<string>[];
  assetOptionDetails: Record<
    string,
    {
      displayName: string;
      totalValue: Decimal;
      // ownershipPercentage is only applicable if the asset option is a business entity
      ownershipPercentage: Decimal | null;
    }
  >;
  totalSellerValue: Decimal;
  loading: boolean;
}

export function useAssetOptions(): UseAssetOptionsReturn {
  const { showFeedback } = useFeedback();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();

  const sellerId = useWatch({
    control,
    name: 'sellerId',
  });

  const sellerIdPulidKind = sellerId ? getPulidKind(sellerId) : null;

  const { data: entityAssetData, loading: loadingEntityAssetOptions } =
    useEntityAssetOptionsQuery({
      variables: { entityId: sellerId },
      skip: sellerIdPulidKind !== PulidKind.Entity,
      onError: () => {
        showFeedback('Error fetching asset options for selling entity');
      },
    });

  const { data: individualAssetData, loading: loadingIndividualAssetOptions } =
    useIndividualAssetOptionsQuery({
      variables: { clientProfileId: sellerId },
      skip: sellerIdPulidKind !== PulidKind.ClientProfile,
      onError: () => {
        showFeedback('Error fetching asset options for selling individual');
      },
    });

  const assetOptionDetails = useMemo(
    () => generateAssetOptionDetails(entityAssetData, individualAssetData),
    [entityAssetData, individualAssetData]
  );

  const assetOptions = useMemo(() => {
    return Object.entries(assetOptionDetails).map(([id, details]) => ({
      value: id,
      display: `${details.displayName} (${formatCompactCurrency(details.totalValue)})`,
      groupName:
        details.ownershipPercentage === null
          ? 'Asset classes'
          : 'Business entities',
    }));
  }, [assetOptionDetails]);

  const totalSellerValue = useMemo(() => {
    return Object.values(assetOptionDetails).reduce(
      (acc, detail) => acc.plus(detail.totalValue),
      new Decimal(0)
    );
  }, [assetOptionDetails]);

  return {
    sellerId: sellerId ?? '',
    totalSellerValue,
    assetOptions,
    assetOptionDetails,
    loading: loadingEntityAssetOptions || loadingIndividualAssetOptions,
  };
}
