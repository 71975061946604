import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { EntityDetail_InsurancePolicyFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import {
  InsurancePolicyFrequencyCopyMap,
  InsurancePolicyTypeCopyMap,
} from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { InsurancePolicyKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getMaskedString } from '@/utils/getMaskedString';

import { Policy } from './TrustPoliciesTab';

function ItemDisplay({
  label,
  value,
  subValue,
  isCompact,
  lines,
}: {
  label: string;
  value: string;
  subValue?: string | null;
  isCompact?: boolean;
  lines?: number;
}) {
  return (
    <SubtleLabelItem
      label={label}
      value={
        <Stack direction="row" alignItems="end">
          {lines ? (
            <TypographyClamped
              variant={isCompact ? 'subtitle2' : 'h5'}
              lines={lines}
            >
              {value}
            </TypographyClamped>
          ) : (
            <Typography variant={isCompact ? 'subtitle2' : 'h5'}>
              {value}
            </Typography>
          )}
          {subValue && <Typography>&nbsp;{subValue}</Typography>}
        </Stack>
      }
    />
  );
}

function getPremiumAmountString({
  premiumAmount,
  premiumFrequency,
}: EntityDetail_InsurancePolicyFragment): string {
  if (!premiumFrequency || !InsurancePolicyFrequencyCopyMap[premiumFrequency]) {
    return EMPTY_CONTENT_HYPHEN;
  }

  if (!premiumAmount) return EMPTY_CONTENT_HYPHEN;

  return `${formatCurrencyNoDecimals(
    premiumAmount
  )} ${InsurancePolicyFrequencyCopyMap[premiumFrequency].toLowerCase()}`;
}

function getConversionString({
  conversionDate,
  kind,
}: EntityDetail_InsurancePolicyFragment): string {
  if (kind !== InsurancePolicyKind.Term || !conversionDate) {
    return 'Not convertible';
  }

  return `Convertible as of ${formatDateToMonDDYYYY(conversionDate)}`;
}

export function mapDataToPolicy(
  input: EntityDetail_InsurancePolicyFragment,
  opts?: { isPrint?: boolean }
): Policy {
  const policyNumber: string | undefined | null =
    input.policyNumber && `(${getMaskedString(input.policyNumber)})`;

  const policyName = (
    <ItemDisplay
      label="Policy name"
      value={input.carrierName ?? EMPTY_CONTENT_HYPHEN}
      subValue={policyNumber}
    />
  );

  const premiumAmount = (
    <ItemDisplay
      label="Premium amount & frequency"
      value={getPremiumAmountString(input)}
    />
  );

  const formattedEffectiveDate = compact([
    formatDateToMonDDYYYY(input.startDate),
    input.termDurationYears && `(${input.termDurationYears} year term)`,
  ]).join(' ');

  const policyType = compact([
    InsurancePolicyTypeCopyMap[input.kind],
    input.survivorship && `(survivorship policy)`,
  ]).join(' ');

  return {
    policyName,
    policyType: <ItemDisplay label="Policy type" value={policyType} />,
    insured: (
      <ItemDisplay
        label="Insured"
        value={compact(
          input.policyHolders?.map((insured) => insured?.displayName) || []
        ).join(', ')}
      />
    ),
    deathBenefitAmount: (
      <ItemDisplay
        label="Death benefit amount"
        value={formatCurrencyNoDecimals(input.deathBenefitAmount)}
      />
    ),
    effectiveDate: (
      <ItemDisplay label="Effective date" value={formattedEffectiveDate} />
    ),
    premiumAmount,
    nextPremiumDate: (
      <ItemDisplay
        label="Next premium payment date"
        value={
          input.nextPremiumDueDate
            ? formatDateToMonDDYYYY(input.nextPremiumDueDate)
            : EMPTY_CONTENT_HYPHEN
        }
      />
    ), // TODO -- still waiting on task work
    convertability: input.kind === InsurancePolicyKind.Term && (
      <ItemDisplay label="Convertibility" value={getConversionString(input)} />
    ),
    cashValue: input.kind !== InsurancePolicyKind.Term && (
      <ItemDisplay
        label="Current cash value"
        value={formatCurrencyNoDecimals(input.cashValue || new Decimal(0))}
        subValue={`(as of ${formatDateToMonDDYYYY(input.updatedAt)})`}
      />
    ),
    loanBalanceOutstanding: input.kind !== InsurancePolicyKind.Term && (
      <ItemDisplay
        label="Loans outstanding"
        value={formatCurrencyNoDecimals(
          input.loanBalanceOutstanding || new Decimal(0)
        )}
        subValue={`(as of ${formatDateToMonDDYYYY(input.updatedAt)})`}
      />
    ),
    notes: (
      <ItemDisplay
        label="Notes"
        value={input.notes || EMPTY_CONTENT_HYPHEN}
        isCompact={opts?.isPrint ?? false}
        lines={opts?.isPrint ? 3 : undefined}
      />
    ),
    documentIds: compact((input?.documents || []).map((doc) => doc.id)),
  };
}
