import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
  getTermLabelItem,
} from './trustCardUtils';

export function getQPRTTrustSummaryProperties({
  entity,
  options = { variant: 'interactive' },
}: EntityDetailsData): TrustSummaryData {
  const isInteractive = options.variant === 'interactive';

  if (entity.subtype.__typename !== 'QPRTTrust') {
    throw new Error('Invalid trust type');
  }

  const summary: TrustSummaryData = {
    ...getSharedSummaryProperties(entity),
    grantorNames:
      entity.subtype.grantors?.map(getGrantorNameFromFragment) || [],
    beneficiariesGroups: [
      {
        heading: undefined,
        items: entity.subtype.beneficiaries || [],
        noItemsText: 'No beneficiaries',
      },
    ],
    trustDetailsItems: [
      getTermLabelItem({
        effectiveDate: entity.subtype.effectiveDate,
        termEndDate: entity.subtype.termEndDate,
        label: isInteractive ? undefined : 'Effective date',
      }),
      {
        label: isInteractive ? 'Term length' : 'Length',
        value: entity.subtype.termDurationYears
          ? `${entity.subtype.termDurationYears} years`
          : EMPTY_CONTENT_HYPHEN,
      },
      {
        label: isInteractive ? 'Initial funding value' : 'Initial funding',
        value: entity.subtype.designerAccount?.initialValuation?.valuationValue
          ? formatCurrency(
              entity.subtype.designerAccount?.initialValuation?.valuationValue
            )
          : EMPTY_CONTENT_HYPHEN,
      },
      {
        label: 'Taxable gift amount',
        value: entity.subtype.taxableGift
          ? formatCurrency(entity.subtype.taxableGift)
          : EMPTY_CONTENT_HYPHEN,
      },
      {
        label: '7520 rate',
        value: entity.subtype.officialInterestRatePercent
          ? `${formatPercent(entity.subtype.officialInterestRatePercent, 1)}%`
          : EMPTY_CONTENT_HYPHEN,
      },
    ],
  };

  return summary;
}
