import { startCase, toLower } from 'lodash';

import { AiOnboardingModal_AiSuggestionFragment } from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import {
  formatSuggestedPersonDisplayName,
  getSuggestedActorDisplayName,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.utils';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { AiSuggestionKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

/**
 * Formats the suggestion as a search query string.
 *
 * When the user clicks on a suggestion to locate the evidence, we prefill the
 * DocumentSearchBar input text with the suggestion, and use it to navigate
 * between the pieces of evidence.
 *
 * @param suggestion
 */
export function getSuggestionAsSearchQuery(
  suggestion:
    | CommonAiSuggestionFragment
    | AiOnboardingModal_AiSuggestionFragment
    | undefined
    | null
): string {
  if (!suggestion) {
    return '';
  }

  switch (suggestion.kind) {
    // For suggested actors, use the suggested display name.
    case AiSuggestionKind.Beneficiary:
    case AiSuggestionKind.BeneficiaryV2:
    case AiSuggestionKind.Principal:
    case AiSuggestionKind.TrustAdvisor:
    case AiSuggestionKind.Trustee:
    case AiSuggestionKind.BeneficialOwner:
    case AiSuggestionKind.KeyPerson:
    case AiSuggestionKind.DpRecipientGrantor_1DiesFirst:
    case AiSuggestionKind.DpRecipientGrantor_2DiesFirst:
      return getSuggestedActorDisplayName(suggestion);

    // For single-value suggestions, use the field's label string.
    case AiSuggestionKind.EffectiveDate:
      return `Effective date`;
    case AiSuggestionKind.GstStatus:
      return `GST status`;
    case AiSuggestionKind.JurisdictionStateCode:
      return `Trust jurisdiction`;
    case AiSuggestionKind.LegalName:
      return `Legal name`;
    case AiSuggestionKind.CrummeyWithdrawalPeriodDays:
      return `Crummey withdrawal period`;
    case AiSuggestionKind.DoingBusinessAsName:
      return `Doing business as name`;
    case AiSuggestionKind.FincenId:
      return `FinCEN ID`;
    case AiSuggestionKind.TaxId:
      return `Tax ID (EIN)`;
    case AiSuggestionKind.BusinessEntityTaxStatus:
      return `Tax status`;
    case AiSuggestionKind.BeneficialOwnersAsOfDate:
      return `Beneficial owners as of date`;
    case AiSuggestionKind.OnboardingEntity:
      return 'onboardingEntity' in suggestion &&
        suggestion.onboardingEntity?.entityName
        ? startCase(toLower(suggestion.onboardingEntity?.entityName))
        : `Entity`;
    case AiSuggestionKind.OnboardingIndividual:
      return 'onboardingIndividual' in suggestion
        ? formatSuggestedPersonDisplayName(suggestion.onboardingIndividual)
        : `Individual`;
    case AiSuggestionKind.OnboardingOrganization:
      return 'onboardingOrganization' in suggestion &&
        suggestion.onboardingOrganization?.organizationName
        ? startCase(
            toLower(suggestion.onboardingOrganization?.organizationName)
          )
        : `Organization`;
    case AiSuggestionKind.OnboardingTestamentaryEntity:
      return 'onboardingTestamentaryEntity' in suggestion &&
        suggestion.onboardingTestamentaryEntity?.testamentaryEntityName
        ? startCase(
            toLower(
              suggestion.onboardingTestamentaryEntity?.testamentaryEntityName
            )
          )
        : `Testamentary entity`;
    case AiSuggestionKind.DpDeathEventGrantor_1DiesFirst:
    case AiSuggestionKind.DpDeathEventGrantor_2DiesFirst:
      // TODO: https://linear.app/luminary/issue/T2-3890/[frontend]-handle-button-click-for-dp-suggestions
      return '';
    default:
      throw new UnreachableError({
        case: suggestion.kind,
        message: `Unsupported suggestionKind ${suggestion.kind}`,
      });
  }
}
