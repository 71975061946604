import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';
import { compact } from 'lodash';
import { marked } from 'marked';

import {
  extractSummaryTitle,
  preprocessMarkdown,
} from '@/pages/DocumentDetailsPage/DocumentDetailsPage.utils';
import { diagnostics } from '@/utils/diagnostics';

/**
 * Converts Markdown content to a Word document and triggers a download.
 * @param markdownContent The Markdown content to convert.
 */
export const convertMarkdownToWord = async (
  markdownContent: string
): Promise<void> => {
  try {
    const markdown = preprocessMarkdown(markdownContent);
    const htmlContent = await marked(markdown, {
      pedantic: false,
      gfm: true,
    });

    const summaryTitle = compact([
      extractSummaryTitle(htmlContent),
      'Summary',
    ]).join(' ');

    const htmlContentWithWordStructure = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><title>${summaryTitle}</title></head><body>${htmlContent}</body></html>`;

    const blob = await HTMLtoDOCX(htmlContentWithWordStructure, null, {
      orientation: 'portrait',
      title: summaryTitle,
      font: 'Calibri',
      margins: {
        top: 1000,
        right: 1400,
        bottom: 1000,
        left: 1400,
        header: 720,
        footer: 720,
        gutter: 0,
      },
      creator: 'Luminary',
      footer: false,
      header: false,
    });

    // Ensure blob is of type Blob
    const blobToSave = blob instanceof Blob ? blob : new Blob([blob]);

    // Trigger file download
    saveAs(blobToSave, `${summaryTitle}.docx`);
  } catch (error) {
    diagnostics.error(
      `Error creating Word document for document summary`,
      error as Error
    );
  }
};
