import * as Apollo from '@apollo/client';
import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import { ComponentType } from 'react';

import { Logomark, LogomarkSize } from '@/components/brand/Logomark/Logomark';
import { NOTIFICATION_THEME_BY_SEVERITY } from '@/components/notifications/constants';
import { useReportError } from '@/hooks/useReportError';

import { ClientPresentationStandaloneSlideType } from '../clientPresentation/clientPresentation.types';
import {
  ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment,
  ClientPresentationDesigner_HouseholdFragment,
} from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useUnguardedClientPresentationDesignerContext } from '../clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterSlide } from '../clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '../PresentationSlide';
import { DisclaimerFooter } from '../PresentationSlide/DisclaimerFooter';
import {
  CoverSlideDetailsQuery,
  CoverSlideDetailsQueryVariables,
  useCoverSlideDetailsQuery,
} from './graphql/CoverSlide.generated';

export interface CoverSlideProps {
  client: ClientPresentationDesigner_HouseholdFragment;
  // coverSlideDetails is optional because it's only passed through this prop in a print scenario, when we want to make sure that all
  // the data is fetched and passed as part of the one core query to ensure that everything is rendered at the time we're printing
  // otherwise, it's fetched through the cache-only query below
  coverSlideDetails?: ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

function useCachedCoverSlideDetails(
  opts: Apollo.QueryHookOptions<
    CoverSlideDetailsQuery,
    CoverSlideDetailsQueryVariables
  >
) {
  const { presentationId } = useUnguardedClientPresentationDesignerContext();
  const { reportError } = useReportError();
  const { data } = useCoverSlideDetailsQuery({
    variables: {
      presentationId: presentationId ?? '',
    },
    // we're actually fetching/refetching/updating this value upstream (in the top-level designer query and in EditCoverSlideModal
    // for any edits), so we just want to read the values here
    fetchPolicy: 'cache-first',
    onError: (error) => {
      reportError('failed to fetch cached cover slide details', error);
    },
    skip: !presentationId,
    ...opts,
  });

  if (!data) return null;
  return data.presentation as ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment;
}

export function CoverSlide({
  SlideWrapper = Box,
  coverSlideDetails,
}: CoverSlideProps) {
  const slideTitle = 'Cover slide';
  const theme = useTheme();
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  const slideId = 'slide_cover';

  const presentation = useCachedCoverSlideDetails({});

  const coverSlideTitle =
    coverSlideDetails?.coverSlideTitle ?? presentation?.coverSlideTitle ?? '';
  const coverSlideDescription =
    coverSlideDetails?.coverSlideDescription ??
    presentation?.coverSlideDescription ??
    '';

  useRegisterSlide({
    displayName: slideTitle,
    bundleId: '',
    id: slideId,
    identifier: ClientPresentationStandaloneSlideType.COVER,
    index: 0,
  });

  if (!shouldShowItem(ClientPresentationStandaloneSlideType.COVER)) {
    return null;
  }
  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        footer={
          <Box px={2}>
            <DisclaimerFooter
              variant="cover"
              contentSx={{
                color: NOTIFICATION_THEME_BY_SEVERITY['info-low'].text,
              }}
            />
          </Box>
        }
      >
        <Stack spacing={3} justifyContent="center" p={10.5} height="100%">
          <Stack direction="column" spacing={theme.spacing(0.5)}>
            <Stack
              maxHeight="15vh"
              direction="row"
              alignItems="center"
              mb="3vh !important"
            >
              <Logomark
                style={{ maxHeight: '100%' }}
                size={LogomarkSize.Wide}
              />
            </Stack>
            {coverSlideTitle && (
              <Typography variant="h1" color={theme.palette.primary.main}>
                {coverSlideTitle}
              </Typography>
            )}
            {coverSlideDescription && (
              <Typography variant="body1">{coverSlideDescription}</Typography>
            )}
          </Stack>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
