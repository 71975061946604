import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SetRequired } from 'type-fest';

import { Button } from '@/components/form/baseInputs/Button';
import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { AISuggestionsSparkleIcon } from '@/modules/aiSuggestions/components/AISuggestionsSparkleIcon';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { getSectionOptions } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { EditEntitySplitScreenModal } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export interface EditEntitySplitScreenOpenModalButtonProps
  extends Partial<ButtonWithPopoverProps> {
  // initialSection: If given one initial section, we won't show the popover button.
  initialSection?: EditEntitySection;
  // initialSectionOptions: If given initial section options, we'll show those in
  // the popover menu, otherwise, we'll show all supported sections for the entity type.
  initialSectionOptions?: EditEntitySection[];
  // navigateAfterSave: If true, navigate to the EntityDetailsPage with the
  // entityTab param set to the corresponding section that was saved.
  navigateAfterSave?: boolean;
  buttonText?: ReactNode;
  // openOnMount: If true, open the EditEntitySplitScreenModal immediately to
  // the BasicInfo section immediately on mount.
  openOnMount?: boolean;
}

export const EditEntitySplitScreenOpenModalButton = ({
  initialSection,
  initialSectionOptions,
  navigateAfterSave = false,
  buttonText = 'Edit details',
  openOnMount,
  ...buttonProps
}: EditEntitySplitScreenOpenModalButtonProps) => {
  const { householdId, primaryClients } = useHouseholdDetailsContext();
  const { entityId, entityType } = useEntityDetailsContext();

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const [selectedSection, setSelectedSection] = useState<
    EditEntitySection | undefined
  >(initialSection);

  useEffect(() => {
    if (openOnMount && !isModalOpen) {
      const section = initialSection
        ? initialSection
        : EditEntitySection.BASIC_INFORMATION;
      setSelectedSection(section);
      openModal();
    }
  }, [initialSection, isModalOpen, openModal, openOnMount]);

  const editEntitySections = useMemo(() => {
    return getSectionOptions(entityType, primaryClients);
  }, [entityType, primaryClients]);

  const handleOpenModalToSection = useCallback(
    (section: EditEntitySection) => {
      setSelectedSection(section);
      openModal();
    },
    [openModal]
  );

  return (
    <>
      <OpenModalButton
        initialSection={initialSection}
        editEntitySections={
          initialSectionOptions
            ? getSectionOptions(
                entityType,
                primaryClients,
                initialSectionOptions
              )
            : editEntitySections
        }
        openModalToSection={handleOpenModalToSection}
        disabled={!entityId || !householdId}
        buttonText={buttonText}
        variant="secondary"
        {...buttonProps}
      />
      {isModalOpen && selectedSection && entityType && (
        <EditEntitySplitScreenModal
          initialSection={selectedSection}
          entityType={entityType}
          navigateAfterSave={navigateAfterSave}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </>
  );
};

interface OpenModalButtonProps
  extends SetRequired<
    Omit<Partial<ButtonWithPopoverProps>, 'onMouseLeave'>,
    'variant'
  > {
  // If given one initial section, we won't show the popover button.
  initialSection?: EditEntitySection;
  editEntitySections: { label: string; section: EditEntitySection }[];
  openModalToSection: (section: EditEntitySection) => void;
  disabled: boolean;
  buttonText: ReactNode;
}

/**
 * If there's only one edit section, show a regular button that opens the modal
 * directly to that section. If there are multiple sections, show a button with
 * popover that allows the user to select the section to open the modal to.
 */
const OpenModalButton = ({
  initialSection,
  editEntitySections,
  openModalToSection,
  disabled,
  buttonText,
  size = 'sm',
  ...buttonProps
}: OpenModalButtonProps) => {
  if (initialSection) {
    return (
      <Button
        onClick={() => openModalToSection(initialSection)}
        startIcon={Edit02Icon}
        disabled={disabled}
        size={size}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <ButtonWithPopover
      startIcon={Edit02Icon}
      label={buttonText}
      popperVariant="menuBelow"
      size={size}
      {...buttonProps}
    >
      {editEntitySections.map(({ label, section }) => (
        <MenuItem
          key={section}
          label={label}
          iconAfter={<AISuggestionsSparkleIcon section={section} />}
          onClick={() => openModalToSection(section)}
        />
      ))}
    </ButtonWithPopover>
  );
};
