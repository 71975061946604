import { Stack, SxProps, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { CheckIcon } from '@/components/icons/CheckIcon';
import { Upload01Icon } from '@/components/icons/Upload01Icon';
import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useStartAiDispositiveProvisionWorkflowMutation } from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/graphql/TriggerAIDPSuggestions.generated';
import { UploadSingleDocumentModal } from '@/modules/aiDP/components/UploadSingleDocumentModal';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { aiDPNotificationMapper } from '@/modules/asyncJobs/AsyncJobNotifications/mappers/aiDispositiveProvision.mapper';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';

export enum TriggerDPAISuggestionsVariant {
  /**
   * Trigger the DP AI Suggestions workflow for 1 entity. This will cause one
   * notification to be added for the single entity's workflow progress.
   */
  SINGLE_ENTITY = 'SINGLE_ENTITY',
  /**
   * Trigger the DP AI Suggestions workflow for multiple entities within a
   * household. This will cause one notification to be added that tracks the
   * progress of the batch of entities.
   */
  BATCHED = 'BATCHED',
}

interface TriggerDPSuggestionsBannerProps {
  variant: TriggerDPAISuggestionsVariant;
  // If the variant is SINGLE_ENTITY, entityId is required so we know which
  // entity to start the workflow for.
  entityId?: string;
  onStartWorkflowSuccess?: (jobID: string) => void;
  sx?: SxProps;
}

export function TriggerAIDPSuggestionsBanner({
  variant,
  entityId,
  onStartWorkflowSuccess,
  sx,
}: TriggerDPSuggestionsBannerProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { householdId } = useHouseholdDetailsContext();
  const { addNotification } = useAsyncJobNotificationsContext();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [startWorkflow, { loading: startingWorkflow }] =
    useStartAiDispositiveProvisionWorkflowMutation({
      onError: (error) => {
        showFeedback(
          'Error starting the extraction process. Please try again.'
        );
        reportError(
          'error in startAIDispositiveProvisionWorkflow mutation',
          error
        );
      },
      onCompleted: (data) => {
        const newNotification = aiDPNotificationMapper(
          data.startAIDispositiveProvisionWorkflow
        );
        if (!newNotification) {
          // This shouldn't happen, since the mutation should always return an
          // async job if it succeeded. But just in case, we'll log an error.
          diagnostics.error(
            `[aiDPNotificationMapper] mapping ${JSON.stringify(data.startAIDispositiveProvisionWorkflow)} to a new notification resulted in a null value`
          );
          return;
        }
        addNotification(newNotification);
        onStartWorkflowSuccess?.(data.startAIDispositiveProvisionWorkflow?.id);
      },
    });

  const handleStartWorkflow = useCallback(async () => {
    if (
      variant === TriggerDPAISuggestionsVariant.SINGLE_ENTITY &&
      (!entityId || !householdId)
    ) {
      diagnostics.error(
        `Entity ID and Household ID are required to start DP AI workflow for variant=${variant}`
      );
      return;
    }
    if (variant === TriggerDPAISuggestionsVariant.BATCHED && !householdId) {
      diagnostics.error(
        `Household ID is required to start DP AI workflow for variant=${variant}`
      );
      return;
    }

    await startWorkflow({
      variables: {
        input: {
          householdID: householdId!, // We've already checked householdID is not empty
          entityID: entityId,
        },
      },
    });
  }, [entityId, householdId, startWorkflow, variant]);

  return (
    <>
      <UploadSingleDocumentModal
        isOpen={isUploadModalOpen}
        handleClose={() => setIsUploadModalOpen(false)}
      />
      <LuminaryAIBannerCard
        cardSx={sx}
        bannerText={
          <Stack direction="column" gap={0.25} flexGrow={1}>
            <Typography variant="label">
              Are all documents specifying dispositions uploaded?
            </Typography>
            <Typography variant="subtitle2">
              Luminary AI will extract dispositions once all relevant documents
              including trust documents and amendments have been uploaded.
            </Typography>
          </Stack>
        }
        primaryButtonProps={{
          onClick: handleStartWorkflow,
          startIcon: CheckIcon,
          size: 'sm',
          variant: 'primary',
          children: 'Run data extraction',
          sx: { width: '100%' },
          loading: startingWorkflow,
        }}
        secondaryButtonProps={{
          onClick: () => {
            setIsUploadModalOpen(true);
          },
          startIcon: Upload01Icon,
          size: 'sm',
          variant: 'secondary',
          children: 'Upload additional',
          sx: { width: '100%' },
          disabled: startingWorkflow,
        }}
        buttonContainerProps={{
          direction: 'column-reverse',
          gap: 1,
          minWidth: 180,
        }}
      />
    </>
  );
}
