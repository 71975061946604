import { applySectionBoundsToState } from '../state';
import {
  EntityDiagramActionFn,
  ReactFlowNodeMeasurementEventAction,
} from '../types';

export const reactFlowNodeMeasurementEventAction: EntityDiagramActionFn<
  ReactFlowNodeMeasurementEventAction
> = (state, _action, queueEffect) => {
  queueEffect({ type: 'recomputeSectionWidthsEffect' });

  return applySectionBoundsToState(state);
};
