import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { Copy06Icon } from '@/components/icons/Copy06Icon';
import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { COPY_ALL_EVENT } from '@/components/TextEditor/plugins/CopyAllPlugin/CopyAllPlugin';
import { getEditorElement } from '@/components/TextEditor/TextEditor.utils';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useRegenAiSummary } from '@/modules/documents/RegenAiSummaryMenuItem/hooks/useRegenAiSummary';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useCustomerTheme } from '@/styles/themes/CustomerThemeProvider';
import { COLORS } from '@/styles/tokens/colors';
import { isDevelopmentMode } from '@/utils/environmentUtils';
import { getNodes } from '@/utils/graphqlUtils';

import { convertMarkdownToWord } from '../printOnly/hooks/useMarkdownToWordDocument';
import { DocumentSummaryPDFDebugger } from './component/DocumentSummaryPDFDebugger';
import {
  getReactPDFCompatibleMarked,
  markdownToPDFBlob,
  preprocessMarkdown,
} from './DocumentDetailsPage.utils';
import { useDocumentDetailsQuery } from './hooks/useDocumentDetailsQuery';

interface DocumentDetailsPageContextMenuProps {
  documentId: string;
  documentSummaryId: string;
  startPollingDocumentSummary: (interval: number) => void;
}

export function DocumentDetailsPageContextMenu({
  documentId,
  documentSummaryId,
  startPollingDocumentSummary,
}: DocumentDetailsPageContextMenuProps) {
  const [debugHTMLContent, setDebugHTMLContent] = useState('');
  const customerTheme = useCustomerTheme();
  const trackUserEvent = useTrackUserEvent();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const { asyncRegen } = useRegenAiSummary(documentId, documentSummaryId);

  const handleCloseConfirmationModal = useCallback(
    (result: 'confirm' | 'cancel') => {
      if (result === 'confirm') {
        void asyncRegen();
        // Shorter poll interval than the default 5 seconds
        // because just summarizing the document should be faster
        startPollingDocumentSummary(1000);
      }
      closeModal();
    },
    [asyncRegen, closeModal, startPollingDocumentSummary]
  );

  const handleCopySummary = useCallback(() => {
    // We use DOM events to communicate between the context menu and the editor
    const editor = getEditorElement();
    if (editor) {
      editor.dispatchEvent(new Event(COPY_ALL_EVENT));
    } else {
      reportError(
        'Failed to copy summary to clipboard',
        new Error('Could not find editor element')
      );
      showFeedback('Failed to copy summary to clipboard');
    }
  }, [reportError, showFeedback]);

  const documentDetailsQueryProps = useDocumentDetailsQuery(documentId);

  const summaryObject = getNodes(documentDetailsQueryProps.data?.documents)[0]
    ?.documentSummary;
  const documentSummary = summaryObject?.summary || '';
  const { displayName } = useHouseholdDetailsContext();

  const handleExportToWord = useCallback(async () => {
    try {
      await convertMarkdownToWord(documentSummary);
      trackUserEvent('document summary exported', {
        documentId,
        format: 'word',
      });
    } catch (error) {
      reportError('Failed to export summary to Word document', error as Error);
      showFeedback('Failed to export summary to Word document');
    }
  }, [documentId, documentSummary, reportError, showFeedback, trackUserEvent]);

  const handleExportToPdf = useCallback(async () => {
    const { blob, summaryTitle } = await markdownToPDFBlob(
      documentSummary,
      displayName,
      customerTheme
    );
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${summaryTitle}.pdf`;
    link.click();
    trackUserEvent('document summary exported', {
      documentId,
      format: 'pdf',
    });
  }, [customerTheme, displayName, documentSummary, documentId, trackUserEvent]);

  const handleDebugPdf = useCallback(async () => {
    // Remove the added whitespace around the bullets in the markdown
    const markdown = preprocessMarkdown(documentSummary);
    // Convert the markdown to HTML
    const htmlContent = await getReactPDFCompatibleMarked()(markdown, {
      pedantic: false,
      gfm: true,
    });
    setDebugHTMLContent(htmlContent);
  }, [documentSummary]);

  return (
    <>
      {debugHTMLContent && (
        <DocumentSummaryPDFDebugger
          isOpen={true}
          onClose={() => setDebugHTMLContent('')}
          htmlDocumentSummary={debugHTMLContent}
          householdDisplayName={displayName}
          customerTheme={customerTheme}
        />
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Generate a new summary using Luminary AI"
        cancelText="Cancel"
        confirmText="Rerun"
        confirmButtonProps={{ variant: 'primary' }}
        onClose={handleCloseConfirmationModal}
      >
        <Typography variant="h6" component="p" color={COLORS.ORANGE[600]}>
          This cannot be undone, any previous edits will be lost.
        </Typography>
      </ConfirmationModal>
      <ContextMenuButton>
        <SelectItemGroupLabel label="Export summary as" />
        <MenuItem label="Microsoft Word" onClick={handleExportToWord} />
        {isDevelopmentMode() && (
          <MenuItem label="Debug PDF" onClick={handleDebugPdf} />
        )}
        <MenuItem
          label="PDF document"
          onClick={handleExportToPdf}
          muiMenuItemProps={{
            divider: true,
          }}
        />
        <MenuItem
          label="Copy summary to clipboard"
          icon={<Copy06Icon size={20} />}
          onClick={handleCopySummary}
        />
        <MenuItem
          label="Re-generate AI summary"
          icon={<Stars01Icon size={18} color={COLORS.TEAL[600]} />}
          onClick={openModal}
        />
      </ContextMenuButton>
    </>
  );
}
