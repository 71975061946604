import { Box, BoxProps, Skeleton, Stack, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { FormAwareEditableHeader } from '@/components/form/formAwareInputs/FormAwareEditableHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

import { TenantPresentationConfigurationProvider } from '../../context/TenantPresentationConfiguration.provider';
import { useClientPresentationDesignerV2Context } from './ClientPresentationDesignerV2.context';
import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import { isSinglePageBundle } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { CoverSlide } from './components/slides/CoverSlide/CoverSlide';
import { CustomSlideBundle } from './components/slides/CustomSlide/CustomSlide';
import { EstateWaterfallBeneficiarySlide } from './components/slides/estateWaterfall/Beneficiaries/EstateWaterfallBeneficiarySlide';
import { EstateWaterfallDiagramSlide } from './components/slides/estateWaterfall/Diagram/EstateWaterfallDiagramSlide';
import { EstateWaterfallSummarySlide } from './components/slides/estateWaterfall/Summary/EstateWaterfallSummarySlide';
import { EstateWaterfallTaxOverviewSlide } from './components/slides/estateWaterfall/TaxOverview/EstateWaterfallTaxOverviewSlide';
import { ClientPresentationV2Bundle } from './types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from './types/ClientPresentationV2.PresentationPageType';

export const HEADER_HEIGHT = '73px';

export function ClientPresentationDesignerV2Header() {
  const { navigate } = useNavigateToRoute();
  const { householdId } = useClientPresentationDesignerV2Context();
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [title] = useWatch({ control, name: ['title'] });
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: `1px solid ${COLORS.ORANGE[100]}`,
        px: 3,
        py: 2,
      }}
      height={HEADER_HEIGHT}
    >
      {title === null ? (
        <Skeleton height="40px" width="400px" />
      ) : (
        <FormAwareEditableHeader<ClientPresentationV2Shape>
          fieldName="title"
          control={control}
        />
      )}
      <Stack direction="row" spacing={1}>
        <Button
          variant="secondary"
          size="sm"
          onClick={() =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS, {
              householdId,
            })
          }
        >
          Close
        </Button>
        <ContextMenuButton>
          <MenuItem
            label="Download PDF"
            onClick={() => {
              throw new Error('Function not implemented.');
            }}
          />
          <MenuItem
            label="Delete presentation"
            onClick={() => {
              throw new Error('Function not implemented.');
            }}
          />
        </ContextMenuButton>
      </Stack>
    </Stack>
  );
}

function PresentationSlideWrapper({ sx = {}, ...otherProps }: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `solid 1px ${COLORS.NEUTRAL_GRAY[100]}`,
        backgroundColor: 'white',
        boxShadow: theme.palette.shadows.lg,
        maxWidth: '80%',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

export function ClientPresentationDesignerV2SlideDisplay() {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [bundles, selectedItemId, presentationConfiguration, title] = useWatch({
    control,
    name: ['bundles', 'selectedItemId', 'presentationConfiguration', 'title'],
  });

  const { bundle, page } = useMemo<{
    bundle: ClientPresentationV2Bundle | undefined;
    page?: ClientPresentationV2Page | undefined;
  }>(() => {
    let bundle: ClientPresentationV2Bundle | undefined;
    let page: ClientPresentationV2Page | undefined;

    bundles.forEach((innerBundle) => {
      if (page || bundle) {
        return;
      }

      if (
        isSinglePageBundle(innerBundle.type) &&
        selectedItemId === innerBundle.id
      ) {
        bundle = innerBundle;
      } else {
        const innerPage = innerBundle.pages.find(
          ({ id }) => id === selectedItemId
        );
        if (innerPage) {
          bundle = innerBundle;
          page = innerPage;
        }
      }
    });

    return { bundle, page };
  }, [bundles, selectedItemId]);

  if (!bundle) {
    return null;
  }

  return (
    <Box flex={1} sx={{ overflowY: 'auto' }}>
      <Stack
        spacing={3}
        alignItems="center"
        justifyContent="flex-start"
        position="relative"
        sx={{
          py: 3,
        }}
      >
        <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
          <CustomerThemeProvider>
            <TenantPresentationConfigurationProvider>
              {(() => {
                // using an if/else chain here because it's crossing between page & bundle
                if (page?.type === PresentationPageKind.WaterfallSummaryPage) {
                  return (
                    <EstateWaterfallSummarySlide
                      bundle={bundle}
                      page={page}
                      SlideWrapper={PresentationSlideWrapper}
                    />
                  );
                } else if (
                  page?.type === PresentationPageKind.WaterfallDiagramPage
                ) {
                  return (
                    <EstateWaterfallDiagramSlide
                      bundle={bundle}
                      page={page}
                      SlideWrapper={PresentationSlideWrapper}
                    />
                  );
                } else if (
                  page?.type === PresentationPageKind.WaterfallTaxOverviewPage
                ) {
                  return (
                    <EstateWaterfallTaxOverviewSlide
                      bundle={bundle}
                      page={page}
                      SlideWrapper={PresentationSlideWrapper}
                      bundleId={bundle.id}
                      bundleIndex={0} // TODO
                      slideId={page.id}
                      federalEstateTaxPercent={new Decimal(0.4)} // TODO
                    />
                  );
                } else if (
                  page?.type === PresentationPageKind.WaterfallBeneficiariesPage
                ) {
                  return (
                    <EstateWaterfallBeneficiarySlide
                      bundle={bundle}
                      page={page}
                      SlideWrapper={PresentationSlideWrapper}
                    />
                  );
                }

                switch (bundle?.type) {
                  case PresentationBundleKind.CoverSlideBundle:
                    return (
                      <CoverSlide
                        bundle={bundle}
                        SlideWrapper={PresentationSlideWrapper}
                        presentationConfiguration={presentationConfiguration}
                        presentationName={title ?? ''}
                      />
                    );
                  case PresentationBundleKind.CustomPageBundle:
                    return (
                      <CustomSlideBundle
                        bundle={bundle}
                        SlideWrapper={PresentationSlideWrapper}
                      />
                    );
                  default: {
                    return null;
                  }
                }
              })()}
            </TenantPresentationConfigurationProvider>
          </CustomerThemeProvider>
        </InteractionParadigmContext.Provider>
      </Stack>
    </Box>
  );
}
