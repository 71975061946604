import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  TrustAdvisorLevel,
  TrusteeDutyKind,
  TrusteeLevel,
} from '@/types/schema';

/** TRUSTEE DUTIES */
export const TRUSTEE_DUTY_DISPLAY_NAMES: Record<TrusteeDutyKind, string> = {
  ADMINISTRATION: 'Administration',
  DISTRIBUTION: 'Distribution',
  INVESTMENT: 'Investment',
  SPECIAL: 'Other',
};

export const TRUSTEE_DUTY_ABBREVIATIONS: Partial<
  Record<TrusteeDutyKind, string>
> = {
  ADMINISTRATION: 'Admin.',
  DISTRIBUTION: 'Dist.',
  INVESTMENT: 'Inv.',
};

export const TRUSTEE_DUTY_KIND_ITEMS: SelectInputOption<TrusteeDutyKind>[] = [
  TrusteeDutyKind.Investment,
  TrusteeDutyKind.Administration,
  TrusteeDutyKind.Distribution,
  TrusteeDutyKind.Special,
].map((value) => ({
  display: TRUSTEE_DUTY_DISPLAY_NAMES[value],
  abbreviation: TRUSTEE_DUTY_ABBREVIATIONS[value],
  value,
}));

/** TRUSTEE LEVELS */
// while successor trustees and "normal" trustees are the same type in the schema, and therefore they both
// can have defined "levels", we don't actually collect level information for primary trustee, and so these
// level display names are specific to successor trustees
export const SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES: Record<
  TrusteeLevel,
  string
> = {
  PRIMARY: 'First successor trustees',
  SECONDARY: 'Second successor trustees',
  TERTIARY: 'Third successor trustees',
  OTHER: 'Other successor trustees',
};

export const SUCCESSOR_TRUSTEE_LEVEL_GROUP_DISPLAY_NAMES_SHORT: Record<
  TrusteeLevel,
  string
> = {
  PRIMARY: 'First successors',
  SECONDARY: 'Secondary successors',
  TERTIARY: 'Tertiary successors',
  OTHER: 'Other successors',
};

/** TRUST ADVISOR LEVELS */
export const TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES: Record<
  TrustAdvisorLevel,
  string
> = {
  PRIMARY: 'Primary trust advisors',
  SECONDARY: 'Secondary trust advisors',
  TERTIARY: 'Tertiary trust advisors',
  OTHER: 'Other trust advisors',
};

export const TRUST_ADVISOR_LEVEL_GROUP_DISPLAY_NAMES_SHORT: Record<
  TrustAdvisorLevel,
  string
> = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary',
  OTHER: 'Other',
};
