import { Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import { SaleLoanIllustration_EstateWaterfallFragment } from '../graphql/SaleLoanIllustration.generated';
import { SaleLoanIllustrationGrowthSchedule } from './SaleLoanIllustrationGrowthSchedule';
import { SaleLoanIllustrationPaymentSchedule } from './SaleLoanIllustrationPaymentSchedule';

interface SaleLoanIllustrationDetailsProps {
  waterfall: SaleLoanIllustration_EstateWaterfallFragment | null;
}

export function SaleLoanIllustrationDetails({
  waterfall,
}: SaleLoanIllustrationDetailsProps) {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const scheduleDisplayType = useWatch({
    control,
    name: 'scheduleDisplayType',
  });

  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Stack spacing={2}>
        <FormAwareButtonGroup
          label="Schedule display type"
          hideLabel
          control={control}
          options={[
            { value: 'paymentSchedule', display: 'Payment schedule' },
            {
              value: 'projectedGrowth',
              display: 'Projected growth',
            },
          ]}
          fieldName={
            'scheduleDisplayType' as const satisfies HypotheticalSaleLoanFormPaths
          }
        />
        {scheduleDisplayType === 'paymentSchedule' && (
          <SaleLoanIllustrationPaymentSchedule waterfall={waterfall} />
        )}
        {scheduleDisplayType === 'projectedGrowth' && (
          <SaleLoanIllustrationGrowthSchedule waterfall={waterfall} />
        )}
      </Stack>
    </Card>
  );
}
