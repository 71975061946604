import { Box, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
} from 'react-beautiful-dnd';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { useFormFieldsDisabled } from '@/components/form/context/formFieldsDisabled.context';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Draghandle } from '@/components/lists/DragAndDropList/Draghandle';
import { StrictModeDroppable } from '@/components/lists/DragAndDropList/StrictModeDroppable';
import { useFormContext } from '@/components/react-hook-form';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { SimulateDispositiveProvisionsQuery } from '../../graphql/SimulateDispositiveProvisions.generated';
import {
  defaultRecipient,
  DISPOSITIVE_PROVISIONS_FORM_NAMESPACE,
} from '../DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
} from '../DispositiveProvisionsForm.types';
import {
  useDispositionScheme,
  useIsImmutableDeathOrder,
} from '../hooks/utilityHooks';
import { DispositiveProvisionsRecipientsEmptyState } from './DispositiveProvisionsRecipientsEmptyState';
import { DraggableListItemRecipient } from './DraggableListItemRecipient';
import { InitialDispositionSchemeSelector } from './InitialDispositionSchemeSelector';

interface DispositiveProvisionsFormRecipientsProps {
  onOpenSchemeSelectionModal: (() => void) | null;
  simulationResults: SimulateDispositiveProvisionsQuery | undefined;
  /**
   * If true, always expand entries by default (eg: on load)
   */
  defaultExpandAll?: boolean;
}

export function DispositiveProvisionsRecipients({
  onOpenSchemeSelectionModal,
  simulationResults,
  defaultExpandAll = false,
}: DispositiveProvisionsFormRecipientsProps) {
  const isImmutableDeathOrder = useIsImmutableDeathOrder();
  const { isTwoClientHousehold } = useDispositiveProvisionsContext();
  const [forceExpandId, setForceExpandId] = useState<string | null>(null);
  const { control, setValue } =
    useFormContext<DispositiveProvisionsFormShape>();
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  // we use this to know when to force expand the most recently added recipient; there's probably a cleaner way to do this
  // but i can't think of it right now
  const hasUnhandledAddition = useRef(false);

  const {
    append,
    remove,
    move,
    fields: recipients,
  } = useFieldArray({
    control,
    name: `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` as const satisfies DispositiveProvisionsFormPaths,
    keyName: '_id',
  });

  useEffect(() => {
    if (!hasUnhandledAddition.current) return;
    const mostRecentlyAddedRecipient = recipients[recipients.length - 1];
    if (mostRecentlyAddedRecipient) {
      setForceExpandId(mostRecentlyAddedRecipient._id);
    }
    hasUnhandledAddition.current = false;
  }, [recipients, recipients.length]);

  const handleAddRecipient = useCallback(() => {
    append(defaultRecipient, { shouldFocus: true });
    hasUnhandledAddition.current = true;
  }, [append]);

  const handleDragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    ({ source, destination }) => {
      if (destination) {
        move(source.index, destination.index);
      }
    },
    [move]
  );

  const hasBeenReviewed = useWatch({
    control,
    name: `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}._hasBeenReviewed` as const satisfies DispositiveProvisionsFormPaths,
  });

  const dispositionScheme = useDispositionScheme();

  const relevantSimulation = useMemo(() => {
    if (dispositionScheme === DispositionScheme.UPON_FIRST_DEATH) {
      return simulationResults?.simulateDispositiveProvisions.firstDeath ?? [];
    }

    return simulationResults?.simulateDispositiveProvisions.secondDeath ?? [];
  }, [
    dispositionScheme,
    simulationResults?.simulateDispositiveProvisions.firstDeath,
    simulationResults?.simulateDispositiveProvisions.secondDeath,
  ]);

  function setDispositionSchema(selection: DispositionScheme) {
    setValue('dispositiveProvisions.dispositionScheme', selection);
    setValue('dispositiveProvisions._hasBeenReviewed', true);
  }

  function handleInitialSchemeSelection(selection: DispositionScheme) {
    setDispositionSchema(selection);
    if (selection !== DispositionScheme.NONE) {
      handleAddRecipient();
    }
  }

  function handleAddEmptyStateRecipient() {
    if (isImmutableDeathOrder) {
      // make sure we're not stuck in DispositionScheme.NONE; we clearly want to no longer be in that mode
      // if we're adding a recipient
      setDispositionSchema(dispositionScheme);
      return handleAddRecipient();
    }

    // with a two-client household, we need the user to choose the disposition order before adding recipients
    if (isTwoClientHousehold) {
      onOpenSchemeSelectionModal?.();
    } else {
      // with a single-client household, we can just set the disposition scheme directly because it's the only option
      setDispositionSchema(DispositionScheme.UPON_FIRST_DEATH);
    }

    handleAddRecipient();
  }

  // if this is the user's first time setting up dispositive provisions for this client/entity/testamentary entity, we want to
  // give them a more robust and descriptive empty state
  if (dispositionScheme === DispositionScheme.NONE && !hasBeenReviewed) {
    return (
      <InitialDispositionSchemeSelector
        onSchemeSelection={handleInitialSchemeSelection}
      />
    );
  }

  if (isEmpty(recipients) || dispositionScheme === DispositionScheme.NONE) {
    return (
      <Box pt={2}>
        <DispositiveProvisionsRecipientsEmptyState
          onAddRecipients={handleAddEmptyStateRecipient}
        />
      </Box>
    );
  }

  return (
    <Stack spacing={2}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId="recipients" direction="vertical">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              component="ul"
              sx={{ p: 0, m: 0, listStyleType: 'none' }}
            >
              {recipients.map((r, i) => {
                return (
                  <Draggable key={r._id} draggableId={r._id} index={i}>
                    {(provided) => (
                      <Box
                        component="li"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        sx={{ listStyleType: 'none', p: 0, m: 0, mb: 2 }}
                      >
                        <DraggableListItemRecipient
                          key={r._id}
                          onRemove={() => remove(i)}
                          defaultExpanded={
                            forceExpandId === r._id || defaultExpandAll
                          }
                          simulatedValue={
                            relevantSimulation[i]?.transferAmount ?? null
                          }
                          index={i}
                          Draghandle={
                            <Draghandle {...provided.dragHandleProps} />
                          }
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      <Button
        size="md"
        fullWidth
        variant="secondary"
        disabled={disabledFromContext}
        startIcon={PlusIcon}
        onClick={() => handleAddRecipient()}
      >
        Add additional recipient
      </Button>
    </Stack>
  );
}
