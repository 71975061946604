import { css } from '@emotion/css';
import { Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { Fullscreen } from '@/components/Fullscreen/Fullscreen';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';
import { EstateWaterfall } from '@/modules/estateWaterfall';
import {
  EstateWaterfallSubPanelButtons,
  SubPanelButtonType,
} from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons';
import { EstateWaterfallAssumptionsUpdater } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater';
import { GROWTH_PROFILES_SENTINEL } from '@/modules/estateWaterfall/components/GrowthProfilesButton';
import { HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL } from '@/modules/estateWaterfall/components/HypotheticalTransfersButton';
import { useEstateWaterfallContext } from '@/modules/estateWaterfall/contexts/estateWaterfall.context';
import { EstateWaterfallProvider } from '@/modules/estateWaterfall/contexts/EstateWaterfall.provider';
import { setMostRecentlyViewedWaterfallId } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { useQueryWaterfall } from '@/modules/estateWaterfall/hooks';
import { UnreachableError } from '@/utils/errors';

const styles = {
  fullHeight: css({ height: '100%' }),
};

const MIN_HEIGHT = 800;

interface ClientDetailsEstateWaterfallDiagramPageInnerProps {
  householdId: string;
  waterfallId: string;
}

function ClientDetailsEstateWaterfallDiagramPageInner({
  householdId,
  waterfallId,
}: ClientDetailsEstateWaterfallDiagramPageInnerProps) {
  const { dispatch, getState } = useEstateWaterfallContext();

  const onClickSubPanelButton = useCallback(
    (type: SubPanelButtonType) => {
      let nodeId: string;
      switch (type) {
        case SubPanelButtonType.HypotheticalTransfers:
          nodeId = HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL;
          break;
        case SubPanelButtonType.GrowthProfiles:
          nodeId = GROWTH_PROFILES_SENTINEL;
          break;
        default:
          throw new UnreachableError({
            case: type,
            message: `Invalid sub panel opened: ${type}`,
          });
      }
      if (nodeId === getState().summaryNodeId) {
        dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
      } else {
        dispatch({
          type: 'OPEN_SUMMARY_PANEL',
          nodeId,
        });
      }
    },
    [dispatch, getState]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Typography variant="h1" component="h2">
          Diagram
        </Typography>

        <EstateWaterfallSubPanelButtons onClick={onClickSubPanelButton} />
      </Stack>
      <EstateWaterfallAssumptionsUpdater waterfallId={waterfallId}>
        <Stack className={styles.fullHeight} minHeight={MIN_HEIGHT}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <EstateWaterfall
                householdId={householdId}
                waterfallId={waterfallId}
                height={Math.max(MIN_HEIGHT, height)}
                width={width}
                // Key on waterfallId so all the state is reset when the waterfallId changes
                key={waterfallId}
              />
            )}
          </AutoSizer>
        </Stack>
      </EstateWaterfallAssumptionsUpdater>
    </>
  );
}

export function ClientDetailsEstateWaterfallDiagramPage() {
  const householdId = useRequiredParam('householdId');
  const waterfallId = useRequiredParam('waterfallId');

  useEffect(() => {
    setMostRecentlyViewedWaterfallId(waterfallId, householdId);
  }, [householdId, waterfallId]);

  const {
    isInitialLoading,
    isEmptyWaterfall,
    isUpdatingWaterfall,
    waterfall,
    primaryClients,
    isFilteredWaterfall,
    visibleNodeIds,
    hiddenNodeIds,
    initWaterfallNodeIds,
  } = useQueryWaterfall({ waterfallId });

  return (
    <Fullscreen name="waterfallDiagram">
      <WaterfallRouteProvider waterfallId={waterfallId}>
        <LoadingOverlay
          open={isInitialLoading}
          updatingText="Loading waterfall..."
          data-waterfallloading={isInitialLoading.toString()}
        />
        <LoadingOverlay
          /** UX optimization: we don't need to show any UI if there is no data */
          open={isUpdatingWaterfall && !isEmptyWaterfall}
          updatingText="Syncing with latest waterfall..."
        />
        <FlowChartProvider
          /*
           * This key is necessary otherwise the flow chart may use an existing node layout when switching waterfalls
           */
          key={waterfallId}
        >
          {!isInitialLoading && waterfall && primaryClients && (
            <EstateWaterfallProvider
              waterfall={waterfall}
              primaryClients={primaryClients}
              isFilteredWaterfall={isFilteredWaterfall}
              isEmptyWaterfall={isEmptyWaterfall}
              visibleNodeIds={visibleNodeIds}
              hiddenNodeIds={hiddenNodeIds}
              initWaterfallNodeIds={initWaterfallNodeIds}
              presentationMode={false}
            >
              <ClientDetailsEstateWaterfallDiagramPageInner
                waterfallId={waterfallId}
                householdId={householdId}
              />
            </EstateWaterfallProvider>
          )}
        </FlowChartProvider>
        <Outlet />
      </WaterfallRouteProvider>
    </Fullscreen>
  );
}
