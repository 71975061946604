import { useWatch } from 'react-hook-form';

import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFormContext } from '@/components/react-hook-form';

import { ClientPresentationV2Shape } from '../../ClientPresentationDesignerV2.types';

export interface ConfirmDeleteBundleProps {
  bundleId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmDeleteBundle({
  bundleId,
  onConfirm,
  onCancel,
}: ConfirmDeleteBundleProps) {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [bundles] = useWatch({ control, name: ['bundles'] });
  const bundle = bundles.find((b) => b.id === bundleId);

  const bundleName = bundle?.displayName
    ? `"${bundle?.displayName}"`
    : 'Bundle';

  return (
    <ConfirmationModal
      isOpen
      heading={`Delete ${bundleName}`}
      confirmButtonProps={{
        onClick: onConfirm,
        variant: 'destructive-prominent',
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      confirmText="Delete"
      onClose={onCancel}
    >
      Are you sure you want to delete this bundle?
    </ConfirmationModal>
  );
}
