import { first, uniqueId } from 'lodash';

import {
  AugmentedCreatePresentationBundleInput,
  PresentationBundleKind,
  PresentationPageKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import { BUNDLE_TO_DISPLAY_NAME } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { isSinglePageBundle } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import {
  ClientPresentationDesignerV2_EstateWaterfallFragment,
  ClientPresentationDesignerV2_EstateWaterfallVizFragment,
  ClientPresentationDesignerV2_PresentationBundleFragment,
  ClientPresentationDesignerV2_PresentationPageFragment,
  ClientPresentationV2_ClientPresentationV2Fragment,
} from './graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Bundle } from './types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from './types/ClientPresentationV2.PresentationPageType';

export const NEW_BUNDLE_ID_SENTINEL = 'NEW_BUNDLE' as const;
export const NEW_PAGE_ID_SENTINEL = 'NEW_PAGE' as const;

export function getNewBundleId(type: PresentationBundleKind): string {
  return `${NEW_BUNDLE_ID_SENTINEL}-${type}-${uniqueId()}`;
}

export function getNewPageId(type: PresentationPageKind): string {
  return `${NEW_PAGE_ID_SENTINEL}-${type}-${uniqueId()}`;
}

export function isNewBundle(bundle: ClientPresentationV2Bundle): boolean {
  return bundle.id.startsWith(NEW_BUNDLE_ID_SENTINEL);
}

export function isNewPage(page: ClientPresentationV2Page): boolean {
  return page.id.startsWith(NEW_PAGE_ID_SENTINEL);
}

export function mapClientPresentationV2ToForm(
  presentation: ClientPresentationV2_ClientPresentationV2Fragment
): ClientPresentationV2Shape {
  const firstBundle = first(getNodes(presentation.bundles));
  let selectedItemId = '';
  if (!firstBundle) {
    // noop
  } else if (isSinglePageBundle(firstBundle.kind)) {
    selectedItemId = firstBundle.id;
  } else {
    selectedItemId = first(getNodes(firstBundle.pages))?.id || firstBundle.id;
  }

  return {
    id: presentation.id,
    title: presentation.name,
    bundles: getNodes(presentation.bundles).map(mapBundleToForm),
    presentationConfiguration: {
      showPageNumbers: presentation.showPageNumbers,
      showPageNumbersOnCoverSlide: presentation.showPageNumbersOnCoverSlide,
      pageNumberToStartFrom: presentation.pageNumberToStartFrom,
      showLegalDisclaimer: presentation.showLegalDisclaimer,
    },
    selectedItemId,
  };
}

function mapBundleToForm(
  bundle: ClientPresentationDesignerV2_PresentationBundleFragment
): ClientPresentationV2Bundle {
  const {
    coverPageConfiguration,
    waterfallBundleConfiguration,
    kind: type,
    id,
  } = bundle;
  const pages = getNodes(bundle.pages).map(mapPageToForm);

  const output: ClientPresentationV2Bundle = {
    id,
    type,
    displayName: BUNDLE_TO_DISPLAY_NAME[type],
    pages,
  };

  switch (type) {
    case PresentationBundleKind.CoverSlideBundle: {
      if (!coverPageConfiguration) {
        break;
      }
      const { customText } = coverPageConfiguration;
      output.coverSlideBundleConfiguration = {
        customText: customText ?? '',
      };
      break;
    }
    case PresentationBundleKind.WaterfallOverviewBundle: {
      if (!waterfallBundleConfiguration) {
        break;
      }
      const {
        includeAssumptionsPage,
        includeBeneficiaryPage,
        includeDiagramPage,
        includeSummaryPage,
        includeTaxOverviewPage,
        firstDeathYear,
        secondDeathYear,
        growthProfile,
        estateWaterfall,
        displayName: configDisplayName,
      } = waterfallBundleConfiguration;

      if (configDisplayName) {
        output.displayName = configDisplayName;
      }

      output.waterfallOverviewBundleConfiguration = {
        includeAssumptionsPage,
        includeBeneficiaryPage,
        includeDiagramPage,
        includeSummaryPage,
        includeTaxOverviewPage,
        firstDeathYear: firstDeathYear ?? new Date().getFullYear(),
        secondDeathYear,
        growthProfileId: growthProfile?.id,
        estateWaterfallId: estateWaterfall?.id,
      };
      break;
    }
    // no configuration object for these bundles
    case PresentationBundleKind.TableOfContentsBundle:
    case PresentationBundleKind.CustomPageBundle: {
      break;
    }
    // TODO: incomplete bundle types
    case PresentationBundleKind.BalanceSheetBundle:
    case PresentationBundleKind.EntitiesOverviewBundle:
    case PresentationBundleKind.EntitySummaryBundle:
    case PresentationBundleKind.ProfessionalTeamBundle:
      break;
    default: {
      throw new UnreachableError({
        case: type,
        message: 'Unhandled bundle type',
      });
    }
  }

  return output;
}

function mapPageToForm(
  page: ClientPresentationDesignerV2_PresentationPageFragment
): ClientPresentationV2Page {
  const output: ClientPresentationV2Page = {
    id: page.id,
    type: page.kind,
  };
  return output;
}

export function mapBundleToUpdateInput(
  bundle: ClientPresentationV2Bundle,
  householdID: string,
  index: number
): AugmentedCreatePresentationBundleInput {
  const { type: kind } = bundle;
  const output: AugmentedCreatePresentationBundleInput = {
    create: {
      kind,
      householdID,
      index,
    },
    withPages: bundle.pages.map((page, index) => ({
      create: {
        kind: page.type,
        householdID,
        index,
      },
    })),
  };

  switch (kind) {
    case PresentationBundleKind.CoverSlideBundle: {
      if (!bundle.coverSlideBundleConfiguration) {
        break;
      }
      output.create.coverPageConfigurationID;
      // TODO - i think BE needs to add the cover slide bundle configuration to the input type
      break;
    }
    case PresentationBundleKind.WaterfallOverviewBundle: {
      if (!bundle.waterfallOverviewBundleConfiguration) {
        break;
      }

      const {
        includeAssumptionsPage,
        includeBeneficiaryPage,
        includeDiagramPage,
        includeSummaryPage,
        includeTaxOverviewPage,
        firstDeathYear,
        secondDeathYear,
        growthProfileId,
        estateWaterfallId,
      } = bundle.waterfallOverviewBundleConfiguration;

      output.withWaterfallBundleConfiguration = {
        create: {
          displayName: bundle.displayName,
          firstDeathYear,
          secondDeathYear,
          growthProfileID: growthProfileId,
          estateWaterfallID: estateWaterfallId,
          includeAssumptionsPage,
          includeBeneficiaryPage,
          includeDiagramPage,
          includeSummaryPage,
          includeTaxOverviewPage,
          householdID,
        },
      };
      break;
    }
    // no configuration object for these bundles
    case PresentationBundleKind.TableOfContentsBundle:
    case PresentationBundleKind.CustomPageBundle: {
      break;
    }
    // TODO: incomplete bundle types
    case PresentationBundleKind.BalanceSheetBundle:
    case PresentationBundleKind.EntitiesOverviewBundle:
    case PresentationBundleKind.EntitySummaryBundle:
    case PresentationBundleKind.ProfessionalTeamBundle:
      break;
    default: {
      throw new UnreachableError({
        case: kind,
        message: 'Unhandled bundle type',
      });
    }
  }

  return output;
}

export function getEstateWaterfallWithProjections(
  bundle: ClientPresentationDesignerV2_PresentationBundleFragment,
  estateWaterfallViz?: ClientPresentationDesignerV2_EstateWaterfallVizFragment
): ClientPresentationDesignerV2_EstateWaterfallFragment | null | undefined {
  const { estateWaterfall, estateWaterfallViz: internalEstateWaterfallViz } =
    bundle.waterfallBundleConfiguration || {};

  estateWaterfallViz = estateWaterfallViz || internalEstateWaterfallViz;

  if (!estateWaterfall) {
    return null;
  }

  const {
    firstGrantorDeathYear: estateWaterfallFirstGrantorDeathYear,
    secondGrantorDeathYear: estateWaterfallSecondGrantorDeathYear,
    growthProfile: estateWaterfallGrowthProfile,
  } = estateWaterfall;

  let outputEstateWaterfall = estateWaterfall;

  const {
    firstGrantorDeathYear: materializedFirstGrantorDeathYear,
    secondGrantorDeathYear: materializedSecondGrantorDeathYear,
    selectedGrowthProfile: materializedGrowthProfile,
  } = estateWaterfallViz?.materializedInputParams || {};

  if (estateWaterfallViz) {
    // TODO -- use bundle death years if a WIP `useWaterfallAssumptions` flag is false
    outputEstateWaterfall = {
      ...estateWaterfall,
      visualizationWithProjections: estateWaterfallViz,
      firstGrantorDeathYear:
        materializedFirstGrantorDeathYear ||
        estateWaterfallFirstGrantorDeathYear,
      secondGrantorDeathYear:
        materializedSecondGrantorDeathYear ||
        estateWaterfallSecondGrantorDeathYear,
      growthProfile: materializedGrowthProfile || estateWaterfallGrowthProfile,
    };
  }

  return outputEstateWaterfall;
}
