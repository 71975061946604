import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { AssetFullScreenModal } from '@/modules/assetValuation/AssetFullScreenModal/AssetFullScreenModal';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { getTrustDetailsTabs } from '@/modules/trusts/TrustDetails/TrustDetails.utils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { EditSubstitutionThresholdModal } from '../../gratTrusts/EditSubstitutionThresholdModal/EditSubstitutionThresholdModal';
import { EntityCardProps } from '../entityDetailPageUtils';
import { EntityDetailsData } from '../entityDetails.types';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
  getTermLabelItem,
} from '../trusts/trustCardUtils';

interface GetFormattedSubstitutionThresholdOpts {
  upsideThresholdPercent?: Maybe<Decimal>;
  upsideThresholdValue?: Maybe<Decimal>;
  downsideThresholdPercent?: Maybe<Decimal>;
}

export function getFormattedSubstitutionThreshold(
  opts: GetFormattedSubstitutionThresholdOpts
): string {
  const formattedDownsideThreshold =
    opts.downsideThresholdPercent &&
    !opts.downsideThresholdPercent.equals(new Decimal(0))
      ? `-${formatPercent(opts.downsideThresholdPercent, 0)}%`
      : null;

  const formattedUpsideThreshold = (() => {
    if (
      opts.upsideThresholdValue &&
      !opts.upsideThresholdValue.equals(new Decimal(0))
    ) {
      return opts.upsideThresholdValue
        ? formatCurrency(opts.upsideThresholdValue)
        : null;
    }

    return opts.upsideThresholdPercent &&
      !opts.upsideThresholdPercent.equals(new Decimal(0))
      ? `+${formatPercent(opts.upsideThresholdPercent, 0)}%`
      : null;
  })();

  const formattedThresholds = _.compact([
    formattedDownsideThreshold,
    formattedUpsideThreshold,
  ]).join(' or ');

  return formattedThresholds || 'None set';
}

interface GratTrustDetailsContext {
  setInitialFundingValueModalOpen: (open: boolean) => void;
  setSubstitutionThresholdModalOpen: (open: boolean) => void;
}

const GratTrustDetailsContext = createContext<GratTrustDetailsContext>({
  setInitialFundingValueModalOpen: () => null,
  setSubstitutionThresholdModalOpen: () => null,
});

export function GRATTrustDetailsProvider({
  entityId,
  householdId,
  entitySubtypeId,
  children,
}: PropsWithChildren<TrustSummaryData>) {
  const [initialFundingValueModalOpen, setInitialFundingValueModalOpen] =
    useState(false);
  const [substitutionThresholdModalOpen, setSubstitutionThresholdModalOpen] =
    useState(false);

  return (
    <GratTrustDetailsContext.Provider
      value={{
        setInitialFundingValueModalOpen,
        setSubstitutionThresholdModalOpen,
      }}
    >
      <AssetFullScreenModal
        subtypeId={entitySubtypeId}
        entityId={entityId}
        householdId={householdId}
        headerText={'Initial funding revaluation'}
        isOpen={initialFundingValueModalOpen}
        submitCopy={'Update initial funding value'}
        onClose={() => setInitialFundingValueModalOpen(false)}
        assetsSubformType="initialFundingRevaluation"
      />
      <EditSubstitutionThresholdModal
        householdId={householdId}
        entityId={entityId}
        isOpen={substitutionThresholdModalOpen}
        onClose={() => setSubstitutionThresholdModalOpen(false)}
      />
      {children}
    </GratTrustDetailsContext.Provider>
  );
}

function InitialFundingButton() {
  const { setInitialFundingValueModalOpen } = useContext(
    GratTrustDetailsContext
  );
  return (
    <EditButton
      onClick={() => setInitialFundingValueModalOpen(true)}
      aria-label={`Edit initial funding value`}
    />
  );
}

function SubstitutionThresholdButton() {
  const { setSubstitutionThresholdModalOpen } = useContext(
    GratTrustDetailsContext
  );
  return (
    <EditButton
      variant="transparent"
      onClick={() => setSubstitutionThresholdModalOpen(true)}
      aria-label={`Edit substitution thresholds`}
    />
  );
}

export const SUBSTITUTION_THRESHOLD_LABEL = 'Substitution threshold';

interface GetGRATTrustSummaryPropertiesOptions {
  variant: 'print' | 'interactive';
}

export function getGRATTrustSummaryProperties(
  cardProps: EntityDetailsData,
  options: GetGRATTrustSummaryPropertiesOptions = { variant: 'interactive' }
): TrustSummaryData {
  const { entity } = cardProps;
  const isInteractive = options.variant === 'interactive';
  if (entity.subtype.__typename !== 'GRATTrust') {
    throw new Error('Invalid trust type');
  }

  const valuations = getNodes(entity.subtype.designerAccount?.valuations);
  const shouldShowInitialFundingValueButton =
    isInteractive &&
    valuations.length > 0 &&
    valuations[0]?.id === entity.subtype.designerAccount?.initialValuation?.id;
  const initialValuationValue =
    entity.subtype.designerAccount?.initialValuation?.valuationValue;
  const grantorNames = entity.subtype.GRATTrustGrantor
    ? [getGrantorNameFromFragment(entity.subtype.GRATTrustGrantor)]
    : [];

  const termDurationYears = entity.subtype.termDurationYears;

  const trustDetailsItems = [
    getTermLabelItem({
      effectiveDate: entity.subtype.effectiveDate,
      termEndDate: entity.subtype.termEndDate,
      label: isInteractive ? undefined : 'Effective date',
    }),
    {
      label: isInteractive ? 'Term length' : 'Length',
      value: termDurationYears
        ? `${termDurationYears.toString()} years`
        : EMPTY_CONTENT_HYPHEN,
    },
    {
      label: isInteractive ? 'Initial funding value' : 'Initial funding',
      value: initialValuationValue
        ? formatCurrency(initialValuationValue)
        : EMPTY_CONTENT_HYPHEN,
      action: shouldShowInitialFundingValueButton ? (
        <InitialFundingButton />
      ) : undefined,
    },
    {
      label: 'Taxable gift amount',
      value: entity.subtype.taxableGift
        ? formatCurrency(entity.subtype.taxableGift)
        : EMPTY_CONTENT_HYPHEN,
    },
    {
      label: '7520 rate',
      value: entity.subtype.officialInterestRatePercent
        ? `${formatPercent(entity.subtype.officialInterestRatePercent, 1)}%`
        : EMPTY_CONTENT_HYPHEN,
    },
    {
      label: SUBSTITUTION_THRESHOLD_LABEL,
      value: getFormattedSubstitutionThreshold({
        upsideThresholdPercent: entity.subtype.upsideThresholdPercent,
        upsideThresholdValue: entity.subtype.upsideThresholdValue,
        downsideThresholdPercent: entity.subtype.downsideThresholdPercent,
      }),
      action: isInteractive ? <SubstitutionThresholdButton /> : undefined,
    },
  ];

  const summary: TrustSummaryData = {
    ...getSharedSummaryProperties(entity),
    grantorNames,
    beneficiariesGroups: [
      {
        heading: undefined,
        items: entity.subtype.beneficiaries || [],
        noItemsText: 'No beneficiaries',
      },
    ],
    trustDetailsItems,
  };

  return summary;
}

export function getGRATTrustDetailsTabs(
  cardProps: EntityCardProps,
  featureFlags: FeatureFlagMap = {}
) {
  const summaryData = getGRATTrustSummaryProperties(cardProps);
  const details = getTrustDetailsTabs(summaryData, featureFlags);

  return details;
}
