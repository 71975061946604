import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { Aidp_AsyncJobWithKgRootFragment } from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/graphql/TriggerAIDPSuggestions.generated';
import { DispositiveProvisionsModalDetails } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';

import {
  ManageDispositions_EntityFragment,
  ManageDispositions_TestamentaryEntityFragment,
} from '../graphql/ManageDispositionsPage.generated';
import {
  ManageDispositionPageSections,
  ManageDispositions_AugmentedClientProfileRow,
} from '../ManageDispositionPage.types';

export interface ManageDispositionsContext {
  activeSection: ManageDispositionPageSections;
  setActiveSection: (section: ManageDispositionPageSections) => void;

  activeClientDeathId: string;
  setActiveClientDeathId: (id: string) => void;

  dispositiveProvisionsModalDetails: DispositiveProvisionsModalDetails | null;
  setDispositiveProvisionsModalDetails: (
    details: DispositiveProvisionsModalDetails | null
  ) => void;

  inEstateEntities: ManageDispositions_EntityFragment[];
  setInEstateEntities: (entities: ManageDispositions_EntityFragment[]) => void;
  outOfEstateEntities: ManageDispositions_EntityFragment[];
  setOutOfEstateEntities: (
    entities: ManageDispositions_EntityFragment[]
  ) => void;
  testamentaryEntities: ManageDispositions_TestamentaryEntityFragment[];
  setTestamentaryEntities: (
    entities: ManageDispositions_TestamentaryEntityFragment[]
  ) => void;
  primaryClientRows: ManageDispositions_AugmentedClientProfileRow[];
  setPrimaryClientRows: (
    clients: ManageDispositions_AugmentedClientProfileRow[]
  ) => void;

  // For each entity that we care about managing DP's for, we'll have at most
  // one AI_DP job that is either completed or in progress.
  aiDPSuggestionJobs: Aidp_AsyncJobWithKgRootFragment[];
  setAIDPSuggestionJobs: (jobs: Aidp_AsyncJobWithKgRootFragment[]) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const ManageDispositionsContext =
  createContext<ManageDispositionsContext>({
    activeSection: ManageDispositionPageSections.IN_ESTATE_ENTITIES,
    setActiveSection: noop,

    activeClientDeathId: '',
    setActiveClientDeathId: noop,

    dispositiveProvisionsModalDetails: null,
    setDispositiveProvisionsModalDetails: noop,

    inEstateEntities: [],
    setInEstateEntities: noop,
    outOfEstateEntities: [],
    setOutOfEstateEntities: noop,
    testamentaryEntities: [],
    setTestamentaryEntities: noop,
    primaryClientRows: [],
    setPrimaryClientRows: noop,

    aiDPSuggestionJobs: [],
    setAIDPSuggestionJobs: noop,

    isLoading: true,
    setIsLoading: noop,
  });

export const useManageDispositionsContext = () => {
  return useGuardedContext(
    ManageDispositionsContext,
    'ManageDispositionsProvider'
  );
};
