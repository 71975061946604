import { css } from '@emotion/css';
import { Box } from '@mui/material';
import { compact } from 'lodash';

import { useListColumnStyles } from '@/components/lists/lists.utils';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../../presentation.constants';
import { PresentationSlide } from '../../../PresentationSlide';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

export interface TrustBeneficiariesSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: TrustSummaryData;
  displayName: string;
}

const SLIDE_TITLE = 'Beneficiaries';
const ITEMS_PER_PAGE = 7;

export function TrustBeneficiariesSlide({
  entity,
  summary,
  displayName,
  ...registrationProps
}: TrustBeneficiariesSlideProps) {
  // the eslint plugin for hooks is incorrectly flagging this as being called in a loop
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useRegisterSlide({
    displayName: SLIDE_TITLE,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  // the eslint plugin for hooks is incorrectly flagging this as being called in a loop
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const listColumnsStyles = useListColumnStyles();

  const pageCount = Math.max(
    ...summary.beneficiariesGroups.map(
      (group) => group.items.length / ITEMS_PER_PAGE
    )
  );

  const pagedGroups: TrustSummaryData['beneficiariesGroups'][] = [];
  for (let page = 0; page <= pageCount; page++) {
    pagedGroups.push(
      summary.beneficiariesGroups.map(({ heading, noItemsText, items }) => {
        const pageStart = page * ITEMS_PER_PAGE;
        const pageEnd = (page + 1) * ITEMS_PER_PAGE;
        return {
          heading,
          noItemsText:
            page === 0 ? noItemsText : 'No beneficiaries on this page',
          items: compact(items.slice(pageStart, pageEnd)),
        };
      })
    );
  }

  return (
    <>
      {pagedGroups.map((pagedGroup, index) => (
        <PresentationSlide.Slide
          id={registrationProps.slideId}
          key={`${registrationProps.slideId}-${index}`}
          leftHeaderContent={
            <PresentationSlide.MainHeading
              heading={SLIDE_TITLE}
              subheading={displayName}
            />
          }
          rightHeaderContent={
            <PresentationSlideSecondaryHeading
              clientDisplayName={entity.household.displayName}
            />
          }
          footer={<PresentationSlide.Footer />}
        >
          <Box p={PRESENTATION_SPACING_UNITS} sx={{ position: 'relative' }}>
            <TrustBeneficiariesList
              variant="flat"
              classes={{
                parentList: css({
                  ...listColumnsStyles,
                  // to allow the list to break across columns even with break-inside: avoid, we need
                  // a specific (px) max-height value -- percentages do not work here
                  maxHeight: 800,
                }),
              }}
              beneficiariesGroups={pagedGroup}
            />
          </Box>
        </PresentationSlide.Slide>
      ))}
    </>
  );
}
