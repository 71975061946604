import { Box } from '@mui/material';
import React from 'react';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { AppNotificationLinearProgress } from '@/components/AppNotifications/AppNotificationLinearProgress';
import { AppNotificationListItem } from '@/components/AppNotifications/AppNotificationListItem';
import { Button } from '@/components/form/baseInputs/Button';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { CheckCircleIcon } from '@/components/icons/CheckCircleIcon';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { useEstimatedProgress } from '@/components/progress/ProgressBar/useEstimatedProgress';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { COLORS } from '@/styles/tokens/colors';

/**
 * @description Renders the appropriate notification item content based on the
 * notification type and status, when the notifications menu is opened.
 *
 * @param notification
 * @param onCompletedActionClick
 * @constructor
 */
export function AsyncJobNotificationItem({
  notification,
  onCompletedActionClick,
}: {
  notification: AsyncJobNotificationInfo;
  onCompletedActionClick: () => void;
}) {
  const {
    status,
    estimatedDurationMs,
    newNotificationMessage,
    completedNotificationMessage,
    asyncJob,
    notificationTargetDisplayName,
  } = notification;

  const isCompleted = status === AsyncJobNotificationStatus.COMPLETED;

  const icon =
    status === AsyncJobNotificationStatus.COMPLETED ? (
      <CheckCircleIcon color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT} size={16} />
    ) : (
      <SpinningIcon Icon={RefreshCw05Icon} size={16} />
    );

  const title =
    status === AsyncJobNotificationStatus.COMPLETED
      ? completedNotificationMessage
      : newNotificationMessage;

  const subtitle = `for ${notificationTargetDisplayName}`;

  const progress = useEstimatedProgress({
    startTime: asyncJob.createdAt.getTime(),
    estimatedDuration: estimatedDurationMs,
    isCompleted,
  });

  return (
    <AppNotificationListItem icon={icon} title={title} subtitle={subtitle}>
      {isCompleted && (
        <Box>
          <Button
            variant="primary"
            size="sm"
            sx={{ marginTop: 2 }}
            endIcon={ArrowRightIcon}
            onClick={onCompletedActionClick}
          >
            Review details
          </Button>
        </Box>
      )}
      {status === AsyncJobNotificationStatus.IN_PROGRESS && (
        <AppNotificationLinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginTop: 1 }}
        />
      )}
    </AppNotificationListItem>
  );
}
