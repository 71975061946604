import { Stack, Theme, useMediaQuery } from '@mui/material';
import { first } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { Button } from '@/components/form/baseInputs/Button';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AfterDeath } from '@/types/schema';

import { EstateWaterfallAssumptionFields } from '../EstateWaterfallAssumptions/types';
import {
  EMPTY_ESTATE_WATERFALL_COMPARISON,
  EstateWaterfallComparisonShape,
} from '../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonTrowserComponents } from './EstateWaterfallComparisonTrowser.components';
import {
  EstateWaterfallComparisonTrowserContextProvider,
  useEstateWaterfallComparisonTrowserContext,
} from './EstateWaterfallComparisonTrowser.context';
import {
  EstateWaterfallComparisonTrowserFormShape,
  EstateWaterfallComparisonWaterfallWithAssumptions as EstateWaterfallComparisonWaterfallLegacy,
} from './EstateWaterfallComparisonTrowser.type';
import {
  getEstateWaterfallComparisonTrowserQueryVariables,
  mapDataToEstateWaterfallComparisonTrowser,
} from './EstateWaterfallComparisonTrowser.utils';
import {
  EstateWaterfallComparisonTrowserQueryVariables,
  useEstateWaterfallComparisonTrowserQuery,
} from './graphql/EstateWaterfallComparisonTrowser.generated';

function EstateWaterfallComparisonTrowserInner() {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const { control, setValue } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const { waterfallIds } = useEstateWaterfallComparisonTrowserContext();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const [legacyAssumptions, afterDeath, comparisonState] = useWatch({
    control,
    name: ['legacyAssumptions', 'afterDeath', 'comparisonState'],
  });

  const variables =
    useMemo<EstateWaterfallComparisonTrowserQueryVariables>(() => {
      return getEstateWaterfallComparisonTrowserQueryVariables(
        waterfallIds,
        isGrowthProfilesEnabled,
        legacyAssumptions,
        comparisonState
      );
    }, [
      waterfallIds,
      isGrowthProfilesEnabled,
      legacyAssumptions,
      comparisonState,
    ]);

  const { data, loading } = useEstateWaterfallComparisonTrowserQuery({
    variables,
    // don't cache the query result, since we don't want the data derived from _this_ specific comparison
    // to be used elsewhere on the page -- nor should it override the cached data on the waterfall view
    fetchPolicy: 'no-cache',
    onError: (error) => {
      reportError(
        `Caught error when fetching estate waterfall comparison for [${waterfallIds.join(', ')}]`,
        error
      );
      showFeedback('Could not compare waterfalls. Please try again.');
    },
  });

  const waterfalls = useMemo<EstateWaterfallComparisonWaterfallLegacy[]>(() => {
    if (!data) {
      return [];
    }
    return mapDataToEstateWaterfallComparisonTrowser(
      data,
      afterDeath,
      waterfallIds,
      comparisonState
    );
  }, [afterDeath, comparisonState, data, waterfallIds]);

  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <Stack spacing={3} px={isLessThanMedium ? 3 : 18} pt={3} pb={4}>
      <LoadingOverlay open={loading} />
      <EstateWaterfallComparisonTrowserComponents.Header />
      {isGrowthProfilesEnabled ? null : (
        <EstateWaterfallComparisonTrowserComponents.Assumptions
          disabled={loading}
          firstWaterfallId={first(waterfallIds[0])}
          onSubmit={(assumptions) => setValue('legacyAssumptions', assumptions)}
        />
      )}
      <EstateWaterfallComparisonTrowserComponents.Tabs
        loading={loading}
        waterfalls={waterfalls}
      />
      <EstateWaterfallComparisonTrowserComponents.ParentComparisonSummary
        waterfalls={waterfalls}
      />
      <EstateWaterfallComparisonTrowserComponents.BarGraph
        waterfalls={waterfalls}
        afterDeath={afterDeath}
      />
      <EstateWaterfallComparisonTrowserComponents.SummaryTable
        waterfalls={waterfalls}
      />
    </Stack>
  );
}

export interface EstateWaterfallComparisonTrowserProps {
  isOpen: boolean;
  onClose: () => void;
  waterfallIds: string[];
  assumptions: EstateWaterfallAssumptionFields;
  comparisonState: EstateWaterfallComparisonShape | null;
}

export function EstateWaterfallComparisonTrowser({
  isOpen,
  onClose,
  waterfallIds,
  assumptions,
  comparisonState,
}: EstateWaterfallComparisonTrowserProps) {
  const defaultValues: EstateWaterfallComparisonTrowserFormShape = {
    legacyAssumptions: assumptions,
    afterDeath: AfterDeath.None,
    comparisonState: comparisonState ?? EMPTY_ESTATE_WATERFALL_COMPARISON,
  };

  const formMethods = useForm<EstateWaterfallComparisonTrowserFormShape>({
    defaultValues,
  });

  const wasOpen = usePrevious(isOpen);
  const trackUserEvent = useTrackUserEvent();

  useEffect(() => {
    // only fire this when the modal is being opened
    if (isOpen && !wasOpen) {
      trackUserEvent('waterfall compared', {
        waterfallIds: waterfallIds.join(','),
        assumptions: JSON.stringify(assumptions),
        comparisonState: JSON.stringify(comparisonState),
      });
    }
  });

  return (
    <EstateWaterfallComparisonTrowserContextProvider
      waterfallIds={waterfallIds}
    >
      <FormProvider {...formMethods}>
        <FullScreenModal isOpen={isOpen} onClose={onClose}>
          <Stack
            height="100%"
            pb={`${FOOTER_HEIGHT}px`}
            sx={{ overflowY: 'auto' }}
          >
            <EstateWaterfallComparisonTrowserInner />
          </Stack>
          <Footer
            rightAction={
              <Button variant="primary" size="sm" onClick={onClose}>
                Close
              </Button>
            }
          />
        </FullScreenModal>
      </FormProvider>
    </EstateWaterfallComparisonTrowserContextProvider>
  );
}
