import { BASIC_ASSETS_SUBFORM as BASIC_ASSETS } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { BENEFICIARIES_SUBFORM } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { INSURANCE_POLICIES_DETAILS_SUBFORM } from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { hasNonGrantorPrincipalFields } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateDonorAdvisedFundInput,
  AugmentedCreatePrivateFoundationInput,
  AugmentedUpdateDonorAdvisedFundInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdatePrivateFoundationInput,
  EntityInEstateStatus,
  NonTrustEntityTaxStatus,
  UpdateDonorAdvisedFundInput,
  UpdateEntityInput,
  UpdatePrivateFoundationInput,
} from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';
import { UnreachableError } from '@/utils/errors';

import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../../../BasicInformationSubform/BasicInformationSubform.types';
import {
  PartialSubformsCombinedType,
  SubformsCombinedType,
} from '../../EntitySubforms.types';
import {
  makeAccountInput,
  makeUpdateAccountInput,
} from '../shared/accounts.utils';
import { makeCreateBeneficiaryInputs } from '../shared/beneficiaries.utils';
import { getTypeOrUndefined } from '../shared/common.utils';
import { makeCreateControllingPartyInputs } from '../shared/controllingParties.utils';
import { makeUpdatePolicyInputs } from '../shared/policies.utils';
import { makeCreateOwnerInputsForAccountDetailsSubform } from '../shared/principals.utils';
import { sharedClearTaxStatusUpdate } from '../shared/taxStatus.utils';

export function makeCharitableNonTrustEntityInput(
  formInput: SubformsCombinedType,
  config: {
    entityType: EntityType;
  },
  householdId: string
):
  | AugmentedCreateDonorAdvisedFundInput
  | AugmentedCreatePrivateFoundationInput {
  const ownerInputs = makeCreateOwnerInputsForAccountDetailsSubform(
    formInput.basicInformationSubform
  );
  const controllingPartiesInputs = makeCreateControllingPartyInputs(
    formInput.basicInformationSubform
  );

  const commonCreateInput = {
    legalName: formInput[BASIC_INFORMATION].legalName || undefined,
    displayName: formInput[BASIC_INFORMATION].displayName,
    effectiveDate: getTypeOrUndefined<Date>(
      formInput[BASIC_INFORMATION].effectiveDate
    ),
    description: getTypeOrUndefined<string>(
      formInput[BASIC_INFORMATION].description
    ),
  };

  const commonRelatedInput = {
    withControllingParties: controllingPartiesInputs,
    withDesignerAccount: makeAccountInput(formInput),
  };

  switch (config.entityType) {
    case 'daf':
    case 'private-foundation': {
      return {
        create: {
          ...commonCreateInput,
          taxStatus: NonTrustEntityTaxStatus.NonTaxable,
          gstStatus: undefined, // (T2-1192) don't include gstStatus for these entities
          inEstateStatus: EntityInEstateStatus.OutOfEstate,
        },
        ...commonRelatedInput,
        withDonors: ownerInputs,
        withBeneficiaries: makeCreateBeneficiaryInputs(
          formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
          householdId
        ),
      };
    }
    default:
      throw new Error(`Unexpected entityType ${config.entityType}`);
  }
}

export function makeEntityTypeUpdateFoundationInput(
  entityId: string,
  entityType: EntityType,
  updateEntityInput: UpdateEntityInput,
  updateInput:
    | AugmentedUpdateDonorAdvisedFundInput
    | AugmentedUpdatePrivateFoundationInput
): AugmentedUpdateEntityInput {
  const sharedProperties = {
    id: entityId,
    update: updateEntityInput,
  };

  switch (entityType) {
    case 'daf':
      return {
        ...sharedProperties,
        updateDonorAdvisedFund: updateInput,
      };
    case 'private-foundation':
      return {
        ...sharedProperties,
        updatePrivateFoundation: updateInput,
      };

    default:
      throw new UnreachableError({
        case: entityType as never,
        message: 'Unrecognized foundation type',
      });
  }
}

export function makeUpdateNonTrustCharitableEntityInput({
  formInput,
  subtypeId,
  entityType,
  householdId,
}: {
  formInput: PartialSubformsCombinedType;
  subtypeId: string;
  entityType: EntityType;
  householdId: string;
}):
  | AugmentedUpdateDonorAdvisedFundInput
  | AugmentedUpdatePrivateFoundationInput {
  if (
    !formInput.basicInformationSubform ||
    !hasNonGrantorPrincipalFields(formInput.basicInformationSubform)
  ) {
    throw new Error(
      `Account details subform is required to update a foundation`
    );
  }

  const { updatePolicies, withPolicies, removePolicyIDs } =
    makeUpdatePolicyInputs(formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]);

  const donorInputs = makeCreateOwnerInputsForAccountDetailsSubform(
    formInput.basicInformationSubform
  );
  const controllingPartiesInputs = makeCreateControllingPartyInputs(
    formInput.basicInformationSubform
  );

  const commonSubentityUpdate:
    | UpdateDonorAdvisedFundInput
    | UpdatePrivateFoundationInput = {
    legalName: formInput[BASIC_INFORMATION]?.legalName || undefined,
    displayName: formInput[BASIC_INFORMATION]?.displayName,
    effectiveDate: formInput[BASIC_INFORMATION]?.effectiveDate,
    description: getTypeOrUndefined<string>(
      formInput[BASIC_INFORMATION]?.description
    ),
    clearControllingParties: true,
    clearDonors: true,
    clearBeneficiaries: true,
    removePolicyIDs,
    ...sharedClearTaxStatusUpdate(formInput),
  };

  const commonRelatedEntityUpdate: Omit<
    | AugmentedUpdateDonorAdvisedFundInput
    | AugmentedUpdatePrivateFoundationInput,
    'update'
  > = {
    id: subtypeId,
    withControllingParties: controllingPartiesInputs,
    withDonors: donorInputs,
    withBeneficiaries: makeCreateBeneficiaryInputs(
      formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
      householdId
    ),
    withPolicies,
    updatePolicies,
  };
  if (formInput[BASIC_ASSETS]) {
    commonRelatedEntityUpdate.updateDesignerAccount =
      makeUpdateAccountInput(formInput);
  }

  switch (entityType) {
    case 'daf':
    case 'private-foundation': {
      const update = {
        ...commonSubentityUpdate,
      };

      return assertExact<
        | AugmentedUpdateDonorAdvisedFundInput
        | AugmentedUpdatePrivateFoundationInput
      >()({
        ...commonRelatedEntityUpdate,
        update,
      });
    }
    default:
      throw new Error(`Unhandled entity type: ${entityType}`);
  }
}
