import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { LegendColorRenderer } from '@/components/tables/DataTable/renderers/cell/LegendColorRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { WaterfallSummaryTableItem } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

const sectionIndicatorColumn: Column<WaterfallSummaryTableItem> = {
  field: 'sectionIndicator',
  headerName: '',
  renderCell: LegendColorRenderer({
    backgroundColor: ({ row }) => row.sectionIndicator.backgroundColor,
  }),
  align: 'center',
  sortable: false,
  width: 55,
  minWidth: 55,
  maxWidth: 55,
};

const entityColumn: Column<WaterfallSummaryTableItem> = {
  field: 'entity',
  headerName: 'Entity',
  flex: 1,
  renderCell: TwoLineTextRenderer({
    lineOneProps: {
      variant: 'h5',
    },
    rightContent: ({ row }) => {
      if (!row.hasHypotheticalTransfer) {
        return undefined;
      }

      return <Badge variant={BadgeVariants.Yellow} display="Transfer" />;
    },
  }),
  sortComparator: (
    v1: WaterfallSummaryTableItem['entity'],
    v2: WaterfallSummaryTableItem['entity'],
    p1,
    p2
  ) => {
    return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
  },
};

const valueColumn: Column<WaterfallSummaryTableItem> = {
  field: 'value',
  headerName: 'Projected value (no growth)',
  align: 'right',
  flex: 1,
  renderCell: TwoLineTextRenderer({
    lineOne: ({ row }) => {
      return formatCurrencyNoDecimalsAccounting(row.value);
    },
    lineOneProps: ({ row }) => {
      const isNegative = row.value.isNegative();

      if (isNegative) {
        return { color: COLORS.MATH.NEGATIVE };
      }

      return {};
    },
    lineTwo: ({ row }) => {
      return row.valueBeforeTransfersNoGrowth
        ? `${formatCurrency(row.valueBeforeTransfersNoGrowth, { notation: 'compact' })} before transfer`
        : '';
    },
  }),
};

const valueWithProjectionsColumn = (
  growthRate: Decimal | null,
  growthProfileName: string
): Column<WaterfallSummaryTableItem> => {
  let headerName = `Projected value (${growthRate?.toNumber()}% growth)`;
  if (growthProfileName) {
    headerName = `Projected value (${growthProfileName})`;
  }
  return {
    field: 'projectedValue',
    headerName,
    align: 'right',
    flex: 1,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => {
        if (!row.projectedValue) {
          return EMPTY_CONTENT_HYPHEN;
        }
        return formatCurrencyNoDecimalsAccounting(row.projectedValue);
      },
      lineOneProps: ({ row }) => {
        const isNegative = row.projectedValue?.isNegative();

        if (isNegative) {
          return { color: COLORS.ORANGE[600] };
        }

        return {};
      },
      lineTwo: ({ row }) => {
        return row.valueBeforeTransfers
          ? `${formatCurrency(row.valueBeforeTransfers, { notation: 'compact' })} before transfer`
          : '';
      },
    }),
  };
};

export const getWaterfallSummaryTableColumns = (
  growthRate: Decimal | null,
  growthProfileName: string
) => {
  return [
    sectionIndicatorColumn,
    entityColumn,
    valueColumn,
    valueWithProjectionsColumn(growthRate, growthProfileName),
  ];
};
