import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/form/baseInputs/Button';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { DEFAULT_ASSUMPTIONS } from '../../EstateWaterfallAssumptions/constants';
import {
  CustomGlobalGrowthRateToggle,
  ExemptionGrowthRateField,
  ExemptionSunsetField,
  FirstGrantorDeathField,
  SecondGrantorDeathField,
} from '../../EstateWaterfallAssumptions/EstateWaterfallAssumptionFormFields';
import { getAssumptionsPreviewCopy } from '../../EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater.utils';
import { EstateWaterfallAssumptionFields } from '../../EstateWaterfallAssumptions/types';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';
import { useAccordionCalloutCopy } from '../hooks/useAccordionCalloutCopy';

export interface EstateWaterfallComparisonTrowserAssumptionsInnerProps {
  disabled: boolean;
  firstWaterfallId: string | undefined; // necessary to get household details for tooltip
  onClickSubmit: () => void;
}

function EstateWaterfallComparisonTrowserAssumptionsInner({
  disabled,
  firstWaterfallId,
  onClickSubmit,
}: EstateWaterfallComparisonTrowserAssumptionsInnerProps) {
  const { control, reset } = useFormContext<EstateWaterfallAssumptionFields>();
  const [
    assetGrowthReturn,
    exemptionGrowthRate,
    willExemptionSunset,
    useCustomGlobalGrowthRate,
  ] = useWatch({
    control,
    name: [
      'assetGrowthReturn',
      'exemptionGrowthRate',
      'willExemptionSunset',
      'useCustomGlobalGrowthRate',
    ],
  });

  const { ILLUSTRATIVE_PURPOSES_ONLY } =
    useAccordionCalloutCopy(firstWaterfallId);

  const assumptionsCopy = useMemo<string>(() => {
    // this shouldn't happen, satisfy ts
    if (!exemptionGrowthRate || !assetGrowthReturn) return '';
    return getAssumptionsPreviewCopy({
      exemptionGrowthRate,
      assetGrowthReturn,
      willExemptionSunset,
      useCustomGlobalGrowthRate,
    });
  }, [
    assetGrowthReturn,
    exemptionGrowthRate,
    useCustomGlobalGrowthRate,
    willExemptionSunset,
  ]);

  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const numInputs = isTwoClientHousehold ? 4 : 3;

  return (
    <Accordion
      variant="filled"
      data-testid="EstateWaterfallAssumptionsAccordion"
      title={
        <Stack flexDirection="row">
          Manage assumptions
          <ContextualHelpTooltip>
            <PopperContent body={ILLUSTRATIVE_PURPOSES_ONLY} />
          </ContextualHelpTooltip>
        </Stack>
      }
      subtitle={
        <Stack pl={1} justifyContent="center">
          <Typography variant="subtitle2">{assumptionsCopy}</Typography>
        </Stack>
      }
      hideSubtitleOnOpen
      labelContainerProps={{ direction: 'row' }}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: isLessThanMedium
              ? 'repeat(2, 1fr)'
              : `repeat(${numInputs}, 1fr)`,
            columnGap: 3,
          }}
        >
          <Stack spacing={1}>
            <FirstGrantorDeathField disabled={disabled} />
            {isTwoClientHousehold && (
              <SecondGrantorDeathField disabled={disabled} />
            )}
          </Stack>
          <Stack spacing={1}>
            <ExemptionGrowthRateField disabled={disabled} />
            <ExemptionSunsetField disabled={disabled} />
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomGlobalGrowthRateToggle disabled={disabled} />
          <Stack direction="row" spacing={2}>
            <Button
              variant="transparent"
              disabled={disabled}
              size="sm"
              onClick={() => {
                reset(DEFAULT_ASSUMPTIONS, { keepDefaultValues: false });
                onClickSubmit();
              }}
            >
              Reset assumptions
            </Button>
            <Button
              variant="primary"
              disabled={disabled}
              size="sm"
              onClick={() => onClickSubmit()}
            >
              Update comparison
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Accordion>
  );
}

export interface EstateWaterfallComparisonTrowserAssumptionsProps
  extends Omit<
    EstateWaterfallComparisonTrowserAssumptionsInnerProps,
    'onClickSubmit'
  > {
  onSubmit: (formValues: EstateWaterfallAssumptionFields) => void;
}

export function EstateWaterfallComparisonTrowserAssumptions({
  disabled,
  firstWaterfallId,
  onSubmit: onSubmitExternal,
}: EstateWaterfallComparisonTrowserAssumptionsProps) {
  const { control } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const [legacyAssumptions] = useWatch({
    control,
    name: ['legacyAssumptions'],
  });

  const formMethods = useForm<EstateWaterfallAssumptionFields>({
    defaultValues: legacyAssumptions || {},
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit((formValues) => {
    onSubmitExternal(formValues);
  });

  return (
    <FormProvider {...formMethods}>
      <EstateWaterfallComparisonTrowserAssumptionsInner
        disabled={disabled}
        firstWaterfallId={firstWaterfallId}
        onClickSubmit={onSubmit}
      />
    </FormProvider>
  );
}
