import { DispositiveProvisionsFormShape } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { Fields as BasicInformationSubformFieldsType } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { Fields as BusinessEntityDetailsSubformFieldsType } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { EntityBeneficiariesSubformFields } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import { SubformConfig } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { Fields as TaxStatusSubformFieldsType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.types';
import { Fields as TrustDetailsSubformFieldsType } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';

// These mostly map to SubformConfig types, but are specific to the UX where
// users edit the entity in sections with the split-screen document view,
// instead of the single large form.
export enum EditEntitySection {
  BASIC_INFORMATION = 'BASIC_INFORMATION',
  TRUSTEES = 'TRUSTEES',
  TAX_STATUS = 'TAX_STATUS',
  BENEFICIARIES = 'BENEFICIARIES', // GRAT (even though they are called "remainder", we're still using the regular beneficiariesSubform
  LIFETIME_BENEFICIARIES = 'LIFETIME_BENEFICIARIES', // SLAT
  REMAINDER_BENEFICIARIES = 'REMAINDER_BENEFICIARIES', // CRT, CLT
  INCOME_BENEFICIARIES = 'INCOME_BENEFICIARIES', // CRT
  LEAD_BENEFICIARIES = 'LEAD_BENEFICIARIES', // CLT
  INSURANCE_POLICIES = 'INSURANCE_POLICIES', // ILIT
  INITIAL_FUNDING_ASSETS = 'INITIAL_FUNDING_ASSETS', // GRAT, QPRT,
  ANNUITY_PAYMENTS = 'ANNUITY_PAYMENTS', // GRAT
  BUSINESS_ENTITY_TAX_STATUS = 'BUSINESS_ENTITY_TAX_STATUS', // Business Entities
  BUSINESS_ENTITY_DETAILS = 'BUSINESS_ENTITY_DETAILS', // Business Entities
  DISPOSITIVE_PROVISIONS_GRANTOR_1 = 'DISPOSITIVE_PROVISIONS_GRANTOR_1',
  DISPOSITIVE_PROVISIONS_GRANTOR_2 = 'DISPOSITIVE_PROVISIONS_GRANTOR_2',
}

export function isEditEntitySection(
  value: string | null | undefined
): value is EditEntitySection {
  return Object.values(EditEntitySection).includes(value as EditEntitySection);
}

export interface EditEntityRichSection {
  label: string;
  section: EditEntitySection;
}

// The individual subform field types for each supported section/subform type.
export type SupportedSubformFieldTypes =
  | BasicInformationSubformFieldsType
  | TrustDetailsSubformFieldsType
  | TaxStatusSubformFieldsType
  | EntityBeneficiariesSubformFields
  | BusinessEntityDetailsSubformFieldsType;

// The combined subform field types for all supported sections/subform types.
export type CombinedSupportedSubformFieldTypes =
  BasicInformationSubformFieldsType &
    TrustDetailsSubformFieldsType &
    TaxStatusSubformFieldsType &
    EntityBeneficiariesSubformFields &
    BusinessEntityDetailsSubformFieldsType &
    DispositiveProvisionsFormShape;

// This is a wrapper around the SubformConfig and initial data for the subform,
// for a given EditEntitySection / SubformConfig type.
export interface EditEntitySectionInitialConfigWithData {
  subformConfig: SubformConfig;

  // We're setting up initial data to be like { [NAMESPACE]: {...values} },
  // even though we're only handling one section/subform at a time, so that we
  // can re-use the same code for form validation/submission.
  initialData: SupportedSubformFieldTypes;
}
