import { Stack, Typography } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { TypographyClamped } from '@/components/typography/TypographyClamped';

interface NotesCardProps {
  description: string | null;
}

// Note, if you update styling here, make sure HEIGHT_CONFIG.NOTES_CARD_HEIGHT_PX
// in entityPresentationPagination.utils.ts is updated accordingly to make sure pagination works correctly
export function NotesCard({ description }: NotesCardProps) {
  if (!description) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      <Stack spacing={0.5} width="100%">
        <Typography variant="h6" component="span">
          Notes
        </Typography>
        {/* 45 lines is the max number of lines that can fit on a presentation slide */}
        <TypographyClamped lines={45} variant="subtitle2">
          {description}
        </TypographyClamped>
      </Stack>
    </Card>
  );
}
