import { Stack } from '@mui/material';
import { getYear } from 'date-fns';
import { first, flatten, orderBy, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  BeneficiaryReportingContext,
  useBeneficiaryReportingContext,
} from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import { ClientOrOrgId } from '@/modules/beneficiaryReporting/beneficiaryReporting.types';
import { BeneficiarySelector } from '@/modules/beneficiaryReporting/BeneficiarySelector';
import { useBeneficiariesData } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';
import { EstateWaterfallSubPanelButtons } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons';
import { useGetHandleEstateWaterfallSubpanelButtonsClick } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons.hooks';
import { EstateWaterfallAssumptionsUpdater } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater';
import { useWaterfallSummaryContext } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { getNodes } from '@/utils/graphqlUtils';

import { WaterfallSummaryProvider } from '../ClientDetailsEstateWaterfallSummaryListPage/contexts/WaterfallSummary.provider';
import { SummaryPanel } from '../ClientDetailsEstateWaterfallSummaryListPage/SummaryPanel';

function ClientDetailsEstateWaterfallBeneficiaryReportingPageInner() {
  const householdId = useRequiredParam('householdId');
  const waterfallId = useRequiredParam('waterfallId');
  const navigate = useNavigate();
  const params = useParams();
  const clientOrOrgId: ClientOrOrgId | null = params.clientOrOrgId || null;

  const { possibleGrantors } = useHouseholdDetailsContext();
  const {
    summaryPanel: { isModalOpen },
  } = useWaterfallSummaryContext();
  const handleSubpanelButtonsClick =
    useGetHandleEstateWaterfallSubpanelButtonsClick();

  const { beneficiariesData, queryData } = useBeneficiaryReportingContext();

  const beneficiaryOptions: SelectInputOption<ClientOrOrgId>[] = useMemo(() => {
    if (!beneficiariesData || !queryData) {
      return [];
    }

    const household = first(getNodes(queryData.estateWaterfalls))?.household;

    if (!household) {
      return [];
    }

    const grantorIds = possibleGrantors?.map((g) => g.id) ?? [];

    const uniqueBeneficiaryIds = orderBy(
      uniq(
        flatten(Object.values(beneficiariesData).map((b) => Object.keys(b)))
      ),
      (id) => {
        if (grantorIds.includes(id)) {
          return 0;
        }
        return 1;
      },
      ['asc']
    );

    return uniqueBeneficiaryIds.map((id) => ({
      display:
        household.clientProfiles?.find((c) => c.id === id)?.displayName ??
        household.clientOrganizations?.find((o) => o.id === id)?.name ??
        id,
      value: id,
    }));
  }, [beneficiariesData, queryData, possibleGrantors]);

  const getReportingOverviewPath = useCallback(() => {
    return getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_OVERVIEW,
      {
        householdId,
        waterfallId,
      }
    );
  }, [householdId, waterfallId]);

  const getBeneficiaryDetailsPath = useCallback(
    (clientOrOrgId: ClientOrOrgId) => {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS,
        {
          householdId,
          waterfallId,
          clientOrOrgId,
        }
      );
    },
    [householdId, waterfallId]
  );

  const menuItems = useMemo(() => {
    return beneficiaryOptions.map((b) => (
      <MenuItem
        key={b.value}
        label={b.display}
        muiMenuItemProps={{
          selected: b.value === clientOrOrgId,
        }}
        onClick={() => {
          navigate(getBeneficiaryDetailsPath(b.value));
        }}
      />
    ));
  }, [beneficiaryOptions, clientOrOrgId, getBeneficiaryDetailsPath, navigate]);

  const selectedBeneficiary = useMemo(() => {
    return beneficiaryOptions.find((o) => o.value === clientOrOrgId);
  }, [beneficiaryOptions, clientOrOrgId]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        pb={2}
        alignItems="center"
      >
        <BigBreadcrumbs
          crumbs={[
            {
              label: (
                <BeneficiarySelector
                  heading="Beneficiaries"
                  menuItems={menuItems}
                  loading={!queryData}
                  hideChevron={!!selectedBeneficiary}
                  to={getReportingOverviewPath()}
                />
              ),
            },
            ...(selectedBeneficiary
              ? [
                  {
                    label: (
                      <BeneficiarySelector
                        heading={selectedBeneficiary.display}
                        menuItems={menuItems}
                        loading={!queryData}
                        to={getBeneficiaryDetailsPath(
                          selectedBeneficiary.value
                        )}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
        <EstateWaterfallSubPanelButtons onClick={handleSubpanelButtonsClick} />
      </Stack>

      <EstateWaterfallAssumptionsUpdater waterfallId={waterfallId}>
        <Stack direction="row" spacing={4} pb={4} flex={1}>
          <Stack flex={1} width={isModalOpen ? 'calc(100% - 430px)' : '100%'}>
            {beneficiaryOptions.length === 0 ? (
              <ActionCard
                heading="No beneficiaries"
                description="Add beneficiaries and dispositions to entities to quantify beneficiary outcomes"
                icon={null}
                sx={{
                  flex: 1,
                }}
                actions={
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        navigate(
                          getCompletePathFromRouteKey(
                            ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
                            {
                              householdId,
                            }
                          )
                        );
                      }}
                    >
                      Go to entities
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        navigate(
                          getCompletePathFromRouteKey(
                            ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS,
                            {
                              householdId,
                            }
                          )
                        );
                      }}
                    >
                      Manage dispositions
                    </Button>
                  </Stack>
                }
              />
            ) : (
              <Outlet />
            )}
          </Stack>
          {isModalOpen && (
            <Stack width="430px">
              <SummaryPanel />
            </Stack>
          )}
        </Stack>
      </EstateWaterfallAssumptionsUpdater>
    </>
  );
}

export function ClientDetailsEstateWaterfallBeneficiaryReportingPage() {
  const waterfallId = useRequiredParam('waterfallId');
  const params = useParams();
  const clientOrOrgId: ClientOrOrgId | null = params.clientOrOrgId || null;

  const { primaryClients } = useHouseholdDetailsContext();

  const { beneficiariesData, data: queryData } =
    useBeneficiariesData(waterfallId);

  const { firstGrantorDeathId, firstGrantorDeathYear, secondGrantorDeathYear } =
    useMemo(() => {
      if (!queryData) {
        return {
          firstGrantorDeathId: undefined,
          firstGrantorDeathYear: undefined,
          secondGrantorDeathYear: undefined,
        };
      }

      const waterfall = getNodes(queryData.estateWaterfalls)[0];

      return {
        firstGrantorDeathId: waterfall?.firstGrantorDeath.id,
        firstGrantorDeathYear:
          waterfall?.firstGrantorDeathYear ?? getYear(new Date()),
        secondGrantorDeathYear:
          waterfall?.secondGrantorDeathYear ?? getYear(new Date()),
      };
    }, [queryData]);

  const [firstGrantor, secondGrantor] = useMemo(() => {
    const firstGrantor = primaryClients?.find(
      (g) => g.id === firstGrantorDeathId
    );
    const secondGrantor = primaryClients?.find(
      (g) => g.id !== firstGrantorDeathId
    );

    return [firstGrantor, secondGrantor];
  }, [firstGrantorDeathId, primaryClients]);

  if (!beneficiariesData || !queryData) {
    return (
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
          },
        }}
      />
    );
  }

  return (
    <WaterfallRouteProvider waterfallId={waterfallId}>
      <WaterfallSummaryProvider waterfallId={waterfallId}>
        <BeneficiaryReportingContext.Provider
          value={{
            clientOrOrgId,
            beneficiariesData,
            firstGrantor,
            secondGrantor,
            firstGrantorDeathYear,
            secondGrantorDeathYear,
            queryData,
          }}
        >
          <ClientDetailsEstateWaterfallBeneficiaryReportingPageInner />
        </BeneficiaryReportingContext.Provider>
      </WaterfallSummaryProvider>
    </WaterfallRouteProvider>
  );
}
