import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';

import { CheckDone02Icon } from '@/components/icons/CheckDone02Icon';
import { IconProps } from '@/components/icons/ChevronDownIcon';
import { Home03Icon } from '@/components/icons/Home03Icon';
import { MessageQuestionCircleIcon } from '@/components/icons/MessageQuestionCircleIcon';
import { Rocket01Icon } from '@/components/icons/Rocket01Icon';
import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import { Settings01Icon } from '@/components/icons/Settings01Icon';
import { Users01Icon } from '@/components/icons/Users01Icon';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useModalState } from '@/hooks/useModalState';
import { AsyncJobNotifications } from '@/modules/asyncJobs/AsyncJobNotifications/AsyncJobNotifications';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { GlobalSearch } from '@/modules/globalSearch/GlobalSearch';
import { ROUTE_KEYS } from '@/navigation/constants';
import { useCanAccessPage } from '@/navigation/usePageAccessLookup';
// this is an appropriate usage of a page-specific import
// eslint-disable-next-line luminary-rules/no-page-imports
import { useAuthorizedSettingsPages } from '@/pages/admin/AdminUsersPage/hooks/useAuthorizedAdminPages';
import { getEnvironment } from '@/utils/environmentUtils';

import { SidebarAvatar } from '../../Sidebar/SidebarAvatar';
import { SidebarMenuItem } from '../../Sidebar/SidebarMenuItem';
import { SupportTooltip } from '../../Sidebar/SupportTooltip';
import { UserSettingsTooltip } from '../../Sidebar/UserSettingsTooltip';

const iconProps: IconProps = {
  size: 16,
  sx: {
    display: 'flex',
  },
};

interface AppBarMenuItemsProps {
  appBarTheme?: 'dark' | 'light';
  disableActiveHighlight?: boolean;
}

export function AppBarMenuItems({
  appBarTheme = 'dark',
  disableActiveHighlight = false,
}: AppBarMenuItemsProps) {
  const currentUser = useCurrentUser();
  const canAccessTasksPage = useCanAccessPage(ROUTE_KEYS.TASKS);
  const canAccessCollaboratorsPage = useCanAccessPage(ROUTE_KEYS.COLLABORATORS);

  // if someone has access to >0 admin pages, we want to show the admin menu item,
  // and route them to the first tab that they have access to
  const eligibleSettingsPages = useAuthorizedSettingsPages();
  const firstEligibleSettingsPage = eligibleSettingsPages[0];

  const [{ isModalOpen: isOpen }, modalFunctions] = useModalState();

  const commonMenuItemProps = useMemo(
    () => ({
      variant: 'small' as const,
      colorTheme: appBarTheme,
      highlightWhenActive: true,
      isActive: disableActiveHighlight ? false : undefined,
    }),
    [appBarTheme, disableActiveHighlight]
  );

  return (
    <>
      <Stack component="ul" alignItems="center" spacing={0.5} direction="row">
        <AsyncJobNotifications colorTheme={appBarTheme} />
        <Tooltip placement="bottom" title="Clients">
          <SidebarMenuItem
            text="Clients"
            to="/households"
            icon={<Home03Icon {...iconProps} />}
            {...commonMenuItemProps}
          />
        </Tooltip>
        {canAccessTasksPage && (
          <Tooltip placement="bottom" title="Tasks">
            <SidebarMenuItem
              text="Tasks"
              to="/tasks"
              icon={<CheckDone02Icon {...iconProps} />}
              {...commonMenuItemProps}
            />
          </Tooltip>
        )}
        {canAccessCollaboratorsPage && (
          <Tooltip placement="bottom" title="Collaborators">
            <SidebarMenuItem
              text="Collaborators"
              to="/collaborators"
              icon={<Users01Icon {...iconProps} />}
              {...commonMenuItemProps}
            />
          </Tooltip>
        )}
        {firstEligibleSettingsPage && (
          <Tooltip placement="bottom" title="Settings">
            <SidebarMenuItem
              text="Settings"
              showWarningBadge={currentUser?.apiUserIntegrationLoginExpired}
              to={firstEligibleSettingsPage.to}
              icon={<Settings01Icon {...iconProps} />}
              {...commonMenuItemProps}
            />
          </Tooltip>
        )}
        <Tooltip placement="bottom" title={<SupportTooltip />}>
          <SidebarMenuItem
            text="Support"
            icon={<MessageQuestionCircleIcon {...iconProps} />}
            {...commonMenuItemProps}
            highlightWhenActive={false}
          />
        </Tooltip>
        {getEnvironment() !== 'prod' && currentUser?.isInternalUser && (
          <Tooltip placement="bottom" title="Dev tools">
            <SidebarMenuItem
              text="Dev tools"
              to="/internal/dev-tools"
              icon={<Rocket01Icon {...iconProps} />}
              {...commonMenuItemProps}
            />
          </Tooltip>
        )}
        <Tooltip placement="bottom" title="Search">
          <SidebarMenuItem
            text="Search"
            icon={<SearchSmIcon aria-label="Search" {...iconProps} />}
            to="/search"
            onClick={() => {
              modalFunctions.openModal();
            }}
            {...commonMenuItemProps}
            highlightWhenActive={false}
          />
        </Tooltip>
        <Box pl={0.5}>
          <Tooltip
            leaveDelay={250}
            placement="bottom"
            title={<UserSettingsTooltip />}
            PopperProps={{
              disablePortal: true, // This is a fix for a scrolling issue
            }}
          >
            <Box
              sx={{
                cursor: 'default',
                position: 'relative',
              }}
            >
              <SidebarAvatar size="xs" isExpanded={false} />
            </Box>
          </Tooltip>
        </Box>
      </Stack>
      <GlobalSearch
        isOpen={isOpen}
        onClose={() => {
          modalFunctions.closeModal();
        }}
        openModal={modalFunctions.openModal}
      />
    </>
  );
}
