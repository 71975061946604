import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';

import {
  GiftDesignerModelScenariosFormShape,
  ScenarioIncomeAndExpensesFormShape,
  ScenarioIncomeAndExpensesFormShapeMerged,
} from '../../GiftDesignerModelScenariosForm.types';
import {
  AnnuallyRecurringValue,
  ScenarioIncomeAndExpensesFormFields,
} from './ScenarioIncomeAndExpensesModal.fields';
import { useScenarioIncomeAndExpensesFormDefaultValues } from './ScenarioIncomeAndExpensesModal.utils';

interface OutOfEstatePortfolioFormModalProps {
  incomeData: ScenarioIncomeAndExpensesFormShape | null;
  isOpen: boolean;
  proposalId: string;
  onClose: () => void;
  onSubmitSuccess: (
    formData: ScenarioIncomeAndExpensesFormShape
  ) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
}

function ScenarioIncomeAndExpensesModalInner({
  incomeData,
  isOpen,
  proposalId,
  onClose,
  onSubmitSuccess,
  onDelete = () => new Promise(() => null),
}: OutOfEstatePortfolioFormModalProps) {
  const isEdit = !!incomeData;
  const [isDeleting, setIsDeleting] = useState(false);

  const { formRef, handleSave } = useFormSaveHandler();

  const { reset, handleSubmit, control, formState } =
    useFormContext<ScenarioIncomeAndExpensesFormShape>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit cash flow';
    }

    return 'Add cash flow';
  }, [isEdit]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<ScenarioIncomeAndExpensesFormShape>
        formState={formState}
        isDeleting={isDeleting}
      >
        {isEdit && (
          <FormModalActions.DeleteButton
            onConfirmDelete={async () => {
              setIsDeleting(true);
              await onDelete();
              setIsDeleting(false);
            }}
          />
        )}
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          {isEdit ? 'Save edits' : 'Add cash flow'}
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave, isDeleting, isEdit, onDelete]);

  const onSubmit = handleSubmit(
    (formData: ScenarioIncomeAndExpensesFormShapeMerged) => {
      const commonFormData = {
        _id: formData._id,
        cashFlowId: formData.cashFlowId,
        amount: formData.amount,
        cashFlowType: formData.cashFlowType,
        displayName: formData.displayName,
        order: formData.order,
        startYear: formData.startYear,
        lengthOfAnalysis: formData.lengthOfAnalysis,
        portfolioID: formData.portfolioID,
      };

      // We need to make sure to format the form data correctly
      if (formData.annuallyRecurring === AnnuallyRecurringValue.false) {
        return onSubmitSuccess({
          ...commonFormData,
          annuallyRecurring: AnnuallyRecurringValue.false,
          endYear: null,
          growthPercentage: null,
        });
      } else {
        return onSubmitSuccess({
          ...commonFormData,
          annuallyRecurring: AnnuallyRecurringValue.true,
          endYear: formData.endYear!,
          growthPercentage: formData.growthPercentage!,
        });
      }
    }
  );

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  const annuallyRecurringValue = useWatch({
    control,
    name: 'annuallyRecurring',
  });

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={handleClose}
      actions={actions}
    >
      <Stack
        component="form"
        ref={formRef}
        onSubmit={onSubmit}
        noValidate
        pb={1}
      >
        <FormLayoutRow>
          <FormLayoutItem>
            <ScenarioIncomeAndExpensesFormFields.Name />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <ScenarioIncomeAndExpensesFormFields.CashFlowAttributedTo
              proposalId={proposalId}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <ScenarioIncomeAndExpensesFormFields.CashFlowType />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={5}>
            <ScenarioIncomeAndExpensesFormFields.Amount />
          </FormLayoutItem>
          <FormLayoutItem width={7}>
            <Stack height="100%" justifyContent="flex-end">
              <ScenarioIncomeAndExpensesFormFields.AnnuallyRecurring />
              <Box height={8} />
            </Stack>
          </FormLayoutItem>
        </FormLayoutRow>
        {annuallyRecurringValue === AnnuallyRecurringValue.true ? (
          <FormLayoutRow>
            <FormLayoutItem width={4}>
              <ScenarioIncomeAndExpensesFormFields.StartYear
                isAnnuallyRecurring={true}
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <ScenarioIncomeAndExpensesFormFields.EndYear />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <ScenarioIncomeAndExpensesFormFields.GrowthRate />
            </FormLayoutItem>
          </FormLayoutRow>
        ) : (
          <FormLayoutRow>
            <FormLayoutItem>
              <ScenarioIncomeAndExpensesFormFields.StartYear
                isAnnuallyRecurring={false}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Stack>
    </FormModal>
  );
}

export function ScenarioIncomeAndExpensesModal(
  props: OutOfEstatePortfolioFormModalProps
) {
  const { incomeData } = props;

  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis',
  });

  const { defaultValues } = useScenarioIncomeAndExpensesFormDefaultValues({
    incomeData,
    lengthOfAnalysis: lengthOfAnalysis || new Decimal(0),
  });

  const formMethods = useForm<ScenarioIncomeAndExpensesFormShape>({
    defaultValues,
    values: defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <ScenarioIncomeAndExpensesModalInner {...props} />
    </FormProvider>
  );
}
