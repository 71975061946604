import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering';
import { compact, first } from 'lodash';

import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { getNewBundleId } from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2Bundle } from '../../types/ClientPresentationV2.PresentationBundleType';
import {
  BUNDLE_TO_DISPLAY_NAME,
  isSinglePageBundle,
  PAGE_TO_DISPLAY_NAME,
} from './ClientPresentationDesignerV2TreeView.constants';
import { PresentationTreeViewItem } from './ClientPresentationDesignerV2TreeView.types';

export function mapPresentationToTreeViewForNav(
  bundles: ClientPresentationV2Bundle[]
): PresentationTreeViewItem[] {
  return bundles.map<PresentationTreeViewItem>((bundle) => {
    const { id, displayName, type, pages } = bundle;
    let children: PresentationTreeViewItem[] | undefined;

    if (!isSinglePageBundle(type)) {
      children = pages.map<PresentationTreeViewItem>((page) => ({
        id: page.id,
        type: page.type,
        label: PAGE_TO_DISPLAY_NAME[page.type],
        isBundle: false,
        bundle,
        page,
      }));
    }

    return {
      id,
      type,
      label: displayName || BUNDLE_TO_DISPLAY_NAME[type],
      children,
      isBundle: true,
      bundle,
    };
  });
}

export interface TreeItemChangeParam {
  itemId: string;
  oldPosition: TreeViewItemReorderPosition;
  newPosition: TreeViewItemReorderPosition;
}

export function onItemPositionChange(
  param: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  // case 1: moving bundles
  if (
    param.oldPosition.parentId === null &&
    param.newPosition.parentId === null
  ) {
    return onMoveBundle(param, bundles);
  }

  // case 2: moving pages w/in the same bundle
  if (param.oldPosition.parentId === param.newPosition.parentId) {
    return onMovePageWithinBundle(param, bundles);
  }

  // case 3: move custom bundle into a bundle
  if (
    param.oldPosition.parentId === null &&
    param.newPosition.parentId !== null
  ) {
    return onMoveCustomIntoBundle(param, bundles);
  }

  // case 4: move custom page to root/new bundle
  if (
    param.oldPosition.parentId !== null &&
    param.newPosition.parentId === null
  ) {
    return onMoveCustomToRoot(param, bundles);
  }

  // case 5: move custom page between bundles
  if (
    param.oldPosition.parentId !== null &&
    param.newPosition.parentId !== null &&
    param.oldPosition.parentId !== param.newPosition.parentId
  ) {
    return onMoveCustomBetweenBundles(param, bundles);
  }

  diagnostics.error(
    'Invalid move condition encountered',
    new Error(JSON.stringify(param))
  );
  return bundles;
}

function onMoveBundle(
  { oldPosition, newPosition }: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  const movedBundle = bundles[oldPosition.index];
  if (!movedBundle) {
    diagnostics.error(
      `Could not find bundle ID [${oldPosition.index}] in onItemPositionChange`
    );
    return bundles;
  }

  const newBundles = bundles.slice();
  newBundles.splice(oldPosition.index, 1);
  newBundles.splice(newPosition.index, 0, movedBundle);

  return compact(newBundles);
}

function onMovePageWithinBundle(
  { oldPosition, newPosition }: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  const updatedBundleIndex = bundles.findIndex(
    (bundle) => bundle.id === oldPosition.parentId
  );
  const updatedBundle = bundles[updatedBundleIndex];

  if (!updatedBundle) {
    diagnostics.error(
      `Could not find bundle ID [${oldPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const movedPage = updatedBundle.pages[oldPosition.index];
  if (!movedPage) {
    diagnostics.error(
      `Could not find page ID [${oldPosition.index}] in onItemPositionChange`
    );
    return bundles;
  }

  const updatedPages = updatedBundle.pages.slice();
  updatedPages.splice(oldPosition.index, 1);
  updatedPages.splice(newPosition.index, 0, movedPage);
  updatedBundle.pages = updatedPages;

  const newBundles = bundles.slice();
  newBundles[updatedBundleIndex] = updatedBundle;

  return newBundles;
}

function onMoveCustomIntoBundle(
  { oldPosition, newPosition }: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  const sourceBundleIndex = oldPosition.index;
  const sourceBundle = bundles[sourceBundleIndex];

  const targetBundleIndex = bundles.findIndex(
    (bundle) => bundle.id === newPosition.parentId
  );

  if (!sourceBundle) {
    diagnostics.error(
      `Could not find source bundle ID [${oldPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const sourcePage = first(sourceBundle.pages);

  if (!sourcePage) {
    diagnostics.error(
      `Could not find source page from bundle ID [${oldPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const newBundles = bundles.slice();
  const newTargetBundle = newBundles[targetBundleIndex];

  if (!newTargetBundle) {
    diagnostics.error(
      `Could not find target bundle ID [${newPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const newTargetBundlePages = newTargetBundle.pages.slice();
  newTargetBundlePages.splice(newPosition.index, 0, sourcePage);
  newTargetBundle.pages = newTargetBundlePages;

  newBundles[targetBundleIndex] = newTargetBundle;

  newBundles.splice(sourceBundleIndex, 1);

  return newBundles;
}

function onMoveCustomToRoot(
  { oldPosition, newPosition }: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  const newBundles = bundles.slice();

  const sourceBundleIndex = bundles.findIndex(
    (bundle) => bundle.id === oldPosition.parentId
  );
  const sourceBundle = bundles[sourceBundleIndex];
  const newSourceBundle = newBundles[sourceBundleIndex];

  if (!sourceBundle || !newSourceBundle) {
    diagnostics.error(
      `Could not find source bundle ID [${oldPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const sourcePage = sourceBundle.pages[oldPosition.index];

  if (!sourcePage) {
    diagnostics.error(
      `Could not find source page in bundle ID [${oldPosition.parentId}], index [${oldPosition.index}]`
    );
    return bundles;
  }

  // remove the page from the source bundle
  const newSourceBundlePages = sourceBundle.pages.slice();
  newSourceBundlePages.splice(oldPosition.index, 1);
  newSourceBundle.pages = newSourceBundlePages;
  newBundles[sourceBundleIndex] = newSourceBundle;

  const newTargetBundle: ClientPresentationV2Bundle = {
    id: getNewBundleId(PresentationBundleKind.CustomPageBundle),
    type: PresentationBundleKind.CustomPageBundle,
    displayName:
      BUNDLE_TO_DISPLAY_NAME[PresentationBundleKind.CustomPageBundle],
    pages: [sourcePage],
  };

  newBundles.splice(newPosition.index, 0, newTargetBundle);

  return newBundles;
}

function onMoveCustomBetweenBundles(
  { oldPosition, newPosition }: TreeItemChangeParam,
  bundles: ClientPresentationV2Bundle[]
): ClientPresentationV2Bundle[] {
  const newBundles = bundles.slice();
  const sourceBundleIndex = newBundles.findIndex(
    (bundle) => bundle.id === oldPosition.parentId
  );
  const newSourceBundle = newBundles[sourceBundleIndex];

  const targetBundleIndex = bundles.findIndex(
    (bundle) => bundle.id === newPosition.parentId
  );
  const newTargetBundle = bundles[targetBundleIndex];

  if (!newSourceBundle || !newTargetBundle) {
    diagnostics.error(
      `Could not find source bundle ID [${oldPosition.parentId}] or target bundle ID [${newPosition.parentId}] in onItemPositionChange`
    );
    return bundles;
  }

  const sourcePage = bundles[sourceBundleIndex]?.pages[oldPosition.index];
  if (!sourcePage) {
    diagnostics.error(
      `Could not find source page in bundle ID [${oldPosition.parentId}], index [${oldPosition.index}]`
    );
    return bundles;
  }

  newSourceBundle.pages.splice(oldPosition.index, 1);
  newTargetBundle.pages.splice(newPosition.index, 0, sourcePage);

  newBundles[sourceBundleIndex] = newSourceBundle;
  newBundles[targetBundleIndex] = newTargetBundle;

  return newBundles;
}
