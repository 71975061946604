import { Box } from '@mui/material';
import Decimal from 'decimal.js';
import React from 'react';
import { Validate } from 'react-hook-form';

import { CurrencyInput } from '@/components/form/baseInputs/CurrencyInput';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { GRATAnnuityPayments } from '@/modules/content/tooltipContent/GRATAnnuityPayments';
import { GRATRemainder } from '@/modules/content/tooltipContent/GRATRemainder';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

import { FormSectionProps, GRATStructuringForm } from '../constants';
import {
  ANNUITY_GROWTH_TYPE_OPTIONS,
  ANNUITY_GROWTH_TYPE_VALUES,
  REMAINDER_TYPE_OPTIONS,
  REMAINDER_TYPE_VALUES,
} from './constants';

type RetainedInterestSplitEntryPattern =
  | 'GRANTOR_RETAINED_INTEREST'
  | 'TAXABLE_GIFT_AMOUNT';

type AnnuityFormProps = FormSectionProps<GRATStructuringForm['annuity']> & {
  initialFundingValue: Decimal | null;
};

const getValidateEqualOrLessThanFundingValue = (
  initialFundingValue: Decimal
) => {
  return function validateEqualOrLessThanFundingValue(value: string) {
    const DecimalToValidate = new Decimal(value);
    if (
      !DecimalToValidate.isNaN() &&
      DecimalToValidate.greaterThan(initialFundingValue)
    ) {
      return 'Grantor retained interest must be less than the funding value.';
    }

    return undefined;
  } as Validate<unknown, unknown>;
};

export function AnnuityForm({ values, initialFundingValue }: AnnuityFormProps) {
  const { control, setValue } = useFormContext<GRATStructuringForm>();
  const formDisabledForAbsentFundingValue = initialFundingValue === null;
  const [
    retainedInterestSplitEntryPattern,
    setRetainedInterestSplitEntryPattern,
  ] = React.useState<RetainedInterestSplitEntryPattern>(
    'GRANTOR_RETAINED_INTEREST'
  );

  React.useEffect(
    function computeTaxableGiftAndGrantorRetainedInterest() {
      if (values.remainderType === REMAINDER_TYPE_VALUES.ZEROED_OUT) {
        setValue('annuity.grantorRetainedInterest', initialFundingValue);
        setValue('annuity.taxableGiftAmount', new Decimal(0));
      } else if (values.remainderType === REMAINDER_TYPE_VALUES.TAXABLE_GIFT) {
        // in Taxable Gift mode, users can enter values in either the grantor retained interest field
        // or the taxable gift amount, and we should calculate the opposite field. the retainedInterestSplitEntryPattern
        // value is the field that was most recently focused, and therefore the field that we should be
        // calculating off of
        if (retainedInterestSplitEntryPattern === 'GRANTOR_RETAINED_INTEREST') {
          const calculatedTaxableGiftAmount = (() => {
            if (
              initialFundingValue === null ||
              values.grantorRetainedInterest === null
            ) {
              return null;
            }

            return initialFundingValue.minus(values.grantorRetainedInterest);
          })();

          setValue('annuity.taxableGiftAmount', calculatedTaxableGiftAmount);
        } else if (
          retainedInterestSplitEntryPattern === 'TAXABLE_GIFT_AMOUNT'
        ) {
          const calculatedGrantorRetainedInterest = (() => {
            if (initialFundingValue === null) return null;
            return initialFundingValue.minus(values.taxableGiftAmount || 0);
          })();
          setValue(
            'annuity.grantorRetainedInterest',
            calculatedGrantorRetainedInterest
          );
        }
      }
    },
    // disabling the exhaustive deps because doing comparisons on complex objects causes the hook to incorrectly rerender,
    // and the lint rule isn't smart enough to be able to understand this via static analysis
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      values.remainderType,
      getComparableDecimalJS(values.grantorRetainedInterest),
      getComparableDecimalJS(values.taxableGiftAmount),
      getComparableDecimalJS(initialFundingValue),
      retainedInterestSplitEntryPattern,
      setValue,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  React.useEffect(
    function handleAnnualIncreaseChange() {
      if (values.growthType === ANNUITY_GROWTH_TYPE_VALUES.FIXED) {
        setValue('annuity.annuityAnnualIncreasePercent', new Decimal(0));
        return;
      }

      if (values.annuityAnnualIncreasePercent?.greaterThan(new Decimal(0))) {
        return;
      }

      setValue('annuity.annuityAnnualIncreasePercent', new Decimal(20));
    },
    // disabling the exhaustive deps because doing comparisons on complex objects causes the hook to incorrectly rerender,
    // and the lint rule isn't smart enough to be able to understand this via static analysis
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      setValue,
      values.growthType,
      getComparableDecimalJS(values.annuityAnnualIncreasePercent),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return (
    <Box>
      {formDisabledForAbsentFundingValue && (
        <FormLayoutRow>
          <FormLayoutItem>
            <Callout severity="info-high">
              Please enter asset information before completing the annuity
              details.
            </Callout>
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareButtonGroup<GRATStructuringForm>
            fieldName="annuity.remainderType"
            label="GRAT remainder"
            contextualHelp={<GRATRemainder />}
            required
            control={control}
            options={REMAINDER_TYPE_OPTIONS}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {values.remainderType && (
        <>
          <FormLayoutRow>
            <FormLayoutItem>
              {/*
                This is a plain CurrencyInput rather than a FormAwareCurrencyInput because
                it's only responsible for displaying a static value, and will never be
                updating that value.
               */}
              <CurrencyInput
                label="Initial funding value"
                value={initialFundingValue?.toString()}
                disabled
                required
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareCurrencyInput<GRATStructuringForm>
                label="Grantor retained interest"
                fieldName="annuity.grantorRetainedInterest"
                helpText="The exact grantor retained interest shown in the scenario analysis on the right may not exactly match the value entered here."
                isDecimalJSInput
                disabled={
                  values.remainderType === REMAINDER_TYPE_VALUES.ZEROED_OUT ||
                  formDisabledForAbsentFundingValue
                }
                onFocus={() =>
                  setRetainedInterestSplitEntryPattern(
                    'GRANTOR_RETAINED_INTEREST'
                  )
                }
                control={control}
                required
                validation={{
                  max: getValidateEqualOrLessThanFundingValue(
                    initialFundingValue || new Decimal(0)
                  ),
                }}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareCurrencyInput<GRATStructuringForm>
                label="Taxable gift amount"
                fieldName="annuity.taxableGiftAmount"
                helpText="The exact taxable gift amount shown in the scenario analysis on the right may not exactly match the value entered here."
                isDecimalJSInput
                disabled={
                  values.remainderType === REMAINDER_TYPE_VALUES.ZEROED_OUT ||
                  formDisabledForAbsentFundingValue
                }
                onFocus={() =>
                  setRetainedInterestSplitEntryPattern('TAXABLE_GIFT_AMOUNT')
                }
                control={control}
                required
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      )}
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareButtonGroup<GRATStructuringForm>
            contextualHelp={<GRATAnnuityPayments />}
            fieldName="annuity.growthType"
            label="Annuity payments"
            required
            control={control}
            options={ANNUITY_GROWTH_TYPE_OPTIONS}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {values.growthType === ANNUITY_GROWTH_TYPE_VALUES.INCREASING && (
        <FormLayoutRow>
          <FormLayoutItem width={6}>
            <FormAwarePercentInput<GRATStructuringForm>
              fieldName="annuity.annuityAnnualIncreasePercent"
              label="Annual increase"
              required
              isDecimalJSInput
              maxValue={20}
              disabled={formDisabledForAbsentFundingValue}
              control={control}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </Box>
  );
}
