import { first, isEmpty, some } from 'lodash';
import { useCallback, useMemo } from 'react';

import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { useMostRecentEntityAISuggestionsAsyncJobs } from '@/modules/aiSuggestions/hooks/useMostRecentEntityAISuggestionsAsyncJobs';
import { AsyncJobNotificationInfoType } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AsyncJobKind } from '@/types/schema';

/**
 * This hook determines if the entity that we're within the context of has
 * triggered an AI DP workflow, and returns a callback to refetch the
 * pending AI suggestions job(s) after the workflow has been triggered.
 */
export function useHasEntityTriggeredAIDPWorkflow() {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const { getNotifications } = useAsyncJobNotificationsContext();
  const { householdId } = useHouseholdDetailsContext();
  const { entityId, previouslyCompletedAISuggestionAsyncJobs } =
    useEntityDetailsContext();
  const { refetch } = useMostRecentEntityAISuggestionsAsyncJobs(
    AsyncJobKind.AiDp
  );
  const { hasSuggestions } = useEntityDPSuggestionsContext();

  const shouldHideTriggerBanner = useMemo(() => {
    if (!aiDpEnabled || !householdId || hasSuggestions) return true;

    const notifs = getNotifications(
      householdId,
      AsyncJobNotificationInfoType.AI_DP
    );

    // Are there any active single-entity notifications we're already tracking?
    const activeEntityNotifs = notifs.filter(
      (n) =>
        n.asyncJob?.kind === AsyncJobKind.AiDp &&
        first(n.asyncJob?.kgRoot)?.entity?.id === entityId
    );

    // Are there any batch job notifications we're already tracking that includes this entity?
    const activeBatchNotifs = notifs.filter(
      (n) =>
        n.asyncJob?.kind === AsyncJobKind.AiDpBatched &&
        some(
          n.asyncJob?.children,
          (child) => first(child.kgRoot)?.entity?.id === entityId
        )
    );

    return (
      !isEmpty(activeEntityNotifs) ||
      !isEmpty(activeBatchNotifs) ||
      !isEmpty(previouslyCompletedAISuggestionAsyncJobs)
    );
  }, [
    aiDpEnabled,
    entityId,
    getNotifications,
    hasSuggestions,
    householdId,
    previouslyCompletedAISuggestionAsyncJobs,
  ]);

  const handleStartWorkflowSuccess = useCallback(() => {
    if (!entityId) return;
    void refetch();
  }, [entityId, refetch]);

  return {
    shouldHideTriggerBanner,
    handleStartWorkflowSuccess,
  };
}
