import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';

import {
  AssetV2IntegrationType,
  AssetValuationV2ValuationSource,
  IntegrationClientKind,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  StructuredAssetsSubform_AccountFragment,
  StructuredAssetsSubform_AssetFragment,
  StructuredAssetsSubform_ValuationFragment,
} from './graphql/StructuredAssetsSubform.generated';
import {
  StructuredAsset,
  StructuredAssetsSubformProperties,
} from './StructuredAssetsSubform.types';

export function getFormAssetsFromAssets(
  assets: StructuredAssetsSubform_AssetFragment[],
  {
    activeClientIntegrationType,
  }: {
    activeClientIntegrationType: IntegrationClientKind | null;
  }
): StructuredAsset[] {
  const assetIntegrationType = activeClientIntegrationType
    ? getAssetIntegrationTypeFromIntegrationClientKind(
        activeClientIntegrationType
      )
    : null;
  return assets.map((asset) => {
    return {
      categoryId: asset.class.id,
      title: asset.displayName,
      value: asset.assetValue.ownedValue ?? new Decimal(0),
      // we pass this specifically for the case where we are editing an integrated valuation
      // and want to add additional manual assets
      integrationType: assetIntegrationType,
    };
  });
}

function getFormValuesFromValuation(
  accountId: string,
  valuation: StructuredAssetsSubform_ValuationFragment | null
): StructuredAssetsSubformProperties {
  let dateOfValuation = valuation?.effectiveDate ?? null;
  // If a valuation is present, but the date is the epoch, then the valuation
  // is a placeholder and should be treated as if it doesn't exist in the form.
  if (
    dateOfValuation?.getTime() === new Date(new Date(0).toISOString()).getTime()
  ) {
    dateOfValuation = null;
  }

  const assets = (() => {
    // if we're looking at an automated valuation, we only want to load assets into the structured assets subform
    // that are NOT being pulled from an integration. This is because we want to allow the user to add additional
    // manual assets via the subform.
    if (valuation?.valuationSource !== AssetValuationV2ValuationSource.Manual) {
      return getManualAssetsFromIntegratedValuation(valuation);
    }
    return getNodes(valuation?.assets);
  })();
  const formAssets = getFormAssetsFromAssets(assets, {
    activeClientIntegrationType: null,
  });
  const assetsValueSum = getAssetValueSum(formAssets);

  return {
    accountId,
    valuationId: valuation?.id,
    documentIds: [],
    description: valuation?.description ?? '',
    assets: formAssets,
    _assetsValueSum: assetsValueSum,
  };
}

export function getAssetValueSum(assets: StructuredAsset[]): Decimal {
  return (assets || []).reduce((acc, a) => {
    acc = acc.add(a.value ?? new Decimal(0));
    return acc;
  }, new Decimal(0));
}

export function getStructuredAssetsSubformValues(
  designerAccount: StructuredAssetsSubform_AccountFragment | null
): StructuredAssetsSubformProperties {
  const emptyFormData: StructuredAssetsSubformProperties = {
    accountId: undefined,
    valuationId: undefined,
    description: '',
    documentIds: [],
    assets: [],
    _assetsValueSum: new Decimal(0),
  };

  if (!designerAccount) {
    diagnostics.error('No designer account found');
    return emptyFormData;
  }

  const mostRecentValuation =
    designerAccount.valuations.edges?.[0]?.node ?? null;
  const formValues = getFormValuesFromValuation(
    designerAccount.id,
    mostRecentValuation
  );
  return formValues;
}

/**
 * Returns true if the valuation has additional manual assets on top of an integration,
 * meaning assets that are not linked to an integration. Returns false if there is no valuation,
 * or if the valuation is manual.
 */
export function valuationHasAdditionalManualAssets(
  valuation: StructuredAssetsSubform_ValuationFragment | null
) {
  const assets = getManualAssetsFromIntegratedValuation(valuation);
  return !isEmpty(assets);
}

function getManualAssetsFromIntegratedValuation(
  valuation: StructuredAssetsSubform_ValuationFragment | null
) {
  if (valuation?.valuationSource === AssetValuationV2ValuationSource.Manual) {
    return [];
  }
  return getNodes(valuation?.assets).filter((asset) => !asset.integrationType);
}

export function getIntegratedAssetsFromIntegratedValuation(
  valuation: StructuredAssetsSubform_ValuationFragment | null
): StructuredAssetsSubform_AssetFragment[] {
  const assets = getNodes(valuation?.assets).filter(
    (asset) => asset.integrationType
  );

  return assets;
}

export function getAssetIntegrationTypeFromIntegrationClientKind(
  integrationClientKind: IntegrationClientKind
): AssetV2IntegrationType | null {
  switch (integrationClientKind) {
    case IntegrationClientKind.Addepar:
      return AssetV2IntegrationType.Addepar;
    case IntegrationClientKind.BlackDiamond:
      return AssetV2IntegrationType.BlackDiamond;
    case IntegrationClientKind.Orion:
      return AssetV2IntegrationType.Orion;
    default:
      return null;
  }
}
