import { noop } from 'lodash';
import { createContext } from 'react';

import { AppNotificationsProps } from '@/components/AppNotifications/AppNotifications';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';

import { AsyncJobStatus_AsyncJobFragment } from '../../graphql/AsyncJobs.generated';

export interface AsyncJobNotificationsContext {
  notifications: AsyncJobNotificationInfo[];
  addNotification: (notification: AsyncJobNotificationInfo) => void;
  removeNotifications: (notificationIds: string[]) => void;
  removeHouseholdNotifications: (householdID: string) => void;
  removeEntityNotifications: (entityID: string) => void;
  newNotificationPopup: AppNotificationsProps['newNotificationPopup'];
  getNotifications: (
    householdID: string,
    type: AsyncJobNotificationInfoType,
    status?: AsyncJobNotificationStatus
  ) => AsyncJobNotificationInfo[];
  lastCompletedJobs: AsyncJobStatus_AsyncJobFragment[];
}

export const getDefaultValues = (): AsyncJobNotificationsContext => ({
  notifications: [],
  addNotification: noop,
  removeNotifications: noop,
  removeHouseholdNotifications: noop,
  removeEntityNotifications: noop,
  newNotificationPopup: null,
  getNotifications: () => [],
  lastCompletedJobs: [],
});

export const AsyncJobNotificationsContext =
  createContext<AsyncJobNotificationsContext>(getDefaultValues());

export const useAsyncJobNotificationsContext = () => {
  return useGuardedContext(
    AsyncJobNotificationsContext,
    'AsyncJobNotificationsProvider'
  );
};
