import { isEmpty } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';

import { EntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { MatchableKind } from '@/modules/aiSuggestions/aiSuggestions.types';
import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useFirstClientDeathId } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/hooks/utilityHooks';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AiSuggestionKind, KgRootKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

function useContextValue(): EntityDPSuggestionsContext {
  const { primaryClients } = useHouseholdDetailsContext();
  const {
    loading: loadingSuggestions,
    suggestionsByKind,
    acknowledgedSuggestions,
    kgRoots,
  } = useEntitySuggestionsContext();

  const firstDeathClientId = useFirstClientDeathId();

  const dpKinds = useMemo(() => {
    if (isEmpty(primaryClients)) return undefined;

    const pcIndex = primaryClients?.findIndex(
      (pc) => pc.id === firstDeathClientId
    );

    switch (pcIndex) {
      case 0:
        return {
          recipientSuggestionKind:
            AiSuggestionKind.DpRecipientGrantor_1DiesFirst as MatchableKind,
          matcherVariantKind:
            AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_1_DIES_FIRST,
          kgRootKind: KgRootKind.EntityDispositiveProvisionsGrantor_1DiesFirst,
        };
      case 1:
        return {
          recipientSuggestionKind:
            AiSuggestionKind.DpRecipientGrantor_2DiesFirst as MatchableKind,
          matcherVariantKind:
            AISuggestionsMatcherVariant.DP_RECIPIENTS_GRANTOR_2_DIES_FIRST,
          kgRootKind: KgRootKind.EntityDispositiveProvisionsGrantor_2DiesFirst,
        };
      default:
        diagnostics.warn(
          `[useAIDPSuggestions] could not determine ai suggestion dp kinds to use for firstDeathPrimaryClientId=${firstDeathClientId}. not in list primaryClients=${JSON.stringify(primaryClients)}`
        );
        return undefined;
    }
  }, [firstDeathClientId, primaryClients]);

  const recipientSuggestions = useMemo(() => {
    if (!dpKinds) return undefined;
    return suggestionsByKind[dpKinds.recipientSuggestionKind].filter(
      (s) => s.dpRecipient
    );
  }, [dpKinds, suggestionsByKind]);

  const kgRoot = useMemo(() => {
    if (!dpKinds) return undefined;
    return kgRoots[dpKinds.kgRootKind];
  }, [dpKinds, kgRoots]);

  const { hasSuggestions, hasUnacknowledgedRecipientSuggestions } =
    useMemo(() => {
      if (!dpKinds) {
        return {
          hasSuggestions: false,
          hasUnacknowledgedRecipientSuggestions: false,
        };
      }

      return {
        hasSuggestions: !isEmpty(recipientSuggestions),
        hasUnacknowledgedRecipientSuggestions:
          !isEmpty(recipientSuggestions) &&
          (recipientSuggestions || []).some(
            (s) => !acknowledgedSuggestions[s.id]
          ),
      };
    }, [acknowledgedSuggestions, dpKinds, recipientSuggestions]);

  return {
    loadingSuggestions,
    dpSuggestionKinds: dpKinds,
    recipientSuggestions,
    hasSuggestions,
    hasUnacknowledgedRecipientSuggestions,
    kgRoot,
  };
}

export function EntityDPSuggestionsProvider({ children }: PropsWithChildren) {
  const contextValue = useContextValue();

  return (
    <EntityDPSuggestionsContext.Provider value={contextValue}>
      {children}
    </EntityDPSuggestionsContext.Provider>
  );
}
