import React, { useEffect, useMemo, useState } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  getAssumptionsFromSearchParams,
  getWaterfallIdsFromSearchParams,
} from '@/modules/estateWaterfall/components/EstateWaterfallBreadcrumbNavigation/EstateWaterfallBreadcrumbNavigation.utils';
import { useWaterfallComparisonSearchParams } from '@/modules/estateWaterfall/components/EstateWaterfallBreadcrumbNavigation/hooks/useWaterfallComparisonSearchParams';
import {
  getEstateWaterfallComparisonTrowserQueryVariables,
  mapDataToEstateWaterfallComparisonTrowser,
} from '@/modules/estateWaterfall/components/EstateWaterfallComparisionTrowser/EstateWaterfallComparisonTrowser.utils';
import {
  EstateWaterfallComparisonTrowserQuery,
  useEstateWaterfallComparisonTrowserQuery,
} from '@/modules/estateWaterfall/components/EstateWaterfallComparisionTrowser/graphql/EstateWaterfallComparisonTrowser.generated';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { HouseholdDetailsProvider } from '@/modules/household/contexts/HouseholdDetails.provider';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { getEstateTaxTabs } from '@/modules/summaryPanels/EstateTaxSummaryPanel/EstateTaxSummaryPanel.utils';

import { PrintStatusSentinel } from '../components/PrintStatusSentinel';
import { PrintStatuses } from '../printOnly.constants';
import { PrintOnlyWaterfallSlide } from './PrintOnlyWaterfallSlide';

function PrintOnlyWaterfallComparisonInner() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const householdId = useRequiredParam('householdId');
  const [comparisonSearchParams] = useWaterfallComparisonSearchParams();

  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');

  const { assumptions: formAssumptions, waterfallIds } = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const assumptions = getAssumptionsFromSearchParams(searchParams);
    const waterfallIds = getWaterfallIdsFromSearchParams(searchParams);
    return { assumptions, waterfallIds };
  }, []);

  const [waterfallData, setWaterfallData] = useState<
    EstateWaterfallComparisonTrowserQuery | undefined
  >();
  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );

  const { loading, error } = useEstateWaterfallComparisonTrowserQuery({
    variables: getEstateWaterfallComparisonTrowserQueryVariables(
      waterfallIds,
      isGrowthProfilesEnabled,
      formAssumptions,
      comparisonSearchParams
    ),
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      showFeedback(FeedbackMessages.queryError);
      reportError('failed to fetch data for waterfall comparison print', err);
    },
    onCompleted: (data: EstateWaterfallComparisonTrowserQuery) => {
      setWaterfallData(data);
    },
  });

  const pagesForWaterfall = useMemo(() => {
    return getEstateTaxTabs(isTwoClientHousehold);
  }, [isTwoClientHousehold]);

  // we wrap this in a useEffect instead of doing straight computation of the status because
  // the MUI table component needs an extra pass to render properly
  useEffect(() => {
    if (loading || !waterfallData) {
      setPrintStatus(PrintStatuses.LOADING);
      return;
    }
    if (error) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    setTimeout(() => {
      setPrintStatus(PrintStatuses.READY);
      return PrintStatuses.READY;
    }, 100);
  }, [error, loading, waterfallData]);

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <PrintStatusSentinel status={printStatus} />
      {pagesForWaterfall.map((afterDeath) => {
        if (!waterfallData) return null;

        const waterfalls = mapDataToEstateWaterfallComparisonTrowser(
          waterfallData,
          afterDeath,
          waterfallIds,
          comparisonSearchParams
        );

        return (
          <PrintOnlyWaterfallSlide
            key={afterDeath}
            afterDeath={afterDeath}
            waterfalls={waterfalls}
            assumptions={formAssumptions}
          />
        );
      })}
    </HouseholdDetailsAwareRoute>
  );
}

export function PrintOnlyWaterfallComparison() {
  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      <HouseholdDetailsProvider>
        <PrintOnlyWaterfallComparisonInner />
      </HouseholdDetailsProvider>
    </InteractionParadigmContext.Provider>
  );
}
