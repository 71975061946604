import { Box } from '@mui/material';
import { chunk } from 'lodash';
import { useMemo } from 'react';

import { EntityDetail_CrtTrustFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { getCRTTrustSummaryProperties } from '@/modules/entities/details/trusts/CRTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';
import { StateCode } from '@/types/schema';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { InsurancePoliciesSlide } from '../../components/InsurancePoliciesSlide';
import { getPaginatedBeneficiariesAndDispositionsForSummary } from '../../entityPresentationPagination.utils';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { CRTTrustBeneficiariesSlide } from './CRTTrust.Beneficiaries';
import { CRTTrustFiduciariesSlide } from './CRTTrust.Fiduciaries';
import { CRTTrustOverviewSlide } from './CRTTrust.Overview';
import { CRTTrustSummarySlide } from './CRTTrust.Summary';

type CRTTrustPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_CrtTrustFragment>;

export function CRTTrustPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  dispositionScenarios,
  ...slideProps
}: CRTTrustPresentationBundleProps) {
  const isEntitySummariesEnabled = isFeatureFlagEnabled('entity_summaries');
  const { availableStateEstateTaxes } = useIRSConstants();

  const { entityId, entity } = slideProps;
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName: slideProps.subtype.displayName,
    identifier: entityId,
  });

  const grantors = useMemo(() => {
    return (
      slideProps.entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false
          : false,
      })) ?? []
    );
  }, [
    availableStateEstateTaxes,
    slideProps.entity.household.possiblePrimaryClients,
  ]);

  const summary = getCRTTrustSummaryProperties({
    entity: slideProps.entity,
    entityType: ENTITY_TYPES.CRT,
  });

  const { insurancePolicies } = summary;

  const policyPages = chunk(insurancePolicies, 2);

  const SLIDE_MAP: EntitySlideMap = useMemo(() => {
    if (!isEntitySummariesEnabled) {
      return {
        [EntityPresentationSlideType.OVERVIEW]: (
          <CRTTrustOverviewSlide
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.OVERVIEW,
              slideTypes
            )}
            {...slideProps}
          />
        ),
        [EntityPresentationSlideType.TRUST_FIDUCIARIES]: (
          <CRTTrustFiduciariesSlide
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.TRUST_FIDUCIARIES,
              slideTypes
            )}
            {...slideProps}
          />
        ),
        [EntityPresentationSlideType.BENEFICIARIES]: (
          <CRTTrustBeneficiariesSlide
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.BENEFICIARIES,
              slideTypes
            )}
            {...slideProps}
          />
        ),
        [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
          getFilteredDispositionSlideProps({
            dispositionScenarios,
            entityId,
            slideTypes,
          }).map((props, i) => (
            <DispositionScenarioSlide
              {...props}
              key={i}
              displayName={slideProps.subtype.displayName}
              entity={slideProps.entity}
              grantors={grantors}
            />
          )),
        [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
          (pagePolicies, i) => (
            <InsurancePoliciesSlide
              key={i}
              entity={slideProps.entity}
              pagePolicies={pagePolicies}
              slideIndex={i}
              numberOfPolicySlides={policyPages.length}
              displayName={slideProps.subtype.displayName}
              {...getPresentationEntitySlideProps(
                entityId,
                EntityPresentationSlideType.INSURANCE_POLICIES,
                slideTypes,
                {
                  localSlideTypeIndex: i,
                  slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
                }
              )}
            />
          )
        ),
      };
    }

    return {
      [EntityPresentationSlideType.SUMMARY]:
        getPaginatedBeneficiariesAndDispositionsForSummary({
          dispositionScenarios,
          entityId,
          slideTypes,
          beneficiaries: [],
          beneficiariesGroups: summary.beneficiariesGroups.map(
            (bg) => bg.items
          ),
          entityKind: entity.kind,
          grantors,
          description: summary.description,
        }).map((slideProps, idx) => {
          return (
            <CRTTrustSummarySlide
              key={`crt-trust-summary-slide-${idx}`}
              registrationProps={getPresentationEntitySlideProps(
                entityId,
                EntityPresentationSlideType.SUMMARY,
                slideTypes
              )}
              entity={entity}
              summary={summary}
              displayName={entity.subtype.displayName}
              grantors={grantors}
              {...slideProps}
            />
          );
        }),
      [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
        (pagePolicies, i) => (
          <InsurancePoliciesSlide
            key={i}
            entity={slideProps.entity}
            pagePolicies={pagePolicies}
            slideIndex={i}
            numberOfPolicySlides={policyPages.length}
            displayName={slideProps.subtype.displayName}
            {...getPresentationEntitySlideProps(
              entityId,
              EntityPresentationSlideType.INSURANCE_POLICIES,
              slideTypes,
              {
                localSlideTypeIndex: i,
                slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
              }
            )}
          />
        )
      ),
    };
  }, [
    dispositionScenarios,
    entity,
    entityId,
    grantors,
    isEntitySummariesEnabled,
    policyPages,
    slideProps,
    slideTypes,
    summary,
  ]);
  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
