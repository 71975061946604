import { Box, MenuItem, Stack } from '@mui/material';

import { Accordion } from '@/components/Accordion/Accordion';
import { CompoundSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import {
  CustomGlobalGrowthRateToggle,
  ExemptionGrowthRateField,
  ExemptionSunsetField,
  FirstGrantorDeathField,
  SecondGrantorDeathField,
} from '../EstateWaterfallAssumptions/EstateWaterfallAssumptionFormFields';
import {
  hasClickHandler,
  useEstateWaterfallMenuItems,
} from '../EstateWaterfallsDropdown/hooks/useEstateWaterfallMenuItems';
import { useEstateWaterfallComparisonModalContext } from './EstateWaterfallComparisonModal.context';
import {
  EstateWaterfallComparisonModalFormShape,
  EstateWaterfallComparisonModalFormShapeWaterfalls,
} from './EstateWaterfallComparisonModal.type';
import { useAccordionCalloutCopy } from './hooks/useAccordionCalloutCopy';

export interface EstateWaterfallComparisonModalWaterfallProps {
  waterfallKey: keyof EstateWaterfallComparisonModalFormShapeWaterfalls;
  label: string;
}

export function EstateWaterfallComparisonModalWaterfall({
  waterfallKey,
  label,
}: EstateWaterfallComparisonModalWaterfallProps) {
  const { control } = useFormContext<EstateWaterfallComparisonModalFormShape>();
  const { householdId, waterfallId } =
    useEstateWaterfallComparisonModalContext();

  const { loading, dropdownItems } = useEstateWaterfallMenuItems({
    householdId,
    waterfallId,
  });

  const options =
    dropdownItems?.map<CompoundSelectInputOption<string>>((item, idx) =>
      hasClickHandler(item)
        ? {
            type: 'component',
            component: (
              <MenuItem
                key={`estate-waterfall-comparison-${waterfallKey}-${idx}`}
                value={item.value}
              >
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center"
                >
                  {item.component}
                  {item.iconAfter}
                </Stack>
              </MenuItem>
            ),
            value: item.value,
          }
        : {
            type: 'component',
            component: item.component,
          }
    ) ?? [];

  return (
    <FormLayoutRow>
      <FormLayoutItem width={12}>
        <FormAwareSelectInput<EstateWaterfallComparisonModalFormShape>
          disabled={loading}
          fieldName={waterfallKey}
          label={label}
          options={options}
          control={control}
        />
      </FormLayoutItem>
    </FormLayoutRow>
  );
}

export function EstateWaterfallComparisonModalAssumptions() {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const { ILLUSTRATIVE_PURPOSES_ONLY } = useAccordionCalloutCopy();

  return (
    <FormLayoutRow>
      <FormLayoutItem width={12}>
        <Accordion
          title={
            <Stack flexDirection="row">
              Manage assumptions
              <ContextualHelpTooltip>
                <PopperContent body={ILLUSTRATIVE_PURPOSES_ONLY} />
              </ContextualHelpTooltip>
            </Stack>
          }
          variant="filled"
        >
          <FormLayoutRow>
            <FormLayoutItem width={4}>
              <FirstGrantorDeathField />
            </FormLayoutItem>
            {isTwoClientHousehold && (
              <FormLayoutItem width={4}>
                <SecondGrantorDeathField />
              </FormLayoutItem>
            )}
            <FormLayoutItem width={4}>
              <ExemptionGrowthRateField disabled={false} />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={6}>
              <ExemptionSunsetField />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <Box height={40} display="flex" alignItems="center">
                <CustomGlobalGrowthRateToggle />
              </Box>
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow></FormLayoutRow>
        </Accordion>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}

export const EstateWaterfallComparisonModalComponents = {
  Waterfall: EstateWaterfallComparisonModalWaterfall,
  Assumptions: EstateWaterfallComparisonModalAssumptions,
};
