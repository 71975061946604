import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { formatTaxId } from '@/utils/formatting/strings';

import { BusinessEntitySummaryData } from './BusinessEntityDetails.types';

export function BusinessEntityDetailsMiniTable({
  taxStatus,
  taxId,
  effectiveDate,
  formationJurisdiction,
  requiresCtaReporting,
  finCenID,
}: BusinessEntitySummaryData) {
  const rows = useMemo(() => {
    return compact([
      taxStatus && {
        label: 'Tax status',
        value: taxStatus,
      },
      taxId && {
        label: 'Tax ID (EIN)',
        value: formatTaxId(taxId),
      },
      formationJurisdiction && {
        label: 'Jurisdiction',
        value: formationJurisdiction,
      },
      effectiveDate && {
        label: 'Effective Date',
        value: formatDateToMonDDYYYY(effectiveDate),
      },
      requiresCtaReporting !== undefined && {
        label: 'Requires Corporate Transparency Act reporting',
        value: requiresCtaReporting ? 'Yes' : 'No',
      },
      finCenID && {
        label: 'FinCEN ID',
        value: finCenID,
      },
    ]);
  }, [
    effectiveDate,
    finCenID,
    formationJurisdiction,
    requiresCtaReporting,
    taxId,
    taxStatus,
  ]);

  return (
    <MiniTable
      variant="default"
      rowProps={{
        alignItems: 'start',
      }}
      rows={rows}
    />
  );
}
