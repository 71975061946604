import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import _, { compact } from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { GSTExemptionAdjustment } from '@/modules/content/tooltipContent/GSTExemptionAdjustment';
import { LifetimeExemptionAdjustment } from '@/modules/content/tooltipContent/LifetimeExemptionAdjustment';
import { RichDocumentUploaderWithList } from '@/modules/documents/RichDocumentUploaderWithList/RichDocumentUploaderWithList';
import { PrimaryClientDropdownSingle } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown';
import { getPrimaryClientOptionsFromData } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown.utils';

import { assetTypeOptions } from '../LogNewGiftModal/LogNewGiftModal.constants';
import { useRecordExemptionUsedModalFormOptionsQuery } from './graphql/RecordExemptionUsedModal.generated';
import { NAMESPACE } from './RecordExemptionUsedModal.constants';
import {
  RecordExemptionUsedForm,
  RecordExemptionUsedFormPaths,
} from './RecordExemptionUsedModal.types';

export function useFormOptions({ householdId }: { householdId: string }) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { control } = useFormContext<RecordExemptionUsedForm>();

  const { data } = useRecordExemptionUsedModalFormOptionsQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
    onError: (error) => {
      showFeedback(
        "We weren't able to load this form. Please refresh the page and try again."
      );
      reportError(
        'failed to load form options for the LogNewGiftModal',
        error,
        {
          householdId,
        }
      );
    },
  });

  const selectedGrantor = useWatch({
    name: `${NAMESPACE}.grantor` as const satisfies RecordExemptionUsedFormPaths,
    control,
  });

  const household = data?.households.edges?.[0]?.node ?? null;
  const primaryClientOptions = getPrimaryClientOptionsFromData(
    household,
    compact([selectedGrantor.clientProfileId])
  );

  return {
    primaryClientOptions,
  };
}

interface Props {
  primaryClientOptions: ReturnType<
    typeof useFormOptions
  >['primaryClientOptions'];
  householdId: string;
}

export function RecordExemptionUsedModalForm({
  primaryClientOptions,
  householdId,
}: Props) {
  const { control, setValue } = useFormContext<RecordExemptionUsedForm>();
  const client = useApolloClient();

  const documentIds = useWatch({
    name: `${NAMESPACE}.documentIds` as const satisfies RecordExemptionUsedFormPaths,
    control,
  });

  return (
    <Stack flex={1} sx={{ minHeight: 450 }}>
      <FormLayoutRow>
        <FormLayoutItem>
          <Callout severity="info-high">
            Use this modal to adjust the total lifetime or GST exemption amounts
            used to date. To track detailed information for individual gifts,
            exit this screen and select &quot;Log a new gift.&quot;
          </Callout>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <PrimaryClientDropdownSingle<RecordExemptionUsedForm>
            control={control}
            namespace={NAMESPACE}
            options={primaryClientOptions}
            labelOverride="Client"
            required
            hideGrantorContextualHelp
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareDatePickerInput<RecordExemptionUsedForm>
            control={control}
            label="As of date"
            required
            fieldName={
              `${NAMESPACE}.asOfDate` as const satisfies RecordExemptionUsedFormPaths
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareCurrencyInput<RecordExemptionUsedForm>
            isDecimalJSInput
            control={control}
            label="Lifetime exemption adjustment"
            contextualHelp={<LifetimeExemptionAdjustment />}
            required
            fieldName={
              `${NAMESPACE}.additionalLifetimeExemptionApplied` as const satisfies RecordExemptionUsedFormPaths
            }
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareCurrencyInput<RecordExemptionUsedForm>
            isDecimalJSInput
            control={control}
            label="GST exemption adjustment"
            contextualHelp={<GSTExemptionAdjustment />}
            required
            fieldName={
              `${NAMESPACE}.additionalGstExemptionApplied` as const satisfies RecordExemptionUsedFormPaths
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<RecordExemptionUsedForm>
            fieldName={
              `${NAMESPACE}.notes` as const satisfies RecordExemptionUsedFormPaths
            }
            label="Add a note"
            control={control}
            multiline
            rows={2}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareRadioGroup<RecordExemptionUsedForm>
            control={control}
            fieldName={
              `${NAMESPACE}.assetType` as const satisfies RecordExemptionUsedFormPaths
            }
            row
            options={assetTypeOptions}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <Accordion variant="filled" title="Document upload">
            <FormLayoutRow>
              <FormLayoutItem>
                <RichDocumentUploaderWithList
                  householdId={householdId}
                  hideEntityPicker
                  variant="card"
                  listDocumentsLike={
                    documentIds.length
                      ? {
                          idIn: documentIds,
                        }
                      : undefined
                  }
                  documentIds={documentIds.length ? documentIds : undefined}
                  onDocumentUploadSuccess={async (documentId) => {
                    const uniqueDocumentIds = _.uniq([
                      ...documentIds,
                      documentId,
                    ]);
                    setValue(
                      `${NAMESPACE}.documentIds` as const satisfies RecordExemptionUsedFormPaths,
                      uniqueDocumentIds
                    );

                    await client.refetchQueries({
                      updateCache(cache) {
                        cache.evict({ fieldName: 'documents' });
                        cache.gc();
                      },
                    });
                  }}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Accordion>
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}
