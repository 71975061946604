import { concat, first, isEmpty } from 'lodash';
import { useMemo } from 'react';

import {
  TriggerAIDPSuggestionsBanner,
  TriggerDPAISuggestionsVariant,
} from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/TriggerAIDPSuggestionsBanner';
import { useIsBatchAIDPWorkflowInProgress } from '@/modules/aiDP/hooks/useIsBatchAIDPWorkflowInProgress';
import { AsyncJobNotificationInfoType } from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { TRUST_ENTITY_KINDS } from '@/modules/entities/entities.constants';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useManageDispositionsContext } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/context/ManageDispositions.context';
import { AsyncJobKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

export function ManageDispositionsAISuggestionsBanner() {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const { householdId } = useHouseholdDetailsContext();
  const { getNotifications } = useAsyncJobNotificationsContext();

  const {
    inEstateEntities,
    outOfEstateEntities,
    isLoading: loadingDPContext,
  } = useManageDispositionsContext();

  // Does the household have a batch job in progress?
  const { isBatchAIDPWorkflowInProgress, loading: loadingBatchDPJobs } =
    useIsBatchAIDPWorkflowInProgress();

  // Are there any batch job notifications we're already tracking?
  const activeBatchNotifs = getNotifications(
    householdId || '',
    AsyncJobNotificationInfoType.AI_DP
  ).filter((n) => n.asyncJob?.kind === AsyncJobKind.AiDpBatched);

  // Are there any entities that are eligible for a NEW AI DP suggestions workflow?
  // They must be a Trust Entity (we'll support more in M2/M3), and have not
  // had a previous/current AIDP job.
  const unTriggeredEntities = useMemo(() => {
    return concat(inEstateEntities, outOfEstateEntities)
      .filter((e) => TRUST_ENTITY_KINDS.includes(e.kind))
      .filter((e) => !first(getNodes(e.prevAIDPJobs)));
  }, [inEstateEntities, outOfEstateEntities]);

  if (
    !aiDpEnabled ||
    loadingDPContext ||
    loadingBatchDPJobs ||
    isBatchAIDPWorkflowInProgress ||
    !isEmpty(activeBatchNotifs) ||
    isEmpty(unTriggeredEntities)
  ) {
    return null;
  }

  return (
    <TriggerAIDPSuggestionsBanner
      sx={{ mb: 3 }}
      variant={TriggerDPAISuggestionsVariant.BATCHED}
    />
  );
}
