import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, SubmitErrorHandler, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AfterDeath } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  HypotheticalSaleLoanFormKind,
  HypotheticalSaleLoanFormShape,
} from './EstateWaterfallHypotheticalSaleLoanModal.types';
import {
  getButtonText,
  mapFormDataToCreateInput,
  mapFormDataToUpdateInput,
  mapQueryDataToFormData,
} from './EstateWaterfallHypotheticalSaleLoanModal.utils';
import { useSaleLoanFormContext } from './Form/context/SaleLoanForm.context';
import { SaleLoanFormProvider } from './Form/context/SaleLoanForm.provider';
import { useActiveSaleLoanTab } from './Form/hooks/useActiveSaleLoanTab';
import { SaleLoanModalForm } from './Form/SaleLoanModalForm';
import { getFirstError } from './Form/utils/formErrors';
import {
  EstateWaterfallHypotheticalSaleLoanModalQuery,
  useCreateEstateWaterfallHypotheticalSaleLoanMutation,
  useDeleteEstateWaterfallHypotheticalSaleLoanMutation,
  useEstateWaterfallHypotheticalSaleLoanModalQuery,
  useUpdateEstateWaterfallHypotheticalSaleLoanMutation,
} from './graphql/EstateWaterfallHypotheticalSaleLoanModal.generated';
import { SaleLoanIllustrationContent } from './Illustration/SaleLoanIllustrationContent';
import { LayoutWithSidePanel } from './LayoutWithSidePanel';

export interface EstateWaterfallHypotheticalSaleLoanModalProps {
  isOpen: boolean;
  waterfallId: string;
  householdId: string;
  // saleLoanId is null if we are creating a new hypothetical sale loan
  saleLoanId: string | null;
  // initialKind is null if we the sale/loan already exists
  initialKind: HypotheticalSaleLoanFormKind | null;
  onClose: () => void;
}

function EstateWaterfallHypotheticalSaleLoanModalInner({
  isOpen,
  onClose: onCloseExternal,
  waterfallId,
  householdId,
  saleLoanId,
  initialKind,
}: EstateWaterfallHypotheticalSaleLoanModalProps) {
  const { activeTab, setActiveTab, clearTab } = useActiveSaleLoanTab();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<HypotheticalSaleLoanFormShape>();

  const kind = useWatch({ control, name: '_kind' });

  const handleClose = useCallback(() => {
    // always reset to the basic information tab when closing the modal, so that when the
    // modal is opened again to make a new hypothetical sale loan, the user is
    // on the first tab
    clearTab();
    onCloseExternal();
  }, [onCloseExternal, clearTab]);

  const { saleLoanFundingId } = useSaleLoanFormContext();
  const [updateHypotheticalSaleLoan] =
    useUpdateEstateWaterfallHypotheticalSaleLoanMutation();
  const [createHypotheticalSaleLoan] =
    useCreateEstateWaterfallHypotheticalSaleLoanMutation();
  const [deleteHypotheticalSaleLoan, { loading: deleteLoading }] =
    useDeleteEstateWaterfallHypotheticalSaleLoanMutation({
      onCompleted: () => {
        showFeedback(`Hypothetical ${kind} deleted`, {
          variant: 'success',
        });
        handleClose();
      },
      onError: (error) => {
        showFeedback(`Failed to delete hypothetical ${kind}`);
        reportError(`Failed to delete hypothetical ${kind}`, error);
      },
    });
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const onValidSubmit = (data: HypotheticalSaleLoanFormShape) => {
    // if we have a saleLoanId, we are updating an existing hypothetical sale loan
    if (saleLoanId) {
      if (!saleLoanFundingId) {
        throw new Error('saleLoanFundingId is required');
      }

      return updateHypotheticalSaleLoan({
        variables: {
          input: mapFormDataToUpdateInput(data, saleLoanId, saleLoanFundingId),
        },
        onCompleted: () => {
          showFeedback(`Hypothetical ${kind} updated`, {
            variant: 'success',
          });
        },
        onError: (error) => {
          showFeedback(`Failed to update hypothetical ${kind}`);
          reportError(`Failed to update hypothetical ${kind}`, error);
        },
      });
    }

    if (!initialKind) {
      throw new Error('initialKind is required in a creation scenario');
    }

    return createHypotheticalSaleLoan({
      variables: {
        input: mapFormDataToCreateInput(
          data,
          initialKind,
          waterfallId,
          householdId
        ),
      },
      onCompleted: () => {
        showFeedback(`Hypothetical ${initialKind} created`, {
          variant: 'success',
        });
        handleClose();
      },
      onError: (error) => {
        showFeedback(`Failed to create hypothetical ${initialKind}`);
        reportError(`Failed to create hypothetical ${initialKind}`, error);
      },
    });
  };

  // When there's an invalid form submission, we want to focus the user on the tab
  // that has the error so that they can fix it.
  const onInvalidSubmit: SubmitErrorHandler<HypotheticalSaleLoanFormShape> = (
    errors
  ) => {
    const errorDetails = getFirstError(errors, activeTab);
    if (!errorDetails) {
      diagnostics.error('onInvalidSubmit called with no error details');
      return;
    }

    if (errorDetails && errorDetails.tabName !== activeTab) {
      setActiveTab(errorDetails.tabName);
    }
    diagnostics.debug('Form validation failed', { errors, errorDetails });
  };

  const onSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);
  const isCreating = !saleLoanId;

  return (
    <FullScreenModal isOpen={isOpen}>
      {isOpen && (
        <Stack component="form" noValidate onSubmit={onSubmit}>
          <LayoutWithSidePanel
            MainContent={
              <SaleLoanIllustrationContent
                waterfallId={waterfallId}
                householdId={householdId}
                saleLoanId={saleLoanId}
                modalKind={initialKind ?? 'sale'}
              />
            }
            SidebarContent={<SaleLoanModalForm />}
            Footer={
              <Footer
                leftAction={
                  !isCreating ? (
                    <DeleteButton
                      loading={deleteLoading}
                      onConfirmDelete={() => {
                        void deleteHypotheticalSaleLoan({
                          variables: {
                            hslId: saleLoanId,
                          },
                        });
                      }}
                      confirmDeleteText={`Delete this ${kind.toLowerCase()}`}
                    />
                  ) : undefined
                }
                rightAction={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={handleClose}
                      >
                        {isCreating ? 'Cancel' : 'Close'}
                      </Button>
                      <Button
                        loading={isSubmitting}
                        size="sm"
                        variant="primary"
                        onClick={onSubmit}
                      >
                        {getButtonText({ kind, isCreating })}
                      </Button>
                    </Stack>
                  </Stack>
                }
              />
            }
          />
        </Stack>
      )}
    </FullScreenModal>
  );
}

export function EstateWaterfallHypotheticalSaleLoanModal(
  props: EstateWaterfallHypotheticalSaleLoanModalProps
) {
  const defaultValues: HypotheticalSaleLoanFormShape = {
    // initialKind is null if we the sale/loan already exists, and in that scenario
    // we're going to the default values are going to be overwritten by existing data
    // so we don't need to set it here
    displayName: props.initialKind
      ? `Untitled ${props.initialKind.toLowerCase()}`
      : '',
    _kind: props.initialKind ?? 'sale',
    pretermGrantorDeathHandling: 'repaid',
    applicableInterestRate: null,
    recipientId: '',
    startDate: new Date(),
    termLength: null,
    sellerId: '',
    fundingKind: 'proRata',
    proRataPattern: 'amount',
    proRataAmount: null,
    proRataPercent: null,
    assetsPattern: 'amount',
    paymentModel: 'interestOnlyWithBalloon',
    selectedAssetClassesAndBusinessIds: [],
    illustrationScenario: AfterDeath.First,
    scheduleDisplayType: 'paymentSchedule',
    selectedAssetClassAndBusinessValues: {},
  };
  const formMethods = useForm<HypotheticalSaleLoanFormShape>({
    defaultValues,
  });

  const { reset } = formMethods;

  const syncDataToForm = useCallback(
    (data: EstateWaterfallHypotheticalSaleLoanModalQuery) => {
      const formData = mapQueryDataToFormData(data);
      if (formData) {
        reset(formData);
      }
    },
    [reset]
  );

  const { loading: isLoadingDefaultValues, data } =
    useEstateWaterfallHypotheticalSaleLoanModalQuery({
      variables: {
        waterfallId: props.waterfallId,
        hypotheticalSaleLoanId: props.saleLoanId,
        hasExistingHypotheticalSaleLoan: Boolean(props.saleLoanId),
      },
      onCompleted: syncDataToForm,
    });

  const saleLoanObject =
    data?.estateWaterfalls?.edges?.[0]?.node?.hypotheticalSaleLoans?.edges?.[0]
      ?.node;
  return (
    <FormProvider {...formMethods}>
      <SaleLoanFormProvider
        loading={isLoadingDefaultValues}
        waterfallId={props.waterfallId}
        saleLoanId={props.saleLoanId}
        growthProfileId={
          data?.estateWaterfalls?.edges?.[0]?.node?.growthProfile?.id ?? null
        }
        waterfall={data?.estateWaterfalls?.edges?.[0]?.node ?? null}
        saleLoanFundingId={saleLoanObject?.funding?.id ?? null}
        householdId={props.householdId}
      >
        <EstateWaterfallHypotheticalSaleLoanModalInner {...props} />
      </SaleLoanFormProvider>
    </FormProvider>
  );
}
