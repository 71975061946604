import { compact, invert } from 'lodash';

import {
  AfterDeath,
  AugmentedCreateHypotheticalSaleLoanInput,
  AugmentedCreateHypotheticalTransferAssetInput,
  AugmentedUpdateHypotheticalSaleLoanInput,
  HypotheticalSaleLoanKind,
  HypotheticalSaleLoanPaymentModel,
  HypotheticalSaleLoanPretermGrantorDeathHandling,
  HypotheticalSaleLoanRecipientKind,
  HypotheticalSaleLoanSourceKind,
  HypotheticalTransferAssetKind,
  HypotheticalTransferFundingSourceKind,
} from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import {
  HypotheticalSaleLoanFormFundingKind,
  HypotheticalSaleLoanFormKind,
  HypotheticalSaleLoanFormPaymentModel,
  HypotheticalSaleLoanFormShape,
} from './EstateWaterfallHypotheticalSaleLoanModal.types';
import { EstateWaterfallHypotheticalSaleLoanModalQuery } from './graphql/EstateWaterfallHypotheticalSaleLoanModal.generated';

export function getButtonText({
  kind,
  isCreating,
}: {
  kind: HypotheticalSaleLoanFormKind;
  isCreating: boolean;
}) {
  const noun = kind === 'sale' ? 'installment sale' : 'loan';

  if (isCreating) {
    return `Create ${noun}`;
  }

  return `Save changes`;
}

const getFormFundingKindFromAPI = (
  kind: HypotheticalTransferFundingSourceKind | undefined
): HypotheticalSaleLoanFormFundingKind | null => {
  switch (kind) {
    case HypotheticalTransferFundingSourceKind.ProRataAmount:
      return 'proRata';
    case HypotheticalTransferFundingSourceKind.ProRataPercentage:
      return 'proRata';
    case HypotheticalTransferFundingSourceKind.SpecificAssetsAmount:
      return 'assets';
    case HypotheticalTransferFundingSourceKind.SpecificAssetsPercentage:
      return 'assets';
    default:
      return null;
  }
};

const paymentModelAPIMap: Record<
  HypotheticalSaleLoanPaymentModel,
  HypotheticalSaleLoanFormPaymentModel
> = {
  [HypotheticalSaleLoanPaymentModel.Amortizing]: 'amortizing',
  [HypotheticalSaleLoanPaymentModel.InterestOnlyWithBalloon]:
    'interestOnlyWithBalloon',
  [HypotheticalSaleLoanPaymentModel.LumpSum]: 'lumpSum',
};

const getFormPaymentModel = (
  model: HypotheticalSaleLoanPaymentModel | undefined
): HypotheticalSaleLoanFormPaymentModel | null => {
  if (model && model in paymentModelAPIMap) {
    return paymentModelAPIMap[model];
  }

  return null;
};

const getAPIPaymentModel = (
  model: HypotheticalSaleLoanFormPaymentModel | null
): HypotheticalSaleLoanPaymentModel => {
  const inversePaymentModelAPIMap = invert(paymentModelAPIMap) as Record<
    HypotheticalSaleLoanFormPaymentModel,
    HypotheticalSaleLoanPaymentModel
  >;
  if (model && model in inversePaymentModelAPIMap) {
    return inversePaymentModelAPIMap[model];
  }
  throw new Error(`Unknown payment model: ${model}`);
};

export const mapQueryDataToFormData = (
  data: EstateWaterfallHypotheticalSaleLoanModalQuery
): HypotheticalSaleLoanFormShape | undefined => {
  const existingLoan =
    data.estateWaterfalls.edges?.[0]?.node?.hypotheticalSaleLoans?.edges?.[0]
      ?.node;

  if (!existingLoan) {
    return undefined;
  }

  return {
    // default to the term end year
    _kind:
      existingLoan.kind === HypotheticalSaleLoanKind.Sale ? 'sale' : 'loan',
    illustrationScenario: AfterDeath.First,
    scheduleDisplayType: 'paymentSchedule',
    displayName: existingLoan.displayName,
    applicableInterestRate: existingLoan.afrRate,
    recipientId:
      existingLoan.recipientEntity?.id ||
      existingLoan.recipientIndividual?.id ||
      '',
    startDate: existingLoan.startDate,
    termLength: existingLoan.termLengthYears,
    sellerId:
      existingLoan.sourceEntity?.id || existingLoan.sourceIndividual?.id || '',
    fundingKind: getFormFundingKindFromAPI(existingLoan.funding?.kind),
    proRataPattern: existingLoan.funding?.proRataAmount ? 'amount' : 'percent',
    proRataAmount: existingLoan.funding?.proRataAmount ?? null,
    proRataPercent: existingLoan.funding?.proRataPercentage ?? null,
    pretermGrantorDeathHandling:
      existingLoan.pretermGrantorDeathHandling ===
      HypotheticalSaleLoanPretermGrantorDeathHandling.Repayment
        ? 'repaid'
        : 'canceled',
    assetsPattern:
      existingLoan.funding?.kind ===
      HypotheticalTransferFundingSourceKind.SpecificAssetsPercentage
        ? 'percent'
        : 'amount',
    selectedAssetClassesAndBusinessIds: compact(
      existingLoan.funding?.assets.edges?.map(
        (edge) => edge?.node?.assetClass?.id || edge?.node?.ownedBusiness?.id
      )
    ),
    selectedAssetClassAndBusinessValues: {},
    paymentModel: getFormPaymentModel(existingLoan.paymentModel),
  };
};

function getFundingKind(
  formData: HypotheticalSaleLoanFormShape
): HypotheticalTransferFundingSourceKind {
  if (formData.fundingKind === 'proRata') {
    if (formData.proRataPattern === 'amount') {
      return HypotheticalTransferFundingSourceKind.ProRataAmount;
    } else if (formData.proRataPattern === 'percent') {
      return HypotheticalTransferFundingSourceKind.ProRataPercentage;
    } else {
      throw new Error(`Unhandled proRata pattern: ${formData.proRataPattern}`);
    }
  }

  if (formData.fundingKind === 'assets') {
    if (formData.assetsPattern === 'amount') {
      return HypotheticalTransferFundingSourceKind.SpecificAssetsAmount;
    } else if (formData.assetsPattern === 'percent') {
      return HypotheticalTransferFundingSourceKind.SpecificAssetsPercentage;
    } else {
      throw new Error(`Unhandled assets pattern: ${formData.assetsPattern}`);
    }
  }

  throw new Error(`Unhandled funding kind: ${formData.fundingKind}`);
}

const getRecipientKind = (recipientId: string) => {
  return getPulidKind(recipientId) === PulidKind.Entity
    ? HypotheticalSaleLoanRecipientKind.Entity
    : HypotheticalSaleLoanRecipientKind.Individual;
};

const getSourceKind = (sellerId: string) => {
  return getPulidKind(sellerId) === PulidKind.Entity
    ? HypotheticalSaleLoanSourceKind.Entity
    : HypotheticalSaleLoanSourceKind.Individual;
};

const mapFormDataToAssetInputs = (
  formData: HypotheticalSaleLoanFormShape
): AugmentedCreateHypotheticalTransferAssetInput[] => {
  if (formData.fundingKind !== 'assets') {
    return [];
  }

  return formData.selectedAssetClassesAndBusinessIds.map((id) => {
    const value = formData.selectedAssetClassAndBusinessValues[id];
    if (!value) {
      throw new Error(`Missing value for asset/business ${id}`);
    }

    const kind =
      getPulidKind(id) === PulidKind.AssetClass
        ? HypotheticalTransferAssetKind.AssetClass
        : HypotheticalTransferAssetKind.BusinessOwnership;

    const createAssetInput: AugmentedCreateHypotheticalTransferAssetInput = {
      create: {
        kind,
        assetClassID:
          kind === HypotheticalTransferAssetKind.AssetClass ? id : undefined,
        ownedBusinessID:
          kind === HypotheticalTransferAssetKind.BusinessOwnership
            ? id
            : undefined,
        valueAmount:
          formData.assetsPattern === 'amount' ? value.amount ?? null : null,
        valuePercentage:
          formData.assetsPattern === 'percent' ? value.percent ?? null : null,
      },
    };

    return createAssetInput;
  });
};

export const mapFormDataToCreateInput = (
  formData: HypotheticalSaleLoanFormShape,
  modalKind: HypotheticalSaleLoanFormKind,
  waterfallId: string,
  householdId: string
): AugmentedCreateHypotheticalSaleLoanInput => {
  if (!formData.startDate || !formData.termLength) {
    throw new Error('Start date and term length are required');
  }

  if (!formData.applicableInterestRate) {
    throw new Error('Applicable interest rate is required');
  }

  if (!formData.paymentModel) {
    throw new Error('Payment model is required');
  }

  const recipientKind = getRecipientKind(formData.recipientId);
  const sourceKind = getSourceKind(formData.sellerId);
  const fundingKind = getFundingKind(formData);
  const input: AugmentedCreateHypotheticalSaleLoanInput = {
    create: {
      householdID: householdId,
      hypotheticalWaterfallID: waterfallId,
      kind:
        modalKind === 'sale'
          ? HypotheticalSaleLoanKind.Sale
          : HypotheticalSaleLoanKind.Loan,
      displayName: formData.displayName,
      pretermGrantorDeathHandling:
        formData.pretermGrantorDeathHandling === 'repaid'
          ? HypotheticalSaleLoanPretermGrantorDeathHandling.Repayment
          : HypotheticalSaleLoanPretermGrantorDeathHandling.Cancellation,
      sourceKind,
      sourceIndividualID:
        sourceKind === HypotheticalSaleLoanSourceKind.Individual
          ? formData.sellerId
          : undefined,
      sourceEntityID:
        sourceKind === HypotheticalSaleLoanSourceKind.Entity
          ? formData.sellerId
          : undefined,
      recipientKind,
      recipientIndividualID:
        recipientKind === HypotheticalSaleLoanRecipientKind.Individual
          ? formData.recipientId
          : undefined,
      recipientEntityID:
        recipientKind === HypotheticalSaleLoanRecipientKind.Entity
          ? formData.recipientId
          : undefined,
      startDate: formData.startDate,
      termLengthYears: formData.termLength,
      afrRate: formData.applicableInterestRate,
      paymentModel: getAPIPaymentModel(formData.paymentModel),
    },
    withFunding: {
      create: {
        kind: fundingKind,
        proRataAmount:
          fundingKind === HypotheticalTransferFundingSourceKind.ProRataAmount
            ? formData.proRataAmount
            : null,
        proRataPercentage:
          fundingKind ===
          HypotheticalTransferFundingSourceKind.ProRataPercentage
            ? formData.proRataPercent
            : null,
      },
      withAssets: mapFormDataToAssetInputs(formData),
    },
  };

  return input;
};

export const mapFormDataToUpdateInput = (
  formData: HypotheticalSaleLoanFormShape,
  hypotheticalSaleLoanId: string,
  hypotheticalSaleLoanFundingId: string
): AugmentedUpdateHypotheticalSaleLoanInput => {
  const recipientKind = getRecipientKind(formData.recipientId);
  const sourceKind = getSourceKind(formData.sellerId);
  const fundingKind = getFundingKind(formData);

  const input: AugmentedUpdateHypotheticalSaleLoanInput = {
    id: hypotheticalSaleLoanId,
    update: {
      displayName: formData.displayName,
      afrRate: formData.applicableInterestRate,
      startDate: formData.startDate,
      termLengthYears: formData.termLength,
      pretermGrantorDeathHandling:
        formData.pretermGrantorDeathHandling === 'repaid'
          ? HypotheticalSaleLoanPretermGrantorDeathHandling.Repayment
          : HypotheticalSaleLoanPretermGrantorDeathHandling.Cancellation,
      paymentModel: getAPIPaymentModel(formData.paymentModel),
      sourceKind,
      sourceIndividualID:
        sourceKind === HypotheticalSaleLoanSourceKind.Individual
          ? formData.sellerId
          : undefined,
      sourceEntityID:
        sourceKind === HypotheticalSaleLoanSourceKind.Entity
          ? formData.sellerId
          : undefined,
      recipientKind: recipientKind,
      recipientIndividualID:
        recipientKind === HypotheticalSaleLoanRecipientKind.Individual
          ? formData.recipientId
          : undefined,
      recipientEntityID:
        recipientKind === HypotheticalSaleLoanRecipientKind.Entity
          ? formData.recipientId
          : undefined,
    },
    updateFunding: {
      id: hypotheticalSaleLoanFundingId,
      update: {
        kind: fundingKind,
        clearAssets: true,
        proRataAmount:
          fundingKind === HypotheticalTransferFundingSourceKind.ProRataAmount
            ? formData.proRataAmount
            : null,
        proRataPercentage:
          fundingKind ===
          HypotheticalTransferFundingSourceKind.ProRataPercentage
            ? formData.proRataPercent
            : null,
        clearProRataAmount:
          fundingKind !== HypotheticalTransferFundingSourceKind.ProRataAmount,
        clearProRataPercentage:
          fundingKind !==
          HypotheticalTransferFundingSourceKind.ProRataPercentage,
      },
      withAssets: mapFormDataToAssetInputs(formData),
    },
  };

  return input;
};
