import { isEmpty } from 'lodash';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  AiSuggestionsMatcherContext,
  getDefaultMatcherConfigs,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import { useUpdateSubformArrayField } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformArrayField';
import { useUpdateSubformSingleField } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformSingleField';
import { getConfigsForAISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/matcherVariant.configs';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { AiSuggestionAcceptanceStatus } from '@/types/schema';

function useContextValue(): AiSuggestionsMatcherContext {
  const matcherRef = useRef<HTMLDivElement>(null);

  const { entityType } = useEntityDetailsContext();
  const { acknowledgeSuggestion } = useEntitySuggestionsContext();

  const { updateArrayFields, appendArrayFields } = useUpdateSubformArrayField();
  const { updateSingleFields } = useUpdateSubformSingleField();

  const [matcherVariant, setMatcherVariant] =
    useState<AiSuggestionsMatcherContext['matcherVariant']>(null);

  const [matchedSuggestions, setMatchedSuggestions] = useState<
    AiSuggestionsMatcherContext['matchedSuggestions']
  >({});

  useEffect(() => {
    // Reset the matched suggestions when the matcher variant changes.
    setMatchedSuggestions({});
  }, [setMatchedSuggestions, matcherVariant]);

  const matcherConfigs = useMemo(
    () =>
      matcherVariant
        ? getConfigsForAISuggestionsMatcherVariant(entityType, matcherVariant)
        : getDefaultMatcherConfigs(),
    [entityType, matcherVariant]
  );

  const commitMatchedSuggestions = useCallback(() => {
    if (isEmpty(matchedSuggestions) || !matcherVariant || !matcherConfigs) {
      return;
    }

    const {
      subformSingleFieldUpdateProps,
      subformArrayFieldUpdateProps,
      subformArrayFieldOnConflict,
    } = matcherConfigs;

    if (matcherVariant === AISuggestionsMatcherVariant.SINGLE_GRANTOR) {
      if (!subformSingleFieldUpdateProps) {
        throw new Error(
          'subformSingleFieldUpdateProps is required for SINGLE_GRANTOR matcher variant'
        );
      }
      const { singleFieldFullPath, mapperFunc, idField } =
        subformSingleFieldUpdateProps;
      updateSingleFields({
        singleFieldFullPath,
        newInput: mapperFunc(Object.values(matchedSuggestions)),
        idField,
      });
    } else {
      // Update the array fields of the current subform with the matched
      // grantors/trustees/successorTrustees/trustAdvisors/beneficiaries.
      const updateProps = subformArrayFieldUpdateProps.map((f) => ({
        arrayFieldFullPath: f.arrayFieldFullPath,
        newInputs: f.mapperFunc(Object.values(matchedSuggestions)),
        idField: f.idField,
      }));

      if (subformArrayFieldOnConflict === 'overwrite') {
        updateArrayFields(updateProps);
      } else {
        appendArrayFields(updateProps);
      }
    }

    // Set the acceptance status of the matched suggestions
    Object.values(matchedSuggestions).forEach((match) => {
      if (
        match.clientProfileId ||
        match.clientOrganizationId ||
        match.entityId ||
        match.testamentaryId
      ) {
        // Count this suggestion as accepted, since it got matched to a profile/org/entity.
        acknowledgeSuggestion({
          suggestionID: match.suggestion.id,
          status: AiSuggestionAcceptanceStatus.Accepted,
          clientProfileId: match.clientProfileId,
          clientOrganizationId: match.clientOrganizationId,
          entityId: match.entityId,
          testamentaryEntityId: match.testamentaryId,
        });
      } else {
        // Count this suggestion as rejected, since it got "ignored".
        acknowledgeSuggestion({
          suggestionID: match.suggestion.id,
          status: AiSuggestionAcceptanceStatus.Rejected,
        });
      }
    });

    // Clear the matched suggestions that we committed.
    setMatchedSuggestions({});
  }, [
    acknowledgeSuggestion,
    appendArrayFields,
    matchedSuggestions,
    matcherConfigs,
    matcherVariant,
    updateArrayFields,
    updateSingleFields,
  ]);

  return {
    matcherVariant,
    setMatcherVariant,
    matchedSuggestions,
    setMatchedSuggestions,
    commitMatchedSuggestions,
    matcherRef,
    matcherConfigs,
  };
}

export function AISuggestionsMatcherProvider({ children }: PropsWithChildren) {
  const contextValue = useContextValue();

  return (
    <AiSuggestionsMatcherContext.Provider value={contextValue}>
      {children}
    </AiSuggestionsMatcherContext.Provider>
  );
}
