import { Grid, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import Decimal from 'decimal.js';
import { useId, useRef, useState } from 'react';
import {
  FormProvider,
  Path,
  SubmitHandler,
  useFieldArray,
  UseFieldArrayInsert,
  UseFieldArrayMove,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { SplitButton } from '@/components/form/baseInputs/SplitButton/SplitButton';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { PresentationChart01Icon } from '@/components/icons/PresentationChart01Icon';
import { Settings03Icon } from '@/components/icons/Settings03Icon';
import { Card } from '@/components/layout/Card/Card';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  GiftBenefitsCard,
  GiftDesignerModelScenarios,
  GiftDesignerModelScenariosFormShape,
  GiftScenarioBarCharts,
  GiftScenarios,
  GiftScenarioTable,
  MAX_GIFT_SCENARIOS_COUNT,
  useGiftDesignerModelScenariosDefaultValues,
} from '@/modules/gifting/proposal/designer/form';
import { TaxReturnLimitModal } from '@/modules/gifting/proposal/designer/form/components/TaxReturnLimitModal/TaxReturnLimitModal';
import { GiftDesignerModelScenariosProvider } from '@/modules/gifting/proposal/designer/form/contexts/GiftDesignerModelScenarios.provider';
import { ShareProposalAction } from '@/modules/proposal/share/ShareProposalAction/ShareProposalAction';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useGetGiftProjectedWealth } from '@/pages/client-proposal/hooks/useGetGiftProjectedWealth';
import { COLORS } from '@/styles/tokens/colors';

import { AddItemHeaderRow } from './GiftDesignerBasicInformationPage';
import { useGiftDesignerModelScenariosPageModals } from './hooks/useGiftDesignerModelScenariosPageModals';
import {
  SaveGiftDesignerInputKind,
  useSaveGiftDesigner,
} from './hooks/useSaveGiftDesigner';
import { useSaveGiftDesignerOnScenarioRemoved } from './hooks/useSaveGiftDesignerOnScenarioRemoved';

const GRID_GAP = 3 as const;
const LEFT_COLUMN_WIDTH = 4 as const;
const RIGHT_COLUMN_WIDTH = 8 as const;
const TOTAL_COLUMNS = LEFT_COLUMN_WIDTH + RIGHT_COLUMN_WIDTH;

interface GiftDesignerModelScenariosPageFormInnerProps {
  householdId: string;
  proposalId: string;
}

function GiftDesignerModelScenariosPageFormInner({
  householdId,
  proposalId,
}: GiftDesignerModelScenariosPageFormInnerProps) {
  const navigate = useNavigate();
  const saveButtonId = useId();
  const [columnWidths, setColumnWidths] = useState<number[]>([]);

  const shareAnalysisButtonRef = useRef<HTMLDivElement>(null);

  const {
    isTaxReturnLimitModalOpen,
    openTaxReturnLimitModal,
    closeTaxReturnLimitModal,
  } = useGiftDesignerModelScenariosPageModals();

  const { handleSubmit, control, formState, setValue } =
    useFormContext<GiftDesignerModelScenariosFormShape>();

  const giftingProposalId = useWatch({
    control,
    name: '_giftProposalId' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
  });

  const { projections } = useGetGiftProjectedWealth({
    giftingProposalId,
  });

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
  });

  const giftScenarioFieldMethods = useFieldArray({
    control,
    name: 'scenarios' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
    keyName: '_id',
    rules: { maxLength: MAX_GIFT_SCENARIOS_COUNT },
  });

  const { saveGiftDesigner } = useSaveGiftDesigner({
    proposalId,
    householdId,
  });

  // Signals to openModalOnFinalizeSubmitSuccess effect that we should open the modal
  // if we are submitting the finalize form
  const saveRef = useRef<
    | SaveGiftDesignerInputKind.SaveModelScenarios
    | SaveGiftDesignerInputKind.CopySecureLink
  >();

  const onValidSubmissionCopySecureLink: SubmitHandler<
    GiftDesignerModelScenariosFormShape
  > = (formValues) => {
    saveRef.current = SaveGiftDesignerInputKind.CopySecureLink;
    return saveGiftDesigner({
      kind: SaveGiftDesignerInputKind.CopySecureLink,
      formValues,
    });
  };

  const onValidSubmissionSaveModelScenarios: SubmitHandler<
    GiftDesignerModelScenariosFormShape
  > = (formValues) => {
    saveRef.current = SaveGiftDesignerInputKind.SaveModelScenarios;
    return saveGiftDesigner({
      kind: SaveGiftDesignerInputKind.SaveModelScenarios,
      formValues,
    });
  };

  const onSubmitSaveModelScenarios = handleSubmit(
    onValidSubmissionSaveModelScenarios
  );
  const onSubmitCopySecureLink = handleSubmit(onValidSubmissionCopySecureLink);

  useSaveGiftDesignerOnScenarioRemoved(onSubmitSaveModelScenarios);

  const handleMove: UseFieldArrayMove = (indexA, indexB) => {
    giftScenarioFieldMethods.move(indexA, indexB);

    void onSubmitSaveModelScenarios();
  };

  const handleDuplicateScenario: UseFieldArrayInsert<
    GiftDesignerModelScenariosFormShape,
    'scenarios'
  > = (index, newScenario) => {
    giftScenarioFieldMethods.insert(index, newScenario);

    void onSubmitSaveModelScenarios();
  };

  return (
    <>
      <TaxReturnLimitModal
        isOpen={isTaxReturnLimitModalOpen}
        onClose={() => {
          closeTaxReturnLimitModal();
        }}
        onSubmitSuccess={async (formData) => {
          setValue('taxReturnLimits', formData);

          await onSubmitSaveModelScenarios();
          closeTaxReturnLimitModal();
        }}
      />
      <Stack
        component="form"
        noValidate
        onSubmit={onSubmitSaveModelScenarios}
        overflow="hidden"
        height={`calc(100% - ${FOOTER_HEIGHT}px)`}
      >
        <Stack p={3} spacing={5} height="100%" overflow="auto">
          <Grid container columns={TOTAL_COLUMNS}>
            <Grid
              item
              sm={LEFT_COLUMN_WIDTH}
              sx={{
                pr: GRID_GAP,
              }}
            >
              <Stack spacing={3}>
                <AddItemHeaderRow>
                  <Typography variant="h1" component="h2" height={40}>
                    Scenarios
                  </Typography>
                </AddItemHeaderRow>
                <GiftScenarios
                  {...giftScenarioFieldMethods}
                  move={handleMove}
                  duplicate={handleDuplicateScenario}
                  triggerSave={onSubmitSaveModelScenarios}
                  proposalId={proposalId}
                  householdId={householdId}
                />
              </Stack>
            </Grid>
            <Grid item sm={RIGHT_COLUMN_WIDTH}>
              <Stack
                spacing={3}
                component="form"
                noValidate
                onSubmit={onSubmitSaveModelScenarios}
              >
                <AddItemHeaderRow>
                  <Typography variant="h1" component="h2">
                    Illustration
                  </Typography>
                  <Button
                    variant="secondary"
                    size="sm"
                    startIcon={Settings03Icon}
                    onClick={openTaxReturnLimitModal}
                  >
                    Return & tax assumptions
                  </Button>
                </AddItemHeaderRow>
                <Card
                  variant="filled"
                  sx={{
                    p: 3,
                    borderRadius: '6px',
                    bgcolor: COLORS.GRAY[200],
                  }}
                >
                  <Stack spacing={2}>
                    <Stack
                      direction="row"
                      gap={3}
                      rowGap={1}
                      width="100%"
                      flexWrap="wrap"
                    >
                      <GiftDesignerModelScenarios.YearOfAnalysis
                        lengthOfAnalysis={lengthOfAnalysis || new Decimal(0)}
                      />
                      <Stack minWidth={200}>
                        <GiftDesignerModelScenarios.AnnualPreTaxReturn />
                      </Stack>
                      <Stack pt={4}>
                        <GiftDesignerModelScenarios.ShowAfterEstateTax />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      gap={3}
                      rowGap={1}
                      width="100%"
                      flexWrap="wrap"
                    >
                      <Stack maxWidth={150}>
                        <GiftDesignerModelScenarios.ExemptionGrowth />
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    p: 3,
                    borderRadius: '6px',
                  }}
                >
                  <Stack
                    spacing={3}
                    sx={{
                      '& > :last-child': {
                        marginTop: 6,
                      },
                    }}
                  >
                    <GiftScenarioBarCharts
                      proposalId={proposalId}
                      projections={projections}
                    />
                    <GiftBenefitsCard
                      proposalId={proposalId}
                      projections={projections}
                      gridTemplateColumns={
                        columnWidths.length
                          ? columnWidths
                              // Account for the margin differences between the table and the card
                              .map((w) => `${w - 64 / columnWidths.length}px`)
                              .join(' ')
                          : undefined
                      }
                    />
                    <GiftScenarioTable
                      proposalId={proposalId}
                      setColumnWidths={setColumnWidths}
                      projections={projections}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Footer
          leftAction={
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                    {
                      householdId,
                    }
                  )
                );
              }}
            >
              Cancel
            </Button>
          }
          rightAction={
            <Stack direction="row" spacing={2}>
              <Button
                size="sm"
                variant="secondary"
                data-testid="gift-designer-previous"
                onClick={() => {
                  navigate(
                    getCompletePathFromRouteKey(
                      ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
                      {
                        householdId,
                        proposalId,
                        designerStage: GiftDesignerStages.BASIC_INFORMATION,
                      }
                    )
                  );
                }}
              >
                Previous
              </Button>
              <SplitButton
                htmlId={saveButtonId}
                onClick={onSubmitSaveModelScenarios}
                loading={formState.isSubmitting}
                buttonContent="Save changes"
                mainButtonTestId="gift-designer-save-changes"
                caretButtonTestId="gift-designer-save-changes-options"
                size="sm"
                variant="primary"
                items={[
                  {
                    menuItemSx: {
                      // Remove the default padding so clicks are intercepted by the
                      // ShareProposalAction component
                      p: 0,
                    },
                    component: (
                      <ShareProposalAction
                        proposalId={proposalId}
                        ActionComponent={({ onClick }) => (
                          <Stack
                            ref={shareAnalysisButtonRef}
                            role="button"
                            data-testid="gift-designer-share-analysis"
                            onClick={async (e) => {
                              await onSubmitCopySecureLink();
                              // Copy link to clipboard
                              void onClick(e);
                            }}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{
                              // Match the menu item padding
                              py: '10px',
                              px: '14px',
                            }}
                          >
                            <Link03Icon size={20} />
                            <Typography variant="body1">
                              Copy share link
                            </Typography>
                          </Stack>
                        )}
                      />
                    ),
                  },
                  {
                    component: (
                      <Stack
                        role="button"
                        data-testid="gift-designer-view-proposal"
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <PresentationChart01Icon size={20} />
                        <Typography variant="body1">View proposal</Typography>
                      </Stack>
                    ),
                    clickHandler: () => {
                      window.open(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
                          { proposalId },
                          {
                            preview: true,
                            householdId,
                          }
                        ),
                        '_blank'
                      );
                    },
                  },
                ]}
              />
            </Stack>
          }
        />
      </Stack>
    </>
  );
}

interface GiftDesignerModelScenariosPageFormProps {
  householdId: string;
  proposalId: string;
}

function GiftDesignerModelScenariosPageForm({
  householdId,
  proposalId,
}: GiftDesignerModelScenariosPageFormProps) {
  const { getDefaultValues } = useGiftDesignerModelScenariosDefaultValues({
    proposalId,
  });

  const formMethods = useForm<GiftDesignerModelScenariosFormShape>({
    defaultValues: async () => {
      return await getDefaultValues();
    },
  });

  return (
    <FormProvider {...formMethods}>
      <GiftDesignerModelScenariosPageFormInner
        householdId={householdId}
        proposalId={proposalId}
      />
    </FormProvider>
  );
}

export function GiftDesignerModelScenariosPage() {
  const householdId = useRequiredParam('householdId');
  const proposalId = useRequiredParam('proposalId');

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <GiftDesignerModelScenariosProvider>
        <GiftDesignerModelScenariosPageForm
          householdId={householdId}
          proposalId={proposalId}
        />
      </GiftDesignerModelScenariosProvider>
    </ErrorBoundary>
  );
}
