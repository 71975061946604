import { compose } from '@/utils/reducerUtils';

import { applyAutoLayoutToState, applySectionBoundsToState } from '../state';
import { AutoPositionAction, EntityDiagramActionFn } from '../types';

export const autoPositionAction: EntityDiagramActionFn<AutoPositionAction> = (
  state,
  _action,
  queueEffect
) => {
  queueEffect({ type: 'fitViewEffect' });

  return compose(applyAutoLayoutToState, applySectionBoundsToState)(state);
};
