import { Typography } from '@mui/material';
import { first } from 'lodash';
import React, { useMemo } from 'react';

import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AIOnboardingModal } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal';
import {
  AsyncJobNotificationInfoType,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { UnreachableError } from '@/utils/errors';

interface AIOnboardingReadyForReviewBannerProps {
  householdID: string;
  variant: 'entities' | 'people_and_orgs';
}

export function AIOnboardingReadyForReviewBanner({
  householdID,
  variant,
}: AIOnboardingReadyForReviewBannerProps) {
  const trackUserEvent = useTrackUserEvent();

  const { getNotifications } = useAsyncJobNotificationsContext();

  const completedNotification = useMemo(
    () =>
      first(
        getNotifications(
          householdID,
          AsyncJobNotificationInfoType.AI_ONBOARDING,
          AsyncJobNotificationStatus.COMPLETED
        )
      ),
    [getNotifications, householdID]
  );

  const bannerText = useMemo(() => {
    if (!completedNotification) return '';
    switch (variant) {
      case 'entities':
        return completedNotification.formatBannerMessage('entities');
      case 'people_and_orgs':
        return completedNotification.formatBannerMessage(
          'individuals and organizations'
        );
      default:
        throw new UnreachableError({
          case: variant,
          message: `Unknown ai onboarding banner variant: ${variant}`,
        });
    }
  }, [completedNotification, variant]);

  const [
    { isModalOpen: isAIOnboardingModalOpen },
    { setModalIsOpen: setIsAIOnboardingModalOpen },
  ] = useModalState();

  return (
    <>
      {completedNotification && bannerText && (
        <LuminaryAIBannerCard
          withBorder
          bannerText={<Typography variant="label">{bannerText}</Typography>}
          primaryButtonProps={{
            onClick: () => {
              setIsAIOnboardingModalOpen(true);
              trackUserEvent('ai_onboarding review button clicked', {
                source: 'ai-onboarding-banner',
                kgRootId: completedNotification.id,
                householdId: completedNotification.household?.id,
              });
            },
            size: 'lg',
            variant: 'primary',
            children: 'Review details',
          }}
        />
      )}
      {completedNotification &&
        isAIOnboardingModalOpen &&
        completedNotification.household?.id && (
          <AIOnboardingModal
            householdId={completedNotification.household.id}
            kgRootId={completedNotification.id}
            isOpen={isAIOnboardingModalOpen}
            onClose={() => setIsAIOnboardingModalOpen(false)}
          />
        )}
    </>
  );
}
