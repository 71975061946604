import { RouteObject } from 'react-router-dom';

import { Match } from './RouteWatcher';

export const ROUTE_KEYS = {
  // Routes with a __CONTAINER key aren't intended to be rendered as a standlone route, but rather
  // are a container for their children.
  __CONTAINER: '__CONTAINER',
  ROOT_REDIRECT: 'ROOT_REDIRECT',
  // PUBLIC_ERROR differs from the standard ERROR route in that it is also used for potentially unauthenticated users,
  // so we don't attempt to render any kind of contextual UI, just a message.
  PUBLIC_ERROR: 'PUBLIC_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  // the FUNCTIONAL_REDIRECT path is intended to be a single interface for backend redirects to hit, such that we
  // can refactor the frontend routes and update the redirect path in one place, rather than update the frontend
  // and backend paths separately and require a phased deploy process with backcompat
  FUNCTIONAL_REDIRECT: 'FUNCTIONAL_REDIRECT',

  // Top-level routes
  TASKS: 'TASKS',
  COLLABORATORS: 'COLLABORATORS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  KNOWLEDGE_CENTER: 'KNOWLEDGE_CENTER',
  TASK_STANDALONE: 'TASK_STANDALONE',
  ADMIN: 'ADMIN',

  // Admin routes
  ADMIN_USERS: 'ADMIN_USERS',
  ADMIN_BRANDING: 'ADMIN_BRANDING',
  ADMIN_SUB_BRANDS: 'ADMIN_SUB_BRANDS',
  ADMIN_CATEGORIES: 'ADMIN_CATEGORIES',
  ADMIN_FEATURE_FLAGS: 'ADMIN_FEATURE_FLAGS',
  ADMIN_INTEGRATIONS: 'ADMIN_INTEGRATIONS',
  ADMIN_TENANTS: 'ADMIN_TENANTS',
  ADMIN_LEGAL_DISCLAIMERS: 'ADMIN_LEGAL_DISCLAIMERS',
  USER_SETTINGS: 'USER_SETTINGS',
  HOUSEHOLDS_LIST: 'HOUSEHOLDS_LIST',

  // Dev tools routes
  INTERNAL_DEV_TOOLS: 'INTERNAL_DEV_TOOLS',
  INTERNAL_ERRORS_SANDBOX: 'INTERNAL_ERRORS_SANDBOX',
  INTERNAL_DATES_SANDBOX: 'INTERNAL_DATES_SANDBOX',
  INTERNAL_DOCS_SANDBOX: 'INTERNAL_DOCS_SANDBOX',
  INTERNAL_RTE_SANDBOX: 'INTERNAL_RTE_SANDBOX',

  // Proposal routes
  HOUSEHOLD_PROPOSAL_CREATE: 'HOUSEHOLD_PROPOSAL_CREATE',
  HOUSEHOLD_PROPOSAL_EDIT: 'HOUSEHOLD_PROPOSAL_EDIT',
  HOUSEHOLD_PROPOSAL_PRESENTATION: 'HOUSEHOLD_PROPOSAL_PRESENTATION',

  // GRAT design and implementation routes
  HOUSEHOLD_ENTITY_IMPLEMENTATION: 'HOUSEHOLD_ENTITY_IMPLEMENTATION',
  HOUSEHOLD_ENTITY_DESIGNER: 'HOUSEHOLD_ENTITY_DESIGNER',

  // Gift designer routes
  HOUSEHOLD_GIFT_DESIGNER: 'HOUSEHOLD_GIFT_DESIGNER',
  HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION:
    'HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION',
  HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS:
    'HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS',

  // CRT/CLT designer routes
  HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER:
    'HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER',
  HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION:
    'HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION',
  HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS:
    'HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS',

  // Single-entity routes
  HOUSEHOLD_ENTITY_DETAILS: 'HOUSEHOLD_ENTITY_DETAILS',
  HOUSEHOLD_ENTITY_DOCUMENTS: 'HOUSEHOLD_ENTITY_DOCUMENTS',
  HOUSEHOLD_ENTITY_DOCUMENT_DETAILS: 'HOUSEHOLD_ENTITY_DOCUMENT_DETAILS',
  HOUSEHOLD_ENTITY_TASKS: 'HOUSEHOLD_ENTITY_TASKS',
  HOUSEHOLD_ENTITY_PERFORMANCE: 'HOUSEHOLD_ENTITY_PERFORMANCE',
  HOUSEHOLD_ENTITY_PAYMENTS: 'HOUSEHOLD_ENTITY_PAYMENTS',
  HOUSEHOLD_ENTITY_VALUATION_HISTORY: 'HOUSEHOLD_ENTITY_VALUATION_HISTORY',
  HOUSEHOLD_ENTITY_DETAILS_DIAGRAM: 'HOUSEHOLD_ENTITY_DETAILS_DIAGRAM',

  // Manage household routes
  MANAGE_HOUSEHOLD_NEW: 'MANAGE_HOUSEHOLD_NEW',
  MANAGE_HOUSEHOLD_DETAILS: 'MANAGE_HOUSEHOLD_DETAILS',
  MANAGE_HOUSEHOLD_ADMIN: 'MANAGE_HOUSEHOLD_ADMIN',

  // Single household routes
  SPECIFIC_HOUSEHOLD_REDIRECT: 'SPECIFIC_CLIENT_REDIRECT',
  UNSPECIFIED_HOUSEHOLD_REDIRECT: 'UNSPECIFIED_CLIENT_REDIRECT',
  HOUSEHOLD_DETAILS_OVERVIEW: 'HOUSEHOLD_DETAILS_OVERVIEW',
  HOUSEHOLD_DETAILS_TASKS: 'HOUSEHOLD_DETAILS_TASKS',
  HOUSEHOLD_DETAILS_ARCHIVED_ENTITIES: 'HOUSEHOLD_DETAILS_ARCHIVED_ENTITIES',
  HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS:
    'HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS',
  HOUSEHOLD_ENTITY_IMPORT: 'HOUSEHOLD_ENTITY_IMPORT',
  HOUSEHOLD_ACTIVITY_HISTORY: 'HOUSEHOLD_ACTIVITY_HISTORY',
  HOUSEHOLD_DETAILS_PROPOSALS: 'HOUSEHOLD_DETAILS_PROPOSALS',
  HOUSEHOLD_DETAILS_ENTITY_MAP: 'HOUSEHOLD_DETAILS_ENTITY_MAP',
  HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM: 'HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM',
  HOUSEHOLD_DETAILS_ENTITIES_LIST: 'HOUSEHOLD_DETAILS_ENTITIES_LIST',
  HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES:
    'HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES',
  HOUSEHOLD_DETAILS_GROWTH_PROFILES: 'HOUSEHOLD_DETAILS_GROWTH_PROFILES',
  HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES:
    'HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES',
  HOUSEHOLD_DETAILS_PERFORMANCE: 'HOUSEHOLD_DETAILS_PERFORMANCE',
  HOUSEHOLD_DETAILS_REPORTING: 'HOUSEHOLD_DETAILS_REPORTING',
  HOUSEHOLD_DETAILS_PRESENTATIONS: 'HOUSEHOLD_DETAILS_PRESENTATIONS',
  HOUSEHOLD_DETAILS_PRESENTATION: 'HOUSEHOLD_DETAILS_PRESENTATION',
  HOUSEHOLD_DETAILS_PRESENTATION_V2: 'HOUSEHOLD_DETAILS_PRESENTATION_V2',
  HOUSEHOLD_DETAILS_GIFTING: 'HOUSEHOLD_DETAILS_GIFTING',
  HOUSEHOLD_DETAILS_DOCUMENTS: 'HOUSEHOLD_DETAILS_DOCUMENTS',
  HOUSEHOLD_DETAILS_DOCUMENT_DETAILS: 'HOUSEHOLD_DETAILS_DOCUMENT_DETAILS',

  // Single household waterfall routes
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_LIST:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_LIST',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_OVERVIEW:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_OVERVIEW',
  HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS:
    'HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS',
  HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS:
    'HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS',
  HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_ESTATE_TAX_CALCULATION_TROWSER:
    'HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_ESTATE_TAX_CALCULATION_TROWSER',

  // Bulk import routes
  IMPORT_ADDEPAR_CONTENT: 'IMPORT_ADDEPAR_CONTENT',
  IMPORT_BULK_ENTITIES: 'IMPORT_BULK_ENTITIES',
  IMPORT_CSV_VALUATIONS: 'IMPORT_CSV_VALUATIONS',

  // Print-only routes
  __PRINT_ONLY_SINGLE_ENTITY: '__PRINT_ONLY_SINGLE_ENTITY',
  __PRINT_ONLY_CLIENT_PRESENTATION: '__PRINT_ONLY_CLIENT_PRESENTATION',
  __PRINT_ONLY_CLIENT_PROPOSAL: '__PRINT_ONLY_CLIENT_PROPOSAL',
  __PRINT_ONLY_CLIENT_REPORTING: '__PRINT_ONLY_CLIENT_REPORTING',
  __PRINT_ONLY_WATERFALL_COMPARISON: '__PRINT_ONLY_WATERFALL_COMPARISON',
  __PRINT_ONLY_WATERFALL_COMPARISON_LEGACY:
    '__PRINT_ONLY_WATERFALL_COMPARISON_LEGACY',

  // Client team
  HOUSEHOLD_DETAILS_PEOPLE_CLIENT_TEAM: 'HOUSEHOLD_DETAILS_PEOPLE_CLIENT_TEAM',
  HOUSEHOLD_DETAILS_FAMILY_TREE: 'HOUSEHOLD_DETAILS_FAMILY_TREE',

  // Sidebar container routes
  HOUSEHOLD_SIDEBAR_OVERVIEW: 'HOUSEHOLD_SIDEBAR_OVERVIEW',
  HOUSEHOLD_SIDEBAR_WEALTH: 'HOUSEHOLD_SIDEBAR_WEALTH',
  HOUSEHOLD_SIDEBAR_ENTITIES: 'HOUSEHOLD_SIDEBAR_ENTITIES',
  HOUSEHOLD_SIDEBAR_PEOPLE: 'HOUSEHOLD_SIDEBAR_PEOPLE',
  HOUSEHOLD_SIDEBAR_PRESENTATIONS: 'HOUSEHOLD_SIDEBAR_PRESENTATIONS',
  HOUSEHOLD_DETAILS_BALANCE_SHEET: 'HOUSEHOLD_DETAILS_BALANCE_SHEET',
} as const;

export type RouteKey = keyof typeof ROUTE_KEYS;

export enum GratDesignerStages {
  BASIC_INFORMATION = 'basic-information',
  STRUCTURING = 'structuring',
}

export enum GiftDesignerStages {
  BASIC_INFORMATION = 'basic-information',
  MODEL_SCENARIOS = 'model-scenarios',
}

export enum CharitableTrustDesignerStages {
  BASIC_INFORMATION = 'basic-information',
  MODEL_SCENARIOS = 'model-scenarios',
}

export type AugmentedHandle = Match['handle'] & {
  features?: {
    /** Set this flag to `true` if a route and all its children should not display the global sidebar. */
    hideGlobalSidebar?: boolean;
  };
};

export type AugmentedRoute = Omit<RouteObject, 'children'> & {
  key: string;
  props?: Record<string, unknown>;
  children?: AugmentedRoute[];
  handle?: AugmentedHandle;
};
