import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { useHypotheticalTransferModalContext } from '../HypotheticalTransferModal.context';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';

function getValueFromDiscountRate(value: Decimal, discountRate: Decimal) {
  return new Decimal(1).minus(discountRate.div(100)).times(value);
}

export function TransferDiscount() {
  const { control, setValue } =
    useFormContext<HypotheticalTransferFormModalShape>();
  const {
    derivedData: { discountRateByEntityId },
  } = useHypotheticalTransferModalContext();

  const [sourceId, isDiscountRateToggled, taxDiscountRate, transferAmount] =
    useWatch({
      control,
      name: [
        'sourceId',
        '_discountRateToggle',
        'taxDiscountRate',
        'transferAmount',
      ],
    });

  const entityDiscountRate = discountRateByEntityId[sourceId];

  const switchLabel = useMemo(() => {
    if (!entityDiscountRate) {
      return 'Apply valuation discount';
    }

    return 'Override valuation discount';
  }, [entityDiscountRate]);

  const calloutText: string | null = useMemo(() => {
    if (!transferAmount) return null;
    const entityHasDiscount = !!entityDiscountRate;

    if (entityHasDiscount && !taxDiscountRate) {
      return `A discount of ${formatPercent(entityDiscountRate, 2)}% exists on this entity. Transfer value equals ${formatCurrencyNoDecimals(getValueFromDiscountRate(transferAmount, entityDiscountRate))} for gift and estate tax calculation purposes.`;
    }

    if (entityHasDiscount && taxDiscountRate) {
      return `This discount rate applies only to the hypothetical transfer and
              does not replace the existing entity discount. Transfer value
              equals ${formatCurrencyNoDecimals(getValueFromDiscountRate(transferAmount, taxDiscountRate))} for gift and estate tax calculation purposes.`;
    }

    if (!entityHasDiscount && taxDiscountRate) {
      return `This discount rate applies only to the hypothetical transfer. Transfer value
              equals ${formatCurrencyNoDecimals(getValueFromDiscountRate(transferAmount, taxDiscountRate))} for gift and estate tax calculation purposes.`;
    }

    return null;
  }, [entityDiscountRate, taxDiscountRate, transferAmount]);

  useEffect(
    function syncEntityDiscountRate() {
      if (taxDiscountRate) return;

      if (entityDiscountRate) {
        setValue('taxDiscountRate', entityDiscountRate);
      }
    },
    [entityDiscountRate, setValue, taxDiscountRate]
  );

  useEffect(() => {
    return () => {
      setValue('taxDiscountRate', null);
    };
  }, [setValue]);

  return (
    <Card variant="filled" sx={{ p: 2 }}>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch<HypotheticalTransferFormModalShape>
            control={control}
            fieldName="_discountRateToggle"
            label={switchLabel}
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {(entityDiscountRate || isDiscountRateToggled) && (
        <FormLayoutRow>
          <FormLayoutItem width={3}>
            <FormAwarePercentInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="taxDiscountRate"
              label="Discount rate"
              isDecimalJSInput
              disabled={!isDiscountRateToggled}
            />
          </FormLayoutItem>
          {calloutText && (
            <FormLayoutItem width={9}>
              <Stack height="100%" justifyContent="end">
                <Callout severity="info-high">{calloutText}</Callout>
              </Stack>
            </FormLayoutItem>
          )}
        </FormLayoutRow>
      )}
    </Card>
  );
}
