import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

interface TrustSummaryMiniTableProps {
  assetLocation?: string;
  grantorNames?: string[];
  taxStatus?: {
    value?: string;
    valueSecondary?: string;
  };
  trustJurisdiction?: string;
  effectiveDate?: Date;
  hideEffectiveDate?: boolean;
}

export function TrustSummaryMiniTable({
  assetLocation,
  grantorNames,
  taxStatus,
  trustJurisdiction,
  effectiveDate,
  hideEffectiveDate,
}: TrustSummaryMiniTableProps) {
  const rows = useMemo(() => {
    return compact([
      assetLocation
        ? {
            label: 'Asset location',
            value: assetLocation,
          }
        : null,
      grantorNames && grantorNames.length > 0
        ? {
            label: 'Grantor(s)',
            value: (
              <TypographyClamped
                sx={{
                  textAlign: 'right',
                }}
                lines={2}
                variant="h6"
              >
                {grantorNames?.join(', ')}
              </TypographyClamped>
            ),
          }
        : null,
      taxStatus?.value
        ? {
            label: 'Tax status',
            value: taxStatus?.value,
          }
        : null,
      trustJurisdiction
        ? {
            label: 'Trust jurisdiction',
            value: trustJurisdiction,
          }
        : null,
      !hideEffectiveDate && effectiveDate
        ? {
            label: 'Effective Date',
            value: formatDateToMonDDYYYY(effectiveDate),
          }
        : null,
    ]);
  }, [
    assetLocation,
    effectiveDate,
    grantorNames,
    hideEffectiveDate,
    taxStatus?.value,
    trustJurisdiction,
  ]);

  return (
    <MiniTable
      variant="default"
      rowProps={{
        alignItems: 'start',
      }}
      rows={rows}
    />
  );
}
