import { Stack } from '@mui/material';
import { first } from 'lodash';
import { useMemo } from 'react';

import { Divider } from '@/components/Divider';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { BeneficiaryReport } from '../beneficiaryReporting.types';
import { getAccessValueForDeathEvent } from '../beneficiaryReporting.utils';
import { BenefitsMetricsItem } from '../BenefitsMetricsItem';
import { BenefitsTooltip } from '../BenefitsTooltip';
import { BeneficiaryReportingQuery } from '../graphql/BeneficiaryReporting.generated';

interface BenefitsMetricsProps {
  beneficiaryReports: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[];
  firstGrantor?: ContextPrimaryClient;
  secondGrantor?: ContextPrimaryClient;
  beneficiaryReportingQueryData: BeneficiaryReportingQuery;
}

export function BenefitsMetrics({
  beneficiaryReports,
  firstGrantor,
  secondGrantor,
  beneficiaryReportingQueryData,
}: BenefitsMetricsProps) {
  const waterfall = first(
    getNodes(beneficiaryReportingQueryData.estateWaterfalls)
  );
  const displayNamesByDeath = useWaterfallGrantorDeathDisplay(waterfall, {
    firstDeathFallback: firstGrantor?.firstName
      ? `${firstGrantor?.firstName}'s`
      : '',
    secondDeathFallback: secondGrantor?.firstName
      ? `${secondGrantor?.firstName}'s`
      : '',
  });
  const allAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports);
  }, [beneficiaryReports]);

  const beforeFirstDeathAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.None]);
  }, [beneficiaryReports]);

  const afterFirstDeathAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.First]);
  }, [beneficiaryReports]);

  const afterBothDeathsAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.Second]);
  }, [beneficiaryReports]);

  return (
    <Stack direction="row" spacing={5}>
      <BenefitsMetricsItem
        title="After both deaths"
        tooltip={
          <BenefitsTooltip
            content="Total amount in beneficiaries name across all events displayed in
            the table below."
          />
        }
        value={allAccessValue}
      />
      <Divider
        sx={{
          borderColor: COLORS.GRAY[200],
        }}
        orientation="vertical"
        flexItem
      />
      <BenefitsMetricsItem
        title={displayNamesByDeath[AfterDeath.None]}
        value={beforeFirstDeathAccessValue}
      />
      {firstGrantor && (
        <BenefitsMetricsItem
          title={displayNamesByDeath[AfterDeath.First]}
          value={afterFirstDeathAccessValue}
        />
      )}
      {secondGrantor && (
        <BenefitsMetricsItem
          title={displayNamesByDeath[AfterDeath.Second]}
          value={afterBothDeathsAccessValue}
        />
      )}
    </Stack>
  );
}
