import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ComponentProps, PropsWithChildren, useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COLORS } from '@/styles/tokens/colors';

export function ScenarioCard({ children }: PropsWithChildren) {
  const isViewOnly = useViewOnly();
  const sx: SxProps<Theme> = useMemo(() => {
    return {
      p: 3,
      backgroundColor: isViewOnly ? COLORS.NEUTRAL_GRAY[50] : COLORS.GRAY[50],
    };
  }, [isViewOnly]);

  return (
    <Card variant="outlined" sx={sx}>
      {children}
    </Card>
  );
}

export const ScenarioTitle = ({ children }: PropsWithChildren) => (
  <Typography
    variant="h2"
    sx={(t) => ({
      color: t.palette.primary.main,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    })}
  >
    {children}
  </Typography>
);

export const ScenarioTitleRow = ({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Stack>>) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  >
    {children}
  </Stack>
);

interface DataCellProps {
  label?: string;
}

export const DataCell = ({
  label,
  children,
}: PropsWithChildren<DataCellProps>) => (
  <Stack py={3}>
    {label && (
      <Typography variant="h6" pb={1} color={COLORS.NEUTRAL_GRAY[500]}>
        {label}
      </Typography>
    )}
    {children}
  </Stack>
);

interface DataItemProps {
  label: string;
  primaryValue: string;
  secondaryValue?: string;
  subItemLeft1?: string;
  subItemLeft2?: string;
  subItemRight1?: string;
  subItemRight2?: string;
  labelVariant?: ComponentProps<typeof Typography>['variant'];
  testId?: string;
}

export const DataItem = ({
  label,
  primaryValue,
  secondaryValue,
  subItemLeft1,
  subItemLeft2,
  subItemRight1,
  subItemRight2,
  labelVariant = 'h3',
  testId,
}: DataItemProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    data-testid={testId}
  >
    <Stack spacing={0.5}>
      <Typography variant={labelVariant}>{label}</Typography>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2">{subItemLeft1}</Typography>
        <Typography variant="subtitle2">{subItemLeft2}</Typography>
      </Stack>
    </Stack>
    <Stack>
      <Stack direction="row" spacing={1}>
        <Stack spacing={0.5} textAlign="right">
          <Typography
            sx={{
              textDecoration: 'line-through',
            }}
            variant="h3"
          >
            {secondaryValue}
          </Typography>
          <Typography variant="subtitle2">{subItemRight2}</Typography>
        </Stack>
        <Stack spacing={0.5} textAlign="right">
          <Typography variant="h3" data-testid={`${testId}-primaryValue`}>
            {primaryValue}
          </Typography>
          <Typography variant="subtitle2">{subItemRight1}</Typography>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
