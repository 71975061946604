import { isEmpty, isEqual, isUndefined } from 'lodash';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AugmentedUpdateClientPresentationV2Input } from '@/types/schema';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { useClientPresentationDesignerV2Context } from './ClientPresentationDesignerV2.context';
import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import {
  getEstateWaterfallWithProjections,
  mapBundleToUpdateInput,
} from './ClientPresentationDesignerV2.utils';
import {
  ClientPresentationDesignerV2_EstateWaterfallFragment,
  useUpdateClientPresentationV2Mutation,
} from './graphql/ClientPresentationDesignerV2.generated';

export function usePersistPresentation() {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const {
    presentationId,
    householdId,
    estateWaterfallBundleConfigurationMap,
    setEstateWaterfallBundleConfigurationMap,
  } = useClientPresentationDesignerV2Context();
  const [bundles, presentationConfiguration, title] = useWatch({
    control,
    name: ['bundles', 'presentationConfiguration', 'title'],
  });

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [updateClientPresentationV2] = useUpdateClientPresentationV2Mutation({
    onError(error) {
      reportError(
        `Caught error when updating presentation [${presentationId}]`,
        error
      );
      showFeedback('Failed to save presentation. Please try again later.');
    },
    // don't update state, because that'll cause a runaway re-render/re-save
    fetchPolicy: 'no-cache',
    ignoreResults: true,
  });

  const previousTitle = usePrevious(title);
  const previousBundles = usePrevious(bundles);
  const previousPresentationConfiguration = usePrevious(
    presentationConfiguration
  );

  useEffect(() => {
    // conditions to skip the save:
    // 1. bundles is empty
    if (isEmpty(bundles)) {
      return;
    }

    // 2. previous bundles, title, or presentationConfiguration are empty (indicator of first render)
    if (
      isUndefined(previousBundles) ||
      isUndefined(previousPresentationConfiguration) ||
      isUndefined(previousTitle)
    ) {
      return;
    }

    // 3. everything is unchanged
    if (
      isEqual(bundles, previousBundles) &&
      isEqual(presentationConfiguration, previousPresentationConfiguration) &&
      title === previousTitle
    ) {
      return;
    }

    const input: AugmentedUpdateClientPresentationV2Input = {
      id: presentationId,
      update: {
        name:
          title ?? `Untitled Presentation ${formatDateToMMDDYY(new Date())}`,
        pageNumberToStartFrom: presentationConfiguration.pageNumberToStartFrom,
        showLegalDisclaimer: presentationConfiguration.showLegalDisclaimer,
        showPageNumbers: presentationConfiguration.showPageNumbers,
        showPageNumbersOnCoverSlide:
          presentationConfiguration.showPageNumbersOnCoverSlide,
        clearBundles: true,
      },
      withBundles: bundles.map((bundle, index) =>
        mapBundleToUpdateInput(bundle, householdId, index)
      ),
    };

    async function persistData() {
      const { data } = await updateClientPresentationV2({
        variables: { input },
      });

      // because the waterfalls are stored in a separate map in context (not in form state),
      // and are fetched during the mutation, that context needs to be updated
      if (data) {
        let shouldUpdateBundleEstateWaterfallMap = false;

        // populate a new object with the old map's data
        const newEstateWaterfallBundleConfigurationMap: Record<
          string,
          ClientPresentationDesignerV2_EstateWaterfallFragment
        > = { ...estateWaterfallBundleConfigurationMap };

        // check each bundle in the response...
        getNodes(data.updateClientPresentationV2.bundles).forEach(
          (bundle, index) => {
            const estateWaterfall = getEstateWaterfallWithProjections(bundle);

            // if the bundle has a waterfall...
            if (estateWaterfall) {
              // normalize on the pre-save bundle ID (as the bundles are cleared & recreated on saving)
              const oldBundleId = bundles[index]?.id;

              // and then push it in to the new object and set the flag
              if (oldBundleId) {
                shouldUpdateBundleEstateWaterfallMap = true;
                newEstateWaterfallBundleConfigurationMap[oldBundleId] =
                  estateWaterfall;
              }
            }
          }
        );

        // if the flag has been set, update the context
        if (shouldUpdateBundleEstateWaterfallMap) {
          setEstateWaterfallBundleConfigurationMap(
            newEstateWaterfallBundleConfigurationMap
          );
        }
      }
    }
    void persistData();
  }, [
    bundles,
    estateWaterfallBundleConfigurationMap,
    householdId,
    presentationConfiguration,
    presentationId,
    previousBundles,
    previousPresentationConfiguration,
    previousTitle,
    setEstateWaterfallBundleConfigurationMap,
    title,
    updateClientPresentationV2,
  ]);
}
