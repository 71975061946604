import { Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';

import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { useTabsContext } from '@/components/navigation/NavigationTabs/tabs.context';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { getEstateTaxTabs } from './EstateTaxSummaryPanel.utils';
import { useSummaryPanelQuery } from './hooks/useSummaryPanelQuery';
import { getNodeDisplayValues } from './utils';

export interface CalculationsProps {
  firstDeathData: ReturnType<typeof useSummaryPanelQuery>['firstDeathData'];
  secondDeathData: ReturnType<typeof useSummaryPanelQuery>['secondDeathData'];
  totalsElements: {
    [AfterDeath.None]: JSX.Element;
    [AfterDeath.First]: JSX.Element;
    [AfterDeath.Second]?: JSX.Element;
  };
}

export const Calculations = ({
  firstDeathData,
  secondDeathData,
  totalsElements,
}: CalculationsProps) => {
  const { currentTab, setCurrentTab } = useTabsContext<AfterDeath>();

  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const tabValues = useMemo(() => {
    return getEstateTaxTabs(isTwoClientHousehold);
  }, [isTwoClientHousehold]);

  const tabStrings = useWaterfallGrantorDeathDisplay(firstDeathData.waterfall);
  const TabsForClient = useMemo(() => {
    return tabValues.map((tabValue) => {
      return (
        <ButtonTab
          key={tabValue}
          display={tabStrings[tabValue]}
          isActive={currentTab === tabValue}
          onClick={() => setCurrentTab(tabValue)}
        />
      );
    });
  }, [currentTab, setCurrentTab, tabStrings, tabValues]);

  const { nodesForTab, deathYearForTab } = useMemo(() => {
    switch (currentTab) {
      case AfterDeath.None:
        return {
          nodesForTab: [],
          deathYearForTab: undefined,
        };
      case AfterDeath.First:
        return {
          nodesForTab: firstDeathData.nodesForDeathTarget,
          deathYearForTab: firstDeathData.deathYear,
        };
      case AfterDeath.Second:
        return {
          nodesForTab: secondDeathData.nodesForDeathTarget,
          deathYearForTab: secondDeathData.deathYear,
        };
    }
  }, [
    currentTab,
    firstDeathData.deathYear,
    firstDeathData.nodesForDeathTarget,
    secondDeathData.deathYear,
    secondDeathData.nodesForDeathTarget,
  ]);

  const orderedNodes = useMemo(
    () => orderBy(nodesForTab, (n) => n.taxableValue?.toNumber(), ['desc']),
    [nodesForTab]
  );

  return (
    <Stack gap={6}>
      <Stack spacing={3}>
        <Tabs fullWidth>{TabsForClient}</Tabs>
        {tabValues.map((tabValue) => (
          <TabContent tabKey={tabValue} key={tabValue}>
            <>{totalsElements[tabValue]}</>
          </TabContent>
        ))}
      </Stack>
      {orderedNodes.map((n) => {
        const { displayName } = getNodeDisplayValues(n);
        return (
          <Stack key={n.id}>
            <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
              {displayName}
            </Typography>
            <MiniTable
              variant="tally"
              rows={[
                {
                  label: `Projected market value${
                    deathYearForTab ? ` (${deathYearForTab})` : ''
                  }`,
                  value: formatCurrencyNoDecimals(
                    n.valueBeforeDispositions || n.value
                  ),
                },
                {
                  label: 'Non-taxable transfers',
                  labelTooltip:
                    'Includes any transfers to charitable recipients, or that use the unlimited marital exclusion.',
                  value: n.nonTaxableValue
                    ? formatCurrencyNoDecimals(n.nonTaxableValue)
                    : EMPTY_CONTENT_HYPHEN,
                },
                {
                  label: 'Amount included in the taxable estate',
                  value: n.taxableValue
                    ? formatCurrencyNoDecimals(n.taxableValue)
                    : EMPTY_CONTENT_HYPHEN,
                },
              ]}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
